// React
import { useContext } from "react"

// Components
import { Textarea } from "components"

// Contexts
import { PoContext } from "../contexts"

export function Location(): JSX.Element {
  // Hooks
  const data = useContext(PoContext)

  return (
    <Textarea
      disabled
      label="BRANCH LOCATION"
      value={data.datapo.address}
    />
  )
}