// React
import { useEffect, useState } from "react"

// Types
import type { SuccessFetch } from "types"
import { BillType } from "../../types"

// Utils
import { useApi } from "utils"

export function useDetail(id: string) {
  // Hooks
  const api = useApi()
  const [data, setData] = useState<BillType | null>(null)
  const [isLoading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    api.get("/probill/detailbill_bypo", {
      params: { purchase_order_id: id }
    }).then((res: SuccessFetch<{
      payload: {
        datapo: {
          purchase_order_id: number
          vendor_id: number
          payment_term: number
          tax_inclusive: boolean
          location_id: number
          memo: string
          transaction_date: string
          referensi: string
          promize_date: string
          approval_status: number
          po_status: number
          approve_by: number
          approve_date: string
          freight: number
          address: string
          po_delivery_status: number
          po_payment_status: number
          company_code: string
          trx_code: string
          vendor_code: string
          vendor_name: string
          location_name: string
          delivered: number
        }
        detailbill: {
          purchase_order_detail_id: number
          product_id: number
          qty_order: number
          delivered: string
          mr_unit_id: number
          price_measure: number
          tax_id: number
          total: number
          discount: string
          product_code: string
          product_name: string
          project_name: string
          project_id: number
        }[]
        reminder: null
      }
    }>) => {
      // Vars
      const result = res.data.payload

      setData({
        ...result,
        datapo: {
          ...result.datapo,
          location_id: result.datapo.location_id.toString(),
          payment_term: result.datapo.payment_term.toString(),
          purchase_order_id: result.datapo.purchase_order_id.toString(),
          vendor_id: result.datapo.vendor_id.toString()
        },
        detailbill: result.detailbill.map(item => {
          return {
            ...item,
            delivered: parseInt(item.delivered),
            discount: parseInt(item.discount),
            mr_unit_id: item.mr_unit_id.toString(),
            product_id: item.product_id.toString(),
            project_id: item.project_id.toString(),
            tax_id: item.tax_id.toString()
          }
        })
      })
    }).catch(() => {
      setData(null)
    }).finally(() => {
      setLoading(false)
    })

    return () => {
      setData(null)
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [])

  return { data, isLoading }
}