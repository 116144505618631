import { Card, DateInput, Input, Select } from "components";
import { Fragment } from "react";
import { appDropdown, useSo } from "../utils";
import { FilterType } from "../types";
import moment from "moment";
import { debounce } from "lodash";

export function Filter(props: {
    selected: FilterType,
    setSelected: (data: FilterType) => void
}) {
    const { data, isLoading, refetch } = useSo(props.selected.start_date || '', props.selected.end_date || '')


    const statusDropdown = [
        { value: "1", label: "Open" },
        { value: "2", label: "Close" },
        { value: "3", label: "Credit" },
    ]
    const allOption = { value: "", label: "ALL" }
    const onDebounce = debounce((e: any) => props.setSelected({ ...props.selected, search: e.target.value }))

    return (
        <Fragment>
            <Card>
                <Card.Body>
                    <section className="flex lg:flex-row flex-col justify-between lg:items-end gap-3 mt-2">
                        <section className="grid lg:grid-cols-5 grid-cols-1 gap-3 w-full">
                            <DateInput
                                label="DATE FROM"
                                onChange={(date) => {
                                    props.setSelected({ ...props.selected, start_date: moment(date).format("YYYY-MM-DD") })
                                    refetch()
                                }}
                                selected={props.selected?.start_date ? moment(props.selected?.start_date).toDate() : null}
                            />

                            <DateInput
                                label="DATE END"
                                onChange={(date) => {
                                    props.setSelected({ ...props.selected, end_date: moment(date).format("YYYY-MM-DD") })
                                    refetch()
                                }}
                                selected={props.selected?.end_date ? moment(props.selected.end_date).toDate() : null}
                            />

                            <Select
                                label="SO"
                                options={[allOption, ...data?.map((item: any) => ({ value: item?.value, label: item?.label }))]}
                                isLoading={isLoading}
                                value={props.selected.sales_order_id !== "" ? [allOption, ...data].find((item) => item.value?.toString() === props.selected.sales_order_id?.toString()) : allOption}
                                onChange={(e) => { props.setSelected({ ...props.selected, sales_order_id: e?.value }) }}

                            />

                            <Select
                                label="APPROVAL"
                                value={props.selected.approval_status !== "" ? [allOption, ...appDropdown].find((item) => item.value?.toString() === props.selected.approval_status?.toString()) : allOption}
                                options={[allOption, ...appDropdown]}
                                onChange={(e) => { props.setSelected({ ...props.selected, approval_status: e?.value }) }}
                            />

                            <Select
                                label="STATUS"
                                value={props.selected.invoice_status !== "" ? [allOption, ...statusDropdown].find((item) => item.value?.toString() === props.selected.invoice_status?.toString()) : allOption}
                                options={[allOption, ...statusDropdown]}
                                onChange={(e) => { props.setSelected({ ...props.selected, invoice_status: e?.value }) }}
                            />
                        </section>
                    </section>

                    <Input
                        label="SEARCH"
                        className="lg:w-[300px] md:w-full"
                        placeholder="Search data..."
                        onChange={onDebounce}
                    />
                </Card.Body>
            </Card>
        </Fragment>
    )
}