// Third-Party Libraries
import { Navigate, Outlet } from "react-router-dom"

// Utils
import { usePermissionRoute } from "utils"

export function PermissionChecker(params: { permission?: string }) {
  // Hooks
  const permissionRoute = usePermissionRoute()

  if (Boolean(params.permission && permissionRoute.checkPermission(params.permission))) {
    return <Outlet />
  }

  return (
    <Navigate
      replace
      to="/403"
    />
  )
}