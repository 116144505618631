import { RegisterDateType } from '../../types';

export const calculateRegister = (data: RegisterDateType[]) => {
  return data.reduce(
    (acc, item) => {
      acc.payment += item.total

      return acc;
    },
    {
      payment: 0,
      balance: 0,
    },
  );
};
