/* eslint-disable react-hooks/exhaustive-deps */
import { Card, Table } from "components";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { FormType } from "../types";
import { getCoaTaxJournal, totalAmountJournal } from "../utils/function";
import moment from "moment";

export const TableJournal = () => {
  const methods = useFormContext<FormType>();
  const [datajournal, transaction_date, trx_code] = methods.watch([
    "datajournal",
    "transaction_date",
    "trx_code"
  ]);

  const journalList = useMemo(
    () => getCoaTaxJournal(datajournal),
    [JSON.stringify(datajournal)]
  );

  const total = totalAmountJournal(datajournal);

  return (
    <Card>
      <Card.Body className="flex flex-col gap-5">
        <section className="flex justify-center">
          <div className="text-xl text-center border-b-2 px-3 border-black dark:border-white font-bold">
            JOURNAL
          </div>
        </section>

        <Table
          data={journalList}
          columns={[
            {
              title: "DATE",
              thProps: { className: "w-[10px]" },
            },
            {
              title: "IDM",
            },
            {
              title: "ACCOUNT",
            },
            {
              title: "DEBET",
            },
            {
              title: "CREDIT",
            }
          ]}>
          <tbody>
            {journalList.map((item, key) => (
              <tr key={key}>
                <td>{moment(transaction_date).format("DD/MM/YYYY")}</td>
                <td>{trx_code}</td>
                <td>{item.coa_name}</td>
                <td className="text-right">
                  {item.position === 1
                    ? item.amount?.toLocaleString("id-ID") ?? "0"
                    : ""}
                </td>
                <td className="text-right">
                  {item.position === 2
                    ? item.amount?.toLocaleString("id-ID") ?? "0"
                    : ""}
                </td>
              </tr>
            ))}
          </tbody>

          <tfoot>
            <tr className="text-xl font-bold">
              <th colSpan={3}></th>
              <th className="text-right">
                {total.debet.toLocaleString("id-ID")}
              </th>
              <th className="text-right">
                {total.credit.toLocaleString("id-ID")}
              </th>
            </tr>
          </tfoot>
        </Table>
      </Card.Body>
    </Card>
  )
}