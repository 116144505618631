// Types
import type { DataJournalType } from "../vars"

// Utils
import { getDiscount } from "./getDiscount"

export function getAcc(datajournal: DataJournalType[]) {
  return {
    sub_total: datajournal.reduce((acc, item) => acc + item.amount - getDiscount(item.amount, item.rate), 0),
    tax: datajournal.reduce((acc, item) => acc + + getDiscount(item.amount, item.rate), 0)
  }
}