import { Label, Select } from "components";
import { Ref } from "react";
import { SingleValue } from "react-select";
import { SelectOptionType } from "types";
import { DELIVERED } from "../utils/vars";

type DropdownDeliveredProps = {
  ref?: Ref<unknown>;
  label?: string;
  error?: string;
  isLoading?: boolean;
  value: string | null;
  onChange?: (item: SingleValue<SelectOptionType>) => void;
  isDefaultAll?: boolean;
};

export function DropdownDelivered({
  ref,
  label,
  error,
  isLoading,
  value,
  onChange,
  isDefaultAll = true,
}: DropdownDeliveredProps) {
  const options = [{ value: "0", label: "ALL" }].concat(Object.values(DELIVERED).filter(item => typeof item === 'number').map((item) => ({ value: item as unknown as string, label: DELIVERED[item as number] })))

  return (
    <section className="w-full">
      {label ? <Label text={label} error={Boolean(error)} /> : null}

      <section className="flex gap-3 w-full">
        <div className="w-full md:w-full">
          <Select
            className="grow"
            placeholder="Select Status"
            error={error}
            isLoading={isLoading}
            defaultValue={isDefaultAll ? { value: "0", label: "All" } : options.find((item) => item.value === value)}
            options={options}
            ref={ref}
            onChange={(item: any) => {
              if (onChange) {
                onChange(item);
              }
            }}
          />
        </div>
      </section>
    </section>
  );
}
