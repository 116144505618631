import { Fragment, useContext } from "react"
import { ActionButton, Button, Modal } from "../../../../../../../../../components"
import { FormSection } from "../../../../FormSection"
import * as yup from "yup"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import toast from "react-hot-toast"
import type { DataList } from "../../../../../types"
import { useApi, useToggle } from "../../../../../../../../../utils"
import { Render } from "../../../../../utils"
import { menuTitle } from "./MenuTitle"

export function Update(props: { id: DataList, refetch: () => void }) {
    const { isActive, toggle } = useToggle(false)

    return (
        <Fragment>
            <ActionButton.Update className="join-item" onClick={toggle} />
            {isActive && <ModalSection id={props.id} toggle={toggle} refetch={props.refetch} />}
        </Fragment>
    )
}

function ModalSection(props: {
    id: DataList
    toggle: () => void
    refetch: () => void
}) {

    return (
        <Modal isOpen title={`EDIT ${menuTitle.title}`} size="xl" closeModal={props.toggle}>
            <DataForm data={props.id} id={props.id} toggle={props.toggle} refetch={props.refetch} />
        </Modal>
    )
}

function DataForm(props: {
    data: DataList
    id: DataList
    toggle: () => void
    refetch: () => void
}) {
    const api = useApi()
    const { toggle } = useContext(Render)

    const validationSchema = yup.object().shape({
        mr_comparation_code: yup.string().label("Comparation code").required().test('len', 'Must be exactly 3 characters', val => val.toString().length === 3),
        mr_comparation_name: yup.number().label("Comparation name").required().test('len', 'Must be exactly 3 characters', val => val.toString().length <= 2).test('max-value','Comparation name value must not exceed 20', val => val <= 20),
        description: yup.string().label("Description"),
    })

    const onSubmit = (value: DataList) => {
        return new Promise<void>((resolve) => {
            toast.promise(
                api.put(`/comparation/edit?mr_comparation_id=${props.id.mr_comparation_id}`,
                    {
                        mr_comparation_code: value.mr_comparation_code,
                        mr_comparation_name: value.mr_comparation_name,
                        description: value.description,
                    }),
                {
                    loading: "Loading...",
                    success: (res) => res.data.message,
                    error: (err) => {
                        if (err.response && err.response.status === 400) {
                            return err.response.data.message || "Bad Request";
                        } else {
                            return "An error occurred";
                        }
                    }
                }
            ).then(() => {
                props.toggle()
                props?.refetch && props.refetch()
                toggle()
            }).catch(() => { }).finally(resolve)
        })
    }
    const methods = useForm({
        defaultValues: props.id,
        resolver: yupResolver(validationSchema)
    })

    return (
        <FormProvider {...methods}>
            {/* @ts-ignore */}
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <FormSection isUpdate />

                <Modal.Footer className="text-end">
                    <Button type="submit" color="primary" loading={methods.formState.isSubmitting ? "true" : undefined}>Save</Button>
                </Modal.Footer>
            </form>
        </FormProvider>
    )
}