// React
import { useEffect, useState } from "react"

// Types
import type { SelectOptionType, SuccessFetch } from "types"

// Utils
import { useApi } from "utils"

export function useSo(start_date: string, end_date: string) {
    
  // Hooks
  const api = useApi()
  const [data, setData] = useState<SelectOptionType[]>([])
  const [isLoading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    refetch()

    return () => {
      setData([])
      setLoading(false)
    }

    // eslint-disable-next-line
  }, [start_date, end_date])

  const refetch = () => {
    setLoading(true)

    api.get(`/salesorder?start_date=${start_date}&end_date=${end_date}`).then((res: SuccessFetch<{
      payload: {
        sales_order_id: string
        trx_code: string
      }[]
    }>) => {
      setData(res.data.payload.map(item => {
        return {
          label: item.trx_code,
          value: item.sales_order_id
        }
      }))
    }).catch(() => {
      setData([])
    }).finally(() => {
      setLoading(false)
    })
  }

  return { data, isLoading, refetch }
}