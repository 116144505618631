/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup"
import { APPROVAL } from "pages/Sales/Register/utils/vars"
import { useEffect, useMemo } from "react"
import { FormProvider, useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import { useApi, useHandleAuth } from "utils"
import { today } from "utils/functions"
import { FormSection } from "./components"
import { formTransferItemsSchema, FormTransferItemsType } from "./types"
import { submitMap } from "./utils/functions"

export default function TransferItems() {
  const navigate = useNavigate()
  const api = useApi()
  const { userTokenData } = useHandleAuth()
  const userToken = userTokenData()

  const defaultValues: FormTransferItemsType = useMemo(
    () => ({
      trx_code: "",
      location_from_id: NaN,
      location_to_id: NaN,
      employee_id: userToken?.user_id ?? NaN,
      transaction_date: today(),
      memo: "Transfer Item transaction",
      transfer_detail: [],
      approval_status: APPROVAL.PENDING,
      approval_status_text: "PENDING",
      approved_by: "",
      approved_by_name: "",
      location_from_name: "",
      location_to_name: ""
    }),
    []
  )

  const submitPromise = async (value: any) => {
    return await toast
      .promise(api.post("/transfer/add", submitMap(value)), {
        loading: "Loading...",
        success: (res) => res.data.message,
        error: (err) =>
          err.response.data.detail?.message ?? err.response.data.message
      })
      .then(() => {
        navigate("/sales/register/", { replace: true })
      })
      .catch((err) => {
        throw new Error(err)
      })
  }

  const onSubmit = async (value: any) => {
    return await submitPromise(value)
  }

  const methods = useForm<any>({
    defaultValues,
    resolver: yupResolver(formTransferItemsSchema)
  })

  useEffect(() => methods.reset(defaultValues), [defaultValues])

  return (
    <FormProvider {...methods}>
      <FormSection onSubmit={methods.handleSubmit(onSubmit)} />
    </FormProvider>
  )
}
