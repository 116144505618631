import { Fragment, useContext } from "react"
import { ChildItem } from "../.."
import { Delete, Detail, IsActive, Update } from "./components"
import type { DataList, DataItem } from "../../../../types"
import { useToggle } from "../../../../../../../../utils"
import { dataContext } from "pages/Setup/Preference/Academy"
import { TableNumber } from "components"

export function Item(props: { item: DataList, index: number, dataList: DataItem, refetch: () => void }) {
  const { isActive } = useToggle(false)
  const { currentPage, limit, size, total } = useContext(dataContext)

  const convertAction = (data: string) => {
    if(data === "1") return "CREATE"
    if(data === "2") return "READ"
    if(data === "3") return "UPDATE"
    if(data === "4") return "DELETE"
    if(data === "5") return "SOFT DELETE"
  }

  return (
    <Fragment>
      <tr>
        <td>{TableNumber({ dataLength: size, pageCurrent: currentPage, index: props.index + 1, limit: limit, totalData: total })}</td> 
        <td>{props.item.feature_code}</td>
        <td>{props?.item?.module?.module_name}</td>
        <td>{props?.item?.menu?.menu_name}</td>
        <td>{props.item.feature_name}</td>
        <td>{props.item.action ? convertAction(props?.item?.action?.toString()) : "-"}</td>
        <td>{props.item.description}</td>
        <td>
          <div className="flex items-center">
            <Detail id={props.item} />
            <Update id={props.item} refetch={props.refetch} />
            <Delete id={props.item} refetch={props.refetch} />
            <IsActive id={props.item} refetch={props.refetch} />
          </div>
        </td>
      </tr>
      {isActive && <ChildItem dataList={props.dataList} refetch={props.refetch} />}
    </Fragment>
  )
}