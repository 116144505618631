import { Button } from "components"
import { useState } from "react"
import { TablePaymentInvoice, TableInvoice } from "./Tables/PaymentInvoice"

type TabPaymentInvoiceProps = {
  salesOrderId?: number
  approvalView?: boolean
  approvalStatus: number
  display?: boolean
  displayCreateButton?: boolean
}

export const TabPaymentInvoice = ({
  salesOrderId,
  approvalView,
  approvalStatus,
  display = true,
  displayCreateButton = true
}: TabPaymentInvoiceProps) => {
  const [activeTab, setActiveTab] = useState(0)
  const tabs = ["INVOICE", "PAYMENT INVOICE"]

  return (
    <div className={`${display ? "" : "hidden"}`}>
      <div className="sm:w-full md:w-32 flex justify-start items-start mb-2">
        {tabs.map((item, key) => {
          const isActive = key === activeTab

          return (
            <Button
              size="sm"
              color="ghost"
              className={`no-animation w-full bg-[#3c3c3c] hover:bg-primary hover:text-black whitespace-nowrap ${
                isActive ? "bg-primary text-black" : "text-white"
              }`}
              onClick={() => setActiveTab(key)}>
              {item}
            </Button>
          )
        })}
      </div>

      <TableInvoice display={activeTab === 0} />

      <TablePaymentInvoice
        approvalView={approvalView}
        salesOrderId={salesOrderId}
        approvalStatus={approvalStatus}
        display={activeTab === 1}
      />
    </div>
  )
}
