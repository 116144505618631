/* eslint-disable react-hooks/exhaustive-deps */
import { useDetailSalesOrder } from "pages/Sales/Transaction/CustomerOrder/utils/hooks"
import { useEffect, useMemo } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { today } from "utils/functions"
import { FormInvoiceType } from "../types"
import { useDetailInvoice } from "../utils/hooks/useDetailInvoice"
import { FormSection } from "./FormSections"

type InvoiceDetailProps = {
  sales_order_id: string
  invoice_id: string
}

export default function InvoiceDetail({ sales_order_id, invoice_id }: InvoiceDetailProps) {
  const invoice = useDetailInvoice({
    invoice_id: invoice_id
  })
  const sales = useDetailSalesOrder({
    sales_order_id: sales_order_id
  })

  const defaultValues: FormInvoiceType = useMemo(
    () => ({
      trx_code: invoice.data?.datainvoice.trx_code,
      link: sales.data?.dataso?.trx_code ?? "",
      customer_id: invoice.data?.datainvoice.customer_id ?? NaN,
      sales_order_id: invoice.data?.datainvoice.sales_order_id ?? NaN,
      delivery_no: invoice.data?.datainvoice.delivery_no ?? 1,
      location_id: invoice.data?.datainvoice.location_id ?? NaN,
      memo: invoice.data?.datainvoice?.memo ?? "",
      referensi: invoice.data?.datainvoice?.referensi ?? "",
      transaction_date: invoice.data?.datainvoice?.transaction_date!,
      employee_id: sales.data?.dataso.employee_id ?? NaN,
      payment_term: sales.data?.dataso.payment_term ?? NaN,
      promize_date: sales.data?.dataso?.promize_date ?? today(),
      shipped: sales.data?.dataso?.address ?? "",
      tax_inclusive: true,
      payment_term_name: sales.data?.dataso?.payment_term_name ?? "",
      price_level_name: sales.data?.dataso?.price_level_name ?? "",
      invoice_detail:
        invoice.data?.detailinvoice?.map((item) => ({
          ...item,
          price_measure: item.price_invoice,
          tax_name: `${item.tax_name} (${item.tax_rate}%)`,
          price_level_name: "",
          location_id: 0,
          sales_order_detail_id: 0,
          product_name: item.product_name,
          product_barcode: "",
          qty_order: 0,
          received: 0,
          mr_unit_id: 0,
          project_id: 0
        })) ?? [],
      sodetail: sales.data?.detailso?.map((item) => ({
        invoiced: false,
        sales_order_detail_id: item.sales_order_detail_id,
        product_id: item.product_id,
        product_name: item.product_name,
        product_barcode: item.product_barcode,
        qty_order: item.qty_order,
        received: item.received ?? 0,
        mr_unit_id: item.mr_unit_id,
        mr_unit_name: item.mr_unit_name,
        price_measure: item.price_measure,
        discount: item.discount,
        total: item.total,
        project_id: item.project_id,
        project_name: item.project_name,
        tax_id: item.tax_id,
        tax_name: `${item.tax_name} (${item.tax_rate}%)`,
        tax_rate: item.tax_rate,
        location_id: item.location_id,
        location_name: item.location_name,
        soh: item?.soh ?? 0
      })),
      freight: invoice.data?.datainvoice?.freight ?? 0,
      approved_by_name:
        invoice.data?.datainvoice?.approve_by_name?.toString() ?? "",
      approval_status: invoice.data?.datainvoice?.approve_status ?? 1,
      invoice_status_text: invoice.data?.datainvoice?.invoice_status_text ?? "",
      delivery_status: 1,
      reminder: Object.is(invoice.data?.reminder, null)
        ? null
        : {
          card_group: invoice.data?.reminder?.card_group ?? NaN,
          card_id: invoice.data?.reminder?.card_id ?? NaN,
          memo: invoice.data?.reminder?.memo ?? "",
          promise_date: invoice.data?.reminder?.promise_date ?? today(),
          transaction_date:
            invoice.data?.reminder?.transaction_date ?? today(),
          trx_code: sales.data?.reminder?.trx_code ?? "",
          vendor_id: invoice.data?.reminder?.card_id ?? NaN
        }
    }),
    [sales.data, invoice.data]
  )

  const methods = useForm<FormInvoiceType>({ defaultValues })

  useEffect(() => methods.reset(defaultValues), [defaultValues])

  return (
    <FormProvider {...methods}>
      <FormSection approvalView isEdit onSubmit={() => null} />
    </FormProvider>
  )
}
