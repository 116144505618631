import { useContext } from "react"
import { BaseTable, Card } from "../../../../components"
import { ChildItem } from "./components"
import { Render, RenderProvider } from "./utils"

export default function CoaGroup() {
  return (
    <RenderProvider>
      <section className="container pt-5  gap-5 flex flex-col">
        <Card>
          <Card.Body className="flex flex-row items-center justify-between !text-xl font-bold">
            <div className="bg-black p-3 text-white rounded-lg">COA GROUP</div>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
              <BaseTable className="table">
                <thead>
                  <tr>
                    <th>NO</th>
                    <th>CODE</th>
                    <th>COA GROUP</th>
                    <th>REPORT TYPE</th>
                    <th>DESCRIPTION</th>
                  </tr>
                </thead>
                <tbody>
                  <TableContent />
                </tbody>
              </BaseTable>
          </Card.Body>
        </Card>
      </section>
    </RenderProvider>
  )
}
function TableContent() {
  const { render } = useContext(Render)

  if (render) {
    return <ChildItem />
  } else {
    return <div />
  }
}