import * as yup from "yup"
import { reconcileListSchema } from "./ReconcileList"

export type FormReconcileType = yup.InferType<typeof formReconcileSchema>

export const formReconcileSchema = yup.object().shape({
  coa_id: yup.string().label("Account ID").required(),
  reconciliation_id: yup.string().label("Reconciliation ID").required(),
  new_statement_balance: yup.number().label("New Statement Balance").required(),
  calculate_statement_balance: yup
    .number()
    .label("Calculate Statement Balance")
    .required(),
  out_of_balance: yup.number().label("Out of Balance").required(),
  last_reconciliation_date: yup
    .string()
    .label("Last Reconciliation Date")
    .required(),
  bank_statement_date: yup.string().label("Bank Statement Date").required(),
  last_statement_balance: yup
    .number()
    .label("Last Statement Balance")
    .required(),
  total_cleared_credit: yup.number().label("Total Cleared Credit").required(),
  total_cleared_debet: yup.number().label("Total Cleared Debet").required(),
  data: yup.array().of(reconcileListSchema).min(1).required()
})
