import { FormReceivedType, SubmitType } from "../../types"

export const submitMap = (data: FormReceivedType): SubmitType => {
  return {
    received_id: data?.received_id,
    transfer_id: data?.transfer_id,
    trx_code: data.trx_code ?? "",
    location_from_id: data.location_from_id,
    location_to_id: data.location_to_id,
    employee_id: data.employee_id,
    memo: data.memo,
    transaction_date: data.transaction_date,
    received_status: data.received_status,
    received_detail:
      data?.received_detail?.map((transfer) => ({
        product_id: transfer.product_id ?? 0,
        quantity_received: transfer.quantity_received ?? 0,
        received_detail_id: transfer?.received_detail_id
      })) ?? []
  }
}
