import { useEffect, useState } from "react"
import type { SelectOptionType, SuccessFetch } from "types"
import { useApi } from "utils"

interface FilterType {
    api: string
    label: string,
    value: string
}

export function useFilterDropdown(value: string) {
    const api = useApi()
    const [data, setData] = useState<SelectOptionType[]>([])
    const [isLoading, setLoading] = useState(true)

    const fetchData = () => {
        const filters: { [key: string]: FilterType } = {
            '1': { api: "/product/", label: 'product_name', value: 'product_id' },
            '2': { api: "/customer/", label: 'customer_name', value: 'customer_id' },
            '3': { api: "/vendor/", label: 'vendor_name', value: 'vendor_id' }
        };
        const selectedFilter = filters[value];
        
        if (selectedFilter) {
            setLoading(true);
            api.get(selectedFilter.api)
                .then((res: SuccessFetch<{
                    payload: {
                        [key: string]: string
                    }[]
                }>) => {
                    setData(res.data.payload.map(item => ({
                        label: item[selectedFilter.label],
                        value: item[selectedFilter.value]
                    })))
                })
                .catch(() => setData([]))
                .finally(() => setLoading(false))
        } else {
            setLoading(false)
        }
    }

    const refetch = () => {
        fetchData(); 
    }

    useEffect(() => {
        fetchData()
        return () => {
            setData([])
            setLoading(true)
        }
        // eslint-disable-next-line
    }, [value])

    return { data, isLoading, refetch }
}
