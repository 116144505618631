import { useApi } from "utils";
import { SalesType, FilterType } from "../../types";
import { useEffect, useState } from "react";
import { SuccessFetch } from "types";
import { DataTypePaging } from "types";

export function useList({ 
    page, 
    approval_status, 
    limit, 
    search,
    start_date,
    end_date,
    invoice_status,
    sales_order_id
}: FilterType) {

    const defaultValue = {
        payload: [],
        pagination_data: {
            page: 0,
            pages: 0,
            size: 0,
            total: 0
        }
    }

    const api = useApi();
    const [data, setData] = useState<DataTypePaging<SalesType>>(defaultValue);
    const [isLoading, setLoading] = useState(true)

    const params: { [key: string]: number | string | undefined } = {}
    if (page) params.page = page;
    if (limit) params.limit = limit;
    if (search) params.search = search;
    if (start_date) params.start_date = start_date;
    if (end_date) params.end_date = end_date;
    if (invoice_status) params.invoice_status = invoice_status;
    if (sales_order_id) params.sales_order_id = sales_order_id;
    if (approval_status) params.approval_status = approval_status;

    const fetchUrl = () => {
        return api.get("/invoice/?",{params})
    }

    const fetchData = () => {
        setLoading(true);

        fetchUrl().then((res: SuccessFetch<DataTypePaging<SalesType>>) => {
            setData(res.data);
        }).catch(() => {
            setData(defaultValue);
        }).finally(() => {
            setLoading(false);
        });
    }

    const refetch = () => fetchData();

    useEffect(() => {
        fetchData();

        return () => {
            setData(defaultValue);
            setLoading(true);
        }
        // eslint-disable-next-line
    }, [page, approval_status, limit, search, start_date, end_date, invoice_status, sales_order_id]);

    return { data, isLoading, refetch }
}
