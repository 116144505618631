// React
import { useEffect, useState } from "react"

// Types
import type { SelectOptionType, SuccessFetch } from "types"

// Utils
import { useApi } from "utils"

export function useEmployee(): {
  dataEmployee: SelectOptionType[]
  isLoadingEmployee: boolean
} {
  // Hooks
  const api = useApi()
  const [dataEmployee, setData] = useState<SelectOptionType[]>([])
  const [isLoadingEmployee, setLoading] = useState<boolean>(true)

  useEffect(() => {
    api.get("/employee/")
    .then((res: SuccessFetch<{
      payload: {
        employee_id: number
        employee_name: string
      }[]
    }>) => {
      setData(res.data.payload.map(item => {
        return {
          label: item.employee_name,
          value: item.employee_id.toString()
        }
      }))
    }).catch(() => {
      setData([])
    }).finally(() => {
      setLoading(false)
    })
  
    return () => {
      setData([])
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [])

  return { dataEmployee, isLoadingEmployee }
}