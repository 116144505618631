// React
import { Fragment, useContext } from "react"

// Components
import { BaseTable, Button, CommandButton, Loading, Modal } from "components"

// Contexts
import { ListJournalContext } from "pages/Finance/Transaction/contexts"

// Third-Party Libraries
import moment from "moment"

// Utils
import { useToggle } from "utils"
import { useDataJournal, useDelete } from "./utils"

export function Delete() {
  // Hooks
  const { data_source, date, is_enabled, journal_code } = useContext(ListJournalContext)
  const { isActive, toggle } = useToggle(false)

  if (is_enabled) {
    return (
      <Fragment>
        <CommandButton
          actiontype="delete"
          onClick={toggle}
        />
  
        {isActive && (
          <ModalSection
            data_source={data_source}
            date={date}
            journal_code={journal_code}
            onClose={toggle}
          />
        )}
      </Fragment>
    )
  }

  return <Fragment />
}

function ModalSection(params: {
  data_source: string
  date: string
  journal_code: string
  onClose: () => void
}) {
  // Hooks
  const { data, isLoading } = useDataJournal(params.data_source, params.date, params.journal_code)

  return (
    <Modal isOpen title="DELETE JOURNAL" size="6xl" closeModal={params.onClose}>
      {isLoading || data.length === 0 ? (
        <Modal.Body>
          <Loading loading={isLoading} errorText="No data available" />
        </Modal.Body>
      ) : (
        <Fragment>
          <Modal.Body className="flex flex-col gap-3">
            <small className="text-lg font-medium text-red-400">Are you sure want to delete this data?</small>

            <BaseTable className="border-collapse border-transparent">
              <thead>
                <tr className="uppercase">
                  <th>Date</th>
                  <th colSpan={2}>Id</th>
                  <th>Account</th>
                  <th>Memo</th>
                  <th>Debit</th>
                  <th>Credit</th>
                  <th>Job Id</th>
                </tr>
              </thead>

              <tbody>
                {data.map((itemData, key) => {
                  // Vars
                  const isFirstRow = key === 0;
                  const isLastRow = key === data.length - 1

                  if (isFirstRow) {
                    return (
                      <tr key={key}>
                        <td className="font-bold">{moment(itemData.transaction_date, "YYYY-MM-DD").format("DD/MM/YYYY")}</td>
                        <td />

                        <td
                          colSpan={6}
                          className="font-bold"
                        >
                          {itemData.source_memo}
                        </td>
                      </tr>
                    )
                  } else if (isLastRow) {
                    return (
                      <tr key={key}>
                        <td colSpan={4} />
                        <td>Balance Checker</td>
                        <td className="text-center font-bold">{itemData.debit?.toLocaleString("id-ID")}</td>
                        <td className="text-center text-red-400 font-bold">{itemData.credit?.toLocaleString("id-ID")}</td>
                        <td />
                      </tr>
                    )
                  }

                  return (
                    <tr key={key}>
                      <td />
                      <td>{itemData.source_code}</td>
                      <td>{itemData.trx_code}</td>
                      <td>{itemData.coa_code} {itemData.coa_name}</td>
                      <td className="border-none">{itemData.memo}</td>
                      <td className="text-center border-none">{itemData.debit?.toLocaleString("id-ID")}</td>
                      <td className="text-center border-none text-red-400">{itemData.credit?.toLocaleString("id-ID")}</td>
                      <td className="text-right">{itemData.job_id}</td>
                    </tr>
                  )
                })}
              </tbody>
            </BaseTable>
          </Modal.Body>

          <Modal.Footer>
            <DeleteButton journal_code={data[0].journal_code} />
          </Modal.Footer>
        </Fragment>
      )}
    </Modal>
  )
}

function DeleteButton(params: { journal_code: string }) {
  // Hooks
  const { isLoading, deleteData } = useDelete(params.journal_code)

  return (
    <Button
      type="button"
      color="error"
      loading={isLoading ? "true" : undefined}
      onClick={deleteData}
    >
      Delete
    </Button>
  )
}