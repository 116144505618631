
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { today } from "utils"
import { useDetailInvoice } from "../../Invoice/utils/hooks/useDetailInvoice"
import { PaymentInvoiceType } from "../types"
import { useDetailPaymentInvoice } from "../utils/hooks"
import { FormSection } from "./FormSection"


type PaymentInvoiceDetailProps = {
  payment_invoice_id: string
}

export default function PaymentInvoiceDetail({ payment_invoice_id }: PaymentInvoiceDetailProps) {
  const paymentInvoice = useDetailPaymentInvoice({
    payment_invoice_id: payment_invoice_id ?? ""
  })
  const invoice = useDetailInvoice({
    invoice_id: paymentInvoice.data?.invoice_id?.toString() ?? ""
  })

  const defaultValues: PaymentInvoiceType = useMemo(() => {
    const balanceInvoice = invoice.data?.datainvoice.balance ?? 0
    const paymentAmount = paymentInvoice.data?.payment_amount ?? 0
    const totalDeposit = paymentInvoice.data?.deposit_amount ?? 0

    return {
      payment_invoice_id: paymentInvoice.data?.payment_invoice_id,
      invoice_id: invoice.data?.datainvoice.invoice_id,
      customer_id: paymentInvoice.data?.customer_id ?? NaN,
      location_id: paymentInvoice.data?.location_id ?? NaN,
      coa_id: paymentInvoice.data?.coa_id ?? NaN,
      coa_name: paymentInvoice.data?.coa_name ?? "",
      coa_balance: 0,
      balance_invoice: balanceInvoice - paymentAmount,
      balance: 0,
      link: invoice.data?.datainvoice?.trx_code ?? "",
      trx_code: paymentInvoice.data?.trx_code,
      no_cheque: paymentInvoice.data?.no_cheque ?? "",
      transaction_date: paymentInvoice.data?.transaction_date ?? today(),
      deposit_amount: totalDeposit,
      payment_amount: paymentAmount,
      memo: paymentInvoice.data?.memo ?? "Payment Invoice transaction",
      shipped: paymentInvoice.data?.address ?? "",
      referensi: paymentInvoice.data?.referensi ?? ""
    }
  }, [invoice.data])

  const methods = useForm<PaymentInvoiceType>({ defaultValues })

  useEffect(() => methods.reset(defaultValues), [defaultValues])

  return (
    <FormProvider {...methods}>
      <FormSection
        approvalView
        isEdit
        onSubmit={() => null}
      />
    </FormProvider>
  )
}
