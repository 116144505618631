import { Fragment } from "react"
import { useItemProductTable } from "../../utils"
import { DataForm } from "./DataForm"
import { BaseTable, Loading } from "components"
import { IoSendSharp } from "react-icons/io5"
import { Manufacture } from "./Manufacture"
import { convertNumber } from "utils"

interface ItemProductTableProps {
    action?: "CREATE" | "UPDATE" | "DETAIL"
    selected?: any
    fieldValue?: any
    setSelected: ( newState: any[] ) => void
    setFieldValue: ( newState: any[] ) => void
    id?: string
    itemsProfile?: any
    setComponentCost: (newState: string) => void
    refetchCOGSManufacture: () => void
    refetchBuying: () => void
}
export const ItemProductTable = ({ action, selected, fieldValue, setSelected, setFieldValue, id, itemsProfile, setComponentCost, refetchCOGSManufacture, refetchBuying }: ItemProductTableProps) => {
    const { dataList, isLoadingList, errorList } = useItemProductTable(id ? parseFloat(id) : undefined!)
  
    return (
      <Fragment>
        <div className="flex gap-8 overflow-x-auto">
          <div>
            <DataForm action={action}  />
  
            {/* Table */}
            <div className="mt-5 overflow-auto max-h-[440px] w-[500px]">
              <div className="text-[13px] mb-1 uppercase"><b>Item Product</b></div>
              <BaseTable className="border-collapse">
                <thead>
                  <tr>
                    <th>BSI</th>
                    <th>CODE</th>
                    <th>ITEM</th>
                    <th>CONV</th>
                    <th>SOH</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoadingList || errorList 
                    ? <tr><td colSpan={5} className="text-center"><Loading errorText="No Data Available" loading={isLoadingList} /></td></tr>
                    : dataList?.length! > 0 
                      ? dataList?.map((field, key) => {
                        const onBSIcreate = `${itemsProfile?.buy ? "B" : ""}${itemsProfile?.sell ? "S" : ""}${itemsProfile?.inventory ? "I" : ""}`
                        return (
                          <tr key={key}>
                            <td className="min-w-[80px]">
                              <div className={`flex items-center justify-${action === "DETAIL" ? "center" : "between"}`}>
                                {action !== "DETAIL" && <div>
                                  <IoSendSharp 
                                    className="cursor-pointer"
                                    onClick={() => {
                                      const updatedSelected = [...selected, field];
                                      setSelected(updatedSelected)
                                    }} 
                                  />
                                </div>}
                                <div>{onBSIcreate ?? "-"}</div>
                              </div>
                            </td>
                            <td>{field?.product_code ?? "-"}</td>
                            <td>{field?.product_name ?? "-"}</td>
                            <td className="text-right">{field?.price_unit_convention ? convertNumber(field?.price_unit_convention)?.intoCurrency : "0"}</td>
                            <td className="text-right">{"-"}</td>
                          </tr>
                        )
                      })
                    : <tr><td colSpan={7} className="text-center">No Data Available</td></tr>
                  }
                </tbody>
              </BaseTable>
            </div>
          </div>
          <div>
            <Manufacture refetchBuying={refetchBuying} refetchCOGSManufacture={refetchCOGSManufacture} setComponentCost={setComponentCost} action={action} selected={selected} fieldValue={fieldValue} setFieldValue={setFieldValue} />
          </div>
        </div>
      </Fragment>
    )
  }