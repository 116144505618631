import { Button } from "components"

interface TabProps {
    tabs: any
    activeTab?: number
    className?: string
    setActiveTab: (newState: any) => void
}

export const Tab = ({ tabs, activeTab, setActiveTab, className }: TabProps) => {
    // const permissionCode = usePermission([""])
    // const notify = () => toast.error('You not have permission!');

    return (
        <div className="flex justify-between overflow-auto">
            {tabs.map((tab: any, index: any) => (
                <Button
                key={tab.index}
                permission={tab.permission}
                type="button"
                color="ghost"
                size="sm"
                className={`grow bg-[#3c3c3c] border-y-0 border-x no-animation hover:bg-primary hover:text-black whitespace-nowrap uppercase ${index === activeTab ? "bg-primary text-black" : "text-white"}`}
                onClick={() => setActiveTab(index)}
            >
                {tab.name}
            </Button>
            ))}
        </div>
    )
}