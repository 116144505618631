import { useState } from "react"
import { Button, Card, CommandButton, HeaderMenu, Loading } from "../../../../../components"
import { FormProvider, useForm } from "react-hook-form"
import { useLocation, useNavigate } from "react-router-dom"
import type { AddressList, BankList, EducationList, PersonalList, ProfileList, WorkStatusList } from "../types"
import { RenderProvider } from "../utils"
import { Tab } from "./ChildItem/components/Item/components/Tabs"
import { FormPersonalData } from "./FormSection/FormPersonalData"
import { FormProfile } from "./FormSection/FormProfile"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import toast from "react-hot-toast"
import { useApi, usePermission } from "../../../../../utils"
import { FormAddress } from "./FormSection/FormAddress"
import { FormWorkStatus } from "./FormSection/FormWorkStatus"
import { FormEducation } from "./FormSection/FormEducation"
import { FormBank } from "./FormSection/FormBank"
import { useDetailPrivillegeData } from "./ChildItem/components/Item/utils"
import { FormPrivilege } from "./FormSection/FormPrivilege"

export function CreatePageTwo(props: { asModal?: boolean }) {
  const navigate = useNavigate()
  const api = useApi()
  const location = useLocation()
  const tabs = ["PROFILE", "ADDRESS", "WORK STATUS", "EDUCATION", "BANK", "PRIVILEGE"];
  const permission = usePermission(["CT02"])

  const [personalDataPayload, setPersonalDataPayload] = useState<any>(undefined);
  const [activeTab, setActiveTab] = useState<number | undefined>(undefined);
  const [fileValue, setFileValue] = useState<File | undefined>(undefined);
  const [isActiveSubmit, setIsActiveSubmit] = useState<any>({ 
    personal: false, 
    profile: false,
    address: false,
    work_status: false,
    education: false,
    bank: false
  })
  const [disableTab, setDisableTab] = useState(false);
  const [responseValue, setResponseValue] = useState<any>(undefined);

  const { dataPrivillege, errorPrivillege, isLoadingPrivillege, refetchPrivillege } = useDetailPrivillegeData(personalDataPayload?.employee_id ? personalDataPayload?.employee_id : null)

  type CreatePersonalData = Pick<PersonalList, "employee_code" | "employee_name" | "account" | "passwd" | "confirm_passwd" | "Employee_Foto">;

  // Form Values
  const defaultValues:CreatePersonalData = {
    employee_code: "",
    employee_name: "",
    account: "",
    passwd: "",
    confirm_passwd: "",
    Employee_Foto: ""
  }
  const profileValues = {
    type_nationality: undefined,
    nomor: "",
    birthdate: undefined,
    marital_status: undefined,
    religion_id: undefined,
    handphone: "",
    email: ""
  }
  const addressValues = {
    country_id: undefined,
    province_id: undefined,
    regency_id: undefined,
    subdistrict_id: undefined,
    village_id: undefined,
    address: undefined,
  }
  const workStatusValues = { 
    employee_status_id: undefined,
    date_end: undefined,
    date_start: undefined,
    group_position_id: undefined,
    job_position_id: undefined,
    location_id: undefined,
    department_id: undefined,
    divisi_id: undefined,
    gender: undefined,
    salary_type_id: undefined,
  }
  const educationValues = {
    sd_id: undefined,
    smp_id: undefined,
    sma_id: undefined,
    academy_id: undefined,
    university_id: undefined,
    magister_id: undefined,
    doctoral_id: undefined,
  }
  const bankValues = { 
    bank_id: undefined,
    branch: "",
    account_number: "",
    account_name: "",
  }
  const validationSchema = yup.object().shape({ 
    employee_code: yup.string().label("Employee code").required(),
    employee_name: yup.string().label("Name").required(),
    account: yup
      .string()
      .label("Account")
      .required()
      .test("checkDuplicate", "The account is already in use!", async (value) => api.post(`/employee/checkaccount?account=${value}&employee_id=0`)
      .then((res) => res?.data?.status === "Error" ? false : true)
      .catch(() => false)),
    passwd: yup.string().label("Password").required(),
    confirm_passwd: yup
      .string()
      .label("Re-Password")
      .test('match', 'Passwords must match', function(value) { return value === this.parent.passwd ? true : false })
    .required(),
  })

  // Handle Form
  const methods = useForm<CreatePersonalData>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })
  const profileMethods = useForm<ProfileList>({ defaultValues: profileValues })
  const addressMethods = useForm<AddressList>({ defaultValues: addressValues })
  const workStatusMethods = useForm<WorkStatusList>({ defaultValues: workStatusValues })
  const educationMethods = useForm<EducationList>({ defaultValues: educationValues })
  const bankMethods = useForm<BankList>({ defaultValues: bankValues })

  // Handle Submit
  const onSubmit = (value: PersonalList) => {
    const formData = new FormData();
    
    formData.append('employee_code', value?.employee_code!);
    formData.append('employee_name', value?.employee_name);
    formData.append('account', value?.account);
    formData.append('passwd', value?.passwd);
    formData.append('confirm_passwd', value?.confirm_passwd);
    if(value?.Employee_Foto){formData.append(fileValue ? 'Employee_Foto' : "", value?.Employee_Foto)}

    return new Promise<void>((resolve) => {
      toast.promise(
        api.post("/employee/add", formData),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => {
            if (err.response && err.response.status === 422) {
              return err.response.data.message || "Bad Request";
            } else {
              return "An error occurred";
            }
          }
        }
      )
      .then((res) => {
        setActiveTab(0)
        setDisableTab(true)
        setPersonalDataPayload(res?.data?.payload?.[0])
        setIsActiveSubmit({ ...isActiveSubmit, personal: true })
      })
      .catch(() => {})
      .finally(resolve)
    })
  }
  const onProfileSubmit = (value: ProfileList) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.put(`/employee/updateprofile?employee_id=${personalDataPayload?.employee_id}`, value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => {
            if (err.response && err.response.status === 422) {
              return err.response.data.message || "Bad Request";
            } else {
              return "An error occurred";
            }
          }
        }
      )
      .then((res) => {
        setIsActiveSubmit({ ...isActiveSubmit, profile: true })
        setResponseValue(res?.data?.payload?.[0])
      })
      .catch(() => {})
      .finally(resolve)
    })
  }
  const onAddressSubmit = (value: AddressList) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.put(`/employee/updateaddress?employee_id=${personalDataPayload?.employee_id}`, value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => {
            if (err.response && err.response.status === 422) {
              return err.response.data.message || "Bad Request";
            } else {
              return "An error occurred";
            }
          }
        }
      )
      .then((res) => {
        setIsActiveSubmit({ ...isActiveSubmit, address: true })
        setResponseValue(res?.data?.payload?.[0])
      })
      .catch(() => {})
      .finally(resolve)
    })
  }
  const onWorkStatusSubmit = (value: WorkStatusList) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.put(`/employee/updateworkstatus?employee_id=${personalDataPayload?.employee_id}`, value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => {
            if (err.response && err.response.status === 422) {
              return err.response.data.message || "Bad Request";
            } else {
              return "An error occurred";
            }
          }
        }
      )
      .then((res) => {
        setIsActiveSubmit({ ...isActiveSubmit, work_status: true })
        setResponseValue(res?.data?.payload?.[0])
      })
      .catch(() => {})
      .finally(resolve)
    })
  }
  const onEducationSubmit = (value: EducationList) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.put(`/employee/updateeducation?employee_id=${personalDataPayload?.employee_id}`, value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => {
            if (err.response && err.response.status === 422) {
              return err.response.data.message || "Bad Request";
            } else {
              return "An error occurred";
            }
          }
        }
      )
      .then((res) => {
        setIsActiveSubmit({ ...isActiveSubmit, education: true })
        setResponseValue(res?.data?.payload?.[0])
      })
      .catch(() => {})
      .finally(resolve)
    })
  }
  const onBankSubmit = (value: BankList) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.put(`/employee/updatebank?employee_id=${personalDataPayload?.employee_id}`, value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => {
            if (err.response && err.response.status === 422) {
              return err.response.data.message || "Bad Request";
            } else {
              return "An error occurred";
            }
          }
        }
      )
      .then((res) => {
        setIsActiveSubmit({ ...isActiveSubmit, bank: true })
        setResponseValue(res?.data?.payload?.[0])
      })
      .catch(() => {})
      .finally(resolve)
    })
  }

  return (
    <RenderProvider>
      <section className="container my-2">
        {!props.asModal && (
          <HeaderMenu title="DATA ENTRY | CREATE NEW EMPLOYEE"/>
        )}
        <Card>
          <Card.Body>
            <div className="flex justify-end">
              <Button
                type="button"
                className="!none" 
                color="primary"
                permission={"CR011"}
                onClick={() => navigate(location?.pathname === "/cards/transaction/create-new-employee" ? "/cards/transaction/create-new-employees" : "/cards/transaction/create-new-employee")}
              >
                CREATE NEW ITEM
              </Button>
            </div>
            <div className="lg:flex">
              <div className="lg:w-[34%] mr-0.5">
                <button className="bg-[#3c3c3c] px-4 py-2 w-full mr-0.5 text-white text-[12px]"><b>PERSONAL DATA</b></button>
                <div className={`max-w-sm w-full lg:max-w-full lg:flex h-[${methods?.control?._formState?.errors ? "1950" : "1750px"}]`}>
                  <div className="w-full border border-[#3c3c3c] lg:border-[#3c3c3c] p-6">
                    <FormProvider {...methods}>
                      {/* @ts-ignore */}
                      <FormPersonalData action="CREATE" onSubmit={onSubmit} setFileValue={setFileValue} isActiveSubmit={isActiveSubmit?.personal} permission={permission?.canCreate} />
                    </FormProvider>
                  </div>
                </div>
              </div>
              <div className="lg:w-[66%]">
                <Tab tabs={tabs} activeTab={activeTab} disabled={!disableTab} setActiveTab={setActiveTab} dataProfile={responseValue} className="text-[11px]" />
                <div className="">
                  <div className={`max-w-sm w-full lg:max-w-full lg:flex h-[${methods?.control?._formState?.errors ? "1950" : "1750px"}]`}>
                    <div className="w-full border border-[#3c3c3c] lg:border-[#3c3c3c] p-6 mr-0.5">
                      <FormProvider {...profileMethods}>
                        {activeTab === 0 && <FormProfile onSubmit={onProfileSubmit} permission={permission?.canCreate} />}
                      </FormProvider>
                      <FormProvider {...addressMethods}>
                        {activeTab === 1 && <FormAddress onSubmit={onAddressSubmit} permission={permission?.canCreate} />}
                      </FormProvider>
                      <FormProvider {...workStatusMethods}>
                        {activeTab === 2 && <FormWorkStatus onSubmit={onWorkStatusSubmit} permission={permission?.canCreate} />}
                      </FormProvider>
                      <FormProvider {...educationMethods}>
                        {activeTab === 3 && <FormEducation onSubmit={onEducationSubmit} permission={permission?.canCreate} />}
                      </FormProvider>
                      <FormProvider {...bankMethods}>
                        {activeTab === 4 && <FormBank onSubmit={onBankSubmit} permission={permission?.canCreate} />}
                      </FormProvider>
                      {activeTab === 5
                        ? isLoadingPrivillege || errorPrivillege 
                          ? <Loading errorText={errorPrivillege} loading={isLoadingPrivillege} /> 
                          : <DataPrivilege dataPrivillege={dataPrivillege} id={personalDataPayload?.employee_id} isLoadingPrivillege={isLoadingPrivillege} refetchPrivillege={refetchPrivillege} permission={permission?.canCreate} />
                        : ""
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      <section className="mt-2">
          <Card>
            <Card.Body className="flex flex-col gap-5">
              <section className="flex flex-wrap justify-between items-center gap-3">
                <section className="flex flex-wrap gap-3">
                  <CommandButton actiontype="help" />
                  <CommandButton actiontype="print" />
                  <CommandButton actiontype="email" />
                  <CommandButton actiontype="export" />
                </section>
              </section>
            </Card.Body>
          </Card>
        </section>
      </section>
    </RenderProvider>
  )
}
interface PrivilegeProps { 
  dataPrivillege?: any 
  isLoadingPrivillege?: boolean
  id: number
  refetchPrivillege: () => void
  permission?: boolean
}
const DataPrivilege: React.FC<PrivilegeProps> = ({ dataPrivillege, id, isLoadingPrivillege, refetchPrivillege, permission }) => {
  const api = useApi()
  const [privillegeData, setPrivillegeData] = useState(dataPrivillege)

  const enabledFeatureIds: number[] = [];
  privillegeData.forEach((module: any) => { module.features.forEach((feature: any) => { if (feature.is_enabled) { enabledFeatureIds.push(feature.feature.feature_id) } }) });

  const onSubmit = () => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.post(`/employee/access/${id}`, { feature_ids: enabledFeatureIds }),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => {
            if (err.response && err.response.status === 422) {
              return err.response.data.message || "Bad Request";
            } else {
              return "An error occurred";
            }
          }
        }
      )
      .then(() => {refetchPrivillege()})
      .catch(() => {refetchPrivillege()})
      .finally(resolve)
    })
  }

  return (
    <FormPrivilege 
      action="UPDATE" 
      privillegeData={privillegeData} 
      setPrivillegeData={setPrivillegeData} 
      onSubmit={onSubmit} 
      isLoadingPrivillege={isLoadingPrivillege}
      id={id}
      refetchPrivillege={refetchPrivillege}
      permission={permission}
    />
  )
}