// React
import { useEffect } from "react"

// Components
import { NumberInput } from "components"

// Form
import { Controller, useFormContext, useWatch } from "react-hook-form"

// Utils
import { useBalance } from "utils"
import { FormType } from "../utils"

export function Balance(): JSX.Element {
  // Hooks
  const { refetch } = useBalance("/spendmoney/balance")

  // Form
  const { control, setValue } = useFormContext<FormType>()
  const coa_id = useWatch({
    control,
    name: "coa_id"
  })
  const transaction_date = useWatch({
    control,
    name: "transaction_date"
  })

  useEffect(() => {
    if (coa_id && transaction_date) {
      refetch(coa_id, transaction_date).then((value) => setValue("_balance", value))
    }

    // eslint-disable-next-line
  }, [coa_id, transaction_date])

  return (
    <Controller
      control={control}
      name="_balance"
      render={({ field, fieldState }) => (
        <NumberInput
          disabled
          inputClass="text-right"
          label="BALANCE"
          error={fieldState.error?.message}
          ref={field.ref}
          value={field.value}
          thousandSeparator=","
          decimalSeparator="."
        />
      )}
    />
  )
}