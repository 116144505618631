import { PaymentInvoiceType, SubmitType } from "../../types"

export const submitMap = (data: PaymentInvoiceType): SubmitType => {
  return {
    payment_invoice_id: data.payment_invoice_id ?? NaN,
    invoice_id: data.invoice_id ?? NaN,
    customer_id: data.customer_id,
    location_id: data.location_id,
    trx_code: data.trx_code ?? "",
    transaction_date: data.transaction_date,
    memo: data?.memo,
    address: data?.shipped ?? "",
    referensi: data?.referensi,
    coa_id: data?.coa_id,
    deposit_amount: data?.deposit_amount,
    payment_amount: data?.payment_amount
  }
}
