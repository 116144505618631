// EXCEPTIONS
// #1: ignore type error to check field name is available on the form type

// React
import { Fragment, useEffect } from "react"

// Components
import { Button, Modal } from "components"
import { FormSection } from "./FormSection"

// Form
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

// Icons
import { TbChevronRight } from "react-icons/tb"

// Third-Party Libraries
import toast from "react-hot-toast"

// Types
import type { ErrorFetch } from "types"
import type { FormType } from "../types"

// Utils
import { useApi, useToggle } from "utils"
import { useCode, validationSchema } from "../utils"

export function Create(props: {
	onSuccess: () => void
}) {
	// Hooks
	const { isActive, toggle } = useToggle(false)

	return (
		<Fragment>
			<Button color="primary" onClick={toggle}>
				CREATE NEW <TbChevronRight />
			</Button>

			{isActive && <ModalSection toggle={toggle} onSuccess={props.onSuccess} />}
		</Fragment>
	)
}

export function ModalSection(props: {
	onSuccess: () => void
	toggle: () => void
}) {
	// Hooks
	const api = useApi()
	const { dataCode} = useCode()
	

	useEffect(() => {
	  if (dataCode?.code) {
		methods.setValue("tax_code", dataCode?.code)
	  }
   // eslint-disable-next-line
	}, [dataCode?.code])
  

	// Form
	const defaultValues = {
		tax_code: "",
		tax_name: "",
		type_tax_id: "",
		rate: 0,
		coa_collect_id: "",
		coa_paid_id: "",
		note: ""
	}
	const onSubmit = (value: FormType) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.post("/taxcode/add", value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.detail.message
        }
      ).then(() => {
        props.toggle()
        props.onSuccess()
      }).catch((err: ErrorFetch<{
				detail: {
					loc: string[]
					type: string
				}[]
			}>) => {
				// @ts-ignore
				if (err.response.status === 422) {
					err.response.data.detail.map(item => {
						// @ts-ignore [EXCEPTION #1]
						return methods.setError(item, {
							message: item.loc[1] ?? "",
							type: "custom"
						})
					})
				}
			}).finally(resolve)
    })
  }
	const methods = useForm<FormType>({
		defaultValues,
		resolver: yupResolver(validationSchema)
	})

	return (
		<Modal
			isOpen
			title="SETUP | CREATE TAX CODE"
			closeModal={props.toggle}
		>
			<form onSubmit={methods.handleSubmit(onSubmit)}>
				<FormProvider {...methods}>
					<FormSection />
				</FormProvider>

				<Modal.Footer className="text-end">
					<Button loading={methods.formState.isSubmitting ? "true" : undefined} color="primary">SAVE</Button>
				</Modal.Footer>
			</form>
		</Modal>
	)
}