/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup"
import { useDetailSalesOrder } from "pages/Sales/Transaction/CustomerOrder/utils/hooks"
import { useEffect, useMemo } from "react"
import { FormProvider, useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { useNavigate, useParams } from "react-router-dom"
import { useApi } from "utils"
import { today } from "utils/functions"
import { FormSection } from "./components"
import {
  formPaymentDepositCustomerSchema,
  PaymentDepositCustomerType
} from "./types"
import { submitMap } from "./utils/functions"
import { useDetailPaymentDeposit } from "./utils/hooks"

export default function PaymentDepositEdit() {
  const navigate = useNavigate()
  const api = useApi()
  const params = useParams()
  const depo = useDetailPaymentDeposit({
    deposit_customer_id: params?.deposit_customer_id ?? ""
  })
  const sales = useDetailSalesOrder({
    sales_order_id: depo?.data?.sales_order_id?.toString() ?? ""
  })

  const defaultValues: PaymentDepositCustomerType = useMemo(
    () => ({
      deposit_customer_id: depo.data?.deposit_customer_id ?? NaN,
      sales_order_id: sales.data?.dataso.sales_order_id ?? NaN,
      customer_id: depo.data?.customer_id ?? NaN,
      customer_name: depo.data?.customer_name ?? "",
      location_id: depo.data?.location_id ?? NaN,
      location_name: depo.data?.location_name ?? "",
      coa_id: depo.data?.coa_id ?? NaN,
      coa_name: depo?.data?.coa_name,
      coa_balance: 0,
      link: sales.data?.dataso?.trx_code ?? "",
      project_id: depo.data?.project_id,
      trx_code: depo.data?.trx_code ?? "",
      transaction_date: depo.data?.transaction_date ?? today(),
      amount: depo.data?.amount ?? 0,
      memo: depo.data?.memo ?? "Payment Deposit Customer transaction",
      shipped: depo.data?.address ?? "",
      no_cheque: depo.data?.no_cheque ?? ""
    }),
    [sales.data, depo.data]
  )

  const onSubmit = async (value: PaymentDepositCustomerType) => {
    await toast
      .promise(api.put("/depositcustomer/update", submitMap(value)), {
        loading: "Loading...",
        success: (res) => res.data.message,
        error: (err) =>
          err.response.data.detail?.message ?? err.response.data.message
      })
      .then(() => {
        navigate(-1)
      })
      .catch((err) => {
        throw new Error(err)
      })
  }

  const methods = useForm<PaymentDepositCustomerType>({
    defaultValues,
    resolver: yupResolver(formPaymentDepositCustomerSchema)
  })

  useEffect(() => methods.reset(defaultValues), [sales.data])

  return (
    <FormProvider {...methods}>
      <FormSection isEdit onSubmit={methods.handleSubmit(onSubmit)} />
    </FormProvider>
  )
}
