import { Select } from "components"
import { Controller, useFormContext } from "react-hook-form"
import { FilterReport } from "../../types"
import { filterType, filterTypePL } from "../../utils/vars"

type FilterTypeProps = {
  type: "balanceSheet" | "profitLoss"
}

export const FilterType = ({ type }: FilterTypeProps) => {
  const methods = useFormContext<FilterReport>()
  const options = type === "balanceSheet" ? filterType : filterTypePL

  return (
    <Controller
      name="filter"
      control={methods.control}
      render={({ field }) => (
        <Select
          label="Filter"
          placeholder="Select Filter"
          className="w-full "
          options={options}
          onChange={(filter) => field.onChange(filter?.value)}
          value={options.find((option) => option.value === field.value)}
        />
      )}
    />
  )
}
