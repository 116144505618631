import { PropsWithChildren, useEffect, useState } from "react"
import { DataForm, DataTableContext } from "./DataForm"
import { useVendor, useProductUnit } from "../../../../../FormSection/utils/hooks"
import { convertNumber, useApi } from "utils"
import { Controller, useFieldArray, useFormContext, useWatch } from "react-hook-form"
import { BuyingList } from "pages/Inventori/Transaction/AddNewItem/types"
import { BaseTable, Input, Select } from "components"
import { Vendor } from "./Vendor"
import { EditDataTable } from "./EditDataTable"

const DataTableContextProvider = (props: PropsWithChildren<{}>) => {
    const dropdownVendor = useVendor()
  
    return (
      <DataTableContext.Provider value={{ dropdownVendor }}>
        {props?.children}
      </DataTableContext.Provider>
    )
}

interface DataTableProps {
    action?: "CREATE" | "UPDATE" | "DETAIL" 
    checkLoading?: boolean
    setCheckLoading: ( newState: boolean ) => void
    setBuyingPrice: (newState: string) => void
}
export const DataTable =({ action, checkLoading, setCheckLoading, setBuyingPrice }: DataTableProps) => {
    const api = useApi()
    const { control, setValue } = useFormContext<BuyingList>()
    const { fields, append, remove } = useFieldArray({ control, name: "datavalue" })
    const dataValues = useWatch({ control, name: "datavalue" })
  
    const quantityConvention = control._formValues.quantity_unit_convention
    const [grandTotalAvarage, setGrandTotalAvarage] = useState<string>("")
    const [checkEdit, setCheckEdit] = useState<string>("")
    const type = "name"
  
    const { dataUnit, isLoadingUnit, refetchUnit } = useProductUnit(type)
  
    const average = () => {
      const grandTotal = dataValues?.reduce((prevValue, currentValue) => {
        const checkTotal = currentValue.buying_price ? parseFloat(currentValue.buying_price) : 0
        return prevValue + checkTotal
      }, 0)
  
      return {
        avarage: grandTotal / dataValues?.length,
        avarage_unit: (grandTotal / dataValues?.length) / parseFloat(grandTotalAvarage !== "" ? grandTotalAvarage : quantityConvention),
      }
    }
  
    useEffect(() => {
      setValue("price_unit_measure", average().avarage?.toString())
      setValue("quantity_unit_measure", "1")
      setValue("price_unit_convention", average().avarage_unit?.toString())
      setBuyingPrice(String(average().avarage_unit))
  
      if (checkLoading) {
        const timeout = setTimeout(() => {
          setCheckLoading(false);
          checkLoading && refetchUnit()
        }, 1000);
  
        return () => clearTimeout(timeout);
      }
      // eslint-disable-next-line
    }, [average().avarage, average().avarage_unit, checkLoading, isLoadingUnit])
  
    return (
      <DataTableContextProvider>
        <div className="overflow-x-auto">
          <BaseTable>
            <thead>
              <tr>
                <th>NO</th>
                <th>CODE</th>
                <Vendor action={action} />
                <th>DESCRIPTION</th>
                <th>BUYING PRICE</th>
                <th><div>TAX WHEN<br />BOUGHT</div></th>
                {action !== "DETAIL" && <th>ACTION</th>}
              </tr>
            </thead>
            <tbody>
              {fields?.length === 0 
                ? <tr><td className="text-center" colSpan={action !== "DETAIL" ? 7 : 6}>No Data Available</td></tr> 
                : fields.map((field, key) => <EditDataTable
                    key={field.id} 
                    action={action} 
                    checkEdit={checkEdit}
                    setCheckEdit={setCheckEdit}
                    index={key} 
                    onDeleteItem={() => {
                      remove(key)
                      field?.product_buying_id && api.delete(`/product/deleteproductbuying?product_buying_id=${field?.product_buying_id}`)
                    }} 
                  />)
              }
              {action !== "DETAIL" && <DataForm key={fields.length} method="add" setCheckEdit={setCheckEdit} onAddDataList={append} />}
            </tbody>
          </BaseTable>
        </div>
  
        <div className="flex mt-12">
          <div className="flex items-top w-full">
            <Controller
              control={control}
              name="unit_measure_id"
              render={({ field, fieldState }) => {
                return(
                  <Select
                    label="BUYING UNIT OF MEASURE"
                    controlClass="!rounded-r-none w-full"
                    placeholder="Select unit of measure"
                    isDisabled={action === "DETAIL"}
                    isLoading={isLoadingUnit}
                    error={fieldState.error?.message}
                    options={dataUnit}
                    value={dataUnit.find(item => item.value === field.value?.toString())}
                    onChange={e => field.onChange(e?.value)}
                  />
                )
              }}
            />
            <Controller
              control={control}
              name="quantity_unit_measure"
              render={({ field }) => 
                <Input
                  {...field} 
                  label="QUANTITY" 
                  type="text" 
                  className="w-[150px]"
                  inputClass="!rounded-l-none !rounded-r-none"
                  placeholder="Input price" 
                  value="1"
                  disabled 
                />
              }
            />
            <Controller
              control={control}
              name="price_unit_measure"
              render={({ field }) => 
                <Input
                  {...field}
                  label="AVERAGE"
                  inputClass="!rounded-l-none" 
                  placeholder="Input price" 
                  value={average().avarage ? convertNumber(average()?.avarage)?.intoCurrency : 0}
                  disabled 
                />
              }
            />
          </div>
        </div>
  
        <div className="flex">
          <div className="flex items-top w-full">
            <Controller
              control={control}
              name="unit_convention_id"
              render={({ field, fieldState }) => {
                return(
                <Select
                  label="UNIT CONVENTION"
                  controlClass="!rounded-r-none w-full"
                  placeholder="Select unit convention"
                  isDisabled={action === "DETAIL"}
                  isLoading={isLoadingUnit}
                  error={fieldState.error?.message}
                  options={dataUnit}
                  value={dataUnit.find(item => item.value === field.value?.toString())}
                  onChange={e => field.onChange(e?.value)}
                />
              )}}
            />
            <Controller
              control={control}
              name="quantity_unit_convention"
              render={({ field, fieldState }) => 
                <Input 
                  {...field} 
                  label="QUANTITY" 
                  type="text" 
                  className="w-[150px]"
                  inputClass="!rounded-l-none !rounded-r-none"
                  placeholder="Input quantity" 
                  error={fieldState.error?.message}
                  value={field.value ?? ""}
                  onChange={(e) => {
                    const result = e.target.value.replace(/[^\d.]/g, '')
                    field.onChange(result)
                    setGrandTotalAvarage(result)
                  }}
                  disabled={action === "DETAIL"} 
                />
              }
            />
            <Controller
              control={control}
              name="price_unit_convention"
              render={({ field }) => 
                <Input 
                  {...field} 
                  label="AVERAGE" 
                  inputClass="!rounded-l-none" 
                  placeholder="Input price" 
                  value={average().avarage_unit ? convertNumber(average()?.avarage_unit)?.intoCurrency : 0}
                  onChange={(e) => {
                    const result = e.target.value.replace(/[^\d.]/g, '')
                    field.onChange(result)
                  }}
                  disabled 
                />
              }
            />
          </div>
        </div>
      </DataTableContextProvider>
    )
}