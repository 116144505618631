import * as yup from "yup"

export const detailValidationSchema = yup.object().shape({
  countdetail: yup.array().required().min(1).of(yup.number().required())
})
export const validationSchema = yup.object().shape({
  count_id: yup.number().nullable(),
  employee_id: yup.string().label("Employee").required(),
  location_id: yup.string().label("Location").required(),
  memo: yup.string().label("Memo").required(),
  transaction_date: yup.string().label("Date").required(),
  trx_code: yup.string().defined(),
  countdetail: yup.array().label("List Item").required().min(1).of(
    yup.object().shape({
      product_id: yup.number().defined(),
      mr_unit_id: yup.number().defined(),
      soh_count: yup.number().defined(),
      real_count: yup.number().label("Count").required()
    })
  )
})

export type DetailFormType = yup.InferType<typeof detailValidationSchema>
export type FormType = yup.InferType<typeof validationSchema>