// Components
import { BaseTable, Card, HeaderMenu, Label } from "components"
import { Account, Balance, BalanceBill, Code, JournalList, LinkBill, Location, Memo, PaymentAmount, PaymentDeposit, Referensi, TotalBalance, TransactionDate, Vendor } from "."

export function InputSection(params: {
  buttonsection?: JSX.Element
}): JSX.Element {
  return (
    <section className="container my-5 flex flex-col gap-2">
      <HeaderMenu title="DATA ENTRY | PAYMENT BILL">
        <Code />
      </HeaderMenu>

      <section className="grid lg:grid-cols-2 gap-3">
        <Account />
        <Balance />
      </section>

      <Card>
        <Card.Body className="grid lg:grid-cols-2 items-stretch gap-3">
          <section className="flex flex-col gap-3">
            <section className="grow flex flex-col gap-3">
              <Vendor />
              <Memo />
              <Location />
            </section>

            <div className="justify-self-end">
              <section className="pt-auto">
                <LinkBill />
              </section>
            </div>
          </section>

          <section className="flex flex-col gap-3">
            <Referensi />
            <TransactionDate />
            <BalanceBill />
            <PaymentDeposit />
            <PaymentAmount />
            <TotalBalance />
          </section>
        </Card.Body>
      </Card>

      <Label text="JOURNAL ENTRY" />

      <BaseTable>
        <thead>
          <tr>
            <th>DATE</th>
            <th>ID#</th>
            <th>ACCOUNT</th>
            <th>MEMO</th>
            <th>DEBET</th>
            <th>CREDIT</th>
          </tr>
        </thead>

        <tbody>
          <JournalList.PaymentAccount />
          <JournalList.PaymentDeposit />
          <JournalList.Total />
        </tbody>
      </BaseTable>

      {params.buttonsection}
    </section>
  )
}