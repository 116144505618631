import { SelectOptionType } from "types"

export const filterType: SelectOptionType[] = [
  { value: "0", label: "All" },
  { value: "01", label: "Assets" },
  { value: "02", label: "Liability" },
  { value: "03", label: "Equity" }
]

export const filterTypePL: SelectOptionType[] = [
  { value: "0", label: "All" },
  { value: "04", label: "Income" },
  { value: "05", label: "Cost Of Goods" },
  { value: "06", label: "Expences" },
  { value: "08", label: "Other Income" },
  { value: "09", label: "Other Expences" }
]
