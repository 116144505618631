import { Fragment } from "react"
import { LinkAccountHeaderType } from "../../types"
import { Create } from "./components/Item/components"
import { BaseTable, Input } from "../../../../../../components"
import { useListAccount } from "../../utils"

export function ChildItem() {

    const { data } = useListAccount()

    const coaData: any = {};
    data.forEach(item => {
        coaData[item.linked_account_code] = {
            coa_code: item.coa_code || '',
            coa_name: item.coa_name || '',
            coa_group_code: item.group_coa_code || ''
        }
    })

    const dataAccountLinkSales: LinkAccountHeaderType[] = [
        {
            header: "SALES LINKED ACCOUNT",
            linkAccountGroup: [
                {
                    linked_account_id: 1,
                    linked_account_code: "LA04",
                    linked_account_name: "Sales Linked Account",
                    description: "Asset Account for Tracking AR",
                    account_coa: "1"
                },
                {
                    linked_account_id: 2,
                    linked_account_code: "LA05",
                    linked_account_name: "Sales Linked Account",
                    description: "Asset Bank Account for Customer Receivable",
                    account_coa: "1"
                },
                {
                    linked_account_id: 3,
                    linked_account_code: "LA06",
                    linked_account_name: "Sales Linked Account",
                    description: "Liability Account for Customer Deposit",
                    account_coa: "2"
                },
                {
                    linked_account_id: 4,
                    linked_account_code: "TAXC",
                    linked_account_name: "Sales Linked Account",
                    description: "Liability Tax Collected",
                    account_coa: "2"
                },
                {
                    linked_account_id: 5,
                    linked_account_code: "SA01",
                    linked_account_name: "Sales Linked Account",
                    description: "Income Account For Freight On Sale",
                    account_coa: "4"
                },
                {
                    linked_account_id: 6,
                    linked_account_code: "TRC",
                    linked_account_name: "Sales Linked Account",
                    description: "Liability Sales Tax Allocation",
                    account_coa: "2"
                },
            ]
        }
    ]

    return (
        <Fragment>
            <div className="border">
                {dataAccountLinkSales?.map((data, key) => (
                    <div key={key}>
                        <div className="border p-4">
                            <span className="font-bold">{data.header}</span>
                        </div>
                        <div>
                            <div className="p-4">
                                <BaseTable>
                                    <thead>
                                        <tr>
                                            <th className="border">CODE</th>
                                            <th className="border" colSpan={2}>DESCRIPTION</th>
                                            <th className="border">NUMBER</th>
                                            <th className="border">COA NAME</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.linkAccountGroup?.map((item, index) => (
                                            <tr key={index}>
                                                <td className="border w-[100px]">{item?.linked_account_code}</td>
                                                <td className="border w-[300px]">{item?.description}</td>
                                                <td className="border text-center w-[100px]">
                                                    <Create dataItem={item} coaType={item.account_coa}/>
                                                </td>
                                                <td className="border w-[200px]">{ (coaData[item.linked_account_code]?.coa_group_code) ? `${coaData[item.linked_account_code]?.coa_group_code}-${coaData[item.linked_account_code]?.coa_code}` : ''}</td>
                                                <td className="border w-[200px]">{coaData[item.linked_account_code]?.coa_name}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </BaseTable>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="grid grid-cols-2 mt-6 mb-3">
                <div className="font-bold my-auto">
                    SALE INVOICE FOOTER INFORMATION
                </div>
                <div className="my-auto">
                    <Input inputClass="h-[50px]" onChange={(e) => e.target.value }/>
                </div>
            </div>
        </Fragment>
    )
}