import { useSearchParams } from "react-router-dom";
import { TabTransactionPath } from "../../types";
import { Fragment, useEffect } from "react";
import { TabPurchases, TabNavTransaction, TabSalesOrder, TabInvoice } from "../ChildItem/components/Item/components/FormTransaction";
import { TabBill } from "../ChildItem/components/Item/components/FormTransaction/TabBill";

type TabsSearchParams = { tabs: TabTransactionPath }

export const FormTransaction = (props: { product_id?: number; disable?: boolean }) => {
    const [searchParams, setSearchParams] = useSearchParams({ tabs: "adjustment" } as TabsSearchParams)
    const activeTab = searchParams.get("tabs")
    const setActiveTab = (path: string) => setSearchParams({ tabs: path })

    useEffect(() => {
      if (props?.product_id) {
        localStorage.setItem("prdId", props.product_id.toString());
      }
    }, [props?.product_id])
    
      return (
        <Fragment>
          <section className="container my-5">
            <section className="mt-1">
                <TabNavTransaction activeTab={activeTab!} setActiveTab={setActiveTab} />
                <section className="border-[1px] border-[#3c3c3c] ml-[1px] p-2">
                    {activeTab === "adjustment" && <div />}
                    {activeTab === "count-inventory" && <div />}
                    {activeTab === "transfers" && <div />}
                    {activeTab === "sku-attached" && <div />}
                    {activeTab === "purchases" && <TabPurchases disable={props?.disable} />}
                    {activeTab === "sales-order" && <TabSalesOrder disable={props?.disable} />}
                    {activeTab === "bills" && <TabBill disable={props?.disable} />}
                    {activeTab === "invoice" && <TabInvoice />}
                </section>
            </section>
          </section>
        </Fragment>
      )
}