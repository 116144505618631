/* eslint-disable react-hooks/exhaustive-deps */
import { useFormContext, useWatch } from "react-hook-form"
import { FormReturnItemsType } from "../types"
import { useReturnItemsCode } from "../utils/hooks"

export const CodeReturnItems = ({ isEdit }: { isEdit: boolean }) => {
  const { control, setValue, formState } = useFormContext<FormReturnItemsType>()
  const [transaction_date, trx_code, sales_order_id] = useWatch({
    control,
    name: ["transaction_date", "trx_code", "sales_order_id"]
  })

  useReturnItemsCode({
    key: formState.submitCount,
    type: "next_code",
    trx_date: transaction_date,
    onSuccess: (data) =>
      setTimeout(() => {
        setValue("trx_code", data)
        setValue("referensi", data)
      }, 1000),
    enabled: !isEdit && Boolean(transaction_date) && Boolean(sales_order_id)
  })

  return <div>{trx_code}</div>
}
