// React
import { useContext } from "react"

// Components
import { Select } from "components"

// Contexts
import { AccountContext } from "contexts"
import { DisabledContext } from "../../contexts"

// Form
import { Controller, useFormContext } from "react-hook-form"

// Types
import type { FormType } from "../utils"

export function Account(): JSX.Element {
  // Hooks
  const disabled = useContext(DisabledContext)
  const { data, isLoading } = useContext(AccountContext)

  // Form
  const { control } = useFormContext<FormType>()

  return (
    <Controller
      name="coa_id"
      control={control}
      render={({ field, fieldState }) => (
        <Select
          label="PAYMENT FROM ACCOUNT"
          placeholder="Select Account"
          isDisabled={disabled}
          isLoading={isLoading}
          options={data}
          error={fieldState.error?.message}
          value={data.find(item => item.value === field.value)}
          onChange={e => {
            if (e) {
              field.onChange(e.value)
            }
          }}
        />
      )}
    />
  )
}