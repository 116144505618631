import { useEffect, useState } from "react";
import { useApi } from "../../../../../../utils";
import { LinkAccountPurchaseType} from "../../types";
import { SuccessFetch } from "../../../../../../types";

export function useListLinkAccount() {
    const api = useApi()
    const [data, setData] = useState<LinkAccountPurchaseType[]>([])
    const [isLoading, setLoading] = useState(true)

    useEffect(() => {
        const fetchData = () => api.get("/linkedaccount/")

        fetchData().then((res: SuccessFetch<{
            payload: {
                linked_account_id: number,
                linked_account_code: string,
                linked_account_name: string,
                description: string,
                coa_id: number,
                coa_code: string,
                coa_name: string
            }[]
        }>) => {
            setData(res.data.payload)
        }).catch(() => {
            setData([])
        }).finally(() => {
            setLoading(false)
        })

        return () => {
            setData([])
            setLoading(true)
        }

        // eslint-disable-next-line
    }, [])

    return { data, isLoading }
}