import { Fragment } from "react"
import { useSearchParams } from "react-router-dom"
import { TabBalanceSheet, TabNavReport, TabProfitLoss } from "./components"
import { TabReportPath } from "./types"
import { HeaderMenu } from "components"

type TabsSearchParams = { tabs: TabReportPath }

export default function FinanceRegister() {
  const defaultParams: TabsSearchParams = { tabs: "balance-sheet" }
  const [searchParams, setSearchParams] = useSearchParams(defaultParams)
  const activeTab = searchParams.get("tabs")
  const setActiveTab = (path: string) => setSearchParams({ tabs: path })

  return (
    <Fragment>
      <section className="container my-5">
        <HeaderMenu title="ACCOUNT REPORT" />

        <TabNavReport activeTab={activeTab!} setActiveTab={setActiveTab} />
        {activeTab === "balance-sheet" && <TabBalanceSheet />}
        {activeTab === "profit-loss" && <TabProfitLoss />}
      </section>
    </Fragment>
  )
}
