/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup"
import { useJournalDetail } from "pages/Finance/Journal/utils/hooks"
import { useEffect, useMemo } from "react"
import { FormProvider, useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { useNavigate, useParams } from "react-router-dom"
import { useApi, usePermission } from "utils"
import { FormSection } from "./components"
import { FormType } from "./types"
import { submitMap, totalAmountJournal } from "./utils/function"
import { useCoaList, useProjectList, useTaxCodeList } from "./utils/hooks"
import { validationSchema } from "./utils/vars"

export default function TransferMoneyEdit() {
  const permission = usePermission(["FT03"])
  const api = useApi()
  const navigate = useNavigate()
  const { data_source, data_source_id } = useParams()
  const taxcode = useTaxCodeList()
  const project = useProjectList()
  const coa = useCoaList()
  const journal = useJournalDetail<{
    images: { rec_id: number; file_path: string }[]
  }>({
    data_source: Number(data_source) ?? NaN,
    data_source_id: Number(data_source_id) ?? NaN
  })

  const defaultValues: FormType = useMemo(
    () => ({
      balance: 0,
      amount: 0,
      finance_id: journal.data.finance.finance_id,
      journal_code: journal.data.journal[0]?.journal_code,
      data_source: journal.data.journal[0]?.data_source,
      data_source_id: journal.data.journal[0]?.data_source_id,
      card_id: journal.data.finance.card_id?.toString(),
      datajournal: journal.data.financedetail.map((item) => {
        const currentTax = taxcode.dropdown.find(
          (tax) => tax.value === item.tax_id.toString()
        )
        const currentCoa = coa.data.find((coa) => coa.coa_id === item.coa_id)
        const currentProject = project.dropdown.find(
          (project) => project.value === item.project_id.toString()
        )
        return {
          amount: item.amount,
          coa_id: item.coa_id?.toString(),
          memo: item.memo,
          project_id: item.project_id?.toString(),
          referensi: item.referensi,
          position: item.position,
          tax_id: item.tax_id?.toString(),
          rate: currentTax?.rate ?? 0,
          coa_name: `${currentCoa?.coa_code} ${currentCoa?.coa_name}`,
          project_name: currentProject?.label ?? "",
          tax_coa_collect: currentTax?.tax_coa_collect ?? "",
          tax_coa_collect_id: currentTax?.tax_coa_collect_id.toString() ?? "",
          tax_coa_paid: currentTax?.tax_coa_paid ?? "",
          tax_coa_paid_id: currentTax?.tax_coa_paid_id.toString() ?? "",
          tax_name: currentTax?.label ?? ""
        }
      }),
      group_card: journal.data.finance.group_card?.toString(),
      imgPath: journal.data.images ?? [],
      location_id: journal.data.finance.location_id?.toString(),
      memo: journal.data.finance.memo,
      reconsiled: false,
      reference_no: journal.data.finance.reference_no,
      tax_inclusive: false,
      transaction_date: journal.data.finance.transaction_date,
      trx_code: journal.data.finance.trx_code,
      type_payment: "",
      name_payment: "",
      number_payment: "",
      expired_payment: "",
      authorization_payment: "",
      note_payment: ""
    }),
    [journal.data, taxcode.data, project.data, coa.data]
  )

  const methods = useForm<FormType>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })

  const onSubmit = async (value: FormType) => {
    const totalAmount = totalAmountJournal(value.datajournal)
    if (totalAmount.debet !== totalAmount.credit) {
      return toast.error("Journal is not balance")
    }

    await toast
      .promise(api.put("/transfermoney/update", submitMap(value)), {
        loading: "Loading...",
        success: (res) => res.data.message,
        error: (err) =>
          err.response.data.detail?.message ?? err.response.data.message
      })
      .then(() => {
        methods.reset(defaultValues)
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
        navigate("/finance/journal", { replace: true })
      })
      .catch((err) => {
        throw new Error(err)
      })
  }

  useEffect(() => methods.reset(defaultValues), [defaultValues])

  return (
    <FormProvider {...methods}>
      <FormSection isEdit onSubmit={methods.handleSubmit(onSubmit)} permission={permission} />
    </FormProvider>
  )
}
