import { BaseTable } from "components"
import { useFormContext, useWatch } from "react-hook-form"
import { FormInvoiceType } from "../types"
import { calculateSummaryInvoice } from "../utils/functions"

export const SummaryFooter = () => {
  const { control } = useFormContext<FormInvoiceType>()
  const [freight, invoice_detail] = useWatch({
    control,
    name: ["freight", "invoice_detail"]
  })
  const summary = calculateSummaryInvoice({
    invoice_detail,
    freight
  })

  return (
    <BaseTable className="w-full min-w-[100px] md:max-w-[400px]">
      <tbody className="text-lg  text-end">
        <tr className="border-dotted border-b-2">
          <td className="w-[1px] whitespace-nowrap pr-3 pt-5 uppercase font-bold">
            SUBTOTAL
          </td>
          <td className="pt-5">{summary?.subtotal?.toLocaleString()}</td>
        </tr>
        <tr className="border-dotted border-b-2">
          <td className="w-[1px] whitespace-nowrap pr-3 pt-5 uppercase font-bold">
            FREIGHT
          </td>
          <td className="pt-5"> {summary?.freight?.toLocaleString()}</td>
        </tr>
        <tr className="border-dotted border-b-2">
          <td className="w-[1px] whitespace-nowrap pr-3 pt-5 uppercase font-bold">
            TAX
          </td>
          <td className="pt-5"> {summary?.tax.toLocaleString()}</td>
        </tr>
        <tr className="border-dotted border-b-2">
          <td className="w-[1px] whitespace-nowrap pr-3 pt-5 uppercase font-bold">
            TOTAL AMOUNT
          </td>
          <td className="pt-5"> {summary?.totalAmount?.toLocaleString()}</td>
        </tr>
        <tr className="border-dotted border-b-2">
          <td className="w-[1px] whitespace-nowrap pr-3 pt-5 uppercase font-bold">
            PAYMENT
          </td>
          <td className="pt-5 text-error">
            {" "}
            {summary.payment?.toLocaleString()}
          </td>
        </tr>
        <tr className="border-dotted border-b-2">
          <td className="w-[1px] whitespace-nowrap pr-3 pt-5 uppercase font-bold text-xl">
            BALANCE
          </td>
          <td className="pt-5 text-xl"> {summary?.balance.toLocaleString()}</td>
        </tr>
      </tbody>
    </BaseTable>
  )
}
