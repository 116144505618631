// React
import { Fragment } from "react"

interface List {
  label: string
  list: {
    label: string
    value: string | string[]
  }[]
}

export function HintList(params: {
  bottomList?: List[]
  list: List[]
  title: string
  detail?: string
}): JSX.Element {
  return (
    <Fragment>
      <div className="font-bold text-xl mb-3">{params.title}</div>

      <ul className="pl-4 list-decimal">
        {params.list.map((item, key) => (
          <Fragment key={key}>
            <li className="mt-2 font-bold">{item.label}:</li>

            <ul className="pl-5 list-disc">
              {item.list.map((item, key) => {
                // Vars
                const isString: boolean = typeof(item.value) === "string"

                return (
                  <Fragment>
                    <li key={key}><strong>{item.label}: </strong>{isString && item.value}</li>

                    {!isString && (
                      <ul className="pl-5 list-[circle]">
                        {Array(item.value).map((item, key) => <li key={key}>{item}</li>)}
                      </ul>
                    )}
                  </Fragment>
                )})}
            </ul>
          </Fragment>
        ))}
      </ul>

      <ul className="pl-4 list-none">
        {params.bottomList?.map((item, key) => (
          <Fragment key={key}>
            <li className="-ml-4 mt-2 font-bold">{item.label}:</li>

            <ul className="pl-5 list-disc">
              {item.list.map((item, key) => {
                // Vars
                const isString: boolean = typeof(item.value) === "string"

                return (
                  <Fragment>
                    <li key={key}><strong>{item.label}: </strong>{isString && item.value}</li>

                    {!isString && (
                      <ul className="pl-6 list-[circle]">
                        {Array(item.value).map((item, key) => <li key={key}>{item}</li>)}
                      </ul>
                    )}
                  </Fragment>
                )})}
            </ul>
          </Fragment>
        ))}
      </ul>

      {params.detail && <div className="p-3 overflow-auto lg:block hidden">{params.detail}</div>}
    </Fragment>
  )
}