import { Fragment } from "react"
import { Loading} from "../../../../../../components"
import { Item } from "./components/Item"
import { DataList } from "../../types"

export function ChildItem(props: {
  dataList: any
  isLoading?: boolean
  refetch: () => void 
}) {
  const data: DataList[] = props.dataList?.payload

  return (
    <Fragment>
      {props.isLoading || data.length === 0 ? (
        <tr>
          <td colSpan={9} className="text-center">
            <Loading loading={props.isLoading} errorText="No data available" /> 
          </td>
        </tr>
      ) : data?.map((item, key) => (
        <Item index={key} item={item} dataList={props.dataList} refetch={props.refetch}/>
      ))}
    </Fragment>
  )
}