// Form
import * as yup from "yup"

export const validationSchema = yup.object().shape({
  tax_code: yup.string().label("CODE").required().test('len', 'Must be exactly 3 characters', val => val.toString().length === 3),
  tax_name: yup.string().label("NAME").required(),
  type_tax_id: yup.string().label("TYPE").required(),
  rate: yup.number().label("RATE").required(),
  coa_collect_id: yup.string().label("COA COLLECT").required(),
  coa_paid_id: yup.string().label("COA PAID").required(),
  note: yup.string().label("NOTE").defined()
})