import { BuyingList } from "pages/Inventori/Transaction/AddNewItem/types"
import { Controller, useFormContext } from "react-hook-form"
import { convertNumber, useToggle } from "utils"
import { DataForm } from "./DataForm"
import { ActionButton } from "components"

interface EditDataTableProps {
    action?: "CREATE" | "UPDATE" | "DETAIL"
    index: number
    onDeleteItem: () => void
    checkEdit: string
    setCheckEdit: ( newState: string ) => void
}
export const EditDataTable = ({ action, index, onDeleteItem, setCheckEdit }: EditDataTableProps) => {
    const { isActive, toggle } = useToggle(false)
    const { control } = useFormContext<BuyingList>()
  
    return (
      <Controller
        control={control}
        name={`datavalue.${index}` as "datavalue.0"}
        render={({ field }) => {
          if (isActive) {
            return (
              <DataForm
                defaultValue={field.value}
                method="edit"
                onCancelEditing={toggle}
                setCheckEdit={() => {}}
                onAddDataList={(value) => {
                  field.onChange(value)
                  toggle()
                  setCheckEdit("onEdit")
                }}
              />
            )
          } else {
            const hasParentheses = /\(.*\)/.test(field.value.tax_name);
            return (
              <tr>
                <td className="text-center">{index + 1}</td>
                <td>{field.value.vendor_code ?? "-"}</td>
                <td>{field.value.vendor_name ?? "-"}</td>
                <td>{field.value.vendor_description ?? "-"}</td>
                <td className="text-right w-[80px]">{field?.value?.buying_price ? convertNumber(parseFloat(field?.value?.buying_price))?.intoCurrency : "-"}</td>
                <td>{field.value.tax_name ? hasParentheses ? field.value.tax_name : `${field.value.tax_name} (${field.value.rate}%)` : "-"}</td>
                {action !== "DETAIL" && <td>
                  <section className="flex justify-center">
                    <ActionButton.Update onClick={toggle} />
                    <ActionButton.Delete onClick={onDeleteItem} />
                  </section>
                </td>}
              </tr>
            )
          }
        }}
      />
    )
  }