// React
import { useContext } from "react"

// Components
import { Input } from "components"

// Contexts
import { PoContext } from "../contexts"

export function LinkPurchase(): JSX.Element {
  // Hooks
  const data = useContext(PoContext)

  return (
    <Input
      disabled
      label="LINK TO PURCHASE ID"
      value={data.datapo.trx_code}
    />
  )
}