import { Fragment } from "react";
import { useListAccount } from "../utils";
import { LinkAccountType } from "../types";
import { BaseTable, Loading } from "components";
import { Create } from "./Create";

type AccountHeaderType = {
  header: string,
  linkAccount: Omit<LinkAccountType, "linked_account_id">[]
}

export function TableContent() {
  // Hooks
  const { data, isLoading } = useListAccount()

  // vars
  const dataAccountLink: AccountHeaderType[] = [
    {
      header: "INVENTORY LINKED ACCOUNTS",
      linkAccount: [
        {
          linked_account_code: "LA10",
          description: "Lost & Damaged Goods",
          linked_account_name: "Inventory Linked Accounts",
        }
      ]
    }
  ]

  return (
    <Fragment>
      {isLoading  ? (
        <Loading loading={isLoading} />
      ) : (
        <div className="border">
          {dataAccountLink.map((i, key) => (
            <div key={key}>
              <div className="border p-4">
                <span className="font-bold">{i.header}</span>
              </div>
              <div>
                <div className="p-4">
                  <BaseTable className="table">
                    <thead>
                      <tr>
                        <th className="border">CODE</th>
                        <th className="border" colSpan={2}>DESCRIPTION</th>
                        <th className="border">NUMBER</th>
                        <th className="border">COA NAME</th>
                      </tr>
                    </thead>
                    <tbody>
                      {i.linkAccount.map((item, index) => {
                        // Vars
                        const coa = data.find(list => list.linked_account_code === item.linked_account_code)
                        
                        return (
                          <tr key={index}>
                            <td className="border w-[100px]">{item?.linked_account_code}</td>
                            <td className="border w-[300px]">{item?.description}</td>
                            <td className="border text-center w-[100px]">
                              <Create
                                dataItem={item} 
                              />
                            </td>
                            <td className="border w-[200px]">{coa ? `${coa.group_coa_code}-${coa.coa_code}` : ''}</td>
                            <td className="border w-[200px]">{coa?.coa_name}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </BaseTable>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </Fragment>
  )
}