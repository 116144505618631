import { useEffect, useState } from "react"
import type { SelectOptionType, SuccessFetch } from "types"
import { useApi } from "utils"
import { DataList, DataListTrade } from "../types"
import { SelectedState } from "pages/Purchase/Register/types/ByCard"

export function useList() {
  const api = useApi()
  const [data, setData] = useState<DataList[]>([])
  const [isLoading, setLoading] = useState(false)

  const refetch = (params: Partial<SelectedState>) => {
    setLoading(true)

    api.get("/purchaseorder/by-card", { params: { 
      date_from: params?.date_from, 
      date_end: params?.date_end, 
      card_id: params?.card_id, 
      vendor_id: params?.vendor_id
    } }) 
    .then((res: SuccessFetch<{ payload: DataList[] }>) => setData(res.data.payload))
    .catch(() => setData([]) )
    .finally(() => setLoading(false))
  
    return () => {
      setData([])
      setLoading(true)
    }
  }

  return { data, isLoading, refetch }
}
export function useListTrade() {
  const api = useApi()
  const [dataTrade, setData] = useState<SelectOptionType[]>([])
  const [isLoadingTrade, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)

    api.get(`/vendor/`)
    .then((res: SuccessFetch<{ payload: DataListTrade[] }>) => setData(res.data.payload.map(val => ({ label: val?.vendor_name, value: val?.vendor_id?.toString() }))))
    .catch(() => setData([]) )
    .finally(() => setLoading(false))
  
    return () => {
      setData([])
      setLoading(true)
    }
    // eslint-disable-next-line
  }, [])

  return { dataTrade, isLoadingTrade }
}