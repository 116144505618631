import { BalanceSheetReportType } from "pages/Finance/Report/types"
import { convertNumber } from "utils"

type ItemTotalProps = {
  item: BalanceSheetReportType
}

export const ItemTotal = ({ item }: ItemTotalProps) => {
  const isNormalFontWeight = [3].includes(item.header_level)

  return (
    <tr key={item.coa_id + "total"}>
      <td>
        <span
          style={{ marginLeft: (item.coa_level - 1) * 24 }}
          className={isNormalFontWeight ? "font-normal" : "font-bold"}>
          {`TOTAL ${item.coa_name}`}
        </span>
      </td>

      {item.balance?.map((balance, index) => (
        <td
          key={balance + index}
          className={`text-right ${
            isNormalFontWeight ? "font-normal" : "font-bold"
          }`}>
          {convertNumber(balance).intoCurrency}
        </td>
      ))}
    </tr>
  )
}
