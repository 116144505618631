import { Button, Modal } from "components";
import { Link } from "react-router-dom";

type ModalBankEntryProps = {
  isOpen: boolean;
  onCloseModal: () => void;
};

export const ModalBankEntry = ({
  isOpen,
  onCloseModal
}: ModalBankEntryProps) => {
  return (
    <Modal
      isOpen={isOpen}
      title="Bank Entry"
      size="md"
      closeModal={onCloseModal}>
      <Modal.Body className="pt-2">
        <div className="flex w-full justify-start items-center gap-2">
          <Link
            className="btn rounded-none btn-primary undefined w-1/2 px-0"
            to="/finance/transaction/spend-money"
            target="_blank"
            rel="noopener noreferrer">
            <Button className="w-full" type="button" color="primary">
              Money Out
            </Button>
          </Link>

          <Link
            className="btn rounded-none btn-primary undefined w-1/2 px-0"
            to="/finance/transaction/receive-money"
            target="_blank"
            rel="noopener noreferrer">
            <Button className="w-full" type="button" color="transparent">
              Money In
            </Button>
          </Link>
        </div>
      </Modal.Body>
    </Modal>
  );
};
