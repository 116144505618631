import { Controller, useFormContext } from "react-hook-form"
import { DataList } from "../../types"
import { Input, Textarea } from "components"
import { menuTitle } from "../ChildItem/components/Item/components"

export function FormSection(props: {
    isDetail?: boolean
    isUpdate?: boolean
    action?: string
}) {
    const { control } = useFormContext<DataList>()

    return (
        <section>
            <Controller
                control={control}
                name="mr_comparation_code"
                render={({ field, fieldState }) => 
                    <Input 
                        {...field} 
                        label="CODE" 
                        value={field.value ?? ""} 
                        disabled={props.action === "DETAIL" || props.action === "DELETE"} 
                        error={fieldState.error?.message}    
                    />}
            />

            <Controller
                control={control}
                name="mr_comparation_name"
                render={({ field, fieldState }) => 
                    <Input 
                        {...field} 
                        label={menuTitle.title} 
                        value={field.value ?? ""} 
                        disabled={props.action === "DETAIL" || props.action === "DELETE"} 
                        error={fieldState.error?.message}
                    />}
            />

            <Controller
                control={control}
                name="description"
                render={({ field }) => <Textarea {...field} label="DESCRIPTION" value={field.value ?? ""} disabled={props.action === "DETAIL" || props.action === "DELETE"} />}
            />
        </section>
    )
}