// Types
import type { MenuType } from "../types"

export const financeMenu = {
  menu: [
    {
      label: "REGISTER",
      link: "/finance/register",
      permission: "FR"
    },
    {
      label: "TRANSACTION",
      link: "#",
      permission: "FT",
      children: [
        { label: "SPEND MONEY", link: "/finance/transaction/spend-money", permission: "FT01" },
        { label: "RECEIVE MONEY", link: "/finance/transaction/receive-money", permission: "FT02" },
        { label: "TRANSFER MONEY", link: "/finance/transaction/transfer-money", permission: "FT03" },
        { label: "RECONCILE ACCOUNT", link: "/finance/transaction/reconcile-account", permission: "FT04" },
        { label: "TRANSACTION JOURNAL", link: "/finance/transaction/transaction-journal", permission: "FT05" },
        { label: "DASHBOARD", link: "/finance/transaction/dashboard", permission: "FT06" },
      ]
    },
    {
      label: "REPORT",
      link: "#",
      permission: "FP",
      children: [
        { label: "BANK REGISTER", link: "/finance/report/bank-register", permission: "FP01" },
        { label: "RECONSILITION REPORT", link: "/finance/report/reconsilition-report", permission: "FP02" },
        { label: "CAST FLOW ANALYSIS", link: "/finance/report/cast-flow-analysis", permission: "FP03" },
        { label: "ACCOUNT BALANCE", link: "/finance/report/account-balance", permission: "FP04" },
        { label: "TRANSACTION ENQUERY", link: "/finance/report/enquery", permission: "FP05" },
        { label: "CAST DISBURSEMENT", link: "/finance/report/cast-disbursement", permission: "FP06" },
        { label: "CAST RECEIPTS", link: "/finance/report/cast-receipts", permission: "FP07" },
        { label: "TRANSACTION BY CONTACT", link: "/finance/report/by-contact", permission: "FP08" },
      ]
    },
    {
      label: "JOURNAL",
      link: "/finance/journal",
      permission: "FT" // BELUM
    },
    {
      label: "FIND",
      link: "/finance/find",
      permission: "FT" // BELUM
    }
  ] as MenuType[]
}