// React
import { PropsWithChildren, createContext, useState } from "react"

export const AuthContext = createContext<{
  permission: string[]
  resetData: () => void
  setAuthData: (
    token: string,
    permission: string[]
  ) => void
  token: string
}>({
  permission: [],
  token: "",
  resetData() {},
  setAuthData(token, permission) {}
})

export function AuthProvider(params: PropsWithChildren<{}>): JSX.Element {
  // Vars
  const [token, setToken] = useState<string>(localStorage.getItem("token") ?? "")
  const [permission, setPermission] = useState<string[]>(localStorage.getItem("access") ? JSON.parse(localStorage.getItem("access")!) : [])

  const setAuthData = (
    token: string,
    permission: string[]
  ): void => {
    setToken(token)
    setPermission(permission)
  }

  const resetData = (): void => {
    setToken("")
    setPermission([])
  }

  return (
    <AuthContext.Provider
      value={{
        permission,
        token,
        resetData,
        setAuthData
      }}
    >
      {params.children}
    </AuthContext.Provider>
  )
}