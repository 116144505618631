import { Label, NumberInput, Select } from "components";
import { useCoaList } from "pages/Finance/Transaction/GeneralEntry/utils/hooks";
import { Ref } from "react";
import { SingleValue } from "react-select";
import { SelectOptionType } from "types";

type DropdownAccountProps = {
  ref?: Ref<unknown>;
  label?: string;
  error?: string;
  isLoading?: boolean;
  value: string | null;
  onChange?: (item: SingleValue<SelectOptionType>) => void;
  currentBalance?: number;
  isDefaultAll?: boolean;
  displayBalance?: boolean;
};

export function DropdownAccount({
  ref,
  label,
  error,
  isLoading,
  onChange,
  currentBalance,
  isDefaultAll,
  displayBalance = true
}: DropdownAccountProps) {
  const { dropdown } = useCoaList();
  const filterByCheque = dropdown.filter((item) => item.cheque_coa);
  const filterWithDefault = [...filterByCheque, { value: "0", label: "All" }];
  const options = isDefaultAll ? filterWithDefault : filterByCheque;

  return (
    <section className="w-full">
      {label ? <Label text={label} error={Boolean(error)} /> : null}

      <section className="flex gap-3 w-full">
        <div className={`w-full md:${displayBalance ? "w-5/12" : "w-full"}`}>
          <Select
            className="grow"
            placeholder="Select Account"
            error={error}
            isLoading={isLoading}
            defaultValue={isDefaultAll ? { value: "0", label: "All" } : null}
            options={options}
            ref={ref}
            onChange={(item: any) => {
              if (onChange) {
                onChange(item);
              }
            }}
          />
        </div>

        {displayBalance && (
          <div className="w-full md:w-3/12">
            <NumberInput disabled textRight value={currentBalance} />
          </div>
        )}
      </section>
    </section>
  );
}
