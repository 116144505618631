import { Modal } from 'components';
import { Fragment, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useToggle } from 'utils';
import { PicturesType } from '../types';


export function PicturesSection(props: {
    items: number
    dataPicture?: PicturesType[]
    className?: string 
}) {
    const { isActive, toggle } = useToggle(false)
    const [imageDetail, setImageDetail] = useState<string>()
    const { REACT_APP_BASE_URL_API } = process.env

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: props.items,
            partialVisibilityGutter: 40,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            partialVisibilityGutter: 30,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            partialVisibilityGutter: 30,
        },
    };

    const selectedImage = (value: string) => {
        setImageDetail(value)
        toggle()
    }
    return (
        <Fragment>
            <div className="my-4 mx-6">
                <Carousel
                    additionalTransfrom={0}
                    arrows={true}
                    autoPlaySpeed={3000}
                    centerMode={false}
                    className="w-full text-center"
                    containerClass=""
                    dotListClass=""
                    draggable
                    focusOnSelect={false}
                    infinite
                    itemClass=""
                    keyBoardControl
                    minimumTouchDrag={80}
                    pauseOnHover
                    renderArrowsWhenDisabled={false}
                    renderButtonGroupOutside={false}
                    renderDotsOutside={false}
                    responsive={responsive}
                    rewind={false}
                    rewindWithAnimation={false}
                    rtl={false}
                    shouldResetAutoplay
                    showDots={false}
                    sliderClass=""
                    slidesToSlide={1}
                    swipeable
                    customLeftArrow={<div className='absolute top-[50%] transform translate-y-[-50%] left-[10px] z-[5] px-2 py-2 bg-slate-300 bg-opacity-25 cursor-pointer rounded-lg ml-2'>❮</div>}
                    customRightArrow={<div className='absolute top-[50%] transform translate-y-[-50%] right-[10px] z-[5] px-2 py-2 bg-slate-300 bg-opacity-25 cursor-pointer rounded-lg mr-2'>❯</div>}
                >
                    {props?.dataPicture?.map((item, i) => (
                        <div className={`image-full ${props.className} p-2`}>
                            <img
                                src={`${REACT_APP_BASE_URL_API}/getimage/?imgpath=${item?.path}`}
                                alt=""
                                onClick={() => {
                                    selectedImage(item?.path)
                                }} className='cursor-pointer w-full h-[150px] object-cover' 
                            />
                        </div>
                    ))}
                </Carousel>
            </div>
            {isActive && <DetailImage toggle={toggle} image={imageDetail} />}
        </Fragment>
    )
}

function DetailImage(props: {
    toggle: () => void,
    image: string | undefined
}) {
    const { REACT_APP_BASE_URL_API } = process.env
    return (
        <Modal isOpen title='' size='lg' closeModal={props.toggle}>
            <Modal.Body>
                <img src={`${REACT_APP_BASE_URL_API}/getimage/?imgpath=${props?.image}`} alt="" className='w-full' />
            </Modal.Body>
        </Modal>
    )
}