// React
import { useEffect, useState } from "react"

// Types
import type { SuccessFetch } from "types"

// Utils
import { useApi } from "utils"

interface ListFetch {
  journal_id: number
  transaction_date: string
  reconsiled: boolean
  source_memo: string
  source_code: string
  trx_code: string
  coa_id: number
  coa_code: string
  coa_name: string
  memo: string
  amount: number
  position: number
  job_id: string
  data_source: number
  data_source_id: number
  journal_code: string
}

interface ListType extends ListFetch {
  debit: number | null
  credit: number | null
}

export function useDataJournal(
  data_source: string,
  date: string,
  journal_code: string
) {
  // Hooks
  const api = useApi()
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState<ListType[]>([])

  useEffect(() => {
    setLoading(true)

    api.postForm("/journal/getjournaldata", {
      DataSource: data_source,
      EndDate: date,
      StartDate: date
    }).then((res: SuccessFetch<{ payload: ListFetch[] }>) => {
      // Vars
      const payload: ListType[] = res.data.payload.filter(item => {
        return item.journal_code === journal_code
      }).map(item => {
        return {
          ...item,
          debit: item.position === 1 ? item.amount : null,
          credit: item.position === 2 ? item.amount : null
        }
      })

      // Functions
      const getAmount = (position_value: "debit" | "credit") => {
        // Vars
        const position_list = {
          debit: 1,
          credit: 2
        }

        return payload.reduce((acc, { amount, position }) => {
          if (position === position_list[position_value]) {
            return acc + amount
          }

          return acc
        }, 0)
      }

      if (payload.length > 0) {
        // Vars
        const firstData = payload[0]
        const credit = getAmount("credit")
        const debit = getAmount("debit")

        payload.unshift(firstData)
        payload.push({
          ...firstData,
          credit,
          debit
        })
      }

      setData(payload)
    }).catch(() => {
      setData([])
    }).finally(() => {
      setLoading(false)
    })

    return () => {
      setData([])
      setLoading(false)
    }

    // eslint-disable-next-line
  }, [])

  return { data, isLoading }
}