// React
import { Fragment, useEffect } from "react"

// Form
import { useFormContext } from "react-hook-form"

// Utils
import type { FormType } from "../../utils"
import { useDataCode } from "./utils"

export function Code(params: { value?: string }) {
  // Hooks
  const { data, refetch } = useDataCode()

  // Form
  const { formState, setValue } = useFormContext<FormType>()
  
  useEffect(() => {
    if (!params.value && formState.isSubmitSuccessful) {
      refetch()
    }

    // eslint-disable-next-line
  }, [formState])

  useEffect(() => {
    if (!params.value && data) {
      setValue("referensi", data)
    }

    // eslint-disable-next-line
  }, [data])
  
  return <Fragment>{params.value ?? data}</Fragment>
}