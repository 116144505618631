import { Select, Button, Textarea } from "../../../../../../components"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import type { AddressList } from "../../types"
import { useCountry, useProvince, useRegency, useSubdistrict, useVillage } from "../../../../../Setup/Preference/Location/components/FormSection/utils"
import { useEffect } from "react"
interface AddressProps {
  action?: "CREATE" | "UPDATE" | "DETAIL"
  onSubmit: (newState: any) => void
  isActiveSubmit?: boolean
  permission?: boolean
}

export const FormAddress:React.FC<AddressProps> = ({ action, onSubmit, isActiveSubmit, permission }) => {
  const { control, getValues, handleSubmit, formState } = useFormContext<AddressList>()

  return (
    <div>
      <section>
        <Country isDisabled={Boolean(action === "DETAIL")} />
        <Province isDisabled={Boolean(action === "DETAIL")} />
        <Regency isDisabled={Boolean(action === "DETAIL")} />
        <Subdistrict isDisabled={Boolean(action === "DETAIL")} />
        <Village isDisabled={Boolean(action === "DETAIL")} />
        <Controller
          control={control}
          name="address"
          render={({ field }) => <Textarea {...field} label="ADDRESS" placeholder="Input address" value={field.value ?? ""} disabled={action === "DETAIL"} />}
        />
      </section>
      {action !== "DETAIL" &&
        <div className="flex justify-end">
          <Button 
            type="button"
            className="!none mt-5" 
            color="primary"
            loading={formState.isSubmitting ? "true" : undefined}
            permission={"CT021"}
            onClick={handleSubmit(() => onSubmit(getValues()),(err)=> console.log(err))}
            disabled={isActiveSubmit}
          >
            SAVE
          </Button>
        </div>
      }
    </div>
  )
}

function Country(props: { isDisabled: boolean }) {
  const { control } = useFormContext<AddressList>()
  const { data, isLoading } = useCountry()

  return (
    <Controller
      control={control}
      name="country_id"
      render={({ field, fieldState }) => (
        <Select
          label="COUNTRY"
          placeholder="Choose country"
          isDisabled={props.isDisabled}
          isLoading={isLoading}
          error={fieldState.error?.message}
          options={data}
          value={data.find(item => item.value === field.value)}
          onChange={e => field.onChange(e?.value)}
        />
      )}
    />
  )
}
function Province(props: { isDisabled: boolean }) {
  const { control } = useFormContext<AddressList>()
  const country_id = useWatch({
    control,
    name: "country_id"
  })
  const { data, isLoading, refetch } = useProvince()

  useEffect(() => {
    refetch(country_id)
    // eslint-disable-next-line
  }, [country_id])

  return (
    <Controller
      control={control}
      name="province_id"
      render={({ field, fieldState }) => (
        <Select
          label="PROVINCE"
          placeholder="Choose province"
          isDisabled={props.isDisabled}
          isLoading={isLoading}
          error={fieldState.error?.message}
          options={data}
          value={data.find(item => item.value === field.value)}
          onChange={e => field.onChange(e?.value)}
        />
      )}
    />
  )
}
function Regency(props: { isDisabled: boolean }) {
  const { control } = useFormContext<AddressList>()
  const province_id = useWatch({
    control,
    name: "province_id"
  })
  const { data, isLoading, refetch } = useRegency()

  useEffect(() => {
    refetch(province_id)
    // eslint-disable-next-line
  }, [province_id])

  return (
    <Controller
      control={control}
      name="regency_id"
      render={({ field, fieldState }) => (
        <Select
          label="REGENCY"
          placeholder="Choose regency"
          isDisabled={props.isDisabled}
          isLoading={isLoading}
          error={fieldState.error?.message}
          options={data}
          value={data.find(item => item.value === field.value)}
          onChange={e => field.onChange(e?.value)}
        />
      )}
    />
  )
}
function Subdistrict(props: { isDisabled: boolean }) {
  const { control } = useFormContext<AddressList>()
  const regency_id = useWatch({
    control,
    name: "regency_id"
  })
  const { data, isLoading, refetch } = useSubdistrict()

  useEffect(() => {
    refetch(regency_id)
    // eslint-disable-next-line
  }, [regency_id])

  return (
    <Controller
      control={control}
      name="subdistrict_id"
      render={({ field, fieldState }) => (
        <Select
          label="SUBDISTRICT"
          placeholder="Choose subdistric"
          isDisabled={props.isDisabled}
          isLoading={isLoading}
          error={fieldState.error?.message}
          options={data}
          value={data.find(item => item.value === field.value)}
          onChange={e => field.onChange(e?.value)}
        />
      )}
    />
  )
}
function Village(props: { isDisabled: boolean }) {
  const { control } = useFormContext<AddressList>()
  const subdistrict_id = useWatch({
    control,
    name: "subdistrict_id"
  })
  const { data, isLoading, refetch } = useVillage()

  useEffect(() => {
    refetch(subdistrict_id)
    // eslint-disable-next-line
  }, [subdistrict_id])

  return (
    <Controller
      control={control}
      name="village_id"
      render={({ field, fieldState }) => (
        <Select
          label="VILLAGE"
          placeholder="Choose village"
          isDisabled={props.isDisabled}
          isLoading={isLoading}
          error={fieldState.error?.message}
          options={data}
          value={data.find(item => item.value === field.value)}
          onChange={e => field.onChange(e?.value)}
        />
      )}
    />
  )
}