// Icons
import { TbEye, TbEyeOff } from "react-icons/tb"

// Third-Party Libraries
import { Controller, useFormContext } from "react-hook-form"

// Types
import type { FormType } from "../types"

// Utils
import { useToggle } from "../../../utils"

export function Password(props: { error?: string, label?: string, textWhite?: boolean }) {
  // Hooks
  const { isActive, toggle } = useToggle(false)

  // Variables
  const Icon = isActive ? TbEye : TbEyeOff

  // Form
  const { control, setValue } = useFormContext<FormType>()

  return (
    <Controller
      control={control}
      name="passwd"
      render={({ field, fieldState }) => (
        <section className="lg:ml-3">
          <label className="label">
            <span className={`label-text font-bold ${props?.textWhite && "!text-[#cacccb]"} ${props.error && "text-error"}`}>{props.label}</span>
          </label>
          <div className={`join w-full lg:w-[260px] ${fieldState.error && "border-error border-2"}`}>
            <input 
              {...field} 
              type={isActive ? "text": "password"} 
              className="grow input input-bordered join-item text-black"
              placeholder="Password"
              onChange={(e) => {
                setValue('passwd', e?.target?.value)
                setValue('confirmPasswd', e?.target?.value)
              }}
            />

            <button
              type="button"
              title={isActive ? "Show Password" : "Hide Password"}
              className="btn-ghost join-item bg-white p-2 border-0"
              onClick={toggle}
            >
              <Icon className="text-black" size={24} />
            </button>
          </div>

          {fieldState.error && (
            <div className="label">
              <span className="label-text text-error">{fieldState.error.message}</span>
            </div>
          )}
        </section>
      )}
    />
  )
}