// Third-Party Libraries
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"

// Utils
import { useApi, useToggle } from "utils"

export function useDelete(journal_code: string) {
  // Hooks
  const api = useApi()
  const navigate = useNavigate()
  const { isActive, setActive } = useToggle(false)

  const deleteData = () => {
    setActive(true)

    toast.promise(
      api.delete("/spendmoney/delete", { params: { JournalCode: journal_code } }),
      {
        loading: "Loading...",
        success: (res) => res.data.message,
        error: (err) => err.response.data.message
      }
    ).then(() => {
      navigate("/finance/journal", { replace: true })
    }).catch(() => {}).finally(() => {
      setActive(false)
    })
  }

  return {
    deleteData,
    isLoading: isActive
  }
}