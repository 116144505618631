import { JournalPaymentDepositCustomer } from "../../types"

type GenerateJournalPaymentDepositCustomer = {
  coa_la06: string
  coa_payment: string
  date: string
  trx_code: string
  balance: number
  amount: number
  memo?: string
}

export const generateJournalPaymentDepositCustomer = ({
  coa_la06,
  coa_payment,
  date,
  trx_code,
  balance,
  amount,
  memo
}: GenerateJournalPaymentDepositCustomer): JournalPaymentDepositCustomer[] => {
  const mapCoa = (coa: string, isDebet: boolean, amount: number) => {
    return {
      coa,
      date,
      idm: trx_code,
      memo: memo ?? "",
      debet: isDebet ? amount : 0,
      credit: isDebet ? 0 : amount
    }
  }

  return [mapCoa(coa_payment, true, amount), mapCoa(coa_la06, false, amount)]
}
