import { Card, DateInput, Input, Textarea } from "components"
import moment from "moment"
import { APPROVAL } from "pages/Sales/Register/utils/vars"
import { DropdownEmployee } from "pages/Sales/Transaction/CustomerOrder/components"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { FormTransferItemsType } from "../types/FormTransferItems"
import { DropdownLocation } from "./DropdownLocation"

type FormTransferItemsProps = {
  approvalView?: boolean
}

export const FormTransferItems = ({ approvalView }: FormTransferItemsProps) => {
  const { control } = useFormContext<FormTransferItemsType>()
  const approval_status = useWatch({
    control: control,
    name: "approval_status"
  })

  return (
    <Card className="border-transparent">
      <Card.Body className="grid lg:grid-cols-2 gap-x-6 gap-y-3">
        <section className="flex flex-col gap-1">
          <DropdownLocation
            label="FROM LOCATION"
            name="location_from_id"
            approvalView={approvalView}
          />

          <DropdownLocation
            label="TO LOCATION"
            name="location_to_id"
            approvalView={approvalView}
          />

          <Controller
            control={control}
            name="memo"
            render={({ field, fieldState }) => (
              <Textarea
                {...field}
                label="MEMO"
                defaultValue={field.value}
                disabled={approval_status !== APPROVAL.PENDING || approvalView}
                onChange={(e) => {
                  field.onChange(e.target.value)
                }}
                error={fieldState.error?.message}
              />
            )}
          />
        </section>

        <section className="flex flex-col gap-1">
          <DropdownEmployee approvalView={approvalView} />

          <Controller
            control={control}
            name="transaction_date"
            render={({ field, fieldState }) => (
              <DateInput
                label="DATE"
                disabled={approval_status !== APPROVAL.PENDING || approvalView}
                error={fieldState.error?.message}
                selected={moment(field.value).toDate()}
                onChange={(date) => {
                  const selectedDate: string = moment(date).format("YYYY-MM-DD")
                  field.onChange(selectedDate)
                }}
              />
            )}
          />

          <Controller
            control={control}
            name="transfer_status"
            render={({ field, fieldState }) => (
              <Input
                disabled
                label="STATUS"
                defaultValue={field.value === 2 ? "CLOSED" : "OPEN"}
                error={fieldState.error?.message}
              />
            )}
          />
        </section>
      </Card.Body>
    </Card>
  )
}
