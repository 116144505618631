import { BaseTable } from "components"
import { DataList } from "../types"
import { InfoSection } from "./InfoSection"

interface ReceivableTableProps {  data?: DataList[] }

export const ReceivableTable:React.FC<ReceivableTableProps> = ({ data }) => (
    <div>
        <div><b>ACCOUNT RECEIVABLE</b></div>
        <div className="lg:w-[45%] ml-5"><InfoSection className="!justify-start" labelClass="lg:ml-5" label="Turnover Ratio" value={data?.[0]?.trade_name} /></div>

        <BaseTable>
            <thead>
                <tr>
                    <th>INV ID#</th>
                    <th>CUSTOMER NAME</th>
                    <th>BALANCE</th>
                    <th>7 DAYS</th>
                    <th>15 DAYS</th>
                    <th>21 DAYS</th>
                    <th>30+ DAYS</th>
                </tr>
            </thead>
            <tbody>
                { data?.length === 0 ? <tr><td className="text-center" colSpan={9}><small>No Data Available</small></td></tr> : data?.map((val: any) => (
                    <tr>
                        <td className="w-[200px]">{val?.coa_code ?? "-"}</td>
                        <td className="w-[100px]">{val?.coa_code ?? "-"}</td>
                        <td>{val?.coa_code ?? "-"}</td>
                        <td className="w-[200px]">{val?.coa_code ?? "-"}</td>
                        <td className="w-[200px]">{val?.coa_code ?? "-"}</td>
                        <td className="w-[300px]">{val?.coa_code ?? "-"}</td>
                        <td>{val?.coa_code ?? "-"}</td>
                    </tr>
                ))}
            </tbody>
        </BaseTable>
    </div>
  )