import { useTaxCodeList } from "pages/Finance/Transaction/GeneralEntry/utils/hooks"
import { Product } from "pages/Sales/Transaction/CustomerOrder/types"
import { SuccessFetch } from "types"
import { useApi } from "utils"
import { FormInvoiceDetailType, JournalList } from "../../types"
import { calculateTotalInvoiceDetail } from "./calculateTotalInvoiceDetail"

type GenerateJournalInvoice = {
  api: ReturnType<typeof useApi>
  taxes: ReturnType<typeof useTaxCodeList>['data']
  freight: number
  date: string
  trx_code: string
  invoiceDetails: FormInvoiceDetailType[]
}

type ProductAvgPriceUse = {
  payload: { avg_price_use: number }
}

type ProductSelling = {
  payload: Product[]
}

export const generateJournalInvoice = async ({ taxes, freight, date, trx_code, invoiceDetails, api }: GenerateJournalInvoice): Promise<JournalList[]> => {
  const coa_la04 = await api.get('linkedaccount/bycode', {
    params: {
      linked_account_code: "LA04"
    }
  }).then(res => {
    const data = res.data.payload?.[0]
    return `${data.group_coa_code}-${data.coa_code} ${data.coa_name}`
  })

  const coa_sa01 = await api.get('linkedaccount/bycode', {
    params: {
      linked_account_code: "SA01"
    }
  }).then(res => {
    const data = res.data?.payload[0]
    return `${data.group_coa_code}-${data.coa_code} ${data.coa_name}`
  })

  const mapCoa = (coa: string, isDebet: boolean, amount: number) => {
    return {
      date, idm: trx_code, coa, debet: isDebet ? amount : 0, credit: isDebet ? 0 : amount
    }
  }

  let invoiceJournal: JournalList[] = await invoiceDetails?.reduce(async (accPromise, detail) => {
    const acc = await accPromise;
    const total = detail.total
    const delivered = detail.quantity_invoice ?? 0
    const calcInvoiceDetail = calculateTotalInvoiceDetail(detail)

    const params = { product_id: detail.product_id }
    const avg_price_use = await api.get("/product/avg_price_use", { params }).then((res: SuccessFetch<ProductAvgPriceUse>) => res.data.payload.avg_price_use ?? 0)
    const quantity_unit_sell_convention = await api.get("/product/selling", { params }).then((res: SuccessFetch<ProductSelling>) => res.data.payload?.[0].quantity_unit_sell_convention ?? 0)
    const quantity_unit = delivered * quantity_unit_sell_convention
    const total_coa_asset = avg_price_use * quantity_unit

    let bsi = await api.get('/probill/coabsi_byproduct', { params: { product_id: detail.product_id } }).then((res) => {
      const payload = res.data.payload

      const coa_buy_code: boolean = Boolean(payload.coa_buy_code && payload.coa_buy_code !== "-")
      const coa_inv_code: boolean = Boolean(payload.coa_inv_code && payload.coa_inv_code !== "-")
      const coa_sell_code: boolean = Boolean(payload.coa_sell_code && payload.coa_sell_code !== "-")

      const coa_sell = `${payload.coa_sell_code} ${payload.coa_sell_name}`
      const coa_buy = `${payload.coa_buy_code} ${payload.coa_buy_name}`
      const coa_asset = `${payload.coa_inv_code} ${payload.coa_inv_name}`

      if (Boolean(coa_buy_code && coa_inv_code && coa_sell_code)) {
        return [
          mapCoa(coa_la04, true, total),
          mapCoa(coa_sell, false, total),
          mapCoa(coa_buy, true, total_coa_asset),
          mapCoa(coa_asset, false, total_coa_asset),
        ]
      } else if (Boolean(coa_buy_code && coa_sell_code)) {
        return [
          mapCoa(coa_la04, true, total),
          mapCoa(coa_sell, false, total),
        ]
      } else if (Boolean(coa_sell_code && coa_inv_code)) {
        return [
          mapCoa(coa_la04, true, total),
          mapCoa(coa_sell, false, total),
          mapCoa(coa_buy, true, total_coa_asset),
          mapCoa(coa_asset, false, total_coa_asset),
        ]
      } else if (Boolean(coa_sell_code)) {
        return [
          mapCoa(coa_la04, true, total),
          mapCoa(coa_sell, false, total),
        ]
      }
    })

    if ((detail.tax_rate ?? 0) > 0) {
      const selectedTax = taxes.find(tax => tax.tax_id === detail.tax_id)
      const coa_collect = `${selectedTax?.coa_collect_grp_code}-${selectedTax?.coa_collect_coa_code} ${selectedTax?.coa_collect_coa_name}`
      const tax = calcInvoiceDetail.totalTax

      bsi = bsi?.concat([
        mapCoa(coa_la04, true, tax),
        mapCoa(coa_collect, false, tax),
      ]).flat()
    }

    return acc.concat(bsi || []);
  }, Promise.resolve<JournalList[]>([]))

  if (freight > 0) {
    invoiceJournal = invoiceJournal.concat([
      mapCoa(coa_la04, true, freight),
      mapCoa(coa_sa01, false, freight),
    ]).flat()
  }

  return invoiceJournal;
}
