import { Fragment, useContext } from "react"
import { ActionButton, Button, Loading, Modal } from "../../../../../../../../../components"
import { FormSection } from "../../../../FormSection"
import * as yup from "yup"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import toast from "react-hot-toast"
import type { DataList } from "../../../../../types"
import { useApi, useToggle } from "../../../../../../../../../utils"
import { Render } from "../../../../../utils"
import { useDetail } from "../utils"
import { random } from "uniqolor"

export function Update(props: { id: DataList, refetch: () => void }) {
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <ActionButton.Update className="join-item" onClick={toggle} />
      {isActive && <ModalSection id={props.id} toggle={toggle} refetch={props.refetch}/>}
    </Fragment>
  )
}

function ModalSection(props: { id: DataList; toggle: () => void; refetch: () => void }) {
  const { data, dataError, isLoading } = useDetail()

  return (
    <Modal isOpen title="EDIT LOCATION" size="xl" closeModal={props.toggle}>
      {isLoading || dataError ? (
        <Loading errorText={dataError} loading={isLoading} />
      ) : (
        <DataForm data={data!} id={props.id} toggle={props.toggle} refetch={props.refetch} />
      )}
    </Modal>
  )
}

function DataForm(props: { data: DataList; id: DataList; toggle: () => void; refetch: () => void }) {
  const api = useApi()
  const { toggle } = useContext(Render)

  type CreateData = Omit<DataList, "location_id" | "country_name" | "province_name" | "regency_name" | "subdistrict_name" | "village_name"> 

  const defaultValues: CreateData= {
    location_name: props?.data.location_name ?? "",
    address: props?.data.address ?? "",
    description: props?.data.description ?? "",
    country_id: Number(props?.data?.country_id) ?? "",
    province_id: Number(props?.data?.province_id) ?? "",
    regency_id: Number(props?.data?.regency_id) ?? "",
    subdistrict_id: Number(props?.data?.subdistrict_id) ?? "",
    village_id: Number(props?.data?.village_id) ?? "",
    color: props?.data?.color ?? random().color,
  }
  const validationSchema = yup.object().shape({ location_name: yup.string().label("Location name").required() })
  const onSubmit = ( value: CreateData ) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.put(`/location/edit?location_id=${props.id.location_id}`, 
        { 
          location_name: value.location_name, 
          address: value.address,
          country_id: value.country_id,
          province_id: value.province_id,
          regency_id: value.regency_id,
          subdistrict_id: value.subdistrict_id,
          village_id: value.village_id,
          description: value.description, 
          color: value.color, 
        }),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message
        }
      ).then(() => {
        props.toggle()
        props?.refetch && props.refetch()
        toggle()
      }).catch(() => {}).finally(resolve)
    })
  }
  const methods = useForm<CreateData>({
    defaultValues: defaultValues,
    resolver: yupResolver(validationSchema)
  })

  return (
    <FormProvider {...methods}>
      {/* @ts-ignore */}
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormSection isUpdate />

        <Modal.Footer className="text-end">
          <Button type="submit" color="primary" loading={methods.formState.isSubmitting ? "true" : undefined}>SAVE</Button>
        </Modal.Footer>
      </form>
    </FormProvider>
  )
}