/* eslint-disable react-hooks/exhaustive-deps */
import { HeaderMenu, NumberInput } from "components"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { useEffect } from "react"
import { useBalance, useToggle } from "utils"
import { PaymentDepositCustomerType } from "../types/PaymentDepositCustomer"
import { CodeDeposit } from "./CodeDeposit"
import { CommandButtons } from "./CommandButtons"
import { FormPaymentDeposit } from "./FormPaymentDeposit"
import { TableJournalEntry } from "./Tables"
import { DropdownAccount } from "./DropdownAccount"
import { ModalDelete } from "./ModalDelete"

export type FormSectionProps = {
  approvalView?: boolean
  isEdit?: boolean
  onSubmit: () => void
}

export const FormSection = ({
  approvalView,
  isEdit = false,
  onSubmit }: FormSectionProps) => {
  const navigate = useNavigate()
  const methods = useFormContext<PaymentDepositCustomerType>()
  const modalDelete = useToggle(false)
  const { refetch } = useBalance("/spendmoney/balance")
  const [
    coa_id,
    deposit_customer_id,
    transaction_date,
    trx_code,
    no_cheque,
    memo,
    amount
  ] = useWatch({
    control: methods.control,
    name: [
      "coa_id",
      "deposit_customer_id",
      "transaction_date",
      "trx_code",
      "no_cheque",
      "memo",
      "amount"
    ]
  })

  const deleteData = {
    deposit_customer_id: deposit_customer_id ?? NaN,
    no_cheque: no_cheque ?? "",
    transaction_date: transaction_date ?? "",
    trx_code: trx_code ?? "",
    memo: memo ?? "",
    amount: amount ?? 0
  }

  useEffect(() => {
    if (coa_id && transaction_date) {
      ; (async () => {
        const coaBalance = await refetch(coa_id.toString(), transaction_date)

        methods.clearErrors("amount")
        methods.setValue("coa_balance", coaBalance ?? 0)
      })()
    }
  }, [coa_id, transaction_date])

  return (
    <section className="container my-5 flex flex-col gap-5">
      <HeaderMenu title="DATA ENTRY | PAYMENT (DEPOSIT)">
        <CodeDeposit isEdit={isEdit} />
      </HeaderMenu>

      <section className="grid lg:grid-cols-2 gap-x-6 gap-y-3">
        <section className="w-full">
          <Controller
            control={methods.control}
            name="coa_id"
            render={({ field, fieldState }) => (
              <DropdownAccount
                approvalView
                value={field.value.toString()}
                displayBalance={false}
                label="PAYMENT FROM ACCOUNT"
                error={fieldState.error?.message}
                onChange={async (data) => {
                  field.onChange(data?.value)
                }}
              />
            )}
          />
        </section>

        <section className="lg:justify-self-end self-end mb-3">
          <Controller
            control={methods.control}
            name="coa_balance"
            render={({ field }) => (
              <NumberInput
                disabled
                textRight
                label="BALANCE"
                value={field.value}
              />
            )}
          />
        </section>
      </section>

      <FormPaymentDeposit approvalView isEdit={isEdit} />

      <section>
        <TableJournalEntry />
      </section>

      <CommandButtons
        approvalView={approvalView}
        showDeleteButton={isEdit}
        onSave={onSubmit}
        onDelete={() => modalDelete.setActive(true)}
      />

      <ModalDelete
        modalState={modalDelete}
        data={deleteData}
        onSuccess={() => {
          modalDelete.setActive(false)
          navigate("/sales/register", { replace: true })
        }}
      />
    </section>
  )
}
