import { FormInvoiceType } from "../../types"
import { calculateTotalInvoiceDetail } from "./calculateTotalInvoiceDetail"

type CalculateSummaryInvoice = Pick<FormInvoiceType, 'invoice_detail' | "freight">

type ReturnCalculateSummaryInvoice = {
  subtotal: number
  tax: number,
  freight: number
  totalAmount: number
  payment: number
  balance: number
}

export const calculateSummaryInvoice = ({ invoice_detail, freight }: CalculateSummaryInvoice): ReturnCalculateSummaryInvoice => {
  const summary = invoice_detail?.reduce((acc, item) => {
    const calculateItem = calculateTotalInvoiceDetail(item)

    acc.subtotal += calculateItem.finalPrice
    acc.tax += calculateItem.totalTax

    return acc
  }, {
    subtotal: 0,
    tax: 0,
  })

  const totalAmount = (summary?.subtotal ?? 0) + (freight ?? 0) + (summary?.tax ?? 0)

  const payment = 0

  const balance = totalAmount - payment

  return {
    subtotal: summary?.subtotal ?? 0,
    tax: summary?.tax ?? 0,
    freight: freight ?? 0,
    totalAmount,
    payment,
    balance
  }
}