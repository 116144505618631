import { Fragment } from "react"
import { useSearchParams } from "react-router-dom"
import { TabRegisterPath } from "./types"
import { HeaderMenu } from "components"
import { RegisterAgedPayable, RegisterAllBills, TabNavRegister } from "./components"
import { RegisterByCard } from "./components/TabByCard"
import { RegisterByLocation } from "./components/TabLocation"
import { RegisterByReferenceId } from "./components/TabReference"
import { ByDateRange } from "./components/TabByDateRange"

type TabsSearchParams = {
  tabs: TabRegisterPath
}

export default function PurchaseRegister() {
  const [searchParams, setSearchParams] = useSearchParams({
    tabs: "by-date-range"
  } as TabsSearchParams)

  const activeTab = searchParams.get("tabs")

  const setActiveTab = (path: string) => setSearchParams({ tabs: path })

  return (
    <Fragment>
      <section className="container my-5">
        <HeaderMenu title="PURCHASE ORDER REGISTER" />

        <section className="mt-1">
          <TabNavRegister activeTab={activeTab!} setActiveTab={setActiveTab} />
          {activeTab === "by-date-range" && <ByDateRange />}
          {activeTab === "by-card" && <RegisterByCard />}
          {activeTab === "by-location" && <RegisterByLocation />}
          {activeTab === "reference-id" && <RegisterByReferenceId />}
          {activeTab === "all-bills" && <RegisterAllBills />}
          {activeTab === "aged-payable" && <RegisterAgedPayable />}
          {/* {activeTab === "reconcilliation" && <RegisterReconciliation />} */}
        </section>
      </section>
    </Fragment>
  )
}
