/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";
import { SuccessFetch } from "types";
import { useApi } from "utils";
import { JournalDetail } from "../../types";

type UseJournalDetail<T> = {
  data_source: number;
  data_source_id: number;
  onSuccess?: (data: JournalDetail & T) => void;
};

type Response<T> = {
  payload: JournalDetail & T;
};

export function useJournalDetail<T>({
  data_source,
  data_source_id,
  onSuccess
}: UseJournalDetail<T>) {
  const api = useApi();
  const defaultState: any = {
    finance: {} as JournalDetail["finance"],
    journal: [],
    financedetail: [],
  };
  const [data, setData] = useState<JournalDetail & T>(defaultState);
  const [isLoading, setLoading] = useState(false);

  const fetchData = () => {
    setLoading(true);

    api
      .postForm("/journal/getjournaldetail", { data_source, data_source_id })
      .then((res: SuccessFetch<Response<T>>) => {
        const data = res.data.payload ?? defaultState;
        onSuccess?.(data);
        setData(data);
      })
      .catch(() => setData(defaultState as JournalDetail & T))
      .finally(() => setLoading(false));
  };

  const refetch = () => fetchData();

  useEffect(() => {
    if (data_source && data_source_id) {
      fetchData();
    }

    return () => {
      setData(defaultState as JournalDetail & T);
      setLoading(false);
    };
  }, [data_source, data_source_id]);

  return { data, isLoading, refetch };
}
