import { Fragment, useContext } from "react"
import { ActionButton, Button, Loading, Modal } from "../../../../../../../../../components"
import { FormProvider, useForm } from "react-hook-form"
import toast from "react-hot-toast"
import type { PersonalList } from "../../../../../types"
import { useApi, useToggle } from "../../../../../../../../../utils"
import { Render } from "../../../../../utils"
import { useDetail } from "../utils"
import { IoIosWarning } from "react-icons/io";

export function Delete(props: { 
  id: PersonalList 
  refetch?: () => void
  permission?: boolean
}) {
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <ActionButton.Delete permission={"CR014"} className="join-item" onClick={toggle} />
      {isActive && <ModalSection id={props.id} toggle={toggle} refetch={props.refetch} />}
    </Fragment>
  )
}

function ModalSection(props: {
  id: PersonalList
  toggle: () => void
  refetch?: () => void
}) {
  const { data, dataError, isLoading } = useDetail()

  return (
    <Modal isOpen title="DELETE EMPLOYEE REGISTRATION" size="xl" closeModal={props.toggle}>
      {isLoading || dataError ? (
        <Loading errorText={dataError} loading={isLoading} />
      ) : (
        <DataForm data={data!} id={props.id} toggle={props.toggle} refetch={props.refetch} />
      )}
    </Modal>
  )
}

function DataForm(props: {
  data: PersonalList
  id: PersonalList
  toggle: () => void
  refetch?: () => void
}) {
  const api = useApi()
  const { toggle } = useContext(Render)

  const onSubmit = (
    // @ts-ignore
    value: PersonalList
  ) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.delete("/employee/delete", { params: { employee_id: props.id.employee_id } }),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message
        }
      ).then(() => {
        props.toggle()
        toggle()
        props.refetch && props.refetch()
      }).catch(() => {}).finally(resolve)
    })
  }
  const methods = useForm({ defaultValues: props.data })

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <h5 className="text-error">Are you sure want to delete this data?</h5>

        <IoIosWarning className="mx-auto mt-5 text-[200px] text-primary" />

        <Modal.Footer className="text-end">
          <Button type="submit" color="error" loading={methods.formState.isSubmitting ? "true" : undefined}>Delete</Button>
        </Modal.Footer>
      </form>
    </FormProvider>
  )
}