// React
import { Fragment, useContext, useState } from "react"

// Components
import { ActionButton, BaseTable, Button, Loading, PermissionLink } from "components"
import { TabSection } from "pages/Purchase/Transaction/components"

// Contexts
import { PurchaseOrderContext } from "../../../contexts"

// Icons
import { IoSendSharp } from "react-icons/io5"

// Third-Party Libraries
import moment from "moment"
import { Link, useParams } from "react-router-dom"

// Utils
import { convertNumber, useToggle } from "utils"
import { useBillList, usePaymentVendorList } from "../../../utils"
import { DeleteModal } from "pages/Purchase/Transaction/PaymentBill/components"

export function PaymentBill(): JSX.Element {
  // Hooks
  const [currentTab, setCurrentTab] = useState<number>(0)

  return (
    <section className="mt-1">
      <TabSection
        activeIndex={currentTab}
        list={["BILL", "PAYMENT BILL"]}
        onChangeTab={setCurrentTab}
      />

      {currentTab === 0 ? (
        <Bill />
      ) : currentTab === 1 ? (
        <PaymentDeposit />
      ) : (
        <Fragment />
      )}
    </section>
  )
}

function Bill(): JSX.Element {
  // Hooks
  const { disabled } = useContext(PurchaseOrderContext)
  const { id } = useParams()
  const { data, isLoading } = useBillList(id!)

  // Vars
  const acc = data.reduce((acc, item) => {
    return {
      depo: acc.depo + item.depo,
      payment: acc.payment + item.payment,
      total: acc.total + item.total
    }
  }, {
    depo: 0,
    payment: 0,
    total: 0
  })

  return (
    <BaseTable>
      <thead>
        <tr>
          <th rowSpan={2}>NO.</th>

          <th
            rowSpan={2}
            colSpan={2}
          >
            BILL ID#
          </th>

          <th rowSpan={2}>DATE</th>
          <th rowSpan={2}>STATUS</th>
          <th rowSpan={2}>DELIVERY</th>
          <th rowSpan={2}>MEMO</th>
          <th rowSpan={2}>SUBTOTAL</th>
          <th colSpan={2}>PAYMENT</th>
          <th rowSpan={2}>BALANCE</th>
          <th rowSpan={2}>ACTION</th>
        </tr>

        <tr>
          <th>DEPO</th>
          <th>PAYMENT</th>
        </tr>
      </thead>

      {isLoading || !data ? (
        <tbody>
          <tr>
            <td colSpan={12}>
              <Loading loading={isLoading} />
            </td>
          </tr>
        </tbody>
      ) : (
        <tbody>
          {data.map((item, key) => {
            // Vars
            const balance: number = item.total - item.payment - item.depo
            const isDisabled = balance <= 0

            return (
              <tr key={key}>
                <td className="text-center">{key + 1}</td>
                <td>
                  {!disabled && <IoSendSharp />}
                </td>
                <td>{item.trx_code}</td>
                <td>{moment(item.transaction_date).format("DD/MM/YYYY")}</td>
                <td>{item.bill_status}</td>
                <td>Delivery #{item.delivery_no}</td>
                <td>{item.memo}</td>
                <td className="text-right">{convertNumber(item.total).intoCurrency}</td>
                <td className="text-right">{convertNumber(item.depo).intoCurrency}</td>
                <td className="text-right">{convertNumber(item.payment).intoCurrency}</td>
                <td className="text-right">{convertNumber(balance).intoCurrency}</td>
                <td className="text-center">
                  {!disabled && (
                    <PermissionLink to={isDisabled ? "#" : `/purchase/transaction/payment-bill/${item.bill_id}`}>
                      <Button
                        color="primary"
                        disabled={isDisabled}
                      >
                        {isDisabled ? "LUNAS" : "PAYMENT"}
                      </Button>
                    </PermissionLink>
                  )}
                </td>
              </tr>
            )
          })}
        </tbody>
      )}

      <tfoot>
        <tr>
          <th
            className="text-center"
            colSpan={7}
          >
            TOTAL (PO)
          </th>

          <td className="text-right">{convertNumber(acc.total).intoCurrency}</td>
          <td className="text-right">{convertNumber(acc.depo).intoCurrency}</td>
          <td className="text-right">{convertNumber(acc.payment).intoCurrency}</td>
          <td className="text-right">{convertNumber(acc.total - acc.depo - acc.payment).intoCurrency}</td>
          <th></th>
        </tr>
      </tfoot>
    </BaseTable>
  )
}

function PaymentDeposit(): JSX.Element {
  // Hooks
  const { id } = useParams()
  const { data, isLoading, refetch } = usePaymentVendorList(id!)

  // Vars
  const acc = data.reduce((acc, item) => {
    // Vars
    const deposit_amount: number = acc.deposit_amount + item.deposit_amount
    const payment_amount: number = acc.payment_amount + item.payment_amount

    return {
      deposit_amount,
      payment_amount,
      total: deposit_amount + payment_amount
    }
  }, {
    deposit_amount: 0,
    payment_amount: 0,
    total: 0
  })

  return (
    <BaseTable>
      <thead>
        <tr>
          <th rowSpan={2}>NO.</th>

          <th
            rowSpan={2}
            colSpan={2}
          >
            PAYMENT BILL ID
          </th>

          <th rowSpan={2}>DATE</th>
          <th rowSpan={2}>STATUS</th>
          <th rowSpan={2}>REF BILL ID#</th>
          <th rowSpan={2}>MEMO</th>
          <th colSpan={2}>PAYMENT</th>
          <th rowSpan={2}>TOTAL PAYMENT BILL</th>
          <th rowSpan={2}>ACTION</th>
        </tr>

        <tr>
          <th>DEPO</th>
          <th>PAYMENT</th>
        </tr>
      </thead>

      {isLoading || !data ? (
        <tbody>
          <tr>
            <td colSpan={12}>
              <Loading loading={isLoading} />
            </td>
          </tr>
        </tbody>
      ) : (
        <tbody>
          {data.map((item, key) => {
            // Vars
            const deposit_amount: number = item.deposit_amount
            const payment_amount: number = item.payment_amount
            const total: number = deposit_amount + payment_amount

            return (
              <tr key={key}>
                <td className="text-center">{key + 1}</td>
                <td className="text-center">
                  <Link to={`/purchase/transaction/payment-bill/edit/${item.payment_bill_id}`}>
                    <IoSendSharp />
                  </Link>
                </td>
                <td>{item.trx_code}</td>
                <td>{moment(item.transaction_date).format("DD/MM/YYYY")}</td>
                <td>{item.bill_status}</td>
                <td>{item.ref_bill_id}</td>
                <td>{item.memo}</td>
                <td className="text-right">{convertNumber(deposit_amount).intoCurrency}</td>
                <td className="text-right">{convertNumber(payment_amount).intoCurrency}</td>
                <td className="text-right">{convertNumber(total).intoCurrency}</td>
                <td className="text-center">
                  {item.approve_status === 1 && (
                    <Delete
                      payment_bill_id={item.payment_bill_id}
                      onSuccess={refetch}
                    />
                  )}
                </td>
              </tr>
            )
          })}
        </tbody>
      )}

      <tfoot>
        <tr>
          <th
            className="text-center"
            colSpan={7}
          >
            TOTAL
          </th>

          <td className="text-right">{convertNumber(acc.deposit_amount).intoCurrency}</td>
          <td className="text-right">{convertNumber(acc.payment_amount).intoCurrency}</td>
          <td className="text-right">{convertNumber(acc.total).intoCurrency}</td>
          <th></th>
        </tr>
      </tfoot>
    </BaseTable>
  )
}

function Delete(params: {
  payment_bill_id: string
  onSuccess: () => void
}): JSX.Element {
  // Hooks
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <ActionButton.Delete onClick={toggle} />

      {isActive && (
        <DeleteModal
          payment_bill_id={params.payment_bill_id}
          toggle={toggle}
          onSuccess={params.onSuccess}
        />
      )}
    </Fragment>
  )
}