import { ErrorMessage } from "@hookform/error-message"
import { BaseTable, NumberInput } from "components"
import { Controller, useFieldArray, useFormContext } from "react-hook-form"
import { FormReceivedType } from "../../../types"

type TableReceivedProps = {
  approvalView?: boolean
  display?: boolean
}

export const TableReceived = ({
  approvalView = false,
  display = true
}: TableReceivedProps) => {
  const methods = useFormContext<FormReceivedType>()

  const { fields } = useFieldArray({
    control: methods.control,
    name: "received_detail"
  })

  return (
    <div className={`${display ? "" : "hidden"}`}>
      <BaseTable>
        <thead className="uppercase">
          <tr>
            <th>-</th>
            <th>Item Code</th>
            <th>Description</th>
            <th>Unit</th>
            <th>Ship</th>
            <th>Received</th>
            <th>Reject</th>
          </tr>
        </thead>

        <tbody>
          {fields.map((arrField, key) => {
            return (
              <Controller
                key={arrField.id}
                control={methods.control}
                name={`received_detail.${key}`}
                render={({ field, formState }) => {
                  return (
                    <tr>
                      <td className="text-center px-0">{key + 1}</td>
                      <td>{field.value.product_barcode}</td>
                      <td>{field.value.product_name}</td>
                      <td>{field.value.mr_unit_name}</td>
                      <td className="text-right">
                        {field.value.total_quantity_transfer}
                      </td>
                      <td className="max-w-[100px] text-right">
                        {approvalView ? field.value.quantity_received :
                          <NumberInput
                            textRight
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale={2}
                            ref={field.ref}
                            defaultValue={field.value.quantity_received}
                            onValueChange={(value) => {
                              field.onChange({
                                ...field.value,
                                quantity_received: value.floatValue,
                                quantity_reject:
                                  (field.value.total_quantity_transfer ?? 0) -
                                  (value.floatValue ?? 0)
                              })
                            }}
                            error={
                              formState.errors.received_detail?.[key]
                                ?.quantity_received?.message
                            }
                          />
                        }
                      </td>
                      <td className="text-right">
                        {field.value.quantity_reject}
                      </td>
                    </tr>
                  )
                }}
              />
            )
          })}
        </tbody>
      </BaseTable>

      <ErrorMessage
        errors={methods.formState.errors}
        name="sodetail"
        render={({ message }) => (
          <div className="mt-2">
            <p className="text-red-500">{message}</p>
          </div>
        )}
      />
    </div>
  )
}
