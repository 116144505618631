// React
import { useEffect, useState } from "react"

// Types
import type { SuccessFetch } from "types"
import type { DataType } from "../../types"

// Utils
import { useApi } from "utils"

export function useProductLocation(id: number): {
  data: DataType[]
  isLoading: boolean
} {
  // Hooks
  const api = useApi()
  const [data, setData] = useState<DataType[]>([])
  const [isLoading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    api.get(
      `/product/location/${id}`
    ).then((res: SuccessFetch<{
      payload: DataType[]
    }>) => {
      setData(res.data.payload)
    }).catch(() => {
      setData([])
    }).finally(() => {
      setLoading(false)
    })

    return () => {
      setData([])
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [])

  return { data, isLoading }
}