// Form
import * as yup from "yup"

export const validationSchema = yup.object().shape({
  _balance: yup.number().defined(),
  coa_id: yup.string().label("Account").required(),
  transaction_date: yup.string().label("Receive Date").required(),
  trx_code: yup.string().defined(),
  vendor_id: yup.string().defined(),
  memo: yup.string().label("Memo").required(),
  referensi: yup.string().label("Referensi").required(),
  deposit_amount: yup.number().label("Payment from Deposit").required(),
  payment_amount: yup.number().label("Payment Amount").required()
})

export type FormType = yup.InferType<typeof validationSchema>