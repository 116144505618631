/* eslint-disable react-hooks/exhaustive-deps */
import { useFormContext, useWatch } from "react-hook-form"
import { FormSalesOrderType } from "../types"
import { useSalesOrderCode } from "../utils/hooks"
export const CodeCustomerOrder = ({ isEdit }: { isEdit: boolean }) => {
  const { control, setValue, formState } = useFormContext<FormSalesOrderType>()
  const [transaction_date, trx_code] = useWatch({
    control,
    name: ["transaction_date", "trx_code"]
  })

  useSalesOrderCode({
    key: formState.submitCount,
    type: "next_code",
    trx_date: transaction_date,
    onSuccess: (data) => {
      setValue("trx_code", data)
      setValue("referensi", data)
    },
    enabled: !isEdit
  })

  return <div>{trx_code}</div>
}
