// React
import { Fragment } from "react"

// Components
import { ActionButton, Loading, Modal } from "../../../../../../components"
import { FormSection } from "../../FormSection"

// Form
import { FormProvider, useForm } from "react-hook-form"

// Types
import type { FormType } from "../../../types"

// Utils
import { useToggle } from "../../../../../../utils"
import { useDetail } from "../utils"

export function Detail(props: { id: number; }) {
  // Hooks
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <ActionButton.Detail permission={"AR012"} className="join-item" onClick={toggle} />
      {isActive && <ModalSection id={props.id} toggle={toggle} />}
    </Fragment>
  )
}

function ModalSection(props: {
  id: number
  toggle: () => void
}) {
  // Hooks
  const { data, dataError, isLoading } = useDetail(props.id)

  return (
    <Modal isOpen title="DETAIL CHART OF ACCOUNT" size="xl" closeModal={props.toggle}>
      {isLoading || dataError ? (
        <Loading errorText={dataError} loading={isLoading} />
      ) : (
        <DataForm data={data!} id={props.id} toggle={props.toggle} />
      )}
    </Modal>
  )
}

function DataForm(props: {
  data: FormType
  id: number
  toggle: () => void
}) {
  // Form
  const methods = useForm({
    defaultValues: props.data
  })

  return (
    <FormProvider {...methods}>
      <FormSection isDetail />
    </FormProvider>
  )
}