/* eslint-disable react-hooks/exhaustive-deps */
import { Card, CommandButton } from "components"
import moment from "moment"
import { useFilter } from "../../utils/hooks"
import { Fragment, useEffect, useState } from "react"
import { SuccessFetch } from "types"
import { useApi, usePermission, useToggle } from "utils"
import {
  thisMonth,
  today,
} from "utils/functions"
import { RegisterDateType } from "../../types"
import { FilterByDate } from "../FilterByDate"
import { FooterSummary } from "../FooterSummary"
import { TableRegister } from "../TableRegister"

type Response = {
  payload: Array<RegisterDateType>
}

export function RegisterDateRange() {
  const api = useApi()
  const todayDate = today()
  const thisMonthDate = thisMonth()
  const [data, setData] = useState<RegisterDateType[]>([])
  const isLoading = useToggle(false)
  const permission = usePermission(["SR01"])

  const { filter, handleChangeFilter } = useFilter({
    dateFrom: thisMonthDate.fromDate,
    dateTo: todayDate,
    Approval: "0",
    Delivered: "0"
  })

  const fetch = () => {
    isLoading.setActive(true)

    const params = {
      DateFrom: filter.dateFrom,
      DateEnd: filter.dateTo,
      Approval: filter.Approval,
      Delivered: filter.Delivered
    }

    api
      .postForm("/salesorder/getdata", null, { params })
      .then((res: SuccessFetch<Response>) => {
        const resData = res.data.payload
        setData(resData)
      }).finally(() => isLoading.setActive(false))
  }

  useEffect(() => fetch(), [filter])

  return (
    <Fragment>
      <section className="mt-2">
        <Card>
          <Card.Body>
            <FilterByDate
              dateFrom={filter.dateFrom}
              dateTo={filter.dateTo}
              onChangeDateFrom={(dateFrom) => {
                const isValid = moment(dateFrom).isBefore(filter.dateTo)
                handleChangeFilter({
                  dateFrom,
                  dateTo: isValid ? filter.dateTo : dateFrom
                })
              }}
              onChangeDateTo={(dateTo) => handleChangeFilter({ dateTo })}
              onChangeApproval={(approval) => handleChangeFilter({ Approval: approval })}
              onChangeDelivered={(delivered) => handleChangeFilter({ Delivered: delivered })}
            />
          </Card.Body>
        </Card>
      </section>

      <section className="mt-2">
        <TableRegister
          data={data}
          filter={filter}
          isLoading={isLoading.isActive}
          fetchList={fetch}
          permission={permission}
        />
      </section>

      <section className="mt-2">
        <Card>
          <Card.Body className="flex flex-row gap-5 justify-between items-center">
            <section className="w-8/12 flex flex-wrap justify-between items-center gap-3">
              <section className="flex flex-wrap gap-3">
                <CommandButton actiontype="help" />
                <CommandButton actiontype="print" />
                <CommandButton actiontype="email" />
                <CommandButton actiontype="export" />
              </section>
            </section>

            <section className="flex gap-3 ">
              <FooterSummary data={data} dateFrom={filter.dateFrom} />
            </section>
          </Card.Body>
        </Card>
      </section>
    </Fragment>
  )
}
