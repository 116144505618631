import * as yup from "yup"
import { formReceivedDetailSchema } from "./FormReceivedDetailType"

export type FormReceivedType = yup.InferType<typeof formReceivedSchema>

export const formReceivedSchema = yup.object().shape({
  received_id: yup.number().label("Received").optional(),
  transfer_id: yup.number().label("Transfer Items").required(),
  transfer_date: yup.string().label("Transfer Transaction Date").defined(),
  transfer_memo: yup.string().label("Transfer Memo").defined(),
  transfer_link: yup.string().label("Transfer Link").defined(),
  transfer_employee_id: yup.number().label("Transfer Employee").defined(),
  trx_code: yup.string().label("Code").optional(),
  location_from_id: yup
    .number()
    .label("Location From")
    .typeError("Location From is required")
    .required(),
  location_from_name: yup.string().label("Location From").optional(),
  location_to_id: yup
    .number()
    .label("Location To")
    .typeError("Location From is required")
    .required(),
  location_to_name: yup.string().label("Location To").optional(),
  employee_id: yup
    .number()
    .label("Employee")
    .typeError("Employee is required")
    .required(),
  employee_name: yup.string().label("Employee").optional(),
  transaction_date: yup.string().label("Transaction Date").required(),
  memo: yup.string().label("Memo").required(),
  approved_by: yup.string().label("Approved By").optional(),
  approved_by_name: yup.string().label("Approved By").defined(),
  approval_status: yup.number().label("Approval Status").defined(),
  received_status: yup.number().label("Received Status").optional(),
  approval_status_text: yup.string().label("Approval Status").defined(),
  received_detail: yup.array().of(formReceivedDetailSchema).min(1).required()
})
