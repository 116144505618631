import { Button, DateInput } from "components"
import moment from "moment"
import { FilterReport } from "pages/Finance/Report/types"
import { Fragment, RefObject } from "react"
import ReactDatePicker from "react-datepicker"
import { useFormContext } from "react-hook-form"
import { IoCalendarOutline } from "react-icons/io5"
import { useToggle } from "utils"
import { prevMonth, prevQuarter, prevYear, today } from "utils/functions"
import { getDateTypeLabelBS } from "../../../../utils/functions"

export type DateProps = {
  buttonDateRef: RefObject<HTMLDivElement>
  inputDateRef: RefObject<ReactDatePicker>
  toggleButtonDate: ReturnType<typeof useToggle>
  toggleReadOnly: ReturnType<typeof useToggle>
}

export const Date = ({
  toggleButtonDate,
  buttonDateRef,
  toggleReadOnly,
  inputDateRef
}: DateProps) => {
  const methods = useFormContext<FilterReport>()
  const [dateType, date] = methods.watch(["dateType", "date"])
  const isDisableDatePick = dateType !== "1"
  const defaultButtonDateOption: {
    label: FilterReport["dateType"]
    value: string
  }[] = [
    { label: "1", value: today() },
    { label: "2", value: today() },
    { label: "3", value: prevMonth().toDate },
    { label: "4", value: prevQuarter().toDate },
    { label: "5", value: prevYear().toDate }
  ]

  return (
    <Fragment>
      <div className="flex justify-start items-end">
        <div>
          <label className="label">
            <span className="label-text font-bold ">Date</span>
          </label>
          <Button
            type="button"
            color="primary"
            className="rounded-l-lg text-start text-ellipsis text-nowrap w-[88px]"
            onClick={() =>
              toggleButtonDate.setActive(!toggleButtonDate.isActive)
            }>
            {getDateTypeLabelBS(dateType)}
          </Button>
        </div>

        <DateInput
          ref={inputDateRef}
          readOnly={toggleReadOnly.isActive}
          disabled={isDisableDatePick}
          className="rounded-l-none rounded-r-none w-[130px]"
          selected={moment(date).toDate()}
          onFocus={(e) => e.target.blur()}
          onInputClick={() => toggleReadOnly.setActive(true)}
          onChange={(date) => {
            const selectedDate = moment(date).format("YYYY-MM-DD")

            toggleReadOnly.setActive(true)
            methods.setValue("date", selectedDate)
            methods.setValue("dateType", "1")
          }}
        />

        <div className="mt-[26px]">
          <Button
            type="button"
            color="ghost"
            disabled={isDisableDatePick}
            className="rounded-l-none rounded-r-lg border-1 border-gray-300 border-l-0"
            onClick={() => {
              if (inputDateRef.current) {
                toggleReadOnly.setActive(false)
                inputDateRef.current.setState({
                  ...inputDateRef.current.state,
                  focused: false,
                  open: true
                })
              }
            }}>
            <IoCalendarOutline fontSize={18} />
          </Button>
        </div>
      </div>

      {toggleButtonDate.isActive && (
        <div
          tabIndex={toggleButtonDate.isActive ? 1 : 0}
          onBlurCapture={() => toggleButtonDate.setActive(false)}
          ref={buttonDateRef}
          className="absolute w-full flex flex-col border justify-start border-gray-100 top-[5.7rem] z-10 bg-white dark:bg-black rounded-lg">
          {defaultButtonDateOption.map((date) => (
            <Button
              key={date.value}
              className="flex justify-between items-center"
              type="button"
              color="ghost"
              onClick={() => {
                methods.setValue("date", date.value)
                methods.setValue("dateType", date.label)
                toggleButtonDate.setActive(false)
              }}>
              <span>{getDateTypeLabelBS(date.label)}</span>
              <span>{moment(date.value).format("DD/MM/YYYY")}</span>
            </Button>
          ))}
        </div>
      )}
    </Fragment>
  )
}
