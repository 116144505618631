import { BaseTable } from "components";

export function Payment() {
  return (
    <BaseTable>
      <thead>
        <tr>
          <th></th>
          <th>ACCOUNT ID</th>
          <th>ID NUMBER</th>
          <th>DATE</th>
          <th>PAYMENT AMOUNT</th>
          <th>REFERENCES</th>
          <th>MEMO</th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td colSpan={7} />
        </tr>
      </tbody>
    </BaseTable>
  )
}