import { DateInput, Select } from "components";
import { Fragment, useState } from "react";
import { useCustomer } from "../utils";
import { FilterType } from "../types";
import moment from "moment";

export function Filter(props: {
    selected: FilterType,
    setSelected: (data: FilterType) => void
}) {
    const { dataCustomer, isLoadingCustomer } = useCustomer()
    const [selectCard, setSelectedCard] = useState<string>('')

    const cardDropdown = [{ value: "1", label: "CUSTOMER" }]
    const allOption = { value: "", label: "ALL" }

    return (
        <Fragment>
            <section className="grid lg:grid-cols-4 gap-2">
                <DateInput
                    label="DATE FROM"
                    onChange={(date) => {
                        props.setSelected({ ...props?.selected, start_date: moment(date).format("YYYY-MM-DD") })
                    }}
                    selected={props.selected?.start_date ? moment(props.selected.start_date).toDate() : null}
                />

                <DateInput
                    label="DATE END"
                    onChange={(date) => {
                        props.setSelected({ ...props?.selected, end_date: moment(date).format("YYYY-MM-DD") })
                    }}
                    selected={props.selected?.end_date ? moment(props.selected?.end_date).toDate() : null}
                />

                <Select
                    label="CARD"
                    options={[allOption, ...cardDropdown?.map((item: any) => ({ value: item?.value, label: item?.label }))]}
                    onChange={(e) => { setSelectedCard(e?.value ?? '') }}
                    value={cardDropdown?.find((item: any) => item?.value === selectCard)}
                />

                <Select
                    label="TRADER"
                    isLoading={isLoadingCustomer}
                    value={props.selected?.customer_id !== "" ? [allOption, ...dataCustomer].find((item) => item.value?.toString() === props.selected?.customer_id?.toString()) : allOption}
                    options={[allOption, ...dataCustomer]}
                    onChange={(e) => { props.setSelected({ ...props.selected, customer_id: e?.value }) }}
                />
            </section>
        </Fragment>
    )
}