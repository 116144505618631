/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react"
import { SuccessFetch } from "types"
import { useApi } from "utils"

type UseReceivedCode = {
  key?: any
  trx_date: string
  enabled?: boolean
  onSuccess?: (data: string) => void
}

type NextCode = {
  next_code: string
}

type Response = {
  payload: NextCode
}

export function useReceivedCode({
  key = "",
  trx_date,
  onSuccess,
  enabled = true
}: UseReceivedCode) {
  const api = useApi()
  const [data, setData] = useState("")
  const [isLoading, setLoading] = useState(false)

  const fetchData = () => {
    setLoading(true)

    api
      .post("/received/generate-code", {
        trx_date
      })
      .then((res: SuccessFetch<Response>) => {
        const data = res.data.payload

        onSuccess?.(data.next_code)
        return setData(data.next_code)
      })
      .catch(() => setData(""))
      .finally(() => setLoading(false))
  }

  const refetch = () => fetchData()

  useEffect(() => {
    if (enabled) {
      fetchData()
    }

    return () => {
      setData("")
      setLoading(false)
    }
  }, [trx_date, enabled, key])

  return { data, isLoading, refetch }
}
