import { Button, Select } from "components"
import { periode } from "pages/Finance/Report/utils/vars"
import { Fragment } from "react"
import { useFormContext } from "react-hook-form"
import { useToggle } from "utils"
import { FilterReport } from "../../types"
import { useComparationList } from "../../utils/hooks"

type PeriodeProps = {
  buttonDateRef: React.RefObject<HTMLDivElement>
  toggleButtonPeriod: ReturnType<typeof useToggle>
}

export const Periode = ({
  buttonDateRef,
  toggleButtonPeriod
}: PeriodeProps) => {
  const methods = useFormContext<FilterReport>()
  const periodConfig = methods.watch("periode")
  const comparation = useComparationList({})
  const periodComparation = comparation.data?.map((comparation) => ({
    value: comparation.mr_comparation_name.toString(),
    label: `${comparation.mr_comparation_name} ${periodConfig?.label}`
  }))
  const isPeriodeNone = periodConfig?.value === "1"

  return (
    <Fragment>
      <div className="relative">
        <label className="label">
          <span className="label-text font-bold ">Periode</span>
        </label>
        <Button
          type="button"
          color="primary"
          className="rounded-l-lg text-start text-ellipsis text-nowrap min-w-[6rem]"
          onClick={() => {
            toggleButtonPeriod.setActive(!toggleButtonPeriod.isActive)
          }}>
          {periodConfig?.label}
        </Button>

        {toggleButtonPeriod.isActive && (
          <div
            ref={buttonDateRef}
            className="absolute w-full flex flex-col border justify-start border-gray-100 top-[5.7rem] z-10 bg-white dark:bg-black rounded-lg">
            {periode.map((period) => (
              <Button
                key={period.value}
                className="flex justify-between items-center"
                type="button"
                color="ghost"
                onClick={() => {
                  toggleButtonPeriod.setActive(false)
                  methods.setValue("periode", period)
                  methods.setValue("comparison", period.value === "1" ? undefined : "1")
                }}>
                <span>{period.label}</span>
              </Button>
            ))}
          </div>
        )}
      </div>

      <Select
        isDisabled={isPeriodeNone}
        label="Comparation Periode(s)"
        key={periodConfig?.value}
        rounded={false}
        placeholder="Select Filter"
        options={isPeriodeNone ? [] : periodComparation}
        defaultValue={isPeriodeNone ? undefined : periodComparation[0]}
        controlClass="!rounded-l-none !rounded-r-lg"
        onChange={(filter) => {
          methods.setValue("comparison", filter?.value)
        }}
      />
    </Fragment>
  )
}
