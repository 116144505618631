import { Input, Textarea } from "../../../../../../components"
import { Controller, useFormContext } from "react-hook-form"
import type { DataList } from "../../types"

export function FormSection(props: {
  isDetail?: boolean
  isUpdate?: boolean
  action?: string
}) {

  const { control } = useFormContext<DataList>()

  return (
    <section>
      <Controller
        control={control}
        name="price_level_vendor_code"
        render={({ field, fieldState }) => <Input {...field} label="CODE" value={field.value ?? ""} error={fieldState.error?.message}  disabled={props.action === "DETAIL" || props.action === "DELETE"} />}
      />

      <Controller
        control={control}
        name="price_level_vendor_name"
        render={({ field, fieldState }) => <Input {...field} label="PRICE LEVEL VENDOR" value={field.value ?? ""} error={fieldState.error?.message} disabled={props.action === "DETAIL" || props.action === "DELETE"} />}
      />

      <Controller
        control={control}
        name="description"
        render={({ field, fieldState }) => <Textarea {...field} label="DESCRIPTION" value={field.value ?? ""} error={fieldState.error?.message} disabled={props.action === "DETAIL" || props.action === "DELETE"} />}
      />
    </section>
  )
}