import { JournalPaymentInvoice } from "../../types"

type GenerateJournalPaymentInvoice = {
  coa_la06: string
  coa_la04: string
  coa_payment: string
  date: string
  trx_code: string
  payment_amount: number
  deposit_amount: number
  memo?: string
}

export const generateJournalPaymentInvoice = ({
  coa_la06,
  coa_la04,
  coa_payment,
  date,
  trx_code,
  payment_amount,
  deposit_amount,
  memo
}: GenerateJournalPaymentInvoice): JournalPaymentInvoice[] => {
  const mapCoa = (coa: string, isDebet: boolean, amount: number) => {
    return {
      coa,
      date,
      idm: trx_code,
      memo: memo ?? "",
      debet: isDebet ? amount : 0,
      credit: isDebet ? 0 : amount
    }
  }

  return [
    mapCoa(coa_payment, true, payment_amount),
    mapCoa(coa_la04, false, payment_amount),
    mapCoa(coa_la06, true, deposit_amount),
    mapCoa(coa_la04, false, deposit_amount)
  ]
}
