import { Loading } from "components";
import { BreakEventAnalysis, CardSection, FixedCostPerUnit, StockAnalysis } from "../ChildItem/components/Item/components";
import { usePerformence } from "./utils";
import { convertNumber } from "utils";

export const FormPerformance = (props: { id?: number; disable?: boolean }) => { 
    const { data, isLoading } = usePerformence({ product_id: props?.id! })

    return (
        <section>
            {isLoading 
              ? <div className="text-center"><Loading loading={isLoading} errorText={isLoading ? "" : "No data available"} /></div>
              : <>
                    <div>
                        <div className="overflow-x-auto">
                            <div className="text-[16px] mb-5"><b>STOCK ANALYSIS</b></div>
                            <StockAnalysis label="Stock In" value={data?.stoct_id ? convertNumber(data?.stoct_id)?.intoNormalAmount! : "0"} />
                            <StockAnalysis label="Stock Out" value={data?.stoct_out ? convertNumber(data?.stoct_out)?.intoNormalAmount! : "0"} textError />
                            <StockAnalysis label="Quantity On Hand :" value={data?.stoct_on_hand ? convertNumber(data?.stoct_on_hand)?.intoNormalAmount! : "0"} />
                            <StockAnalysis label="COGM Current Value :" value={data?.cogm_current_value ? convertNumber(data?.cogm_current_value)?.intoNormalAmount! : "0"} />
                            <StockAnalysis label="Average Cost :" value={data?.average_cost ? convertNumber(data?.average_cost)?.intoNormalAmount! : "0"} />
                            <StockAnalysis label="Committed :" value={data?.commited ? convertNumber(data?.commited)?.intoNormalAmount! : "0"} />
                            <StockAnalysis label="On Vendor Order :" value={data?.on_vendor ? convertNumber(data?.on_vendor)?.intoNormalAmount! : "0"} />
                            <StockAnalysis label="Available :" value={data?.available ? convertNumber(data?.available)?.intoNormalAmount! : "0"} />
                            <StockAnalysis label="Retail Current Value :" value={data?.retail_current_value ? convertNumber(data?.retail_current_value)?.intoNormalAmount! : "0"} />
                        </div>
                        <div className="flex mt-12 gap-2 overflow-x-auto">
                            <CardSection className="!bg-[#758694] !border-[#758694]" label="Inventory Adjustment" value="1" text="Pcs" />
                            <CardSection className="!bg-[#FFAF00] !border-[#FFAF00]" label="On Order" value="0" text="Box" />
                            <CardSection className="!bg-[#387F39] !border-[#387F39]" label="Delivered" value="1800" text="Box" />
                            <CardSection className="!bg-[#5B99C2] !border-[#5B99C2]" label="Commited" value="" text="Pcs" />
                            <CardSection className="!bg-[#03346E] !border-[#03346E]" label="Soid" value="" text="Pcs" />
                        </div>
                        <div className="flex mt-1 gap-2 overflow-x-auto">
                            <CardSection className="!bg-[#399918] !border-[#399918]" label="On Customer Quotation" value="" text="Pcs" />
                            <CardSection className="!bg-[#FD9B63] !border-[#FD9B63]" label="Build Component" value="0" text="Pcs" />
                            <CardSection className="!bg-[#1A5319] !border-[#1A5319]" label="Total Stock Incoming" value="1800" text="Pcs" />
                            <CardSection className="!bg-[#A02334] !border-[#A02334]" label="Total Stock Out Going" value="" text="Pcs" />
                            <CardSection className="!bg-[#658147] !border-[#658147]" label="Total Stock On Hard" value="" text="Pcs" />
                        </div>
                    </div>
                    <div className="flex justify-between mt-12 overflow-x-auto">
                        <div className="w-[47%]">
                            <BreakEventAnalysis disable={props?.disable!} />
                        </div>
                        <div className="w-[47%]">
                            <FixedCostPerUnit disable={props?.disable!} />
                        </div>
                    </div>
                </>
            }
        </section>
    )
}