import { ReactNode } from "react"

interface DisableFieldProps {
    children: ReactNode;
    className?: string
    width?: string
}
export const DisableField = ({ children, className, width }: DisableFieldProps) => (
    <label className={`form-control w-[${width ? width : "220px"}]`}>
        <div className={`input input-bordered border-none bg-gray-200 dark:bg-[#181f27] dark:border-gray-400 dark:text-[EEEEEE] flex items-center ${className}`}>
            {children}
        </div>
    </label>
)