import { useEffect, useState } from "react"
import type { SelectOptionType, SuccessFetch } from "../../../../../../../../types"
import { useApi } from "../../../../../../../../utils"

export function useCOACogs() {
  const api = useApi()
  const [data, setData] = useState<SelectOptionType[]>([])
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    api.get("/coa/detailcoabygroupid?group_id=5")
    .then((res: SuccessFetch<{
      payload: {
        coa_id: number
        coa_code: string
        coa_name: string
      }[]
    }>) => { setData(res.data.payload.map(item => { return { label: `${item.coa_code} ${item.coa_name}`, value: item.coa_id.toString() }}))})
    .catch(() => setData([]))
    .finally(() => setLoading(false))
  
    return () => {
      setData([])
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [])

  return { data, isLoading }
}