// React Number Format
import { forwardRef } from "react"
import { NumericFormat } from "react-number-format"
import type { NumericFormatProps } from "react-number-format"

interface Type extends NumericFormatProps {
  error?: string
  label?: string
  textRight?: boolean
  inputClass?: string
}

export const NumberInput = forwardRef((props: Type, ref) => {
  return (
    <label className="form-control">
      {props.label && (
        <label className="label">
          <span className={`label-text font-bold ${props.error && "text-error"}`}>{props.label}</span>
        </label>
      )}

      <NumericFormat
        decimalSeparator=","
        thousandSeparator="."
        {...props}
        getInputRef={ref}
        displayType="input"
        className={`input input-bordered dark:border-gray-400 ${props.inputClass} ${props.error && "!input-error"} ${props.textRight ? "text-right" : ""}`}
        placeholder={props.disabled ? "-" : props.placeholder}
      />

      {props.error && (
        <label className="label">
          <span className={`label-text-alt font-bold ${props.error && "text-error"}`}>{props.error}</span>
        </label>
      )}
    </label>
  )
})