// React
import { useContext, useEffect, useRef } from "react"

// Components
import { BaseTable, ErrorText } from "components"
import { Job, Tax } from "../../components"
import { DetailBody } from "./DetailBody"

// Contexts
import { PurchaseOrderContext } from "../contexts"

// Form
import { ErrorMessage } from "@hookform/error-message"
import { useFormContext } from "react-hook-form"

// Utils
import type { FormType } from "../utils"

export function Table():JSX.Element {
  // Hooks
  const ref = useRef<HTMLTableElement>(null)
  const { disabled } = useContext(PurchaseOrderContext)

  // Form
  const { formState: { errors } } = useFormContext<FormType>()

  useEffect(() => {
    if (errors["podetail"]) {
      ref.current?.scrollIntoView()
    }

    // Adjust for additional height, e.g., 150px
    const additionalHeight = 150
    const scrolledY = window.scrollY
    if (scrolledY) {
      window.scroll(0, scrolledY - additionalHeight)
    }

    // eslint-disable-next-line
  }, [errors])

  return (
    <div className="overflow-x-auto">
      <div ref={ref}>
        <BaseTable>
          <thead>
            <tr>
              <th>#</th>
              <th>ORDER</th>
              <th>RECEIVED</th>
              <th>BACKORDER</th>
              <th>ITEM ORDER</th>
              <th>DESCRIPTION</th>
              <th>UNIT</th>
              <th>PRICE</th>
              <th>DISC</th>
              <th>TOTAL</th>
              <Job disabled={disabled} />
              <Tax disabled={disabled} />
              <th>ACTION</th>
            </tr>
          </thead>

          <tbody>
            <DetailBody />
          </tbody>
        </BaseTable>
      </div>

      <ErrorMessage
        name="podetail"
        errors={errors}
        render={({ message }) => <ErrorText text={message} />}
      />
    </div>
  )
}