// React
import { useContext } from "react"

// Components
import { Loading } from "components"
import { InputSection } from "../components"

// Contexts
import { AccountProvider } from "contexts"
import { BillContext } from "../contexts"

// Form
import { FormProvider, useForm } from "react-hook-form"

// Utils
import { type FormType, useDetail } from "../utils"
import { DisabledContext } from "../../contexts"

export function Detail(params: {
  payment_bill_id: string
}): JSX.Element {
  // Hooks
  const { data, isLoading } = useDetail(params.payment_bill_id)

  if (isLoading || !data) {
    return <Loading loading={isLoading} />
  }

  return (
    <BillContext.Provider value={data}>
      <DisabledContext.Provider value={true}>
        <AccountProvider>
          <FormSection />
        </AccountProvider>
      </DisabledContext.Provider>
    </BillContext.Provider>
  )
}

function FormSection(): JSX.Element {
  // Hooks
  const data = useContext(BillContext)

  // Vars
  const databill = data.databill

  // Form
  const defaultValues: FormType = {
    _balance: 0,
    coa_id: data.coa_id,
    memo: data.memo,
    deposit_amount: data.deposit_amount,
    payment_amount: data.payment_amount,
    referensi: data.referensi,
    trx_code: data.trx_code,
    transaction_date: data.transaction_date,
    vendor_id: databill.vendor_id
  }
  const methods = useForm<FormType>({
    defaultValues,
  })

  return (
    <FormProvider {...methods}>
      <InputSection />
    </FormProvider>
  )
}