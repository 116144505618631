// React
import { useState } from "react"

// Types
import type { SelectOptionType, SuccessFetch } from "../../../../../../../types"

// Utils
import { useApi } from "../../../../../../../utils"

export function useAccountType() {
  // Hooks
  const api = useApi()
  const [data, setData] = useState<SelectOptionType[]>([])
  const [isLoading, setLoading] = useState(false)

  const refetch = (group_coa_id?: string) => {
    setLoading(true)

    api.get("/acctype/byidgroup", {
      params: { groupid: group_coa_id }
    }).then((res: SuccessFetch<{
      payload: {
        account_type_id: number
        account_type_name: string
      }[]
    }>) => {
      setData(res.data.payload.map(item => {
        return {
          label: item.account_type_name,
          value: item.account_type_id.toString()
        }
      }))
    }).catch(() => {
      setData([])
    }).finally(() => {
      setLoading(false)
    })
  }

  return { data, isLoading, refetch }
}