// Utils
import type { FormType } from "../vars"

export function getFinalValue(values: FormType) {
  // Vars
  const { approval_status, ...value } = values

  return {
    ...value,
    podetail: value.podetail.map(item => {
      return {
        ...item,
        location_id: value.location_id
      }
    }),
    reminder: value.reminder ? {
      ...value.reminder,
      card_group: value.vendor_id,
      card_id: 3
    } : null
  }
}