// Icons
import { TbHeart, TbHistory, TbSettings } from "react-icons/tb"

export const iconMenu = [
  {
    icon: TbSettings,
    label: "Setup",
    link: "/setup",
    permission: "SUBSCRIBED"
  },
  {
    icon: TbHeart,
    label: "Favorite",
    link: "#",
    permission: "SUBSCRIBED"
  },
  {
    icon: TbHistory,
    label: "History",
    link: "#",
    permission: "SUBSCRIBED"
  }
]