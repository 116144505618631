import { PaymentDepositCustomerType, SubmitType } from "../../types"

export const submitMap = (data: PaymentDepositCustomerType): SubmitType => {
  return {
    deposit_customer_id: data.deposit_customer_id,
    sales_order_id: data.sales_order_id!,
    coa_id: data.coa_id,
    customer_id: data.customer_id,
    location_id: data.location_id,
    project_id: 0,
    trx_code: data.trx_code ?? "",
    transaction_date: data.transaction_date ?? "",
    amount: data.amount,
    memo: data.memo,
    no_cheque: data.no_cheque,
    address: data.shipped
  }
}
