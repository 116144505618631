// React
import { useEffect, useState } from "react"

// Types
import type { SuccessFetch } from "types"

// Utils
import { useApi } from "utils"
import { default_option } from "pages/Purchase/utils"

interface DepositVendorType {
  deposit_vendor_id: number
  trx_code: string
  transaction_date: string
  approve_status: string
  memo: string
  amount: number
  deposit_used: number
}

export function useDepositVendorList(purchase_order_id: string): {
  data: DepositVendorType[]
  isLoading: boolean
  refetch: () => void
} {
  // Vars
  const defaultData: DepositVendorType[] = []

  // Hooks
  const api = useApi()
  const [data, setData] = useState<DepositVendorType[]>(defaultData)
  const [isLoading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    refetch()

    return () => {
      setData(defaultData)
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [])

  const refetch = (): void => {
    const promise = new Promise<DepositVendorType[]>((resolve) => {
      setLoading(true)

      api.get("/depositvendor/deposit_vendor_bypo", {
        params: { purchase_order_id }
      }).then((res: SuccessFetch<{ payload: {
        deposit_vendor_id: number
        trx_code: string
        transaction_date: string
        approve_status: number
        memo: string
        amount: string
        deposit_used: string
      }[] }>) => {
        resolve(res.data.payload.map(item => {
          return {
            amount: parseInt(item.amount),
            approve_status: default_option.find(i => i.value === item.approve_status.toString())?.label ?? "-",
            deposit_used: parseInt(item.deposit_used),
            deposit_vendor_id: item.deposit_vendor_id,
            memo: item.memo,
            transaction_date: item.transaction_date,
            trx_code: item.trx_code
          }
        }))
      }).catch(() => {
        resolve(defaultData)
      }).finally(() => {
        setLoading(false)
      })
    })

    promise.then(setData)
  }

  return { data, isLoading, refetch }
}