/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react"
import { SuccessFetch } from "types"
import { useApi } from "utils"
import { DepositCustomerListBySO } from "../../types"

type UseDepositCustomerListBySoProps = {
  params: {
    sales_order_id?: number
  }
}

type Response = {
  payload: DepositCustomerListBySO[]
}

export function useDepositCustomerListBySo({
  params
}: UseDepositCustomerListBySoProps) {
  const api = useApi()
  const [data, setData] = useState<Response["payload"]>([])
  const [isLoading, setLoading] = useState(false)

  const fetchData = () => {
    setLoading(true)

    api
      .get("depositcustomer/deposit_customer_byso", { params })
      .then((res: SuccessFetch<Response>) => {
        const data = res.data.payload ?? []
        setData(data)
      })
      .catch(() => setData([]))
      .finally(() => setLoading(false))
  }

  const refetch = () => fetchData()

  useEffect(() => {
    if (params.sales_order_id) {
      fetchData()
    }

    return () => {
      setData([])
      setLoading(false)
    }
  }, [params.sales_order_id])

  return {
    data,
    isLoading,
    refetch,
    dropdown: data.map((item) => ({ value: item, label: item }))
  }
}
