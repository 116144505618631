import * as yup from "yup"

export type FormSalesOrderDetailType = yup.InferType<
  typeof formSalesOrderDetailSchema
>

export const formSalesOrderDetailSchema = yup.object().shape({
  sales_order_detail_id: yup.number().nullable(),
  price_level_name: yup.string().label("Price Level").optional(),
  product_id: yup.number().label("Product").typeError("Product is required").required(),
  product_name: yup.string().label("Product").optional(),
  product_barcode: yup.string().label("Product").optional(),
  qty_order: yup.number().label("Qty Order").required().min(1, "Qty Order is required"),
  received: yup.number().label("Received").required(),
  mr_unit_id: yup.number().label("Unit").typeError("Unit is required").required(),
  mr_unit_name: yup.string().label("Unit").optional(),
  price_measure: yup.number().label("Price Measure").required(),
  discount: yup.number().label("Discount").required(),
  total: yup.number().label("Total").required(),
  project_id: yup.number().label("Project").typeError("Project is required").required(),
  project_name: yup.string().label("Project").optional(),
  tax_id: yup.number().label("Tax").typeError("Tax is required").required(),
  tax_name: yup.string().label("Tax").optional(),
  tax_rate: yup.number().label("Tax").optional(),
  location_id: yup.number().label("Location").optional(),
  location_name: yup.string().label("Location").optional(),
  soh: yup.number().label("SOH").optional()
})
