// React
import { useContext } from "react"

// COmponents
import { Select } from "components"

// Contexts
import { DataJournalContext } from "contexts"

// Form
import { Controller, useFormContext } from "react-hook-form"

// Utils
import { DetailFormType } from "../../../utils"

export function Job() {
  // Hooks
  const { project: { data, isReloading } } = useContext(DataJournalContext)

  // Form
  const { control } = useFormContext<DetailFormType>()

  return (
    <Controller
      control={control}
      name="project_id"
      render={({ field, fieldState }) => (
        <Select
          placeholder="Select Job"
          error={fieldState.error?.message}
          isLoading={isReloading}
          options={data}
          ref={field.ref}
          value={data.find(item => item.value === field.value) ?? null}
          onChange={item => field.onChange(item?.value)}
        />
      )}
    />
  )
}