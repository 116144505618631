// Utils
import { getDiscount } from "./getDiscount"

export function getFinalValue(value: any, type: "spend-money" | "receive-money") {
  // Vars
  const isSpendMoney = type === "spend-money"

  const data_journal: {
    amount: number
    card_id: string
    coa_id: string
    group_card: string
    location_id: string
    memo: string
    no_urut_detail_finance: number
    position: string
    project_id: string
    referensi: string
    transaction_date: string
    trx_code: string
  }[] = []

  data_journal.push({
    amount: value.amount,
    card_id: value.card_id,
    coa_id: value.coa_id,
    group_card: value.group_card,
    location_id: value.location_id,
    memo: value.memo,
    no_urut_detail_finance: 0,
    position: isSpendMoney ? "2" : "1",
    project_id: "1",
    referensi: value.reference_no,
    transaction_date: value.transaction_date,
    trx_code: value.trx_code,
  })

  value.datajournal.map((item: any, key: number) => {
    if (item.rate > 0) {
      data_journal.push({
        amount: item.amount * item.rate / 100,
        card_id: value.card_id,
        coa_id: value.tax_inclusive ? item.tax_coa_paid_id : item.tax_coa_collect_id,
        group_card: value.group_card,
        location_id: value.location_id,
        memo: value.tax_inclusive ? "tax inclusive" : "not tax inclusive",
        no_urut_detail_finance: key + 1,
        position: isSpendMoney ? "1" : "2",
        project_id: item.project_id,
        referensi: item.referensi,
        transaction_date: value.transaction_date,
        trx_code: value.trx_code
      })
    }

    return data_journal.push({
      amount: item.amount - getDiscount(item.amount, item.rate),
      card_id: value.card_id,
      coa_id: item.coa_id,
      group_card: value.group_card,
      location_id: value.location_id,
      memo: item.memo,
      no_urut_detail_finance: key + 1,
      position: isSpendMoney ? "1" : "2",
      project_id: item.project_id,
      referensi: item.referensi,
      transaction_date: value.transaction_date,
      trx_code: value.trx_code
    })
  })

  return {
    DataFinance: {
      amount: value.amount,
      trx_code: value.trx_code,
      data_source: isSpendMoney ? "1" : "2",
      transaction_date: value.transaction_date,
      group_card: value.group_card,
      card_id: value.card_id,
      location_id: value.location_id,
      memo: value.memo,
      type_payment: value.type_payment && value.type_payment !== "" ? value.type_payment : undefined,
      name_payment: value.name_payment && value.name_payment !== "" ? value.name_payment : undefined,
      number_payment: value.number_payment && value.number_payment !== "" ? value.number_payment : undefined,
      expired_payment: value.expired_payment && value.expired_payment !== "" ? value.expired_payment : undefined,
      authorization_payment: value.authorization_payment && value.authorization_payment !== "" ? value.authorization_payment : undefined,
      note_payment: value.note_payment,
      reference_no: value.reference_no,
      tax_inclusive: value.tax_inclusive
    },
    DataFinanceDetail: {
      datadetailfinance: value.datajournal.map((item: any, key: number) => {
        return {
          coa_id: item.coa_id,
          memo: item.memo,
          amount: item.amount,
          position: isSpendMoney ? "1" : "2",
          project_id: item.project_id,
          referensi: item.referensi,
          tax_id: item.tax_id,
          no_urut: key + 1,
        }
      })
    },
    DataJournal: { datajournal: data_journal },
    imgPath: value.imgPath
  }
}