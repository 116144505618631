import { useEffect, useState } from "react"
import type { SelectOptionType, SuccessFetch } from "../../../../../../../../types"
import { useApi } from "../../../../../../../../utils"

export function usePriceLevel() {
  const api = useApi()
  const [data, setData] = useState<SelectOptionType[]>([])
  const [isLoading, setLoading] = useState(false)

  const refetch = () => {
    setLoading(true)

    api.get("/pricelevel/")
    .then((res: SuccessFetch<{
      payload: {
        price_level_id: number
        price_level_name: string
      }[]
    }>) => { setData(res.data.payload.map(item => { return { label: item.price_level_name, value: item.price_level_id.toString() }}))})
    .catch(() => setData([]))
    .finally(() => setLoading(false))
  }

  useEffect(() => {
    setLoading(true)

    api.get("/pricelevel/")
    .then((res: SuccessFetch<{
      payload: {
        price_level_id: number
        price_level_name: string
      }[]
    }>) => { setData(res.data.payload.map(item => { return { label: item.price_level_name, value: item.price_level_id.toString() }}))})
    .catch(() => setData([]))
    .finally(() => setLoading(false))
    // eslint-disable-next-line
  }, [])

  return { data, isLoading, refetch }
}