/* eslint-disable react-hooks/exhaustive-deps */
import { BaseTable, Card, CommandButton, Loading } from "components"
import FilterByDate from "pages/Finance/Journal/components/FilterByDate"
import { useFilter } from "pages/Finance/Journal/utils/hooks"
import { Fragment } from "react"
import { thisMonth, today } from "utils/functions"
import { DropdownAccount } from "../DropdownAccount"
import { Item } from "./components"
import {
  useRegisterReconcileList
} from "./utils/hooks"

export function RegisterReconciliation() {
  const todayDate = today()
  const thisMonthDate = thisMonth()

  const { filter, handleChangeFilter } = useFilter({
    CoaID: "0",
    dateFrom: thisMonthDate.fromDate,
    dateTo: todayDate
  })

  const { isLoading, data } = useRegisterReconcileList({
    params: { coa_id: filter.CoaID!, start_date: filter.dateFrom, end_date: filter.dateTo },
  })

  return (
    <Fragment>
      <section className="container">
        <section className="mt-2">
          <Card>
            <Card.Body>
              <section className="container flex-col lg:flex lg:flex-row justify-center lg:justify-between pt-5">
                <div className="w-full lg:w-[36%] flex-col lg:flex lg:flex-row justify-center lg:justify-start items-center">
                  <div className="label-text pb-1 font-bold pe-6">
                    Account CHQ
                  </div>
                  <DropdownAccount
                    value={null}
                    displayBalance={false}
                    onChange={(item) =>
                      handleChangeFilter({ CoaID: item?.value })
                    }
                  />
                </div>

                <div className="py-3 px-3 md:px-0">
                  <CommandButton actiontype="advanced" />
                </div>
              </section>

              <section className="container flex-col lg:flex lg:flex-row justify-center lg:justify-between pt-1">
                <FilterByDate
                  dateFrom={filter.dateFrom}
                  dateTo={filter.dateTo}
                  displayButtonShortcut={false}
                  onChangeDateFrom={(date) => handleChangeFilter({ dateFrom: date })}
                  onChangeDateTo={(date) => handleChangeFilter({ dateTo: date })}
                  onClickPrevYear={() => null}
                  onClickThisYear={() => null}
                  onClickThisWeek={() => null}
                  onClickThisMonth={() => null}
                  onClickToday={() => null}
                />
              </section>
            </Card.Body>
          </Card>
        </section>

        <section className="container flex-col lg:flex lg:flex-row justify-center items-start lg:justify-between">
          <div className="w-full flex-col lg:flex lg:flex-row justify-center lg:justify-start items-center">
            <section className="container my-5">
              <Card>
                <Card.Body className="max-h-[600px]">
                  <BaseTable className="border-transparent">
                    <thead>
                      <tr className="uppercase">
                        <th>No</th>
                        <th>RCL ID#</th>
                        <th>By</th>
                        <th>New<br/>Statement Date</th>
                        <th>Last<br/>Reconcile Date</th>
                        <th>New<br/>Statemenet Balance</th>
                        <th>Last<br/>Statement Balance</th>
                        <th>Total<br/>Cleared Debet</th>
                        <th>Total<br/>Cleared Credit</th>
                        <th>Out of<br/>Balance</th>
                        <th>Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {!data?.length || isLoading ? (
                        <tr>
                          <td colSpan={10} className="text-center">
                            <Loading
                              loading={isLoading}
                              errorText={
                                isLoading ? "" : "No data available"
                              }
                            />
                          </td>
                        </tr>
                      ) : (
                        data.map((item, key: number) => (
                          <Item
                            item={item}
                            index={key}
                          />
                        ))
                      )}
                    </tbody>
                  </BaseTable>
                </Card.Body>
              </Card>
            </section>
          </div>
        </section>
        <section className="mb-7">
          <Card>
            <Card.Body className="flex flex-col gap-5">
              <section className="flex flex-wrap justify-between items-center gap-3">
                <section className="flex flex-wrap gap-3">
                  <CommandButton actiontype="help" />
                  <CommandButton actiontype="print" />
                  <CommandButton actiontype="email" />
                  <CommandButton actiontype="export" />
                </section>
              </section>
            </Card.Body>
          </Card>
        </section>
      </section>
    </Fragment>
  )
}
