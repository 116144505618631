import { FormType } from "../../types"

export const totalAmountJournal = (data: FormType["datajournal"]) => {
  return data.reduce(
    (acc, curr) => {
      curr.position === 1
        ? (acc.debet += curr.amount)
        : (acc.credit += curr.amount)
      return acc
    },
    {
      credit: 0,
      debet: 0
    }
  )
}
