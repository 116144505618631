// EXCEPTIONS
// #1: ignore type error to check field name is available on the form type

// React
import { Fragment } from "react"

// Components
import { Button, ErrorText, Label, Modal, Select } from "components"
import { CreatePage as NewVendor } from "pages/Card/Transaction/CreateNewVendor/components"

// Form
import { Controller, useFormContext } from "react-hook-form"

// Icons
import { TbPlus } from "react-icons/tb"

// Utils
import { useToggle } from "utils"
import { useSupplier } from "./utils"

export function Supplier(params: {
  disabled?: boolean
  detail?: string
  name: string
  trading_term_name: string
  vendor_name: string
}) {
  // Form
  const { control, setValue } = useFormContext()

  // API
  const { data, isLoading, refetch } = useSupplier()

  return (
    <Controller
      control={control}
      name={params.name}
      render={({ field, fieldState }) => (
        <section className="w-full">
          <Label text="SUPPLIER" />

          <section className="flex gap-3">
            <Select
              isDisabled={params.disabled}
              className="grow"
              placeholder="Select Supplier"
              isLoading={isLoading}
              ref={field.ref}
              value={data.find(item => item.value === field.value) ?? null}
              options={data}
              onChange={res => {
                if (res) {
                  field.onChange(res.value)
                  setValue(params.vendor_name, res.label)
                  params.detail && setValue(params.detail, [])

                  // @ts-expect-error [EXCEPTION #1]
                  setValue(params.trading_term_name, res.payment_term_vendor_name)
                }
              }}
            />

            {!params.disabled && <NewSupplier onSuccess={refetch} />}
          </section>

          {fieldState.error && <ErrorText text={fieldState.error.message!} />}
        </section>
      )}
    />
  )
}

function NewSupplier(params: { onSuccess: () => void }) {
  // Hooks
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <Button
        color="primary"
        className="px-2"
        onClick={toggle}
      >
        <TbPlus size={24} />
      </Button>

      {isActive && (
        <Modal
          isOpen
          title="Create New Supplier"
          size="full"
          closeModal={() => {
            toggle()
            params.onSuccess()
          }}
        >
          <Modal.Body>
            <NewVendor asModal />
          </Modal.Body>
        </Modal>
      )}
    </Fragment>
  )
}