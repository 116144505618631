// Form
import { useFormContext, useWatch } from "react-hook-form"

// Types
import type { FormType } from "pages/Finance/Transaction/utils"

// Utils
import { getAcc } from "pages/Finance/Transaction/utils"
import { convertNumber } from "utils"
import { BaseTable } from "components"

export function AccTable() {
  // Form
  const { control } = useFormContext<FormType>()
  const amount = useWatch({
    control,
    name: "amount"
  })
  const datajournal = useWatch({
    control,
    name: "datajournal"
  })

  // Hooks
  const { sub_total, tax } = getAcc(datajournal)

  // Vars
  const amount_diff = Math.abs(amount - (sub_total + tax))

  return (
    <BaseTable className="w-full min-w-[100px] md:max-w-[400px]">
      <tbody className="text-lg">
        <tr className="border-dotted border-b-2">
          <td className="w-[1px] whitespace-nowrap pr-3 pt-5 uppercase font-bold">Sub Total</td>
          <td className="pt-5">: {convertNumber(sub_total).intoCurrency}</td>
        </tr>
        <tr className="border-dotted border-b-2">
          <td className="w-[1px] whitespace-nowrap pr-3 pt-5 uppercase font-bold">Tax</td>
          <td className="pt-5">: {convertNumber(tax).intoCurrency}</td>
        </tr>
        <tr className="border-dotted border-b-2">
          <td className="w-[1px] whitespace-nowrap pr-3 pt-5 uppercase font-bold">Allocated</td>
          <td className="pt-5">: {convertNumber(amount).intoCurrency}</td>
        </tr>
        <tr className="border-dotted border-b-2">
          <td className="w-[1px] whitespace-nowrap pr-3 pt-5 uppercase font-bold">Balance</td>
          <td className="pt-5">: <span className={`${amount_diff > 0 && "text-error"}`}>{convertNumber(amount_diff).intoCurrency}</span></td>
        </tr>
      </tbody>
    </BaseTable>
  )
}