import moment from "moment";
import { RegisterReconcileList } from "../types";
import { ActionButton } from "components";

interface ItemProps {
  index: number;
  item: RegisterReconcileList
}

export function Item({ item, index }: ItemProps) {

  return (
    <tr key={index}>
      <td className="font-normal text-nowrap">{index + 1}</td>

      <td className="font-normal text-nowrap">
        {item.reconciliation_code}
      </td>

      <td>{item.created_by_name}</td>

      <td className="font-normal text-nowrap">
        {moment(item.new_statement_date).format("DD/MM/YYYY")}
      </td>

      <td className="font-normal text-nowrap">
        {moment(item.last_reconciliation_date).format("DD/MM/YYYY")}
      </td>

      <td className="text-right">
        {(item.new_statement_balance ?? 0).toLocaleString("id-ID")}
      </td>

      <td className="text-right">
        {item.last_statement_balance.toLocaleString("id-ID")}
      </td>

      <td className="text-right">
        {item.total_cleared_debet.toLocaleString("id-ID")}
      </td>

      <td className="text-right">
        {item.total_cleared_credit.toLocaleString("id-ID")}
      </td>

      <td className="text-right">
        {item.out_of_balance.toLocaleString("id-ID")}
      </td>

      <td>
        <ActionButton.Detail permission="FR052" />
      </td>
    </tr>
  );
}
