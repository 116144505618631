import { Button, Label, Select } from "components"
import { useLocationList } from "pages/Finance/Transaction/GeneralEntry/utils/hooks"
import { APPROVAL } from "pages/Sales/Register/utils/vars"
import { CreateModal as LocationModal } from "pages/Setup/Preference/Location/components"
import { Fragment } from "react"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { TbPlus } from "react-icons/tb"
import { useToggle } from "utils"
import { FormSalesOrderType } from "../types"

type DropdownLocationProps = {
  approvalView?: boolean
  isDisabled?: boolean
}

export const DropdownLocation = ({ approvalView, isDisabled }: DropdownLocationProps) => {
  const { control, clearErrors } = useFormContext<FormSalesOrderType>()
  const { dropdown, refetch } = useLocationList()
  const approval_status = useWatch({
    control: control,
    name: "approval_status"
  })

  return (
    <section>
      <Label text="LOCATION" />

      <section className="flex gap-3">
        <Controller
          control={control}
          name="location_id"
          render={({ field, fieldState }) => (
            <Select
              ref={field.ref}
              className="grow"
              placeholder="Select Location"
              isDisabled={approval_status !== APPROVAL.PENDING || isDisabled || approvalView}
              options={dropdown ?? []}
              value={
                field.value
                  ? dropdown.find(
                    (item) => item.value === field.value?.toString()
                  )
                  : null
              }
              onChange={(e) => {
                if (e?.value) {
                  field.onChange(e?.value)
                  clearErrors("location_id")
                }
              }}
              error={fieldState.error?.message}
            />
          )}
        />

        <CreateLocation approvalView={approvalView} onSuccess={refetch} />
      </section>
    </section>
  )
}

type CreateLocationProps = {
  approvalView?: boolean
  onSuccess: () => void
}

function CreateLocation({ approvalView, onSuccess }: CreateLocationProps) {
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <Button color="primary" className={`px-2 ${approvalView ? "hidden" : ""} `} onClick={toggle}>
        <TbPlus size={24} />
      </Button>

      {isActive && (
        <LocationModal toggle={toggle} onSuccess={onSuccess} />
      )}
    </Fragment>
  )
}
