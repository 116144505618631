import {
  DateInput
} from "components"
import moment from "moment"
import { DropdownApproval } from "./DropdownApproval"
import { DropdownDelivered } from "./DropdownDelivered"

interface FilterByDateProps {
  dateFrom: string
  dateTo: string
  Approval?: number
  Delivered?: number
  onChangeDateFrom: (date: string) => void
  onChangeDateTo: (date: string) => void
  onChangeApproval?: (approval: string) => void
  onChangeDelivered?: (delivered: string) => void
}

export function FilterByDate({
  dateFrom,
  dateTo,
  Approval,
  Delivered,
  onChangeDateFrom,
  onChangeDateTo,
  onChangeApproval,
  onChangeDelivered
}: FilterByDateProps) {
  return (
    <section className="container flex-col lg:flex lg:flex-row justify-center lg:justify-between pt-5">
      <div className="w-full flex-col lg:flex lg:flex-row justify-center lg:justify-start items-center gap-2 pe-3">
        <div className="label-text pb-1 font-bold pe-2">Date From</div>
        <DateInput
          className="w-1/4"
          placeholderText="Date From"
          onChange={(date) =>
            onChangeDateFrom(moment(date).format("YYYY-MM-DD"))
          }
          selected={moment(dateFrom).toDate()}
        />

        <div className="mt-3 lg:mt-0 lg:px-2 pb-1 label-text font-bold">
          Date To
        </div>
        <DateInput
          className="w-1/4"
          placeholderText="Date To"
          minDate={moment(dateFrom).toDate()}
          selected={moment(dateTo).toDate()}
          onChange={(date) => onChangeDateTo(moment(date).format("YYYY-MM-DD"))}
        />

        <div className="mt-3 lg:mt-0 lg:px-2 pb-1 label-text font-bold">
          Approval
        </div>
        <div className="w-1/4">
          <DropdownApproval value={Approval?.toString() ?? ""} onChange={(item) => onChangeApproval && onChangeApproval(item?.value ?? "")} />
        </div>

        <div className="mt-3 lg:mt-0 lg:px-2 pb-1 label-text font-bold">
          Delivered
        </div>
        <div className="w-1/4">
          <DropdownDelivered value={Delivered?.toString() ?? ""} onChange={(item) => onChangeDelivered && onChangeDelivered(item?.value ?? "")} />
        </div>
      </div>
    </section>
  )
}
