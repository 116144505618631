// Pages
import Home from "../pages/Home"
import { salesMenu } from "configs/salesMenu"
import { ModuleLayout } from "../components"
import { accountsMenu, analyticsMenu, cardsMenu, financeMenu, inventoryMenu } from "../configs"
import { accountsRoutes } from "./accountsRoutes"
import { cardsRoutes } from "./cardsRoutes"
import { financeRoutes } from "./financeRoutes"
import { inventoryRoutes } from "./inventoryRoutes"
import { salesRoutes } from "./salesRoutes"
import { purchaseMenu } from "configs/purchaseMenu"
import { purchaseRoutes } from "./purchaseRoutes"

// Utils
import { routeMapper } from "utils"
import { analyticsRoutes } from "./analyticsRoutes"

export const mainRoute = [
  {
    path: "/",
    element: <Home />
  },
  {
    path: "/analytics",
    element: <ModuleLayout menu={analyticsMenu.menu} />,
    children: routeMapper(analyticsRoutes)
  },
  {
    path: "/accounts",
    element: <ModuleLayout menu={accountsMenu.menu} />,
    children: routeMapper(accountsRoutes)
  },
  {
    path: "/finance",
    element: <ModuleLayout menu={financeMenu.menu} />,
    children: routeMapper(financeRoutes)
  },
  {
    path: "/inventory",
    element: <ModuleLayout menu={inventoryMenu.menu} />,
    children: routeMapper(inventoryRoutes)
  },
  {
    path: "/cards",
    element: <ModuleLayout menu={cardsMenu.menu} />,
    children: routeMapper(cardsRoutes)
  },
  {
    path: "/sales",
    element: <ModuleLayout menu={salesMenu.menu} />,
    children: routeMapper(salesRoutes)
  },
  {
    path: "/purchase",
    element: <ModuleLayout menu={purchaseMenu.menu} />,
    children: routeMapper(purchaseRoutes)
  }
]