/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react"
import { SuccessFetch } from "types"
import { useApi } from "utils"
import { DetailPaymentDeposit } from "../../types"

type UsePaymentInvoice = {
  deposit_customer_id: string
  onSuccess?: (data: DetailPaymentDeposit) => void
}

type Response = {
  payload: {
    data: DetailPaymentDeposit
  }
}

export function useDetailPaymentDeposit({
  deposit_customer_id,
  onSuccess
}: UsePaymentInvoice) {
  const api = useApi()
  const [data, setData] = useState<DetailPaymentDeposit | null>(null)
  const [isLoading, setLoading] = useState(false)

  const fetchData = () => {
    setLoading(true)

    const params = {
      deposit_customer_id
    }

    api
      .get("/depositcustomer/detail", { params })
      .then((res: SuccessFetch<Response>) => {
        const data = res.data.payload.data
        onSuccess?.(data)
        setData(data)
      })
      .catch(() => setData(null))
      .finally(() => setLoading(false))
  }

  const refetch = () => fetchData()

  useEffect(() => {
    if (deposit_customer_id) {
      fetchData()
    }

    return () => {
      setData(null)
      setLoading(false)
    }
  }, [deposit_customer_id])

  return { data, isLoading, refetch }
}
