import { ActionButton } from "components";
import { Fragment } from "react";

export function Delete(props: {
    data: any
}) {
    return (
        <Fragment>
            {props?.data?.status_approval !== '4' && <ActionButton.Delete  />}
        </Fragment>
    )
}