// React
import { useContext } from "react"

// Components
import { AddProject } from "components"

// Contexts
import { DataJournalContext } from "contexts"

export function Job(params: {
  disabled?: boolean
}) {
  // Hooks
  const { project } = useContext(DataJournalContext)

  return (
    <th className="whitespace-nowrap">
      JOB {!params.disabled && <AddProject onSuccess={project.refetch} />}
    </th>
  )
}