// React
import { useContext } from "react"

// Component
import { Select } from "components"

// Contexts
import { BillContext } from "../contexts"

// Form
import { useFormContext } from "react-hook-form"

// Types
import type { SelectOptionType } from "types"
import type { FormType } from "../utils"

export function Vendor(): JSX.Element {
  // Hooks
  const data = useContext(BillContext)

  // Form
  const { getValues } = useFormContext<FormType>()

  // Vars
  const databill = data.databill
  const options: SelectOptionType[] = [
    {
      label: databill.vendor_name,
      value: databill.vendor_id
    }
  ]

  return (
    <Select
      isDisabled
      label="PAY TO"
      options={options}
      value={options.find(item => item.value === getValues("vendor_id"))}
    />
  )
}