import { RegisterDateType } from '../types';
import { useMemo } from 'react';
import { calculateRegister } from '../utils/function';
import { BaseTable } from 'components';

type FooterSummaryProps = {
  data: RegisterDateType[];
  dateFrom: string;
};

export const FooterSummary = (props: FooterSummaryProps) => {

  const calculate = useMemo(
    () => calculateRegister(props.data),
    [props.data],
  );

  return (
    <BaseTable className="w-auto min-w-[50px] md:max-w-[400px]">
      <tbody className="text-md">
        <tr className="text-right">
          <td className="w-[1px] whitespace-nowrap pr-3  uppercase font-bold">Summary Payment</td>
          <td className="">{calculate.payment.toLocaleString()}</td>
        </tr>
        <tr className="text-right">
          <td className="w-[1px] pt-2 whitespace-nowrap pr-3 uppercase font-bold">Summary Balance</td>
          <td className="pt-2 ">{calculate.balance.toLocaleString()}</td>
        </tr>
      </tbody>
    </BaseTable>
  );
};
