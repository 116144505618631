import { useEffect, useState } from "react"
import type { ErrorFetch, SuccessFetch } from "../../../../../../../../../../types"
import { DataList } from "../../../../../../types"
import { useApi } from "../../../../../../../../../../utils"

export function useDetail() {
  const api = useApi()
  const [data, setData] = useState<DataList | null>(null)
  const [dataError, setError] = useState<string | undefined>(undefined)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    api.get("/paymentterm/").then((res: SuccessFetch<{
      payload: {
        payment_term_id: number
        payment_term_code: string
        payment_term_name: number
        description: string,
      }
    }>) => {
      const data = res.data.payload

      setData(data)
    }).catch((err: ErrorFetch<{ detail: { message: string } }>) => {
      setError(err.response.data.detail.message)
    }).finally(() => {
      setLoading(false)
    })
  
    return () => {
      setData(null)
      setError(undefined)
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [])

  return { data, dataError, isLoading }
}