import { Label, NumberInput, Select } from "components"
import { Ref } from "react"
import { SingleValue } from "react-select"
import { SelectOptionType } from "types"
import { useAccounts } from "utils"

type DropdownAccountProps = {
  ref?: Ref<unknown>
  label?: string
  error?: string
  isLoading?: boolean
  value: string | null
  onChange?: (item: SingleValue<SelectOptionType>) => void
  currentBalance?: number
  isDefaultAll?: boolean
  approvalView?: boolean
  displayBalance?: boolean
}

export function DropdownAccount({
  ref,
  value,
  label,
  error,
  isLoading,
  onChange,
  currentBalance,
  isDefaultAll,
  approvalView,
  displayBalance = true

}: DropdownAccountProps) {
  const { data, isLoading: isCoaLoading } = useAccounts()
  const defaultOption = data.find((item) => item.value === value)

  return (
    <section className="w-full">
      {label ? <Label text={label} error={Boolean(error)} /> : null}

      <section className="flex gap-3 w-full">
        <div className={`w-full md:${displayBalance ? "w-5/12" : "w-full"}`}>
          <Select
            className="grow"
            placeholder="Select Account"
            error={error}
            isLoading={isLoading || isCoaLoading}
            value={defaultOption?.value ? defaultOption : null}
            defaultValue={isDefaultAll ? { value: "0", label: "All" } : null}
            options={data}
            ref={ref}
            isDisabled={approvalView}
            onChange={(item: any) => {
              if (onChange) {
                onChange(item)
              }
            }}
          />
        </div>

        {displayBalance && (
          <div className="w-full md:w-3/12">
            <NumberInput disabled textRight value={currentBalance} />
          </div>
        )}
      </section>
    </section>
  )
}
