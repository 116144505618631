import { FormType, SubmitType } from "../../types"
import { getCoaTaxJournal } from "./getCoaTaxJournal"

export const submitMap = (data: FormType): SubmitType => {
  return {
    finance_id: data.finance_id ? Number(data.finance_id) : undefined,
    journal_code: data.journal_code ? data.journal_code : undefined,
    employee_id: Number(data.card_id),
    memo: data.memo,
    transaction_date: data.transaction_date,
    location_id: Number(data.location_id),
    reference_no: data.reference_no,
    tax_inclusive: false,
    detail: data.datajournal.map((journal) => ({
      coa_id: Number(journal.coa_id),
      project_id: Number(journal.project_id),
      tax_id: Number(journal.tax_id),
      memo: journal.memo,
      amount: journal.amount,
      position: journal.position,
      reference_no: journal.referensi
    })),
    journalData: getCoaTaxJournal(data.datajournal).map((journal) => ({
      card_id: Number(data.card_id),
      group_card: Number(data.group_card),
      location_id: Number(data.location_id),
      transaction_date: data.transaction_date,
      trx_code: data.trx_code,
      detail_index: journal.detail_index,
      coa_id: Number(journal.coa_id),
      project_id: Number(journal.project_id),
      memo: journal.memo,
      amount: journal.amount,
      position: journal.position,
      reference_no: journal.referensi
    })),
    attachments:
      data.imgPath?.map((item) =>
        typeof item === "string" ? item : item.file_path
      ) ?? []
  }
}
