import Swal, { SweetAlertResult } from "sweetalert2"

export const freightAlert = async (): Promise<SweetAlertResult<any>> =>
  await Swal.fire({
    title: "Are you sure want to continue?",
    text: "This Invoice still has no freight cost",
    showCancelButton: true,
    confirmButtonText: 'Save',
    cancelButtonText: 'Cancel',
    customClass: {
      confirmButton: "btn btn-primary",
      cancelButton: "btn btn-error"
    }
  })