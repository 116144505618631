import { Card, CommandButton } from "components"

type CommandButtonsProps = {
  approvalView?: boolean
  showDeleteButton?: boolean
  onDelete?: () => void
  onSave?: () => void
}

export const CommandButtons = ({ approvalView, showDeleteButton, onDelete, onSave }: CommandButtonsProps) => {
  return (
    <Card className={approvalView ? "hidden" : ""}>
      <Card.Body className="flex flex-col gap-5">
        <section className="flex flex-wrap justify-between gap-3">
          <section className="flex flex-wrap gap-3">
            <CommandButton actiontype="help" />
            <CommandButton actiontype="print" />
            <CommandButton actiontype="email" />
            <CommandButton actiontype="journal" />
            <CommandButton actiontype="attachment" />
          </section>

          <section className="flex flex-wrap gap-3">
            {showDeleteButton && <CommandButton actiontype="delete" onClick={onDelete} />}
            <CommandButton
              actiontype="save"
              type="button"
              color="primary"
              permission="ST121"
              onClick={onSave}
            />
          </section>
        </section>
      </Card.Body>
    </Card>
  )
}
