// React
import { useEffect, useState } from "react"

// Types
import type { SelectOptionType, SuccessFetch } from "types"

// Utils
import { useApi } from "utils"

export function useProject() {
  // Hooks
  const api = useApi()
  const [data, setData] = useState<SelectOptionType[]>([])
  const [isLoading, setLoading] = useState(true)
  const [isReloading, setReloading] = useState(false)

  useEffect(() => {
    refetch()
  
    return () => {
      setData([])
      setLoading(true)
      setReloading(false)
    }

    // eslint-disable-next-line
  }, [])

  const refetch = () => {
    setReloading(true)

    api.get("/project/").then((res: SuccessFetch<{
      payload: {
        project_id: number
        project_name: string
      }[]
    }>) => {
      setData(res.data.payload.map(item => {
        return {
          label: item.project_name,
          value: item.project_id.toString()
        }
      }))
    }).catch(() => {
      setData([])
    }).finally(() => {
      setLoading(false)
      setReloading(false)
    })
  }

  return { data, isLoading, isReloading, refetch }
}