// Types
import type { MenuType } from "../types"

export const salesMenu = {
  menu: [
    {
      label: "REGISTER",
      link: "/sales/register",
      permission: "SR"
    },
    {
      label: "TRANSACTION",
      link: "#",
      permission: "ST",
      children: [
        {
          label: "SALES REGISTER",
          link: "/sales/transaction/sales-register",
          permission: "ST01"
        },
        {
          label: "CUSTOMER ORDER",
          link: "/sales/transaction/customer-order",
          permission: "ST02"
        },
        {
          label: "CUSTOMER QUOTATION",
          link: "/sales/transaction/customer-quotation",
          permission: "ST03"
        },
        {
          label: "RECORD SALES",
          link: "/sales/transaction/record-sales",
          permission: "ST04"
        },
        {
          label: "RETURN SALES",
          link: "/sales/transaction/return-sales",
          permission: "ST05"
        },
        {
          label: "RECEIVE PAYMENT",
          link: "/sales/transaction/receive-payment",
          permission: "ST06"
        },
        {
          label: "APPROVE DEPO CUSTOMER",
          link: "/sales/transaction/approve-depo-customer",
          permission: "ST13"
        },
        {
          label: "APPROVE PAYMENT INVOICE",
          link: "/sales/transaction/approve-payment-invoice",
          permission: "ST15"
        },
        {
          label: "APPROVE SO",
          link: "/sales/transaction/approve-so",
          permission: "ST10"
        },
        {
          label: "APPROVE INVOICE",
          link: "/sales/transaction/approve-invoice",
          permission: "ST11"
        },
        {
          label: "APPROVE RETURN ITEMS",
          link: "/sales/transaction/approve-return-items",
          permission: "ST18"
        },
        {
          label: "TRANSACTION JOURNAL",
          link: "/sales/transaction/transaction-journal",
          permission: "ST07"
        },
        {
          label: "DASHBOARD",
          link: "/sales/transaction/dashboard",
          permission: "ST08"
        }
      ]
    },
    {
      label: "REPORT",
      link: "#",
      permission: "SP",
      children: [
        {
          label: "AGING SUMMARY",
          link: "/sales/report/aging-summary",
          permission: "SP01"
        },
        {
          label: "AGING DETAIL",
          link: "/sales/report/ageng-detail",
          permission: "SP02"
        },
        {
          label: "SALES SUMMARY",
          link: "/sales/report/sales-summary",
          permission: "SP03"
        },
        {
          label: "ANALYSE SALES - PERIODE",
          link: "/sales/report/analyse-sales-periode",
          permission: "SP04"
        },
        {
          label: "ANALYSE SALES - COMPARATION",
          link: "/sales/report/analyse-sales-comparation",
          permission: "SP05"
        },
        {
          label: "ANALYSE SALES - MONTHLY",
          link: "/sales/report/analyse-sales-monthly",
          permission: "SP06"
        },
        {
          label: "CUSTOMER SALES DETAIL",
          link: "/sales/report/customer-sales-detail",
          permission: "SP07"
        },
        {
          label: "SALESPERSON SALES SUMMARY",
          link: "/sales/report/salesperson-sales-summary",
          permission: "SP08"
        },
        {
          label: "SALESPERSON SALES DETAIL",
          link: "/sales/report/salesperson-sales-detail",
          permission: "SP09"
        },
        {
          label: "ALL SALES",
          link: "/sales/report/all-sales",
          permission: "SP10"
        },
        {
          label: "PAYMENT HISTORY",
          link: "/sales/report/payment-history",
          permission: "SP11"
        },
        {
          label: "SALES RECEIVABLE JOURNAL",
          link: "/sales/report/sales-receivable-journal",
          permission: "SP12"
        },
        {
          label: "DASHBOARD",
          link: "/sales/report/dashboard",
          permission: "SP13"
        }
      ]
    },
    {
      label: "JOURNAL",
      link: "/sales/journal",
      // permission: "SJ"
      permission: "ST" // BELUM
    },
    {
      label: "FIND",
      link: "/sales/find",
      // permission: "SF"
      permission: "ST" // BELUM
    }
  ] as MenuType[]
}
