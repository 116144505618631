import { BaseTable, Button } from "components";
import { FilterType } from "pages/Card/Transaction/CreateNewCustomer/types";
import { useExportList } from "pages/Card/Transaction/CreateNewCustomer/utils";
import { forwardRef, useRef} from "react";
import { FaFilePdf } from "react-icons/fa6";
import { useReactToPrint } from "react-to-print";

interface DataListProps {
    fillterData: FilterType
}

export function Pdf(props: {
    dataFillter: FilterType
}) {
    const contentToPrint = useRef(null);
    const handlePdf = useReactToPrint({
        documentTitle: "Print This Document",
        removeAfterPrint: true,
    });

    return (
        <>
            <div style={{ display: "none" }} ><DataList ref={contentToPrint} fillterData={props.dataFillter} /></div>
            <Button color="warning" onClick={() => handlePdf(null, () => contentToPrint.current)}><FaFilePdf className="w-4 h-4" /> Export Pdf</Button>
        </>
    )
}

const DataList = forwardRef<null, DataListProps>((props, ref) => {

    const { data } = useExportList()

    return (
        <div ref={ref}>
            <div className="m-4">
                <BaseTable className="border-collapse">
                    <thead className="bg-yellow-400">
                        <tr className="font-bold">
                            <th>NO</th>
                            <th>KODE CUSTOMER</th>
                            <th>CUSTOMER</th>
                            {props.fillterData.handphone && <th >HANDPHONE</th>}
                            {props.fillterData.email && <th >EMAIL</th>}
                            {props.fillterData.cardLabel && <th >CARD LABEL</th>}
                            {props.fillterData.category && <th >CATEGORY</th>}
                            {props.fillterData.priceLevel && <th >PRICE LEVEL</th>}
                            {props.fillterData.bank && <th >BANK</th>}
                            {props.fillterData.bankAccount && <th >BANK ACCOUNT</th>}
                            {props.fillterData.country && <th >COUNTRY</th>}
                            {props.fillterData.province && <th >PROVINCE</th>}
                            {props.fillterData.regency && <th >REGENCY</th>}
                            {props.fillterData.subdistrict && <th >SUBDISTRICT</th>}
                            {props.fillterData.village && <th >VILLAGE</th>}
                            {props.fillterData.address && <th >ADDRESS</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((item, key) => (
                            <tr key={key}>
                                <td>{key + 1}</td>
                                <td>{item.customer_code}</td>
                                <td>{item.customer_name}</td>
                                {props.fillterData.handphone && <td>{item.handphone}</td>}
                                {props.fillterData.email && <td>{item.email}</td>}
                                {props.fillterData.cardLabel && <td>{item.card_label === 1 ? 'Individu' : 'Company'}</td>}
                                {props.fillterData.category && <td>{item.category_customer_name}</td>}
                                {props.fillterData.priceLevel && <td>{item.price_level_customer_name}</td>}
                                {props.fillterData.bank && <td>{item.bank_name}</td>}
                                {props.fillterData.bankAccount && <td>{item.bank_account}</td>}
                                {props.fillterData.country && <td>{item.country_name}</td>}
                                {props.fillterData.province && <td>{item.province_name}</td>}
                                {props.fillterData.regency && <td>{item.regency_name}</td>}
                                {props.fillterData.subdistrict && <td>{item.subdistrict_name}</td>}
                                {props.fillterData.village && <td>{item.village_name}</td>}
                                {props.fillterData.address && <td>{item.address}</td>}
                            </tr>
                        ))}
                    </tbody>
                </BaseTable>
            </div>
        </div>
    );
});

