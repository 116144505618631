// React
import { PropsWithChildren, createContext } from 'react'

// Types
import type { SelectOptionType, TaxCodeList } from 'types'

// Utils
import { useProject, useTaxCode } from 'utils'

export const DataJournalContext = createContext<{
  project: {
    data: SelectOptionType[]
    isLoading: boolean
    isReloading: boolean
    refetch: () => void
  }
  tax_code: {
    data: TaxCodeList[]
    isLoading: boolean
    isReloading: boolean
    refetch: () => void
  }
}>({
  project: {
    data: [],
    isLoading: false,
    isReloading: false,
    refetch: () => {}
  },
  tax_code: {
    data: [],
    isLoading: false,
    isReloading: false,
    refetch: () => {}
  }
})

export function DataJournalContextProvider(props: PropsWithChildren<{}>) {
  // Hooks
  const project = useProject()
  const tax_code = useTaxCode()

  return (
    <DataJournalContext.Provider value={{ project, tax_code }}>
      {props.children}
    </DataJournalContext.Provider>
  )
}