import * as yup from "yup"

export type PaymentDepositCustomerType = yup.InferType<
  typeof formPaymentDepositCustomerSchema
>

export const formPaymentDepositCustomerSchema = yup.object().shape({
  deposit_customer_id: yup
    .number()
    .label("Payment Deposit Customer")
    .optional(),
  sales_order_id: yup.number().label("Sales Order").optional(),
  coa_id: yup
    .number()
    .label("Payment Account")
    .typeError("Payment Account is required")
    .required(),
  coa_name: yup.string().label("Code").optional(),
  coa_balance: yup.number().label("Balanace"),
  customer_id: yup
    .number()
    .label("Customer")
    .typeError("Customer is required")
    .required(),
  customer_name: yup.string().label("Customer").optional(),
  amount: yup
    .number()
    .label("Payment Amount")
    .typeError("Payment Amount is required")
    .test(
      "amountRequired",
      "Payment Amount is required",
      (value) => (value ?? 0) > 0
    )
    .test(
      "insufficientBalance",
      "Insufficient Balance",
      (value, context) => (value ?? 0) <= context.parent.coa_balance
    )
    .required(),
  location_id: yup
    .number()
    .label("Location")
    .typeError("Location is required")
    .required(),
  link: yup.string().label("Link").required(),
  shipped: yup.string().label("Shipped").required(),
  memo: yup.string().label("Memo").required(),
  no_cheque: yup.string().label("No Cheque").required(),
  trx_code: yup.string().label("Code").optional(),
  transaction_date: yup.string().label("Transaction Date").required()
})
