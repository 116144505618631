// React
import { Fragment, useEffect } from "react"

// Components
import { BaseTable, Button, DateInput, Loading } from "components"
import { CheckboxSection } from "../CheckboxSection"
import { NetAssets } from "../NetAssets"
import { ListItem } from "./components"

// Contexts
import { FilterProvider } from "../../contexts"

// Form
import { Controller, FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

// Third-Party Libraries
import moment from "moment"
import toast from "react-hot-toast"

// Utils
import { useApi } from "utils"
import { validation_schema, type FormType, ItemType } from "../../utils"
import { getHistoryBalance, getOpeningBalance } from "./utils"

export function ListSection(params: {
  date: string
  list: ItemType[]
  onSuccess: (list: ItemType[]) => void
}) {
  // Hooks
  const api = useApi()

  // Form
  const onSubmit = (value: FormType) => {
    return new Promise<void>((resolve) => {
      // Vars
      const list = value.list

      if (getOpeningBalance(list, 1) - getOpeningBalance(list, 2) !== getOpeningBalance(list, 3)) {
        toast.error("NET ASSETS must be balance with EQUITY")

        return resolve()
      }

      toast.promise(
        api.post(`/openbalance/save?BalanceDate=${value.balance_date}`, {
          DataOpeningBalance: list.filter(item => item.header_level === 3)
        }),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message
        }
      ).then(() => {
        window.scrollTo({ top: 0 })
        params.onSuccess(list)
      }).catch(() => {}).finally(resolve)
    })
  }
  const methods = useForm<FormType>({
    defaultValues: {
      balance_date: params.date,
      old_balance_date: params.date,
      list: params.list
    },
    resolver: yupResolver(validation_schema)
  })

  const calculateParent = (list: ItemType[], parent_id: number) => {
    // Vars
    // eslint-disable-next-line
    const selected_item = list.find(item => item.coa_id === parent_id)!
    // eslint-disable-next-line
    const selected_item_index = list.findIndex(item => item.coa_id === parent_id)!

    methods.setValue(`list.${selected_item_index}`, {
      ...selected_item,
      opening_balance: list.filter(item => {
        return item.parent_id === selected_item.coa_id
      }).reduce((acc, { opening_balance }) => {
        return acc + opening_balance
      }, 0)
    }, { shouldValidate: true })

    return selected_item.parent_id
  }

  useEffect(() => {
    //  ** START: Recalculate all detail COA and all of it's parent ** //
    methods.getValues("list").filter(item => {
      return item.header_level === 3 && item.linked_code !== "LA03"
    }).map((item) => {
      // Vars
      let parent_id = item.parent_id

      while (parent_id !== -1) {
        parent_id = calculateParent(methods.getValues("list"), parent_id)
      }

      return null
    })
    //  ** END: Recalculate all detail COA and all of it's parent ** //

    //  ** START: Recalculate HISTORY BALANCE and all of it's parent ** //
    // Vars
    const list = methods.getValues("list")
    const history_balance = getHistoryBalance(list)
    const assets = getOpeningBalance(list, 1)
    const liability = getOpeningBalance(list, 2)
    const coa_equity_detail = list.filter(item => {
      return item.group_coa_id === 3 && item.header_level === 3 && item.linked_code !== "LA03"
    }).reduce((acc, { opening_balance }) => {
      return acc + opening_balance
    }, 0)

    if (history_balance.item) {
      methods.setValue(`list.${history_balance.index}`, {
        ...history_balance.item,
        opening_balance: assets - liability - coa_equity_detail
      })

      // Vars
      let history_balance_parent_id = history_balance.item.parent_id

      while (history_balance_parent_id !== -1) {
        history_balance_parent_id = calculateParent(list, history_balance_parent_id)
      }
    }
    //  ** END: Recalculate HISTORY BALANCE and all of it's parent ** //
  
    // eslint-disable-next-line
  }, [])

  return (
    <FilterProvider>
      <section className="flex flex-wrap justify-between items-end gap-3 peer">
        <section className="w-fit">
          <Controller
            control={methods.control}
            name="balance_date"
            render={({ field, fieldState }) => (
              <DateInput
                label="DATE OPENING BALANCE"
                error={fieldState.error?.message}
                ref={field.ref}
                selected={moment(field.value).toDate()}
                onChange={value => field.onChange(moment(value).format("YYYY-MM-DD"))}
              />
            )}
          />
        </section>

        <CheckboxSection />
      </section>

      <hr />

      <BaseTable>
        <thead>
          <tr>
            <th>ACCOUNT</th>
            <th>OPENING BALANCE</th>
          </tr>
        </thead>

        <tbody>
          {params.list.length === 0 ? (
            <td colSpan={3}>
              <Loading errorText="No data available" />
            </td>
          ) : (
            <FormProvider {...methods}>
              {methods.getValues("list").map((item, key) => {
                if (item.parent_id === -1) {
                  return (
                    <Fragment key={key}>
                      {item.group_coa_id === 3 && <NetAssets isInputOnly />}

                      <ListItem
                        index={key}
                        item={item}
                        level={1}
                      />
                    </Fragment>
                  )
                }

                return <Fragment key={key} />
              })}
            </FormProvider>
          )}
        </tbody>
      </BaseTable>

      <section className="mt-3 flex justify-end">
        <Button
          type="button"
          color="primary"
          loading={methods.formState.isSubmitting ? "true" : undefined}
          onClick={methods.handleSubmit(onSubmit)}
        >
          SAVE
        </Button>
      </section>
    </FilterProvider>
  )
}