// React
import { useState } from "react"

// Third-Party Libraries
import toast from "react-hot-toast"

// Types
import type { ErrorFetch, SuccessFetch } from "types"

// Utils
import { useApi } from "utils"

export function useConvertionData(): {
  isLoading: boolean
  getData: (product_id: string) => Promise<number>
} {
  // Hooks
  const api = useApi()
  const [isLoading, setLoading] = useState<boolean>(false)

  const getData = (product_id: string): Promise<number> => {
    setLoading(true)

    return new Promise<number>((resolve) => {
      api.get("/convertion/", {
        params: { product_id }
      }).then((res: SuccessFetch<{
        payload: {
          quantity_unit_convention: string
        }
      }>) => {
        // Vars
        const quantity_unit_convention: number = parseInt(res.data.payload.quantity_unit_convention)
        resolve(quantity_unit_convention)
      }).catch((err: ErrorFetch<{
        message: string
      }>) => {
        toast.error(err.response.data.message)
      }).finally(() => {
        setLoading(false)
      })
    })
  }

  return { isLoading, getData }
}