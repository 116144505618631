import { Fragment, useContext } from "react"
import { ActionButton, Button, Loading, Modal } from "../../../../../../../../../components"
import { FormSection } from "../../../../FormSection"
import * as yup from "yup"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import toast from "react-hot-toast"
import type { DataList } from "../../../../../types"
import { useApi, useToggle } from "../../../../../../../../../utils"
import { Render } from "../../../../../utils"
import { useDetail } from "../utils"
import { menuTitle } from "./MenuTitle"
// import { useCode } from "../../../../../utils"

export function Update(props: { id: DataList, refetch: () => void }) {
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <ActionButton.Update className="join-item" onClick={toggle} />
      {isActive && <ModalSection id={props.id} toggle={toggle} refetch={props.refetch}/>}
    </Fragment>
  )
}

function ModalSection(props: {
  id: DataList
  toggle: () => void
  refetch: () => void
}) {
  const { data, dataError, isLoading } = useDetail()

  return (
    <Modal isOpen title={`UPDATE ${menuTitle.title}`} closeModal={props.toggle}>
      {isLoading || dataError ? (
        <Loading errorText={dataError} loading={isLoading} />
      ) : (
        <DataForm data={data!} id={props.id} toggle={props.toggle} refetch={props.refetch} />
      )}
    </Modal>
  )
}

function DataForm(props: {
  data: DataList
  id: DataList
  toggle: () => void
  refetch: () => void
}) {
  const api = useApi()
  const { toggle } = useContext(Render)

  // const { dataCode } = useCode()

//   useEffect(() => {
//     if (dataCode?.next_code) {
//       methods.setValue("feature_code", dataCode?.next_code)
//     }
//  // eslint-disable-next-line
//   }, [dataCode?.next_code])

  const defaultValues = {
    menu_id: props?.id?.menu?.menu_id?.toString() ?? "",
    module_id: props?.id?.module?.module_id ?? "",
    action: props?.id?.action ?? "",
    feature_code: props?.id?.feature_code ?? "",
    feature_name: props?.id?.feature_name ?? "",
    description: props?.id?.description ?? "",
  }

  const validationSchema = yup.object().shape({
    menu_id: yup.string().label("Menu").required(),
    action: yup.number().typeError('Action is a required field').required(),
    feature_code: yup.string().label("Feature code").required().max(5, 'Maximum code is 5 characters'),
    feature_name: yup.string().label("Feature name").required(),
    description: yup.string().label("Description").optional(),
  })
  const onSubmit = ( value: DataList ) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.put(`/feature/${props.id.feature_id}`, 
        { 
          menu_id: value.menu_id, 
          action: value.action, 
          feature_code: value.feature_code, 
          feature_name: value.feature_name, 
          description: value.description, 
        }),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message
        }
      ).then(() => {
        props.toggle()
        props?.refetch && props.refetch()
        toggle()
      }).catch(() => {}).finally(resolve)
    })
  }
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })

  return (
    <FormProvider {...methods}>
      {/* @ts-ignore */}
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Modal.Body>
          <FormSection isUpdate />
        </Modal.Body>

        <Modal.Footer className="text-end">
          <Button type="submit" color="primary" loading={methods.formState.isSubmitting ? "true" : undefined}>Save</Button>
        </Modal.Footer>
      </form>
    </FormProvider>
  )
}