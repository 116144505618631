// React
import { useEffect, useState } from "react"

// Types
import type { SelectOptionType, SuccessFetch } from "../../../../../types"

// Utils
import { useApi } from "../../../../../utils"

export function useCoaGroup() {
  // Vars
  const show_all = {
    label: "Show All",
    value: ""
  }

  // Hooks
  const api = useApi()
  const [data, setData] = useState<SelectOptionType[]>([])
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    api.get("/groupcoa/").then((res: SuccessFetch<{
      payload: {
        group_coa_id: number
        group_coa_name: string
      }[]
    }>) => {
      setData([
        show_all,
        ...res.data.payload.map(item => {
          return {
            label: item.group_coa_name,
            value: item.group_coa_id.toString()
          }
        })
      ])
    }).catch(() => {
      setData([show_all])
    }).finally(() => {
      setLoading(false)
    })
  
    return () => {
      setData([])
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [])

  return { data, isLoading }
}