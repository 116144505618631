import { useEffect, useState } from "react"
import type { SelectOptionType, SuccessFetch } from "types"
import { useApi } from "utils"

export function useVendor() {
  const api = useApi()
  const [dataVendor, setData] = useState<SelectOptionType[]>([])
  const [isLoadingVendor, setLoading] = useState(true)

  useEffect(() => {
    api.get("/vendor/")
    .then((res: SuccessFetch<{
      payload: {
        vendor_id: string
        vendor_name: string }[]
    }>) => { setData(res.data.payload.map(item => { return { label: item.vendor_name, value: item.vendor_id }}))})
    .catch(() => setData([]))
    .finally(() => setLoading(false))
  
    return () => {
      setData([])
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [])

  return { dataVendor, isLoadingVendor }
}