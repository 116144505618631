// React
import { Fragment, useContext } from "react"

// Components
import { Button, Input, Modal } from "components"
import { Date, Memo, PromisedDate } from "."

// Contexts
import { ReminderContext } from "../contexts"

// Form
import { FormProvider, useForm, useFormContext } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

// Icons
import { TbPlus } from "react-icons/tb"

// Third-Party Libraries
import moment from "moment"

// Utils
import { useToggle } from "utils"
import { type ReminderFormType, reminder_validation_schema } from "../PurchaseOrder/utils"

export function AddReminder() {
  // Hooks
  const { isActive, toggle } = useToggle(false)
  const { disabled } = useContext(ReminderContext)

  return (
    <Fragment>
      <section className="flex lg:justify-end">
        <Button permission={"PT021"} className="w-fit" onClick={toggle}>
          <TbPlus /> {!disabled && "ADD"} REMINDER
        </Button>
      </section>

      {isActive && <ModalSection toggle={toggle} />}
    </Fragment>
  )
}

function ModalSection(params: { toggle: () => void }) {
  // Hooks
  const { disabled, name, transaction_from, onSuccess } = useContext(ReminderContext)

  // Form
  const { getValues } = useFormContext()
  const default_values: ReminderFormType = {
    memo: getValues(name.reminder) ? getValues(name.reminder_child.memo) : getValues(name.memo),
    promise_date: getValues(name.reminder) ? moment(getValues(name.reminder_child.promise_date)).format("YYYY-MM-DD") : moment(getValues(name.promise_date)).format("YYYY-MM-DD"),
    transaction_date: getValues(name.reminder) ? moment(getValues(name.reminder_child.transaction_date)).format("YYYY-MM-DD") : moment(getValues(name.transaction_date)).format("YYYY-MM-DD")
  }
  const onSubmit = (value: ReminderFormType) => {
    onSuccess(value)
    params.toggle()
  }
  const methods = useForm<ReminderFormType>({
    defaultValues: default_values,
    resolver: yupResolver(reminder_validation_schema)
  })

  // Vars
  const data: {
    transaction_from: string
    vendor_name: string
    referensi: string
  } = {
    transaction_from,
    referensi: getValues(name.referensi),
    vendor_name: getValues(name.vendor_name),
  }

  return (
    <Modal isOpen title="ADD REMINDER" closeModal={params.toggle}>
      <Modal.Body className="flex flex-col gap-3">
        <Input
          disabled
          label="ID#"
          value={data.referensi}
        />

        <Input
          disabled
          label="TRADE NAME"
          value={data.vendor_name}
        />

        <FormProvider {...methods}>
          <Date disabled={disabled} name="transaction_date" />
          <PromisedDate disabled={disabled} name="promise_date" />
          <Memo disabled={disabled} name="memo" />
        </FormProvider>

        <Input
          disabled
          label="TRANSACTION FROM"
          value={data.transaction_from}
        />
      </Modal.Body>

      {!disabled && (
        <Modal.Footer>
          <Button
            type="button"
            color="primary"
            disabled={disabled}
            onClick={methods.handleSubmit(onSubmit)}
          >
            SAVE
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  )
}