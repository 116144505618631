// Components
import { Card, HeaderMenu, Loading } from "components"
import { Acc, AddReminder, Date, Deliver, Freight, Memo, PromisedDate, RecurringButton, Supplier, SupplierId, TableSection, TaxInclusive, TradingTerm } from "../../components"
import { Code, Tab, Table } from "../components"

// Contexts
import { DataJournalContextProvider } from "contexts"
import { ReminderContext } from "../../contexts"
import { PurchaseOrderContext } from "../contexts"

// Form
import { FormProvider, useForm } from "react-hook-form"

// Types
import type { PurchaseOrderType } from "../types"

// Utils
import { default_option } from "pages/Purchase/Register/utils"
import { type FormType, useDetail } from "../utils"

export function Detail(params: {
  purchase_order_id: string
}): JSX.Element {
  // Hooks
  const { data, isLoading } = useDetail(params.purchase_order_id)

  if (isLoading || !data) {
    return (
      <Loading
        errorText="No data available"
        loading={isLoading}
      />
    )
  }

  return (
    <PurchaseOrderContext.Provider value={{ disabled: true }}>
      <FormSection
        data={data}
        id={params.purchase_order_id}
      />
    </PurchaseOrderContext.Provider>
  )
}

function FormSection(params: {
  data: PurchaseOrderType
  id: string
}): JSX.Element {
  // Vars
  const { trx_code, po_delivery_status, ...rest } = params.data

  // Form
  const default_values: FormType = rest
  const methods = useForm<FormType>({
    defaultValues: default_values
  })

  return (
    <FormProvider {...methods}>
      <section className="flex flex-col gap-2">
        <HeaderMenu title="DATA ENTRY | PURCHASE ORDER">
          <Code value={trx_code} />
        </HeaderMenu>

        <section className="grid lg:grid-cols-3 gap-x-6 gap-y-3 items-end">
          <Supplier
            disabled
            name="vendor_id"
            trading_term_name="payment_term"
            vendor_name="_vendor_name"
            detail="podetail"
          />

          <TradingTerm name="payment_term" />
          <TaxInclusive name="tax_inclusive" />
        </section>

        <Card>
          <Card.Body className="grid lg:grid-cols-2 gap-x-6 gap-y-3">
            <section className="flex flex-col gap-3">
              <Deliver
                disabled
                deliver_name="location_id"
                detail_name="address"
              />

              <Memo
                disabled
                name="memo"
              />
            </section>

            <section className="flex flex-col gap-3">
              <Date
                disabled
                name="transaction_date"
              />

              <SupplierId
                disabled
                name="referensi"
              />

              <PromisedDate
                disabled
                name="promize_date"
              />

              <ReminderContext.Provider
                value={{
                  disabled: true,
                  transaction_from: "PURCHASE ORDER",
                  name: {
                    memo: "memo",
                    promise_date: "promize_date",
                    reminder: "reminder",
                    referensi: "referensi",
                    transaction_date: "transaction_date",
                    vendor_name: "_vendor_name",
                    reminder_child: {
                      memo: "reminder.memo",
                      promise_date: "reminder.promise_date",
                      transaction_date: "reminder.transaction_date"
                    }
                  },
                  onSuccess: (value) => {
                    methods.setValue("reminder.memo", value.memo)
                    methods.setValue("reminder.promise_date", value.promise_date)
                    methods.setValue("reminder.transaction_date", value.transaction_date)
                  }
                }}
              >
                <AddReminder />
              </ReminderContext.Provider>
            </section>
          </Card.Body>
        </Card>

        <DataJournalContextProvider>
          <Table />

          <section className="grid lg:grid-cols-2 gap-x-6 gap-y-3">
            <section className="flex flex-col gap-3">
              <TableSection>
                <TableSection.Row
                  label="PAYMENT STATUS"
                  value={default_option[methods.getValues("approval_status")]?.label ?? "-"}
                />

                <TableSection.Row
                  label="APPROVE STATUS"
                  value="-"
                />

                <TableSection.Row
                  label="APPROVED BY"
                  value="-"
                />
              </TableSection>

              <Freight
                disabled
                name="freight"
              />

              <RecurringButton />
            </section>

            <Acc
              name={{
                detail: "podetail",
                freight: "freight"
              }}
            />
          </section>
        </DataJournalContextProvider>

        <Tab />
      </section>
    </FormProvider>
  )
}
