/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react"
import { SuccessFetch } from "types"
import { useApi } from "utils"
import { DetailInvoice } from "../../types"

type UseDetailInvoice = {
  invoice_id: string
  onSuccess?: (data: DetailInvoice) => void
}

type Response = {
  payload: DetailInvoice
}

export function useDetailInvoice({ invoice_id, onSuccess }: UseDetailInvoice) {
  const api = useApi()
  const [data, setData] = useState<DetailInvoice | null>(null)
  const [isLoading, setLoading] = useState(false)

  const fetchData = () => {
    setLoading(true)

    const params = {
      invoice_id,
    }

    api
      .get("/invoice/detailinvoice", { params })
      .then((res: SuccessFetch<Response>) => {
        const data = res.data.payload
        onSuccess?.(data)
        setData(data)
      })
      .catch(() => setData(null))
      .finally(() => setLoading(false))
  }

  const refetch = () => fetchData()

  useEffect(() => {
    if (invoice_id) {
      fetchData()
    }

    return () => {
      setData(null)
      setLoading(false)
    }
  }, [invoice_id])

  return { data, isLoading, refetch }
}
