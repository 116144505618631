/* eslint-disable react-hooks/exhaustive-deps */
import { useFormContext, useWatch } from "react-hook-form"
import { FormReceivedType } from "../types"
import { useReceivedCode } from "../utils/hooks"

export const CodeReceived = ({ isEdit }: { isEdit: boolean }) => {
  const { control, setValue, formState } = useFormContext<FormReceivedType>()
  const [transaction_date, trx_code] = useWatch({
    control,
    name: ["transaction_date", "trx_code"]
  })

  useReceivedCode({
    key: formState.submitCount,
    trx_date: transaction_date,
    onSuccess: (data) =>
      setTimeout(() => {
        setValue("trx_code", data)
      }, 1000),
    enabled: !isEdit && Boolean(transaction_date)
  })

  return <div>{trx_code}</div>
}
