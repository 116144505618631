/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react"
import { SuccessFetch } from "types"
import { useApi } from "utils"
import { TaxCode } from "types"

type Response = {
  payload: TaxCode[]
}

export function useTaxCodeList() {
  const api = useApi()
  const [data, setData] = useState<TaxCode[]>([])
  const [isLoading, setLoading] = useState(false)

  const fetchData = () => {
    setLoading(true)

    api
      .get("/taxcode/")
      .then((res: SuccessFetch<Response>) => {
        const data = res.data.payload ?? []
        setData(data)
      })
      .catch(() => setData([]))
      .finally(() => setLoading(false))
  }

  const refetch = () => fetchData()

  useEffect(() => {
    fetchData()

    return () => {
      setData([])
      setLoading(false)
    }
  }, [])

  return {
    data,
    isLoading,
    refetch,
    dropdown: data.map((item) => ({
      label: `${item.tax_name} (${item.rate}%)`,
      value: item.tax_id.toString(),
      rate: item.rate,
      tax_coa_collect: `${item.coa_collect_grp_code}-${item.coa_collect_coa_code} ${item.coa_collect_coa_name}`,
      tax_coa_collect_id: item.coa_collect_id,
      tax_coa_paid: `${item.coa_paid_grp_code}-${item.coa_paid_coa_code} ${item.coa_paid_coa_name}`,
      tax_coa_paid_id: item.coa_paid_id
    }))
  }
}
