// React
import { Fragment, useContext } from "react"

// Components
import { ApproveAction } from "./ApproveAction"

// Third-Party Libraries
import moment from "moment"

// Utils
import { statusMap } from "../utils"
import { convertNumber } from "utils"

// context
import { dataContext } from "pages/Sales/Transaction/ApprovalSO"
import { TableNumber } from "components"


export function Item(props: {
  data: any,
  index: number,
  refetch: () => void
}) {

  const { currentPage, limit, size, total } = useContext(dataContext)

  return (
    <Fragment>
      <tr key={props.index}>
        <td>{TableNumber({ dataLength: size, pageCurrent: currentPage, index: props.index, limit: limit, totalData: total })}</td>
        <td>
          <ApproveAction data={props.data} refetch={props.refetch} />
        </td>
        <td>{props.data.trx_code}</td>
        <td>{moment(props.data.transaction_date).format("DD/MM/YYYY")}</td>
        <td>{props.data.customer.customer_name}</td>
        <td className="text-center">{props.data.qty_order}</td>
        <td className="text-right">{convertNumber(props.data.total_sales).intoCurrency}</td>
        <td>{props.data.location.location_name}</td>
        <td>{props.data.memo}</td>
        <td>{statusMap[props.data.approval_status]}</td>
        <td>{props.data.approve_date ? moment(props.data.approve_date).format("DD/MM/YYYY") : ""}</td>
      </tr>
    </Fragment>
  )
}