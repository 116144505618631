// React
import { PropsWithChildren } from "react"

export function HeaderMenu(params: PropsWithChildren<{
  title: string
}>) {
  return (
    <div className="card card-compact bg-black">
      <section className={`p-3 flex flex-wrap text-white text-xl font-bold items-center gap-3 ${params.children ? "justify-between" : "justify-center"}`}>
        <div>{params.title}</div>

        {params.children && (
          <div>
            {params.children}
          </div>
        )}
      </section>
    </div>
  )
}