// React
import { FocusEventHandler, ReactNode, Ref, forwardRef } from "react"

// React-Select
import AsyncSelect from "react-select/async"
import {
  ActionMeta,
  GroupBase,
  OptionsOrGroups,
  PropsValue,
  SingleValue
} from "react-select"

export const Select = forwardRef(
  (
    {
      rounded = true,
      ...props
    }: {
      rounded?: boolean
      className?: string
      controlClass?: string
      width?: boolean
      defaultValue?: PropsValue<{
        value: string
        label: string
      }>
      error?: string
      isDisabled?: boolean
      isLoading?: boolean
      textWhite?: boolean
      label?: string
      onBlur?: FocusEventHandler<HTMLInputElement>
      onChange?:
        | ((
            newValue: SingleValue<{
              value: string
              label: string
            }>,
            actionMeta: ActionMeta<{
              value: string
              label: string
            }>
          ) => void)
        | undefined
      options?: OptionsOrGroups<
        {
          value: string
          label: string
        },
        GroupBase<{
          value: string
          label: string
        }>
      >
      placeholder?: ReactNode
      ref?: Ref<any>
      showPlaceholder?: boolean
      value?: PropsValue<{
        value: string
        label: string
      }>
    },
    ref
  ) => {
    // Variables
    const error = {
      true: {
        control: "!border-error",
        value: "!text-error"
      },
      false: {
        control: "dark:!border-gray-400",
        value: "dark:!text-gray-400"
      }
    }

    const filterColors = (inputValue: string) => {
      return (
        props.options?.filter(
          (i) => i.label?.toLowerCase().includes(inputValue.toLowerCase()) ?? ""
        ) ?? []
      )
    }

    const loadOptions = (
      inputValue: string,
      callback: (options: any) => void
    ) => {
      setTimeout(() => {
        callback(filterColors(inputValue))
      }, 300)
    }

    return (
      <section
        className={`${!props.width && "w-full"} ${
          props.isDisabled && "!cursor-not-allowed"
        }`}>
        {props.label && (
          <label className="label">
            <span
              className={`label-text font-bold ${props?.textWhite && "!text-[#cacccb]"} ${props.error && "text-error"}`}>
              {props.label}
            </span>
          </label>
        )}

        <AsyncSelect
          {...props}
          // @ts-ignore
          ref={ref}
          cacheOptions
          className={props.className}
          defaultOptions={props.options}
          menuPortalTarget={document.body}
          loadOptions={loadOptions}
          placeholder={
            props.showPlaceholder || !props.isDisabled ? props.placeholder : "-"
          }
          loadingMessage={() => <div>Loading items...</div>}
          classNames={{
            control: () =>
              `${rounded ? "!rounded-lg" : ""} !py-1 h-[48px] ${
                props.controlClass
              } ${
                props.isDisabled
                  ? "!bg-gray-200 dark:!bg-slate-800 dark:!border-slate-800"
                  : "!bg-base-100"
              } ${error[props.error ? "true" : "false"].control}`,
            menu: () => "!bg-base-100",
            option: (state) =>
              state.isFocused
                ? "dark:!bg-gray-900"
                : state.isSelected
                ? "dark:!bg-gray-500"
                : "",
            singleValue: () => `${error[props.error ? "true" : "false"].value}`,
            placeholder: () => `${error[props.error ? "true" : "false"].value}`,
            dropdownIndicator: () =>
              `${error[props.error ? "true" : "false"].value}`
          }}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        />

        {props.error && (
          <label className="label">
            <span
              className={`label-text-alt font-bold ${
                props.error && "text-error"
              }`}>
              {props.error}
            </span>
          </label>
        )}
      </section>
    )
  }
)
