import { Input } from "components";
import { FaChartLine } from "react-icons/fa6";

type FixedCostPerUnitProps = { disable?: boolean };

export const FixedCostPerUnit = ({ disable }: FixedCostPerUnitProps) => {
    const formula = "The formula to find the fixed cost per unit is simply the total fixed costs divided by the total number of units produced. As an example, suppose that a company had fixed expensesof $120,000 per year and produced 10,000 widgets. The fixed costper unit would be $120,000/10,000 or $12/unit."
    const totalNumber = "Total Number of unit produced YTD more the company produced the smaller number cost per unit"

    return (
      <>
        <div className="text-[16px] mb-5"><b>FIXED COST PER UNIT</b></div>
        <small className="mb-5">{formula}</small>
        <div className="mr-3 mt-2 text-[13px]"><b>FIXED COST PER UNIT</b></div>
        <div className="flex items-center my-2">
            <Input disabled={disable} inputClass="rounded-r-none max-w-[100px]" />
            <Input disabled={disable} inputClass="rounded-l-none max-w-[100px]" />
            <small className="ml-2">Current fixed costs per unit</small>
        </div>
        <div className="w-[200px]"><small className="mt-5">{totalNumber}</small></div>
        <div className="flex items-center mt-2">
            <FaChartLine className="text-[50px]" />
            <div className="mx-3 text-[13px] w-[100px]"><b>IF PRODUCE</b></div>
            <Input disabled={disable} inputClass="" />
        </div>
      </>
    )
}