// React
import { useEffect, useState } from "react"

// Types
import type { SuccessFetch } from "../../types"
import type { CoaGroup } from "../../types"

// Utils
import { useApi } from "./useApi"

export function useCoaList(parent_id?: number, group_id?: string) {
  // Hooks
  const api = useApi()
  const [data, setData] = useState<CoaGroup[]>([])
  const [isLoading, setLoading] = useState(true)
  const [search, setSearch] = useState("")

  useEffect(() => {
    refetch()
  
    return () => {
      setData([])
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [])

  const refetch = () => {
    setLoading(true)

    const fetchData = () => {
      if (search) {
        return api.get("/coa/filtercoabycodeorname", {
          params: {
            group_id,
            coa_code_or_name: search
          }
        })
      } else if (parent_id) {
        return api.get("/coa/byparentid", { params: { parent_id }})
      } else {
        return api.get("/coa/header", { params: { group_id: group_id && group_id !== "" ? group_id : undefined } })
      }
    }

    fetchData().then((res: SuccessFetch<{
      payload: {
        childcount: number
        coa_id: number
        group_coa_code: string
        coa_code: string
        coa_name: string
        parent_id: number
        coa_level: number
        cheque_coa: boolean
        budget_coa: boolean
        opening_balance: number | null
        account_type: string
        account_type_id: number | null
        group_coa_id: number
        header_level: 1 | 2 | 3
        report_type_name: string
        account_type_name: string
        note: string
      }[]
    }>) => {
      setData(res.data.payload.map(item => {
        return {
          ...item,
          account_type_id: item.account_type_id?.toString() ?? "",
          account_type: item.header_level === 1 ? "Header" : item.header_level === 2 ? "Sub Header" : "Detail",
          group_coa_id: item.group_coa_id.toString(),
          report_type: item.report_type_name,
        }
      }))
    }).catch(() => {
      setData([])
    }).finally(() => {
      setLoading(false)
    })
  }

  return { data, isLoading, search, refetch, setSearch }
}