/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";
import type { SuccessFetch } from "types";
import { useApi } from "utils";

type Response = SuccessFetch<{ payload: { next_code: string } }>;

export const useCode = (trx_date: string, enabled: boolean, submitCount: number) => {
  const api = useApi();
  const [data, setData] = useState<string>("-");

  useEffect(() => {
    if (enabled) {
      api
        .post("/general-entry/generate-code", {
          trx_date
        })
        .then((res: Response) => {
          setData(res.data.payload.next_code);
        });
    }
  }, [trx_date, submitCount]);

  return { data };
};
