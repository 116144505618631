import * as yup from "yup"

export type FormInvoiceDetailType = yup.InferType<
  typeof formInvoiceDetailSchema
>

export const formInvoiceDetailSchema = yup.object().shape({
  return_detail_id: yup.number().label("Return").optional(),
  quantity_back_delivered: yup.number().optional(),
  quantity_invoice: yup.number().required(),
  quantity_returned: yup
    .number()
    .required("Quantity Returned ")
    .test(
      "quantityReturnedShouldBeLessThanQuantityBackDelivered",
      "Should be less than Qty. Back Delivered",
      (value, context) => (value ?? 0) <= context.parent.quantity_back_delivered
    ),
  product_id: yup
    .number()
    .label("Product")
    .typeError("Product is required")
    .required(),
  product_name: yup.string().label("Product").optional(),
  product_barcode: yup.string().label("Product").optional(),
  mr_unit_id: yup
    .number()
    .label("Unit")
    .typeError("Unit is required")
    .required(),
  mr_unit_name: yup.string().label("Unit").optional(),
  price_invoice: yup.number().label("Price Invoice").required(),
  discount: yup.number().label("Discount").required(),
  total: yup.number().label("Total").required(),
  tax_id: yup.number().label("Tax").typeError("Tax is required").required(),
  tax_name: yup.string().label("Tax").optional(),
  tax_rate: yup.number().label("Tax").optional()
})
