import { yupResolver } from "@hookform/resolvers/yup"
import { ActionButton, Button, Input, NumberInput, Select } from "components"
import { useTax } from "pages/Inventori/Transaction/AddNewItem/components/FormSection/utils"
import { ValueList } from "pages/Inventori/Transaction/AddNewItem/types/ValueList"
import { createContext, useContext, useEffect, useState } from "react"
import { Controller, FormProvider, useForm } from "react-hook-form"
import { TbEye } from "react-icons/tb"
import { SelectOptionType } from "types"
import { useToggle } from "utils"
import * as yup from "yup"
import { ModalSectionPortal } from "./ModalSectionPortal"
import { FaDoorOpen } from "react-icons/fa";

interface DataFormProps {
    action?: "CREATE" | "UPDATE" | "DETAIL"
    defaultValue?: ValueList
    method: "add" | "edit"
    onAddDataList: (value: ValueList) => void
    onCancelEditing?: () => void
    setCheckEdit: ( newState: string ) => void
}
export const DataForm = ({ action, defaultValue, method, onAddDataList, onCancelEditing, setCheckEdit }: DataFormProps) => {
    const { dataTax, isLoadingTax } = useTax()
    const { dropdownVendor } = useContext(DataTableContext)
    const [modalConfig, setModalConfig] = useState({ show: false, action: "", data: {} })
    const { isActive, toggle } = useToggle(false)
  
    const defaultValues: ValueList = {
      tax_id: "2",
      tax_name: "N-T (0%)",
      vendor_description: "",
      buying_price: "",
      vendor_id: "",
      vendor_name: "",
      vendor_code: "",
      product_buying_id: NaN,
    }
    const validationSchema = yup.object().shape({
      tax_id: yup.string().label("Tax").defined(),
      tax_name: yup.string().label("Tax name").defined(),
      vendor_description: yup.string().label("Description").required(),
      buying_price: yup.string().label("buying price").defined(),
      vendor_id: yup.string().label("Vendor").required(),
      vendor_name: yup.string().label("Vendor name").defined(),
      vendor_code: yup.string().label("Vendor code").defined(),
    })
    const methods = useForm<ValueList>({
      defaultValues: method === "edit" ? defaultValue : defaultValues,
      resolver: yupResolver(validationSchema)
    })
    const onSubmit = (value: ValueList) => {
      onAddDataList(value)
      setCheckEdit("onAdd")
      methods.reset()
    }
  
    useEffect(() => {
      if (method === "add") {
        methods.reset({
          tax_id: "2",
          tax_name: "",
          vendor_description: "",
          buying_price: "",
          vendor_id: "",
          vendor_name: "",
          vendor_code: "",
        })
      }
      // eslint-disable-next-line
    }, [])
  
    return (
      <FormProvider {...methods}>
        <tr className="no-padding-body">
          <td>{!isActive && method === "add" && <TbEye className="size-5 mx-auto my-3 text-center" onClick={toggle} />}</td>
          {method === "add" 
            ? isActive && <>
                <td><div/></td>
                <td>
                  <div className="flex"> 
                    <Controller
                      control={methods.control}
                      name="vendor_id"
                      render={({ field, fieldState }) => {
                        return(
                          <Select
                            controlClass={action !== "DETAIL" ? "!rounded-r-none w-[150px]" : ""}
                            placeholder="Select vendor"
                            isDisabled={action === "DETAIL"}
                            isLoading={dropdownVendor.isLoadingVendor}
                            error={fieldState.error?.message}
                            options={dropdownVendor.dataVendor}
                            value={ dropdownVendor.dataVendor.find(item => item.value === field.value?.toString())}
                            onChange={e => {
                              field.onChange(e?.value)
                              // @ts-ignore
                              methods.setValue("vendor_code", e?.code_vendor)
                              methods.setValue("vendor_name", e?.label!)
                              dropdownVendor.refetchVendor()
                              methods.setValue("tax_name", "N-T (0%)")
                            }}
                          />
                      )}}
                    />
                    {action !== "DETAIL" && 
                      <Button
                        type="button" 
                        color="primary" 
                        className="!rounded-r tooltip"  data-tip="Portal"
                        onClick={() => setModalConfig({ ...modalConfig, show: true, action: "PORTAL" })}
                      >
                        <FaDoorOpen />
                      </Button>
                    }
                  </div>
                </td>
                <td>
                  <Controller
                    control={methods.control}
                    name="vendor_description"
                    render={({ field, fieldState }) => 
                      <Input
                        {...field} 
                        type="text" 
                        inputClass="w-[120px]"
                        error={fieldState.error?.message}
                        placeholder="Input description"
                        disabled={action === "DETAIL"} 
                      />
                    }
                  />
                </td>
                <td>
                  <Controller
                    control={methods.control}
                    name="buying_price"
                    render={({ field, fieldState }) => 
                      <NumberInput
                        decimalSeparator='.'
                        thousandSeparator=","
                        className="text-gray-400"
                        placeholder="Input price"
                        inputClass="w-[135px]"
                        error={fieldState.error?.message}
                        value={field.value}
                        onValueChange={value => field.onChange(value.floatValue)}
                        disabled={action === "DETAIL"} 
                      />
                    }
                  />
                </td>
                <td>
                  <Controller
                    control={methods.control}
                    name="tax_id"
                    render={({ field, fieldState }) => {
                      return(
                      <Select
                        controlClass="w-[100px]"
                        placeholder="Select Tax"
                        isDisabled={action === "DETAIL"}
                        isLoading={isLoadingTax}
                        error={fieldState.error?.message}
                        options={dataTax}
                        value={dataTax.find(item => item.value?.toString() === field.value?.toString())}
                        onChange={e => {
                          field.onChange(e?.value)
                          methods.setValue("tax_name", e?.label!)
                        }}
                      />
                    )}}
                  />
                </td>
                <td className="text-center flex items-center justify-center">
                  {onCancelEditing && <ActionButton.Close onClick={onCancelEditing} />}
                  <ActionButton.Add loading={methods.formState.isSubmitting ? "true" : undefined} onClick={methods.handleSubmit(onSubmit)} />
                </td>
              </>
            : <>
                <td><div /></td>
                <td>
                  <div className="flex"> 
                    <Controller
                      control={methods.control}
                      name="vendor_id"
                      render={({ field, fieldState }) => {
                        return(
                          <Select
                            controlClass={action !== "DETAIL" ? "!rounded-r-none w-[150px]" : ""}
                            placeholder="Select vendor"
                            isDisabled={action === "DETAIL"}
                            isLoading={dropdownVendor.isLoadingVendor}
                            error={fieldState.error?.message}
                            options={dropdownVendor.dataVendor}
                            value={ dropdownVendor.dataVendor.find(item => item.value === field.value?.toString())}
                            onChange={e => {
                              field.onChange(e?.value)
                              // @ts-ignore
                              methods.setValue("vendor_code", e?.code_vendor)
                              methods.setValue("vendor_name", e?.label!)
                              dropdownVendor.refetchVendor()
                            }}
                          />
                      )}}
                    />
                    {action !== "DETAIL" && 
                      <Button 
                        type="button" 
                        color="primary" 
                        className="!rounded-r tooltip"  data-tip="Portal"
                        onClick={() => setModalConfig({ ...modalConfig, show: true, action: "PORTAL" })}
                      >
                        <FaDoorOpen />
                      </Button>
                    }
                  </div>
                </td>
                <td>
                  <Controller
                    control={methods.control}
                    name="vendor_description"
                    render={({ field, fieldState }) => 
                      <Input 
                        {...field} 
                        type="text" 
                        inputClass="w-[120px]"
                        error={fieldState.error?.message}
                        placeholder="Input description"
                        disabled={action === "DETAIL"} 
                      />
                    }
                  />
                </td>
                <td>
                  <Controller
                    control={methods.control}
                    name="buying_price"
                    render={({ field, fieldState }) => 
                      <NumberInput
                        decimalSeparator='.'
                        thousandSeparator=","
                        className="text-gray-400"
                        placeholder="Input price"
                        inputClass="w-[135px]"
                        error={fieldState.error?.message}
                        value={field.value}
                        onValueChange={value => field.onChange(value.floatValue)}
                        disabled={action === "DETAIL"} 
                      />
                    }
                  />
                </td>
                <td>
                  <Controller
                    control={methods.control}
                    name="tax_id"
                    render={({ field, fieldState }) => {
                      return(
                      <Select
                        controlClass="w-[100px]"
                        placeholder="Select Tax"
                        isDisabled={action === "DETAIL"}
                        isLoading={isLoadingTax}
                        error={fieldState.error?.message}
                        options={dataTax}
                        value={dataTax.find(item => item.value?.toString() === field.value?.toString())}
                        onChange={e => {
                          field.onChange(e?.value)
                          methods.setValue("tax_name", e?.label!)
                        }}
                      />
                    )}}
                  />
                </td>
                <td className="text-center flex items-center justify-center">
                  {onCancelEditing && <ActionButton.Close onClick={onCancelEditing} />}
                  <ActionButton.Add loading={methods.formState.isSubmitting ? "true" : undefined} onClick={methods.handleSubmit(onSubmit)} />
                </td>
              </>
          }
        </tr>
        {modalConfig?.show === true && modalConfig?.action === "PORTAL" && <ModalSectionPortal data={methods.control?._formValues?.vendor_id} toggle={() => setModalConfig({ ...modalConfig, show: false, action: "PORTAL" })} />}
      </FormProvider>
    )
}

interface Type extends SelectOptionType { code_vendor: string }
export const DataTableContext = createContext<{ 
  dropdownVendor: {
    dataVendor: Type[]
    isLoadingVendor: boolean
    refetchVendor: () => void
  }
}>({
  dropdownVendor: {
    dataVendor: [],
    isLoadingVendor: false,
    refetchVendor: () => {}
  }
})