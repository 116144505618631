import * as yup from "yup"

const detail = yup.object().shape({
  quantity_bill: yup.number().defined(),
  quantity_rate: yup.number().defined(),
  quantity_reject: yup.number().label("Quantity Rejected").required().min(1).max(yup.ref("quantity_bill")),
  product_id: yup.number().defined(),
  price_reject: yup.number().defined(),
  discount: yup.number().defined(),
  tax_id: yup.number().defined(),
  tax_rate: yup.number().defined()
})
export const validationDetailSchema = yup.object().shape({
  quantity_bill: yup.number().defined(),
  quantity_reject: yup.number().label("Quantity Rejected").required().min(1).max(yup.ref("quantity_bill")),
})
export const validationSchema = yup.object().shape({
  trx_code: yup.string().defined(),
  transaction_date: yup.string().label("Date").required(),
  memo: yup.string().label("Memo").required(),
  referensi: yup.string().label("Referensi").required(),
  prorejectdetail: yup.array().label("Reject Detail").min(1).required().of(detail)
})

export type DetailType = yup.InferType<typeof detail>
export type FormDetailType = yup.InferType<typeof validationDetailSchema>
export type FormType = yup.InferType<typeof validationSchema>