// React
import { DetailedHTMLProps, Fragment, PropsWithChildren, TdHTMLAttributes } from "react"

// Icons
import { TiArrowSortedDown, TiArrowSortedUp, TiArrowUnsorted } from "react-icons/ti"

type Sort = {
  field: string
  dir: 'asc' | 'desc'
}

export function Table(props: PropsWithChildren<{
  className?: string
  columns: {
    title: string
    thProps?: DetailedHTMLProps<TdHTMLAttributes<HTMLTableDataCellElement>, HTMLTableDataCellElement>
    tdProps?: DetailedHTMLProps<TdHTMLAttributes<HTMLTableDataCellElement>, HTMLTableDataCellElement>
    field?: (item: any, key: number) => JSX.Element | string | number
    sortField?: string
  }[]
  data?: {
    [key: string]: any
  }[]
  isLoading?: boolean
  onSortChange?: (props: Sort) => void
  sort?: Sort
}>): JSX.Element {
  return (
    <table className={`table ${props.className}`}>
      <thead>
        <tr>
          {props.columns.map((item, key) => {
            return (
              <th
                {...item.thProps}
                key={key}
                className={`!font-bold !text-center !bg-[#f6f6f6] dark:!bg-slate-500 !border-none ${item.sortField ? "cursor-pointer" : ""} ${item.thProps?.className}`}
                onClick={() => {
                  if (props.onSortChange && item.sortField) {
                    // Variables
                    let is_asc = props.sort?.dir ?? true

                    if (props.sort?.field === item.sortField && props.sort?.dir === "asc") {
                      is_asc = false
                    }

                    props.onSortChange({
                      field: item.sortField,
                      dir: is_asc ? 'asc' : 'desc'
                    })
                  }
                }}
              >
                <div className="justify-center flex items-center gap-x-2">
                  <span>{item.title}</span>

                  {item.sortField && (
                    <Fragment>
                      {props.sort?.field !== item.sortField ? (
                        <TiArrowUnsorted />
                      ) : props.sort?.dir === "desc" ? (
                        <TiArrowSortedDown />
                      ) : (
                        <TiArrowSortedUp />
                      )}
                    </Fragment>
                  )}
                </div>
              </th>
            )
          })}
        </tr>
      </thead>

      {props.children ?? (
        <tbody>
          {props.isLoading ? (
            <tr>
              <td colSpan={props.columns.length} className="!py-10">
                <span className="loading loading-spinner loading-lg"></span>
              </td>
            </tr>
          ) : props.data && (
            <Fragment>
              {props.data.length === 0 ? (
                <tr>
                  <td colSpan={props.columns.length} className="!text-center">No Data Available</td>
                </tr>
              ) : (
                <Fragment>
                  {props.data.map((item, key) => (
                    <tr key={key}>
                      {props.columns.map((cItem, cKey) => (
                        <td key={cKey} {...cItem.tdProps} className={`!border-b-1 !border-b-gray-300 ${typeof cItem.field === "number" && "text-center"} ${cItem.tdProps?.className}`}>
                          {cItem.field ? cItem.field(item, key) : "-"}
                        </td>
                      ))}
                    </tr>
                  ))}
                </Fragment>
              )}
            </Fragment>
          )}
        </tbody>
      )}
    </table>
  )
}