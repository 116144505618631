// Types
import type { FormType } from "../../types"

interface Type extends Omit<FormType, "opening_balance"> {
  opening_balance?: number
  parent_id?: number
}

export function getSubmitValue(value: Type, parent_id: number | undefined) {
  // Vars
  const { group_coa_code, report_type, parent_name, ...data } = value

  if (parent_id) {
    data.parent_id = parent_id
  }

  if (value.header_level === "2") {
    delete data.opening_balance
  }
  
  return data
}