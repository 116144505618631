import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useApi } from "utils";
import { FormSection } from "./components";
import { FormType } from "./types";
import { submitMap, totalAmountJournal } from "./utils/function";
import { validationSchema } from "./utils/vars";

export default function GeneralEntry() {
  const api = useApi();

  const defaultValues: FormType = {
    balance: 0,
    card_id: "",
    amount: 0,
    datajournal: [],
    group_card: "1",
    imgPath: [],
    location_id: "",
    memo: "General Entry transaction",
    reconsiled: false,
    reference_no: "",
    tax_inclusive: false,
    transaction_date: moment().format("YYYY-MM-DD"),
    trx_code: "",
    type_payment: "",
    name_payment: "",
    number_payment: "",
    expired_payment: "",
    authorization_payment: "",
    note_payment: ""
  };

  const methods = useForm<FormType>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = async (value: FormType) => {
    const totalAmount = totalAmountJournal(value.datajournal);
    if (totalAmount.debet !== totalAmount.credit) {
      return toast.error("Journal is not balance");
    }

    await toast
      .promise(api.post("/general-entry", submitMap(value)), {
        loading: "Loading...",
        success: (res) => res.data.message,
        error: (err) =>
          err.response.data.detail?.message ?? err.response.data.message
      })
      .then(() => {
        methods.reset(defaultValues);
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      })
      .catch((err) => {
        throw new Error(err);
      });
  };

  return (
    <FormProvider {...methods}>
      <FormSection onSubmit={methods.handleSubmit(onSubmit)} />
    </FormProvider>
  );
}
