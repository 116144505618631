// React
import { useContext } from "react"

// Components
import { Input } from "components"

// Contexts
import { DisabledContext } from "../contexts"

// Form
import { Controller, useFormContext } from "react-hook-form"

export function Memo(params: {
  disabled?: boolean
  name: string
}) {
  // Hooks
  const disabled = useContext(DisabledContext)

  // Form
  const { control } = useFormContext()

  return (
    <Controller
      control={control}
      name={params.name}
      render={({ field, fieldState }) => (
        <Input
          {...field}
          label="MEMO"
          disabled={params.disabled || disabled}
          error={fieldState.error?.message}
        />
      )}
    />
  )
}