import { DateInput, Select } from 'components'
import moment from 'moment'
import { SearchingSectionProps } from 'pages/Purchase/Register/types/Location'
import { useEffect } from 'react';
import { useListLocation } from '../hooks';

export const SearchingSections = ({ selected, setSelected }: SearchingSectionProps) => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();
  const dateTo = new Date(year, month, 1);
  const dateEnd = new Date(year, month + 1, 0);

  const { dataLocation, isLoadingLocation } = useListLocation()
  const dataDelivered = [
    { value: "1", label: "Pending" },
    { value: "2", label: "Part Delivered" },
    { value: "3", label: "Full Delivered" }
  ]

  const sortedSelectLocation = dataLocation.sort((a, b) => parseInt(b.value) - parseInt(a.value));
  const sortedSelectDelivered = dataDelivered.sort((a, b) => parseInt(b.value) - parseInt(a.value));
  const allOption = { value: "", label: "All" };

  useEffect(() => {
    if(selected?.date_from === "") { setSelected({ ...selected, date_from: moment(dateTo).format("YYYY-MM-DD")}) }
    if(selected?.date_end === "") { setSelected({ ...selected, date_end: moment(dateEnd).format("YYYY-MM-DD")}) }

    // eslint-disable-next-line
  }, [selected])

  return (
    <section className="lg:flex overflow-x-auto">
        <div className='flex items-center w-[284px] lg:w-[240px]'>
            <div className="label-text pb-1 font-bold pe-2 lg:w-[145px] w-[350px] uppercase">Date From</div>
            <DateInput
                className="!w-[150px]"
                placeholderText="Date From"
                onChange={(date) => setSelected({ ...selected, date_from: moment(date).format("YYYY-MM-DD")})}
                selected={ selected?.date_from ? moment(selected?.date_from).toDate() : null }
            />
        </div>
        <div className='flex items-center w-[284px] lg:w-[230px] mt-2 lg:mt-0'>
            <div className="lg:mt-0 lg:px-2 pb-1 label-text font-bold lg:w-[110px] !w-[550px] uppercase">Date To</div>
            <DateInput
                className="!w-[150px]"
                placeholderText="Date To"
                onChange={(date) => setSelected({ ...selected, date_end: moment(date).format("YYYY-MM-DD")})}
                selected={ selected?.date_end ? moment(selected?.date_end).toDate() : null }
            />
        </div>
        <div className='flex items-center ml-1 mt-2 lg:mt-0'>
            <div className="lg:mt-0 lg:px-2 pb-1 label-text font-bold w-[142px] lg:w-full">LOCATION</div>
            <Select
                className="w-[150px]"
                placeholder="Select Location"
                isLoading={isLoadingLocation}
                value={selected?.location_id !== "" ? [allOption, ...sortedSelectLocation].find((item) => item.value?.toString() === selected?.location_id?.toString()) : allOption}
                options={[allOption, ...dataLocation]}
                onChange={(e) => setSelected({ ...selected, location_id: e?.value! })}
            />
        </div>
        <div className='flex items-center ml-1 mt-2 lg:mt-0'>
            <div className="lg:mt-0 lg:px-2 pb-1 label-text font-bold w-[142px] lg:w-full">DELIVERED</div>
            <Select
                className="w-[150px]"
                placeholder="Select Location"
                value={selected?.delivered_id !== "" ? [allOption, ...sortedSelectDelivered].find((item) => item.value?.toString() === selected?.delivered_id?.toString()) : allOption}
                options={[allOption, ...dataDelivered]}
                onChange={(e) => setSelected({ ...selected, delivered_id: e?.value! })}
            />
        </div>
    </section>
  )
}