/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";
import { SuccessFetch } from "types";
import { useApi } from "utils";

type UseReconciliationCode = {
  trx_date: string;
  onSuccess?: (data: string) => void;
};

type Response = {
  payload: {
    next_code: string;
  };
};

export function useReconciliationCode({
  trx_date,
  onSuccess
}: UseReconciliationCode) {
  const api = useApi();
  const [data, setData] = useState("");
  const [isLoading, setLoading] = useState(false);

  const fetchData = () => {
    setLoading(true);

    api
      .post("/reconciliation/generate-code", {
        trx_date
      })
      .then((res: SuccessFetch<Response>) => {
        const data = res.data.payload;
        console.log(data);

        onSuccess?.(data.next_code);
        setData(data.next_code);
      })
      .catch(() => setData(""))
      .finally(() => setLoading(false));
  };

  const refetch = () => fetchData();

  useEffect(() => {
    fetchData();

    return () => {
      setData("");
      setLoading(false);
    };
  }, [trx_date]);

  return { data, isLoading, refetch };
}
