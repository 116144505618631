// React
import { Fragment, useContext } from "react"

// Contexts
import { ProductContext } from "../contexts"

// Form
import { useFormContext, useWatch } from "react-hook-form"

// Utils
import { DetailFormType } from "../../../utils"

export function Unit() {
  // Hooks
  const { data } = useContext(ProductContext)
  
  // Form
  const { control } = useFormContext<DetailFormType>()
  const product_id = useWatch({
    control,
    name: "product_id"
  })

  // Vars
  const mr_unit_name = data.find(item => item.value === product_id)?.mr_unit_name

  return <Fragment>{mr_unit_name}</Fragment>
}