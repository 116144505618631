/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";
import { SuccessFetch } from "types";
import { useApi } from "utils";
import { RegisterReconcileList } from "../../types";

type UseRegisterReconcileList = {
  params: {
    coa_id: string;
    start_date: string;
    end_date: string;
  };
  onSuccess?: (data: RegisterReconcileList[]) => void;
};

type Response = {
  payload: RegisterReconcileList[];
};

export function useRegisterReconcileList({
  params,
  onSuccess
}: UseRegisterReconcileList) {
  const api = useApi();
  const [data, setData] = useState<RegisterReconcileList[]>([]);
  const [isLoading, setLoading] = useState(false);

  const fetchData = () => {
    if (
      !params.coa_id ||
      !params.start_date ||
      !params.end_date
    )
      return;

    setLoading(true);

    api
      .get("reconciliation", { params })
      .then((res: SuccessFetch<Response>) => {
        const data = res.data.payload.map((item) => ({ ...item, rc: false }));

        onSuccess?.(data);
        setData(data);
      })
      .catch(() => setData([]))
      .finally(() => setLoading(false));
  };

  const refetch = () => fetchData();

  useEffect(() => {
    fetchData();

    return () => {
      setData([]);
      setLoading(false);
    };
  }, [
    params.coa_id,
    params.start_date,
    params.end_date
  ]);

  return { data, isLoading, refetch };
}
