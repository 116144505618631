import { Fragment } from "react"
import { ActionButton, Modal } from "../../../../../../../../../components"
import { FormSection } from "../../../../FormSection"
import { FormProvider, useForm } from "react-hook-form"
import type { DataList } from "../../../../../types"
import { useToggle } from "../../../../../../../../../utils"
import { menuTitle } from "./MenuTitle"

export function Detail(props: { id: DataList }) {
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <ActionButton.Detail className="join-item" onClick={toggle} />
      {isActive && <ModalSection id={props.id} toggle={toggle} />}
    </Fragment>
  )
}

function ModalSection(props: {
  id: DataList
  toggle: () => void
}) {

  return (
    <Modal isOpen title={`DETAIL ${menuTitle.title}`} closeModal={props.toggle}>
        <DataForm data={props.id!} toggle={props.toggle} />
    </Modal>
  )
}

function DataForm(props: {
  data: DataList
  toggle: () => void
}) {
  const methods = useForm({ defaultValues: props.data })

  return (
    <FormProvider {...methods}>
      <Modal.Body>
        <FormSection isDetail action="DETAIL" />
      </Modal.Body>
    </FormProvider>
  )
}