/* eslint-disable react-hooks/exhaustive-deps */
import { useDetailSalesOrder } from "pages/Sales/Transaction/CustomerOrder/utils/hooks"
import { useEffect, useMemo } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { today } from "utils/functions"
import { FormSection } from "./FormSections"
import { useDetailPaymentDeposit } from "../utils/hooks"
import { PaymentDepositCustomerType } from "../types"

type PaymentDepositDetailProps = {
  deposit_customer_id: string
  sales_order_id: string
}

export default function PaymentDepositDetail({ deposit_customer_id, sales_order_id }: PaymentDepositDetailProps) {
  const depo = useDetailPaymentDeposit({
    deposit_customer_id: deposit_customer_id ?? ""
  })
  const sales = useDetailSalesOrder({
    sales_order_id: sales_order_id
  })

  const defaultValues: PaymentDepositCustomerType = useMemo(
    () => ({
      deposit_customer_id: depo.data?.deposit_customer_id ?? NaN,
      sales_order_id: sales.data?.dataso.sales_order_id ?? NaN,
      customer_id: depo.data?.customer_id ?? NaN,
      customer_name: depo.data?.customer_name ?? "",
      location_id: depo.data?.location_id ?? NaN,
      location_name: depo.data?.location_name ?? "",
      coa_id: depo.data?.coa_id ?? NaN,
      coa_name: depo?.data?.coa_name,
      coa_balance: 0,
      link: sales.data?.dataso?.trx_code ?? "",
      project_id: depo.data?.project_id,
      trx_code: depo.data?.trx_code ?? "",
      transaction_date: depo.data?.transaction_date ?? today(),
      amount: depo.data?.amount ?? 0,
      memo: depo.data?.memo ?? "Payment Deposit Customer transaction",
      shipped: depo.data?.address ?? "",
      no_cheque: depo.data?.no_cheque ?? ""
    }),
    [sales.data, depo.data]
  )

  const methods = useForm<PaymentDepositCustomerType>({
    defaultValues,
  })

  useEffect(() => methods.reset(defaultValues), [defaultValues])

  return (
    <FormProvider {...methods}>
      <FormSection approvalView isEdit onSubmit={() => null} />
    </FormProvider>
  )
}
