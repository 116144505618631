import { Input, Label, Select, Textarea } from "../../../../../../components"
import { Controller, useFormContext } from "react-hook-form"
import type { DataList } from "../../types"
import { useJobPosition } from "pages/Card/Transaction/CreateNewEmployee/components/FormSection/utils"

export function FormSection(props: {
  isDetail?: boolean
  isUpdate?: boolean
  action?: string
}) {

  const { control } = useFormContext<DataList>()

  return (
    <table className="table">
      <tbody>
        <Controller
          control={control}
          name="transaction_code"
          render={({ field, fieldState }) => 
            <tr>
              <td><Label text="CODE" error={Boolean(fieldState.error)} /></td>
              <td><Input {...field} placeholder="Input Code" value={field.value ?? ""} error={fieldState.error?.message} disabled={props.action === "DETAIL" || props.action === "DELETE"} /></td>
            </tr>
          }
        />

        <Controller
          control={control}
          name="transaction_name"
          render={({ field, fieldState }) => 
            <tr>
              <td><Label text="TRANSACTION" error={Boolean(fieldState.error)} /></td>
              <td><Input {...field} placeholder="Input Transaction" value={field.value ?? ""} error={fieldState.error?.message} disabled={props.action === "DETAIL" || props.action === "DELETE"} /></td>
            </tr>
          }
        />

        <JobPosition isDisabled={props.action === "DETAIL" || props.action === "DELETE"} />

        <Controller
          control={control}
          name="description"
          render={({ field, fieldState }) => 
            <tr>
              <td><Label text="DESCRIPTION" error={Boolean(fieldState.error)} /></td>
              <td><Textarea {...field} placeholder="Input Description" value={field.value ?? ""} error={fieldState.error?.message} disabled={props.action === "DETAIL" || props.action === "DELETE"} /></td>
            </tr>
          }
        />
      </tbody>
    </table>
  )
}

function JobPosition(props: { isDisabled?: boolean }) {
  const { dataJobPosition, isLoadingJobPosition } = useJobPosition()
  const { control } = useFormContext<DataList>()

  return (
    <Controller
      control={control}
      name="job_position_id"
      render={({ field, fieldState }) => (
        <tr>
          <td><Label text="APPROVAL BY" error={Boolean(fieldState.error)} /></td>
          <td>
            <Select
              placeholder="Select Item"
              error={fieldState.error?.message}
              isDisabled={props.isDisabled}
              isLoading={isLoadingJobPosition}
              options={dataJobPosition}
              value={dataJobPosition.find(item => item.value === field.value?.toString())}
              onChange={e => field.onChange(e?.value)}
            />
          </td>
        </tr>
      )}
    />
  )
}