import { useEffect, useState } from "react"
import type { SuccessFetch } from "../../../../../../../../types"
import { useApi } from "../../../../../../../../utils"

export function useMenu() {
  const api = useApi()
  const [dataMenu, setData] = useState<any[]>([])
  const [isLoadingMenu, setLoading] = useState(true)
  const [selectedModule, setSelectedModule] = useState<string>("")

  const refetch = () => {
    setLoading(true)

    api.get("/menu/?only_active=true")
    .then((res: SuccessFetch<{
      payload: {
        menu_id: number
        menu_name: string
        module: { module_id: string }
      }[]
    }>) => { setData(res.data.payload.map(item => { return { label: item.menu_name, value: item.menu_id.toString(), moduleId: item?.module?.module_id }}))})
    .catch(() => setData([]))
    .finally(() => setLoading(false))
  }

  useEffect(() => {
    api.get("/menu/?only_active=true")
    .then((res: SuccessFetch<{
      payload: {
        menu_id: number
        menu_name: string
        module: { module_id: string }
      }[]
    }>) => { setData(res.data.payload.map(item => { return { label: item.menu_name, value: item.menu_id.toString(), moduleId: item?.module?.module_id }}))})
    .catch(() => setData([]))
    .finally(() => setLoading(false))
  
    return () => {
      setData([])
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [])

  const filteredMenu = selectedModule ? dataMenu.filter(menu => menu.moduleId?.toString() === selectedModule?.toString()) : dataMenu

  return { dataMenu: filteredMenu, isLoadingMenu, setSelectedModule, refetch }
}