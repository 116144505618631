import { Select } from "components"
import { useTaxCodeList } from "pages/Finance/Transaction/GeneralEntry/utils/hooks"
import { Controller, useFormContext } from "react-hook-form"
import { FormSalesOrderDetailType } from "../types"

type DropdownTaxProps = {
  tax: ReturnType<typeof useTaxCodeList>
}

export const DropdownTax = ({ tax }: DropdownTaxProps) => {
  const { control, setValue } = useFormContext<FormSalesOrderDetailType>()

  return (
    <Controller
      control={control}
      name="tax_id"
      render={({ field, fieldState }) => (
        <Select
          controlClass="w-[200px]"
          placeholder="Select Tax"
          error={fieldState.error?.message}
          ref={field.ref}
          options={tax.dropdown}
          value={
            field.value
              ? tax.dropdown.find(
                (item) => item.value === field.value.toString()
              )
              : null
          }
          onChange={(e: any) => {
            field.onChange(e?.value)
            setValue("tax_name", e.label)
            setValue("tax_rate", e.rate)
          }}
        />
      )}
    />
  )
}
