/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react"
import { SuccessFetch } from "types"
import { useApi } from "utils"
import { ProductAvailable } from "../../types"

type UseProductAvailableList = {
  location_id: number
  onSuccess?: (data: ProductAvailable[]) => void
}

type Response = {
  payload: ProductAvailable[]
}

export function useProductAvailableList({
  location_id
}: UseProductAvailableList) {
  const api = useApi()
  const [data, setData] = useState<ProductAvailable[]>([])
  const [isLoading, setLoading] = useState(false)

  const fetchData = () => {
    setLoading(true)

    api
      .get("/transfer/productavailable", { params: { location_id } })
      .then((res: SuccessFetch<Response>) => {
        const data = res.data.payload ?? []
        setData(data)
      })
      .catch(() => setData([]))
      .finally(() => setLoading(false))
  }

  const refetch = () => fetchData()

  useEffect(() => {
    if (location_id) {
      fetchData()
    }

    return () => {
      setData([])
      setLoading(false)
    }
  }, [location_id])

  return {
    data,
    isLoading,
    refetch,
    dropdown: data?.map((item) => ({
      ...item,
      label: `${item.product_barcode} - ${item.product_name}`,
      value: item.product_id.toString()
    }))
  }
}
