// React
import { PropsWithChildren } from "react"

// Third-Party Libraries
import { type LinkProps, Link } from "react-router-dom"

// Utils
import { usePermissionRoute } from "utils"

// Types
interface Type extends LinkProps {
  permission?: string
  className?: string
}

export function PermissionLink(params: PropsWithChildren<Type>): JSX.Element {
  // Vars
  const { children, permission, ...rest } = params

  // Hooks
  const permissionRoute = usePermissionRoute()

  if (!permission || Boolean(permission && permissionRoute.checkPermission(permission, { disableAlert: true }))) {
    return <Link {...rest}>{children}</Link>
  }

  return (
    <div
      className={`cursor-pointer w-full ${params.className}`}
      onClick={() => permissionRoute.checkPermission(permission)}
    >
      {children}
    </div>
  )
}