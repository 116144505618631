import { useEffect, useState } from "react"
import type { SelectOptionType, SuccessFetch } from "types"
import { useApi } from "utils"
import { DataList, DataListVendor } from "../types"
import { SelectedState } from "pages/Purchase/Register/types/AgedPayable"

export function useList() {
  const api = useApi()
  const [data, setData] = useState<DataList[]>([])
  const [isLoading, setLoading] = useState(false)

  const refetch = (params: Partial<SelectedState>) => {
    setLoading(true)

    api.get("/aged-payable/", { params: {
      date_from: params?.date_from, 
      date_end: params?.date_end, 
      vendor_id: params?.vendor_id,
      bill_status: params?.bill_status,
    } }) 
    .then((res: SuccessFetch<{ payload: DataList[] }>) => setData(res.data.payload))
    .catch(() => setData([]) )
    .finally(() => setLoading(false))
  
    return () => {
      setData([])
      setLoading(true)
    }
  }

  return { data, isLoading, refetch }
}
export function useListVendor() {
  const api = useApi()
  const [dataVendor, setData] = useState<SelectOptionType[]>([])
  const [isLoadingVendor, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
  
    api.get("/vendor/")
    .then((res: SuccessFetch<{ payload: DataListVendor[] }>) => {
      const vendors = res.data.payload.map(item => ({
        label: item.vendor_name,
        value: item.vendor_id.toString()
      }));
      setData(vendors);
    })
    .catch(() => setData([]) )
    .finally(() => setLoading(false))
  
    return () => {
      setData([])
      setLoading(true)
    }
    // eslint-disable-next-line
  }, [])

  return { dataVendor, isLoadingVendor }
}