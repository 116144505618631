/* eslint-disable react-hooks/exhaustive-deps */
import { Card, CommandButton } from "components"
import moment from "moment"
import { useFilter } from "pages/Finance/Journal/utils/hooks"
import { GroupName } from "pages/Finance/Transaction/types"
import { Fragment, useEffect, useState } from "react"
import { SuccessFetch } from "types"
import { useApi } from "utils"
import { prevYear, thisMonth, thisWeek, thisYear, today } from "utils/functions"
import { RegisterDateType } from "../../types"
import { DropdownCard } from "../DropdownCard"
import { FilterByDate } from "../FilterByDate"
import { TableRegister } from "../TableRegister"
import { FooterSummary } from "../FooterSummary"

type Response = {
  payload: RegisterDateType[]
}

export function RegisterCard() {
  const api = useApi()
  const todayDate = today()
  const prevYearDate = prevYear()
  const thisYearDate = thisYear()
  const thisMonthDate = thisMonth()
  const thisWeekDate = thisWeek()
  const [data, setData] = useState<RegisterDateType[]>([])
  const isLoading = false

  const { filter, handleChangeFilter } = useFilter({
    GroupCardID: "" as GroupName,
    TraderID: "",
    dateFrom: thisMonthDate.fromDate,
    dateTo: todayDate
  })

  const fetch = () =>
    api
      .postForm("/regbydatarange/getdatabycard", {
        GroupCardID: filter.GroupCardID,
        TraderID: filter.TraderID,
        StartDate: filter.dateFrom,
        EndDate: filter.dateTo
      })
      .then((res: SuccessFetch<Response>) => {
        const data = res.data.payload

        setData(
          data?.map((item) => ({
            ...item,
            checked: false
          })) ?? []
        )
      })

  useEffect(() => {
    if (filter.GroupCardID && filter.TraderID) {
      fetch()
    }
  }, [filter])

  const handleCheckData = (index: number) => {
    const newData = [...data]
    data[index].checked = !data[index].checked

    setData(newData)
  }

  return (
    <Fragment>
      <section className="mt-2">
        <Card>
          <Card.Body>
            <section className="container flex-col lg:flex lg:flex-row justify-center lg:justify-between pt-5">
              <div className="w-full lg:w-1/2 flex-col lg:flex lg:flex-row justify-center lg:justify-start items-center">
                <div className="label-text pb-1 font-bold pe-6">Group Card</div>
                <DropdownCard
                  value={null}
                  groupCardId={filter.GroupCardID as GroupName}
                  onChangeGroup={(item) =>
                    handleChangeFilter({
                      GroupCardID: item?.value as GroupName
                    })
                  }
                  onChange={(item) =>
                    handleChangeFilter({ TraderID: item?.value })
                  }
                />
              </div>
            </section>
            <FilterByDate
              permission="FR023"
              dateFrom={filter.dateFrom}
              dateTo={filter.dateTo}
              onChangeDateFrom={(dateFrom) => {
                const isValid = moment(dateFrom).isBefore(filter.dateTo)
                handleChangeFilter({
                  dateFrom,
                  dateTo: isValid ? filter.dateTo : dateFrom
                })
              }}
              onChangeDateTo={(dateTo) => handleChangeFilter({ dateTo })}
              onClickPrevYear={() =>
                handleChangeFilter({
                  dateFrom: prevYearDate.fromDate,
                  dateTo: prevYearDate.toDate
                })
              }
              onClickThisYear={() =>
                handleChangeFilter({
                  dateFrom: thisYearDate.fromDate,
                  dateTo: thisYearDate.toDate
                })
              }
              onClickThisWeek={() =>
                handleChangeFilter({
                  dateFrom: thisWeekDate.fromDate,
                  dateTo: thisWeekDate.toDate
                })
              }
              onClickThisMonth={() =>
                handleChangeFilter({
                  dateFrom: thisMonthDate.fromDate,
                  dateTo: thisMonthDate.toDate
                })
              }
              onClickToday={() =>
                handleChangeFilter({
                  dateFrom: todayDate,
                  dateTo: todayDate
                })
              }
            />
          </Card.Body>
        </Card>
      </section>

      <section className="mt-2">
        <TableRegister
          data={data}
          filter={filter}
          isLoading={isLoading}
          handleCheckData={handleCheckData}
          fetchList={fetch}
          permission="FR02"
        />
      </section>

      <section className="mt-2">
        <Card>
          <Card.Body className="flex flex-row gap-5">
            <section className="w-8/12 flex flex-wrap justify-between items-center gap-3">
              <section className="flex flex-wrap gap-3">
                <CommandButton actiontype="help" />
                <CommandButton actiontype="print" />
                <CommandButton actiontype="email" />
                <CommandButton actiontype="export" />
              </section>
            </section>

            <FooterSummary data={data} dateFrom={filter.dateFrom} />
          </Card.Body>
        </Card>
      </section>
    </Fragment>
  )
}
