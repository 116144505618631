// React
import { forwardRef } from "react"

// Third-Party Libraries
import { HexColorPicker } from "react-colorful"

export const ColorInput = forwardRef((props: {
  disabled?: boolean
  error?: string
  label?: string
  onChange?: (color: string) => void
  placeholder?: string
  value?: string
}, ref) => {
  return (
    <label className="form-control">
      {props.label && (
        <label className="label">
          <span className={`label-text font-bold ${props.error && "text-error"}`}>{props.label}</span>
        </label>
      )}

      <input
        // @ts-ignore
        ref={ref}
        readOnly
        placeholder={props.disabled ? "-" : props.placeholder}
        className={`input input-bordered dark:border-gray-400 ${props.error && "!input-error"}`}
        disabled={props.disabled}
        value={props.value}
      />

      <HexColorPicker
        color={props.value}
        onChange={value => {
          if (!props.disabled && props.onChange) {
            props.onChange(value)
          }
        }}
      />

      {props.error && (
        <label className="label">
          <span className={`label-text-alt font-bold ${props.error && "text-error"}`}>{props.error}</span>
        </label>
      )}
    </label>
  )
})