// React
import { Fragment, useRef } from "react"

// Components
import { Button, PermissionLink } from "components"

// Third-Party Libraries
import { Location, Outlet, useLocation } from "react-router-dom"

// Types
import type { MenuType } from "types"

// Utils
import { getButtonClass } from "./utils"

export function ModuleLayout(props: { menu: MenuType[] }) {
  // Hooks
  const location = useLocation()

  const handleClick = () => {
    // @ts-ignore
    document.activeElement?.blur()
  }

  return (
    <Fragment>
      <section className="pt-1 sticky top-0 z-10 bg-base-100">
        <header className="w-full bg-[#3c3c3c] overflow-auto">
          <section className="flex overflow-x-auto no-scrollbar">
            {props.menu.map((item, key) => {
              return (
                <section
                  key={key}
                  className="min-w-[126px]"
                >
                  {item.children ? (
                    <Menu data={item} location={location} handleClick={handleClick} />
                  ) : (
                    <PermissionLink
                      key={key}
                      to={item.link}
                      permission={item.permission}
                    >
                      <Button
                        size="sm"
                        color="ghost"
                        className={getButtonClass(Boolean(location.pathname.includes(item.link)))}
                      >
                        {item.label}
                      </Button>
                    </PermissionLink>
                  )}
                </section>
              )
            }
          )}
          </section>
        </header>
      </section>

      <Outlet />
    </Fragment>
  )
}

function Menu(props: {
  data: MenuType
  location: Location
  handleClick: () => void
  permission?: boolean
}) {
  const ref = useRef<any>()

  return (
    <div className="dropdown w-full">
      <Button
        tabIndex={0}
        size="sm"
        color="ghost"
        // permission={props?.permission}
        className={getButtonClass(Boolean(props.location.pathname.includes(props.data.label.toLowerCase())))}
      >
        {props.data.label}
      </Button>

      <ul
        tabIndex={0}
        className="!fixed dropdown-content z-10 menu p-2 shadow bg-white text-black dark:bg-black dark:text-white rounded-box !w-[300px] overflow-y-auto !flex-nowrap rounded-none"
        ref={ref}
      >
        {props.data.children?.map((item, key) => {
          return (
            <li
              key={key}
              onClick={props.handleClick}
              className={`border-b border-black dark:border-white ${key === 0 && "border-t"}`}
            >
              <PermissionLink
                to={item.link}
                permission={item.permission}
              >
                {item.label}
              </PermissionLink>
            </li>
          )
        }) ?? []}
      </ul>
    </div>
  )
}