import { Label, Select } from "components"
import { useLocationList } from "pages/Finance/Transaction/GeneralEntry/utils/hooks"
import { APPROVAL } from "pages/Sales/Register/utils/vars"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { FormTransferItemsType } from "../types"

type DropdownLocationProps = {
  approvalView?: boolean
  isDisabled?: boolean
  name: keyof FormTransferItemsType
  label: string
}

export const DropdownLocation = ({
  approvalView,
  isDisabled,
  name,
  label
}: DropdownLocationProps) => {
  const { control, clearErrors } = useFormContext<FormTransferItemsType>()
  const { dropdown } = useLocationList()
  const [approval_status, location_from_id] = useWatch({
    control: control,
    name: ["approval_status", "location_from_id"]
  })

  const options =
    name === "location_to_id" && location_from_id
      ? dropdown.filter((item: any) => item.value !== location_from_id)
      : dropdown

  return (
    <section>
      <Label text={label} />

      <section className="flex gap-3">
        <Controller
          control={control}
          name={name}
          render={({ field, fieldState }) => (
            <Select
              ref={field.ref}
              className="grow"
              placeholder="Select Location"
              isDisabled={
                approval_status !== APPROVAL.PENDING ||
                isDisabled ||
                approvalView
              }
              options={options ?? []}
              value={
                field.value
                  ? dropdown.find(
                      (item: any) => item.value === field.value?.toString()
                    )
                  : null
              }
              onChange={(e: any) => {
                if (e?.value) {
                  field.onChange(e?.value)
                  clearErrors(name)
                }
              }}
              error={fieldState.error?.message}
            />
          )}
        />
      </section>
    </section>
  )
}
