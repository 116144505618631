// React
import { useContext } from "react"

// Components
import { TaxCode } from "components"

// Contexts
import { DataJournalContext } from "contexts"

export function Tax(params: {
  disabled?: boolean
}) {
  // Hooks
  const { tax_code } = useContext(DataJournalContext)

  return (
    <TaxCode
      disabled={params.disabled}
      onSuccess={tax_code.refetch}
    />
  )
}