import { useEffect, useState } from "react"
import type { SelectOptionType, SuccessFetch } from "../../../../../../../../types"
import { useApi } from "../../../../../../../../utils"

export function useProductColour() {
  const api = useApi()
  const [dataColour, setData] = useState<SelectOptionType[]>([])
  const [isLoadingColour, setLoading] = useState(true)

  const refetchColour = () => {
    setLoading(true)

    api.get("/productcolour/")
    .then((res: SuccessFetch<{
      payload: {
        mr_product_colour_id: number
        mr_product_colour_name: string
      }[]
    }>) => { setData(res.data.payload.map(item => { return { label: item.mr_product_colour_name, value: item.mr_product_colour_id.toString() }}))})
    .catch(() => setData([]))
    .finally(() => setLoading(false))
  }

  useEffect(() => {
    api.get("/productcolour/")
    .then((res: SuccessFetch<{
      payload: {
        mr_product_colour_id: number
        mr_product_colour_name: string
      }[]
    }>) => { setData(res.data.payload.map(item => { return { label: item.mr_product_colour_name, value: item.mr_product_colour_id.toString() }}))
    })
    .catch(() => setData([]))
    .finally(() => setLoading(false))
    // eslint-disable-next-line
  }, [])

  return { dataColour, isLoadingColour, refetchColour }
}