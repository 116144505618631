import { useEffect, useState } from "react"
import type { SuccessFetch } from "../../../../../../types"
import { useApi } from "../../../../../../utils"
import { FormType } from "../../types"

type Response = {
    payload: FormType[]
};

export function useDetailOpeningStock({ id_opening_stock, onSuccess }: { id_opening_stock: number, onSuccess?: (data: FormType[]) => void }) {

    const api = useApi()
    const [data, setData] = useState<FormType[]>([])
    const [isLoading, setLoading] = useState(true)

    const fetchData = () => {
        setLoading(true);
        api
            .get(`/opening-stock/getbyid?opening_stock_id=${id_opening_stock}`)
            .then((res: SuccessFetch<Response>) => {
                const data = res.data.payload
                onSuccess?.(data);
                setData(data);
            })
            .catch(() => setData([]))
            .finally(() => setLoading(false));
    };

    const refetch = () => fetchData();

    useEffect(() => {
        if (id_opening_stock) {
            fetchData();
        }

        return () => {
            setData([]);
            setLoading(false);
        };
        // eslint-disable-next-line
    }, [id_opening_stock]);

    return { data, isLoading, refetch };
}

