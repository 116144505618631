import { CommandButton } from "components"
import { TableSectionProps } from "pages/Purchase/Register/types/AgedPayable"

export const FotherSection = ({ data }: TableSectionProps) => {

  return (
    <section className="flex flex-wrap gap-3">
      <CommandButton actiontype="help" />
      <CommandButton actiontype="print" />
      <CommandButton actiontype="email" />
      <CommandButton actiontype="export" />
    </section>
  )
}