import { useEffect, useState } from "react"
import type { SelectOptionType, SuccessFetch } from "../../../../../../../../types"
import { useApi } from "../../../../../../../../utils"

interface Type extends SelectOptionType { code_vendor: string }
export function useVendor() {
  const api = useApi()
  const [dataVendor, setData] = useState<Type[]>([])
  const [isLoadingVendor, setLoading] = useState(false)

  const refetchVendor = () => {
    setLoading(true)

    api.get("/vendor/")
    .then((res: SuccessFetch<{
      payload: {
        vendor_id: number
        vendor_name: string
        vendor_code: string
      }[]
      }>) => { setData(res.data.payload.map(item => { return { label: item.vendor_name, code_vendor: item.vendor_code, value: item.vendor_id.toString() }}))})
    .catch(() => setData([]))
    .finally(() => setLoading(false))
  }

  useEffect(() => {
    setLoading(true)

    api.get("/vendor/")
    .then((res: SuccessFetch<{
      payload: {
        vendor_id: number
        vendor_name: string
        vendor_code: string
      }[]
    }>) => { setData(res.data.payload.map(item => { return { label: item.vendor_name, code_vendor: item.vendor_code, value: item.vendor_id.toString() }}))})
    .catch(() => setData([]))
    .finally(() => setLoading(false))
    // eslint-disable-next-line
  }, [])

  return { dataVendor, isLoadingVendor, refetchVendor }
}