import { useState } from "react"
import type { SelectOptionType, SuccessFetch } from "types"
import { useApi } from "utils"
import { DataList, DataListPO } from "../types"
import { SelectedState, SelectedStatePO } from "pages/Purchase/Register/types"

export function useList() {
  const api = useApi()
  const [data, setData] = useState<DataList[]>([])
  const [isLoading, setLoading] = useState(false)

  const refetch = (params: Partial<SelectedState>) => {
    setLoading(true)

    api.get("/all-bills/", { params: { 
      search: params?.search, 
      date_from: params?.date_from, 
      date_end: params?.date_end, 
      purchase_order_id: params?.purchase_order_id, 
      approve_status: params?.approve_status ? Number(params?.approve_status) : null,
      bill_status: params?.bill_status,
    } }) 
    .then((res: SuccessFetch<{ payload: DataList[] }>) => setData(res.data.payload))
    .catch(() => setData([]) )
    .finally(() => setLoading(false))
  
    return () => {
      setData([])
      setLoading(true)
    }
  }

  return { data, isLoading, refetch }
}
export function useListPO() {
  const api = useApi()
  const [dataPO, setData] = useState<SelectOptionType[]>([])
  const [isLoadingPO, setLoading] = useState(false)

  const refetchPO = (params: Partial<SelectedStatePO>) => {
    setLoading(true)

    api.get(`/all-bills/dropdown-purchase-order?date_from=${params.DateFrom}&date_end=${params.DateEnd}`)
    .then((res: SuccessFetch<{ payload: DataListPO[] }>) => setData(res.data.payload.map(val => ({ label: val?.trx_code, value: val?.purchase_order_id?.toString() }))))
    .catch(() => setData([]) )
    .finally(() => setLoading(false))
  
    return () => {
      setData([])
      setLoading(true)
    }
  }

  return { dataPO, isLoadingPO, refetchPO }
}