import { ColumnConfigType } from "types";

export const defaultValue = (dataList: ColumnConfigType[]) => {
    const nomorNationalId = dataList?.find(item => item.column_code === 'emNaId')?.column_status;
    const religion = dataList.find(item => item.column_code === 'emRel')?.column_status;
    const handphone = dataList.find(item => item.column_code === 'emHp')?.column_status;
    const email = dataList.find(item => item.column_code === 'emEmail')?.column_status;

    const country = dataList.find(item => item.column_code === 'emCount')?.column_status;
    const province = dataList.find(item => item.column_code === 'emProv')?.column_status;
    const regency = dataList.find(item => item.column_code === 'emReg')?.column_status;
    const subdistrict = dataList.find(item => item.column_code === 'emSub')?.column_status;
    const village = dataList.find(item => item.column_code === 'emVil')?.column_status;
    const address = dataList.find(item => item.column_code === 'emAdd')?.column_status;

    const departement = dataList.find(item => item.column_code === 'emDept')?.column_status;
    const divisi = dataList.find(item => item.column_code === 'emDiv')?.column_status;
    const gender = dataList.find(item => item.column_code === 'emGen')?.column_status;
    const employeeStatus = dataList.find(item => item.column_code === 'emEmSt')?.column_status;

    const bank = dataList.find(item => item.column_code === 'emBank')?.column_status;
    const branch = dataList.find(item => item.column_code === 'emBr')?.column_status;
    const accountBankName = dataList.find(item => item.column_code === 'emBaN')?.column_status;

    return {
        nomorNationalId,
        religion,
        handphone,
        email,
        country,
        province,
        regency,
        subdistrict,
        village,
        address,
        departement,
        divisi,
        gender,
        employeeStatus,
        bank,
        branch,
        accountBankName
    };
};
