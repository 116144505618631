import { Fragment, useContext } from "react"
import { ActionButton, Button, Modal } from "../../../../../../../../../components"
import { FormSection } from "../../../../FormSection"
import { FormProvider, useForm } from "react-hook-form"
import toast from "react-hot-toast"
import type { DataList } from "../../../../../types"
import { useApi, useToggle } from "../../../../../../../../../utils"
import { Render } from "../../../../../utils"
import { menuTitle } from "./MenuTitle"

export function Delete(props: { id: DataList, refetch?: () => void }) {
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <ActionButton.Delete className="join-item" onClick={toggle} />
      {isActive && <ModalSection id={props.id} toggle={toggle} refetch={props.refetch}/>}
    </Fragment>
  )
}

function ModalSection(props: {
  id: DataList
  toggle: () => void
  refetch?: () => void
}) {

  return (
    <Modal isOpen title={`DELETE ${menuTitle.title}`} closeModal={props.toggle}>
        <DataForm data={props.id!} toggle={props.toggle}  refetch={props.refetch} />
    </Modal>
  )
}

function DataForm(props: {
  data: DataList
  toggle: () => void
  refetch?: () => void
}) {
  const api = useApi()
  const { toggle } = useContext(Render)

  const defaultValues = {
    module_id: props?.data?.module?.module_id,
    menu_code: props?.data?.menu_code,
    menu_name: props?.data?.menu_name,
    description: props?.data?.description,
  }

  const onSubmit = () => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.delete(`/menu/${props.data.menu_id}`),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
           error: (err) => {
            if (err.response && err.response.status === 422) {
              return err.response.data.message || "Bad Request";
            } else {
              return "An error occurred";
            }
          }
        }
      ).then(() => {
        props.toggle()
        props?.refetch && props.refetch()
        toggle()
      }).catch(() => {}).finally(resolve)
    })
  }
  const methods = useForm({ defaultValues })

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Modal.Body>
          <h5 className="text-error">Are you sure want to delete this data?</h5>
          <FormSection isDetail action="DELETE" />
        </Modal.Body>

        <Modal.Footer className="text-end">
          <Button type="submit" color="error" loading={methods.formState.isSubmitting ? "true" : undefined}>Delete</Button>
        </Modal.Footer>
      </form>
    </FormProvider>
  )
}