import { Fragment, useContext } from "react"
import type { DataList } from "../../../../../types"
import { Toggle } from "components/actions/Toggle"
import toast from "react-hot-toast"
import { useApi } from "utils"
import { Render } from "../../../../../utils"


export function IsActive(props: {
    id: DataList
    refetch?: () => void
}) {
    const api = useApi()
    const { toggle } = useContext(Render)

    const onSubmit = (value: DataList) => {
        return new Promise<void>((resolve) => {
            toast.promise(
                api.patch(`/feature/${value?.feature_id}/is_deleted`, { is_deleted: !value.is_deleted }),
                {
                    loading: "Loading...",
                    success: (res) => res.data.message,
                    error: (err) => {
                        if (err.response && err.response.status === 400) {
                            return err.response.data.message || "Bad Request";
                        } else {
                            return "An error occurred";
                        }
                    }
                }
            ).then(() => {
                toggle()
                props?.refetch && props.refetch()
            }).catch(() => {}).finally(resolve)
        })
    }

    return (
        <Fragment>
            <Toggle 
                isChecked={props.id.is_deleted} 
                onClick={() => {
                    onSubmit(props.id);
                }} />
        </Fragment>
    )
}