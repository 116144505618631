import { useEffect, useState } from "react"
import type { SelectOptionType, SuccessFetch } from "types"
import { useApi } from "utils"
import { DataList, DataListLocation } from "../types"
import { SelectedState } from "pages/Purchase/Register/types/Location"

export function useList() {
  const api = useApi()
  const [data, setData] = useState<DataList[]>([])
  const [isLoading, setLoading] = useState(false)

  const refetch = (params: Partial<SelectedState>) => {
    setLoading(true)

    api.get("/purchaseorder/by-location", { params: {
      date_from: params?.date_from, 
      date_end: params?.date_end, 
      location_id: params?.location_id,
      delivery_status: params?.delivered_id,
    } }) 
    .then((res: SuccessFetch<{ payload: DataList[] }>) => setData(res.data.payload))
    .catch(() => setData([]) )
    .finally(() => setLoading(false))
  
    return () => {
      setData([])
      setLoading(true)
    }
  }

  return { data, isLoading, refetch }
}
export function useListLocation() {
  const api = useApi()
  const [dataLocation, setData] = useState<SelectOptionType[]>([])
  const [isLoadingLocation, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
  
    api.get("/location/")
    .then((res: SuccessFetch<{ payload: DataListLocation[] }>) => {
      const location = res.data.payload.map(item => ({
        label: item.location_name,
        value: item.location_id.toString()
      }));
      setData(location);
    })
    .catch(() => setData([]) )
    .finally(() => setLoading(false))
  
    return () => {
      setData([])
      setLoading(true)
    }
    // eslint-disable-next-line
  }, [])

  return { dataLocation, isLoadingLocation }
}