import { BaseTable } from 'components';
import { RegisterDateType } from 'pages/Finance/Register/types';
import { calculateRegister } from 'pages/Finance/Register/utils/function';
import { useMemo } from 'react';
import { convertNumber } from 'utils';

type FooterSummaryProps = {
  data: RegisterDateType[];
  dateFrom: string;
};

export const FooterSummary = (props: FooterSummaryProps) => {

  const calculate = useMemo(
    () => calculateRegister(props.data),
    [props.data],
  );

  return (
    <BaseTable className="w-full min-w-[100px] md:max-w-[400px]">
      <tbody className="text-md">
        <tr className="border-dotted border-b-2">
          <td className="w-[1px] whitespace-nowrap pr-3 pt-5 uppercase font-bold">Total Debit</td>
          <td className="pt-5">: {convertNumber(calculate.totalDebet).intoCurrency}</td>
        </tr>
        <tr className="border-dotted border-b-2">
          <td className="w-[1px] whitespace-nowrap pr-3 pt-5 uppercase font-bold">Total Credit</td>
          <td className="pt-5">: {convertNumber(calculate.totalCredit).intoCurrency}</td>
        </tr>
        <tr className="border-dotted border-b-2">
          <td className="w-[1px] whitespace-nowrap pr-3 pt-5 uppercase font-bold">Net Change</td>
          <td className="pt-5">: <span>{convertNumber(calculate.netChange).intoCurrency}</span></td>
        </tr>
      </tbody>
    </BaseTable>
  );
};
