import * as yup from "yup"
import { formInvoiceDetailSchema } from "./FormInvoiceDetail"

export type FormReturnItemsType = yup.InferType<typeof formReturnItemsSchema>

export const formReturnItemsSchema = yup.object().shape({
  invoice_id: yup.number().label("Invoice").optional(),
  return_id: yup.number().label("Return").optional(),
  sales_order_id: yup.number().label("Sales Order").optional(),
  customer_id: yup
    .number()
    .label("Customer")
    .typeError("Customer is required")
    .required(),
  customer_name: yup.string().label("Customer").optional(),
  employee_id: yup
    .number()
    .label("Employee")
    .typeError("Employee is required")
    .required(),
  payment_term: yup
    .number()
    .label("Payment Term")
    .typeError("Please fill Customer Payment Term")
    .required("Please fill Customer Payment Term"),
  payment_term_name: yup.string().label("Payment Term").optional(),
  link: yup
    .string()
    .label("Link"),
  price_level_name: yup
    .string()
    .label("Price Level")
    .required("Please fill Customer Price Level"),
  location_id: yup
    .number()
    .label("Location")
    .typeError("Location is required")
    .required(),
  tax_inclusive: yup.boolean().label("Tax Inclusive").required(),
  shipped: yup.string().label("Shipped").required(),
  memo: yup.string().label("Memo").required(),
  trx_code: yup.string().label("Code").optional(),
  transaction_date: yup.string().label("Transaction Date").required(),
  referensi: yup.string().label("Referensi").required(),
  promize_date: yup.string().label("Promise Date").required(),
  freight: yup.number().label("Freight").optional(),
  approved_by: yup.string().label("Approved By").optional(),
  approved_by_name: yup.string().label("Approved By").optional(),
  approval_status: yup.number().label("Approved By").defined(),
  delivery_no: yup.number().label("Delivery").defined(),
  approval_status_text: yup.string().label("Approved By").defined(),
  so_payment_status: yup.number().label("Payment Status").optional(),
  so_payment_status_text: yup.string().label("Payment Status").optional(),
  invoice_detail: yup.array(formInvoiceDetailSchema.omit(["quantity_returned"])),
  return_detail: yup.array(formInvoiceDetailSchema),
})
