// React
import { createContext } from "react"

// Utils
import { DetailFormType } from "../utils"

export const ProbilContext = createContext<{
  append: (value: DetailFormType) => void
}>({
  append(value) {},
})