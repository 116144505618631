import moment from "moment"
import { useEffect } from "react"
import { Button, CommandButton, DateInput, Select } from "components"
import { useAccount, useCustomer, useEmployee, useJobId, useLocation, useVendor } from "../utils"

interface SearchingSectionProps {
  selected?: any
  isSelected?: any
  setSelected: (newState: any) => void
  setIsSelected: (newState: any) => void
  refetch: (search?: string, detail?: string, from?: string, to?: string) => void
  setIsSort: (sort: boolean) => void
}

export const SearchingSection:React.FC<SearchingSectionProps> = ({ selected, setSelected, isSelected, setIsSelected, refetch, setIsSort }) => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();
  const startDate = new Date(year, month, 1);
  const endDate = new Date(year, month + 1, 0);
  const allFieldsFilled = () => { return Object.values(selected).every(value => value !== "") };

  const { dataAccount } = useAccount()
  const { dataCustomer } = useCustomer()
  const { dataEmployee } = useEmployee()
  const { dataJobId } = useJobId()
  const { dataLocation } = useLocation()
  const { dataVendor } = useVendor()
  
  const dataSearching = [
    { label: "EMPLOYEE", value: "1" },
    { label: "CUSTOMER", value: "2" },
    { label: "VENDOR", value: "3" },
    { label: "ACCOUNT", value: "4" },
    { label: "LOCATION", value: "5" },
    { label: "JOB ID", value: "6" },
  ]

  type DataType = typeof dataAccount | typeof dataCustomer | typeof dataEmployee | typeof dataJobId | typeof dataLocation | typeof dataVendor;
  const dropdownMapping: { [key: string]: DataType } = {
    "1": dataEmployee,
    "2": dataCustomer,
    "3": dataVendor,
    "4": dataAccount,
    "5": dataLocation,
    "6": dataJobId
  };
  const dropdownData: DataType | any = dropdownMapping[selected?.search] || null; 

  useEffect(() => {
    if(selected?.date_from === "") { setSelected({ ...selected, date_from: moment(startDate).format("YYYY-MM-DD")}) }
    if(selected?.date_to === "") { setSelected({ ...selected, date_to: moment(endDate).format("YYYY-MM-DD")}) }
    // eslint-disable-next-line
  }, [selected])
  
  return (
    <section className="lg:flex lg:justify-between lg:items-center">
      <div className="lg:w-[80%]">
        <div className="w-full lg:flex justify-center lg:justify-start lg:items-center lg:mb-3">
          <div className="label-text pb-1 font-bold pe-2">SEARCH BY</div>
          <div className="lg:ml-3">
            <Select 
              controlClass="lg:w-[250px]"  
              options={dataSearching}
              value={dataSearching.find(item => item.value === selected?.search)}
              onChange={e => setSelected({ ...selected, search: e?.value!, detail_search: "" })}
            />
          </div>
          <div className="lg:ml-3 lg:w-[338px] my-2 lg:my-0">
            <Select 
              options={dropdownData}
              isDisabled={Boolean(!selected?.search)}
              value={selected?.detail_search !== "" ? dropdownData.find((item: any) => item.value === selected?.detail_search) : ""}
              onChange={e => setSelected({ ...selected, detail_search: e?.value! })}
            />
          </div>
          <div>
            <Button
              type="button"
              color="primary"
              className="lg:ml-3"
              disabled={!allFieldsFilled()}
              onClick={() => {
                refetch(selected?.search, selected?.detail_search, selected?.date_from, selected?.date_to)
                setIsSort(false)
                setIsSelected({
                  ...isSelected,
                  search: selected?.search,
                  detail_search: selected?.detail_search,
                  date_from: selected?.date_from,
                  date_to: selected?.date_to,
                })}
              }
            >
              SEARCH
            </Button>
          </div>
        </div>
        <div className="w-full lg:flex justify-center lg:justify-start lg:items-center my-2 lg:my-0">
          <div className="label-text pb-1 font-bold pe-2 lg:w-[100px]">DATE FROM</div>
          <DateInput 
            className="lg:w-[250px]" 
            placeholderText="Date from" 
            onChange={(date) => setSelected({ ...selected, date_from: moment(date).format("YYYY-MM-DD")})}
            selected={ selected?.date_from ? moment(selected?.date_from).toDate() : null }
          />

          <div className="mt-3 lg:mt-0 lg:px-2 pb-1 label-text font-bold lg:w-[90px] lg:ml-2">DATE TO</div>
          <DateInput 
            className="lg:w-[250px]" 
            placeholderText="Date To" 
            onChange={(date) => setSelected({ ...selected, date_to: moment(date).format("YYYY-MM-DD")})}
            selected={ selected?.date_to ? moment(selected?.date_to).toDate() : null }
          />
        </div>
      </div>

      <div className="lg:w-[20%] lg:flex lg:justify-end my-2 lg:my-0">
        <CommandButton actiontype="advanced" />
      </div>
    </section>
  )
}