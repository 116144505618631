// React
import { useEffect, useState } from "react"

// Types
import type { SelectOptionType, SuccessFetch } from "../../../../../../types"

// Utils
import { useApi } from "../../../../../../utils"

export function useModule() {
  // Hooks
  const api = useApi()
  const [data, setData] = useState<SelectOptionType[]>([])
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    api.get("/module/?only_active=false").then((res: SuccessFetch<{
      payload: {
        module_id: number
        module_name: string
      }[]
    }>) => {
      setData(res.data.payload.map(item => {
        return {
          label: item.module_name,
          value: item.module_id.toString()
        }
      }))
    }).catch(() => {
      setData([])
    }).finally(() => {
      setLoading(false)
    })
  
    return () => {
      setData([])
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [])

  return { data, isLoading }
}