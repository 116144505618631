// React
import { createContext } from "react"

// Types
import type { DataBillType } from "../types"

export const DataBillContext = createContext<DataBillType>({
  approval_status: "",
  approved_by: "",
  bill_status: ""
})