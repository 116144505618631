import { Input, Textarea } from "../../../../../../components"
import { Controller, useFormContext } from "react-hook-form"
import type { DataList } from "../../types"
import { menuTitle } from "../ChildItem/components/Item/components/MenuTitle"

export function FormSection(props: {
  isDetail?: boolean
  isUpdate?: boolean
  action?: string
}) {

  const { control } = useFormContext<DataList>()

  return (
    <section>
      <Controller
        control={control}
        name="job_position_code"
        render={({ field, fieldState }) => <Input {...field} label="CODE" value={field.value ?? ""} error={fieldState.error?.message} disabled={props.action === "DETAIL" || props.action === "DELETE"} />}
      />

      <Controller
        control={control}
        name="job_position_name"
        render={({ field, fieldState }) => <Input {...field} label={menuTitle.title} value={field.value ?? ""} error={fieldState.error?.message} disabled={props.action === "DETAIL" || props.action === "DELETE"} />}
      />

      <Controller
        control={control}
        name="description"
        render={({ field, fieldState }) => <Textarea {...field} label="DESCRIPTION" value={field.value ?? ""} error={fieldState.error?.message} disabled={props.action === "DETAIL" || props.action === "DELETE"} />}
      />
    </section>
  )
}