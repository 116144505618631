// Utils
import { usePermissionRoute } from "utils"

export function Toggle(params: {
    className?: string
    isChecked?: boolean
    onClick?: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void
    permission?: string
}) {
  // Hooks
  const permissionRoute = usePermissionRoute()

  return (
    <div className="flex items-center tooltip" title="Soft Delete" >
      <input
        type="checkbox" 
        className={`toggle toggle-xs h-2 ${params.className}`}
        checked={params?.permission ? params.isChecked : false}
        onClick={(e) => {
          if (Boolean(!params.permission || Boolean(params.permission && permissionRoute.checkPermission(params.permission))) && params.onClick) {
            params.onClick(e)
          }
        }}
      />
    </div>
  )
}