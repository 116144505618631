import * as yup from "yup"

export type FormTransferDetailType = yup.InferType<
  typeof formTransferDetailSchema
>

export const formTransferDetailSchema = yup.object().shape({
  transfer_detail_id: yup.number().label("Transfer").optional(),
  product_id: yup
    .number()
    .label("Product")
    .typeError("Product is required")
    .required(),
  product_name: yup.string().label("Product").optional(),
  product_barcode: yup.string().label("Product").optional(),
  mr_unit_id: yup.number().label("Unit").optional(),
  soh: yup.number().optional(),
  mr_unit_name: yup.string().label("Unit").optional(),
  quantity_transfer: yup
    .number()
    .optional()
    .required("Quantity Transfer")
    .min(1)
    .test(
      "quantityTransferShouldBeLessEqualThanQuantitySOH",
      "Should be less equal than Qty. SOH",
      (value, context) => (value ?? 0) <= (context.parent.soh ?? 0)
    )
})
