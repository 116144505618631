import { useApi } from "utils";
import { CardType, FilterType } from "../../types";
import { useEffect, useState } from "react";
import { SuccessFetch } from "types";
import { DataTypePaging } from "types";

export function useList({ 
    page, 
    limit, 
    search,
    start_date,
    end_date,
    location_id,
    delivery_status
}: FilterType) {

    const defaultValue = {
        payload: [],
        pagination_data: {
            page: 0,
            pages: 0,
            size: 0,
            total: 0
        }
    }

    const api = useApi();
    const [data, setData] = useState<DataTypePaging<CardType>>(defaultValue);
    const [isLoading, setLoading] = useState(true)

    const params: { [key: string]: number | string | undefined } = {}
    if (page) params.page = page;
    if (limit) params.limit = limit;
    if (search) params.search = search;
    if (start_date) params.start_date = start_date;
    if (end_date) params.end_date = end_date;
    if (location_id) params.location_id = location_id;
    if (delivery_status) params.delivery_status = delivery_status;

    const fetchUrl = () => {
        return api.get("/salesorder/register?",{params})
    }

    const fetchData = () => {
        setLoading(true);

        fetchUrl().then((res: SuccessFetch<DataTypePaging<CardType>>) => {
            setData(res.data);
        }).catch(() => {
            setData(defaultValue);
        }).finally(() => {
            setLoading(false);
        });
    }

    const refetch = () => fetchData();

    useEffect(() => {
        fetchData();

        return () => {
            setData(defaultValue);
            setLoading(true);
        }
        // eslint-disable-next-line
    }, [page, limit, search, start_date, end_date, location_id, delivery_status]);

    return { data, isLoading, refetch }
}
