import { Fragment, useState } from "react"
import { Tab } from "./components";
import { RenderProvider } from "./utils";
import CreateNewCustomer from "../../Transaction/CreateNewCustomer";
import CreateNewEmployee from "../../Transaction/CreateNewEmployee";
import CreateNewVendor from "../../Transaction/CreateNewVendor";
import { HeaderMenu } from "components";

export default function All() {
    const tabs = [
        { name: "EMPLOYEE", permission: "CR01" },
        { name: "CUSTOMER", permission: "CR02" },
        { name: "VENDOR", permission: "CR03" }
    ]
    const [activeTab, setActiveTab] = useState<number | undefined>(0);

    return (
        <Fragment>
            <section className="container my-5">
                <HeaderMenu title="CARDS REGISTER"/>
                <RenderProvider>
                    <section className="w-full">
                        <Tab tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} className="text-[11px]" />
                    </section>
                    <section>
                        {activeTab === 0 
                            ? <CreateNewEmployee tab="tab" /> 
                            : (activeTab === 1 
                                ? <CreateNewCustomer tab="tab"/> 
                                : <CreateNewVendor tab="tab"/>
                              )
                    }
                    </section>
                </RenderProvider>
            </section>
        </Fragment>
    )
}