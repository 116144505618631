import { RegisterDateType } from '../../types';

export const calculateRegister = (data: RegisterDateType[],) => {
  return data.reduce(
    (acc, item) => {
      if (item.position === 1) {
        acc.totalDebet += item.amount;
      } else {
        acc.totalCredit += item.amount;
      }

      acc.netChange = acc.totalDebet - acc.totalCredit

      return acc;
    },
    {
      netChange: 0,
      totalDebet: 0,
      totalCredit: 0,
    },
  );
};
