// React
import { createContext } from "react"

export const ReminderContext = createContext({
  disabled: false,
  transaction_from: "",
  name: {
    memo: "",
    promise_date: "",
    referensi: "",
    reminder: "",
    transaction_date: "",
    vendor_name: "",
    reminder_child: {
      memo: "",
      promise_date: "",
      transaction_date: "",
    },
  },
  onSuccess: (value: {
    memo: string
    promise_date: string
    transaction_date: string
  }) => {
    return console.log(value)
  }
})