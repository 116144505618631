/* eslint-disable react-hooks/exhaustive-deps */
import {
  ActionButton,
  BaseTable,
  Card,
  CommandButton,
  DateInput,
  HeaderMenu,
  Input,
  Modal,
  Textarea
} from "components"
import moment from "moment"
import { ModalDeleteJournal } from "pages/Finance/Journal/components/ModalDeleteJournal"
import { JournalList, MapJournalList } from "pages/Finance/Journal/types"
import { mapJournalList } from "pages/Finance/Journal/utils/functions"
import { CreateModal as ProjectModal } from "pages/Setup/Preference/Project/components"
import { ModalSection as TaxCodeModal } from "pages/Setup/Preference/TaxCode/components"
import { Fragment, useEffect, useRef, useState } from "react"
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch
} from "react-hook-form"
import toast from "react-hot-toast"
import { TbFileUpload } from "react-icons/tb"
import { useNavigate } from "react-router-dom"
import { SuccessFetch, permissionProps } from "types"
import { convertNumber, useApi, useToggle } from "utils"
import { FileList } from "../../components"
import { FormType } from "../types"
import { totalAmountJournal } from "../utils/function"
import { useCode, useProjectList, useTaxCodeList } from "../utils/hooks"
import { Body } from "./Body"
import { Employee } from "./Employee"
import { FooterAccountTable } from "./FooterAccountTable"
import { Location } from "./Location"
import { TableJournal } from "./TableJournal"

export type FormSectionProps = {
  isEdit?: boolean
  onSubmit: () => void
  permission: permissionProps
}

export const FormSection = ({ isEdit = false, onSubmit, permission }: FormSectionProps) => {
  const api = useApi()
  const navigate = useNavigate()
  const methods = useFormContext<FormType>()
  const project = useProjectList()
  const tax = useTaxCodeList()
  const loading = useToggle(false)
  const modalDelete = useToggle(false)
  const [editField, setEditField] = useState<number | null>(null)
  const [dataJournal, setDataJournal] = useState<MapJournalList[]>([])

  const [datajournal, reconsiled, transaction_date, data_source, data_source_id, journal_code] = methods.watch(["datajournal", "reconsiled", "transaction_date", "data_source", "data_source_id", "journal_code"])

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "datajournal"
  })

  const total = totalAmountJournal(datajournal)

  useEffect(() => {
    if (modalDelete.isActive) {
      loading.toggle()

      api
        .postForm("/journal/getjournaldata", {
          StartDate: transaction_date,
          EndDate: transaction_date,
          DataSource: data_source
        })
        .then((res: SuccessFetch<{ payload: JournalList[] }>) => {
          const data = res?.data?.payload ?? []
          setDataJournal(mapJournalList(data, data_source_id))
        })
        .catch(() => setDataJournal([]))
        .finally(() => setTimeout(loading.toggle, 800))
    }
  }, [modalDelete.isActive])

  return (
    <section className="container my-5 flex flex-col gap-5">
      <HeaderMenu title="DATA ENTRY | TRANSFER MONEY">
        <Code isEdit={isEdit} />
      </HeaderMenu>

      <Card>
        <Card.Body className="grid lg:grid-cols-2 gap-x-6">
          <section className="flex flex-col gap-3">
            <Employee />

            <Controller
              control={methods.control}
              name="memo"
              render={({ field, fieldState }) => (
                <Textarea
                  label="MEMO"
                  defaultValue={field.value}
                  onChange={field.onChange}
                  error={fieldState.error?.message}
                />
              )}
            />
          </section>

          <section className="flex flex-col gap-3">
            <Controller
              control={methods.control}
              name="transaction_date"
              render={({ field, fieldState }) => (
                <DateInput
                  label="DATE"
                  error={fieldState.error?.message}
                  ref={field.ref}
                  selected={moment(field.value).toDate()}
                  onChange={value => field.onChange(moment(value).format("YYYY-MM-DD"))}
                />
              )}
            />

            <Location />

            <Controller
              control={methods.control}
              name="reference_no"
              render={({ field, fieldState }) => (
                <Input
                  ref={field.ref}
                  label="REFERENCE"
                  defaultValue={field.value}
                  value={field.value}
                  onChange={field.onChange}
                  error={fieldState.error?.message}
                />
              )}
            />
          </section>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          <BaseTable>
            <thead>
              <tr>
                <th>-</th>
                <th>ACCOUNT NUMBER</th>
                <th>MEMO</th>
                <th>DEBIT</th>
                <th>CREDIT</th>
                <ProjectHead project={project} />
                <th>REF NO.</th>
                <TaxHead tax={tax} />
                <th>ACTION</th>
              </tr>
            </thead>

            <tbody>
              {fields.map((arrField, key) => {
                return (
                  <Controller
                    key={arrField.id}
                    control={methods.control}
                    name={`datajournal.${key}`}
                    render={({ field }) => {
                      if (editField === key) {
                        return (
                          <Body
                            tax={tax}
                            project={project}
                            key={arrField.id}
                            index={editField}
                            action="edit"
                            isHideForm={false}
                            defaultValues={field.value}
                            onCancelEditing={() => setEditField(null)}
                            onSubmitJournal={(value) => {
                              field.onChange(value)
                              setEditField(null)
                            }}
                          />
                        )
                      } else {
                        return (
                          <tr>
                            <td className="text-center">{key + 1}</td>
                            <td>{field.value.coa_name}</td>
                            <td>{field.value.memo}</td>
                            <td className="text-right">
                              {convertNumber(field.value.position === 1
                                ? field.value.amount
                                : 0
                              ).intoCurrency}
                            </td>
                            <td className="text-right">
                              {convertNumber(field.value.position === 2
                                ? field.value.amount
                                : 0
                              ).intoCurrency}
                            </td>
                            <td>{field.value.project_name}</td>
                            <td>{field.value.referensi}</td>
                            <td>{field.value.tax_name}</td>
                            <td>
                              <section className="flex justify-center">
                                <ActionButton.Update
                                  onClick={() => setEditField(key)}
                                />
                                <ActionButton.Delete
                                  onClick={() => remove(key)}
                                />
                              </section>
                            </td>
                          </tr>
                        )
                      }
                    }}
                  />
                )
              })}
              <Body
                key={fields.length}
                tax={tax}
                project={project}
                action="add"
                onSubmitJournal={(value) => append(value)}
              />
            </tbody>
          </BaseTable>

          <section className="flex justify-end">
            <FooterAccountTable total={total} />
          </section>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body className="flex flex-col gap-5">
          <section className="flex flex-wrap justify-between gap-3">
            <section className="flex flex-wrap gap-3">
              <CommandButton actiontype="help" />
              <CommandButton actiontype="print" />
              <CommandButton actiontype="email" />
              <CommandButton actiontype="export" />
              <JournalModal />
              <AttachmentModal isEdit={isEdit} />
            </section>

            <section className="flex flex-wrap gap-3">
              {isEdit && !reconsiled ? <CommandButton actiontype="delete" onClick={modalDelete.toggle} /> : null}

              <CommandButton
                actiontype="save"
                type="button"
                color="primary"
                permission="FT031"
                loading={methods.formState.isSubmitting ? "true" : undefined}
                onClick={onSubmit}
              />
            </section>
          </section>
        </Card.Body>
      </Card>

      <ModalDeleteJournal
        loadingData={loading.isActive}
        isOpen={modalDelete.isActive}
        journalList={dataJournal[0]?.data?.data ?? []}
        date={transaction_date}
        journalCode={journal_code!}
        toggle={modalDelete.toggle}
        refetchJournal={() => { navigate("/finance/journal", { replace: true }) }}
      />
    </section>
  )
}

export const Code = ({ isEdit }: { isEdit: boolean }) => {
  const { control, setValue, formState } = useFormContext<FormType>()
  const [transaction_date, trx_code] = useWatch({
    control,
    name: ["transaction_date", "trx_code"]
  })

  const code = useCode(transaction_date, !isEdit, formState.submitCount)

  useEffect(() => setValue("trx_code", code.data), [code.data])

  return <div>{trx_code}</div>
}

function ProjectHead({
  project
}: {
  project: ReturnType<typeof useProjectList>
}) {
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <th className="whitespace-nowrap">
        PROJECT ID{" "}
        <span
          className="whitespace-nowrap text-primary cursor-pointer"
          onClick={toggle}>
          [+]
        </span>
      </th>

      {isActive && (
        <ProjectModal
          toggle={() => {
            toggle()
            project.refetch()
          }}
        />
      )}
    </Fragment>
  )
}

function TaxHead({ tax }: { tax: ReturnType<typeof useTaxCodeList> }) {
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <th className="whitespace-nowrap">
        TAX CODE{" "}
        <span
          className="whitespace-nowrap text-primary cursor-pointer"
          onClick={toggle}>
          [+]
        </span>
      </th>

      {isActive && (
        <TaxCodeModal
          toggle={toggle}
          onSuccess={() => {
            toggle()
            tax.refetch()
          }}
        />
      )}
    </Fragment>
  )
}

function JournalModal() {
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <CommandButton actiontype="journal" onClick={toggle} />

      {isActive && (
        <Modal isOpen title="JOURNAL" closeModal={toggle} size="full">
          <Modal.Body>
            <TableJournal />
          </Modal.Body>
        </Modal>
      )}
    </Fragment>
  )
}

function UploadFile(props: {
  onSuccess: (result: string) => void
  uploadFileUrl: string
}) {
  const api = useApi()
  const fileRef = useRef<HTMLInputElement>(null)
  const { isActive, toggle } = useToggle(false)

  const onUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      toggle()

      toast
        .promise(
          api.postForm(props.uploadFileUrl, { filebukti: e.target.files[0] }),
          {
            loading: "Loading...",
            success: (res) => res.data.message,
            error: (err) => err.response.data.detail.message
          }
        )
        .then((res: SuccessFetch<{ payload: { namafile: string } }>) => {
          props.onSuccess(res.data.payload.namafile)

          if (fileRef.current) {
            fileRef.current.value = ""
          }
        })
        .catch(console.error)
        .finally(toggle)
    }
  }

  return (
    <Fragment>
      <input
        hidden
        id="file-upload"
        type="file"
        ref={fileRef}
        onChange={onUpload}
      />

      <label
        htmlFor="file-upload"
        className="w-fit btn btn-primary rounded-none di">
        {isActive && <span className="loading loading-spinner" />}
        <TbFileUpload size={24} /> UPLOAD FILE
      </label>
    </Fragment>
  )
}

function AttachmentModal({ isEdit }: { isEdit: boolean }) {
  const api = useApi()
  const { isActive, toggle } = useToggle(false)
  const { control, getValues, setValue } = useFormContext<FormType>()
  const trx_code = useWatch({
    control,
    name: "trx_code"
  })

  return (
    <Fragment>
      <CommandButton actiontype="attachment" onClick={toggle} />

      {isActive && (
        <Modal isOpen title="ATTACHMENT" closeModal={toggle}>
          <Modal.Body>
            <UploadFile
              uploadFileUrl="/transfermoney/uploadfile"
              onSuccess={(result) =>
                setValue("imgPath", [...getValues("imgPath"), result])
              }
            />

            <Controller
              control={control}
              name="imgPath"
              render={({ field, fieldState }) => (
                <section ref={field.ref}>
                  <FileList
                    label={trx_code}
                    error={fieldState.error?.message}
                    items={
                      isEdit
                        ? field.value.map((item) => item.file_path)
                        : field.value
                    }
                    onDelete={(item, key) => {
                      const data = [...field.value]

                      if (!isEdit) {
                        data.splice(key, 1)
                        return field.onChange(data)
                      }

                      api
                        .post(`/transfermoney/removebukti?strPath=${item}`)
                        .then(() => data.splice(key, 1))
                        .finally(() => field.onChange(data))
                    }}
                  />
                </section>
              )}
            />
          </Modal.Body>
        </Modal>
      )}
    </Fragment>
  )
}
