import { useEffect, useState } from "react"
import { Button, Card, CommandButton, HeaderMenu, Loading } from "../../../../../components"
import { FormProvider, useForm } from "react-hook-form"
import { useLocation, useNavigate } from "react-router-dom"
import type { BuyingList, ComponentList, ProductList, ProfileList } from "../types"
import { RenderProvider } from "../utils"
import { Tab } from "./ChildItem/components/Item/components/Tabs"
import { FormPersonalData } from "./FormSection/FormPersonalData"
import { FormProfile } from "./FormSection/FormProfile"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import toast from "react-hot-toast"
import { useApi } from "../../../../../utils"
import { FormBuying } from "./FormSection/FormBuying"
import { FormComponentCreate } from "./FormSection/FormComponent"
import { FormSellingCreate } from "./FormSection/FormSelling"
import { FormPictures } from "./FormSection/FormPictures"
import { useDetailPictureData, usePriceUnit } from "./ChildItem/components/Item/utils"
import { FormPerformance } from "./FormSection/FormPerformence"
import { FormTransaction } from "./FormSection/FormTransaction"

export function CreatePageTwo() {
  const navigate = useNavigate()
  const api = useApi()
  const location = useLocation()
  const id = location?.state?.employee_id
  const tabs = ["PROFILE", "BUYING", "COMPONENT", "SELLING", "LOCATION", "PERFORMENCE", "PICTURE", "TRANSACTION", "LOG"];

  const [personalDataPayload, setPersonalDataPayload] = useState<any>(undefined);
  const [activeTab, setActiveTab] = useState<number | undefined>(undefined);
  const [fileValue, setFileValue] = useState<File | undefined>(undefined);
  const [isActiveSubmit, setIsActiveSubmit] = useState<any>({ 
    personal: false, 
    profile: false,
    buying: false,
    component: false,
    selling: false,
    location: false,
    performance: false,
    picture: false,
    transaction: false,
    log: false,
  })
  const [itemsProfile, setItemsProfile] = useState<any | undefined>({ buy: "", sell: "", inventory: "" });
  const [disableTab, setDisableTab] = useState(false);
  const [responseValue, setResponseValue] = useState<any>(undefined);
  const [checkLoading, setCheckLoading] = useState<boolean>(false)
  const [discountAmound, setDiscountAmound] = useState<string>("")
  const [buyingPrice, setBuyingPrice] = useState<string>("")
  const [componentCost, setComponentCost] = useState<string>("")

  const [selected, setSelected] = useState<any[]>([]);
  const [fieldValue, setFieldValue] = useState<any>({ 
    total_ingredient_cost: undefined, 
    component_at_cost: undefined, 
    cogm: undefined, 
    cost_production: undefined, 
    wasted: undefined, 
    overhead_cost: undefined,
    sales_tax: undefined,
    other_change: undefined,
    build_item: undefined,
    quantity:  1,
  })
  const [selectedValue, setSelectedValue] = useState<any>({ 
    wholesales_mark_up: 0,
    wholesales_profit: 0,
    distributor_mark_up: 0,
    distributor_profit: 0,
    factory_mark_up: 0,
    factory_profit: 0,
    online_mark_up: 0,
    online_profit: 0,
    special_arrangement_mark_up: 0,
    special_arrangement_profit: 0,
  });

  const { dataPicture, errorPicture, isLoadingPicture, refetchPicture } = useDetailPictureData(personalDataPayload?.product_id ? personalDataPayload?.product_id : null)
  const { dataPriceUnit, refetchPriceUnit, isLoadingPriceUnit, errorPriceUnit } = usePriceUnit(personalDataPayload?.product_id ? personalDataPayload?.product_id : null)
  
  useEffect(() => {
    if(personalDataPayload?.product_id) {
      refetchPriceUnit()
    }
    // eslint-disable-next-line
  }, [personalDataPayload?.product_id])
  

  type CreatePersonalData = Pick<ProductList, 
    "product_code" | "product_barcode" | "product_name" | "mr_product_group_id" | "mr_product_style_id" |
    "main_size_id" | "mr_product_colour_id" | "mr_product_category_id" | "mr_category_customer_id" | "type" |
    "mr_product_material_id" | "Product_Foto" | "length" | "width" | "height" | "weight" | "weight_unit_id" | "size_type"
  >;
  type CreateComponentData = Pick<ComponentList, "mr_product_group_id" | "unit_build_id">;

  // FORM VALUES
  const defaultValues: CreatePersonalData = {
    length: "",
    width: "",
    height: "",
    weight: "",
    weight_unit_id: "",
    product_code: "",
    product_barcode: "",
    product_name: "",
    mr_product_group_id: "",
    mr_product_style_id: "",
    main_size_id: "",
    mr_product_colour_id: "",
    mr_product_category_id: "",
    mr_category_customer_id: "",
    mr_product_material_id: "",
    Product_Foto: "",
    size_type: "",
    type: "1",
  }
  const profileValues = {
    product_id: personalDataPayload?.product_id,
    active: true,
    inactive: false,
    damage: false,
    build_item: false,
    buy: false,
    sell: false,
    inventory: false,

    online_description: "",
    coa_buy: "",
    coa_sell: "",
    coa_asset: "",
    explanation: "explaination",
    discount_value: "",
    discount_start: "",
    
    is_apply: false,
    is_online: false,
  }
  const buyingValues = {
    product_id: personalDataPayload?.product_id,
    unit_measure_id: NaN,
    quantity_unit_measure: "",
    price_unit_measure : "",
    unit_convention_id : "",
    quantity_unit_convention: "",
    price_unit_convention: "",
    datavalue: [],
  }
  const componentValues = { 
    mr_product_group_id: "",
    unit_build_id: ""
  }
  const sellingValues = { 
    product_id: personalDataPayload?.product_id,
    product_price_unit_id: "",
    product_component_cost_id: "",
    overhead: "",
    sales_tax: "",
    other_changes: "",
    cogm: 0,
    mr_unit_id: "",
    quantity: "1",
    tax_id: "",
    price_retail: "",
    wholesales_price: "",
    distributor_price: "",
    factory_price: "",
    online_price: "",
    special_arrangement_price: "",
    
    price_unit_convention: "0",
    component_at_cost: "0",
    // discount_amound: discountAmound ?? "0"

    unit_sell_measure_id: NaN,
    quantity_unit_sell_measure: NaN,
    quantity_unit_sell_convention: NaN
  }

  const validationSchema = yup.object().shape({
    product_code: yup.string().label("Item barcode").required(),
    product_barcode: yup.string().label("Item barcode").required(),
    product_name: yup.string().label("Item name").required(),
    mr_product_group_id: yup.string().label("Item group").required(),
    mr_product_style_id: yup.string().label("Style").required(),
    main_size_id: yup.string().label("Size").required(),
    mr_product_colour_id: yup.string().label("Colour").required(),
    mr_product_category_id: yup.string().label("Category").required(),
    mr_category_customer_id: yup.string().label("Market segment").required(),
    mr_product_material_id: yup.string().label("Material").required(),
    Product_Foto: yup.string().label("Profile").optional(),
    length: yup.string().label("Length").optional(),
    width: yup.string().label("Width").optional(),
    height: yup.string().label("Height").optional(),
    weight: yup.string().label("Weight").required().max(2, 'Maximum code is 2 characters'),
    weight_unit_id: yup.string().required("Unit Required"),
    size_type: yup.string().required("Size type Required"),
    type: yup.string().required("Type Required"),
  })
  
  const coaYup = () => {
    if (itemsProfile?.buy === "B" && itemsProfile?.sell === "S" && itemsProfile?.inventory === "I") { return { coa_buy: yup.string().label("Cogs Account Costs").required() } }
    if (itemsProfile?.buy === "B" && itemsProfile?.sell === "S") { return { coa_buy: yup.string().label("Expences and Cogs").required() } }
    if (itemsProfile?.sell === "S" && itemsProfile?.inventory === "I") { return { coa_buy: yup.string().label("Cogs Account Costs").required() } }
    if (itemsProfile?.buy === "B" && itemsProfile?.inventory !== "I") { return { coa_buy: yup.string().label("Expences").required() } }

    return { coa_buy: yup.string().label("Expences").defined().nullable() }
  }
  const coaSellYup = () => {
    if (itemsProfile?.sell === "S") { return { coa_sell: yup.string().label("Income Account Sales").required() } }

    return { coa_sell: yup.string().label("Income Account Sales").defined().nullable() }
  }
  const coaAssetYup = () => {
    if (itemsProfile?.inventory === "I") { return { coa_asset: yup.string().label("Asset Account Inventory").required() } }

    return { coa_asset: yup.string().label("Asset Account Inventory").defined().nullable() }
  }
  const profileValidationSchema = yup.object().shape({
    explanation: yup.string().label("Explanation").required(),
    ...coaYup(),
    ...coaSellYup(),
    ...coaAssetYup()
  })
  const buyingValidationSchema = yup.object().shape({
    product_id: yup.string().label("Product").optional(),
    // unit_measure_id: yup.string().label("Unit measure").optional(),
    quantity_unit_measure: yup.string().label("Qty measure").optional(),
    price_unit_measure: yup.string().label("Price measure").optional(),
    
    unit_convention_id: yup.string().label("Unit convention").required(),
    quantity_unit_convention: yup.string().required("Quantity is a required! "),
    price_unit_convention: yup.string().label("Avarage").required(),

    vendor_id: yup.string().label("Vendor").optional(),
    vendor_code: yup.string().label("Vendor code").optional(),
    vendor_name: yup.string().label("Vendor name").optional(),
    vendor_description: yup.string().label("Vendor description").optional(),
    buying_price: yup.string().label("Buying price").optional(),
    tax_id: yup.string().label("Tax").optional(),
    tax_name: yup.string().label("Tax name").optional(),
    datavalue: yup.array().label("Data list").defined(),
  })
  const complimentCalidationSchema = yup.object().shape({ 
    mr_product_group_id: yup.string().label("Build component").required(),
    unit_build_id: yup.string().required("Build component is required!")
  })

  // HANDLE FORM
  const methods = useForm<CreatePersonalData>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })
  const profileMethods = useForm<ProfileList>({
    defaultValues: profileValues,
    resolver: yupResolver(profileValidationSchema)
  })
  const buyingMethods = useForm<BuyingList>({
    defaultValues: buyingValues,
    resolver: yupResolver(buyingValidationSchema)
  })
  const componentMethods = useForm<CreateComponentData>({  
    defaultValues: componentValues,
    resolver: yupResolver(complimentCalidationSchema)
  })
  const sellingMethods = useForm<any>({ defaultValues: sellingValues })

  // HANDLE SUBMIT
  const onSubmit = (value: ProductList) => {
    const formData = new FormData();
    
    formData.append('product_code', value?.product_code);
    formData.append('product_barcode', value?.product_barcode);
    formData.append('product_name', value?.product_name);
    formData.append('mr_product_group_id', value?.mr_product_group_id);
    formData.append('mr_product_style_id', value?.mr_product_style_id);
    if(value?.main_size_id){formData.append('main_size_id', value?.main_size_id)}
    formData.append('mr_product_colour_id', value?.mr_product_colour_id);
    formData.append('mr_product_category_id', value?.mr_product_category_id);
    formData.append('mr_category_customer_id', value?.mr_category_customer_id);
    formData.append('mr_product_material_id', value?.mr_product_material_id);
    if(value?.Product_Foto){formData.append(fileValue ? 'Product_Foto' : "", value?.Product_Foto)}
    formData.append('size_type', value?.size_type);
    formData.append('type', value?.type);

    const params: { [key: string]: string | undefined } = {};
    if (id) params.product_id = id;
    if (isActiveSubmit?.personal) params.product_id = personalDataPayload?.product_id;
    if (methods.control?._formValues?.length) params.length = methods.control._formValues.length;
    if (methods.control?._formValues?.width) params.width = methods.control._formValues.width;
    if (methods.control?._formValues?.height) params.height = methods.control._formValues.height;
    if (methods.control?._formValues?.weight) params.weight = methods.control._formValues.weight;
    if (methods.control?._formValues?.weight_unit_id) params.weight_unit_id = methods.control._formValues.weight_unit_id;


    return new Promise<void>((resolve) => {
      toast.promise(
        isActiveSubmit?.personal 
        ? api.put("/product/edit", formData, { params })
        : api.post("/product/add", formData, { params }),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message
        }
      )
      .then((res) => {
        setActiveTab(0)
        setDisableTab(true)
        setPersonalDataPayload(res?.data?.payload?.[0])
        setIsActiveSubmit({ ...isActiveSubmit, personal: true })
      })
      .catch(() => {})
      .finally(resolve)
    })
  }
  const onProfileSubmit = () => {
    const data = new URLSearchParams();

    data.append('product_id', personalDataPayload?.product_id);
    data.append('active', profileMethods.control?._formValues?.active);
    data.append('inactive', profileMethods.control?._formValues?.inactive);
    data.append('damage', profileMethods.control?._formValues?.damage);
    data.append('build_item', profileMethods.control?._formValues?.build_item);
    data.append('buy', profileMethods.control?._formValues?.buy);
    data.append('sell', profileMethods.control?._formValues?.sell);
    data.append('inventory', profileMethods.control?._formValues?.inventory);

    const params: { [key: string]: string | undefined } = {};
    if (profileMethods.control?._formValues?.online_description) params.online_description = profileMethods.control._formValues.online_description;
    if (profileMethods.control?._formValues?.coa_buy) params.coa_buy = profileMethods.control._formValues.coa_buy;
    if (profileMethods.control?._formValues?.coa_sell) params.coa_sell = profileMethods.control._formValues.coa_sell;
    if (profileMethods.control?._formValues?.coa_asset) params.coa_asset = profileMethods.control._formValues.coa_asset;
    if (profileMethods.control?._formValues?.explanation) params.explanation = profileMethods.control._formValues.explanation;
    if (profileMethods.control?._formValues?.discount_value) params.discount_value = profileMethods.control._formValues.discount_value;
    if (profileMethods.control?._formValues?.discount_start) params.discount_start = profileMethods.control._formValues.discount_start;

    return new Promise<void>((resolve) => {
      toast.promise(
        api.put("/product/updateprofile", data, { params }),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => {
            if (err.response && err.response.status === 422) {
              return err.response.data.message || "Bad Request";
            } else {
              return "An error occurred";
            }
          }
        }
      )
      .then((res) => {
        setIsActiveSubmit({ ...isActiveSubmit, profile: true })
        setResponseValue(res?.data?.payload?.[0])
      })
      .catch(() => {})
      .finally(resolve)
    })
  }

  function getFinalValue(value: BuyingList) {
    const data_value: {
      product_id: any,
      product_buying_id: number | undefined
      vendor_id: string,
      vendor_description: string,
      buying_price: string,
      tax_id: number,
    }[] = []
  
    value.datavalue.map(item => {
      return data_value.push({
        product_id: personalDataPayload?.product_id,
        product_buying_id: item.product_buying_id,
        vendor_id: item.vendor_id,
        vendor_description: item.vendor_description,
        buying_price: item.buying_price,
        tax_id: parseFloat(item.tax_id),
      })
    })
  
    return {
      product_id: personalDataPayload?.product_id,
      unit_measure_id: parseFloat(value.unit_measure_id),
      quantity_unit_measure: value.quantity_unit_measure,
      price_unit_measure: value.price_unit_measure,
      unit_convention_id: value.unit_convention_id,
      quantity_unit_convention: value.quantity_unit_convention,
      price_unit_convention: value.price_unit_convention,
      DataProductBuying: { dataproductbuying: data_value },
    }
  }
  const onBuyingSubmit = (value: BuyingList) => {
    return new Promise<void>((resolve) => {

      toast.promise(
        api.post("/product/buying", getFinalValue(value)),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.detail.message
        }
      )
      .then(() => { 
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        refetchPriceUnit()
      })
      .catch(() => {})
      .finally(resolve)
    })
  }
  const onSellingSubmit = () => {

    const requestData = {
      SchemaProductSelling: {
        product_id: personalDataPayload?.product_id,
        product_price_unit_id: sellingMethods.control?._formValues?.product_price_unit_id,
        product_component_cost_id: sellingMethods.control?._formValues?.product_component_cost_id,
        overhead: sellingMethods.control?._formValues?.overhead,
        sales_tax: sellingMethods.control?._formValues?.sales_tax,
        other_changes: sellingMethods.control?._formValues?.other_changes,
        cogm: sellingMethods.control?._formValues?.cogm,
        mr_unit_id: sellingMethods.control?._formValues?.mr_unit_id,
        quantity: sellingMethods.control?._formValues?.quantity,
        tax_id: sellingMethods.control?._formValues?.tax_id,
        price_retail: sellingMethods.control?._formValues?.price_retail,
        wholesales_price: sellingMethods.control?._formValues?.wholesales_price,
        distributor_price: sellingMethods.control?._formValues?.distributor_price,
        factory_price: sellingMethods.control?._formValues?.factory_price,
        online_price: sellingMethods.control?._formValues?.online_price,
        special_arrangement_price: sellingMethods.control?._formValues?.special_arrangement_price
      },
      SchemaProductPriceUnit: {
        product_id: personalDataPayload?.product_id,
        unit_measure_id: dataPriceUnit?.unit_measure_id,
        quantity_unit_measure: dataPriceUnit?.quantity_unit_measure,
        price_unit_measure: dataPriceUnit?.price_unit_measure,
        unit_convention_id: dataPriceUnit?.unit_convention_id,
        quantity_unit_convention: dataPriceUnit?.quantity_unit_convention,
        price_unit_convention: dataPriceUnit?.price_unit_convention,
        unit_sell_measure_id: sellingMethods.control?._formValues?.unit_sell_measure_id,
        quantity_unit_sell_measure: sellingMethods.control?._formValues?.quantity_unit_sell_measure,
        quantity_unit_sell_convention: sellingMethods.control?._formValues?.quantity_unit_sell_convention
      }
    };

    return new Promise<void>((resolve) => {
      toast.promise(
        api.post("/product/selling", requestData),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => {
            if (err.response && err.response.status === 422) {
              return err.response.data.message || "Bad Request";
            } else {
              return "An error occurred";
            }
          }
        }
      )
      .then(() => {})
      .catch(() => {})
      .finally(resolve)
    })
  }

  return (
    <RenderProvider>
      <section className="container my-2">
        <HeaderMenu title="DATA ENTRY | CREATE NEW ITEM" />
        <Card>
          <Card.Body>
            <div className="flex justify-end">
              <Button
                type="button"
                className="!none" 
                color="primary"
                permission="IT011"
                onClick={() => navigate(location?.pathname === "/inventory/transaction/add-new-item" ? "/inventory/transaction/add-new-items" : "/inventory/transaction/add-new-item")}
              >
                CREATE NEW ITEM
              </Button>
            </div>
            <div className="lg:flex">
              <div className="lg:w-[34%] mr-0.5">
                <button className="bg-[#3c3c3c] px-4 py-2 w-full mr-0.5 text-white text-[12px]"><b>PRODUCT DATA</b></button>
                <div className={`max-w-sm w-full lg:max-w-full lg:flex h-[${methods?.control?._formState?.errors ? "1950" : "1750px"}]`}>
                  <div className="w-full border border-[#dadada] dark:border-[#3c3c3c] p-6">
                    <FormProvider {...methods}>
                      {/* @ts-ignore */}
                      <FormPersonalData 
                        action="CREATE" 
                        onSubmit={onSubmit} 
                        setFileValue={setFileValue} 
                        isActiveSubmit={isActiveSubmit?.personal} 
                        setItemsProfile={setItemsProfile} 
                        itemsProfile={itemsProfile} 
                        setCheckLoading={setCheckLoading}
                        permission="IT011"
                      />
                    </FormProvider>
                  </div>
                </div>
              </div>
              <div className="lg:w-[66%]">
                <Tab action="CREATE" tabs={tabs} activeTab={activeTab} disabled={!disableTab} setActiveTab={setActiveTab} dataProfile={responseValue} className="text-[11px]" />
                <div className="">
                  <div className={`max-w-sm w-full lg:max-w-full lg:flex h-[${methods?.control?._formState?.errors ? "1950" : "1750px"}]`}>
                    <div className="w-full border border-[#dadada] dark:border-[#3c3c3c] p-6 mr-0.5">
                      <FormProvider {...profileMethods}>
                        {activeTab === 0 &&
                          <FormProfile 
                            action="CREATE" 
                            onSubmit={onProfileSubmit} 
                            setItemsProfile={setItemsProfile} 
                            itemsProfile={itemsProfile} 
                            setDiscountAmound={setDiscountAmound} 
                            onlineValue={personalDataPayload?.product_name}
                            permission="IT011"
                          />
                        }
                      </FormProvider>
                      <FormProvider {...buyingMethods}>
                        {activeTab === 1 && 
                          <FormBuying 
                            action="CREATE" 
                            onSubmit={buyingMethods.handleSubmit(onBuyingSubmit)} 
                            checkLoading={checkLoading} 
                            setCheckLoading={setCheckLoading} 
                            setBuyingPrice={setBuyingPrice} 
                            permission="IT011"
                          />
                        }
                      </FormProvider>
                      <FormProvider {...componentMethods}>
                        {activeTab === 2 && 
                          <FormComponentCreate 
                            selected={selected} 
                            setSelected={setSelected} 
                            fieldValue={fieldValue} 
                            setFieldValue={setFieldValue} 
                            id={personalDataPayload?.product_id} 
                            action="CREATE" 
                            onSubmit={() => {}} 
                            setComponentCost={setComponentCost}
                            itemsProfile={itemsProfile} 
                            refetchPriceUnit={refetchPriceUnit} 
                            permission="IT011"
                          />
                        }
                      </FormProvider>
                      <FormProvider {...sellingMethods}>
                        {activeTab === 3 
                          ? isLoadingPriceUnit || errorPriceUnit
                            ? <Loading errorText={errorPicture} loading={isLoadingPriceUnit} />
                            : <FormSellingCreate
                                productId={personalDataPayload?.product_id}
                                action="CREATE" 
                                discountAmound={discountAmound} 
                                onSubmit={sellingMethods.handleSubmit(onSellingSubmit)} 
                                setCheckLoading={() => {}} 
                                buyingPriceValue={buyingPrice}
                                componentCostValue={componentCost}
                                selectedValue={selectedValue}
                                setSelectedValue={setSelectedValue}
                                permission="IT011"
                              />
                            : ""
                        }
                      </FormProvider>
                      {activeTab === 5 && <FormPerformance id={personalDataPayload?.product_id} /> }
                      {activeTab === 6
                        ? isLoadingPicture || errorPicture 
                          ? <Loading errorText={errorPicture} loading={isLoadingPicture} /> 
                          : <FormPictures dataPicture={dataPicture} refetchPicture={refetchPicture} id={personalDataPayload?.product_id} permission="IT011"  /> 
                        : ""
                      }
                      {activeTab === 7 && <FormTransaction product_id={personalDataPayload?.product_id} /> }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
        <section className="mt-2">
            <Card>
              <Card.Body className="flex flex-col gap-5">
                <section className="flex flex-wrap justify-between items-center gap-3">
                  <section className="flex flex-wrap gap-3">
                    <CommandButton actiontype="help" />
                    <CommandButton actiontype="print" />
                    <CommandButton actiontype="email" />
                    <CommandButton actiontype="export" />
                  </section>
                </section>
              </Card.Body>
            </Card>
          </section>
      </section>
    </RenderProvider>
  )
}