// React
import { DetailedHTMLProps, InputHTMLAttributes, PropsWithChildren, forwardRef } from "react"

interface PreType extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label?: string
  error?: string
  className?: string
}

interface Type extends Omit<PreType, "value"> {
  value?: boolean
  className?: string
  labelClassName?: string
}

export const Checkbox = forwardRef((prop: PropsWithChildren<Type>, ref) => {
  // Variables
  const {value, children, ...props} = prop

  return (
    <section className="w-full form-control">
      {props.label && (
        <label className="label">
          <span className={`label-text font-bold ${props.error && "text-error"}`}>{props.label}</span>
        </label>
      )}

      <label className={`justify-start label cursor-pointer gap-2 !p-0 ${props.disabled && "!cursor-not-allowed"} ${props.labelClassName}`}>
        <input
          {...props}
          // @ts-ignore
          ref={ref}
          type="checkbox"
          checked={value}
          className={`checkbox checkbox-primary ${props.className}`}
        />

        {children && <span className={`label-text ${props.error && "text-error"}`}>{children}</span> }
      </label>

      {props.error && (
        <label className="label">
          <span className={`label-text-alt font-bold ${props.error && "text-error"}`}>{props.error}</span>
        </label>
      )}
    </section>
  )
})