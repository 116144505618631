import { useContext, useEffect } from "react"
import { Button, HintList, Modal } from "../../../../../components"
import { FormSection } from "./FormSection"
import * as yup from "yup"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import toast from "react-hot-toast"
import type { ListBank } from "../types"
import { useApi } from "../../../../../utils"
import { Render, useCode } from "../utils"

export function CreateModal(props: {
  group_coa_id?: number
  account_type_code?: string
  account_type_name?: string
  description?: string 
  toggle: () => void
  refetch?: () => void
}) {

  const api = useApi()
  const { toggle } = useContext(Render)

  const { dataCode} = useCode()

  useEffect(() => {
    if (dataCode?.code) {
      methods.setValue("account_type_code", dataCode?.code)
    }
 // eslint-disable-next-line
  }, [dataCode?.code])

  type CreateListBank = Omit<ListBank, "group_coa_id" | "account_type_id" | "group_coa_type" | "group_coa_name"> 

  const defaultValues = {
    group_coa_id: props.group_coa_id ?? 1,
    account_type_code: "",
    account_type_name: "",
    description: "",
  }
  const validationSchema = yup.object().shape({
    account_type_code: yup.string().label("Code").required().max(3, 'Maximum code is 3 characters'),
    account_type_name: yup.string().label("Name Type").required(),
    description: yup.string().label("Description").max(500, 'Maximum code is 500 characters'),
  })
  const onSubmit = (value: CreateListBank) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.post("/acctype/add", value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => {
            if (err.response && err.response.status === 409 ) {
              return err.response.data.message || err.response.data.detail.message;
            } else {
              return "An error occurred";
            }
          }
        }
      ).then(() => {
        props.toggle()
        props?.refetch && props.refetch()
        toggle()
      }).catch(() => {}).finally(resolve)
    })
  }
  const methods = useForm<CreateListBank>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })

  return (
    <Modal isOpen title="SETUP | COA TYPE" closeModal={props.toggle}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Modal.Body
            hintText={(
              <HintList
                title="Creating COA TYPE"
                list={[
                  {
                    label: "Code",
                    list: [
                      {
                        label: "Definition",
                        value: "A unique alphanumeric identifier assigned to each account in the chart of accounts."
                      },
                      {
                        label: "Purpose",
                        value: "The code simplifies account identification and enables quick data entry and lookup. It helps maintain the integrity and consistency of financial transactions and records."
                      },
                      {
                        label: "Example",
                        value: `Codes like "1001" for "Cash", "2001" for "Accounts Payable", or "4001" for "Sales Revenue" can be used to distinguish different account types.`
                      }
                    ]
                  },
                  {
                    label: "CoA Group",
                    list: [
                      {
                        label: "Definition",
                        value: "The grouping category that classifies the accounts into major financial categories such as assets, liabilities, equity, income, and expenses."
                      },
                      {
                        label: "Purpose",
                        value: "The CoA Group helps organize the accounts systematically, enabling easier reporting and analysis. It allows users to understand how various accounts relate to each other and to the company's financial statements."
                      },
                      {
                        label: "Example",
                        value: `"Assets" could include groups like "Current Assets" and "Fixed Assets." "Liabilities" might include "Current Liabilities" and "Long-term Liabilities."`
                      },
                    ]
                  },
                  {
                    label: " Description Type",
                    list: [
                      {
                        label: "Definition",
                        value: "A textual description or label that provides more details about the purpose and usage of the account."
                      },
                      {
                        label: "Purpose",
                        value: "The Description Type helps users understand the account's function, ensuring consistent use across departments and operations. It also aids in financial reporting, audit trails, and decision-making by providing context to each account entry."
                      },
                      {
                        label: "Example",
                        value: `For an account code "4001," the Description Type could be "Sales Revenue - Domestic," indicating that it records revenue generated from domestic sales only.`
                      },
                    ]
                  },
                  {
                    label: " Note",
                    list: [
                      {
                        label: "Definition",
                        value: "Additional information or comments that provide specific instructions, clarifications, or relevant details about the account. "
                      },
                      {
                        label: "Purpose",
                        value: "Notes help provide context or special conditions associated with an account, such as usage guidelines, accounting methods, or regulatory requirements. They can also contain reminders or annotations that may be important for auditors, accountants, or system users."
                      },
                      {
                        label: "Example",
                        value: `A note for an account under "Accounts Payable" might include "Reconcile this account monthly" or "Used only for transactions over $5,000."`
                      },
                    ]
                  }
                ]}
              />
            )}
          >
            <FormSection />
          </Modal.Body>

          <Modal.Footer className="text-end">
            <Button type="submit" color="primary" loading={methods.formState.isSubmitting ? "true" : undefined}>Save</Button>
          </Modal.Footer>
        </form>
      </FormProvider>
    </Modal>
  )
}