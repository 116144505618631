import moment from "moment"
import { FormType, SubmitType } from "../../types"
import { getCoaTaxJournal } from "./getCoaTaxJournal"

export const submitMap = (data: FormType): SubmitType => {
  const tomorrow = moment().add(1, "days").format("YYYY-MM-DD")

  return {
    financeid: data.finance_id ? Number(data.finance_id) : undefined,
    journal_code: data.journal_code ? data.journal_code : undefined,
    DataFinance: {
      trx_code: data.trx_code,
      data_source: 4,
      transaction_date: data.transaction_date,
      group_card: Number(data.group_card),
      card_id: Number(data.card_id),
      location_id: Number(data.location_id),
      memo: data.memo,
      type_payment: 0,
      name_payment: "",
      number_payment: "",
      expired_payment: tomorrow,
      authorization_payment: "",
      note_payment: "",
      reference_no: data.reference_no,
      amount: data.amount,
      tax_inclusive: false
    },
    DataFinanceDetail: {
      datadetailfinance: data.datajournal.map((journal, key) => ({
        ...journal,
        no_urut: key + 1,
        coa_id: Number(journal.coa_id),
        project_id: Number(journal.project_id),
        tax_id: Number(journal.tax_id)
      }))
    },
    DataJournal: {
      datajournal: getCoaTaxJournal(data.datajournal)?.map((journal, key) => ({
        ...journal,
        transaction_date: data.transaction_date,
        trx_code: data.trx_code,
        card_id: Number(data.card_id),
        group_card: Number(data.group_card),
        location_id: Number(data.location_id)
      }))
    },
    imgPath:
      data.imgPath?.map((item) =>
        typeof item === "string" ? item : item.file_path
      ) ?? []
  }
}
