// React
import { Fragment } from "react"

// Components
import { ActionButton, Button, Loading, Modal } from "../../../../../../components"
import { FormSection } from "../../FormSection"

// Form
import { FormProvider, useForm } from "react-hook-form"

// Third-Party Libraries
import toast from "react-hot-toast"

// Types
import type { FormType } from "../../../types"

// Utils
import { useApi, useToggle } from "../../../../../../utils"
import { useDetail } from "../utils"

export function Delete(props: { id: number; onSuccess: () => void }) {
  // Hooks
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <ActionButton.Delete permission={"AR014"} className="join-item" onClick={toggle} />
      {isActive && <ModalSection id={props.id} toggle={toggle} onSuccess={props.onSuccess} />}
    </Fragment>
  )
}

function ModalSection(props: {
  id: number
  onSuccess: () => void
  toggle: () => void
}) {
  // Hooks
  const { data, dataError, isLoading } = useDetail(props.id)

  return (
    <Modal isOpen title="DETAIL CHART OF ACCOUNT" size="xl" closeModal={props.toggle}>
      {isLoading || dataError ? (
        <Loading errorText={dataError} loading={isLoading} />
      ) : (
        <DataForm data={data!} id={props.id} toggle={props.toggle} onSuccess={props.onSuccess} />
      )}
    </Modal>
  )
}

function DataForm(props: {
  data: FormType
  id: number
  onSuccess: () => void
  toggle: () => void
}) {
  // Hooks
  const api = useApi()

  // Form
  const onSubmit = (
    // @ts-ignore
    value: FormType
  ) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.delete("/coa/delete", { params: { coa_id: props.id } }),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message
        }
      ).then(() => {
        props.toggle()
        props.onSuccess()
      }).catch(() => {}).finally(resolve)
    })
  }
  const methods = useForm({
    defaultValues: props.data
  })

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <h5 className="text-error">Are you sure want to delete this data?</h5>
        <FormSection isDetail />

        <Modal.Footer className="text-end">
          <Button type="submit" color="error" loading={methods.formState.isSubmitting ? "true" : undefined}>Delete</Button>
        </Modal.Footer>
      </form>
    </FormProvider>
  )
}