/* eslint-disable react-hooks/exhaustive-deps */
import { TableNumber } from "components"
import moment from "moment"
import { APPROVAL } from "pages/Sales/Register/utils/vars"
import { Fragment } from "react"
import { useDropdownEmployee } from "../../CustomerOrder/utils/hooks/useDropdownEmployee"
import { InvoiceList } from "../../Invoice/utils/hooks/useInvoiceList"
import { FilterApprovalInvoice } from "../types"
import { ApproveAction } from "./ApproveAction"

type RowApproveInvoiceProps = {
  pagination: FilterApprovalInvoice
  employees: ReturnType<typeof useDropdownEmployee>["dropdown"]
  data: InvoiceList
  index: number
}

export function RowApproveInvoice({
  pagination,
  employees,
  data,
  index
}: RowApproveInvoiceProps) {
  return (
    <Fragment>
      <tr key={index}>
        <td>
          {TableNumber({
            pageCurrent: pagination?.page,
            dataLength: pagination?.size!,
            index: index,
            limit: pagination?.limit,
            totalData: pagination?.total
          })}
        </td>
        <td>
          <ApproveAction employees={employees} data={data} />
        </td>
        <td>{data.trx_code}</td>
        <td>{moment(data.transaction_date).format("DD/MM/YYYY")}</td>
        <td>{data.customer.customer_name}</td>
        <td>{data.sales_order.trx_code}</td>
        <td className="text-center">{data.delivered_qty}</td>
        <td>{data.location.location_name}</td>
        <td>{data?.memo}</td>
        <td>{APPROVAL[data.approval_status]}</td>
        <td>
          {data?.approve_date
            ? moment(data.approve_date).format("DD/MM/YYYY")
            : ""}
        </td>
      </tr>
    </Fragment>
  )
}
