import { getDiscount } from "pages/Finance/Transaction/utils";
import { FormType } from "../../types";

type ResultCoaTaxJournal = Array<{
  detail_index: number;
  coa_id: number;
  project_id: number;
  amount: number;
  tax_id: number;
  memo: string;
  position: number;
  coa_name: string;
  referensi: string;
}>;

export const getCoaTaxJournal = (
  data: FormType["datajournal"]
): ResultCoaTaxJournal => {
  let result: ResultCoaTaxJournal = [];

  data.forEach((item, index) => {
    result.push({
      detail_index: index,
      amount: item.amount - getDiscount(item.amount, item.rate),
      coa_id: Number(item.coa_id),
      project_id: Number(item.project_id),
      tax_id: Number(item.tax_id),
      memo: item.memo,
      position: item.position,
      coa_name: item.coa_name,
      referensi: item.referensi
    });

    if (item.rate > 0) {
      result.push({
        detail_index: index,
        amount: getDiscount(item.amount, item.rate),
        coa_id: Number(item.tax_coa_paid_id),
        project_id: Number(item.project_id),
        tax_id: Number(item.tax_id),
        coa_name: item.tax_coa_paid,
        memo: item.memo,
        position: item.position,
        referensi: item.referensi
      });
    }
  });

  return result;
};
