import { useState } from "react"
import type { SelectOptionType, SuccessFetch } from "../../../../../../../../types"
import { useApi } from "../../../../../../../../utils"

export function useSubdistrict() {
  const api = useApi()
  const [data, setData] = useState<SelectOptionType[]>([])
  const [isLoading, setLoading] = useState(false)

  const refetch = (regency_id?: string) => {
    setLoading(true)

    api.get("/subdistricts/", {
      params: { regency_id: regency_id }
    }).then((res: SuccessFetch<{
      payload: {
        subdistrict_id: string
        subdistrict_name: string
      }[]
    }>) => {
      setData(res.data.payload.map(item => {
        return {
          label: item.subdistrict_name,
          value: item.subdistrict_id
        }
      }))
    }).catch(() => {
      setData([])
    }).finally(() => {
      setLoading(false)
    })
  }

  return { data, isLoading, refetch }
}