import { useEffect, useState } from "react"
import type { SelectOptionType, SuccessFetch } from "types"
import { useApi } from "utils"

export function useAccount() {
  const api = useApi()
  const [dataAccount, setData] = useState<SelectOptionType[]>([])
  const [isLoadingAccount, setLoading] = useState(true)

  useEffect(() => {
    api.get("/coa/")
    .then((res: SuccessFetch<{
      payload: {
        coa_id: string
        coa_name: string
        group_coa_code: string
        coa_code: string 
      }[]
    }>) => { 
      const sortedData = res.data.payload.slice().sort((a, b) => a.coa_name.localeCompare(b.coa_name));
      setData(sortedData.map(item => { return { label: `${item?.group_coa_code}-${item?.coa_code} ${item.coa_name}`, value: item.coa_id }}))
    })
    .catch(() => setData([]))
    .finally(() => setLoading(false))
  
    return () => {
      setData([])
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [])

  return { dataAccount, isLoadingAccount }
}