/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react"
import { Employee, SuccessFetch } from "types"
import { useApi } from "utils"

export type Response = {
  payload: Employee[]
}

export function useDropdownEmployee() {
  const api = useApi()
  const [data, setData] = useState<Response["payload"]>([])
  const [isLoading, setLoading] = useState(false)

  const fetchData = () => {
    setLoading(true)

    api
      .get("/employee/")
      .then((res: SuccessFetch<Response>) => {
        const data = res.data.payload

        setData(data)
      })
      .catch(() => setData([]))
      .finally(() => setLoading(false))
  }

  const refetch = () => fetchData()

  useEffect(() => {
    fetchData()

    return () => {
      setData([])
      setLoading(false)
    }
  }, [])

  return {
    data,
    isLoading,
    dropdown: data?.map((item) => ({
      ...item,
      label: item.employee_name,
      value: item.employee_id.toString()
    })),
    refetch
  }
}
