import { PropsWithChildren, createContext, useEffect, useState } from 'react'

export const Render = createContext<{
  render: boolean
  toggle: () => void
}>({
  render: true,
  toggle() {}
})

export function RenderProvider(props: PropsWithChildren<{}>) {
  const [render, setRender] = useState(true)

  useEffect(() => {
    if (!render) {
      setRender(true)
    }
  }, [render])

  const toggle = () => {
    setRender(false)
  }

  return (
    <Render.Provider value={{ render, toggle }}>
      {props.children}
    </Render.Provider>
  )
}