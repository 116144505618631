// React
import { useContext } from "react"

// Components
import { Select } from "components"

// Contexts
import { DataJournalContext } from "pages/Finance/Transaction/contexts"

// Form
import { Controller, useFormContext } from "react-hook-form"

// Types
import type { DataJournalType } from "../utils"

// Utils
import { account_class } from "../utils"

export function Project() {
  // Hooks
  const { project: { data, isReloading } } = useContext(DataJournalContext)

  // Form
  const { control, setValue } = useFormContext<DataJournalType>()

  return (
    <Controller
      control={control}
      name="project_id"
      render={({ field, fieldState }) => (
        <section {...account_class}>
          <Select
            placeholder="Select Job Id"
            error={fieldState.error?.message}
            isLoading={isReloading}
            options={data}
            ref={field.ref}
            value={data.find(item => item.value === field.value) ?? null}
            // @ts-ignore
            onChange={item => {
              field.onChange(item?.value)
              setValue("project_name", item?.label!)
            }}
          />
        </section>
      )}
    />
  )
}