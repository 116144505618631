// React
import { useState } from "react"

// Types
import type { SuccessFetch } from "types"

// Utils
import { useApi } from "utils"

interface DataType {
  mr_unit_id: number
  mr_unit_name: string
  product_barcode: string
  product_foto: string
  product_id: number
  product_name: string
  different: number
  real_count: number
  soh_count: number
}

export function useLocationProduct(): {
  data: DataType[]
  isLoading: boolean
  refetch: (
    location_id: string,
    transaction_date: string
  ) => void
} {
  // Hooks
  const api = useApi()
  const [data, setData] = useState<DataType[]>([])
  const [isLoading, setLoading] = useState<boolean>(false)

  // Functions
  const refetch = (
    location_id: string,
    transaction_date: string
  ) => {
    setLoading(true)

    api.get("/invcount/product_by_location", {
      params: {
        location_id,
        transaction_date
      }
    })
    .then((res: SuccessFetch<{
      payload: {
        product_barcode: string
        product_foto: string
        product_id: number
        product_name: string
        sell: boolean
        soh: string
        unit_convention_id: number
        unit_convention_name: string
        unit_sell_measure_id: number
        unit_sell_measure_name: string
      }[]
    }>) => {
      setData(res.data.payload.map(item => {
        // Vars
        const sell = item.sell

        return {
          real_count: 0,
          different: parseInt(item.soh),
          mr_unit_id: sell ? item.unit_sell_measure_id : item.unit_convention_id,
          mr_unit_name: sell ? item.unit_sell_measure_name : item.unit_convention_name,
          product_barcode: item.product_barcode,
          product_foto: item.product_foto,
          product_id: item.product_id,
          product_name: item.product_name,
          soh_count: parseInt(item.soh)
        }
      }))
    }).catch(() => {
      setData([])
    }).finally(() => {
      setLoading(false)
    })
  }

  return { data, isLoading, refetch }
}