import { ActionButton, BaseTable, Button, Loading } from "components"
import moment from "moment"
import { APPROVAL } from "pages/Sales/Register/utils/vars"
import { IoSendSharp } from "react-icons/io5"
import { useNavigate, useParams } from "react-router-dom"
import { convertNumber, useToggle } from "utils"
import { useReturnItemsListBySo } from "../../../utils/hooks"
import { useState } from "react"
import { ReturnItemsListBySO } from "../../../types"
import { ModalDelete } from "../../ModalDelete"

type TableReturnItemsProps = {
  approvalView?: boolean
  display?: boolean
}

export const TableReturnItems = ({
  approvalView,
  display = true
}: TableReturnItemsProps) => {
  const navigate = useNavigate()
  const modalDelete = useToggle(false)
  const params = useParams()
  const returnItems = useReturnItemsListBySo({
    params: {
      sales_order_id: params.sales_order_id ?? ""
    }
  })
  const [deleteData, setDeleteData] = useState<ReturnItemsListBySO>(
    {} as ReturnItemsListBySO
  )

  return (
    <div className={`${display ? "" : "hidden"}`}>
      <BaseTable>
        <thead className="uppercase">
          <tr>
            <th>NO</th>
            <th colSpan={2}>Return ID</th>
            <th>Date</th>
            <th>Status</th>
            <th>Delivery</th>
            <th>Memo</th>
            <th>Subtotal</th>
            <th>Action</th>
          </tr>
        </thead>

        <tbody>
          {returnItems.data?.length && !returnItems.isLoading ? (
            returnItems.data.map((item, key) => {
              return (
                <tr key={item.invoice_id}>
                  <td className="text-center">{key + 1}</td>
                  <td className="text-center px-0">
                    <Button
                      size="xs"
                      color="ghost"
                      onClick={() => {
                        navigate(
                          `/sales/transaction/return-items/edit/${item.return_id}`,
                          {
                            state: {
                              sales_order_id: params.sales_order_id
                            }
                          }
                        )
                      }}>
                      <IoSendSharp className="size-4" />
                    </Button>
                  </td>
                  <td>{item.trx_code}</td>
                  <td>{moment(item.transaction_date).format("DD/MM/YYYY")}</td>
                  <td>{APPROVAL[item.approve_status]}</td>
                  <td></td>
                  <td>{item.memo}</td>
                  <td className="text-right">
                    {convertNumber(item.total_return ?? 0).intoCurrency}
                  </td>
                  <td className={approvalView ? "hidden" : ""}>
                    <section className="flex justify-center items-center">
                      {item.approve_status !== APPROVAL.APPROVE && (
                        <ActionButton.Delete
                          onClick={() => {
                            modalDelete.setActive(true)
                            setDeleteData(item)
                          }}
                        />
                      )}
                    </section>
                  </td>
                </tr>
              )
            })
          ) : (
            <tr>
              <td colSpan={12} className="text-center">
                <Loading
                  loading={returnItems.isLoading}
                  errorText={returnItems.isLoading ? "" : "No data available"}
                />
              </td>
            </tr>
          )}
        </tbody>
      </BaseTable>

      <ModalDelete
        modalState={modalDelete}
        data={deleteData}
        onSuccess={() => {
          modalDelete.setActive(false)
          returnItems.refetch()
        }}
      />
    </div>
  )
}
