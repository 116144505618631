export const group_list = [
  {
    label: "Employee",
    value: "1"
  },
  {
    label: "Customer",
    value: "2"
  },
  {
    label: "Vendor",
    value: "3"
  }
]