import type { ProfileList } from "../../../../../types" 

interface TabProps {
  action?: string
  tabs: string[];
  activeTab?: number
  className?: string
  disabled?: boolean
  dataProfile?: ProfileList
  setActiveTab: (newState: any) => void
}

export const Tab = ({ tabs, activeTab, setActiveTab, className, disabled, dataProfile }: TabProps) => {
  const isBuyingTabDisabled = dataProfile?.buy === false ? dataProfile?.buy === false : dataProfile === undefined ? dataProfile === undefined : dataProfile === null
  const isSellingTabDisabled = dataProfile?.sell === false ? dataProfile?.sell === false : dataProfile === undefined ? dataProfile === undefined : dataProfile === null
  const isComponentTabDisabled = dataProfile?.build_item === false ? dataProfile?.build_item === false : dataProfile === undefined ? dataProfile === undefined : dataProfile === null

  return (
    <div className="flex justify-between overflow-auto">
      {tabs.map((tab, index) => (
        <button
          key={index}
          className={`${index === activeTab ? "bg-primary dark:text-black" : "bg-[#3c3c3c]"} px-4 py-2 w-full mr-0.5 text-white dark:text-white`}
          onClick={() => setActiveTab(index)}
          disabled={disabled 
            ? disabled 
            : index === 1
              ? isBuyingTabDisabled
              : index === 2 
                ? isComponentTabDisabled
                : index === 3 && isSellingTabDisabled
          }
        >
          <div className={className}><b>{tab}</b></div>
        </button>
      ))}
    </div>
  );
};
