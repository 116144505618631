// Types
import type { SuccessFetch } from "types"

// Utils
import { useApi } from "utils"

export function useCode(url: string) {
  // Hooks
  const api = useApi()

  const refetch = (vartgl: string) => {
    return new Promise<string>((resolve) => {
      // "/spendmoney/getnewtrxcode"
      api.post(url, {
        vartgl
      }).then((res: SuccessFetch<{
        payload: { nextcode: string }
      }>) => {
        resolve(res.data.payload.nextcode)
      }).catch(() => {
        resolve("-")
      })
    })
  }

  return { refetch }
}