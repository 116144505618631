// React
import { PropsWithChildren, createContext } from "react"

// Utils
import { useToggle } from "utils"

export const FilterContext = createContext<{
  account_number: ReturnType<typeof useToggle>
  sub_header: ReturnType<typeof useToggle>
  zero_value: ReturnType<typeof useToggle>
}>({
  account_number: {
    isActive: true,
    setActive: () => {},
    toggle: () => {}
  },
  sub_header: {
    isActive: true,
    setActive: () => {},
    toggle: () => {}
  },
  zero_value: {
    isActive: true,
    setActive: () => {},
    toggle: () => {}
  }
})

export function FilterProvider(params: PropsWithChildren<{}>) {
  // Hooks
  const account_number = useToggle(true)
  const sub_header = useToggle(true)
  const zero_value = useToggle(true)

  return (
    <FilterContext.Provider value={{ account_number, sub_header, zero_value }}>
      {params.children}
    </FilterContext.Provider>
  )
}