// Pages
import Find from "pages/FindSection"
import Register from "pages/Sales/Register"
import OpeningBalance from "pages/Accounts/Transaction/OpeningBalance"
import Journal from "pages/Finance/Journal"
import GeneralEntry from "pages/Finance/Transaction/GeneralEntry"
import Dashboard from "pages/Sales/Dashboard"
import CustomerOrder from "pages/Sales/Transaction/CustomerOrder"
import CustomerOrderEdit from "pages/Sales/Transaction/CustomerOrder/Edit"
import Invoice from "pages/Sales/Transaction/Invoice"
import InvoiceEdit from "pages/Sales/Transaction/Invoice/Edit"
import ApprovalSO from "pages/Sales/Transaction/ApprovalSO"
import SalesOrderRegisterInvoice from "pages/Sales/Transaction/SalesOrderRegisterInvoice"
import ApproveInvoice from "pages/Sales/Transaction/ApproveInvoice"
import PaymentDeposit from "pages/Sales/Transaction/PaymentDeposit"
import ApprovalDepoCustomer from "pages/Sales/Transaction/ApprovalDepoCustomer"
import PaymentInvoice from "pages/Sales/Transaction/PaymentInvoice"
import PaymentDepositEdit from "pages/Sales/Transaction/PaymentDeposit/Edit"
import ReturnItems from "pages/Sales/Transaction/ReturnItems"
import ApproveReturnItems from "pages/Sales/Transaction/ApproveReturnItems"
import ReturnItemsEdit from "pages/Sales/Transaction/ReturnItems/Edit"
import PaymentInvoiceEdit from "pages/Sales/Transaction/PaymentInvoice/Edit"

// Types
import type { RouteType } from "types"
import ApprovePaymentInvoice from "pages/Sales/Transaction/ApprovePaymentInvoice"

export const salesRoutes: RouteType[] = [
  {
    path: "/sales/register",
    element: <Register />,
    permission: "SR"
  },
  {
    path: "/sales/transaction/customer-order",
    element: <CustomerOrder />,
    permission: "ST02"
  },
  {
    path: "/sales/transaction/customer-order/edit/:sales_order_id",
    element: <CustomerOrderEdit />,
    permission: "ST023"
  },
  {
    path: "/sales/transaction/dashboard",
    element: <Dashboard />,
    permission: "ST08"
  },
  {
    path: "/sales/transaction/opening-balance",
    element: <OpeningBalance />,
    permission: "AT01"
  },
  {
    path: "/sales/journal",
    element: <Journal />,
    // permission: "SJ"
    permission: "ST" // BELUM
  },
  {
    path: "/sales/find",
    element: <Find />,
    // permission: "SF"
    permission: "ST" // BELUM
  },
  {
    path: "/sales/transaction/general-entry",
    element: <GeneralEntry />,
    permission: "AT02"
  },
  {
    path: "/sales/transaction/sales-order-register",
    element: <SalesOrderRegisterInvoice />,
    permission: "ST09"
  },
  {
    path: "/sales/transaction/approve-so",
    element: <ApprovalSO />,
    permission: "ST10"
  },
  {
    path: "/sales/transaction/approve-invoice",
    element: <ApproveInvoice />,
    permission: "ST11"
  },
  {
    path: "/sales/transaction/invoice/:sales_order_id",
    element: <Invoice />,
    permission: "ST12"
  },
  {
    path: "/sales/transaction/invoice/edit/:invoice_id",
    element: <InvoiceEdit />,
    permission: "ST12"
  },
  {
    path: "/sales/transaction/approve-depo-customer",
    element: <ApprovalDepoCustomer />,
    permission: "ST12"
  },
  {
    path: "/sales/transaction/payment-invoice/:invoice_id",
    element: <PaymentInvoice />,
    permission: "ST14"
  },
  {
    path: "/sales/transaction/payment-invoice/edit/:payment_invoice_id",
    element: <PaymentInvoiceEdit />,
    permission: "ST14"
  },
  {
    path: "/sales/transaction/approve-payment-invoice",
    element: <ApprovePaymentInvoice />,
    permission: "ST15"
  },
  {
    path: "/sales/transaction/payment-deposit/:sales_order_id",
    element: <PaymentDeposit />,
    permission: "ST16"
  },
  {
    path: "/sales/transaction/payment-deposit/edit/:deposit_customer_id",
    element: <PaymentDepositEdit />,
    permission: "ST16"
  },
  {
    path: "/sales/transaction/return-items/:invoice_id",
    element: <ReturnItems />,
    permission: "ST17"
  },
  {
    path: "/sales/transaction/return-items/edit/:return_id",
    element: <ReturnItemsEdit />,
    permission: "ST17"
  },
  {
    path: "/sales/transaction/approve-return-items",
    element: <ApproveReturnItems />,
    permission: "ST18"
  },
  {
    path: "/sales/transaction/sales-register",
    element: <div />,
    permission: "ST" // BELUM
  },
  {
    path: "/sales/transaction/customer-quotation",
    element: <div />,
    permission: "ST" // BELUM
  },
  {
    path: "/sales/transaction/record-sales",
    element: <div />,
    permission: "ST" // BELUM
  },
  {
    path: "/sales/transaction/return-sales",
    element: <div />,
    permission: "ST" // BELUM
  },
  {
    path: "/sales/transaction/receive-payment",
    element: <div />,
    permission: "ST" // BELUM
  },
  {
    path: "/sales/transaction/transaction-journal",
    element: <div />,
    permission: "ST" // BELUM
  },
  {
    path: "/sales/report/aging-summary",
    element: <div />,
    permission: "ST" // BELUM
  },
  {
    path: "/sales/report/ageng-detail",
    element: <div />,
    permission: "ST" // BELUM
  },
  {
    path: "/sales/report/sales-summary",
    element: <div />,
    permission: "ST" // BELUM
  },
  {
    path: "/sales/report/analyse-sales-periode",
    element: <div />,
    permission: "ST" // BELUM
  },
  {
    path: "/sales/report/analyse-sales-comparation",
    element: <div />,
    permission: "ST" // BELUM
  },
  {
    path: "/sales/report/analyse-sales-monthly",
    element: <div />,
    permission: "ST" // BELUM
  },
  {
    path: "/sales/report/customer-sales-detail",
    element: <div />,
    permission: "ST" // BELUM
  },
  {
    path: "/sales/report/salesperson-sales-summary",
    element: <div />,
    permission: "ST" // BELUM
  },
  {
    path: "/sales/report/salesperson-sales-detail",
    element: <div />,
    permission: "ST" // BELUM
  },
  {
    path: "/sales/report/all-sales",
    element: <div />,
    permission: "ST" // BELUM
  },
  {
    path: "/sales/report/payment-history",
    element: <div />,
    permission: "ST" // BELUM
  },
  {
    path: "/sales/report/sales-receivable-journal",
    element: <div />,
    permission: "ST" // BELUM
  },
  {
    path: "/sales/report/dashboard",
    element: <div />,
    permission: "ST" // BELUM
  }
]
