/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react"
import { SuccessFetch } from "types"
import { useApi } from "utils"
import { ProductType } from "../../types"

export type Response = {
    payload: ProductType[]
  }
  
export function useProductDropdown() {
    const api = useApi()
    const [data, setData] = useState<Response["payload"]>([])
    const [isLoading, setLoading] = useState(false)

    const fetchData = () => {
        setLoading(true)

        api
            .get("/opening-stock/product")
            .then((res: SuccessFetch<Response>) => {
                const data = res.data.payload
                setData(data)
            })
            .catch(() => setData([]))
            .finally(() => setLoading(false))
    }

    const refetch = () => fetchData()

    useEffect(() => {
        fetchData()

        return () => {
            setData([])
            setLoading(false)
        }
    }, [])

    return {
        data,
        isLoading,
        dropdownProduct: data?.map((item) => ({
            ...item,
            label: `${item.product_barcode} - ${item.product_name}`,
            value: item.prouct_id.toString()
        })).sort((a: any, b: any) => a.product_barcode.localeCompare(b.product_barcode)),
        refetch
    }
}