import { Button, Checkbox, Modal } from "components";
import { Controller, useForm } from "react-hook-form";
import { FaSliders } from "react-icons/fa6";
import { useApi, useToggle } from "utils";
import { FilterType } from "../../types";
import { useState } from "react";
import toast from "react-hot-toast";
import { ColumnConfigType } from "types";
import { defaultValue } from "../../utils/function";


export default function FilterTable(props: {
    refetch: () => void
    dataList: ColumnConfigType[]
}) {

    const { isActive, toggle } = useToggle(false)

    return (
        <>
            <button>
                <FaSliders className="w-6 h-6 text-warning" onClick={toggle} />
                {isActive && <ModalFillter toggle={toggle} refetch={props.refetch} dataList={props.dataList} />}
            </button>
        </>
    )
}

function ModalFillter(props: {
    toggle: () => void
    refetch: () => void
    dataList: ColumnConfigType[]
}) {
    const [loading, isLoading] = useState(false)
    const api = useApi()
    const dataFillter = defaultValue(props.dataList)

    const defaultValues = {
        handphone: dataFillter.handphone,
        email: dataFillter.email,
        cardLabel: dataFillter.cardLabel,
        category: dataFillter.category,
        priceLevel: dataFillter.priceLevel,
        bank: dataFillter.bank,
        bankAccount: dataFillter.bankAccount,
        country: dataFillter.country,
        province: dataFillter.province,
        regency: dataFillter.regency,
        subdistrict: dataFillter.subdistrict,
        village: dataFillter.village,
        address: dataFillter.address
    }

    const { control, setValue, getValues } = useForm<FilterType>({
        defaultValues
    })
    const hasTrueValue = Object.values(getValues()).some(value => value === true)

    const onFilter = () => {
        isLoading(true);
        setTimeout(() => {
            props.refetch && props.refetch();
            props.toggle();
        }, 1000);
    };

    const handleCheckboxChange = (value: ColumnConfigType) => {
        return new Promise<void>((resolve) => {
            toast.promise(
                api.post("/columnconfig/update", {
                    module: value.module,
                    column_code: value.column_code,
                    column_name: value.column_name,
                    column_status: value.column_status
                }),
                {
                    loading: "Loading...",
                    success: (res) => res.data.message,
                    error: (err) => {
                        if (err.response && err.response.status === 422) {
                            return err.response.data.message || "Bad Request";
                        } else {
                            return "An error occurred";
                        }
                    }
                }
            )
                .then(() => {

                })
                .catch(() => { })
                .finally(resolve)
        })
    };


    return (
        <Modal isOpen title="FILTER TABLE" closeModal={props.toggle}>
            <Modal.Body>
                <div role="tablist" className="tabs tabs-lifted">
                    <input type="radio" name="my_tabs_2" role="tab" className="tab" aria-label="Profile" checked />
                    <div role="tabpanel" className="tab-content bg-base-100 border-base-300 rounded-box p-6">
                        <div className="grid grid-cols-2">
                            <div>
                                <Controller
                                    control={control}
                                    name="handphone"
                                    render={({ field }) => (
                                        <Checkbox
                                            {...field}
                                            className="mb-2"
                                            ref={null}
                                            onChange={(e) => {
                                                setValue("handphone", e.target.checked);
                                                handleCheckboxChange({ module: 'vendor', column_code: 'vhp', column_name: 'handphone', column_status: e.target.checked });
                                            }}
                                        >
                                            Hp
                                        </Checkbox>
                                    )}
                                />

                                <Controller
                                    control={control}
                                    name="email"
                                    render={({ field }) => (
                                        <Checkbox
                                            {...field}
                                            className="mb-2"
                                            ref={null}
                                            onChange={(e) => {
                                                setValue("email", e.target.checked)
                                                handleCheckboxChange({ module: 'vendor', column_code: 'vemail', column_name: 'email', column_status: e.target.checked });
                                            }}
                                        >
                                            E-Mail
                                        </Checkbox>
                                    )}
                                />

                                <Controller
                                    control={control}
                                    name="cardLabel"
                                    render={({ field }) => (
                                        <Checkbox
                                            {...field}
                                            className="mb-2"
                                            ref={null}
                                            onChange={(e) => {
                                                setValue("cardLabel", e.target.checked)
                                                handleCheckboxChange({ module: 'vendor', column_code: 'vcardlab', column_name: 'card label', column_status: e.target.checked });
                                            }}
                                        >
                                            Card Label
                                        </Checkbox>
                                    )}
                                />

                                <Controller
                                    control={control}
                                    name="category"
                                    render={({ field }) => (
                                        <Checkbox
                                            {...field}
                                            className="mb-2"
                                            ref={null}
                                            onChange={(e) => {
                                                setValue("category", e.target.checked)
                                                handleCheckboxChange({ module: 'vendor', column_code: 'vcat', column_name: 'category', column_status: e.target.checked });
                                            }}
                                        >
                                            Category
                                        </Checkbox>
                                    )}
                                />
                            </div>
                            <div>

                                <Controller
                                    control={control}
                                    name="priceLevel"
                                    render={({ field }) => (
                                        <Checkbox
                                            {...field}
                                            className="mb-2"
                                            ref={null}
                                            onChange={(e) => {
                                                setValue("priceLevel", e.target.checked)
                                                handleCheckboxChange({ module: 'vendor', column_code: 'vpricelev', column_name: 'price level', column_status: e.target.checked });
                                            }}
                                        >
                                            Price Level
                                        </Checkbox>
                                    )}
                                />

                                <Controller
                                    control={control}
                                    name="bank"
                                    render={({ field }) => (
                                        <Checkbox
                                            {...field}
                                            className="mb-2"
                                            ref={null}
                                            onChange={(e) => {
                                                setValue("bank", e.target.checked)
                                                handleCheckboxChange({ module: 'vendor', column_code: 'vbank', column_name: 'bank', column_status: e.target.checked });
                                            }}
                                        >
                                            Bank
                                        </Checkbox>
                                    )}
                                />

                                <Controller
                                    control={control}
                                    name="bankAccount"
                                    render={({ field }) => (
                                        <Checkbox
                                            {...field}
                                            className="mb-2"
                                            ref={null}
                                            onChange={(e) => {
                                                setValue("bankAccount", e.target.checked)
                                                handleCheckboxChange({ module: 'vendor', column_code: 'vbankac', column_name: 'bank account', column_status: e.target.checked });
                                            }}
                                        >
                                            Bank Account
                                        </Checkbox>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    <input type="radio" name="my_tabs_2" role="tab" className="tab" aria-label="Adreess" />
                    <div role="tabpanel" className="tab-content bg-base-100 border-base-300 rounded-box p-6">
                        <div className="grid grid-cols-2">
                            <div>

                                <Controller
                                    control={control}
                                    name="country"
                                    render={({ field }) => (
                                        <Checkbox
                                            {...field}
                                            className="mb-2"
                                            ref={null}
                                            onChange={(e) => {
                                                setValue("country", e.target.checked)
                                                handleCheckboxChange({ module: 'vendor', column_code: 'vcount', column_name: 'country', column_status: e.target.checked });
                                            }}
                                        >
                                            Country
                                        </Checkbox>
                                    )}
                                />

                                <Controller
                                    control={control}
                                    name="province"
                                    render={({ field }) => (
                                        <Checkbox
                                            {...field}
                                            className="mb-2"
                                            ref={null}
                                            onChange={(e) => {
                                                setValue("province", e.target.checked)
                                                handleCheckboxChange({ module: 'vendor', column_code: 'vprov', column_name: 'province', column_status: e.target.checked });
                                            }}
                                        >
                                            Province
                                        </Checkbox>
                                    )}
                                />

                                <Controller
                                    control={control}
                                    name="regency"
                                    render={({ field }) => (
                                        <Checkbox
                                            {...field}
                                            className="mb-2"
                                            ref={null}
                                            onChange={(e) => {
                                                setValue("regency", e.target.checked)
                                                handleCheckboxChange({ module: 'vendor', column_code: 'vreg', column_name: 'regency', column_status: e.target.checked });
                                            }}
                                        >
                                            Regency
                                        </Checkbox>
                                    )}
                                />

                            </div>
                            <div>

                                <Controller
                                    control={control}
                                    name="subdistrict"
                                    render={({ field }) => (
                                        <Checkbox
                                            {...field}
                                            className="mb-2"
                                            ref={null}
                                            onChange={(e) => {
                                                setValue("subdistrict", e.target.checked)
                                                handleCheckboxChange({ module: 'vendor', column_code: 'vsubdis', column_name: 'subdistrict', column_status: e.target.checked });
                                            }}
                                        >
                                            Subdistric
                                        </Checkbox>
                                    )}
                                />

                                <Controller
                                    control={control}
                                    name="village"
                                    render={({ field }) => (
                                        <Checkbox
                                            {...field}
                                            className="mb-2"
                                            ref={null}
                                            onChange={(e) => {
                                                setValue("village", e.target.checked)
                                                handleCheckboxChange({ module: 'vendor', column_code: 'vvil', column_name: 'village', column_status: e.target.checked });
                                            }}
                                        >
                                            Village
                                        </Checkbox>
                                    )}
                                />

                                <Controller
                                    control={control}
                                    name="address"
                                    render={({ field }) => (
                                        <Checkbox
                                            {...field}
                                            className="mb-2"
                                            ref={null}
                                            onChange={(e) => {
                                                setValue("address", e.target.checked)
                                                handleCheckboxChange({ module: 'vendor', column_code: 'vadress', column_name: 'adress', column_status: e.target.checked });
                                            }}
                                        >
                                            Address
                                        </Checkbox>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button type="button" color="warning" onClick={() => onFilter()} loading={loading ? 'true' : undefined}>{`FILTER ${hasTrueValue ? 'ON' : 'OFF'}`}</Button>
            </Modal.Footer>
        </Modal>
    )
}