/* eslint-disable react-hooks/exhaustive-deps */
import { BaseTable } from "components"
import moment from "moment"
import { useEffect, useState } from "react"
import { useFormContext, useWatch } from "react-hook-form"
import { convertNumber, useAccounts, useCoaByCode } from "utils"
import { JournalPaymentInvoice, PaymentInvoiceType } from "../../../types"
import { generateJournalPaymentInvoice } from "../../../utils/functions"

type TableJournalEntryProps = {
  approvalStatus?: number
  display?: boolean
  displayCreateButton?: boolean
}

export const TableJournalEntry = ({
  display = true
}: TableJournalEntryProps) => {
  const methods = useFormContext<PaymentInvoiceType>()
  const accounts = useAccounts()
  const [data, setData] = useState<JournalPaymentInvoice[]>([])
  const la06 = useCoaByCode("LA06")
  const la04 = useCoaByCode("LA04")
  const [
    payment_amount,
    deposit_amount,
    balance,
    date,
    trx_code,
    memo,
    coa_id
  ] = useWatch({
    control: methods.control,
    name: [
      "payment_amount",
      "deposit_amount",
      "coa_balance",
      "transaction_date",
      "trx_code",
      "memo",
      "coa_id"
    ]
  })

  const totalJournal = data.reduce(
    (total, item) => {
      return {
        debet: total.debet + item.debet,
        credit: total.credit + item.credit
      }
    },
    { debet: 0, credit: 0 }
  )

  useEffect(() => {
    if (coa_id) {
      const coa_payment =
        accounts.data.find((item) => item.value === coa_id.toString())?.label ??
        ""

      const journal = generateJournalPaymentInvoice({
        coa_la06: la06.data,
        coa_la04: la04.data,
        coa_payment,
        payment_amount: payment_amount,
        deposit_amount: deposit_amount,
        date,
        trx_code: trx_code ?? "",
        memo
      })

      setData(journal ?? [])
    }
  }, [payment_amount, balance, date, trx_code, memo])

  return (
    <div className={`${display ? "" : "hidden"}`}>
      <div className="font-bold uppercase text-lg mb-2">Journal Entry</div>

      <BaseTable>
        <thead className="uppercase">
          <tr>
            <th>NO</th>
            <th>Date</th>
            <th>Id#</th>
            <th>Account</th>
            <th>Memo</th>
            <th>Debet</th>
            <th>Credit</th>
          </tr>
        </thead>

        <tbody>
          {data.map((item, key) => {
            return (
              <tr>
                <td className="text-center">{key + 1}</td>
                <td>{moment(item.date).format("DD/MM/YYYY")}</td>
                <td>{item.idm}</td>
                <td>{item.coa}</td>
                <td>{item.memo}</td>
                <td className="text-right">
                  {item.debet > 0 ? convertNumber(item.debet).intoCurrency : ""}
                </td>
                <td className="text-right text-red-500">
                  {item.credit > 0
                    ? convertNumber(item.credit).intoCurrency
                    : ""}
                </td>
              </tr>
            )
          })}
          {data?.length ? (
            <tr>
              <td colSpan={5} />
              <td className="text-right">
                {convertNumber(totalJournal.debet).intoCurrency}
              </td>
              <td className="text-right">
                {convertNumber(totalJournal.credit).intoCurrency}
              </td>
            </tr>
          ) : null}
        </tbody>
      </BaseTable>
    </div>
  )
}
