import { ColumnConfigType } from "types";

export const defaultValue = (dataList: ColumnConfigType[]) => {
    const handphone = dataList?.find(item => item.column_code === 'chp')?.column_status;
    const email = dataList.find(item => item.column_code === 'cemail')?.column_status;
    const cardLabel = dataList.find(item => item.column_code === 'ccardlab')?.column_status;
    const category = dataList.find(item => item.column_code === 'ccat')?.column_status;
    const priceLevel = dataList.find(item => item.column_code === 'cpricelev')?.column_status;
    const bank = dataList.find(item => item.column_code === 'cbank')?.column_status;
    const bankAccount = dataList.find(item => item.column_code === 'cbankac')?.column_status;
    const country = dataList.find(item => item.column_code === 'ccount')?.column_status;
    const province = dataList.find(item => item.column_code === 'cprov')?.column_status;
    const regency = dataList.find(item => item.column_code === 'creg')?.column_status;
    const subdistrict = dataList.find(item => item.column_code === 'csubdis')?.column_status;
    const village = dataList.find(item => item.column_code === 'cvil')?.column_status;
    const address = dataList.find(item => item.column_code === 'cadress')?.column_status;

    return {
        handphone,
        email,
        cardLabel,
        category,
        priceLevel,
        bank,
        bankAccount,
        country,
        province,
        regency,
        subdistrict,
        village,
        address
    };
};
