// EXCEPTIONS
// #1: ignore type error to check field name is available on the form type

// React
import { Fragment } from "react"

// Components
import { ActionButton, Button, Modal } from "../../../../../components"
import { FormSection } from "./FormSection"

// Form
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

// Third-Party Libraries
import toast from "react-hot-toast"

// Types
import type { ErrorFetch } from "../../../../../types"
import type { FormType, TaxCodeType } from "../types"

// Utils
import { useApi, useToggle } from "../../../../../utils"
import { validationSchema } from "../utils"

export function Update(props: {
	item: TaxCodeType
	onSuccess: () => void
}) {
	// Hooks
	const { isActive, toggle } = useToggle(false)

	return (
		<Fragment>
			<ActionButton.Update onClick={toggle} />
			{isActive && <ModalSection item={props.item} toggle={toggle} onSuccess={props.onSuccess} />}
		</Fragment>
	)
}

function ModalSection(props: {
	item: TaxCodeType
	onSuccess: () => void
	toggle: () => void
}) {
	// Hooks
	const api = useApi()

	// Form
	const defaultValues = {
		tax_code: props.item.tax_code,
		tax_name: props.item.tax_name,
		type_tax_id: props.item.type_tax_id.toString(),
		rate: props.item.rate,
		coa_collect_id: props.item.coa_collect_id.toString(),
		coa_paid_id: props.item.coa_paid_id.toString(),
		note: props.item.note
	}
	const onSubmit = (value: FormType) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.put(`/taxcode/edit?tax_id=${props.item.tax_id}`, value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.detail.message
        }
      ).then(() => {
        props.toggle()
        props.onSuccess()
      }).catch((err: ErrorFetch<{
				detail: {
					loc: string[]
					type: string
				}[]
			}>) => {
				// @ts-ignore
				if (err.response.status === 422) {
					err.response.data.detail.map(item => {
						// @ts-ignore [EXCEPTION #1]
						return methods.setError(item, {
							message: item.loc[1] ?? "",
							type: "custom"
						})
					})
				}
			}).finally(resolve)
    })
  }
	const methods = useForm<FormType>({
		defaultValues,
		resolver: yupResolver(validationSchema)
	})

	return (
		<Modal isOpen title="UPDATE TAX CODE" closeModal={props.toggle}>
			<form onSubmit={methods.handleSubmit(onSubmit)}>
				<FormProvider {...methods}>
					<FormSection />
				</FormProvider>

				<Modal.Footer className="text-end">
					<Button loading={methods.formState.isSubmitting ? "true" : undefined} color="primary">SAVE</Button>
				</Modal.Footer>
			</form>
		</Modal>
	)
}