/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react"
import { SuccessFetch } from "types"
import { useApi } from "utils"
import { DetailTransfer } from "../../types"

type UseDetailTransferItem = {
  transfer_id: string
  onSuccess?: (data: DetailTransfer) => void
}

type Response = {
  payload: DetailTransfer
}

export function useDetailTransferItem({
  transfer_id,
  onSuccess
}: UseDetailTransferItem) {
  const api = useApi()
  const [data, setData] = useState<DetailTransfer | null>(null)
  const [isLoading, setLoading] = useState(false)

  const fetchData = () => {
    setLoading(true)

    const params = {
      transfer_id
    }

    api
      .get("/transfer/detailtransfer", { params })
      .then((res: SuccessFetch<Response>) => {
        const data = res.data.payload
        onSuccess?.(data)
        setData(data)
      })
      .catch(() => setData(null))
      .finally(() => setLoading(false))
  }

  const refetch = () => fetchData()

  useEffect(() => {
    if (transfer_id) {
      fetchData()
    }

    return () => {
      setData(null)
      setLoading(false)
    }
  }, [transfer_id])

  return { data, isLoading, refetch }
}
