// Components
import { Checkbox } from "components"

// Form
import { Controller, useFormContext } from "react-hook-form"

export function TaxInclusive(params: { name: string }) {
  // Form
  const { control } = useFormContext()

  return (
    <section className="lg:justify-self-end mb-3">
      <Controller
        control={control}
        name={params.name}
        render={({ field }) => (
          <Checkbox
            readOnly
            value={field.value}
          >
            TAX-INCLUSIVE
          </Checkbox>
        )}
      />
    </section>
  )
}