import { BaseTable, Loading, PermissionLink } from "components"
import { Fragment } from "react"
import { useList } from "./hooks"
import { IoSendSharp } from "react-icons/io5"
import { convertDate } from "pages/Inventori/Transaction/TransactionJournal/utils"
import { convertNumber } from "utils"

export function TabPurchases(props: { disable?: boolean }) {
  const productId = localStorage?.getItem("prdId")
  const { data, isLoading } = useList(Number(productId))

  return (
    <Fragment>
      <section className="mt-2">
        <div className="overflow-x-auto">
          <BaseTable className="border-transparent">
            <thead>
              <tr className="uppercase">
                  <th>No</th>
                  <th colSpan={props?.disable ? 1 : 2}>PO ID</th>
                  <th>Date</th>
                  <th>Vendor</th>
                  <th>Qty</th>
                  <th>Unit</th>
                  <th>Price</th>
                  <th>Disc</th>
                  <th>Ext</th>
              </tr>
            </thead>
            <tbody>
              {!data.length || isLoading 
                  ? <tr><td colSpan={15} className="text-center"><Loading loading={isLoading} errorText={isLoading ? "" : "No data available"} /></td></tr>
                  : data.map((val, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      {!props?.disable && <td>
                        <PermissionLink permission="IR013" to={`/purchase/transaction/purchase-order/${val.purchase_order_id}`}>
                          <IoSendSharp />
                        </PermissionLink>
                      </td>}
                      <td>{val?.trx_code ?? ""}</td>
                      <td>{val?.transaction_date ? convertDate(val?.transaction_date) : "-"}</td>
                      <td>{val?.vendor_name ?? "-"}</td>
                      <td className="text-center">{val?.qty_order ? convertNumber(val?.qty_order)?.intoNormalAmount : "-"}</td>
                      <td>{val?.mr_unit_name ?? "-"}</td>
                      <td className="text-right">{val?.price_measure ? convertNumber(val?.price_measure)?.intoCurrency : "-"}</td>
                      <td className="text-center">{val?.discount ? `${convertNumber(val?.discount)?.intoNormalAmount}%` : "-"}</td>
                      <td className="text-right">{val?.total ? convertNumber(val?.total)?.intoCurrency : "-"}</td>
                    </tr>
                  ))
              }
            </tbody>
          </BaseTable>
        </div>
      </section>
    </Fragment>
  )
}
