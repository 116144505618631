export const menu = [
  {
    label: "ANALYTICS",
    link: "/analytics",
    permission: "N"
  },
  {
    label: "ACCOUNTS",
    link: "/accounts",
    permission: "A"
  },
  {
    label: "FINANCE",
    link: "/finance",
    permission: "F"
  },
  {
    label: "SALES",
    link: "/sales",
    permission: "S"
  },
  {
    label: "PURCHASES",
    link: "/purchase",
    permission: "P"
  },
  {
    label: "INVENTORY",
    link: "inventory",
    permission: "I"
  },
  {
    label: "CARDS",
    link: "/cards",
    permission: "C"
  }
]