import { FilterReport } from "pages/Finance/Report/types"

export const getDateTypeLabelBS = (dateType: FilterReport["dateType"]) => {
  switch (dateType) {
    case "2":
      return "This Month"
    case "3":
      return "End of Last Month"
    case "4":
      return "End of Last Quarter"
    case "5":
      return "End of Last Financial Year"
    default:
      return "Select Date"
  }
}
