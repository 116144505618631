import { useState } from "react"
import type { SuccessFetch } from "types"
import { useApi } from "utils"
import { DataList } from "../types"
import { SelectedState, SourceDataState } from "pages/FindSection/types"

export function useList() {
  const api = useApi()
  const [data, setData] = useState<DataList[]>([])
  const [isLoading, setLoading] = useState(false)

  const filterBy: { [key: string]: string } = {
    '1': "product_id",
    '2': "customer_id",
    '3': "vendor_id",
  }
  const refetch = (params: Partial<SelectedState>) => {

    setLoading(true)
    api.get("/inventoryjournal/find", {
      params: {
        start_date: params?.date_from,
        end_date: params?.date_end,
        [filterBy[params?.search_by || ""]]: params?.search_id
      }
    })
      .then((res: SuccessFetch<{ payload: DataList[] }>) => setData(res.data.payload))
      .catch(() => setData([]))
      .finally(() => setLoading(false))

    return () => {
      setData([])
      setLoading(true)
    }
  }

  return { data, isLoading, refetch }
}

export function useSourceData() {
  const api = useApi();
  const [sourceData, setData] = useState<any>(null);
  const [isSourceDataLoading, setLoading] = useState(false);

  const refetch = async (params: Partial<SourceDataState>) => {
    setLoading(true);
    setData(null);

    try {
      const res = await api.get(`journal/find/source-data?data_source=${params?.data_source}&data_source_id=${params?.data_source_id}`);
      setData(res.data.payload);
      return res.data.payload;
    } catch (error) {
      setData(null)
      return null;
    } finally {
      setLoading(false);
    }
  };

  return { sourceData, isSourceDataLoading, refetch };
}
