/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";
import { SuccessFetch } from "types";
import { useApi } from "utils";

type UseReconciliationLastBalance = {
  params: {
    coa_id: string;
    transaction_date: string;
  };
  onSuccess?: (data: number) => void;
};

type Response = {
  payload: {
    last_statement_balance: number;
  };
};

export function useReconciliationLastBalance({
  params,
  onSuccess
}: UseReconciliationLastBalance) {
  const api = useApi();
  const [data, setData] = useState(0);
  const [isLoading, setLoading] = useState(false);

  const fetchData = () => {
    if (!params.coa_id || !params.transaction_date) return;

    setLoading(true);

    api
      .get("/reconciliation/last-statement-balance", {
        params
      })
      .then((res: SuccessFetch<Response>) => {
        const data = res.data.payload;

        onSuccess?.(data.last_statement_balance);
        setData(data.last_statement_balance);
      })
      .catch(() => setData(0))
      .finally(() => setLoading(false));
  };

  const refetch = () => fetchData();

  useEffect(() => {
    fetchData();

    return () => {
      setData(0);
      setLoading(false);
    };
  }, [params.coa_id, params.transaction_date]);

  return { data, isLoading, refetch };
}
