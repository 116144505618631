import { Fragment, useContext } from "react"
// import { ActionButton } from "../../../../../../../../components"
// import { CreateModal } from "../../../CreateModal"
import { ChildItem } from "../.."
import { Delete, Detail, IsActive, Update } from "./components"
import type { DataItem } from "../../../../types"
import { useToggle } from "../../../../../../../../utils"
import { dataContext } from "pages/Setup/Account/CoaType"
import { TableNumber } from "components"


export function Item(props: { item: any, index: number, dataList: DataItem, refetch: () => void}) {
  const { isActive } = useToggle(false)
  const { currentPage, limit, size, total } = useContext(dataContext)
  

  return (
    <Fragment>
      <tr>
      <td>{TableNumber({ dataLength: size, pageCurrent: currentPage, index: props.index, limit: limit, totalData: total })}</td>
        <td>{props.item.AccountType.account_type_code}</td>
        <td>{props.item.group_coa_name}</td>
        <td>{props.item.AccountType.account_type_name}</td>
        <td>{props.item.AccountType.description}</td>
        <td>
          <div className="flex items-center">
          <Detail id={props.item.AccountType} />
          <Update id={props.item.AccountType} refetch={props.refetch} />
          <Delete id={props.item.AccountType} refetch={props.refetch} />
          <IsActive id={props.item.AccountType} refetch={props.refetch} />
          </div>
        </td>
      </tr>
      {isActive && <ChildItem dataList={props.dataList} refetch={props.refetch} />}
    </Fragment>
  )
}

// function Add(props: { item: ListBank }) {
//   const { isActive, toggle } = useToggle(false)

//   return (
//     <Fragment>
//       <ActionButton.Add className="join-item" onClick={toggle} />

//       {isActive && (
//         <CreateModal
//           group_coa_id={props.item.group_coa_id}
//           account_type_code={props.item.account_type_code}
//           account_type_name={props.item.account_type_name}
//           description={props.item.description}
//           toggle={toggle}
//         />
//       )}
//     </Fragment>
//   )
// }