import { Label, Select } from 'components';
import { useLocationList } from 'pages/Finance/Transaction/GeneralEntry/utils/hooks';
import { Ref } from 'react';
import { SingleValue } from 'react-select';
import { SelectOptionType } from 'types';

type DropdownLocationProps = {
  ref?: Ref<unknown>;
  label?: string;
  error?: string;
  isLoading?: boolean;
  value: string | null;
  onChange?: (item: SingleValue<SelectOptionType>) => void;
};

export function DropdownLocation({
  ref,
  label,
  error,
  isLoading,
  onChange,
}: DropdownLocationProps) {
  const { dropdown } = useLocationList()

  return (
    <section className="w-full">
      {label ? <Label text={label} error={Boolean(error)} /> : null}

      <section className="flex gap-3 w-full">
        <div className="w-full md:w-5/12">
          <Select
            className="grow"
            placeholder="Select Location"
            error={error}
            isLoading={isLoading}
            defaultValue={{ value: '0', label: 'All' }}
            options={[{ value: '0', label: 'All' }].concat(dropdown)}
            ref={ref}
            onChange={(item: any) => {
              if (onChange) {
                onChange(item);
              }
            }}
          />
        </div>
      </section>
    </section>
  );
}
