import type { RouteType } from "types"

export const analyticsRoutes: RouteType[] = [
  {
    path: "/analytics/register",
    element: <div />,
    // permission: "NR"
    permission: "AR" // BELUM
  },
  {
    path: "/analytics/transaction",
    element: <div />,
    // permission: "NT"
    permission: "AR" // BELUM
  },
  {
    path: "/analytics/report",
    element: <div />,
    // permission: "NT"
    permission: "AR" // BELUM
  },
  {
    path: "/analytics/journal",
    element: <div />,
    // permission: "NJ"
    permission: "AR" // BELUM
  },
  {
    path: "/analytics/find",
    element: <div />,
    // permission: "NF"
    permission: "AR" // BELUM
  },
]