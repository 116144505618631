import { Label, Select } from "components";
import { Ref } from "react";
import { SingleValue } from "react-select";
import { SelectOptionType } from "types";
import { useReferenceList } from "../utils/hooks";

type DropdownReferenceProps = {
  ref?: Ref<unknown>;
  label?: string;
  error?: string;
  isLoading?: boolean;
  value: string | null;
  onChange?: (item: SingleValue<SelectOptionType>) => void;
};

export function DropdownReference({
  ref,
  label,
  error,
  isLoading,
  onChange
}: DropdownReferenceProps) {
  const { dropdown } = useReferenceList();

  return (
    <section className="w-full">
      {label ? <Label text={label} error={Boolean(error)} /> : null}

      <section className="flex gap-3 w-full">
        <div className="w-full md:w-5/12">
          <Select
            className="grow"
            placeholder="Select Reference"
            error={error}
            isLoading={isLoading}
            options={dropdown}
            ref={ref}
            onChange={(item: any) => {
              if (onChange) {
                onChange(item);
              }
            }}
          />
        </div>
      </section>
    </section>
  );
}
