import { Select } from "components"
import { Controller, useFormContext } from "react-hook-form"
import { useProductAvailableList } from "../utils/hooks"

type DropdownProductAvailableProps = {
  priceLevelName?: string
  products: ReturnType<typeof useProductAvailableList>
}

export const DropdownProductAvailable = ({
  products
}: DropdownProductAvailableProps) => {
  const { control, setValue } = useFormContext()

  return (
    <section>
      <Controller
        control={control}
        name="product_id"
        render={({ field, fieldState }) => (
          <Select
            isLoading={products.isLoading}
            controlClass="w-[250px]"
            placeholder="Select Item Order"
            error={fieldState.error?.message}
            ref={field.ref}
            options={products.dropdown}
            value={
              field.value
                ? products.dropdown.find(
                    (item) => item.value === field.value.toString()
                  )
                : null
            }
            onChange={(product: any) => {
              field.onChange(product?.value)

              setValue("product_name", product?.product_name ?? "")

              setValue("product_barcode", product?.product_barcode ?? "")

              setValue(
                "mr_unit_id",
                product?.mr_unit_id ?? product?.unit_sell_measure_id ?? 0
              )

              setValue(
                "mr_unit_name",
                product?.mr_unit_name ?? product?.unit_sell_measure_name ?? ""
              )
              setValue("soh", product?.soh ?? 0)
            }}
          />
        )}
      />
    </section>
  )
}
