import { ActionButton } from "components";
import { Fragment } from "react";

export function Delete(props: {
    approval_status: number,
    sales_order_id: number
}){
    return(
        <Fragment>
            {props.approval_status !== 4 && <ActionButton.Delete permission="SR024"  />}
        </Fragment>
    )
}