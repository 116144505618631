/* eslint-disable react-hooks/exhaustive-deps */
import { useFormContext, useWatch } from "react-hook-form"
import { FormTransferItemsType } from "../types"
import { useTransferItemsCode } from "../utils/hooks"

export const CodeTransferItems = ({ isEdit }: { isEdit: boolean }) => {
  const { control, setValue, formState } =
    useFormContext<FormTransferItemsType>()
  const [transaction_date, trx_code] = useWatch({
    control,
    name: ["transaction_date", "trx_code"]
  })

  useTransferItemsCode({
    key: formState.submitCount,
    trx_date: transaction_date,
    onSuccess: (data) =>
      setTimeout(() => {
        setValue("trx_code", data)
      }, 1000),
    enabled: !isEdit && Boolean(transaction_date)
  })

  return <div>{trx_code}</div>
}
