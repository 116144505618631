// React
import { useContext } from "react"

// Contexts
import { AuthContext } from "contexts"

// Third-Party Libraries
import toast from "react-hot-toast"

interface Option {
  disableAlert: boolean
}

export function usePermissionRoute() {
  // Hooks
  const { permission: list } = useContext(AuthContext)

  // Functions
  const checkValidity = (
    permission: string,
    option?: Option
  ): boolean => {
    // Vars
    const isValid: boolean = Boolean(list.find((val) => val.startsWith(permission)))

    if (!isValid && !option?.disableAlert) {
      toast.error('You not have permission!')
    }

    return isValid
  }

  return {
    checkPermission: (
      permission: string,
      option?: Option
    ) => checkValidity(permission, option)
  }
}