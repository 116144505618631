import { ExportExcel } from "components"
import { useExportList } from "pages/Card/Transaction/CreateNewCustomer/utils"
import { FilterType } from "pages/Card/Transaction/CreateNewCustomer/types"

export function Excel(props: {
    dataFillter: FilterType
}) {
    const { data } = useExportList()

    const dataExcel = [
        {
            header: 'Customer Code',
            items: data?.map((item) => item.customer_code),
        },
        {
            header: 'Customer Name',
            items: data?.map((item) => item.customer_name),
        },

        props.dataFillter?.handphone &&
        {
            header: 'Handphone',
            items: data?.map((item) => item.handphone)
        },

        props.dataFillter?.email &&
        {
            header: 'Email',
            items: data?.map((item) => item.email)
        },

        props.dataFillter?.cardLabel &&
        {
            header: 'Card Label',
            items: data?.map((item) => item.card_label)
        },

        props.dataFillter?.category &&
        {
            header: 'Category',
            items: data?.map((item) => item.category_customer_name)
        },

        props.dataFillter?.priceLevel &&
        {
            header: 'Price Level',
            items: data?.map((item) => item.price_level_customer_name)
        },

        props.dataFillter?.bank &&
        {
            header: 'Bank',
            items: data?.map((item) => item.bank_name)
        },

        props.dataFillter?.bankAccount &&
        {
            header: 'Bank Account',
            items: data?.map((item) => item.bank_account)
        },

        props.dataFillter?.country &&
        {
            header: 'Country',
            items: data?.map((item) => item.country_name)
        },

        props.dataFillter?.province &&
        {
            header: 'Province',
            items: data?.map((item) => item.province_name)
        },

        props.dataFillter?.regency &&
        {
            header: 'Regency',
            items: data?.map((item) => item.regency_name)
        },

        props.dataFillter?.subdistrict &&
        {
            header: 'Subdistrict',
            items: data?.map((item) => item.subdistrict_name)
        },

        props.dataFillter?.village &&
        {
            header: 'Village',
            items: data?.map((item) => item.village_name)
        },

        props.dataFillter?.address &&
        {
            header: 'Address',
            items: data?.map((item) => item.address)
        },
    ]

    return (
        <>
            <ExportExcel dataList={dataExcel} title="CUSTOMER REGISTRATION" fileName="Customer Registration" />
        </>
    )
}