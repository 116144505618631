/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";
import { SuccessFetch } from "types";
import { useApi } from "utils";
import { DetailSalesOrder } from "../../types";

type UseDetailSalesOrder = {
  sales_order_id: string;
  onSuccess?: (data: DetailSalesOrder) => void;
};

type Response = {
  payload: DetailSalesOrder
};

export function useDetailSalesOrder({
  sales_order_id,
  onSuccess
}: UseDetailSalesOrder) {
  const api = useApi();
  const [data, setData] = useState<DetailSalesOrder | null>(null);
  const [isLoading, setLoading] = useState(false);

  const fetchData = () => {
    setLoading(true);
    const params = {
      so_id: sales_order_id
    }

    api
      .get("/salesorder/detailso", { params })
      .then((res: SuccessFetch<Response>) => {
        const data = res.data.payload
        onSuccess?.(data);
        setData(data);
      })
      .catch(() => setData(null))
      .finally(() => setLoading(false));
  };

  const refetch = () => fetchData();

  useEffect(() => {
    if (sales_order_id) {
      fetchData();
    }

    return () => {
      setData(null);
      setLoading(false);
    };
  }, [sales_order_id]);

  return { data, isLoading, refetch };
}
