import { Button } from "components"

type DataFilter = {
  value: number
  label: string
}

type FilterProps = {
  filterValue?: number
  dataFilter: DataFilter[]
  onClick: (filter: number) => void
}

export default function Filter({
  filterValue,
  dataFilter,
  onClick
}: FilterProps) {
  return (
    <section className="grow flex items-stretch overflow-x-auto">
      {dataFilter.map((item) => (
        <Button
          key={item.value}
          type="button"
          color="ghost"
          size="sm"
          className={`grow bg-[#3c3c3c] border-y-0 border-x border-white no-animation hover:bg-primary hover:text-black whitespace-nowrap uppercase ${item.value === filterValue ? "bg-primary text-black" : "text-white"
            }`}
          onClick={() => onClick(item.value)}>
          {item.label}
        </Button>
      ))}
    </section>
  )
}
