import { BalanceSheetNoneType } from "pages/Finance/Report/types"

export const generateNetAsset = (data: Array<BalanceSheetNoneType>) => {
  const accCoaAsset =
    data?.find((coa) => coa.group_coa_code === "01" && coa.parent_id === -1)
      ?.balance ?? 0
  const accCoaLiability =
    data?.find((coa) => coa.group_coa_code === "02" && coa.parent_id === -1)
      ?.balance ?? 0
  const firstEquityCoaIndex = data?.findIndex(
    (coa) => coa.group_coa_code === "03" && coa.parent_id === -1
  )
  const netAssets: BalanceSheetNoneType = {
    coa_id: -2,
    parent_id: -1,
    group_coa_id: "3",
    header_level: 1,
    coa_level: 1,
    group_coa_code: "03",
    coacode: "",
    coa_name: "NET ASSETS",
    normal_pos: 0,
    linked_code: "",
    balance: accCoaAsset - accCoaLiability
  }

  data.splice(firstEquityCoaIndex, 0, netAssets)

  return data
}
