/* eslint-disable react-hooks/exhaustive-deps */
import { useFormContext, useWatch } from "react-hook-form"
import { PaymentInvoiceType } from "../types"
import { usePaymentInvoiceCode } from "../utils/hooks"

export const CodePaymentInvoice = ({ isEdit }: { isEdit: boolean }) => {
  const { control, formState, setValue } = useFormContext<PaymentInvoiceType>()
  const [transaction_date, trx_code] = useWatch({
    control,
    name: ["transaction_date", "trx_code"]
  })

  usePaymentInvoiceCode({
    key: formState.submitCount,
    trx_date: transaction_date,
    onSuccess: (data) => {
      setTimeout(() => setValue("trx_code", data), 1000)
    },
    enabled: !isEdit && Boolean(transaction_date)
  })

  return <div>{trx_code}</div>
}
