interface BottomSectionProps { 
    label?: string
    value?: string
    className?: string
}

export const BottomSection:React.FC<BottomSectionProps> = ({ label, value, className }) => (
    <div className={`ml-5 mt-2 ${className}`}>
      <div className="bg-black dark:bg-black border-[1px] border-[#3c3c3c]">
        <div className="label-text my-1 text-center text-white">{label ?? ""}</div>
      </div>
      <div className="flex">
        <div className="border border-[#3c3c3c] h-[100px] text-center w-[110px]">
          <div className="text-[10px]">LAST MONTH</div>
        </div>
        <div className="border border-[#3c3c3c] h-[100px] text-center mx-1 w-[110px]">
          <div className="text-[10px]">THIS MONTH</div>
        </div>
        <div className="border border-[#3c3c3c] h-[100px] text-center w-[110px]">
          <div className="text-[10px]">BUDGET</div>
        </div>
      </div>
    </div>
  )