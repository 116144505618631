import * as yup from 'yup';

export type PaymentInvoiceType = yup.InferType<typeof paymentInvoiceSchema>;

export const paymentInvoiceSchema = yup.object().shape({
  payment_invoice_id: yup.number().label('Payment Invoice').optional(),
  invoice_id: yup.number().label('Invoice').optional(),
  coa_id: yup
    .number()
    .label('Payment Account')
    .typeError('Payment Account is required')
    .required(),
  coa_name: yup.string().label('Code').optional(),
  coa_balance: yup.number().label('Balanace'),
  customer_id: yup
    .number()
    .label('Customer')
    .typeError('Customer is required')
    .required(),
  customer_name: yup.string().label('Customer').optional(),
  deposit_amount: yup
    .number()
    .label('Deposit Amount')
    .typeError('Deposit Amount is required')
    .test(
      'amountRequired',
      'Deposit Amount is required',
      (value) => (value ?? 0) > 0,
    )
    .required(),
  payment_amount: yup
    .number()
    .label('Payment Amount')
    .typeError('Payment Amount is required')
    .test(
      'amountRequired',
      'Payment Amount is required',
      (value) => (value ?? 0) > 0,
    )
    .test(
      'insufficientBalance',
      'Insufficient Balance',
      (value, context) => (value ?? 0) <= context.parent.coa_balance,
    )
    .required(),
  balance_invoice: yup.number().label('Balance').defined(),
  balance: yup
    .number()
    .label('Balance')
    .defined()
    .test('shouldNotBeMinus', 'Balance cannot be minus', (value) => value >= 0),
  location_id: yup
    .number()
    .label('Location')
    .typeError('Location is required')
    .required(),
  link: yup.string().label('Link').required(),
  shipped: yup.string().label('Shipped').required(),
  memo: yup.string().label('Memo').required(),
  referensi: yup.string().label('No Cheque').required(),
  trx_code: yup.string().label('Code').optional(),
  transaction_date: yup.string().label('Transaction Date').required(),
});
