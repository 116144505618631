import { Fragment, useContext } from "react";
import { IoSendSharp } from "react-icons/io5";
import { statusMap } from "../utils";
import { Delete } from "./Delete";
import { SalesType } from "../types";
import { convertNumber } from "utils";
import moment from "moment";
import { PermissionLink, TableNumber } from "components";
import { dataContext } from "..";
import { APPROVAL } from "pages/Sales/Register/utils/vars";

export function Item(props: {
    data: SalesType,
    index: number
}) {

    const { currentPage, limit, size, total } = useContext(dataContext)

    return (
        <Fragment>
            <tr key={props.index}>
                <td>{TableNumber({ dataLength: size, pageCurrent: currentPage, index: props.index, limit: limit, totalData: total })}</td>
                <td>
                    {props.data.approval_status !== "3" && props.data.approval_status !== "4" && (
                        <PermissionLink permission="SR053" target="_blank" rel="noopener noreferrer" to={`/sales/transaction/invoice/edit/${props.data.invoice_id}`} >
                            <IoSendSharp />
                        </PermissionLink>
                    )}
                </td>
                <td>{props.data.trx_code}</td>
                <td>{props.data.sales_order.trx_code}</td>
                <td>{moment(props.data.transaction_date).format("DD/MM/YYYY")}</td>
                <td>{props.data.customer.customer_name}</td>
                <td className="w-[200px]">{statusMap[props.data.approval_status]}</td>
                <td className="text-center">{convertNumber(props.data.delivered_qty).intoNormalAmount}</td>
                <td>{props.data.location.location_name}</td>
                <td className="text-right">{convertNumber(props.data.subtotal).intoCurrency}</td>
                <td className="text-right">{convertNumber(props.data.payment).intoCurrency}</td>
                <td className="text-right">{convertNumber(props.data.balance_owing).intoCurrency}</td>
                <td>
                    {parseInt(props.data.approval_status) === APPROVAL.PENDING  && (
                        <Delete data={props.data} />
                    )}
                </td>
            </tr>
        </Fragment>
    )
}