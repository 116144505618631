// React
import { PropsWithChildren, createContext } from "react"

// Types
import { CoaGroup } from "../../types"

export const DataContext = createContext<{
  data: CoaGroup[]
  refetch: () => void
}>({
  data: [],
  refetch: () => {}
})

export function DataProvider(params: PropsWithChildren<{
  data: CoaGroup[]
  refetch: () => void
}>) {
  return (
    <DataContext.Provider value={{
      data: params.data,
      refetch: params.refetch
    }}>
      {params.children}
    </DataContext.Provider>
  )
}