/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup"
import { ActionButton, NumberInput } from "components"
import {
  useProjectList,
  useTaxCodeList
} from "pages/Finance/Transaction/GeneralEntry/utils/hooks"
import { useDropdownProduct } from "pages/Sales/Transaction/CustomerOrder/utils/hooks"
import { useEffect, useMemo } from "react"
import { Controller, FormProvider, useForm } from "react-hook-form"
import { formInvoiceDetailSchema, FormInvoiceDetailType } from "../../../types/FormInvoiceDetail"

type Action = "add" | "edit"

type RowProps = {
  index?: number
  action: Action
  defaultValues?: any
  onSubmit: (value: any) => void
  onCancel: () => void
  tax: ReturnType<typeof useTaxCodeList>
  jobs: ReturnType<typeof useProjectList>
  products: ReturnType<typeof useDropdownProduct>
  priceLevelName?: string
  displayArrowNavigation?: boolean
}

export function RowInvoiceReturnItems(props: RowProps) {
  const defaultValues: FormInvoiceDetailType = useMemo(
    () => ({
      quantity_back_delivered: props?.defaultValues?.quantity_invoice ?? 0 - props?.defaultValues?.quantity_returned ?? 0,
      quantity_invoice: props?.defaultValues?.quantity_invoice ?? 0,
      quantity_returned: props?.defaultValues?.quantity_returned ?? 0,
      product_id: props?.defaultValues?.product_id,
      product_name: props?.defaultValues?.product_name,
      product_barcode: props?.defaultValues?.product_barcode,
      mr_unit_id: props?.defaultValues?.mr_unit_id,
      mr_unit_name: "",
      price_invoice: props?.defaultValues?.price_invoice,
      discount: props?.defaultValues?.discount,
      total: props?.defaultValues?.total,
      tax_id: props?.defaultValues?.tax_id,
      tax_name: `${props?.defaultValues?.tax_name}`,
      tax_rate: props?.defaultValues?.tax_rate,
    }),
    [props.defaultValues, props.priceLevelName]
  )

  const methods = useForm<FormInvoiceDetailType>({
    defaultValues,
    resolver: yupResolver(formInvoiceDetailSchema)
  })

  useEffect(() => methods.reset(defaultValues), [defaultValues])

  return (
    <FormProvider {...methods}>
      <tr className="no-padding-body">
        <td className="text-right">
          <div className="w-[120px] px-3">
            {methods.getValues("quantity_back_delivered")}
          </div>
        </td>
        <td className="text-right">
          <Controller
            control={methods.control}
            name="quantity_returned"
            render={({ field, fieldState }) => (
              <NumberInput
                textRight
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={2}
                inputClass="w-[120px]"
                value={field.value}
                ref={field.ref}
                onValueChange={(value) => {
                  field.onChange(value.floatValue)
                }}
                error={fieldState.error?.message}
              />
            )}
          />
        </td>
        <td>
          <Controller
            control={methods.control}
            name="product_barcode"
            render={({ field }) => (
              <div className="w-[250px] px-3">{field.value}</div>
            )}
          />
        </td>
        <td>
          <Controller
            control={methods.control}
            name="product_name"
            render={({ field }) => (
              <div className="w-[250px] px-3">{field.value}</div>
            )}
          />
        </td>
        <td>
          <Controller
            control={methods.control}
            name="mr_unit_name"
            render={({ field }) => <div className="px-3">{field.value}</div>}
          />
        </td>
        <td className="text-right">
          <Controller
            control={methods.control}
            name="price_invoice"
            render={({ field }) => (
              <div className="px-3">{field.value.toLocaleString()}</div>
            )}
          />
        </td>
        <td className="text-right">
          <Controller
            control={methods.control}
            name="discount"
            render={({ field }) => <div className="px-3">{field.value}</div>}
          />
        </td>
        <td className="text-right">
          <Controller
            control={methods.control}
            name="total"
            render={({ field }) => (
              <div className="w-[120px] px-3">
                {field.value.toLocaleString()}
              </div>
            )}
          />
        </td>
        <td>
          <Controller
            control={methods.control}
            name="tax_name"
            render={({ field }) => (
              <div className="w-[120px] px-3">{field.value}</div>
            )}
          />
        </td>
        <td className="flex justify-center items-center">
          <ActionButton.Check
            onClick={() => {
              methods.handleSubmit((data) => props.onSubmit(data))()
            }}
          />

          <ActionButton.Close onClick={props.onCancel} />
        </td>
      </tr>
    </FormProvider>
  )
}
