// Components
import { DateInput } from "components"

// Form
import { Controller, useFormContext } from "react-hook-form"

// Third-Party Libraries
import moment from "moment"

export function PromisedDate(params: {
  disabled?: boolean
  name: string
}) {
  // Form
  const { control } = useFormContext()

  return (
    <Controller
      control={control}
      name={params.name}
      render={({ field, fieldState }) => (
        <DateInput
          label="PROMISED DATE"
          disabled={params.disabled}
          error={fieldState.error?.message}
          selected={moment(field.value).toDate()}
          onChange={(date) => {
            // Vars
            const selectedDate: string = moment(date).format("YYYY-MM-DD")

            field.onChange(selectedDate)
          }}
        />
      )}
    />
  )
}