// Form
import * as yup from "yup"

const validation_item = yup.object().shape({
  coacode: yup.string().required(),
  coa_id: yup.number().required(),
  coa_name: yup.string().required(),
  group_coa_id: yup.number().required(),
  header_level: yup.mixed<1 | 2 | 3>().oneOf([1, 2, 3]).required(),
  linked_code: yup.string().defined(),
  normal_pos: yup.number().defined(),
  old_opening_balance: yup.number().defined(),
  opening_balance: yup.number().label("OPENING BALANCE").required(),
  parent_id: yup.number().required()
})

export const validation_schema = yup.object().shape({
  balance_date: yup.string().required(),
  old_balance_date: yup.string().required(),
  list: yup.array().required().of(validation_item)
})

export type FormType = yup.InferType<typeof validation_schema>
export type ItemType = yup.InferType<typeof validation_item>