import { BaseTable, Button, Loading } from "components"
import { convertDate } from "pages/Inventori/Transaction/TransactionJournal/utils"
import { convertNumber } from "utils"
import { TableSectionProps } from "../types"
import { getSourceAndLink } from "pages/FindSection/functions"
import { IoSendSharp } from "react-icons/io5"
import { useNavigate } from "react-router-dom"
import { useSourceData } from "../hooks"

export const TableSection = ({ data, isLoading }: TableSectionProps) => {

  const { refetch } = useSourceData()
  const navigate = useNavigate()

  const handleLinkClick = async (dataSource: number, val: any) => {
    let updatedSourceData = val;
    const checkedSource = [1, 2, 3, 4, 6, 15, 18, 19].includes(dataSource);

    if (checkedSource) {
      const selectedSource = {
        data_source: val?.data_source,
        data_source_id: val?.data_source_id,
      };

      try {
        const fetchedData = await refetch(selectedSource);
        updatedSourceData = fetchedData || val;
      } catch (error) {
        console.error('Failed to fetch data', error);
      }
    }

    const { link } = getSourceAndLink(dataSource, updatedSourceData);
    navigate(link)
  };

  const totalDebit = data.reduce((total, item) => { return total + (item.position === 1 && item.amount ? item.amount : 0)}, 0);
  const totalCredit = data.reduce((total, item) => { return total + (item.position === 2 && item.amount ? item.amount : 0)}, 0);

  return (
    <div className="overflow-x-auto">
      <BaseTable className="border-transparent">
        <thead>
          <tr className="uppercase">
            <th>No</th>
            <th colSpan={2}>Date</th>
            <th>Id#</th>
            <th>Source</th>
            <th>Account</th>
            <th>Account Name</th>
            <th>Memo</th>
            <th>Qty</th>
            <th>Unit</th>
            <th>Debit</th>
            <th>Credit</th>
            <th>Job Id</th>
            <th>Location</th>
          </tr>
        </thead>
        <tbody>
          {!data.length || isLoading
            ? <tr><td colSpan={15} className="text-center"><Loading loading={isLoading} errorText={isLoading ? "" : "No data available"} /></td></tr>
            : data.map((val, index) => {
              const { source } = getSourceAndLink(val?.data_source, val);
              return (
                <tr>
                  <td>{index + 1}</td>
                  <td>
                    <Button permission="IF02" onClick={() => handleLinkClick(val?.data_source, val)} className="!btn !btn-link !text-[#EEEDEB]">
                      <IoSendSharp />
                    </Button>
                  </td>
                  <td>{val?.transaction_date ? convertDate(val?.transaction_date) : "-"}</td>
                  <td>{val?.trx_code ?? "-"}</td>
                  <td>{val?.data_source ? source : "-"}</td>
                  <td>{`${val?.account.group_coa_code} - ${val?.account.coa_code}`}</td>
                  <td>{val?.account.coa_name ?? "-"}</td>
                  <td>{val?.memo ?? "-"}</td>
                  <td className="text-center">{val?.qty ? convertNumber(val?.qty)?.intoNormalAmount : "-"}</td>
                  <td>{val?.unit?.mr_unit_name ?? "-"}</td>
                  <td className="text-right">{val?.position === 1 && convertNumber(val?.amount)?.intoCurrency}</td>
                  <td className="text-right">{val?.position === 2 && convertNumber(val?.amount)?.intoCurrency}</td>
                  <td>{val?.project?.project_name ?? "-"}</td>
                  <td>{val?.location?.location_name ?? "-"}</td>
                </tr>
              )
            }
            )
          }
          <tr>
            <td colSpan={10} />
            <td className="text-right">{totalDebit ? convertNumber(totalDebit)?.intoCurrency : "0"}</td>
            <td className="text-right">{totalCredit ? convertNumber(totalCredit)?.intoCurrency : "0"}</td>
            <td>#</td>
            <td>#</td>
          </tr>
        </tbody>
      </BaseTable>
    </div>
  )
}