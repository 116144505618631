/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react';
import { SuccessFetch } from 'types';
import { useApi } from 'utils';
import { ComparationType } from '../../types';

type Response = {
  payload: Array<ComparationType>;
};

type UseComparationAll = {
  onSuccess?: (data: Response['payload']) => void;
};

export function useComparationList(params: UseComparationAll) {
  const api = useApi();
  const [data, setData] = useState<Response['payload']>([]);
  const [isLoading, setLoading] = useState(false);

  const fetchData = () => {
    setLoading(true);

    api
      .get('/comparation/')
      .then((res: SuccessFetch<Response>) => {
        const data = res.data.payload ?? [];

        const sortedByComparationName = data.sort(
          (a, b) => a.mr_comparation_name - b.mr_comparation_name,
        );

        params?.onSuccess?.(sortedByComparationName);
        setData(sortedByComparationName);
      })
      .catch(() => setData([]))
      .finally(() => setLoading(false));
  };

  const refetch = () => fetchData();

  useEffect(() => {
    fetchData();

    return () => {
      setData([]);
      setLoading(false);
    };
  }, []);

  return { data, isLoading, refetch };
}
