import * as yup from "yup"

export type FormReminderType = yup.InferType<typeof formReminderSchema>

export const formReminderSchema = yup.object().shape({
  vendor_id: yup.number().label("Vendor").optional(),
  card_group: yup.number().label("Card Group").optional(),
  card_id: yup.number().label("Card Id").optional(),
  transaction_date: yup.string().label("Transaction Date").optional(),
  trx_code: yup.string().label("Code").optional(),
  promise_date: yup.string().label("Transaction Date").optional(),
  memo: yup.string().label("Transaction Date").optional()
})
