import { ActionButton, HeaderMenu, PermissionLink } from "components"
import { FormProvider, useForm } from "react-hook-form"
import { FormSection } from "../FormSection"
import { validationSchema } from "../../utils/vars"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormType } from "../../types"
import { useParams } from 'react-router-dom';
import toast from "react-hot-toast"
import { today, useApi } from "utils"
import { FormAction } from "../FormAction"
import { Fragment, useEffect } from "react"
import { useDetailOpeningStock } from "../../utils"

export type DefaultValues = Pick<FormType, 'transaction_date' | 'location_id' | 'product_id' | 'mr_unit_id' | 'av_price_unit' | 'soh'>
export function Update(props: { id: number }) {

    return (
        <PermissionLink permission={"IT083"} to={`/inventory/transaction/opening-stock/update/${props.id}`}>
            <ActionButton.Update permission={"IT083"} className="join-item" />
        </PermissionLink>
    )
}

export function UpdateAction() {
    const api = useApi()
    const params = useParams()
    const openingDetail = useDetailOpeningStock({
        id_opening_stock: Number(params.opening_stock_id)
    })

    const defaultValues: DefaultValues = {
        transaction_date: today(),
        location_id: '',
        product_id:  '',
        mr_unit_id:  '',
        av_price_unit: '',
        soh: ''
    }

    const methods = useForm<DefaultValues>({
        resolver: yupResolver(validationSchema),
        defaultValues: defaultValues
    })

    useEffect(() => {
        if (openingDetail.data[0]) {
            methods.setValue('location_id', openingDetail.data[0].location_id)
            methods.setValue('product_id', openingDetail.data[0].product_id)
            methods.setValue('mr_unit_id', openingDetail.data[0].mr_unit_id)
            methods.setValue('transaction_date', openingDetail.data[0].transaction_date)
            methods.setValue('av_price_unit', openingDetail.data[0]?.av_price_unit ?? '')
            methods.setValue('soh', Number(openingDetail.data[0].soh) % 1 === 0 ? Math.floor(Number(openingDetail.data[0].soh)).toLocaleString("id-ID") : openingDetail.data[0].soh.replace(/(\.\d*?)0+$/, '$1').replace(/\.$/, ''))
        }
        // eslint-disable-next-line
    }, [openingDetail.data[0]])

    const onSubmit = (value: DefaultValues) => {
        return new Promise<void>((resolve) => {
            toast.promise(
                api.put(`/opening-stock/update?Opening_Stock_Id=${params.opening_stock_id}`,
                    {
                        transaction_date: value.transaction_date,
                        location_id: value.location_id,
                        product_id: value.product_id,
                        av_price_unit: value.av_price_unit,
                        soh: value.soh
                    }),
                {
                    loading: "Loading...",
                    success: (res) => res.data.message,
                    error: (err) => {
                        if (err.response && err.response.status === 400) {
                            return err.response.data.message || err.response.data.detail.message;
                        } else {
                            return "An error occurred";
                        }
                    }
                }
            ).then(() => {
                // Scroll to Top
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
            }).catch(() => { }).finally(resolve)
        })
    }

    return (
        <Fragment>
            <section className="container my-5">
                <section>
                    <HeaderMenu title="UPDATE DATA | OPENING STOCK" />
                    <section className="mt-2">
                        <section className="mt-2">
                            <FormProvider {...methods}>
                                <FormAction
                                    onSubmit={methods.handleSubmit(onSubmit)}
                                >
                                    <FormSection />
                                </FormAction>
                            </FormProvider>
                        </section>
                    </section>
                </section>
            </section>
        </Fragment>
    )
}