/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";
import { SuccessFetch } from "types";
import { useApi } from "utils";

type UseReconciliationLastDate = {
  params: { coa_id: string };
  onSuccess?: (data: string) => void;
};

type Response = {
  payload: {
    last_reconciliation_date: string;
  };
};

export function useReconciliationLastDate({
  params,
  onSuccess
}: UseReconciliationLastDate) {
  const api = useApi();
  const [data, setData] = useState("");
  const [isLoading, setLoading] = useState(false);

  const fetchData = () => {
    if (!params.coa_id) return;

    setLoading(true);

    api
      .get("/reconciliation/last-date", { params })
      .then((res: SuccessFetch<Response>) => {
        const data = res.data.payload;
        console.log(data);

        onSuccess?.(data.last_reconciliation_date);
        setData(data.last_reconciliation_date);
      })
      .catch(() => setData(""))
      .finally(() => setLoading(false));
  };

  const refetch = () => fetchData();

  useEffect(() => {
    fetchData();

    return () => {
      setData("");
      setLoading(false);
    };
  }, [params.coa_id]);

  return { data, isLoading, refetch };
}
