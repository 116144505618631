// React
import axios from "axios"
import { useEffect, useState } from "react"

// Types
import type { SuccessFetch } from "types"

// Utils
import { useApi } from "utils"

interface DataType {
  freight: string
  tracing: string
}
interface FetchType {
  coa_code: string
  coa_name: string
  group_coa_code: string
}

export function useCoaByCode(): {
  data: DataType
  isLoading: boolean
} {
  // Vars
  const defaultValues: DataType = {
    freight: "",
    tracing: ""
  }

  // Hooks
  const api = useApi()
  const [data, setData] = useState<DataType>(defaultValues)
  const [isLoading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const promise = new Promise<DataType>((resolve) => {
      axios.all([
        api.get("/linkedaccount/bycode", {
          params: { linked_account_code: "BA01" }
        }),
        api.get("/linkedaccount/bycode", {
          params: { linked_account_code: "LA07" }
        })
      ]).then(axios.spread((
        resFreight: SuccessFetch<{ payload: FetchType[] }>,
        resTracing: SuccessFetch<{ payload: FetchType[] }>
      ) => {
        // Vars
        const freightPayload = resFreight.data.payload[0]
        const tracingPayload = resTracing.data.payload[0]

        resolve({
          freight: `${freightPayload.group_coa_code}-${freightPayload.coa_code} ${freightPayload.coa_name}`,
          tracing: `${tracingPayload.group_coa_code}-${tracingPayload.coa_code} ${tracingPayload.coa_name}`
        })
      })).catch(() => {
        resolve(defaultValues)
      }).finally(() => {
        setLoading(false)
      })
    })

    promise.then(setData)

    return () => {
      setData(defaultValues)
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [])

  return { data, isLoading }
}