import { useEffect, useState } from "react"
import type { SelectOptionType, SuccessFetch } from "types"
import { useApi } from "utils"

export function useTypeIndustry() {
  const api = useApi()
  const [dataTypeIndustry, setData] = useState<SelectOptionType[]>([])
  const [isLoadingTypeIndustry, setLoading] = useState(true)

  useEffect(() => {
    api.get("/companies/type-industry?only_active=true")
    .then((res: SuccessFetch<{
      payload: {
        type_industry_id: number
        type_industry_name: string
      }[]
    }>) => { setData(res.data.payload.map(item => { return { label: item.type_industry_name, value: item.type_industry_id.toString() }}))})
    .catch(() => setData([]))
    .finally(() => setLoading(false))
  
    return () => {
      setData([])
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [])

  return { dataTypeIndustry, isLoadingTypeIndustry }
}