/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup"
import { ActionButton, Button, NumberInput } from "components"
import { Fragment, useEffect, useMemo } from "react"
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
  UseFormReturn,
  useWatch
} from "react-hook-form"
import { TbEye } from "react-icons/tb"
import { useToggle } from "utils"
import {
  formTransferDetailSchema,
  FormTransferDetailType,
  FormTransferItemsType
} from "../../../types"
import { useProductAvailableList } from "../../../utils/hooks"
import { DropdownProductAvailable } from "../../DropdownProductAvailable"

type Action = "add" | "edit"

type RowProps = {
  index?: number
  action: Action
  defaultValues?: any
  isHideForm?: boolean
  onCancelEditing?: () => void
  onDeleteJournal?: () => void
  onSubmit: (
    value: FormTransferDetailType,
    methods?: UseFormReturn<FormTransferDetailType>
  ) => void
  products: ReturnType<typeof useProductAvailableList>
}

export function RowTransferItem(props: RowProps) {
  const isHideForm = useToggle(props.isHideForm ?? true)

  const defaultValues: FormTransferDetailType = useMemo(
    () => ({
      transfer_detail_id: props?.defaultValues?.transfer_detail_id,
      product_id: props?.defaultValues?.product_id ?? NaN,
      product_name: props?.defaultValues?.product_name ?? "",
      product_barcode: props?.defaultValues?.product_barcode ?? "",
      quantity_transfer: props?.defaultValues?.quantity_transfer ?? 0,
      mr_unit_id: props?.defaultValues?.mr_unit_id ?? 0,
      mr_unit_name: props?.defaultValues?.mr_unit_name ?? "",
      soh: props?.defaultValues?.soh ?? 0
    }),
    [props.defaultValues]
  )
  const methodsParent = useFormContext<FormTransferItemsType>()

  const location_from_id = useWatch({
    control: methodsParent.control,
    name: "location_from_id"
  })

  const methods = useForm<FormTransferDetailType>({
    defaultValues,
    resolver: yupResolver(formTransferDetailSchema)
  })

  const handleToggleHideForm = () => {
    if (!location_from_id) {
      return methodsParent.trigger("location_from_id")
    }

    isHideForm.toggle()
  }

  useEffect(() => methods.reset(defaultValues), [defaultValues])

  return (
    <FormProvider {...methods}>
      <tr className="no-padding-body">
        <td className="text-center">
          {isHideForm.isActive && (
            <Button color="transparent" onClick={handleToggleHideForm}>
              <TbEye className="size-5" />
            </Button>
          )}
        </td>
        {isHideForm.isActive ? (
          <td colSpan={13} />
        ) : (
          <Fragment>
            <td className="w-[250px]">
              <DropdownProductAvailable products={props.products} />
            </td>

            <td>
              {
                <Controller
                  control={methods.control}
                  name="product_name"
                  render={({ field }) => (
                    <div className="w-[250px] px-3">{field.value}</div>
                  )}
                />
              }
            </td>

            <td className="text-right">
              {
                <Controller
                  control={methods.control}
                  name="soh"
                  render={({ field }) => (
                    <div className="px-3">{field.value}</div>
                  )}
                />
              }
            </td>

            <td>
              {
                <Controller
                  control={methods.control}
                  name="mr_unit_name"
                  render={({ field }) => (
                    <div className="px-3">{field.value}</div>
                  )}
                />
              }
            </td>

            <td className="text-right">
              <Controller
                control={methods.control}
                name="quantity_transfer"
                render={({ field, fieldState }) => (
                  <NumberInput
                    textRight
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    value={field.value}
                    ref={field.ref}
                    onValueChange={(value) => {
                      field.onChange(value.floatValue)
                    }}
                    error={fieldState.error?.message}
                  />
                )}
              />
            </td>

            <td>
              <div className="text-center flex justify-center items-center">
                {props.action === "edit" && (
                  <>
                    <ActionButton.Check
                      onClick={() => {
                        methods.handleSubmit((data) => props.onSubmit(data))()
                      }}
                    />
                    <ActionButton.Close onClick={props?.onCancelEditing} />
                  </>
                )}

                {props.action === "add" && (
                  <>
                    <ActionButton.Add
                      onClick={() => {
                        methods.handleSubmit((data) =>
                          props.onSubmit(data, methods)
                        )()
                      }}
                    />
                    <ActionButton.Close
                      onClick={() => {
                        isHideForm.toggle()
                        methods.reset(defaultValues)
                      }}
                    />
                  </>
                )}
              </div>
            </td>
          </Fragment>
        )}
      </tr>
    </FormProvider>
  )
}
