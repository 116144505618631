import { Button, DateInput } from "components"
import moment from "moment"
import { IoCalendarOutline } from "react-icons/io5"

interface FilterByDateProps {
  dateFrom: string
  dateTo: string
  permission?: string
  onChangeDateFrom: (date: string) => void
  onChangeDateTo: (date: string) => void
  onClickPrevYear: () => void
  onClickThisYear: () => void
  onClickThisMonth: () => void
  onClickThisWeek: () => void
  onClickToday: () => void
}

export function FilterByDate({
  dateFrom,
  dateTo,
  permission,
  onChangeDateFrom,
  onChangeDateTo,
  onClickPrevYear,
  onClickThisYear,
  onClickThisMonth,
  onClickThisWeek,
  onClickToday
}: FilterByDateProps) {
  return (
    <section className="container flex-col lg:flex lg:flex-row justify-center lg:justify-between pt-5">
      <div className="w-full lg:w-[35%] flex-col lg:flex lg:flex-row justify-center lg:justify-start items-center">
        <div className="label-text pb-1 font-bold pe-2">Date From</div>
        <DateInput
          className="w-[149px]"
          placeholderText="Date From"
          onChange={(date) =>
            onChangeDateFrom(moment(date).format("YYYY-MM-DD"))
          }
          selected={moment(dateFrom).toDate()}
        />

        <div className="mt-3 lg:mt-0 lg:px-2 pb-1 label-text font-bold">
          Date To
        </div>
        <DateInput
          className="w-[149px]"
          placeholderText="Date To"
          minDate={moment(dateFrom).toDate()}
          selected={moment(dateTo).toDate()}
          onChange={(date) => onChangeDateTo(moment(date).format("YYYY-MM-DD"))}
        />
      </div>

      <div className="w-full lg:w-[65%] mt-5 lg:mt-0 lg:flex lg:justify-end lg:max-w-[65%]">
        <div
          className="flex-wrap lg:inline-flex rounded-lg shadow-sm"
          role="group">
          <Button
            permission={permission}
            className="uppercase sm:rounded-s-lg border-white hover:border-primary hover:bg-primary hover:text-black"
            onClick={onClickPrevYear}>
            <IoCalendarOutline fontSize={18} />
            Prev Year
          </Button>

          <Button
            permission={permission}
            className=" uppercase border-white hover:border-primary hover:bg-primary hover:text-black"
            onClick={onClickThisYear}>
            <IoCalendarOutline fontSize={18} />
            This Year
          </Button>

          <Button
            permission={permission}
            className="uppercase border-white hover:border-primary hover:bg-primary hover:text-black"
            onClick={onClickThisMonth}>
            <IoCalendarOutline fontSize={18} />
            This Month
          </Button>

          <Button
            permission={permission}
            className="uppercase border-white hover:border-primary hover:bg-primary hover:text-black"
            onClick={onClickThisWeek}>
            <IoCalendarOutline fontSize={18} />
            This Week
          </Button>

          <Button
            permission={permission}
            className="uppercase sm:rounded-e-lg border-white hover:border-primary hover:bg-primary hover:text-black"
            onClick={onClickToday}>
            <IoCalendarOutline fontSize={18} />
            Today
          </Button>
        </div>
      </div>
    </section>
  )
}
