// React
import { Fragment, useContext } from "react"

// Components
import { NumberInput } from "components"

// Contexts
import { FilterContext } from "../contexts"
import { convertNumber } from "utils"

export function ItemView(params: {
  amount: number
  code?: string
  inputClass?: string
  isAmountOnly?: boolean
  isInputOnly?: boolean
  label: string
  labelClassName?: string
  level: number
}) {
  // Hooks
  const { sub_header, zero_value } = useContext(FilterContext)

  return (
    <tr>
      <td
        className={`flex ${params.labelClassName}`}
        style={{ paddingLeft: 16 + 10 * params.level }}>
        {params.code &&
          Boolean(params.level === 2 ? sub_header.isActive : true) &&
          Boolean(params.amount === 0 ? zero_value.isActive : true) && (
            <Fragment>
              <div className="peer-has-[:checked]:group-[]:block hidden">
                {params.code}
              </div>
              <div className="divider divider-horizontal m-0 peer-has-[:checked]:group-[]:flex hidden" />
            </Fragment>
          )}

        <div>{params.label}</div>
      </td>
      <td className="p-0">
        {!params.isInputOnly && params.isAmountOnly ? (
          <div className={`text-end ${params.inputClass}`}>
            {convertNumber(params.amount).intoCurrency}
          </div>
        ) : (
          <NumberInput
            disabled
            thousandSeparator={","}
            decimalSeparator={"."}
            decimalScale={0}
            inputClass={`text-end ${params.inputClass}`}
            value={params.amount}
          />
        )}
      </td>
    </tr>
  )
}
