/* eslint-disable react-hooks/exhaustive-deps */

import { APPROVAL } from "pages/Sales/Register/utils/vars"
import { useEffect, useState } from "react"
import { SuccessFetch } from "types"
import { useApi } from "utils"
import { InvoiceListBySO } from "../../types"

type UseSOInvoiceListProps = {
  sales_order_id: string
  Approval?: APPROVAL
}

type Response = {
  payload: InvoiceListBySO[]
}

export function useSOInvoiceList({
  sales_order_id,
  Approval
}: UseSOInvoiceListProps) {
  const api = useApi()
  const [data, setData] = useState<Response["payload"]>([])
  const [isLoading, setLoading] = useState(false)

  const fetchData = () => {
    setLoading(true)

    const params = {
      sales_order_id: Number(sales_order_id),
      Approval
    }

    api
      .post("/invoice/byso", null, { params })
      .then((res: SuccessFetch<Response>) => {
        const data = res.data.payload ?? []
        setData(data)
      })
      .catch(() => setData([]))
      .finally(() => setLoading(false))
  }

  const refetch = () => fetchData()

  useEffect(() => {
    if (Boolean(sales_order_id) && !isNaN(Number(sales_order_id))) {
      fetchData()
    }

    return () => {
      setData([])
      setLoading(false)
    }
  }, [sales_order_id])

  return {
    data,
    isLoading,
    refetch,
    dropdown: data.map((item) => ({
      value: item.invoice_id,
      label: item.trx_code
    }))
  }
}
