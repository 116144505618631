import { Button } from "components"
import { useState } from "react"
import { TableInvoice, TableReturnItems } from "./Tables"

type TabReturnItemsProps = {
  salesOrderId?: number
  approvalView?: boolean
  approvalStatus: number
  display?: boolean
  displayCreateButton?: boolean
}

export const TabReturnItems = ({
  salesOrderId,
  approvalView,
  approvalStatus,
  display = true,
  displayCreateButton = true
}: TabReturnItemsProps) => {
  const [activeTab, setActiveTab] = useState(0)
  const tabs = ["INVOICE", "RETURNED"]

  return (
    <div className={`${display ? "" : "hidden"}`}>
      <div className="sm:w-full md:w-20 flex justify-start items-start mb-2">
        {tabs.map((item, key) => {
          const isActive = key === activeTab

          return (
            <Button
              size="sm"
              color="ghost"
              className={`no-animation w-full bg-[#3c3c3c] hover:bg-primary hover:text-black whitespace-nowrap ${
                isActive ? "bg-primary text-black" : "text-white"
              }`}
              onClick={() => setActiveTab(key)}>
              {item}
            </Button>
          )
        })}
      </div>

      <TableInvoice display={activeTab === 0} />

      <TableReturnItems display={activeTab === 1} />
    </div>
  )
}
