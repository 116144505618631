/* eslint-disable react-hooks/exhaustive-deps */
import { Label, Select } from "components"
import { Controller, useFormContext } from "react-hook-form"
import { useDropdownCustomer } from "../../CustomerOrder/utils/hooks"

export type DropdownPayProps = {
  isDisabled?: boolean
}

export const DropdownPay = ({ isDisabled }: DropdownPayProps) => {
  const { dropdown, isLoading } = useDropdownCustomer()
  const { control } = useFormContext()

  return (
    <>
      <Label text="PAY FROM" />
      <section className="flex gap-3">
        <Controller
          control={control}
          name="customer_id"
          render={({ field, fieldState }) => (
            <Select
              className="grow"
              placeholder="Select Customer"
              isLoading={isLoading}
              options={dropdown}
              value={
                field.value
                  ? dropdown.find((c) => field.value.toString() === c.value)
                  : null
              }
              isDisabled={isDisabled}
              onChange={(e: any) => {
                field.onChange(e?.value)
              }}
              error={fieldState.error?.message}
            />
          )}
        />
      </section>
    </>
  )
}
