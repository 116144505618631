// React
import { PropsWithChildren, createContext } from "react"

// Types
import { AccountHookType } from "types"

// Utils
import { useAccounts } from "utils"

export const AccountContext = createContext<AccountHookType>({
  data: [],
  isLoading: true,
  refetch: () => {}
})

export function AccountProvider(params: PropsWithChildren<{}>): JSX.Element {
  // Hooks
  const { data, isLoading, refetch } = useAccounts()

  return (
    <AccountContext.Provider
      value={{
        data,
        isLoading,
        refetch
      }}
    >
      {params.children}
    </AccountContext.Provider>
  )
}