import * as yup from "yup"

export const dataJournalValidationSchema = yup.object().shape({
  amount: yup.number().label("AMOUNT").required(),
  coa_id: yup.string().label("ACCOUNTS").required(),
  coa_name: yup.string().defined(),
  memo: yup.string().label("MEMO").defined(),
  project_id: yup.string().label("PROJECT ID").required(),
  project_name: yup.string().defined(),
  rate: yup.number().defined(),
  referensi: yup.string().label("REFERENCE").defined(),
  tax_id: yup.string().label("TAX CODE").required(),
  tax_coa_collect: yup.string().defined(),
  tax_coa_collect_id: yup.string().defined(),
  tax_coa_paid: yup.string().defined(),
  tax_coa_paid_id: yup.string().defined(),
  tax_name: yup.string().defined()
})

export type DataJournalType = yup.InferType<typeof dataJournalValidationSchema>