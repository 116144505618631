import { Button, Select, DateInput } from "../../../../../../components"
import { Controller, useFormContext } from "react-hook-form"
import type { WorkStatusList } from "../../types"
import { useDepartement, useDivisi, useEmployeeStatus, useGroupPosition, useJobsPosition, useMainLocation, useSalaryType } from "./utils"
import { useState } from "react"
import { FaPlus } from "react-icons/fa";
import { CreateModal as CreateModalGroupPosition } from "../../../../../Setup/Preference/GroupPosition/components";
import { CreateModal as CreateModalJobPosition } from "../../../../../Setup/Preference/JobPosition/components";
import { CreateModal as CreateModalMainLocation } from "../../../../../Setup/Preference/Location/components";
import { CreateModal as CreateModalDepartement } from "../../../../../Setup/Preference/Departement/components";
import { CreateModal as CreateModalDivisi } from "../../../../../Setup/Preference/Divisi/components";
import { CreateModal as CreateModalSalaryType } from "../../../../../Setup/Preference/SalaryType/components";
import { CreateModal as CreateModalEmployeeStatus } from "../../../../../Setup/Preference/EmployeeStatus/components";
import moment from "moment"
interface MapProps {
  action?: "CREATE" | "UPDATE" | "DETAIL"
  onSubmit: (newState: any) => void
  isActiveSubmit?: boolean
  permission?: boolean
}

export const FormWorkStatus:React.FC<MapProps> = ({ action, onSubmit, isActiveSubmit, permission }) => {
  const { control, getValues, handleSubmit, formState } = useFormContext<WorkStatusList>()

  return (
    <div>
      <section>
        <EmployeeStatus isDisabled={action === "DETAIL"} />
        <Controller
          control={control}
          name="date_end"
          render={({ field, fieldState }) => 
            <DateInput
              label="DATE START" 
              className="w-full" 
              error={control?._formValues?.date_end ? "" : fieldState.error?.message}
              disabled={action === "DETAIL"}
              placeholderText="Input date start" 
              ref={field.ref}
              selected={moment(field.value).toDate()}
              onChange={value => field.onChange(moment(value).format("YYYY-MM-DD"))}
            />
          }
        />
        <Controller
          control={control}
          name="date_start"
          render={({ field, fieldState }) => 
            <DateInput
              label="DATE END" 
              className="w-full" 
              error={control?._formValues?.date_start ? "" : fieldState.error?.message}
              placeholderText="Input date end" 
              disabled={action === "DETAIL"}
              ref={field.ref}
              selected={moment(field.value).toDate()}
              onChange={value => field.onChange(moment(value).format("YYYY-MM-DD"))}
            />
          }
        />
        <GroupPosition isDisabled={action === "DETAIL"} />
        <JobPosition isDisabled={action === "DETAIL"} />
        <MainLocation isDisabled={action === "DETAIL"} />
        <Departement isDisabled={action === "DETAIL"} />
        <Divisi isDisabled={action === "DETAIL"} />
        <Gender isDisabled={action === "DETAIL"} />
        <SalaryType isDisabled={action === "DETAIL"} />
      </section>
      {action !== "DETAIL" &&
        <div className="flex justify-end">
          <Button 
            type="button"
            className="!none mt-5" 
            color="primary"
            loading={formState.isSubmitting ? "true" : undefined}
            permission={"CR011"}
            onClick={handleSubmit(() => onSubmit(getValues()),(err)=> console.log(err))}
            disabled={isActiveSubmit}
          >
            SAVE
          </Button>
        </div>
      }
    </div>
  )
}

function EmployeeStatus(props: { isDisabled: boolean }) {
  const { control } = useFormContext<WorkStatusList>()
  const { data, isLoading, refetch } = useEmployeeStatus()
  const [modalConfig, setModalConfig] = useState(false)

  return (
    <div className="flex items-top">
      <Controller
        control={control}
        name="employee_status_id"
        render={({ field, fieldState }) => (
          <Select
            label="EMPLOYEE STATUS"
            placeholder="Select employee status..."
            controlClass={!props.isDisabled ? "!rounded-r-none" : ""}
            isDisabled={props.isDisabled}
            isLoading={isLoading}
            error={fieldState.error?.message}
            options={data}
            value={data.find(item => item.value === field.value?.toString())}
            onChange={e => {
              field.onChange(e?.value)
              refetch()
            }}
          />
        )}
      />
      {!props.isDisabled && 
        <div className="mt-[36px]">
          <Button 
            type="button" 
            color="primary" 
            className="!rounded-r-lg" 
            onClick={() => setModalConfig(true)}>
            <FaPlus />
          </Button>
        </div>
      }
      {modalConfig === true && <CreateModalEmployeeStatus toggle={() => {
        setModalConfig(false)
        refetch()
      }} />}
    </div>
  )
}
function GroupPosition(props: { isDisabled: boolean }) {
  const { control } = useFormContext<WorkStatusList>()
  const { data, isLoading, refetch } = useGroupPosition()
  const [modalConfig, setModalConfig] = useState(false)

  return (
    <div className="flex items-top">
      <Controller
        control={control}
        name="group_position_id"
        render={({ field, fieldState }) => (
          <Select
            label="GROUP POSITION"
            placeholder="Select group position..."
            controlClass={!props.isDisabled ? "!rounded-r-none" : ""}
            isDisabled={props.isDisabled}
            isLoading={isLoading}
            error={fieldState.error?.message}
            options={data}
            value={data.find(item => item.value === field.value?.toString())}
            onChange={e => {
              field.onChange(e?.value)
              refetch()
            }}
          />
        )}
      />
      {!props.isDisabled && 
        <div className="mt-[36px]">
          <Button 
            type="button" 
            color="primary" 
            className="!rounded-r-lg" 
            onClick={() => setModalConfig(true)}>
            <FaPlus />
          </Button>
        </div>
      }
      {modalConfig === true && <CreateModalGroupPosition toggle={() => {
        setModalConfig(false)
        refetch()
      }} />}
    </div>
  )
}
function JobPosition(props: { isDisabled: boolean }) {
  const { control } = useFormContext<WorkStatusList>()
  const { data, isLoading, refetch } = useJobsPosition()
  const [modalConfig, setModalConfig] = useState(false)

  return (
    <div className="flex items-top">
      <Controller
        control={control}
        name="job_position_id"
        render={({ field, fieldState }) => (
          <Select
            label="JOB POSITION"
            placeholder="Select job position..."
            controlClass={!props.isDisabled ? "!rounded-r-none" : ""}
            isDisabled={props.isDisabled}
            isLoading={isLoading}
            error={fieldState.error?.message}
            options={data}
            value={data.find(item => item.value === field.value?.toString())}
            onChange={e => {
              field.onChange(e?.value)
              refetch()
            }}
          />
        )}
      />
      {!props.isDisabled && 
        <div className="mt-[36px]">
          <Button 
            type="button" 
            color="primary" 
            className="!rounded-r-lg" 
            onClick={() => setModalConfig(true)}>
            <FaPlus />
          </Button>
        </div>
      }
      {modalConfig === true && <CreateModalJobPosition toggle={() => {
        setModalConfig(false)
        refetch()
      }} />}
    </div>
  )
}
function MainLocation(props: { isDisabled: boolean }) {
  const { control } = useFormContext<WorkStatusList>()
  const { dataMainLocation, isLoadingMainLocation, refetch } = useMainLocation()
  const [modalConfig, setModalConfig] = useState(false)

  return (
    <div className="flex items-top">
      <Controller
        control={control}
        name="location_id"
        render={({ field, fieldState }) => (
          <Select
            label="MAIN LOCATION"
            placeholder="Select main location..."
            controlClass={!props.isDisabled ? "!rounded-r-none" : ""}
            isDisabled={props.isDisabled}
            isLoading={isLoadingMainLocation}
            error={fieldState.error?.message}
            options={dataMainLocation}
            value={dataMainLocation.find(item => item.value === field.value?.toString())}
            onChange={e => {
              field.onChange(e?.value)
              refetch()
            }}
          />
        )}
      />
      {!props.isDisabled && 
        <div className="mt-[36px]">
          <Button 
            type="button" 
            color="primary" 
            className="!rounded-r-lg" 
            onClick={() => setModalConfig(true)}>
            <FaPlus />
          </Button>
        </div>
      }
      {modalConfig === true && <CreateModalMainLocation toggle={() => {
        setModalConfig(false)
        refetch()
      }} />}
    </div>
  )
}
function Departement(props: { isDisabled: boolean }) {
  const { control } = useFormContext<WorkStatusList>()
  const { dataDepartement, isLoadingDepartement, refetch } = useDepartement()
  const [modalConfig, setModalConfig] = useState(false)

  return (
    <div className="flex items-top">
      <Controller
        control={control}
        name="department_id"
        render={({ field, fieldState }) => (
          <Select
            label="DEPARTEMENT"
            placeholder="Select departement..."
            controlClass={!props.isDisabled ? "!rounded-r-none" : ""}
            isDisabled={props.isDisabled}
            isLoading={isLoadingDepartement}
            error={fieldState.error?.message}
            options={dataDepartement}
            value={dataDepartement.find(item => item.value === field.value?.toString())}
            onChange={e => {
              field.onChange(e?.value)
              refetch()
            }}
          />
        )}
      />
      {!props.isDisabled && 
        <div className="mt-[36px]">
          <Button 
            type="button" 
            color="primary" 
            className="!rounded-r-lg" 
            onClick={() => setModalConfig(true)}>
            <FaPlus />
          </Button>
        </div>
      }
      {modalConfig === true && <CreateModalDepartement toggle={() => {
        setModalConfig(false)
        refetch()
      }} />}
    </div>
  )
}
function Divisi(props: { isDisabled: boolean }) {
  const { control } = useFormContext<WorkStatusList>()
  const { data, isLoading, refetch } = useDivisi()
  const [modalConfig, setModalConfig] = useState(false)

  return (
    <div className="flex items-top">
      <Controller
        control={control}
        name="divisi_id"
        render={({ field, fieldState }) => (
          <Select
            label="DIVISI"
            placeholder="Select divisi..."
            controlClass={!props.isDisabled ? "!rounded-r-none" : ""}
            isDisabled={props.isDisabled}
            isLoading={isLoading}
            error={fieldState.error?.message}
            options={data}
            value={data.find(item => item.value === field.value?.toString())}
            onChange={e => {
              field.onChange(e?.value)
              refetch()
            }}
          />
        )}
      />
      {!props.isDisabled && 
        <div className="mt-[36px]">
          <Button 
            type="button" 
            color="primary" 
            className="!rounded-r-lg" 
            onClick={() => setModalConfig(true)}>
            <FaPlus />
          </Button>
        </div>
      }
      {modalConfig === true && <CreateModalDivisi toggle={() => {
        setModalConfig(false)
        refetch()
      }} />}
    </div>
  )
}
function Gender(props: { isDisabled: boolean }) {
  const { control } = useFormContext<WorkStatusList>()
  const dropdownGender = [
    { value: "1", label: "Man" },
    { value: "2", label: "Woman" },
  ]

  return (
    <Controller
      control={control}
      name="gender"
      render={({ field, fieldState }) => (
        <Select
          label="GENDER"
          placeholder="Select gender..."
          isDisabled={props.isDisabled}
          error={fieldState.error?.message}
          options={dropdownGender}
          value={dropdownGender.find(item => item.value === field.value?.toString())}
          onChange={e => field.onChange(e?.value)}
        />
      )}
    />
    
  )
}
function SalaryType(props: { isDisabled: boolean }) {
  const { control } = useFormContext<WorkStatusList>()
  const { data, isLoading, refetch } = useSalaryType()
  const [modalConfig, setModalConfig] = useState(false)

  return (
    <div className="flex items-top">
      <Controller
        control={control}
        name="salary_type_id"
        render={({ field, fieldState }) => (
          <Select
            label="SALARY TYPE"
            placeholder="Select salary type..."
            controlClass={!props.isDisabled ? "!rounded-r-none" : ""}
            isDisabled={props.isDisabled}
            isLoading={isLoading}
            error={fieldState.error?.message}
            options={data}
            value={data.find(item => item.value === field.value?.toString())}
            onChange={e => {
              field.onChange(e?.value)
              refetch()
            }}
          />
        )}
      />
      {!props.isDisabled && 
        <div className="mt-[36px]">
          <Button 
            type="button" 
            color="primary" 
            className="!rounded-r-lg" 
            onClick={() => setModalConfig(true)}>
            <FaPlus />
          </Button>
        </div>
      }
      {modalConfig === true && <CreateModalSalaryType toggle={() => {
        setModalConfig(false)
        refetch()
      }} />}
    </div>
  )
}