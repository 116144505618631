// Components
import { FormSection } from "../components"

// Contexts
import { ListJournalProvider } from "../contexts"

// Form
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

// Third-Party Libraries
import moment from "moment"
import toast from "react-hot-toast"

// Utils
import { useApi, usePermission } from "utils"
import { FormType, getAcc, getFinalValue, validationSchema } from "../utils"
import { formParams } from "./utils"

export default function ReceiveMoney() {
  // Hooks
  const api = useApi()
  const permission = usePermission(["FT02"])

  // Form
  const defaultValues: FormType = {
    balance: 0,
    card_id: "",
    amount: 0,
    datajournal: [],
    coa_id: "",
    coa_name: "",
    group_card: "",
    imgPath: [],
    location_id: "",
    memo: "Receive money transaction",
    reconsiled: false,
    reference_no: "",
    tax_inclusive: false,
    transaction_date: moment().format("YYYY-MM-DD"),
    trx_code: "",
    type_payment: "",
    name_payment: "",
    number_payment: "",
    expired_payment: "",
    authorization_payment: "",
    note_payment: ""
  }
  
  const methods = useForm<FormType>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })
  const onSubmit = (value: FormType) => {
    // Hooks
    const { sub_total, tax } = getAcc(value.datajournal)

    return new Promise<void>((resolve) => {
      if (Math.abs(value.amount - (sub_total + tax)) !== 0) {
        toast.error("Balance must be 0")
        return resolve()
      }

      toast.promise(
        api.post("/receivemoney/add", getFinalValue(value, "receive-money")),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.detail?.message ?? err.response.data.message
        }
      ).then(() => {
        methods.reset({
          transaction_date: value.transaction_date,
          trx_code: "",
          balance: 0,
          card_id: "",
          amount: 0,
          datajournal: [],
          coa_id: "",
          coa_name: "",
          group_card: "",
          imgPath: [],
          location_id: "",
          memo: "Receive money transaction",
          reference_no: "",
          tax_inclusive: false,
          type_payment: "",
          name_payment: "",
          number_payment: "",
          expired_payment: "",
          authorization_payment: "",
          note_payment: ""
        })

        // Scroll to Top
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }).catch(() => {}).finally(resolve)
    })
  }

  return (
    <FormProvider {...methods}>
      <ListJournalProvider
        data_source=""
        date=""
        journal_code=""
        is_enabled={false}
      >
        <FormSection
          {...formParams}
          onSubmit={methods.handleSubmit(onSubmit)}
          permission={permission}
        />
      </ListJournalProvider>
    </FormProvider>
  )
}