/**
 * Note: 
 * 1 = Send Money -> fms_finance -> CD
 * 2 = Receive Money -> fms_finance -> DC
 * 3 = Transfer Bank -> fms_transfer -> TR
 * 4 = Bill -> fms_bill -> BL
 * 5 = Invoice -> fms_invoice -> IN
 * 6 = General Entry -> fms_gl -> GL 
 * 7 = Build -> fms_build -> BI
 * 0 = All
*/

export const dataFilter = [
  { value: 3, label: "General" },
  { value: 4, label: "Transfer Money" },
  { value: 1, label: "Disbursements" },
  { value: 2, label: "Receipts" },
  { value: 5, label: "Sales" },
  { value: 6, label: "Purchases" },
  { value: 7, label: "Inventory" },
  { value: 0, label: "All" }
]
