// Types
import type { SuccessFetch } from "types"

// Utils
import { useApi } from "utils"

export function useDataCode() {
  // Hooks
  const api = useApi()

  const refetch = (date: string): Promise<string> => {
    return new Promise<string>((resolve) => {
      api.post("/paymentbill/generate-code", {
        trx_date: date
      }).then((res: SuccessFetch<{
        payload: { next_code: string }
      }>) => {
        resolve(res.data.payload.next_code)
      }).catch(() => {
        resolve("")
      })
    })
  }

  return { refetch }
}