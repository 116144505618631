import { Button } from "components"
import { TabRegisterPath } from "../../types/TabRegisterPath"

type TabRegisterProps = {
  activeTab: string
  setActiveTab: (path: string) => void
}
type Tabs = {
  label: string
  path: TabRegisterPath
  permission: string
}

export function TabNavFind({ activeTab, setActiveTab }: TabRegisterProps) { 
  const tabs: Tabs[] = [
    { label: "Accounts", path: "accounts", permission: "FF01" },
    { label: "Finance", path: "finance", permission: "FF02" },
    { label: "Sales", path: "sales", permission: "FF03" },
    { label: "Purchases", path: "purchase", permission: "FF04" },
    { label: "Inventory", path: "inventory", permission: "FF05" },
    { label: "Cards", path: "cards", permission: "FF06" },
    { label: "Journals", path: "journals", permission: "FF07" }
  ]

  return (
    <section className="grow flex items-stretch overflow-x-auto">
      {tabs.map((tab) => (
        <Button
          key={tab?.path}
          type="button"
          color="ghost"
          size="sm"
          permission={tab?.permission}
          className={`grow bg-[#3c3c3c] border-y-0 border-x border-white no-animation hover:bg-primary hover:text-black whitespace-nowrap uppercase ${tab.path === activeTab ? "bg-primary text-black" : "text-white"}`}
          onClick={() => setActiveTab(tab?.path)}
        >
          {tab.label}
        </Button>
      ))}
    </section>
  )
}
