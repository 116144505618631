import { DateInput, Select } from 'components'
import moment from 'moment'
import { SearchingSectionProps } from 'pages/Purchase/Register/types/ByCard'
import { useEffect } from 'react';
import { useListTrade } from '../hooks';

export const SearchingSections = ({ selected, setSelected }: SearchingSectionProps) => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();
  const dateTo = new Date(year, month, 1);
  const dateEnd = new Date(year, month + 1, 0);

  const { dataTrade, isLoadingTrade } = useListTrade()
  const dataCard = [{ label: "VENDOR", value: "1" }]

  const sortedSelectTrader = dataTrade.sort((a, b) => parseInt(b.value) - parseInt(a.value));
  const allOption = { value: "", label: "All" };

  useEffect(() => {
    if(selected?.date_from === "") { setSelected({ ...selected, date_from: moment(dateTo).format("YYYY-MM-DD")}) }
    if(selected?.date_end === "") { setSelected({ ...selected, date_end: moment(dateEnd).format("YYYY-MM-DD")}) }

    // eslint-disable-next-line
  }, [selected])

  return (
    <section>
        <div className="lg:flex overflow-x-auto">
            <div className='flex items-center w-[284px] lg:w-[240px]'>
                <div className="label-text pb-1 font-bold pe-2 lg:w-[145px] w-[350px] uppercase">Date From</div>
                <DateInput
                    className="!w-[150px]"
                    placeholderText="Date From"
                    onChange={(date) => setSelected({ ...selected, date_from: moment(date).format("YYYY-MM-DD")})}
                    selected={ selected?.date_from ? moment(selected?.date_from).toDate() : null }
                />
            </div>
            <div className='flex items-center w-[284px] lg:w-[230px] mt-2 lg:mt-0'>
                <div className="lg:mt-0 lg:px-2 pb-1 label-text font-bold lg:w-[110px] !w-[550px] uppercase">Date To</div>
                <DateInput
                    className="!w-[150px]"
                    placeholderText="Date To"
                    onChange={(date) => setSelected({ ...selected, date_end: moment(date).format("YYYY-MM-DD")})}
                    selected={ selected?.date_end ? moment(selected?.date_end).toDate() : null }
                />
            </div>
            <div className='flex items-center ml-1 mt-2 lg:mt-0'>
                <div className="lg:mt-0 lg:px-2 pb-1 label-text font-bold w-[142px] lg:w-full">CARD</div>
                <Select
                    className="w-[150px]"
                    placeholder="Select Location"
                    value={selected?.card_id !== "" ? [allOption, ...dataCard].find((item) => item.value?.toString() === selected?.card_id?.toString()) : allOption}
                    options={[allOption, ...dataCard]}
                    onChange={(e) => setSelected({ ...selected, card_id: e?.value!, vendor_id: "" })}
                />
            </div>
            <div className='flex items-center ml-1 mt-2 lg:mt-0'>
                <div className="lg:mt-0 lg:px-2 pb-1 label-text font-bold w-[142px] lg:w-full">TRADER</div>
                <Select
                    className="w-[150px]"
                    placeholder="Select Location"
                    isLoading={isLoadingTrade}
                    value={selected?.vendor_id !== "" ? [allOption, ...sortedSelectTrader].find((item) => item.value?.toString() === selected?.vendor_id?.toString()) : allOption}
                    options={[allOption, ...dataTrade]}
                    onChange={(e) => setSelected({ ...selected, vendor_id: e?.value! })}
                    isDisabled={!Boolean(selected?.card_id)}
                />
            </div>
        </div>
    </section>
  )
}