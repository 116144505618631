import { Fragment } from "react"
import { ActionButton, Button, Modal } from "components"
import { useToggle } from "utils"

type DeleteConfirmationProps = {
  onDelete: () => void
}

export function DeleteConfirmation({ onDelete }: DeleteConfirmationProps) {
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <ActionButton.Delete type="button" onClick={toggle} />

      {isActive && (
        <Modal
          isOpen
          title="DELETE DETAIL PURCHASE ORDER"
          closeModal={toggle}
        >
          <Modal.Body>
            <div className="text-error">Are you sure want to delete this data?</div>
          </Modal.Body>

          <Modal.Footer className="flex justify-end gap-3">
            <Button
              color="error"
              onClick={() => {
                onDelete()
                toggle()
              }}
            >
              DELETE
            </Button>

            <Button
              color="ghost"
              onClick={toggle}
            >
              CANCEL
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Fragment>
  )
}