// Lightbox
import Lightbox from "yet-another-react-lightbox"
import { Fullscreen, Slideshow, Thumbnails, Zoom } from "yet-another-react-lightbox/plugins"
import "yet-another-react-lightbox/styles.css"
import "yet-another-react-lightbox/plugins/thumbnails.css"

export function ImageGallery(props: {
  activeIndex: number
  isOpen?: boolean
  images: { src: string }[]
  onClose: () => void
}) {
  return (
    <Lightbox
      index={props.activeIndex}
      open={props.isOpen}
      slides={props.images}
      close={props.onClose}
      plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
    />
  )
}