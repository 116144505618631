import { Fragment } from "react"
import { ChildItem } from "../.."
// import { Delete, Detail, Update } from "./components"
import type { DataItem, FormData } from "../../../../types"
import { useToggle } from "../../../../../../../../utils"
// import { dataContext } from "pages/Setup/Preference/Bank"
// import { TableNumber } from "components"
// import { IsActive } from "./components/IsActive"

export function Item(props: { item: FormData, index: number, dataList: DataItem, refetch: () => void}) {
  const { isActive } = useToggle(false)
  // const { currentPage, limit, size, total} = useContext(dataContext)

  return (
    <Fragment>
      <tr>
      {/* <td>{TableNumber({dataLength: size, pageCurrent: currentPage, index: props.index, limit: limit, totalData: total})}</td>
        <td>{props.item.mr_bin_code}</td>
        <td>{props.item.mr_bin_name}</td>
        <td>{props.item.description}</td>
        <td>
        <div className="flex items-center">
          <Detail id={props.item} />
          <Update id={props.item} refetch={props.refetch} />
            <Delete id={props.item} refetch={props.refetch} />
            <IsActive id={props.item} refetch={props.refetch} />
          </div>
        </td> */}
      </tr>
      {isActive && <ChildItem dataList={props.dataList} refetch={props.refetch} />}
    </Fragment>
  )
}
