import { useContext, useEffect } from "react"
import { Button, Modal } from "../../../../../components"
import { FormSection } from "./FormSection"
import * as yup from "yup"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import toast from "react-hot-toast"
import type { ListDepartement } from "../types"
import { useApi } from "../../../../../utils"
import { Render, useCode } from "../utils"

export function CreateModal(props: {
  department_code?: string
  department_name?: string
  description?: string
  toggle: () => void
  refetch?: () => void
}) {

  const api = useApi()
  const { toggle } = useContext(Render)
  const { dataCode} = useCode()

  useEffect(() => {
    if (dataCode?.code) {
      methods.setValue("department_code", dataCode?.code)
    }
 // eslint-disable-next-line
  }, [dataCode?.code])

  type CreateListCategoryVendor = Omit<ListDepartement, "department_id"> 

  const defaultValues:CreateListCategoryVendor= {
    department_code: "",
    department_name: "",
    description: ""
  }
  const validationSchema = yup.object().shape({
    department_code: yup.string().label("Department code").required().max(3, 'Maximum code is 3 characters'),
    department_name: yup.string().label("Department name").required(),
    description: yup.string().label("Department name").optional(),
  })
  const onSubmit = (value: CreateListCategoryVendor) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.post("/department/add", value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => {
            if (err.response && err.response.status === 400) {
              return err.response.data.message || "Bad Request";
            } else {
              return "An error occurred";
            }
          }
        }
      ).then(() => {
        props.toggle()
        toggle()
        props?.refetch && props.refetch()
      }).catch(() => {}).finally(resolve)
    })
  }
  const methods = useForm<CreateListCategoryVendor>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })

  return (
    <Modal isOpen title="CREATE DEPARTEMENT" size="xl" closeModal={props.toggle}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <FormSection />

          <Modal.Footer className="text-end">
            <Button type="submit" color="primary" loading={methods.formState.isSubmitting ? "true" : undefined}>Save</Button>
          </Modal.Footer>
        </form>
      </FormProvider>
    </Modal>
  )
}