import { BaseTable } from "components";

export function Journal() {
  return (
    <BaseTable>
      <thead>
        <tr>
          <th>DATE</th>
          <th>IDM</th>
          <th>ACCOUNT</th>
          <th>DEBET</th>
          <th>CREDIT</th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td colSpan={5} />
        </tr>
      </tbody>
    </BaseTable>
  )
}