/* eslint-disable react-hooks/exhaustive-deps */
import { Label, Select } from 'components';
import { useTrader } from 'pages/Finance/Transaction/components/widgets/FormSection/components/Trader/utils';
import { GroupName } from 'pages/Finance/Transaction/types';
import { group_list } from 'pages/Finance/Transaction/utils';
import { Ref, useEffect } from 'react';
import { SingleValue } from 'react-select';
import { SelectOptionType } from 'types';

type DropdownCardProps = {
  ref?: Ref<unknown>;
  label?: string;
  error?: string;
  isLoading?: boolean;
  value: string | null;
  groupCardId: GroupName
  onChangeGroup?: (item: SingleValue<SelectOptionType>) => void;
  onChange?: (item: SingleValue<SelectOptionType>) => void;
};

export function DropdownCard({
  ref,
  label,
  error,
  isLoading,
  groupCardId,
  onChangeGroup,
  onChange,
}: DropdownCardProps) {
  const trader = useTrader();

  useEffect(() => {
    if (groupCardId) {
      trader.refetch(groupCardId)
    }

  }, [groupCardId])

  return (
    <section className="w-full">
      {label ? <Label text={label} error={Boolean(error)} /> : null}

      <section className="flex gap-3 w-full">
        <div className="w-full md:w-5/12">
          <Select
            className="grow"
            placeholder="Select Group Card"
            error={error}
            isLoading={isLoading}
            options={group_list}
            ref={ref}
            onChange={(item) => {
              if (onChangeGroup) {
                onChangeGroup(item);
              }
            }}
          />
        </div>

        <div className="w-full md:w-5/12">
          <Select
            key={groupCardId}
            className="grow"
            isDisabled={trader.isLoading || !groupCardId}
            placeholder="Select Trader"
            error={error}
            isLoading={isLoading}
            options={trader.data ?? []}
            ref={ref}
            onChange={(item: any) => {
              if (onChange) {
                onChange(item);
              }
            }}
          />
        </div>
      </section>
    </section>
  );
}
