/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react"
import { SuccessFetch } from "types"
import { useApi } from "utils"
import { DetailPaymentInvoiceType } from "../../types"

type UseDetailPaymentInvoice = {
  payment_invoice_id: string
  onSuccess?: (data: DetailPaymentInvoiceType) => void
}

type Response = {
  payload: {
    data: DetailPaymentInvoiceType
  }
}

export function useDetailPaymentInvoice({ payment_invoice_id, onSuccess }: UseDetailPaymentInvoice) {
  const api = useApi()
  const [data, setData] = useState<DetailPaymentInvoiceType | null>(null)
  const [isLoading, setLoading] = useState(false)

  const fetchData = () => {
    setLoading(true)

    const params = {
      payment_invoice_id,
    }

    api
      .get("/paymentinvoice/detail", { params })
      .then((res: SuccessFetch<Response>) => {
        const data = res.data.payload.data
        onSuccess?.(data)
        setData(data)
      })
      .catch(() => setData(null))
      .finally(() => setLoading(false))
  }

  const refetch = () => fetchData()

  useEffect(() => {
    if (payment_invoice_id) {
      fetchData()
    }

    return () => {
      setData(null)
      setLoading(false)
    }
  }, [payment_invoice_id])

  return { data, isLoading, refetch }
}
