import { Fragment } from "react";
import { IoSendSharp } from "react-icons/io5";
import { PermissionLink, TableNumber } from "components";
import { AgedType, FilterType } from "../types";
import moment from "moment";

export function Item(props: {
    data: AgedType
    index: number,
    dataPaging: Pick<FilterType, 'page' | 'limit' | 'pages' | 'total' >
}) {

    return (
        <Fragment>
            <tr >
                <td>{TableNumber({ dataLength: props.dataPaging.limit , pageCurrent: props.dataPaging.page, index: props.index, limit: props.dataPaging.limit, totalData: props.dataPaging.total })}</td>
                <td>
                    <PermissionLink permission="SR063" target="_blank" rel="noopener noreferrer" to={`/sales/transaction/invoice/edit/${props.data.invoice_id}`} >
                        <IoSendSharp />
                    </PermissionLink>
                </td>
                <td>{props.data.trx_code}</td>
                <td>{props.data.trx_code_so}</td>
                <td>{moment(props.data.transaction_date).format("DD/MM/YYYY")}</td>
                <td>{props.data.customer.customer_name}</td>
                <td className="text-right">{props.data.balance}</td>
                <td className="text-right">{props.data.aged_receivable <= 7 ? props.data.balance : ''}</td>
                <td className="text-right">{props.data.aged_receivable > 7 && props.data.aged_receivable <= 15 ? props.data.balance : ''}</td>
                <td className="text-right">{props.data.aged_receivable > 15 && props.data.aged_receivable <= 21 ? props.data.balance : ''}</td>
                <td className="text-right">{props.data.aged_receivable > 21 ? props.data.balance : ''}</td>
            </tr>
        </Fragment>
    )
}