import { Fragment } from "react"
import { Card, HeaderMenu, Loading } from "../../../../components"
import { useList } from "./utils"
import { ExpensesActivites, PayableTable, ReceivableTable, StatementInfo } from "./components"

export default function Dashboard() {
  const { data, isLoading } = useList()

  if (isLoading) {return <section><div className="flex justify-center py-[25%]"><Loading loading /></div></section>}

  return (
    <Fragment>
      <section className="container my-5">
          <section>
            <HeaderMenu title="FINANCE DASHBOARD" />

            <section className="mt-2">
              <Card>
                <Card.Body>
                  <Card.Title>
                    <div className="lg:flex">
                      <StatementInfo data={data} />

                      <div className="my-5 label-text lg:w-[66%]">
                        <ReceivableTable data={data} />
                        <PayableTable data={data} />
                      </div>
                    </div>

                    <ExpensesActivites data={data} />
                  </Card.Title>
                </Card.Body>
              </Card>
            </section>
          </section>
      </section>
    </Fragment>
  )
}