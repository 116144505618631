// React
import { PropsWithChildren, createContext, useEffect } from "react"

// Form
import { useFormContext, useWatch } from "react-hook-form"

// Types
import type { LocationProductType } from "../types"

// Utils
import { type FormType, useLocationProduct } from "../utils"

export const LocationProductContext = createContext<{
  data: LocationProductType[]
  isLoading: boolean
}>({
  data: [],
  isLoading: false
})

export function LocationProductProvider(params: PropsWithChildren<{}>): JSX.Element {
  // Form
  const { control } = useFormContext<FormType>()
  const location_id: string = useWatch({
    control,
    name: "location_id"
  })
  const transaction_date: string = useWatch({
    control,
    name: "transaction_date"
  })

  // Hooks
  const { data, isLoading, refetch } = useLocationProduct()

  useEffect(() => {
    if (location_id && transaction_date) {
      refetch(location_id, transaction_date)
    }

    // eslint-disable-next-line
  }, [location_id, transaction_date])

  return (
    <LocationProductContext.Provider
      value={{
        data: data,
        isLoading: isLoading
      }}
    >
      {params.children}
    </LocationProductContext.Provider>
  )
}