import { HeaderMenu } from "components"
import { Fragment } from "react"
import { useSearchParams } from "react-router-dom"
import {
  RegisterDateRange,
  TabAgedReceivable,
  TabNavRegister,
} from "./components"
import { TabRegisterPath } from "./types"
import SalesOrderRegisterInvoice from "../Transaction/SalesOrderRegisterInvoice"
import { TabByCard } from "./components/TabByCard"
import { TabLocation } from "./components/TabLocation"
import { TabReference } from "./components/TabReference"

type TabsSearchParams = {
  tabs: TabRegisterPath
}

export default function SalesRegister() {
  const [searchParams, setSearchParams] = useSearchParams({
    tabs: "by-date-range"
  } as TabsSearchParams)

  const activeTab = searchParams.get("tabs")

  const setActiveTab = (path: string) => setSearchParams({ tabs: path })

  return (
    <Fragment>
      <section className="container my-5">
        <HeaderMenu title="SALES REGISTER" />

        <section className="mt-1">
          <TabNavRegister activeTab={activeTab!} setActiveTab={setActiveTab} />
          {activeTab === "by-date-range" && <RegisterDateRange />}
          {activeTab === "all-invoice" && <SalesOrderRegisterInvoice />}
          {activeTab === "aged-receivable" && <TabAgedReceivable />}
          {activeTab === "by-card" && <TabByCard />}
          {activeTab === "by-location" && <TabLocation />}
          {activeTab === "reference-id" && <TabReference />}
          {/* {activeTab === "by-card" && <RegisterCard />}
          {activeTab === "by-location" && <RegisterLocation />}
          {activeTab === "reference-id" && <RegisterReference />} */}
        </section>
      </section>
    </Fragment>
  )
}
