import { ErrorMessage } from "@hookform/error-message"
import { ActionButton, BaseTable, Button } from "components"
import {
  useProjectList,
  useTaxCodeList
} from "pages/Finance/Transaction/GeneralEntry/utils/hooks"
import { useDropdownProduct } from "pages/Sales/Transaction/CustomerOrder/utils/hooks"
import { useState } from "react"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { IoSendSharp } from "react-icons/io5"
import { convertNumber } from "utils"
import { FormReturnItemsType } from "../../../types"
import { RowInvoiceReturnItems } from "./RowInvoiceReturnItems"

type TableInvoiceReturnItemsProps = {
  // data:
  //   | Array<FormInvoiceType & { id: string }>
  //   | Array<FormSalesOrderDetailType & { id: string }>
  approvalView?: boolean
  data: any[]
  display?: boolean
  displayArrowNavigation?: boolean
  onClickArrowNavigation?: (item: any) => void
  onClickDeleteSOInvoice?: (key: number) => void
}

export const TableInvoiceReturnItems = ({
  approvalView,
  data,
  display = true,
  displayArrowNavigation = false,
  onClickArrowNavigation,
  onClickDeleteSOInvoice
}: TableInvoiceReturnItemsProps) => {
  const methods = useFormContext<FormReturnItemsType>()
  const [editField, setEditField] = useState<number | null>(null)
  const [price_level_name, location_id] = useWatch({
    control: methods.control,
    name: ["price_level_name", "location_id"]
  })
  const tax = useTaxCodeList()
  const jobs = useProjectList()
  const products = useDropdownProduct({ location_id })

  return (
    <div className={`${display ? "" : "hidden"}`}>
      <BaseTable>
        <thead className="uppercase">
          <tr>
            <th
              colSpan={displayArrowNavigation ? 2 : 1}
              className={displayArrowNavigation ? "" : "hidden"}>
              Delivered
            </th>
            <QtyReturnHeader
              displayArrowNavigation={displayArrowNavigation}
            />
            <th>Item Code</th>
            <th>Description</th>
            <th>Unit</th>
            <th>Price</th>
            <th>Disc (%)</th>
            <th>Total</th>
            <th>Tax</th>
            <th className={(displayArrowNavigation || approvalView) ? "hidden" : ""}>ACTION</th>
          </tr>
        </thead>

        <tbody>
          {data?.map((d, key) => {
            return (
              <Controller
                key={d.id}
                control={methods.control}
                name={
                  displayArrowNavigation
                    ? `invoice_detail.${key}`
                    : `return_detail.${key}`
                }
                render={({ field }) => {
                  if (editField === key) {
                    return (
                      <RowInvoiceReturnItems
                        key={d.id}
                        tax={tax}
                        jobs={jobs}
                        action="edit"
                        priceLevelName={price_level_name}
                        products={products}
                        defaultValues={field.value}
                        onSubmit={(value) => {
                          field.onChange(value)
                          setEditField(null)
                        }}
                        onCancel={() => setEditField(null)}
                      />
                    )
                  } else {
                    return (
                      <tr key={d.id}>
                        <td className={`text-center px-0 ${(displayArrowNavigation) ? "" : "hidden"}`}>
                          <Button
                            className={approvalView ? "hidden" : ""}
                            disabled={approvalView}
                            size="xs"
                            onClick={() =>
                              onClickArrowNavigation?.(field.value)
                            }>
                            <IoSendSharp className="size-4 -rotate-90" />
                          </Button>
                        </td>
                        <td className="text-right">
                          {field.value?.quantity_invoice}
                        </td>
                        <QtyReturnField
                          value={field.value}
                          displayArrowNavigation={displayArrowNavigation}
                        />
                        <td>{field.value?.product_barcode}</td>
                        <td>{field.value?.product_name}</td>
                        <td>{field.value?.mr_unit_name}</td>
                        <td className="text-right">
                          {
                            convertNumber(field.value?.price_invoice ?? 0)
                              .intoCurrency
                          }
                        </td>
                        <td className="text-right">{field.value?.discount}</td>
                        <td className="text-right">
                          {convertNumber(field.value?.total ?? 0).intoCurrency}
                        </td>
                        <td>{field.value?.tax_name}</td>
                        <td className={(displayArrowNavigation || approvalView) ? "hidden" : ""}>
                          <section
                            className="flex justify-center">
                            <ActionButton.Update
                              onClick={() => setEditField(key)}
                            />

                            <ActionButton.Delete
                              type="button"
                              onClick={() => {
                                onClickDeleteSOInvoice?.(key)
                              }}
                            />
                          </section>
                        </td>
                      </tr>
                    )
                  }
                }}
              />
            )
          })}
        </tbody>
      </BaseTable>

      {!displayArrowNavigation && (
        <ErrorMessage
          errors={methods.formState.errors}
          name="invoice_detail"
          render={({ message }) => <p className="text-red-500">{message}</p>}
        />
      )}
    </div>
  )
}

const QtyReturnHeader = ({
  displayArrowNavigation
}: Pick<TableInvoiceReturnItemsProps, "displayArrowNavigation">) => {
  if (displayArrowNavigation) {
    return (
      <>
        <th>Qty Returned</th>
        <th>Back Delivered</th>
      </>
    )
  }

  return (
    <>
      <th>Back Delivered</th>
      <th>Qty Returned</th>
    </>
  )
}

const QtyReturnField = ({
  displayArrowNavigation,
  value
}: Pick<TableInvoiceReturnItemsProps, "displayArrowNavigation"> & {
  value: any
}) => {
  if (displayArrowNavigation) {
    return (
      <>
        <td className="text-right">{value?.quantity_returned}</td>
        <td className="text-right">
          {value?.quantity_invoice - value?.quantity_returned}
        </td>
      </>
    )
  }

  return (
    <>
      <td className="text-right">{value?.quantity_returned}</td>
    </>
  )
}
