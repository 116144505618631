type GenerateLinkTransactionParams = {
  data_source: number;
  data_source_id: number;
};

export const generateLinkTransaction = ({
  data_source,
  data_source_id,
}: GenerateLinkTransactionParams) => {
  switch (data_source) {
    case 1:
      return `/finance/transaction/spend-money/edit/${data_source}/${data_source_id}`;
    case 2:
      return `/finance/transaction/receive-money/edit/${data_source}/${data_source_id}`;
    case 3:
      return `/finance/transaction/general-entry/edit/${data_source}/${data_source_id}`;
    case 4:
      return `/finance/transaction/transfer-money/edit/${data_source}/${data_source_id}`;
    default:
      return '/';
  }
};
