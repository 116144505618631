import * as yup from "yup"

const baseValidation = {
    approve_date: yup.string().label("Approve Date").required(),
    employee_id: yup.number().label("Employee Name").required(),
    job_position_id: yup.number().label("Job Position").required(),
    approve_status : yup.number().label("Approve Status").required(),
    // employee_name : yup.string().label("Employee Name").optional(),
    // job_position_name : yup.string().label("Job Position").optional(),
}

export const validationSchema = yup.object().shape(baseValidation)

export type FormType = yup.InferType<typeof validationSchema>