// React
import { type PropsWithChildren, createContext, useState, useEffect } from "react"

export const ThemeContext = createContext<{
  theme: string
  handleTheme: (e: React.ChangeEvent<HTMLInputElement>) => void
}>({
  theme: "",
  handleTheme(e) {}
})

export function ThemeProvider(params: PropsWithChildren<{}>): JSX.Element {
  // Hooks
  const [theme, setTheme] = useState<string>(localStorage.getItem("theme") ? localStorage.getItem("theme")! : "light")

  useEffect(() => {
    localStorage.setItem("theme", theme)
    const localTheme = localStorage.getItem("theme")!
    // add custom data-theme attribute to html tag required to update theme using DaisyUI
    document.querySelector("html")!.setAttribute("data-theme", localTheme)
  }, [theme])

  // Functions
  const handleTheme = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setTheme("dark")
    } else {
      setTheme("light")
    }
  }

  return (
    <ThemeContext.Provider value={{
      theme,
      handleTheme
    }}>
      {params.children}
    </ThemeContext.Provider>
  )
}