/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react"
import { SuccessFetch } from "types"
import { useApi } from "utils"
import { DetailReturn } from "../../types"

type UseDetailReturnItem = {
  return_id: string
  onSuccess?: (data: DetailReturn) => void
}

type Response = {
  payload: DetailReturn
}

export function useDetailReturnItem({
  return_id,
  onSuccess
}: UseDetailReturnItem) {
  const api = useApi()
  const [data, setData] = useState<DetailReturn | null>(null)
  const [isLoading, setLoading] = useState(false)

  const fetchData = () => {
    setLoading(true)
    const params = {
      return_id
    }

    api
      .get("/return/detailreturn", { params })
      .then((res: SuccessFetch<Response>) => {
        const data = res.data.payload
        onSuccess?.(data)
        setData(data)
      })
      .catch(() => setData(null))
      .finally(() => setLoading(false))
  }

  const refetch = () => fetchData()

  useEffect(() => {
    if (return_id) {
      fetchData()
    }

    return () => {
      setData(null)
      setLoading(false)
    }
  }, [return_id])

  return { data, isLoading, refetch }
}
