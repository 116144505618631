// Components
import { PermissionChecker } from "components"

// Types
import type { RouteType } from "types"

export function routeMapper(route: RouteType[]): {
  element: JSX.Element
  children: {
      path: string
      element: JSX.Element
  }[]
}[] {
  return route.map(item => {
    return {
      element: <PermissionChecker permission={item.permission} />,
      children: [
        {
          path: item.path,
          element: item.element
        }
      ]
    }
  })
}