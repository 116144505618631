// React
import { createContext } from "react"

// Types
import type { BillType } from "../types"

export const DataContext = createContext<BillType>({
  datapo: {
    address: "",
    company_code: "",
    delivered: 0,
    freight: 0,
    location_id: "",
    location_name: "",
    memo: "",
    payment_term: "",
    po_delivery_status: 0,
    po_payment_status: 0,
    po_status: 0,
    promize_date: "",
    purchase_order_id: "",
    referensi: "",
    tax_inclusive: false,
    transaction_date: "",
    trx_code: "",
    vendor_code: "",
    vendor_id: "",
    vendor_name: ""
  },
  detailbill: [],
  reminder: null
})