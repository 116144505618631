import { useEffect } from "react"
import { Input, Textarea, Select, Label } from "../../../../../../components"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import type { DataList } from "../../types"
import { useCountry, useProvince, useRegency, useSubdistrict, useVillage } from "./utils"

export function FormSection(props: { isDetail?: boolean; isUpdate?: boolean; action?: string }) {
  const { control } = useFormContext<DataList>()

  return (
    <table className="table">
      <tbody>
        <Controller
          control={control}
          name="project_name"
          render={({ field, fieldState }) => 
            <tr>
              <td><Label text="PROJECT" error={Boolean(fieldState.error)} /></td>
              <td><Input {...field} placeholder="Input Project Name" value={field.value ?? ""} disabled={props.isDetail} error={fieldState.error?.message} /></td>
            </tr>
          }
        />
  
        <Country isDisabled={Boolean(props.isDetail)} />
        <Province isDisabled={Boolean(props.isDetail)} />
        <Regency isDisabled={Boolean(props.isDetail)} />
        <Subdistrict isDisabled={Boolean(props.isDetail)} />
        <Village isDisabled={Boolean(props.isDetail)} />

        <Controller
          control={control}
          name="address"
          render={({ field, fieldState }) => 
            <tr>
              <td><Label text="ADDRESS" error={Boolean(fieldState.error)} /></td>
              <td><Textarea {...field} placeholder="Input Address" value={field.value ?? ""} disabled={props.isDetail} error={fieldState.error?.message} /></td>
            </tr>
          }
        />
        <Controller
          control={control}
          name="note"
          render={({ field }) => 
            <tr>
              <td><Label text="NOTE" /></td>
              <td><Textarea {...field} placeholder="Input Note" value={field.value ?? ""} disabled={props.isDetail} /></td>
            </tr>
          }
        />
      </tbody>
    </table>
  )
}

function Country(props: { isDisabled: boolean }) {
  const { control } = useFormContext<DataList>()
  const { data, isLoading } = useCountry()

  return (
    <Controller
      control={control}
      name="country_id"
      render={({ field, fieldState }) => (
        <tr>
          <td>
            <Label
              text="COUNTRY"
              error={Boolean(fieldState.error)}
            />
          </td>
          <td>
            <Select
              placeholder="Choose country"
              isDisabled={props.isDisabled}
              isLoading={isLoading}
              error={fieldState.error?.message}
              options={data}
              value={data.find(item => item.value === field.value?.toString())}
              onChange={e => field.onChange(e?.value)}
            />
          </td>
        </tr>
      )}
    />
  )
}
function Province(props: { isDisabled: boolean }) {
  const { control } = useFormContext<DataList>()
  const country_id = useWatch({ control, name: "country_id" })
  const { data, isLoading, refetch } = useProvince()

  useEffect(() => {
    if(country_id) { refetch(country_id?.toString()) }
    // eslint-disable-next-line
  }, [country_id])

  return (
    <Controller
      control={control}
      name="province_id"
      render={({ field, fieldState }) => (
        <tr>
          <td>
            <Label
              text="PROVINCE"
              error={Boolean(fieldState.error)}
            />
          </td>
          <td>
            <Select
              placeholder="Choose province"
              isDisabled={props.isDisabled}
              isLoading={isLoading}
              error={fieldState.error?.message}
              options={data}
              value={data.find(item => item.value === field.value?.toString())}
              onChange={e => field.onChange(e?.value)}
            />
          </td>
        </tr>
      )}
    />
  )
}
function Regency(props: { isDisabled: boolean }) {
  const { control } = useFormContext<DataList>()
  const province_id = useWatch({ control, name: "province_id" })
  const { data, isLoading, refetch } = useRegency()

  useEffect(() => {
    if(province_id) { refetch(province_id?.toString()) }
    // eslint-disable-next-line
  }, [province_id])

  return (
    <Controller
      control={control}
      name="regency_id"
      render={({ field, fieldState }) => (
        <tr>
          <td>
            <Label
              text="REGENCY"
              error={Boolean(fieldState.error)}
            />
          </td>
          <td>
            <Select
              placeholder="Choose regency"
              isDisabled={props.isDisabled}
              isLoading={isLoading}
              error={fieldState.error?.message}
              options={data}
              value={data.find(item => item.value === field.value?.toString())}
              onChange={e => field.onChange(e?.value)}
            />
          </td>
        </tr>
      )}
    />
  )
}
function Subdistrict(props: { isDisabled: boolean }) {
  const { control } = useFormContext<DataList>()
  const regency_id = useWatch({ control, name: "regency_id" })
  const { data, isLoading, refetch } = useSubdistrict()

  useEffect(() => {
    if(regency_id) { refetch(regency_id?.toString()) }
    // eslint-disable-next-line
  }, [regency_id])

  return (
    <Controller
      control={control}
      name="subdistrict_id"
      render={({ field, fieldState }) => (
        <tr>
          <td>
            <Label
              text="SUBDISTRICT"
              error={Boolean(fieldState.error)}
            />
          </td>
          <td>
            <Select
              placeholder="Choose subdistric"
              isDisabled={props.isDisabled}
              isLoading={isLoading}
              error={fieldState.error?.message}
              options={data}
              value={data.find(item => item.value === field.value?.toString())}
              onChange={e => field.onChange(e?.value)}
            />
          </td>
        </tr>
      )}
    />
  )
}
function Village(props: { isDisabled: boolean }) {
  const { control } = useFormContext<DataList>()
  const subdistrict_id = useWatch({ control, name: "subdistrict_id" })
  const { data, isLoading, refetch } = useVillage()

  useEffect(() => {
    if(subdistrict_id) { refetch(subdistrict_id?.toString()) }
    // eslint-disable-next-line
  }, [subdistrict_id])

  return (
    <Controller
      control={control}
      name="village_id"
      render={({ field, fieldState }) => (
        <tr>
          <td>
            <Label
              text="VILLAGE"
              error={Boolean(fieldState.error)}
            />
          </td>
          <td>
            <Select
              placeholder="Choose village"
              isDisabled={props.isDisabled}
              isLoading={isLoading}
              error={fieldState.error?.message}
              options={data}
              value={data.find(item => item.value === field.value?.toString())}
              onChange={e => field.onChange(e?.value)}
            />
          </td>
        </tr>
      )}
    />
  )
}