/* eslint-disable react-hooks/exhaustive-deps */
import { Button, HeaderMenu, Input } from "components"
import { APPROVAL } from "pages/Sales/Register/utils/vars"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { useToggle } from "utils"
import { FormTransferItemsType } from "../types/FormTransferItems"
import { CodeTransferItems } from "./CodeTransferItems"
import { CommandButtons } from "./CommandButtons"
import { FormTransferItems } from "./FormTransferItems"
import { ModalDelete } from "./ModalDelete"
import { TableTransferItem } from "./Tables/TransferItem"

export type FormSectionProps = {
  approvalView?: boolean
  isEdit?: boolean
  onSubmit: () => void
}

export const FormSection = ({
  approvalView,
  isEdit = false,
  onSubmit
}: FormSectionProps) => {
  const navigate = useNavigate()
  const methods = useFormContext<FormTransferItemsType>()
  const modalDelete = useToggle(false)

  const [
    transfer_id,
    transaction_date,
    trx_code,
    location_from_name,
    location_to_name,
    transfer_detail,
    memo,
    employee_name,
    transfer_status,
    approval_status
  ] = useWatch({
    control: methods.control,
    name: [
      "transfer_id",
      "transaction_date",
      "trx_code",
      "location_from_name",
      "location_to_name",
      "transfer_detail",
      "memo",
      "employee_name",
      "transfer_status",
      "approval_status"
    ]
  })

  const totalTransfer = transfer_detail.reduce(
    (sum, item) => sum + (item.quantity_transfer ?? 0),
    0
  )

  const deleteData = {
    transfer_id: transfer_id ?? NaN,
    transaction_date: transaction_date ?? "",
    trx_code: trx_code ?? "",
    location_from_name: location_from_name ?? "",
    location_to_name: location_to_name ?? "",
    employee_name: employee_name ?? "",
    memo: memo ?? "",
    quantity_transfer: totalTransfer ?? 0
  }

  return (
    <section className="container my-5 flex flex-col gap-5">
      <HeaderMenu title="DATA ENTRY | TRANSFER">
        <CodeTransferItems isEdit={isEdit} />
      </HeaderMenu>

      <FormTransferItems approvalView={approvalView} />

      <TableTransferItem display approvalView={approvalView} />

      <section className="grid lg:grid-cols-2 gap-x-6 gap-y-3">
        <div className="flex flex-col gap-3 flex-grow">
          <Controller
            control={methods.control}
            name="approval_status"
            render={({ field, fieldState }) => (
              <Input
                disabled
                label="APPROVE STATUS"
                value={APPROVAL[field.value]}
                ref={field.ref}
                onChange={field.onChange}
                error={fieldState.error?.message}
              />
            )}
          />

          <Controller
            control={methods.control}
            name="approved_by_name"
            render={({ field, fieldState }) => (
              <Input
                disabled
                label="APPROVE BY"
                value={field.value}
                ref={field.ref}
                onChange={field.onChange}
                error={fieldState.error?.message}
              />
            )}
          />
        </div>

        <div className="flex flex-col justify-start items-end gap-3">
          <span className="font-bold text-sm py-[0.5rem]">
            TOTAL TRANSFER : {totalTransfer}
          </span>

          {APPROVAL.APPROVE && transfer_status === 2 ? (
            <Button
              disabled={approvalView}
              onClick={() =>
                navigate(`/inventory/transaction/received/${transfer_id}`)
              }>
              RECEIVED
            </Button>
          ) : null}
        </div>
      </section>

      <CommandButtons
        approvalView={approvalView}
        showSaveButton={approval_status !== APPROVAL.APPROVE}
        showDeleteButton={isEdit && approval_status !== APPROVAL.APPROVE}
        onDelete={() => modalDelete.setActive(true)}
        onSave={onSubmit}
      />

      <ModalDelete
        modalState={modalDelete}
        data={deleteData}
        onSuccess={() => {
          modalDelete.setActive(false)
          navigate("/sales/register", { replace: true })
        }}
      />
    </section>
  )
}
