import { Controller, useFormContext, useWatch } from "react-hook-form"
import { SellingCreateProps, SellingList, SellingProps } from "../../types"
import { Button, Input } from "../../../../../../components"
import { CostOfGodManufactur, DisableField, PriceLevel, PriceLevelCreate, SalesTax } from "../ChildItem/components/Item/components"
import { convertNumber } from "utils"

export const FormSelling = ({ action, checkLoading, setCheckLoading, onSubmit, discountAmound, buyingPriceValue, componentCostValue, productId }: SellingProps) => {
    const { control, formState } = useFormContext<SellingList>()

    const buyingPrice = useWatch({ control, name: 'price_unit_convention' })
    const componentAtCost = useWatch({ control, name: 'component_at_cost' })

    const totalProductionCost = parseFloat(String(buyingPriceValue ? buyingPriceValue : buyingPrice)) + parseFloat(String(componentCostValue ? componentCostValue : componentAtCost))

    return (
        <div>
            <div className="text-[16px] text-primary mb-10"><b>CALCULATE SELLING PRICE </b></div>
            <div className="grid grid-cols-2 gap-3 overflow-x-auto">
                <div>
                    <div className="flex justify-between items-center mb-2">
                        <div><b>BUYING PRICE</b></div>
                        <Controller
                            control={control}
                            name="price_unit_convention"
                            render={({ field }) => <> { action === "DETAIL" ?
                                <Input
                                    {...field}
                                    type="text"
                                    value={buyingPriceValue
                                        ? convertNumber(parseFloat(buyingPriceValue))?.intoCurrency
                                        : field.value ? convertNumber(parseFloat(field?.value))?.intoCurrency : 0
                                    }
                                    disabled
                                /> :
                                <DisableField>
                                    {buyingPriceValue
                                        ? (parseFloat(buyingPriceValue)).toLocaleString("id-ID", { maximumFractionDigits: 0 })?.replace(/\./g, ",")
                                        : field.value ? convertNumber(parseFloat(field?.value))?.intoCurrency : 0
                                    }
                                </DisableField>
                            } </> }
                        />
                    </div>
                    <div className="flex justify-between items-center mb-2">
                        <div><b>COMPONENT AT COST</b></div>
                        <Controller
                            control={control}
                            name="component_at_cost"
                            render={({ field }) => <> { action === "DETAIL" ?
                                <Input 
                                    {...field} 
                                    type="text" 
                                    value={componentCostValue
                                        ? convertNumber(parseFloat(componentCostValue))?.intoCurrency
                                        : field.value ? convertNumber(parseFloat(field?.value))?.intoCurrency : 0
                                    } 
                                    disabled 
                                /> :
                                <DisableField>
                                    {componentCostValue
                                        ? convertNumber(parseFloat(componentCostValue))?.intoCurrency
                                        : field.value ? convertNumber(parseFloat(field?.value))?.intoCurrency : 0
                                    }
                                </DisableField>
                            } </> }
                        />
                    </div>
                    <hr className="my-5" />
                    <div className="flex justify-between items-center mb-2">
                        <div><b>TOTAL PRODUCTION COST</b></div>
                        { action === "DETAIL" 
                            ? <Input disabled inputClass="w-[220px]" value={totalProductionCost ? convertNumber(totalProductionCost)?.intoCurrency : 0} />
                            : <DisableField>{totalProductionCost ? convertNumber(totalProductionCost)?.intoCurrency : 0}</DisableField>
                        }
                    </div>
                    <SalesTax action={action} totalValueProduction={totalProductionCost} />
                </div>
                <PriceLevel action={action} totalValueProduction={totalProductionCost} />
            </div>
            <CostOfGodManufactur
                action={action} 
                discountAmound={discountAmound} 
                totalValueProduction={totalProductionCost} 
                checkLoading={checkLoading} 
                setCheckLoading={setCheckLoading} 
                productId={productId} 
            />
            {action !== "DETAIL" &&
                <div className="flex justify-end">
                    <Button 
                        type="button" 
                        color="primary" 
                        className="mt-10"
                        loading={formState.isSubmitting ? "true" : undefined}
                        onClick={onSubmit}
                    >
                        {action === "UPDATE" ? "UPDATE" : "SAVE"}
                    </Button>
                </div>
            }
        </div>
    )
}

export const FormSellingCreate = ({ 
    action, 
    checkLoading, 
    setCheckLoading, 
    onSubmit, 
    discountAmound, 
    buyingPriceValue, 
    componentCostValue, 
    selectedValue, 
    setSelectedValue, 
    productId,
    permission
}: SellingCreateProps) => {
    const { control, formState } = useFormContext<SellingList>()

    const buyingPrice = useWatch({ control, name: 'price_unit_convention' })
    const componentAtCost = useWatch({ control, name: 'component_at_cost' })

    const totalProductionCost = parseFloat(String(buyingPriceValue ? buyingPriceValue === "NaN" ? 0 : buyingPriceValue : buyingPrice)) + parseFloat(String(componentCostValue ? componentCostValue : componentAtCost))

    const Alert = () => (
        <div role="alert" className="alert alert-warning mb-3">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 shrink-0 stroke-current"
                fill="none"
                viewBox="0 0 24 24">
                <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
            </svg>
            <span>Warning: Please input the required data first!</span>
        </div>
    )

    const valueChecking = buyingPriceValue === "NaN" && (componentCostValue === "" || componentCostValue === "0")
    console.log(buyingPrice)
    console.log(componentAtCost)
    console.log(buyingPriceValue)
    console.log(componentCostValue)
    console.log(totalProductionCost)

    return (
        <div>
            <>{valueChecking && <Alert />}</>
            <div className="text-[16px] text-primary mb-10"><b>CALCULATE SELLING PRICE </b></div>
            <div className="grid grid-cols-2 gap-3 overflow-x-auto">
                <div>
                    <div className="flex justify-between items-center mb-2">
                        <div><b>BUYING PRICE</b></div>
                        <Controller
                            control={control}
                            name="price_unit_convention"
                            render={({ field }) => 
                                <DisableField>
                                    {buyingPriceValue
                                        ? convertNumber(parseFloat(buyingPriceValue === "NaN" ? "0" : buyingPriceValue))?.intoCurrency
                                        : field.value ? convertNumber(parseFloat(field?.value))?.intoCurrency : 0
                                    }
                                </DisableField>
                            }
                        />
                    </div>
                    <div className="flex justify-between items-center mb-2">
                        <div><b>COMPONENT AT COST</b></div>
                        <Controller
                            control={control}
                            name="component_at_cost"
                            render={({ field }) => 
                                <DisableField>
                                    {componentCostValue
                                        ? convertNumber(parseFloat(componentCostValue))?.intoCurrency
                                        : field.value ? convertNumber(parseFloat(field?.value))?.intoCurrency : 0
                                    }
                                </DisableField>
                            }
                        />
                    </div>
                    <hr className="my-5" />
                    <div className="flex justify-between items-center mb-2">
                        <div><b>TOTAL PRODUCTION COST</b></div>
                        <DisableField>{totalProductionCost ? convertNumber(totalProductionCost)?.intoCurrency : 0}</DisableField>
                    </div>
                    <SalesTax action={action} totalValueProduction={totalProductionCost} />
                </div>
                <PriceLevelCreate action={action} totalValueProduction={totalProductionCost} selectedValue={selectedValue} setSelectedValue={setSelectedValue} />
            </div>
            <CostOfGodManufactur action={action} discountAmound={discountAmound} totalValueProduction={totalProductionCost} checkLoading={checkLoading} setCheckLoading={setCheckLoading} productId={productId} />
            {action !== "DETAIL" &&
                <div className="flex justify-end">
                    <Button 
                        type="button" 
                        color="primary" 
                        className="mt-10"
                        loading={formState.isSubmitting ? "true" : undefined}
                        onClick={onSubmit}
                        permission={"IT011"}
                        disabled={valueChecking}
                    >
                        {action === "UPDATE" ? "UPDATE" : "SAVE"}
                    </Button>
                </div>
            }
        </div>
    )
}