import React from 'react';

interface AccordionProps { title?: string | React.ReactNode; content: React.ReactNode; defaultChecked?: boolean; className?: string; checkboxType?: boolean }

export const Accordion = ({ title, content, defaultChecked, className, checkboxType }: AccordionProps) => {

  return (
    <div className={`collapse collapse-arrow bg-base-200 mt-2 rounded-[8px] ${className}`}>
      <input type={checkboxType ? "checkbox" : "radio"} name="my-accordion-2" defaultChecked={defaultChecked} />
      <div className="collapse-title text-[16px] font-bold pt-5">{title}</div>
      <div className="collapse-content overflow-x-auto">{content}</div>
    </div>
  );
};
