// React
import { useContext } from "react"

// Components
import { Loading } from "components"
import { InputSection } from "./InputSection"

// Contexts
import { DataContext, ParamsContext } from "../contexts"

// Form
import { FormProvider, useForm } from "react-hook-form"

// Utils
import { type FormType, useDetailReject } from "../utils"

export function Detail(params: {
  bill_id: string
  reject_id: string
}): JSX.Element {
  // Hooks
  const { data, isLoading } = useDetailReject(params.bill_id, params.reject_id)

  if (isLoading || !data) {
    return (
      <Loading
        errorText="No data available"
        loading={isLoading}
      />
    )
  }

  return (
    <DataContext.Provider value={data}>
      <FormSection />
    </DataContext.Provider>
  )
}

function FormSection() {
  // Hooks
  const { data, detail_reject } = useContext(DataContext)

  // Form
  const defaultValues: FormType = {
    trx_code: data.trx_code_reject,
    transaction_date: data.transaction_date,
    memo: data.memo,
    referensi: data.referensi,
    prorejectdetail: detail_reject
  }
  const methods = useForm<FormType>({
    defaultValues
  })

  return (
    <FormProvider {...methods}>
      <ParamsContext.Provider
        value={{ isDisabled: true }}
      >
        <InputSection />
      </ParamsContext.Provider>
    </FormProvider>
  )
}