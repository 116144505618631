// React
import { createContext } from "react"

// Types
import type { DetailType } from "../utils"

export const ListContext = createContext<{
  item: DetailType
  index: number
}>({
  index: 0,
  item: {
    discount: 0,
    price_reject: 0,
    product_id: 0,
    quantity_bill: 0,
    quantity_rate: 0,
    quantity_reject: 0,
    tax_id: 0,
    tax_rate: 0
  }
})