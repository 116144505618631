import { FormSalesOrderType, SubmitType } from "../../types"

export const submitMap = (data: FormSalesOrderType): SubmitType => {
  return {
    sales_order_id: data?.sales_order_id
      ? Number(data.sales_order_id)
      : undefined,
    customer_id: data.customer_id,
    payment_term: data.payment_term,
    tax_inclusive: data.tax_inclusive,
    location_id: data.location_id,
    memo: data.memo,
    address: data.shipped,
    transaction_date: data.transaction_date,
    referensi: data.referensi,
    promize_date: data.promize_date,
    freight: data?.freight,
    approved_by: data.approved_by,
    employee_id: data.employee_id,
    sodetail:
      data?.sodetail?.map((item) => ({
        product_id: item.product_id,
        qty_order: item.qty_order,
        mr_unit_id: item.mr_unit_id,
        price_measure: item.price_measure,
        discount: item.discount,
        total: item.total,
        project_id: item.project_id,
        tax_id: item.tax_id,
        soh: item?.soh,
        location_id: data.location_id
      })) ?? [],
    reminder: data.reminder
      ? {
          card_group: data.reminder.card_group,
          card_id: data.reminder.card_id,
          vendor_id: data.reminder.vendor_id,
          transaction_date: data.reminder.transaction_date,
          promise_date: data.reminder.promise_date,
          memo: data.reminder.memo
        }
      : null
  }
}
