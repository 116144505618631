// React
import { PropsWithChildren } from "react"

function Card (props: PropsWithChildren<{
  className?: string
}>): JSX.Element {
  return (
    <div className={`card card-compact bg-base-100 border-[1px] border-[#dadada] ${props.className} flex flex-col`}>
      {props.children}
    </div>
  )
}

function Body (props: PropsWithChildren<{
  className?: string
}>): JSX.Element {
  return (
    <div className={`card-body ${props.className}`}>
      {props.children}
    </div>
  )
}

function Title (props: PropsWithChildren<{}>): JSX.Element {
  return (
    <div className="text-lg font-bold">
      {props.children}
    </div>
  )
}

Card.Body = Body
Card.Title = Title

export { Card }