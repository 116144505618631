import * as yup from "yup"
import { formSalesOrderDetailSchema } from "pages/Sales/Transaction/CustomerOrder/types"

export type FormInvoiceDetailType = yup.InferType<
  typeof formInvoiceDetailSchema
>

export const formInvoiceDetailSchema = formSalesOrderDetailSchema.shape({
  quantity_invoice: yup
    .number()
    .test(
      "is-not-larger-than-soh",
      "Quantity Invoice cannot be larger than SOH",
      function (value) {
        const { soh } = this.parent
        if (soh && value && value > soh) {
          return false
        }
        return true
      }
    ),
  invoice_id: yup.number().nullable(),
  invoice_detail_id: yup.number().nullable()
})
