import { FormTransferItemsType, SubmitType } from "../../types"

export const submitMap = (data: FormTransferItemsType): SubmitType => {
  return {
    transfer_id: data?.transfer_id,
    trx_code: data.trx_code ?? "",
    location_from_id: data.location_from_id,
    location_to_id: data.location_to_id,
    employee_id: data.employee_id,
    memo: data.memo,
    transaction_date: data.transaction_date,
    transfer_status: data.transfer_status,
    transfer_detail: data?.transfer_detail?.map((transfer) => ({
      product_id: transfer.product_id ?? 0,
      quantity_transfer: transfer.quantity_transfer ?? 0,
      transfer_detail_id: transfer?.transfer_detail_id
    }))
  }
}
