import { useEffect, useState } from "react"
import type { SelectOptionType, SuccessFetch } from "../../../../../../../../types"
import { useApi } from "../../../../../../../../utils"

export function useTax() {
  const api = useApi()
  const [dataTax, setData] = useState<SelectOptionType[]>([])
  const [isLoadingTax, setLoading] = useState(true)

  useEffect(() => {
    api.get("/taxcode/").then((res: SuccessFetch<{
      payload: {
        tax_id: number
        tax_name: string
        rate: number
      }[]
    }>) => {
      setData(res.data.payload.map(item => {
        return {
          label: `${item.tax_name} (${item.rate}%)`,
          value: item.tax_id.toString()
        }
      }))
    }).catch(() => {
      setData([])
    }).finally(() => {
      setLoading(false)
    })
  
    return () => {
      setData([])
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [])

  return { dataTax, isLoadingTax }
}