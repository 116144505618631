import { Fragment } from "react"
import { useSearchParams } from "react-router-dom"
import {
  RegisterCard,
  RegisterDateRange,
  RegisterLocation,
  RegisterReconciliation,
  RegisterReference,
  TabNavRegister
} from "./components"
import { TabRegisterPath } from "./types"
import { HeaderMenu } from "components"

type TabsSearchParams = {
  tabs: TabRegisterPath
}

export default function FinanceRegister() {
  const [searchParams, setSearchParams] = useSearchParams({
    tabs: "by-date-range"
  } as TabsSearchParams)

  const activeTab = searchParams.get("tabs")

  const setActiveTab = (path: string) => setSearchParams({ tabs: path })

  return (
    <Fragment>
      <section className="container my-5">
        <HeaderMenu title="FINANCE REGISTER" />

        <section className="mt-1">
          <TabNavRegister activeTab={activeTab!} setActiveTab={setActiveTab} />
          {activeTab === "by-date-range" && <RegisterDateRange />}
          {activeTab === "by-card" && <RegisterCard />}
          {activeTab === "by-location" && <RegisterLocation />}
          {activeTab === "reference-id" && <RegisterReference />}
          {activeTab === "reconcilliation" && <RegisterReconciliation />}
        </section>
      </section>
    </Fragment>
  )
}
