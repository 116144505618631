export const getDataSourceLabel = (data_source: number) => {
  switch (data_source) {
    case 1:
      return "SM";
    case 2:
      return "RM";
    case 3:
      return "GE";
    case 4:
      return "TM";
    default:
      return "";
  }
};
