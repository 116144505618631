import { Card } from "components";
import { TableContent } from "./components";
import { Render, RenderProvider } from "./utils";
import { useContext } from "react";

export default function LinkAccountInventory() {
    return (
        <RenderProvider>
            <section className="container pt-5  gap-5 flex flex-col">
                <Card>
                    <Card.Body className="flex flex-row items-center justify-between !text-xl font-bold">
                        <div className="bg-black p-3 text-white rounded-lg">LINK ACCOUNT INVENTORY</div>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <div className="overflow-x-auto">
                            <TableContentSection />
                        </div>
                    </Card.Body>
                </Card>
            </section>
        </RenderProvider>
    )
}

function TableContentSection() {
    const { render } = useContext(Render)

    if (render) {
        return <TableContent />
    } else {
        return <div />
    }
}