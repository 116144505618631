// React
import { Fragment, useContext } from "react"

// Components
import { ActionButton, BaseTable, Button, Loading } from "components"

// Contexts
import { PurchaseOrderContext } from "../../../contexts"

// Form
import { useFormContext } from "react-hook-form"

// Icons
import { IoSendSharp } from "react-icons/io5"

// Third-Party Libraries
import moment from "moment"
import { Link, useParams } from "react-router-dom"

// Utils
import { convertNumber, useToggle } from "utils"
import { type FormType, useProbillList } from "pages/Purchase/Transaction/PurchaseOrder/utils"
import { DeleteModal } from "pages/Purchase/Transaction/EnterBill/components"

export function Delivery(): JSX.Element {
  // Hooks
  const { id } = useParams()
  const { data, isLoading, refetch } = useProbillList(id)
  const { disabled } = useContext(PurchaseOrderContext)

  return (
    <Fragment>
      <BaseTable>
        <thead>
          <tr>
            <th></th>
            <th>BILL ID</th>
            <th>DATE</th>
            <th>STATUS</th>
            <th>DELIVERY</th>
            <th>MEMO</th>
            <th>SUBTOTAL</th>
            <th>PAYMENT</th>
            <th>BALANCE</th>
            <th>ACTION</th>
          </tr>
        </thead>

        <tbody>
          {isLoading || !data.length ? (
            <tr>
              <td colSpan={10}>
                <Loading
                  loading={isLoading}
                  errorText="No data available"
                />
              </td>
            </tr>
          ) : data.map((item, key) => (
            <tr key={key}>
              <td>
                {!disabled && (
                  <Link to={`/purchase/transaction/enter-bills/${id}/edit/${item.bill_id}`}>
                    <IoSendSharp />
                  </Link>
                )}
              </td>

              <td className="text-center">{item.trx_code}</td>
              <td>{moment(item.transaction_date).format("DD/MM/YYYY")}</td>
              <td>{item.approval}</td>
              <td>Delivery#{item.delivery_no}</td>
              <td>{item.memo}</td>
              <td className="text-right">{convertNumber(item.total_bill).intoCurrency}</td>
              <td className="text-right">{convertNumber(item.total_payment).intoCurrency}</td>
              <td className="text-right">{convertNumber(item.balance).intoCurrency}</td>

              <td className="text-center">
                {item.approval !== "Approved" && !disabled && (
                  <Delete
                    purchase_order_id={id!}
                    bill_id={item.bill_id}
                    onSuccess={refetch}
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </BaseTable>

      {!disabled && <CreateBillButton />}
    </Fragment>
  )
}

function CreateBillButton(): JSX.Element {
  // Hooks
  const { id } = useParams()

  // Form
  const { getValues } = useFormContext<FormType>()

  // Vars
  const isApproved: boolean = getValues("approval_status") === 4
  
  return (
    <section className="flex justify-end">
      <Link to={isApproved ? `/purchase/transaction/enter-bills/${id}` : "#"}>
        <Button
          className="w-fit"
          color="primary"
          disabled={!isApproved}
        >
          CREATE BILL
        </Button>
      </Link>
    </section>
  )
}

function Delete(params: {
  purchase_order_id: string
  bill_id: string
  onSuccess: () => void
}): JSX.Element {
  // Hooks
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <ActionButton.Delete onClick={toggle} />

      {isActive && (
        <DeleteModal
          {...params}
          toggle={toggle}
        />
      )}
    </Fragment>
  )
}