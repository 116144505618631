import { Button, DateInput, Input, Modal, NumberInput } from "components"
import moment from "moment"
import { Fragment, useState } from "react"
import { toast } from "react-hot-toast"
import { useApi, useToggle } from "utils"
import { DepositCustomerListBySO } from "../types"

type ModalDeleteProps = {
  data: Pick<
    DepositCustomerListBySO,
    | "deposit_customer_id"
    | "transaction_date"
    | "trx_code"
    | "no_cheque"
    | "memo"
    | "amount"
  >
  modalState: ReturnType<typeof useToggle>
  onSuccess?: () => void
}

export const ModalDelete = ({
  data,
  modalState,
  onSuccess
}: ModalDeleteProps) => {
  const [loadingData, setLoadingData] = useState(false)
  const api = useApi()

  const handleDeleteData = async () => {
    setLoadingData(true)
    await toast
      .promise(
        api.delete(`depositcustomer/delete/${data?.deposit_customer_id}`),
        {
          loading: "Loading...",
          success: (res) => res?.data?.message,
          error: (err) => err.response?.data?.detail?.message
        }
      )
      .then(() => {
        modalState.setActive(false)
        onSuccess?.()
      })
      .finally(() => {
        setLoadingData(false)
      })
  }

  return (
    <Modal
      isOpen={modalState.isActive}
      title="DELETE PAYMENT DEPOSIT"
      size="xl"
      closeModal={modalState.toggle}>
      <Modal.Body>
        <Fragment>
          <small className="text-lg font-medium text-red-400">
            Are you sure want to delete this data?
          </small>

          <Input
            disabled
            label="PAYMENT DEPOSIT ID"
            defaultValue={data.trx_code}
          />

          <DateInput
            disabled
            label="DATE"
            selected={moment(data.transaction_date).toDate()}
            onChange={() => null}
          />

          <Input
            disabled
            label="CC | DEBIT |CHAQUE NO."
            defaultValue={data.no_cheque}
          />

          <Input disabled label="MEMO" defaultValue={data.memo} />

          <NumberInput
            disabled
            label="PAYMENT DEPOSIT"
            defaultValue={data.amount}
          />
        </Fragment>
      </Modal.Body>

      <Modal.Footer>
        <Button
          type="button"
          color="error"
          disabled={loadingData}
          loading={loadingData ? "true" : undefined}
          onClick={handleDeleteData}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
