import { Fragment } from "react"
import { Loading } from "../../../../../../components"
import { Item } from "./components/Item"
import { useList } from "../../utils"

export function ChildItem() {
  const { data, isLoading } = useList()

  return (
    <Fragment>
      {isLoading || data.length === 0 ? (
        <tr>
          <td colSpan={9} className="text-center">
            <Loading loading={isLoading} errorText="No data available" />
          </td>
        </tr>
      ) : data.map((item, key) => (
        <Item index={key} item={item} />
      ))}
    </Fragment>
  )
}