import { useState } from "react"

export interface UseFilter {
  typeFilter?: number
  dateFrom: string
  dateTo: string
  Approval?: string
  Delivered?: string
}

export const useFilter = ({ typeFilter, Approval, Delivered, dateFrom, dateTo }: UseFilter) => {
  const [filter, setFilter] = useState<UseFilter>({
    dateFrom,
    dateTo,
    Approval,
    Delivered
  })

  const handleChangeFilter = (params: Partial<UseFilter>) => {
    setFilter((prev) => ({ ...prev, ...params }))
  }

  return {
    filter,
    handleChangeFilter
  }
}
