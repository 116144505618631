import { BaseTable, Loading } from "components"
import { convertDate } from "../utils"
import { useState } from "react"
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io"
import { TableSectionProps } from "../types"
import { convertNumber } from "utils"

export const TableSection = ({ data, selected, refetch, setIsSort, isLoading, isSort }: TableSectionProps) => {
  const [active, setActive] = useState<number | null>(null)
  const header: string[] = ['TRANSACTION DATE', 'ID', 'TRADE NAME', 'ACCOUNTS', 'ACCOUNT NAME', 'MEMO', 'DEBIT', 'CREDIT', 'JOB ID']

  const toggleActive = async (index: number, item: string) => {
    setActive((prevActive) => (prevActive === index ? null : index));
    const newSort = index !== active ? 'asc' : 'desc';
    
    const sortBy = (field: string) => refetch(selected?.search, selected?.detail_search, selected?.date_from, selected?.date_to, `${field}:${newSort}`);

    switch (item) {
      case 'TRANSACTION DATE': sortBy('transaction_date'); break;
      case 'ID': sortBy('trx_code'); break;
      case 'TRADE NAME': sortBy('trade_name'); break;
      case 'ACCOUNTS': sortBy('coa_code'); break;
      case 'ACCOUNT NAME': sortBy('coa_name'); break;
      case 'MEMO': sortBy('memo'); break;
      case 'DEBIT': sortBy('debet'); break;
      case 'CREDIT': sortBy('credit'); break;
      case 'JOB ID': sortBy('job_id'); break;
      default: refetch(selected?.search, selected?.detail_search, selected?.date_from, selected?.date_to, ``);
    }

    setIsSort(true);
  };
  
  return (
    <div className="font-light">
      <BaseTable>
        <thead>
          <tr>
            {header.map((item, index) => (
              <th key={index} onClick={() => toggleActive(index, item)}>
                <div className="flex gap-2 items-center">
                  {item}
                  {index === active ? <IoIosArrowUp className="w-5 h-5" /> : <IoIosArrowDown className="w-5 h-5" /> }
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
        {isLoading && isSort === true
          ? <tr><td colSpan={10} className="text-center"><Loading loading /></td></tr>
          : data?.length === 0 ? <tr><td className="text-center" colSpan={9}><small>No Data Available</small></td></tr> : data?.map((val: any) => (
            <tr>
              <td>{val?.transaction_date ? convertDate(val?.transaction_date) : "-"}</td>
              <td>{val?.trx_code ?? "-"}</td>
              <td className="min-w-[300px]">{val?.trade_name ? val?.trade_name : "-"}</td>
              <td className="min-w-[180px]">{val?.coa_code ?? "-"}</td>
              <td className="min-w-[180px]">{val?.coa_name ?? "Sallary Office"}</td>
              <td className="min-w-[180px]">{val?.memo ?? "Beli Makan"}</td>
              <td className="min-w-[180px] text-right">{val?.position === 1 ? convertNumber(val?.amount)?.intoCurrency : "-"}</td>
              <td className="min-w-[180px] text-right">{val?.position === 2 ? convertNumber(val?.amount)?.intoCurrency : "-"}</td>
              <td className="min-w-[180px]">{val?.job_id ?? "-"}</td>
            </tr>
          ))}
        </tbody>
      </BaseTable>
    </div>
  )
}