import * as yup from "yup"

export type FormReceivedDetailType = yup.InferType<
  typeof formReceivedDetailSchema
>

export const formReceivedDetailSchema = yup.object().shape({
  received_detail_id: yup.number().label("Transfer").optional(),
  product_id: yup
    .number()
    .label("Product")
    .typeError("Product is required")
    .required(),
  product_name: yup.string().label("Product").optional(),
  product_barcode: yup.string().label("Product").optional(),
  mr_unit_id: yup.number().label("Unit").optional(),
  mr_unit_name: yup.string().label("Unit").optional(),
  total_quantity_transfer: yup.number().optional(),
  quantity_reject: yup.number().optional(),
  quantity_received: yup
    .number()
    .required("Quantity Received")
    .min(1)
    .test(
      "quantityTransferShouldBeLessEqualThanTotalQuantityTransfer",
      "Should be less equal than Qty Ship",
      (value, context) =>
        (value ?? 0) <= (context.parent.total_quantity_transfer ?? 0)
    )
})
