/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Label, Modal, Select } from "components"
import { CreatePage as NewEmployee } from "pages/Card/Transaction/CreateNewEmployee/components"
import { APPROVAL } from "pages/Sales/Register/utils/vars"
import { Fragment } from "react"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { TbPlus } from "react-icons/tb"
import { useToggle } from "utils"
import { useDropdownEmployee } from "../utils/hooks/useDropdownEmployee"

export type DropdownEmployeeProps = {
  approvalView?: boolean
  isEdit?: boolean
  label?: string
  disabled?: boolean
  name?: string
}

export const DropdownEmployee = ({ approvalView, isEdit = false, disabled, label = "EMPLOYEE", name = "employee_id" }: DropdownEmployeeProps) => {
  const { dropdown, isLoading } = useDropdownEmployee()
  const { control } = useFormContext()
  const modalCreateEmployee = useToggle(false)
  const [approval_status] = useWatch({
    control: control,
    name: ["approval_status"]
  })

  return (
    <>
      <Label text={label} />
      <section className="flex gap-3">
        <Controller
          control={control}
          name={name}
          render={({ field, fieldState }) => (
            <Select
              className="grow"
              placeholder="Select Employee"
              isLoading={isLoading}
              options={dropdown}
              value={
                field.value
                  ? dropdown.find((e) => field.value.toString() === e.value)
                  : null
              }
              isDisabled={approval_status !== APPROVAL.PENDING || isEdit || approvalView || disabled}
              onChange={(e: any) => {
                field.onChange(e?.value)
              }}
              error={fieldState.error?.message}
            />
          )}
        />

        <Button
          color="primary"
          className={`px-2 ${approvalView ? "hidden" : ""} `}
          onClick={modalCreateEmployee.toggle}>
          <TbPlus size={24} />
        </Button>
      </section>

      <ModalCreateEmployee
        isOpen={modalCreateEmployee.isActive}
        onClose={modalCreateEmployee.toggle}
      />
    </>
  )
}

type ModalCreateEmployeeProps = {
  isOpen: boolean
  onClose?: () => void
}

function ModalCreateEmployee({ isOpen, onClose }: ModalCreateEmployeeProps) {
  return (
    <Fragment>
      <Modal
        isOpen={isOpen}
        title="Create New Employee"
        size="full"
        closeModal={onClose}>
        <Modal.Body>
          <NewEmployee asModal />
        </Modal.Body>
      </Modal>
    </Fragment>
  )
}
