import { FilterReportPNL } from "pages/Finance/Report/types"

export const getDateTypeLabelFNL = (dateType: FilterReportPNL["dateType"]) => {
  switch (dateType) {
    case "2":
      return "This Month"
    case "3":
      return "This Quarter"
    case "4":
      return "This Financial Year"
    case "5":
      return "Last Month"
    case "6":
      return "Last Quarter"
    case "7":
      return "Last Financial Year"
    case "8":
      return "Month to Date"
    case "9":
      return "Quarter to Date"
    case "10":
      return "Year to Date"
    default:
      return "Select Date"
  }
}
