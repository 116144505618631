// React
import { Fragment } from "react"

// Components
import { BaseTable, Button, Loading, Modal } from "components"

// Third-Party Libraries
import moment from "moment"
import toast from "react-hot-toast"

// Types
import type { JournalListCheck } from "../../types"

// Utils
import { convertNumber, useApi } from "utils"
import { generateDeleteJournalPromise } from "../../utils/functions/generateDeleteJournalPromise"

type ModalDeleteJournalProps = {
  isOpen: boolean
  loadingData: boolean
  journalList: JournalListCheck[]
  date: string
  journalCode: string
  toggle: () => void
  refetchJournal: () => void
}

export const ModalDeleteJournal = ({
  loadingData,
  isOpen,
  journalList,
  date,
  journalCode,
  toggle,
  refetchJournal
}: ModalDeleteJournalProps) => {
  const api = useApi()
  const journal = journalList[0]
  const params = {
    JournalCode: journalCode
  }

  const handleDeleteJournal = async () => {
    await toast
      .promise(
        generateDeleteJournalPromise({
          api,
          params,
          data_source: journal.data_source
        }),
        {
          loading: "Loading...",
          success: (res) => res?.data?.message,
          error: (err) => err.response?.data?.detail?.message
        }
      )
      .then(() => {
        refetchJournal()
        toggle()
      })
  }

  return (
    <Modal isOpen={isOpen} title="" size="6xl" closeModal={toggle}>
      <Modal.Body>
        {loadingData ? (
          <Loading loading />
        ) : (
          <Fragment>
            <section className="mb-3 border-b pb-3">
              <div className="text-xl font-bold">Delete Journal</div>
              <small className="text-lg font-medium text-red-400">
                Are you sure want to delete this data?
              </small>
            </section>

            <BaseTable className="border-collapse border-transparent">
              <thead>
                <tr className="uppercase">
                  <th>Date</th>
                  <th>Id</th>
                  <th>Account</th>
                  <th>Memo</th>
                  <th>Debit</th>
                  <th>Credit</th>
                  <th>Job Id</th>
                </tr>
              </thead>

              <tbody>
                {journalList.map((itemData, key: number) => {
                  const isMemoHeader = key === 0
                  const hasPosition = itemData.position
                  const isDebit = itemData.position === 1
                  const showDivider = false
                  const isLastTransaction = key === journalList.length - 1
                  const dividerClassName =
                    "border-b-[2px] border-black  dark:border-white"

                  return (
                    <tr>
                      <td>
                        {isMemoHeader && (
                          <>
                            <span className="font-bold">
                              {moment(date, "YYYY-MM-DD").format("DD/MM/YYYY")}
                            </span>
                          </>
                        )}
                      </td>

                      <td
                        className={isMemoHeader ? "font-bold" : "font-normal"}>
                        {isMemoHeader ? (
                          <span className="ps-12">{itemData.source_memo}</span>
                        ) : (
                          <>
                            <span className="pe-2">{itemData.source_code}</span>
                            {!isLastTransaction && (
                              <span className="ps-4">{itemData.trx_code}</span>
                            )}
                          </>
                        )}
                      </td>

                      <td>
                        {itemData.coa_code} {itemData.coa_name}
                      </td>

                      <td
                        className={
                          showDivider ? dividerClassName : "border-none"
                        }>
                        {itemData.memo}
                      </td>

                      <td
                        className={`
                      text-center 
                      ${showDivider ? dividerClassName : "border-none"} 
                      ${hasPosition ? "font-normal" : "font-bold"} 
                      `}>
                        {hasPosition
                          ? isDebit
                            ? convertNumber(itemData.amount).intoCurrency
                            : ""
                          : itemData.checkerDebit && convertNumber(itemData.checkerDebit).intoCurrency}
                      </td>

                      <td
                        className={`
                      text-center text-red-400 
                      ${showDivider ? dividerClassName : "border-none"} 
                      ${hasPosition ? "font-normal" : "font-bold"} 
                      `}>
                        {hasPosition
                          ? isDebit
                            ? ""
                            : convertNumber(itemData.amount).intoCurrency
                          : itemData.checkerCredit && convertNumber(itemData.checkerCredit).intoCurrency}
                      </td>

                      <td className="text-right">{itemData.job_id ?? ""}</td>
                    </tr>
                  )
                })}
              </tbody>
            </BaseTable>
          </Fragment>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button
          type="button"
          permission="FR014"
          color="error"
          disabled={loadingData}
          onClick={handleDeleteJournal}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
