// React
import { ButtonHTMLAttributes, DetailedHTMLProps } from "react"

// Utils
import { usePermissionRoute } from "utils"

interface Type extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  color?: "" | "error" | "primary" | "success" | "transparent" | "warning" | "secondary" | "ghost" | "outline-error" | "outline-primary" | "outline-success" | "outline-warning" | "outline-secondary"
  datatip?: string
  loading?: "true" | undefined
  size?: "lg" | "sm" | "xs"
  permission?: string
}

export function Button(props: Type) {
  // Variables
  const color = {
    error: "btn-error",
    primary: "btn-primary",
    success: "btn-success",
    transparent: "btn-ghost",
    warning: "btn-warning",
    secondary: "btn-secondary",
    ghost: "btn-ghost",
    "outline-error": "btn-outline-error",
    "outline-primary": "btn-outline-primary",
    "outline-success": "btn-outline-success",
    "outline-warning": "btn-outline-warning",
    "outline-secondary": "btn-outline-secondary",
    "": ""
  }
  const size = {
    lg: "btn-lg",
    sm: "btn-sm",
    xs: "btn-xs"
  }

  // Hooks
  const permissionRoute = usePermissionRoute()

  return (
    <button
      {...props}
      className={`btn rounded-none ${props.color && color[props.color]} ${props.size && size[props.size]} ${props.className}`}
      disabled={props.loading || props.disabled ? true : undefined}
      onClick={(e) => {
        if (Boolean(!props.permission || Boolean(props.permission && permissionRoute.checkPermission(props.permission))) && props.onClick) {
          props.onClick(e)
        }
      }}
    >
      {props.loading && <span className="loading loading-spinner" />}
      {props.children}
    </button>
  )
}