import { useEffect, useState } from "react"
import type { SuccessFetch } from "../../../../../../types"
import { useApi } from "../../../../../../utils" 
import { ProductType } from "../../types"


export function useProduct() {

  const api = useApi()
  const [dataProduct, setData] = useState<ProductType[]>([])
  const [isLoading, setLoading] = useState(true)

  const fetchUrl = () => {
      return api.get("/opening-stock/product")
  }

  const fetchData = () => {
    setLoading(true)

    fetchUrl()
    // .then((res: SuccessFetch<{ payload: DataType }>) => {
      .then((res: SuccessFetch<{
        payload: ProductType[] }>) => {
      setData(res.data.payload)
    })
    .catch(() => {
      setData([])
    }).finally(() => {
      setLoading(false)
    })
  }

  const refetch = () => fetchData()

  useEffect(() => {
    fetchData()

    return () => {
      setData([])
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [])

  return { dataProduct, isLoading, refetch }
}

