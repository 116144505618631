// Types
import type { SelectOptionType } from "types"

export const delivery_option: SelectOptionType[] = [
  {
    label: "ALL",
    value: "0"
  },
  {
    label: "Pending",
    value: "1"
  },
  {
    label: "Part Delivery",
    value: "2"
  },
  {
    label: "Full Delivery",
    value: "3"
  }
]