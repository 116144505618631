import { PerformenceList } from "pages/Inventori/Transaction/AddNewItem/types"
import { useEffect, useState } from "react"
import { SuccessFetch } from "types"
import { useApi } from "utils"

export function usePerformence(props: { product_id: number }) {
    const api = useApi()
    const [data, setData] = useState<PerformenceList | null>(null)
    const [isLoading, setLoading] = useState(false)
    
    useEffect(() => {
      setLoading(true)
  
      api.get(`/product/performance/${props?.product_id}`) 
      .then((res: SuccessFetch<{ payload: PerformenceList | null }>) => setData(res.data.payload))
      .catch(() => setData(null) )
      .finally(() => setLoading(false))
    
      return () => {
        setData(null)
        setLoading(true)
      }
      // eslint-disable-next-line
    }, [])
  
    return { data, isLoading }
}