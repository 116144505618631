// Types
import type { SelectOptionType } from "types"

export const default_option: SelectOptionType[] = [
  {
    label: "All",
    value: "0"
  },
  {
    label: "Pending",
    value: "1"
  },
  {
    label: "Checked",
    value: "2"
  },
  {
    label: "Rejected",
    value: "3"
  },
  {
    label: "Approved",
    value: "4"
  }
]