// React
import { ButtonHTMLAttributes, DetailedHTMLProps, Fragment, PropsWithChildren } from "react"

// Components
import { Button } from "components"

// Icons
import { TbCheck, TbMessage, TbPencil, TbPlus, TbTrash, TbX } from "react-icons/tb"

// Vars
const className = "px-2 tooltip"

export function ActionButton(params: PropsWithChildren<{}>) {
  return <Fragment>{params.children}</Fragment>
}

interface Type extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  loading?: "true" | undefined
  permission?: string
}

function Add(params: Type) {
  return (
    <Button
      {...params}
      color="ghost"
      title="Add Data"
      className={`${className} ${params.className}`}
      disabled={params.loading ? true : undefined}
      permission={params.permission}
    >
      {params.loading ? <span className="loading loading-spinner" /> : <TbPlus className="size-5" />}
    </Button>
  )
}

function Check(params: Type) {
  return (
    <Button
      title="Submit"
      color="ghost"
      className={`${className} ${params.className}`}
      disabled={params.loading ? true : undefined}
      permission={params.permission}
      onClick={params.onClick}
    >
      {params.loading ? <span className="loading loading-spinner" /> : <TbCheck className="size-5" />}
    </Button>
  )
}

function Close(params: Type) {
  return (
    <button
      {...params}
      title="Close"
      className={`${className} ${params.className}`}
      onClick={params.onClick}
      disabled={params.loading ? true : undefined}
    >
      {params.loading ? <span className="loading loading-spinner" /> : <TbX className="size-5" />}
    </button>
  )
}

function Delete(params: Type) {
  return (
    <Button
      {...params}
      color="ghost"
      title="Delete Data"
      className={`text-error ${className} ${params.className}`}
      permission={params.permission}
    >
      <TbTrash className="size-5" />
    </Button>
  )
}

function Detail(params: {
  className?: string
  onClick?: () => void
  permission?: string
}) {
  return (
    <Button
      color="ghost"
      title="Detail Data"
      className={`${className} ${params.className}`}
      permission={params.permission}
      onClick={params.onClick}
    >
      <TbMessage className="size-5" />
    </Button>
  )
}

function Update(params: Type) {
  return (
    <Button
      {...params}
      color="ghost"
      title="Update Data"
      permission={params.permission}
      className={`${className} ${params.className}`}
    >
      <TbPencil className="size-5" />
    </Button>
  )
}

ActionButton.Add = Add
ActionButton.Check = Check
ActionButton.Close = Close
ActionButton.Delete = Delete
ActionButton.Detail = Detail
ActionButton.Update = Update