// React
import { Fragment, useEffect, useRef, useState } from "react"

// Components
import { Select } from "components/forms"

// Icons
import { TbChevronLeftPipe, TbChevronRightPipe } from "react-icons/tb"

// Third-Party Libraries
import ReactPaginate from "react-paginate"

export function Pagination(props: {
  currentPage: number
  totalPage: number
  onPageChange: (page: number) => void
  onAddShow?: (value: string) => void
  limit: number
  total: number
  size: number
}) {
  // Vars
  const showAllValue = [
    { label: "5", value: "5" },
    { label: "10", value: "10" },
    { label: "50", value: "50" },
    { label: "100", value: "100" },
  ]

  // Hooks
  const ref = useRef(null)
  const [width, setWidth] = useState(0)

  useEffect(() => {
    if (ref.current) {
      // @ts-ignore
      const elementWidth = ref.current?.offsetWidth
      setWidth(elementWidth)
    }

    return () => {
      setWidth(0)
    }
  }, [ref]);

  return (
    <Fragment>
      <div className="flex lg:flex-row flex-col justify-between items-center mt-5 gap-5">
        <div>{`Show 
          ${props.limit === props.size ?
          props.currentPage * props.size + props.currentPage - props.currentPage : props.total
        } from ${props.total} data`}</div>

        <div ref={ref} className={`lg:order-none order-first ${width > window.innerWidth ? "self-start" : ""}`}>
          <ReactPaginate
            breakLabel="..."
            activeLinkClassName="btn-active"
            breakLinkClassName="join-item btn"
            className="join"
            pageLinkClassName="join-item btn"
            nextLinkClassName="join-item btn"
            previousLinkClassName="join-item btn"
            forcePage={props.currentPage - 1}
            pageCount={props.totalPage}
            renderOnZeroPageCount={null}
            nextLabel={<TbChevronRightPipe />}
            previousLabel={<TbChevronLeftPipe />}
            onPageChange={(selectedItem: { selected: number }) => {
              props.onPageChange(selectedItem.selected + 1)
            }}
          />
        </div>

        <div className="flex gap-4 items-center flex-col lg:flex-row">
          <div>Show :</div>

          <div>
            <Select
              value={showAllValue.find(item => item.value === props.limit.toString())}
              options={showAllValue}
              controlClass="w-[100px]"
              onChange={(e) => {
                if (e && props.onAddShow) {
                  props.onAddShow(e.value)
                }
              }}
            />
          </div>
        </div>
      </div>
    </Fragment>
  )
}