/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react"
import { SuccessFetch } from "types"
import { useApi } from "utils"

type UseSalesOrderCode = {
  key?: any
  type: "next_code" | "reminder_code"
  trx_date: string
  enabled?: boolean
  onSuccess?: (data: string) => void
}

type NextCode = {
  next_code: string
}

type ReminderCode = {
  reminder_code: string
}

type Response = {
  payload: NextCode | ReminderCode
}

export function useSalesOrderCode({
  key = "",
  type,
  trx_date,
  onSuccess,
  enabled = true
}: UseSalesOrderCode) {
  const api = useApi()
  const [data, setData] = useState("")
  const [isLoading, setLoading] = useState(false)

  const fetchData = () => {
    setLoading(true)

    const url =
      type === "next_code"
        ? "/salesorder/generate-code"
        : "/salesorder/generate-reminder-code"

    api
      .post(url, {
        trx_date
      })
      .then((res: SuccessFetch<Response>) => {
        const data = res.data.payload

        if ("next_code" in data) {
          onSuccess?.(data.next_code)
          return setData(data.next_code)
        }

        onSuccess?.(data.reminder_code)
        setData(data.reminder_code)
      })
      .catch(() => setData(""))
      .finally(() => setLoading(false))
  }

  const refetch = () => fetchData()

  useEffect(() => {
    if (enabled) {
      fetchData()
    }

    return () => {
      setData("")
      setLoading(false)
    }
  }, [trx_date, enabled, key])

  return { data, isLoading, refetch }
}
