import moment from "moment"
// import { periode } from "pages/Finance/Report/utils/vars"

export const generateDateHeaderColumn = (
  periodeValue: string | undefined,
  date: string
) => {
  if (!periodeValue) {
    return date
  }

  switch (periodeValue) {
    case "4":
      return moment(date).format("MMM YYYY")

    case "5":
      return moment(date).format("MMM YYYY")

    default:
      return moment(date).format("DD MMM YYYY")
  }
}
