/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup"
import { APPROVAL } from "pages/Sales/Register/utils/vars"
import { useEffect, useMemo } from "react"
import { FormProvider, useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { useNavigate, useParams } from "react-router-dom"
import { useApi, useHandleAuth } from "utils"
import { today } from "utils/functions"
import { useDetailTransferItem } from "../TransferItem/utils/hooks"
import { FormSection } from "./components"
import { formReceivedSchema, FormReceivedType } from "./types"
import { submitMap } from "./utils/functions"

export default function Received() {
  const navigate = useNavigate()
  const api = useApi()
  const params = useParams()
  const { userTokenData } = useHandleAuth()
  const userToken = userTokenData()
  const transfer = useDetailTransferItem({
    transfer_id: params.transfer_id ?? ""
  })

  const defaultValues: FormReceivedType = useMemo(
    () => ({
      trx_code: "",
      transfer_id: transfer.data?.datatransfer.transfer_id ?? NaN,
      location_from_id: transfer.data?.datatransfer.location_from_id ?? NaN,
      location_to_id: transfer.data?.datatransfer.location_to_id ?? NaN,
      employee_id: userToken?.user_id ?? NaN,
      transaction_date: today(),
      transfer_date: transfer?.data?.datatransfer.transaction_date ?? today(),
      transfer_memo: transfer?.data?.datatransfer.memo ?? "",
      transfer_link: transfer?.data?.datatransfer.trx_code ?? "",
      transfer_employee_id: transfer.data?.datatransfer.employee_id ?? NaN,
      memo: "Received transaction",
      received_detail:
        transfer.data?.detailtransfer?.map((transfer) => ({
          product_id: transfer.product_id,
          product_name: transfer.product_name,
          product_barcode: transfer.product_barcode,
          mr_unit_name: transfer.mr_unit_name,
          quantity_reject: transfer.quantity_reject ?? 0,
          quantity_received: 0,
          total_quantity_transfer: transfer.quantity_transfer ?? 0
        })) ?? [],
      approval_status: APPROVAL.PENDING,
      approval_status_text: "PENDING",
      approved_by: "",
      approved_by_name: "",
      location_from_name: transfer.data?.datatransfer.location_from_name,
      location_to_name: transfer.data?.datatransfer.location_to_name
    }),
    [transfer.data]
  )

  const submitPromise = async (value: any) => {
    return await toast
      .promise(api.post("/received/add", submitMap(value)), {
        loading: "Loading...",
        success: (res) => res.data.message,
        error: (err) =>
          err.response.data.detail?.message ?? err.response.data.message
      })
      .then(() => {
        navigate("/inventory/register/", { replace: true })
      })
      .catch((err) => {
        throw new Error(err)
      })
  }

  const onSubmit = async (value: any) => {
    return await submitPromise(value)
  }

  const methods = useForm<any>({
    defaultValues,
    resolver: yupResolver(formReceivedSchema)
  })

  useEffect(() => methods.reset(defaultValues), [defaultValues])

  return (
    <FormProvider {...methods}>
      <FormSection onSubmit={methods.handleSubmit(onSubmit)} />
    </FormProvider>
  )
}
