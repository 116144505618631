import { BaseTable, Card, CommandButton, Loading, PaginationData } from "components";
import React, { Fragment, useState } from "react";
import { Filter, Item, SummarySection } from "./components";
import { FilterType } from "./types";
import { defaultFilter, useList } from "./utils";
import { convertNumber } from "utils";

export const dataContext = React.createContext({
    currentPage: 1,
    limit: 10,
    size: 1,
    total: 0
})
export function TabReference() {

    const [selected, setSelected] = useState<FilterType>(defaultFilter)
    const { data, isLoading } = useList(selected)

    const paymentTotal = data?.payload?.reduce((acc, item) => acc + item.total_payment, 0)
    const balanceTotal = data?.payload?.reduce((acc, item) => acc + item.balance_owing, 0)

    return (
        <Fragment>
            <section className="mt-2">
                <Card>
                    <Card.Body>
                        <Filter selected={selected} setSelected={setSelected} />
                    </Card.Body>
                </Card>
            </section>

            <section className="mt-2">
                <Card>
                    <Card.Body>
                        <div className="overflow-x-auto">
                            <BaseTable>
                                <thead>
                                    <tr>
                                        <th rowSpan={2}>No</th>
                                        <th colSpan={2} rowSpan={2}>SO ID#</th>
                                        <th rowSpan={2}>DATE</th>
                                        <th rowSpan={2}>CUSTOMER</th>
                                        <th rowSpan={2}>REFERENCE</th>
                                        <th colSpan={2}>STATUS</th>
                                        <th rowSpan={2} className="whitespace-pre-line">QTY ORDER</th>
                                        <th rowSpan={2} className="whitespace-pre-line">QTY DELIVERED</th>
                                        <th rowSpan={2} className="whitespace-pre-line">QTY BO</th>
                                        <th rowSpan={2} className="whitespace-pre-line">TOTAL SALES</th>
                                        <th rowSpan={2} className="whitespace-pre-line">TOTAL PAYMENT</th>
                                        <th rowSpan={2} className="whitespace-pre-line">BALANCE OWING</th>
                                        <th rowSpan={2}>ACTION</th>
                                    </tr>
                                    <tr>
                                        <th>APPROVAL</th>
                                        <th>DELIVERED</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <dataContext.Provider
                                        value={{
                                            currentPage: selected.page,
                                            limit: selected.limit,
                                            size: data.pagination_data.size,
                                            total: selected.total
                                        }}
                                    >
                                        {isLoading || data?.payload?.length === 0 ? (
                                            <tr>
                                                <td colSpan={15} className="text-center">
                                                    <Loading loading={isLoading} errorText={isLoading ? "" : "No data available"} />
                                                </td>
                                            </tr>
                                        ) :
                                            data?.payload?.map((item: any, key: number) => (
                                                <Item data={item} index={key} />
                                            ))}
                                    </dataContext.Provider>
                                </tbody>
                            </BaseTable>
                        </div>
                        <div className="mt-5">
                            <PaginationData data={data} pagination={selected} setPagination={setSelected} />
                        </div>
                    </Card.Body>
                </Card>
            </section>

            <section className="mt-2">
                <Card>
                    <Card.Body className="flex flex-col gap-5">
                        <section className="flex flex-wrap justify-between items-center gap-3">
                            <section className="flex flex-wrap gap-3">
                                <CommandButton actiontype="help" />
                                <CommandButton actiontype="print" />
                                <CommandButton actiontype="email" />
                                <CommandButton actiontype="export" />
                            </section>
                            <section>
                                <SummarySection label="SUMMARY PAYMENT" value={convertNumber(paymentTotal)?.intoCurrency} />
                                <SummarySection label="SUMMARY BALANCE" value={convertNumber(balanceTotal)?.intoCurrency} />
                            </section>
                        </section>
                    </Card.Body>
                </Card>
            </section>
        </Fragment>
    )
}