// React
import { Fragment, useEffect, useState } from "react"

// Components
import { Modal, Select } from "components"
import { CreatePage as NewCustomer } from "pages/Card/Transaction/CreateNewCustomer/components"
import { CreatePage as NewEmployee } from "pages/Card/Transaction/CreateNewEmployee/components"
import { CreatePage as NewVendor } from "pages/Card/Transaction/CreateNewVendor/components"

// Form
import { Controller, useFormContext, useWatch } from "react-hook-form"

// Icons
import { TbPlus } from "react-icons/tb"

// Third-Party Libraries
import { Link } from "react-router-dom"

// Types
import type { GroupName } from "./types"
import type { FormType } from "pages/Finance/Transaction/utils"

// Utils
import { modal_list, useTrader } from "./utils"

export function Trader() {
  // Hooks
  const { data, isLoading, refetch } = useTrader()

  // Form
  const { control } = useFormContext<FormType>()
  const group_card = useWatch({
    control,
    name: "group_card"
  })

  useEffect(() => {
    if (Boolean(group_card)) {
      refetch(group_card as GroupName)
    }

    // eslint-disable-next-line
  }, [group_card])

  return (
    <Fragment>
      <Controller
        control={control}
        name="card_id"
        render={({ field, fieldState }) => (
          <Select
            showPlaceholder
            className="grow"
            placeholder="Select Trader Name"
            error={fieldState.error?.message}
            isDisabled={Boolean(!group_card && group_card === "")}
            isLoading={isLoading}
            options={data}
            ref={field.ref}
            value={data.find(item => item.value === field.value) ?? null}
            onChange={item => field.onChange(item?.value)}
          />
        )}
      />

      <CreateTrader
        onSuccess={() => {
          if (Boolean(group_card)) {
            refetch(group_card as GroupName)
          }
        }} />
    </Fragment>
  )
}

function CreateTrader(props: {
  onSuccess: () => void
}) {
  // Hooks
  const [modal, setModal] = useState("")

  const toggle = () => {
    setModal("")
  }

  return (
    <Fragment>
      <div className="dropdown dropdown-end">
        <div tabIndex={0} role="button" className="btn btn-primary rounded-none px-2">
          <TbPlus size={24} />
        </div>

        <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-white text-black dark:bg-black dark:text-white rounded-box !w-[200px]">
          {modal_list.map((item, key) => (
            <li key={key}>
              <Link to="#" onClick={() => setModal(item)}>{item}</Link>
            </li>
          ))}
        </ul>
      </div>

      {modal && (
        <Fragment>
          {modal === "Customer" ? (
            <Modal
              isOpen
              title="Create New Customer"
              size="full"
              closeModal={() => {
                toggle()
                props.onSuccess()
              }}
            >
              <Modal.Body>
                <NewCustomer asModal />
              </Modal.Body>
            </Modal>
          ) : modal === "Employee" ? (
            <Modal
              isOpen
              title="Create New Employee"
              size="full"
              closeModal={() => {
                toggle()
                props.onSuccess()
              }}
            >
              <Modal.Body>
                <NewEmployee asModal />
              </Modal.Body>
            </Modal>
          ) : modal === "Vendor" ? (
            <Modal
              isOpen
              title="Create New Vendor"
              size="full"
              closeModal={() => {
                toggle()
                props.onSuccess()
              }}
            >
              <Modal.Body>
                <NewVendor asModal />
              </Modal.Body>
            </Modal>
          ) : (
            <Modal isOpen title="" closeModal={toggle} />
          )}
        </Fragment>
      )}
    </Fragment>
  )
}