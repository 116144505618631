import { useContext, useEffect } from "react"
import { Button, Modal } from "../../../../../components"
import { FormSection } from "./FormSection"
import * as yup from "yup"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import toast from "react-hot-toast"
import type { DataList } from "../types"
import { useApi } from "../../../../../utils"
import { Render, useCode } from "../utils"
import { menuTitle } from "./ChildItem/components/Item/components/MenuTitle"

export function CreateModal(props: { magister_code?: string; magister_name?: string; description?: string; toggle: () => void; refetch?: () => void }) {

  const api = useApi()
  const { toggle } = useContext(Render)
  const { dataCode } = useCode()

  useEffect(() => {
    if (dataCode) { methods.setValue("menu_code", dataCode) }
    // eslint-disable-next-line
  }, [dataCode])


  type CreateData = Omit<DataList, "menu_id">;

  const defaultValues: CreateData = {
    module_id: "",
    menu_code: "",
    menu_name: "",
    description: ""
  }
  const validationSchema = yup.object().shape({
    module_id: yup.string().label("Module").required(),
    menu_code: yup.string().label("Menu code").required(),
    menu_name: yup.string().label("Menu name").required(),
    description: yup.string().label("Description").optional(),
  })
  const onSubmit = (value: CreateData) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.post("/menu", value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message
        }
      ).then(() => {
        props.toggle()
        props?.refetch && props.refetch()
        toggle()
      }).catch(() => { }).finally(resolve)
    })
  }
  const methods = useForm<CreateData>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })

  return (
    <Modal isOpen title={`SETUP | ${menuTitle.title}`} closeModal={props.toggle}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Modal.Body>
            <FormSection />
          </Modal.Body>

          <Modal.Footer className="text-end">
            <Button type="submit" color="primary" loading={methods.formState.isSubmitting ? "true" : undefined}>Save</Button>
          </Modal.Footer>
        </form>
      </FormProvider>
    </Modal>
  )
}