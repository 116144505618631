import { Fragment} from "react"
import type { PersonalList } from "../../../../../types"
import { Toggle } from "components/actions/Toggle"
import toast from "react-hot-toast"
import { useApi } from "utils"

export function IsActive(props: {
    id: PersonalList
    refetch?: () => void
    permission?: boolean
}) {
    const api = useApi()
    const onSubmit = (value: PersonalList) => {
        return new Promise<void>((resolve) => {
            toast.promise(
                api.put("/vendor/changestatus", { vendor_id: value.vendor_id, vendordel: !value.is_deleted }),
                {
                    loading: "Loading...",
                    success: (res) => res.data.message,
                    error: (err) => {
                        if (err.response && err.response.status === 400) {
                            return err.response.data.message || "Bad Request";
                        } else {
                            return "An error occurred";
                        }
                    }
                }
            )
            .then(() => props?.refetch && props.refetch())
            .catch(() => {})
            .finally(resolve)
        })
    }

    return (
        <Fragment>
            <Toggle permission={"CR035"} isChecked={props.id.is_deleted} onClick={() => onSubmit(props.id)} />
        </Fragment>
    )
}