import { Button, Checkbox, Modal } from "components";
import { Fragment, useState } from "react";
import { Controller, FormProvider, useForm, useFormContext } from "react-hook-form";
import { FaSliders } from "react-icons/fa6";
import { useApi, useToggle } from "utils";
import { FilterType } from "../../types/FilterType";
import { ColumnConfigType } from "types";
import toast from "react-hot-toast";
import { defaultValue } from "../../utils/function";

export default function FilterTable(props: {
    refetch: () => void
    dataList: ColumnConfigType[]
}) {

    const { isActive, toggle } = useToggle(false)

    return (
        <button>
            <button>
                <FaSliders className="w-6 h-6 text-warning" onClick={toggle} />
                {isActive && <ModalFillter toggle={toggle} refetch={props.refetch} dataList={props.dataList} />}
            </button>
        </button>
    )
}

function ModalFillter(props: {
    toggle: () => void
    refetch: () => void
    dataList: ColumnConfigType[]
}) {

    const [loading, isLoading] = useState(false)
    const api = useApi()
    const dataFillter = defaultValue(props.dataList)

    const defaultValues = {
        nomorNationalId: dataFillter.nomorNationalId,
        religion: dataFillter.religion,
        handphone: dataFillter.handphone,
        email: dataFillter.email,
        country: dataFillter.country,
        province: dataFillter.province,
        regency: dataFillter.regency,
        subdistrict: dataFillter.subdistrict,
        village: dataFillter.village,
        address: dataFillter.address,
        departement: dataFillter.departement,
        divisi: dataFillter.divisi,
        gender: dataFillter.gender,
        employeeStatus: dataFillter.employeeStatus,
        bank: dataFillter.bank,
        branch: dataFillter.branch,
        accountBankName: dataFillter.accountBankName,
    }

    const methods = useForm<FilterType>({
        defaultValues
    })

    const handleCheckboxChange = (value: ColumnConfigType) => {
        return new Promise<void>((resolve) => {
            toast.promise(
                api.post("/columnconfig/update", {
                    module: value.module,
                    column_code: value.column_code,
                    column_name: value.column_name,
                    column_status: value.column_status
                }),
                {
                    loading: "Loading...",
                    success: (res) => res.data.message,
                    error: (err) => {
                        if (err.response && err.response.status === 422) {
                            return err.response.data.message || "Bad Request";
                        } else {
                            return "An error occurred";
                        }
                    }
                }
            )
                .then(() => {

                })
                .catch(() => { })
                .finally(resolve)
        })
    }

    const onFilter = () => {
        isLoading(true);
        setTimeout(() => {
            props.refetch && props.refetch();
            props.toggle();
        }, 1000);
    };

    const hasTrueValue = Object.values(methods.getValues()).some(value => value === true)

    return (
        <FormProvider {...methods}>
            <Modal title="FILTER TABLE" isOpen closeModal={props.toggle}>
                <Modal.Body>
                    <div role="tablist" className="tabs tabs-lifted">
                        <TabPanelProfile handleCheckboxChange={handleCheckboxChange} />
                        <TabPanelAddress handleCheckboxChange={handleCheckboxChange} />
                        <TabPanelWorkStatus handleCheckboxChange={handleCheckboxChange} />
                        <TabPanelBank handleCheckboxChange={handleCheckboxChange} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="button" color="warning" onClick={() => onFilter()} loading={loading ? 'true' : undefined}>{`FILTER ${hasTrueValue ? 'ON' : 'OFF'}`}</Button>
                </Modal.Footer>
            </Modal>
        </FormProvider>
    )
}

function TabPanelProfile(props: {
    handleCheckboxChange: (value: ColumnConfigType) => void
}) {
    const { control, setValue } = useFormContext<FilterType>()

    return (
        <Fragment>
            <input type="radio" name="my_tabs_2" role="tab" className="tab" aria-label="Profile" checked />
            <div role="tabpanel" className="tab-content bg-base-100 border-base-300 rounded-box p-6">
                <div>
                    <Controller
                        control={control}
                        name="nomorNationalId"
                        render={({ field }) => (
                            <Checkbox
                                {...field}
                                className="mb-2"
                                ref={null}
                                onChange={(e) => {
                                    setValue("nomorNationalId", e.target.checked);
                                    props.handleCheckboxChange({ module: 'employee', column_code: 'emNaId', column_name: 'nomor nasional id', column_status: e.target.checked });
                                }}
                            >
                                Nomor Of Nationality Id
                            </Checkbox>
                        )}
                    />

                    <Controller
                        control={control}
                        name="religion"
                        render={({ field }) => (
                            <Checkbox
                                {...field}
                                className="mb-2"
                                ref={null}
                                onChange={(e) => {
                                    setValue("religion", e.target.checked);
                                    props.handleCheckboxChange({ module: 'employee', column_code: 'emRel', column_name: 'religion', column_status: e.target.checked });
                                }}
                            >
                                Religion
                            </Checkbox>
                        )}
                    />

                    <Controller
                        control={control}
                        name="handphone"
                        render={({ field }) => (
                            <Checkbox
                                {...field}
                                className="mb-2"
                                ref={null}
                                onChange={(e) => {
                                    setValue("handphone", e.target.checked);
                                    props.handleCheckboxChange({ module: 'employee', column_code: 'emHp', column_name: 'handphone', column_status: e.target.checked });
                                }}
                            >
                                Handphone
                            </Checkbox>
                        )}
                    />

                    <Controller
                        control={control}
                        name="email"
                        render={({ field }) => (
                            <Checkbox
                                {...field}
                                className="mb-2"
                                ref={null}
                                onChange={(e) => {
                                    setValue("email", e.target.checked);
                                    props.handleCheckboxChange({ module: 'employee', column_code: 'emEmail', column_name: 'email', column_status: e.target.checked });
                                }}
                            >
                                Email
                            </Checkbox>
                        )}
                    />
                </div>
            </div>
        </Fragment>
    )
}

function TabPanelAddress(props: {
    handleCheckboxChange: (value: ColumnConfigType) => void
}) {

    const { control, setValue } = useFormContext<FilterType>()

    return (
        <Fragment>
            <input type="radio" name="my_tabs_2" role="tab" className="tab" aria-label="Address" />
            <div role="tabpanel" className="tab-content bg-base-100 border-base-300 rounded-box p-6">
                <div className="grid grid-cols-2">
                    <div>
                        <Controller
                            control={control}
                            name="country"
                            render={({ field }) => (
                                <Checkbox
                                    {...field}
                                    className="mb-2"
                                    ref={null}
                                    onChange={(e) => {
                                        setValue("country", e.target.checked);
                                        props.handleCheckboxChange({ module: 'employee', column_code: 'emCount', column_name: 'country', column_status: e.target.checked });
                                    }}
                                >
                                    Country
                                </Checkbox>
                            )}
                        />

                        <Controller
                            control={control}
                            name="province"
                            render={({ field }) => (
                                <Checkbox
                                    {...field}
                                    className="mb-2"
                                    ref={null}
                                    onChange={(e) => {
                                        setValue("province", e.target.checked);
                                        props.handleCheckboxChange({ module: 'employee', column_code: 'emProv', column_name: 'province', column_status: e.target.checked });
                                    }}
                                >
                                    Province
                                </Checkbox>
                            )}
                        />

                        <Controller
                            control={control}
                            name="regency"
                            render={({ field }) => (
                                <Checkbox
                                    {...field}
                                    className="mb-2"
                                    ref={null}
                                    onChange={(e) => {
                                        setValue("regency", e.target.checked);
                                        props.handleCheckboxChange({ module: 'employee', column_code: 'emReg', column_name: 'province', column_status: e.target.checked });
                                    }}
                                >
                                    Regency
                                </Checkbox>
                            )}
                        />
                    </div>
                    <div>
                        <Controller
                            control={control}
                            name="subdistrict"
                            render={({ field }) => (
                                <Checkbox
                                    {...field}
                                    className="mb-2"
                                    ref={null}
                                    onChange={(e) => {
                                        setValue("subdistrict", e.target.checked);
                                        props.handleCheckboxChange({ module: 'employee', column_code: 'emSub', column_name: 'subdistrict', column_status: e.target.checked });
                                    }}
                                >
                                    Subdistrict
                                </Checkbox>
                            )}
                        />

                        <Controller
                            control={control}
                            name="village"
                            render={({ field }) => (
                                <Checkbox
                                    {...field}
                                    className="mb-2"
                                    ref={null}
                                    onChange={(e) => {
                                        setValue("village", e.target.checked);
                                        props.handleCheckboxChange({ module: 'employee', column_code: 'emVil', column_name: 'village', column_status: e.target.checked });
                                    }}
                                >
                                    Village
                                </Checkbox>
                            )}
                        />

                        <Controller
                            control={control}
                            name="address"
                            render={({ field }) => (
                                <Checkbox
                                    {...field}
                                    className="mb-2"
                                    ref={null}
                                    onChange={(e) => {
                                        setValue("address", e.target.checked);
                                        props.handleCheckboxChange({ module: 'employee', column_code: 'emAdd', column_name: 'address', column_status: e.target.checked });
                                    }}
                                >
                                    Address
                                </Checkbox>
                            )}
                        />

                    </div>
                </div>
            </div>
        </Fragment>
    )
}

function TabPanelWorkStatus(props: {
    handleCheckboxChange: (value: ColumnConfigType) => void
}) {

    const { control, setValue } = useFormContext<FilterType>()

    return (
        <Fragment>
            <input type="radio" name="my_tabs_2" role="tab" className="tab" aria-label="Work Status" />
            <div role="tabpanel" className="tab-content bg-base-100 border-base-300 rounded-box p-6">
                <div>
                    <Controller
                        control={control}
                        name="departement"
                        render={({ field }) => (
                            <Checkbox
                                {...field}
                                className="mb-2"
                                ref={null}
                                onChange={(e) => {
                                    setValue("departement", e.target.checked);
                                    props.handleCheckboxChange({ module: 'employee', column_code: 'emDept', column_name: 'departement', column_status: e.target.checked });
                                }}
                            >
                                Departement
                            </Checkbox>
                        )}
                    />

                    <Controller
                        control={control}
                        name="divisi"
                        render={({ field }) => (
                            <Checkbox
                                {...field}
                                className="mb-2"
                                ref={null}
                                onChange={(e) => {
                                    setValue("divisi", e.target.checked);
                                    props.handleCheckboxChange({ module: 'employee', column_code: 'emDiv', column_name: 'divisi', column_status: e.target.checked });
                                }}
                            >
                                Divisi
                            </Checkbox>
                        )}
                    />

                    <Controller
                        control={control}
                        name="gender"
                        render={({ field }) => (
                            <Checkbox
                                {...field}
                                className="mb-2"
                                ref={null}
                                onChange={(e) => {
                                    setValue("gender", e.target.checked);
                                    props.handleCheckboxChange({ module: 'employee', column_code: 'emGen', column_name: 'gender', column_status: e.target.checked });
                                }}
                            >
                                Gender
                            </Checkbox>
                        )}
                    />

                    <Controller
                        control={control}
                        name="employeeStatus"
                        render={({ field }) => (
                            <Checkbox
                                {...field}
                                className="mb-2"
                                ref={null}
                                onChange={(e) => {
                                    setValue("employeeStatus", e.target.checked);
                                    props.handleCheckboxChange({ module: 'employee', column_code: 'emEmSt', column_name: 'employee status', column_status: e.target.checked });
                                }}
                            >
                                Employee Status
                            </Checkbox>
                        )}
                    />
                </div>
            </div>
        </Fragment>
    )
}

function TabPanelBank(props: {
    handleCheckboxChange: (value: ColumnConfigType) => void
}) {

    const { control, setValue } = useFormContext<FilterType>()

    return (
        <Fragment>
            <input type="radio" name="my_tabs_2" role="tab" className="tab" aria-label="Bank" />
            <div role="tabpanel" className="tab-content bg-base-100 border-base-300 rounded-box p-6">
                <div>
                    <Controller
                        control={control}
                        name="bank"
                        render={({ field }) => (
                            <Checkbox
                                {...field}
                                className="mb-2"
                                ref={null}
                                onChange={(e) => {
                                    setValue("bank", e.target.checked);
                                    props.handleCheckboxChange({ module: 'employee', column_code: 'emBank', column_name: 'bank', column_status: e.target.checked });
                                }}
                            >
                                Bank
                            </Checkbox>
                        )}
                    />

                    <Controller
                        control={control}
                        name="branch"
                        render={({ field }) => (
                            <Checkbox
                                {...field}
                                className="mb-2"
                                ref={null}
                                onChange={(e) => {
                                    setValue("branch", e.target.checked);
                                    props.handleCheckboxChange({ module: 'employee', column_code: 'emBr', column_name: 'branch', column_status: e.target.checked });
                                }}
                            >
                                Branch
                            </Checkbox>
                        )}
                    />

                    <Controller
                        control={control}
                        name="accountBankName"
                        render={({ field }) => (
                            <Checkbox
                                {...field}
                                className="mb-2"
                                ref={null}
                                onChange={(e) => {
                                    setValue("accountBankName", e.target.checked);
                                    props.handleCheckboxChange({ module: 'employee', column_code: 'emBaN', column_name: 'account bank name', column_status: e.target.checked });
                                }}
                            >
                                Account Bank Name
                            </Checkbox>
                        )}
                    />
                </div>
            </div>
        </Fragment>
    )
}
