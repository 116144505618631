// React
import { PropsWithChildren } from "react"

// Components
import { BaseTable } from "components"

export function TableSection(params: PropsWithChildren<{}>): JSX.Element {
  return (
    <BaseTable
      plain
      className="text-right"
    >
      <tbody>
        {params.children}
      </tbody>
    </BaseTable>
  )
}

function Row(params: {
  label: string
  value: string
}): JSX.Element {
  return (
    <tr>
      <td className="w-[1px] whitespace-nowrap">{params.label}</td>
      <td>{params.value}</td>
    </tr>
  )
}

TableSection.Row = Row