// Form
import * as yup from "yup"

export const validationSchema = yup.object().shape({
  qty_order: yup.number().defined(),
  qty_multiply: yup.number().defined(),
  project_id: yup.string().defined(),
  project_name: yup.string().defined(),
  mr_unit_id: yup.string().defined(),
  quantity_unit: yup.number().defined(),
  product_id: yup.string().label("Item Order").required(),
  discount: yup.number().label("Discount").required(),
  total: yup.number().label("Total").required(),
  tax_id: yup.string().label("Tax").required(),
  quantity_bill: yup.number().label("Quantity").required().min(1).max(yup.ref("qty_order")),
  price_bill: yup.number().label("Price").required()
})

export type FormType = yup.InferType<typeof validationSchema>