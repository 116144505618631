import { useEffect, useState } from "react"
import type { SuccessFetch } from "types"
import { useApi } from "utils"
import { DataList } from "../../types"

export function useList() {
  const api = useApi()
  const [data, setData] = useState<DataList[]>([])
  const [isLoading, setLoading] = useState(false)

    useEffect(() => {

      api.get("/inventoryjournal/", { params: { by: 1, refID: 7, start_date: "2023-10-10", end_date: "2024-10-17" } })
      .then((res: SuccessFetch<{ payload: DataList[] }>) => setData(res.data.payload))
      .catch(() => setData([]) )
      .finally(() => setLoading(false))
    
      return () => {
        setData([])
        setLoading(true)
      }
  
      // eslint-disable-next-line
    }, [])

  return { data, isLoading }
}