import { useEffect, useState } from "react"
import type { SuccessFetch } from "../../../../../../../../types"
import { useApi } from "../../../../../../../../utils"
import { ProductList } from "../../../../types"
import { ErrorFetch } from "../../../../../../../../types"

export function useProductByVendorID(id: string) {
  const api = useApi()
  const [dataByVendorID, setData] = useState<ProductList[] | null>(null)
  const [dataError, setError] = useState<string | undefined>(undefined)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    api.get("/product/byvendor", { params: { vendor_id: id } })
    .then((res: SuccessFetch<{ payload: ProductList[] }>) => setData(res.data.payload))
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err.response.data.detail.message))
    .finally(() => setLoading(false))
  
    return () => {
      setData(null)
      setError(undefined)
      setLoading(true)
    }
    // eslint-disable-next-line
  }, [])

  return { dataByVendorID, dataError, isLoading }
}