import { Fragment } from "react";

export function SummarySection (props: {
    label: string
    value: string
}) {
    return (
        <Fragment>
            <div className="flex justify-between">
                <div className="w-[160px] font-bold">{props.label}</div>
                <div>:</div>
                <div className="ml-2 font-bold text-right flex-1">{props.value}</div>
            </div>
        </Fragment>
    )
}