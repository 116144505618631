// React
import { Fragment } from "react"

// Components
import { Loading, Modal } from "../../../components"
import { CoaList } from "../CoaList"

// Utils
import { useAccountGroup } from "../../../utils"

export function AccountModal(props: {
  cheque_coa_condition?: boolean
  disabledCoaGroup?: boolean
  defaultGroup?: string
  onChange: (value: string, label: string) => void
  toggle: () => void
}) {
  // Hooks
  const { data, isLoading } = useAccountGroup()

  return (
    <Modal isOpen title="" size="full" closeModal={props.toggle}>
      <Modal.Body>
        {isLoading || data.length === 0 ? (
          <section className="my-5">
            <Loading loading={isLoading} />
          </section>
        ) : (
          <Fragment>
            <section className="flex justify-center mb-3">
              <div className="border-b text-xl font-bold">ACCOUNT</div>
            </section>

            <CoaList
              cheque_coa_condition={props.cheque_coa_condition}
              disabledCoaGroup={props.disabledCoaGroup}
              defaultGroup={props.defaultGroup}
              data={data}
              onAddCoa={(value, label) => {
                props.onChange(value, label)
                props.toggle()
              }}
            />
          </Fragment>
        )}
      </Modal.Body>
    </Modal>
  )
}
