import { Button, DateInput } from "components"
import moment from "moment"
import { getDateTypeLabelFNL } from "pages/Finance/Report/components/TabProfitLoss/utils/functions"
import { FilterReportPNL } from "pages/Finance/Report/types"
import { Fragment, RefObject } from "react"
import { useFormContext } from "react-hook-form"
import { useToggle } from "utils"
import {
  prevMonth,
  prevQuarter,
  prevYear,
  thisMonth,
  thisQuarter,
  thisYear,
  today
} from "utils/functions"

export type DateRangeProps = {
  buttonDateRef: RefObject<HTMLDivElement>
  toggleButtonDate: ReturnType<typeof useToggle>
}

export const DateRange = ({
  toggleButtonDate,
  buttonDateRef
}: DateRangeProps) => {
  const methods = useFormContext<FilterReportPNL>()
  const [dateType, date, dateTo] = methods.watch(["dateType", "date", "dateTo"])
  const isDisableDatePick = dateType !== "1"
  const defaultButtonDateOption: {
    label: FilterReportPNL["dateType"]
    start: string
    end: string
    divider?: boolean
  }[] = [
    {
      label: "1",
      start: thisMonth().fromDate,
      end: thisMonth().toDate
    },
    {
      label: "2",
      start: thisMonth().fromDate,
      end: thisMonth().toDate
    },
    {
      label: "3",
      start: thisQuarter().fromDate,
      end: thisQuarter().toDate
    },
    {
      label: "4",
      start: thisYear().fromDate,
      end: thisYear().toDate,
      divider: true
    },
    {
      label: "5",
      start: prevMonth().fromDate,
      end: prevMonth().toDate
    },
    {
      label: "6",
      start: prevQuarter().fromDate,
      end: prevQuarter().toDate
    },
    {
      label: "7",
      start: prevYear().fromDate,
      end: prevYear().toDate,
      divider: true
    },
    {
      label: "8",
      start: thisMonth().fromDate,
      end: today()
    },
    {
      label: "9",
      start: thisQuarter().fromDate,
      end: today()
    },
    {
      label: "10",
      start: thisYear().fromDate,
      end: today()
    }
  ]

  return (
    <Fragment>
      <div className="flex justify-start items-end">
        <div>
          <label className="label">
            <span className="label-text font-bold ">Date Range</span>
          </label>
          <Button
            type="button"
            color="primary"
            className="rounded-l-lg text-start text-ellipsis text-nowrap w-[88px]"
            onClick={() =>
              toggleButtonDate.setActive(!toggleButtonDate.isActive)
            }>
            {getDateTypeLabelFNL(dateType)}
          </Button>
        </div>

        <DateInput
          disabled={isDisableDatePick}
          className="rounded-l-none rounded-r-none w-[135px]"
          selected={moment(date).toDate()}
          onChange={(date) => {
            const selectedDate = moment(date).format("YYYY-MM-DD")
            methods.setValue("date", selectedDate)
            methods.setValue("dateType", "1")
          }}
        />

        <DateInput
          disabled={isDisableDatePick}
          className="rounded-l-none w-[135px]"
          selected={moment(dateTo).toDate()}
          onChange={(date) => {
            const selectedDate = moment(date).format("YYYY-MM-DD")
            methods.setValue("dateTo", selectedDate)
            methods.setValue("dateType", "1")
          }}
        />
      </div>

      {toggleButtonDate.isActive && (
        <div
          tabIndex={toggleButtonDate.isActive ? 1 : 0}
          onBlurCapture={() => toggleButtonDate.setActive(false)}
          ref={buttonDateRef}
          className="absolute w-full flex flex-col border justify-start border-gray-100 top-[5.7rem] z-10 bg-white dark:bg-black rounded-lg">
          {defaultButtonDateOption.map((date) => (
            <Fragment>
              <Button
                key={date.label}
                className="flex justify-between items-center "
                type="button"
                color="ghost"
                onClick={() => {
                  methods.setValue("date", date.start)
                  methods.setValue("dateTo", date.end)
                  methods.setValue("dateType", date.label)
                  toggleButtonDate.setActive(false)
                }}>
                <span>{getDateTypeLabelFNL(date.label)}</span>
                <span>{`${moment(date.start).format("DD MMM")} - ${moment(
                  date.end
                ).format("DD MMM YYYY")}`}</span>
              </Button>

              {date.divider ? (
                <div className="border-t border-gray-100" />
              ) : null}
            </Fragment>
          ))}
        </div>
      )}
    </Fragment>
  )
}
