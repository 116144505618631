// Components
import { Button, Modal } from "../../../../components"
import { FormSection } from "./FormSection"

// Form
import * as yup from "yup"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

// Third-Party Libraries
import toast from "react-hot-toast"

// Types
import type { FormType } from "../types"

// Utils
import { regex_validation, useApi } from "../../../../utils"
import { getSubmitValue, normal_post_list } from "../utils"

export function CreateModal(props: {
  account_type_id?: string
  group_coa_code?: string
  group_coa_id?: string
  parent_id?: number
  parent_level: number
  parent_name?: string
  report_type?: string
  onSuccess: () => void
  toggle: () => void
}) {
  // Vars
  const number_only = regex_validation.number_only

  const accountTypeYup = () => {
    if (props.parent_level === 1) {
      return { account_type_id: yup.string().label("Account Type").defined().nullable() }
    }

    return { account_type_id: yup.string().label("Account Type").required().nullable() }
  }

  // Hooks
  const api = useApi()

  // Form
  const defaultValues = {
    coa_code: "",
    coa_name: "",
    group_coa_id: props.group_coa_id ?? "",
    group_coa_code: props.group_coa_code ?? "",
    account_type_id: props.account_type_id ?? "",
    coa_level: props.parent_level.toString(),
    header_level: props.parent_level === 1 ? "1" : "",
    normal_pos: props.group_coa_id ? normal_post_list[props.group_coa_id] : "",
    opening_balance: 0,
    cheque_coa: false,
    note: "Note",
    report_type: props.report_type ?? "",
    parent_name: props.parent_name ?? ""
  }
  const validationSchema = yup.object().shape({
    coa_code: yup.string().label("Account Number").required(),
    coa_name: yup.string().label("Account Name").required(),
    group_coa_id: yup.string().label("Account Group").required().matches(number_only.value, number_only.description),
    group_coa_code: yup.string().defined(),
    coa_level: yup.string().label("Level").required().matches(number_only.value, number_only.description),
    header_level: yup.string().label("Header Level").required().matches(number_only.value, number_only.description),
    normal_pos: yup.string().label("Normal Post").required().matches(number_only.value, number_only.description),
    opening_balance: yup.number().label("Opening Balance").required(),
    cheque_coa: yup.boolean().label("Cheque Account").required(),
    note: yup.string().label("Note").required().nullable(),
    report_type: yup.string().label("Report Type").required().nullable(),
    parent_name: yup.string().defined(),
    ...accountTypeYup()
  })
  const onSubmit = (value: FormType) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.post("/coa/add", getSubmitValue(value, props.parent_id)),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message
        }
      ).then(() => {
        props.toggle()
        props.onSuccess()
      }).catch(() => {}).finally(resolve)
    })
  }
  const methods = useForm<FormType>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })

  return (
    <Modal isOpen title="NEW CHART OF ACCOUNT" size="xl" closeModal={props.toggle}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <FormSection parent_id={props.parent_id} />

          <Modal.Footer className="text-end">
            <Button type="submit" color="primary" loading={methods.formState.isSubmitting ? "true" : undefined}>Save</Button>
          </Modal.Footer>
        </form>
      </FormProvider>
    </Modal>
  )
}