import { useEffect, useState } from "react"
import type { SuccessFetch } from "../../../../../../types"
import { useApi } from "../../../../../../utils" 

interface AcccountType {
  coa_id: string,
  coa_code: string,
  coa_name: string
}

export function useAccountKredit() {

  const api = useApi()
  const [dataAccount, setData] = useState<AcccountType | null>(null)
  const [isLoading, setLoading] = useState(true)

  const fetchUrl = () => {
      return api.get("/opening-stock/la03")
  }

  const fetchData = () => {
    setLoading(true)

    fetchUrl()
    // .then((res: SuccessFetch<{ payload: DataType }>) => {
      .then((res: SuccessFetch<{
        payload: {
          coa_id: string,
          coa_code: string,
          coa_name: string
        } }>) => {
      setData(res.data.payload)
    })
    .catch(() => {
      setData(null)
    }).finally(() => {
      setLoading(false)
    })
  }

  const refetch = () => fetchData()

  useEffect(() => {
    fetchData()

    return () => {
      setData(null)
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [])

  return { dataAccount, isLoading, refetch }
}

