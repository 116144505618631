// React
import { Fragment } from "react"

// Components
import { Loading } from "../../../../components"
import { Item } from "./Item"

// Utils
import { useCoaList } from "../../../../utils"

export function ChildItem(props: {
  level: number
  parent_id?: number
  group_id?: string
  cheque_coa_condition?: boolean
}) {
  // Hooks
  const { data, isLoading } = useCoaList(props.parent_id, props.group_id)

  return (
    <Fragment>
      {isLoading || data.length === 0 ? (
        <tr>
          <td colSpan={6} className="text-center">
            <Loading loading={isLoading} errorText="No data available" />
          </td>
        </tr>
      ) : (
        data.map((item, key) => (
          <Item
            cheque_coa_condition={props.cheque_coa_condition}
            key={key}
            index={key}
            item={item}
            level={props.level + 1}
            parent_id={props.parent_id}
          />
        ))
      )}
    </Fragment>
  )
}
