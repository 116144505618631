import { Fragment } from "react"
import { HeaderMenu } from "components"
import { useLocation, useSearchParams } from "react-router-dom" 
import { TabRegisterPath } from "./types/TabRegisterPath"
import { TabNavFind } from "./components/TabNavFind"
import { FindByFinance } from "./components"
import { FindByInventory } from "./components/TabInventory"

type TabsSearchParams = { tabs: TabRegisterPath }

export default function Find() {
  const location = useLocation()
  const path = location?.pathname.split('/');
  const firstPath = path[1];

  const pathMapping: { [key: string]: string } = {
    accounts: "accounts",
    finance: "finance",
    sales: "sales",
    purchase: "purchase",
    inventory: "inventory",
    cards: "cards",
    journals: "journals",
  };
  
  const result = pathMapping[firstPath] ?? "accounts";
  const [searchParams, setSearchParams] = useSearchParams({ tabs: result } as TabsSearchParams)

  const activeTab = searchParams.get("tabs") 
  const setActiveTab = (path: string) => setSearchParams({ tabs: path })

  return (
    <Fragment>
      <section className="container my-5">
        <HeaderMenu title="FIND RECORDS" />

        <section className="mt-1">
          <TabNavFind activeTab={activeTab!} setActiveTab={setActiveTab} />

          {activeTab === "accounts" && <div />}
          {activeTab === "finance" && <FindByFinance />}
          {activeTab === "sales" && <div />}
          {activeTab === "purchase" && <div />}
          {activeTab === "inventory" && <FindByInventory />}
          {activeTab === "cards" && <div />}
          {activeTab === "journals" && <div />}
        </section>
      </section>
    </Fragment>
  )
}