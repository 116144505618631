/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { today } from "utils/functions"
import { FormTransferItemsType } from "../types"
import { useDetailTransferItem, useProductAvailableList } from "../utils/hooks"
import { FormSection } from "./FormSections"

type TransferItemsDetailProps = {
  transfer_id: string
}

export default function TransferItemsDetail({
  transfer_id
}: TransferItemsDetailProps) {
  const transfer = useDetailTransferItem({
    transfer_id
  })
  const productAvailable = useProductAvailableList({
    location_id: transfer.data?.datatransfer.location_from_id ?? NaN
  })

  const defaultValues: FormTransferItemsType = useMemo(
    () => ({
      transfer_id: transfer.data?.datatransfer.transfer_id,
      trx_code: transfer.data?.datatransfer.trx_code ?? "",
      location_from_id: transfer.data?.datatransfer.location_from_id ?? NaN,
      location_to_id: transfer.data?.datatransfer.location_to_id ?? NaN,
      employee_id: transfer.data?.datatransfer.employee_id ?? NaN,
      transaction_date: transfer.data?.datatransfer.transaction_date ?? today(),
      memo: transfer.data?.datatransfer.memo ?? "",
      transfer_status: transfer.data?.datatransfer.transfer_status ?? 0,
      transfer_detail:
        transfer.data?.detailtransfer?.map((item) => ({
          transfer_detail_id: item?.transfer_detail_id,
          product_name: item.product_name,
          product_barcode: item.product_barcode,
          mr_unit_name: item.mr_unit_name,
          product_id: item.product_id,
          quantity_transfer: item.quantity_transfer,
          soh:
            productAvailable.data?.find((i) => i.product_id === item.product_id)
              ?.soh ?? 0
        })) ?? [],
      location_from_name: transfer.data?.datatransfer.location_from_name,
      location_to_name: transfer.data?.datatransfer.location_to_name,
      approved_by_name: transfer.data?.datatransfer.approve_by_name ?? "",
      approval_status: transfer.data?.datatransfer.approve_status ?? 0,
      approval_status_text:
        transfer.data?.datatransfer.approve_status_text ?? ""
    }),
    [productAvailable.data]
  )

  const methods = useForm<FormTransferItemsType>({
    defaultValues
  })

  useEffect(() => methods.reset(defaultValues), [defaultValues])

  return (
    <FormProvider {...methods}>
      <FormSection approvalView isEdit onSubmit={() => {}} />
    </FormProvider>
  )
}
