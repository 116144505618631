/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup"
import { useDetailSalesOrder } from "pages/Sales/Transaction/CustomerOrder/utils/hooks"
import { useEffect, useMemo } from "react"
import { FormProvider, useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { useNavigate, useParams } from "react-router-dom"
import { useApi } from "utils"
import { today } from "utils/functions"
import { useDetailInvoice } from "../Invoice/utils/hooks/useDetailInvoice"
import { FormSection } from "./components"
import { formReturnItemsSchema, FormReturnItemsType } from "./types"
import { submitMap } from "./utils/functions"
import { useDetailReturnItem } from "./utils/hooks/useDetailReturnItem"

export default function ReturnItemsEdit() {
  const navigate = useNavigate()
  const api = useApi()
  const params = useParams()
  const returnItem = useDetailReturnItem({
    return_id: params?.return_id ?? ""
  })
  const invoice = useDetailInvoice({
    invoice_id: returnItem.data?.datareturn.invoice_id.toString() ?? ""
  })
  const sales = useDetailSalesOrder({
    sales_order_id: invoice.data?.datainvoice.sales_order_id.toString() ?? ""
  })

  const defaultValues: FormReturnItemsType = useMemo(
    () => ({
      return_id: Number(params?.return_id) ?? NaN,
      invoice_id: returnItem.data?.datareturn.invoice_id,
      trx_code: returnItem.data?.datareturn.trx_code,
      customer_id: returnItem.data?.datareturn.customer_id ?? NaN,
      customer_name: returnItem.data?.datareturn.customer_name,
      location_id: returnItem.data?.datareturn.location_id ?? NaN,
      shipped: returnItem.data?.datareturn.address ?? "",
      memo: returnItem.data?.datareturn.memo ?? "Return Item transaction",
      transaction_date: returnItem.data?.datareturn.transaction_date ?? today(),
      referensi: returnItem.data?.datareturn.referensi ?? "",
      approval_status_text:
        returnItem.data?.datareturn.approve_status_text ?? "",
      approved_by_name: returnItem.data?.datareturn.approve_by_name ?? "",
      so_payment_status: sales.data?.dataso.so_payment_status ?? NaN,
      so_payment_status_text: sales.data?.dataso.so_payment_status_text,
      employee_id: sales.data?.dataso.employee_id ?? NaN,
      payment_term: sales.data?.dataso.payment_term ?? NaN,
      sales_order_id: sales.data?.dataso.sales_order_id ?? NaN,
      link: invoice.data?.datainvoice?.trx_code ?? "",
      delivery_no: invoice.data?.datainvoice.delivery_no ?? 1,
      promize_date: sales.data?.dataso?.promize_date ?? today(),
      tax_inclusive: true,
      payment_term_name: sales.data?.dataso?.payment_term_name ?? "",
      price_level_name: sales.data?.dataso?.price_level_name ?? "",
      return_detail: returnItem.data?.detailreturn?.map((item) => {
        const qtyInvoice =
          invoice.data?.detailinvoice?.find(
            (so) => so.product_barcode === item.product_barcode
          )?.quantity_invoice ?? 0
        const qtyReturned = item.quantity_return
        const qtyBackDelivered = qtyInvoice - qtyReturned
        return {
          return_detail_id: item.return_detail_id,
          quantity_back_delivered: qtyBackDelivered,
          quantity_invoice: qtyInvoice,
          quantity_returned: qtyReturned,
          product_id: item.product_id,
          product_name: item.product_name,
          product_barcode: item.product_barcode ?? "",
          mr_unit_id: item.unit_convention_id,
          mr_unit_name: item.unit_convention_name,
          price_invoice: item.price_return,
          discount: item.discount_return,
          total: item.total_return,
          tax_id: item.tax_id,
          tax_name: `${item.tax_name} (${item.tax_rate}%)`,
          tax_rate: item.tax_rate
        }
      }),
      invoice_detail: invoice.data?.detailinvoice?.map((inv) => {
        const qtyInvoice = inv.quantity_invoice ?? 0
        const qtyReturned = inv.quantity_returned ?? 0
        const qtyBackDelivered = qtyInvoice - qtyReturned
        return {
          quantity_back_delivered: qtyBackDelivered,
          quantity_invoice: qtyInvoice,
          quantity_returned: qtyReturned,
          product_id: inv.product_id,
          product_name: inv.product_name,
          product_barcode: inv.product_barcode,
          mr_unit_id: inv.unit_convention_id,
          mr_unit_name: inv.mr_unit_name,
          price_invoice: inv.price_invoice,
          discount: inv.discount,
          total: inv.total,
          tax_id: inv.tax_id,
          tax_name: `${inv.tax_name} (${inv.tax_rate}%)`,
          tax_rate: inv.tax_rate
        }
      }),
      freight: sales.data?.dataso?.freight ?? 0,
      approved_by: sales.data?.dataso?.approve_by ?? "",
      approval_status: 1,
      delivery_status: 1
    }),
    [sales.data]
  )

  const submitPromise = async (value: FormReturnItemsType) => {
    return await toast
      .promise(api.put("/return/edit", submitMap(value)), {
        loading: "Loading...",
        success: (res) => res.data.message,
        error: (err) =>
          err.response.data.detail?.message ?? err.response.data.message
      })
      .then(() => {
        navigate("/sales/register/", { replace: true })
      })
      .catch((err) => {
        throw new Error(err)
      })
  }

  const onSubmit = async (value: FormReturnItemsType) => {
    return await submitPromise(value)
  }

  const methods = useForm<FormReturnItemsType>({
    defaultValues,
    resolver: yupResolver(formReturnItemsSchema)
  })

  useEffect(() => methods.reset(defaultValues), [defaultValues])

  return (
    <FormProvider {...methods}>
      <FormSection isEdit onSubmit={methods.handleSubmit(onSubmit)} />
    </FormProvider>
  )
}
