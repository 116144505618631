import { Fragment, useEffect, useState } from "react"
import { ActionButton, Button, Card, CommandButton, HeaderMenu, Loading } from "../../../../../../../../../components"
import { FormProvider, useForm } from "react-hook-form"
import type { BuyingList, ComponentList, ProductList, ProfileList } from "../../../../../types"
import { useLocation, useNavigate } from "react-router-dom"
import { useCOGSManufacture, useDetailBuyingData, useDetailBuyingDataTable, useDetailPersonalData, useDetailPictureData, useDetailProfileData, useDetailSellingData, useIngredientFormulaTable, usePriceUnit } from "../utils"
import { RenderProvider } from "../../../../../utils"
import { Tab } from "./Tabs"
import { FormPersonalData } from "../../../../FormSection/FormPersonalData"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useApi } from "../../../../../../../../../utils"
import toast from "react-hot-toast"
import { FormProfile } from "../../../../FormSection/FormProfile"
import { FormBuying } from "../../../../FormSection/FormBuying"
import { FormComponent } from "../../../../FormSection/FormComponent"
import { SellingList } from "../../../../../types"
import { FormSelling } from "../../../../FormSection/FormSelling"
import { FormPictures } from "../../../../FormSection/FormPictures"
import { FormTransaction } from "../../../../FormSection/FormTransaction"
import { DataLocationForm } from "./DataLocationForm"
import { FormPerformance } from "../../../../FormSection/FormPerformence"

export function Update(props: { id: ProductList; size: ProductList; permission?: boolean }) {
  const navigate = useNavigate()
  return (
    <Fragment>
      <ActionButton.Update permission="IR013" className="join-item" onClick={() => navigate("/inventory/transaction/add-new-item/update", { state: { dataId: props.id, dataSize: props.size } })} />
    </Fragment>
  )
}
export function UpdatePage(props: {
  id?: string,
  size?: any,
  refetch?: () => void
}) {
  const navigate = useNavigate()
  const location = useLocation()
  const id = location?.state?.dataId?.product_id ?? props.id
  const size = location?.state?.dataSize ?? props.size
  const tabs = ["PROFILE", "BUYING", "COMPONENT", "SELLING", "LOCATION", "PERFORMENCE", "PICTURE", "TRANSACTION", "LOG"];

  const [activeTab, setActiveTab] = useState<number | undefined>(0);
  const [itemsProfile, setItemsProfile] = useState<any | undefined>({ buy: "", sell: "", inventory: "", action: "" });
  const [responseValue, setResponseValue] = useState<any>(undefined);
  const [checkLoading, setCheckLoading] = useState<boolean>(false)
  
  const { dataDetail, dataError, isLoading } = useDetailPersonalData(id ? id : null)
  const { dataProfile, errorProfile, isLoadingProfile, refetchProfile } = useDetailProfileData(id ? id : null)
  const { dataBuying, errorBuying, isLoadingBuying, refetchBuying } = useDetailBuyingData(id ? id : null)
  const { dataList, refetchBuyingTable } = useDetailBuyingDataTable(id ? id : null)
  const { dataComponentTable, errorComponentTable, isLoadingComponentTable, refetchComponentTable } = useIngredientFormulaTable(id ? id : null)
  const { dataCOGS, refetchCOGSManufacture } = useCOGSManufacture(id ? id : null)
  const { dataSelling, errorSelling, isLoadingSelling, refetchSelling } = useDetailSellingData(id ? id : null)
  const { dataPicture, errorPicture, isLoadingPicture, refetchPicture } = useDetailPictureData(id ? id : null)
  const { dataPriceUnit, isLoadingPriceUnit, refetchPriceUnit } = usePriceUnit(id ? id : null)

  return (
    <RenderProvider>
      <section className="container my-2">
        <HeaderMenu title="DATA ENTRY | CREATE NEW ITEM" />
        <Card>
          <Card.Body>
            <div className="flex sm:flex-row flex-col justify-between sm:items-center gap-3">
              <div className="text-xl">
                Item Name: <span className="font-bold">{dataDetail?.product_name}</span>
              </div>

              <Button
                type="button"
                className="!none" 
                color="primary"
                onClick={() => navigate("/inventory/transaction/add-new-item")}
              >
                CREATE NEW ITEM
              </Button>
            </div>
            <div className="lg:flex">
              <div className="lg:w-[34%] mr-0.5">
                <button className="bg-[#3c3c3c] px-4 py-2 w-full mr-0.5 text-white text-[12px]"><b>PRODUCT DATA</b></button>
                <div className={`max-w-sm w-full lg:max-w-full lg:flex h-[${activeTab === 1 || activeTab === 2 ? "" : "1600px"}]`}>
                  <div className="w-full border border-[#3c3c3c] lg:border-[#3c3c3c] p-6">
                    {isLoading || dataError 
                      ? <Loading errorText={dataError} loading={isLoading} />
                      : <DataForm 
                          data={dataDetail!} 
                          dataDefault={size!} 
                          id={dataDetail?.product_id!} 
                          setActiveTab={setActiveTab} 
                          dataProfile={dataProfile!} 
                          setItemsProfile={setItemsProfile} 
                          itemsProfile={itemsProfile}
                          setCheckLoading={setCheckLoading}
                        />
                    }
                  </div>
                </div>
              </div>
              <div className="lg:w-[66%]">
                <Tab tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} dataProfile={responseValue ?? dataProfile} className="text-[11px]" />
                <div className={`max-w-sm w-full lg:max-w-full lg:flex h-[${activeTab === 1 || activeTab === 2 ? "" : "1600px"}]`}>
                  <div className="w-full border border-[#3c3c3c] lg:border-[#3c3c3c] p-6 mr-0.5">
                    {activeTab === 0 
                      ? isLoadingProfile || errorProfile 
                        ? <Loading errorText={errorProfile} loading={isLoadingProfile} /> 
                        : <DataProfileForm 
                            dataProfile={dataProfile ?? dataDetail} 
                            setResponseValue={setResponseValue} 
                            setItemsProfile={setItemsProfile} 
                            itemsProfile={itemsProfile} 
                            isLoadingProfile={isLoadingProfile} 
                            refetchProfile={refetchProfile}
                          /> 
                      : ""
                    }
                    {activeTab === 1 
                      ? isLoadingBuying || errorBuying 
                        ? <Loading errorText={errorBuying} loading={isLoadingBuying} /> 
                        : <DataBuyForm 
                            dataBuying={dataBuying!} 
                            dataList={dataList!} 
                            id={id} 
                            setResponseValue={setResponseValue} 
                            checkLoading={checkLoading} 
                            setCheckLoading={setCheckLoading} 
                            refetchBuying={refetchBuying} 
                            refetchBuyingTable={refetchBuyingTable} 
                            refetch={props.refetch}
                          />
                      : ""
                    }
                    {activeTab === 2
                      ? isLoadingComponentTable || errorComponentTable 
                        ? <Loading errorText={errorComponentTable} loading={isLoadingComponentTable} /> 
                        : <DataComponentForm 
                            dataComponentTable={dataComponentTable!} 
                            id={id} 
                            setResponseValue={setResponseValue} 
                            itemsProfile={itemsProfile} 
                            dataCOGS={dataCOGS} 
                            dataBuying={dataBuying!}
                            refetchComponentTable={refetchComponentTable}
                            refetchCOGSManufacture={refetchCOGSManufacture}
                            refetchBuying={refetchBuying}
                            refetch={props.refetch}
                          />
                      : ""
                    }
                    { activeTab === 3
                      ? isLoadingSelling || isLoadingPriceUnit || errorSelling
                        ? <Loading errorText={errorSelling} loading={isLoadingSelling || isLoadingPriceUnit} />
                        : <DataSellingForm 
                            id={dataProfile?.product_id} 
                            checkLoading={checkLoading} 
                            setCheckLoading={setCheckLoading} 
                            discount={dataProfile?.discount_value} 
                            dataSelling={dataSelling} 
                            dataCOGS={dataCOGS!} 
                            dataBuying={dataBuying!}
                            dataProfile={dataProfile!}
                            refetchSelling={refetchSelling}
                            dataPriceUnit={dataPriceUnit}
                            refetchPriceUnit={refetchPriceUnit}
                          /> 
                      : ""
                    }
                    {activeTab === 4 && <DataLocationForm id={id} />}
                    {activeTab === 5
                      ? isLoadingPicture || errorPicture 
                        ? <Loading errorText={errorPicture} loading={isLoadingPicture} /> 
                        : <DataPerformanceForm id={id}  /> 
                      : ""
                    }
                    {activeTab === 6
                      ? isLoadingPicture || errorPicture 
                        ? <Loading errorText={errorPicture} loading={isLoadingPicture} /> 
                        : <DataPicturesForm dataPicture={dataPicture} refetchPicture={refetchPicture} id={id}  /> 
                      : ""
                    }
                    {activeTab === 7
                      ? isLoadingPicture || errorPicture 
                        ? <Loading errorText={errorPicture} loading={isLoadingPicture} /> 
                        : <DataTransactionForm id={id}  /> 
                      : ""
                    }
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
        <section className="mt-2">
            <Card>
              <Card.Body className="flex flex-col gap-5">
                <section className="flex flex-wrap justify-between items-center gap-3">
                  <section className="flex flex-wrap gap-3">
                    <CommandButton actiontype="help" />
                    <CommandButton actiontype="print" />
                    <CommandButton actiontype="email" />
                    <CommandButton actiontype="export" />
                  </section>
                </section>
              </Card.Body>
            </Card>
          </section>
      </section>
    </RenderProvider>
  )
}

function DataForm(props: {
  data: ProductList
  dataDefault: ProductList
  dataProfile: ProfileList
  id: number
  itemsProfile?: any
  toggle?: () => void
  setActiveTab: (newState: any) => void
  setItemsProfile: (newState: any) => void
  setCheckLoading: (newState: boolean) => void
}) {
  const api = useApi()
  const location = useLocation()

  const [fileValue, setFileValue] = useState<File | undefined>(undefined);
  const [isActiveSubmit, setIsActiveSubmit] = useState<any>(false)

  type CreatePersonalData = Pick<ProductList, 
    "product_code" | "product_barcode" | "product_name" | "mr_product_group_id" | "mr_product_style_id" |
    "main_size_id" | "mr_product_colour_id" | "mr_product_category_id" | "mr_category_customer_id" | "type" |
    "mr_product_material_id" | "Product_Foto" | "length" | "width" | "height" | "weight" | "weight_unit_id" | "universal_size" | "volume_size" | "size_type"
  >;
  const defaultValues: CreatePersonalData = {
    length: props.data?.length ?? "",
    width: props.data?.width ?? "",
    height: props.data?.height ?? "",
    weight: props.data?.weight,
    weight_unit_id: props.data?.weight_unit_id,

    product_code: props.data?.product_code!,
    product_barcode: props.data.product_barcode,
    product_name: props.data.product_name,
    mr_product_group_id: props.data?.mr_product_group_id!,
    mr_product_style_id: props.data.mr_product_style_id,
    main_size_id: props.data.main_size_id,
    mr_product_colour_id: props.data.mr_product_colour_id,
    mr_product_category_id: props.data.mr_product_category_id,
    mr_category_customer_id: props.data.mr_category_customer_id,
    mr_product_material_id: props.data.mr_product_material_id,
    Product_Foto: props.data?.product_foto ?? "",
    // universal_size: Boolean(props.data.main_size_id?.toString() !== props.dataDefault?.default_size),
    // volume_size: Boolean(props.data.main_size_id?.toString() === props.dataDefault?.default_size),
    universal_size: Boolean(props.data.size_type === "1"),
    volume_size: Boolean(props.data.size_type === "2"),
    size_type: props.data?.size_type ?? "",
    type: props.data?.type ?? "",
  }
  // const sizeYup = () => {
  //   if (props.universalValue === true) { return { main_size_id: yup.string().label("Size").required() } }
  //   return { main_size_id: yup.string().label("Size").defined().nullable() }
  // }
  const validationSchema = yup.object().shape({
    product_code: yup.string().label("Item barcode").required(),
    product_barcode: yup.string().label("Item barcode").required(),
    product_name: yup.string().label("Item name").required(),
    mr_product_group_id: yup.string().label("Item group").required(),
    mr_product_style_id: yup.string().label("Style").required(),
    main_size_id: yup.string().label("Size").required(),
    // ...sizeYup(),
    mr_product_colour_id: yup.string().label("Colour").required(),
    mr_product_category_id: yup.string().label("Category").required(),
    mr_category_customer_id: yup.string().label("Market segment").required(),
    mr_product_material_id: yup.string().label("Material").required(),
    Product_Foto: yup.string().label("Profile").optional(),
    length: yup.string().label("Length").optional(),
    width: yup.string().label("Width").optional(),
    height: yup.string().label("Height").optional(),
    weight: yup.string().label("Weight").required().required().max(2, 'Maximum code is 2 characters'),
    weight_unit_id: yup.string().required("Unit Required"),
    size_type: yup.string().required("Size type Required"),
    type: yup.string().required("Type Required"),
  })
  const methods = useForm<CreatePersonalData>({ 
    defaultValues,
    resolver: yupResolver(validationSchema)
  })
  const onSubmit = (value: ProductList) => {
    const formData = new FormData();
    
    formData.append('product_code', value?.product_code);
    formData.append('product_barcode', value?.product_barcode);
    formData.append('product_name', value?.product_name);
    formData.append('mr_product_group_id', value?.mr_product_group_id);
    formData.append('mr_product_style_id', value?.mr_product_style_id);
    if(value?.main_size_id){formData.append('main_size_id', value?.main_size_id)}
    // formData.append('main_size_id', value?.main_size_id!);
    formData.append('mr_product_colour_id', value?.mr_product_colour_id);
    formData.append('mr_product_category_id', value?.mr_product_category_id);
    formData.append('mr_category_customer_id', value?.mr_category_customer_id);
    formData.append('mr_product_material_id', value?.mr_product_material_id);
    if(value?.Product_Foto){formData.append(fileValue ? 'Product_Foto' : "", value?.Product_Foto)}
    formData.append('size_type', value?.size_type);
    formData.append('type', value?.type);

    const params: { [key: string]: string | undefined } = {};
    if (location?.state?.dataId?.product_id ?? props.id) params.product_id = location?.state?.dataId?.product_id ?? props.id;
    if (methods?.control?._formValues?.length) params.length = methods?.control._formValues.length;
    if (methods?.control?._formValues?.width) params.width = methods?.control._formValues.width;
    if (methods?.control?._formValues?.height) params.height = methods?.control._formValues.height;
    if (methods?.control?._formValues?.weight) params.weight = methods?.control._formValues.weight;
    if (methods?.control?._formValues?.weight_unit_id) params.weight_unit_id = methods?.control._formValues.weight_unit_id;


    return new Promise<void>((resolve) => {
      toast.promise(
        api.put("/product/edit", formData, { params }),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message
        }
      )
      .then(() => {
        props.setActiveTab(0)
        setIsActiveSubmit(true)
      })
      .catch(() => {})
      .finally(resolve)
    })
  }

  return (
    <FormProvider {...methods}>
      {/* @ts-ignore */}
      <FormPersonalData 
        action="UPDATE"
        onSubmit={onSubmit} 
        setFileValue={setFileValue} 
        isActiveSubmit={isActiveSubmit} 
        dataProfile={props.dataProfile} 
        itemsProfile={props.itemsProfile}
        setItemsProfile={props.setItemsProfile} 
        setCheckLoading={props.setCheckLoading}
      />
    </FormProvider>
  )
}
interface ProfileProps {
  dataProfile?: any
  itemsProfile?: any
  isLoadingProfile?: boolean
  setResponseValue: (newState: any) => void
  setItemsProfile: (newState: any) => void
  refetchProfile: () => void
}
const DataProfileForm: React.FC<ProfileProps> = ({ dataProfile, setResponseValue, setItemsProfile, itemsProfile, isLoadingProfile, refetchProfile }) => {
  const api = useApi()

  const defaultValues = {
    product_id: dataProfile?.product_id,
    active: dataProfile?.active ? true : false,
    inactive: dataProfile?.inactive ? true : false,
    damage: dataProfile?.damage ? true : false,
    build_item: dataProfile?.build_item ? true : false,
    buy: dataProfile?.buy ? true : false,
    sell: dataProfile?.sell ? true : false,
    inventory: dataProfile?.inventory ? true : false,

    online_description: dataProfile?.online_description ?? dataProfile?.product_name ?? "",
    coa_buy: dataProfile?.coa_buy ?? "",
    coa_sell: dataProfile?.coa_sell ?? "",
    coa_asset: dataProfile?.coa_asset ?? "",
    explanation: dataProfile?.explanation ?? "",
    discount_value: dataProfile?.discount_value ?? "",
    discount_start: dataProfile?.discount_start ?? "",
    
    is_apply: dataProfile?.discount_value || dataProfile?.discount_start ? true : false,
    is_online: dataProfile?.online_description ? true : false,
  }
  const coaYup = () => {
    if (itemsProfile?.buy === "B" && itemsProfile?.sell === "S" && itemsProfile?.inventory === "I") { return { coa_buy: yup.string().label("Cogs Account Costs").required() } }
    if (itemsProfile?.buy === "B" && itemsProfile?.sell === "S") { return { coa_buy: yup.string().label("Expences and Cogs").required() } }
    if (itemsProfile?.sell === "S" && itemsProfile?.inventory === "I") { return { coa_buy: yup.string().label("Cogs Account Costs").required() } }
    if (itemsProfile?.buy === "B" && itemsProfile?.inventory !== "I") { return { coa_buy: yup.string().label("Expences").required() } }

    return { coa_buy: yup.string().label("Expences").defined().nullable() }
  }
  const coaSellYup = () => {
    if (itemsProfile?.sell === "S") { return { coa_sell: yup.string().label("Income Account Sales").required() } }

    return { coa_sell: yup.string().label("Income Account Sales").defined().nullable() }
  }
  const coaAssetYup = () => {
    if (itemsProfile?.inventory === "I") { return { coa_asset: yup.string().label("Asset Account Inventory").required() } }

    return { coa_asset: yup.string().label("Asset Account Inventory").defined().nullable() }
  }
  const validationSchema = yup.object().shape({
    explanation: yup.string().label("Explanation").required(),
    ...coaYup(),
    ...coaSellYup(),
    ...coaAssetYup()
  })
  const methods = useForm<ProfileList>({  
    defaultValues,
    resolver: yupResolver(validationSchema)
  })
  const onSubmit = () => {
    const data = new URLSearchParams();

    const product_id = dataProfile?.product_id;
    data.append('product_id', String(product_id))
    data.append('active', methods.control?._formValues?.active);
    data.append('inactive', methods.control?._formValues?.inactive);
    data.append('damage', methods.control?._formValues?.damage);
    data.append('build_item', methods.control?._formValues?.build_item);
    data.append('buy', methods.control?._formValues?.buy);
    data.append('sell', methods.control?._formValues?.sell);
    data.append('inventory', methods.control?._formValues?.inventory);

    const params: { [key: string]: string | undefined } = {};
    if (methods.control?._formValues?.online_description) params.online_description = methods.control._formValues.online_description;
    if (methods.control?._formValues?.coa_buy) params.coa_buy = methods.control._formValues.coa_buy;
    if (methods.control?._formValues?.coa_sell) params.coa_sell = methods.control._formValues.coa_sell;
    if (methods.control?._formValues?.coa_asset) params.coa_asset = methods.control._formValues.coa_asset;
    if (methods.control?._formValues?.explanation) params.explanation = methods.control._formValues.explanation;
    if (methods.control?._formValues?.discount_value) params.discount_value = methods.control._formValues.discount_value;
    if (methods.control?._formValues?.discount_start) params.discount_start = methods.control._formValues.discount_start;

    return new Promise<void>((resolve) => {
      toast.promise(
        api.put("/product/updateprofile", data, { params }),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => {
            if (err.response && err.response.status === 422) {
              return err.response.data.message || "Bad Request";
            } else {
              return "An error occurred";
            }
          }
        }
      )
      .then((res) => {
        setResponseValue(res?.data?.payload?.[0])
        refetchProfile()
      })
      .catch(() => {})
      .finally(resolve)
    })
  }

  return (
    <FormProvider {...methods}>
      {/* @ts-ignore */}
      <FormProfile 
        action="UPDATE" 
        onSubmit={onSubmit} 
        setItemsProfile={setItemsProfile} 
        itemsProfile={itemsProfile} 
        isLoadingProfile={isLoadingProfile}  
      />
    </FormProvider>
  )
}
interface BuyProps {
  id?: number
  dataBuying: BuyingList
  dataList: any 
  checkLoading?: boolean
  setResponseValue: (newState: any) => void
  setCheckLoading: (newState: boolean) => void
  refetchBuying: () => void
  refetchBuyingTable: () => void
  refetch?: () => void
}
const DataBuyForm: React.FC<BuyProps> = ({ id, dataBuying, dataList, setResponseValue, checkLoading, setCheckLoading, refetchBuying, refetchBuyingTable, refetch }) => {
  const api = useApi()
  
  const defaultValues = {
    product_id : id?.toString(),
    unit_measure_id: dataBuying?.unit_measure_id ?? "",
    quantity_unit_measure: dataBuying?.quantity_unit_measure ?? "",
    price_unit_measure : dataBuying?.price_unit_measure ?? "",
    unit_convention_id : dataBuying?.unit_convention_id ?? "",
    quantity_unit_convention: dataBuying?.quantity_unit_convention ?? "",
    price_unit_convention: dataBuying?.price_unit_convention ?? "",
    datavalue: dataList ?? [],
  }
  const validationSchema = yup.object().shape({
    product_id: yup.string().label("Product").optional(),
    // unit_measure_id: yup.string().label("Unit measure").optional(),
    quantity_unit_measure: yup.string().label("Qty measure").optional(),
    price_unit_measure: yup.string().label("Price measure").optional(),
    
    unit_convention_id: yup.string().label("Unit convention").required(),
    quantity_unit_convention: yup.string().required("Quantity is a required! "),
    price_unit_convention: yup.string().label("Avarage").required(),

    vendor_id: yup.string().label("Vendor").optional(),
    vendor_code: yup.string().label("Vendor code").optional(),
    vendor_name: yup.string().label("Vendor name").optional(),
    vendor_description: yup.string().label("Vendor description").optional(),
    buying_price: yup.string().label("Buying price").optional(),
    tax_id: yup.string().label("Tax").optional(),
    tax_name: yup.string().label("Tax name").optional(),
    datavalue: yup.array().label("Data list").defined(),
  })
  const methods = useForm<BuyingList>({  
    defaultValues,
    resolver: yupResolver(validationSchema)
  })
  function getFinalValue(value: BuyingList) {
    const data_value: {
      product_id: any,
      vendor_id: string,
      vendor_description: string,
      buying_price: string,
      tax_id: number,
      product_buying_id: number | undefined
    }[] = []
  
    value.datavalue.map(item => {
      return data_value.push({
        product_id: id,
        product_buying_id: item.product_buying_id,
        vendor_id: item.vendor_id,
        vendor_description: item.vendor_description,
        buying_price: item.buying_price,
        tax_id: parseFloat(item.tax_id),
      })
    })
  
    return {
      product_id: id,
      unit_measure_id: parseFloat(value.unit_measure_id),
      quantity_unit_measure: value.quantity_unit_measure,
      price_unit_measure: value.price_unit_measure,
      unit_convention_id: value.unit_convention_id,
      quantity_unit_convention: value.quantity_unit_convention,
      price_unit_convention: value.price_unit_convention,
      DataProductBuying: { dataproductbuying: data_value },
    }
  }

  useEffect(() => 
    methods.setValue('datavalue', dataList) 
     // eslint-disable-next-line
  ,[dataList])

  const onSubmit = (value: BuyingList) => {
    return new Promise<void>((resolve) => {

      toast.promise(
        api.post("/product/buying", getFinalValue(value)),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message
        }
      )
      .then(() => { 
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        refetchBuying()
        refetchBuyingTable()
        refetch && refetch()
      })
      .catch(() => {})
      .finally(resolve)
    })
  }

  return (
    <FormProvider {...methods}>
      {/* @ts-ignore */}
      <FormBuying action="UPDATE" onSubmit={methods.handleSubmit(onSubmit)} checkLoading={checkLoading} setCheckLoading={setCheckLoading} setBuyingPrice={() => {}} />
    </FormProvider>
  )
}
interface ComponentProps {
  id?: number
  dataComponentTable?: ComponentList[]
  setResponseValue: (newState: any) => void
  itemsProfile?: any
  dataCOGS?: any
  dataBuying?: BuyingList
  refetchComponentTable: () => void
  refetchCOGSManufacture: () => void
  refetchBuying: () => void
  refetch?: () => void
}
const DataComponentForm: React.FC<ComponentProps> = ({ id, itemsProfile, dataComponentTable, dataCOGS, dataBuying, refetchComponentTable, refetchCOGSManufacture, refetchBuying, refetch }) => {
  type CreateComponentData = Pick<ComponentList, "mr_product_group_id" | "unit_build_id">;

  const defaultValues = { 
    mr_product_group_id: "2",
    unit_build_id: dataBuying?.unit_convention_id ?? ""
  }
  const validationSchema = yup.object().shape({ 
    mr_product_group_id: yup.string().label("Build component").required(),
    unit_build_id: yup.string().required("Build component is required!")
  })
  const methods = useForm<CreateComponentData>({  
    defaultValues,
    resolver: yupResolver(validationSchema)
  }) 

  return (
    <FormProvider {...methods}>
      {/* @ts-ignore */}
      <FormComponent 
        id={id} 
        action="UPDATE" 
        onSubmit={() => {}} 
        itemsProfile={itemsProfile} 
        dataComponentTable={dataComponentTable}
        dataCOGS={dataCOGS} 
        dataBuying={dataBuying!}
        refetchComponentTable={refetchComponentTable}
        refetchCOGSManufacture={refetchCOGSManufacture}
        refetchBuying={refetchBuying}
        refetch={refetch}
      />
    </FormProvider>
  )
}
interface SellingProps { 
  id?: number 
  discount?: string 
  checkLoading?: boolean
  setCheckLoading: (newState: boolean) => void
  dataSelling: SellingList[] 
  dataCOGS: ComponentList[]
  dataBuying?: BuyingList
  dataProfile?: ProfileList
  dataPriceUnit?: any
  refetchSelling: () => void
  refetchPriceUnit: () => void
}
const DataSellingForm: React.FC<SellingProps> = ({ id, checkLoading, setCheckLoading, discount, dataSelling, dataBuying, dataProfile, refetchSelling, dataPriceUnit, refetchPriceUnit }) => {
  const api = useApi()
  console.log(dataPriceUnit)

  const isBuyingTabDisabled = dataProfile?.buy === false ? dataProfile?.buy === false : dataProfile === undefined ? dataProfile === undefined : dataProfile === null
  const isComponentTabDisabled = dataProfile?.build_item === false ? dataProfile?.build_item === false : dataProfile === undefined ? dataProfile === undefined : dataProfile === null

  const defaultValues = { 
    product_id: id,
    product_price_unit_id: dataSelling?.[0]?.product_price_unit_id,
    product_component_cost_id: dataSelling?.[0]?.product_component_cost_id,
    overhead: dataSelling?.[0]?.overhead,
    sales_tax: dataSelling?.[0]?.sales_tax,
    other_changes: dataSelling?.[0]?.other_changes,
    cogm: dataSelling?.[0]?.cogm,
    mr_unit_id: dataSelling?.[0]?.mr_unit_id,
    quantity: dataSelling?.[0]?.quantity,
    tax_id: dataSelling?.[0]?.tax_id,
    price_retail: dataSelling?.[0]?.price_retail,
    wholesales_price: dataSelling?.[0]?.wholesales_price,
    distributor_price: dataSelling?.[0]?.distributor_price,
    factory_price: dataSelling?.[0]?.factory_price,
    online_price: dataSelling?.[0]?.online_price,
    special_arrangement_price: dataSelling?.[0]?.special_arrangement_price,
    
    // price_unit_convention: dataBuying?.price_unit_convention ?? "0",
    // component_at_cost: dataCOGS?.[0]?.component_at_cost ?? "0",
    price_unit_convention: !isBuyingTabDisabled ? dataBuying?.price_unit_convention : "0",
    component_at_cost: !isComponentTabDisabled ? dataBuying?.price_unit_convention : "0",
    discount_amound: discount,

    unit_measure_id: dataPriceUnit?.unit_measure_id,
    quantity_unit_measure: dataPriceUnit?.quantity_unit_measure,
    price_unit_measure: dataPriceUnit?.price_unit_measure,
    unit_convention_id: dataPriceUnit?.unit_convention_id,
    quantity_unit_convention: dataPriceUnit?.quantity_unit_convention,
    unit_sell_measure_id: dataPriceUnit?.unit_sell_measure_id,
    quantity_unit_sell_measure: dataPriceUnit?.quantity_unit_sell_measure,
    quantity_unit_sell_convention: dataPriceUnit?.quantity_unit_sell_convention
  }
  const methods = useForm<any>({ defaultValues })
  const onSubmit = () => {
    let price: string = methods.control?._formValues?.product_price_unit_id;
    let priceInt: number = Math.floor(parseFloat(price));

    const requestData = {
      SchemaProductSelling: {
        product_id: id,
        product_price_unit_id: priceInt,
        product_component_cost_id: methods.control?._formValues?.product_component_cost_id,
        overhead: methods.control?._formValues?.overhead,
        sales_tax: methods.control?._formValues?.sales_tax,
        other_changes: methods.control?._formValues?.other_changes,
        cogm: methods.control?._formValues?.cogm,
        mr_unit_id: methods.control?._formValues?.mr_unit_id,
        quantity: methods.control?._formValues?.quantity ?? "1",
        tax_id: methods.control?._formValues?.tax_id,
        price_retail: methods.control?._formValues?.price_retail,
        wholesales_price: methods.control?._formValues?.wholesales_price,
        distributor_price: methods.control?._formValues?.distributor_price,
        factory_price: methods.control?._formValues?.factory_price,
        online_price: methods.control?._formValues?.online_price,
        special_arrangement_price: methods.control?._formValues?.special_arrangement_price
      },
      SchemaProductPriceUnit: {
        product_id: id,
        unit_measure_id: methods.control?._formValues?.unit_measure_id,
        quantity_unit_measure: methods.control?._formValues?.quantity_unit_measure,
        price_unit_measure: methods.control?._formValues?.price_unit_measure,
        unit_convention_id: methods.control?._formValues?.unit_convention_id,
        quantity_unit_convention: methods.control?._formValues?.quantity_unit_convention,
        price_unit_convention: methods.control?._formValues?.price_unit_convention,
        unit_sell_measure_id: methods.control?._formValues?.unit_sell_measure_id,
        quantity_unit_sell_measure: methods.control?._formValues?.quantity_unit_sell_measure,
        quantity_unit_sell_convention: methods.control?._formValues?.quantity_unit_sell_convention
      }
    };

    return new Promise<void>((resolve) => {
      toast.promise(
        api.post("/product/selling", requestData),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message
        }
      )
      .then(() => {
        refetchSelling()
        refetchPriceUnit()
      })
      .catch(() => {})
      .finally(resolve)
    })
  }

  return (
    <FormProvider {...methods}>
        <FormSelling action="UPDATE" onSubmit={onSubmit} checkLoading={checkLoading} setCheckLoading={setCheckLoading} productId={id!} />
    </FormProvider>
  )
}
const DataPicturesForm = (props: { 
  dataPicture: any
  refetchPicture: () => void
  id?: number 
}) => { 
  return ( <FormPictures action="UPDATE" dataPicture={props.dataPicture} refetchPicture={props.refetchPicture} id={props?.id} /> ) 
}
const DataPerformanceForm = (props: { id?: number }) => { return ( <FormPerformance id={props?.id} /> ) }
const DataTransactionForm = (props: { id?: number }) => { return ( <FormTransaction product_id={props?.id} /> ) }