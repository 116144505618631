// React
import { useContext } from "react"

// Components
import { Select } from "components"

// Contexts
import { DataJournalContext } from "pages/Finance/Transaction/contexts"

// Form
import { Controller, useFormContext } from "react-hook-form"

// Types
import type { DataJournalType } from "../utils"

// Utils
import { account_class } from "../utils"

export function TaxCode() {
  // Hooks
  const { tax_code: { data, isReloading } } = useContext(DataJournalContext)

  // Form
  const { control, setValue } = useFormContext<DataJournalType>()

  return (
    <Controller
      control={control}
      name="tax_id"
      render={({ field, fieldState }) => (
        <section {...account_class}>
          <Select
            placeholder="Enter Tax"
            error={fieldState.error?.message}
            isLoading={isReloading}
            options={data}
            ref={field.ref}
            value={data.find(item => item.value === field.value) ?? null}
            // @ts-ignore [EXCEPTION #1]
            onChange={(item: TaxCodeList) => {
              field.onChange(item?.value)
              setValue("rate", item.rate)
              setValue("tax_coa_collect", item.tax_coa_collect)
              setValue("tax_coa_collect_id", item.tax_coa_collect_id.toString())
              setValue("tax_coa_paid", item.tax_coa_paid)
              setValue("tax_coa_paid_id", item.tax_coa_paid_id.toString())
              setValue("tax_name", item?.label!)
            }}
          />
        </section>
      )}
    />
  )
}