/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react"
import { SuccessFetch } from "types"
import { useApi } from "utils"
import { ReconcileList } from "../../types"

type UseReconciliationJournal = {
  params: {
    coa_id: string
    last_reconciliation_date: string
    bank_statement_date: string
  }
  onSuccess?: (data: ReconcileList[]) => void
}

type Response = {
  payload: ReconcileList[]
}

export function useReconciliationJournal({
  params,
  onSuccess
}: UseReconciliationJournal) {
  const api = useApi()
  const [data, setData] = useState<ReconcileList[]>([])
  const [isLoading, setLoading] = useState(false)

  const fetchData = () => {
    if (
      !params.coa_id ||
      !params.bank_statement_date ||
      !params.last_reconciliation_date
    )
      return

    setLoading(true)

    api
      .get("reconciliation/journal", { params })
      .then((res: SuccessFetch<Response>) => {
        const data = res.data.payload.map((item) => ({ ...item, rc: false }))

        onSuccess?.(data)
        setData(data)
      })
      .catch(() => setData([]))
      .finally(() => setLoading(false))
  }

  const refetch = () => fetchData()

  useEffect(() => {
    fetchData()

    return () => {
      setData([])
      setLoading(false)
    }
  }, [
    params.coa_id,
    params.bank_statement_date,
    params.last_reconciliation_date
  ])

  return { data, isLoading, refetch }
}
