import { useTaxCodeList } from "pages/Finance/Transaction/GeneralEntry/utils/hooks"
import { Fragment } from "react"
import { useToggle } from "utils"
import { ModalSection as TaxCodeModal } from "pages/Setup/Preference/TaxCode/components"

type TaxHeaderProps = {
  approvalView?: boolean
  tax: ReturnType<typeof useTaxCodeList>
}

export const TaxHeader = ({
  approvalView,
  tax,
}: TaxHeaderProps) => {
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <th className="whitespace-nowrap">
        TAX{" "}
        <span
          className={`whitespace-nowrap text-primary cursor-pointer ${approvalView ? "hidden" : ""}`}
          onClick={toggle}>
          [+]
        </span>

      </th>

      {isActive && (
        <TaxCodeModal
          toggle={toggle}
          onSuccess={() => {
            toggle()
            tax.refetch()
          }}
        />
      )}
    </Fragment>
  )
}
