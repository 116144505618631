// React
import { Fragment } from "react"

// Components
import { Card, CommandButton, BaseTable, Loading, DateInput, Select } from "components"

export function ItemsAdjustment(): JSX.Element {
  return (
    <Fragment>
      <section className="container my-2">
        <section className="flex flex-col gap-3">
          <Card>
            <Card.Body>
              <section className="flex lg:flex-row flex-col justify-between lg:items-end gap-3 mt-2">
                <section className="grid lg:grid-cols-4 grid-cols-1 gap-3 w-full">
                  <DateInput
                    label="DATE FROM"
                    onChange={() => {}}
                  />

                  <DateInput
                    label="DATE END"
                    onChange={() => {}}
                  />

                  <Select label="LOCATION" />
                  <Select label="APPROVED" />
                </section>
              </section>
            </Card.Body>
          </Card>

          <Card>
            <Card.Body>
              <BaseTable className="border-collapse">
                <thead>
                  <tr>
                    <th>NO</th>
                    <th colSpan={2}>ADJUSTMENT ID#</th>
                    <th>REF COUNT ID#</th>
                    <th>DATE</th>
                    <th>LOCATION</th>
                    <th>TRADER</th>
                    <th>APPROVED</th>
                    <th>MEMO</th>
                    <th>SOH</th>
                    <th>COUNT</th>
                    <th>DIFFERENT</th>
                    <th>ACTION</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td colSpan={13}>
                      <Loading errorText="No data available" />
                    </td>
                  </tr>
                </tbody>
              </BaseTable>
            </Card.Body>
          </Card>
        </section>

        <section className="mt-2">
          <Card>
            <Card.Body className="flex flex-col gap-5">
              <section className="flex flex-wrap justify-between items-center gap-3">
                <section className="flex flex-wrap gap-3">
                  <CommandButton actiontype="help" />
                  <CommandButton actiontype="print" />
                  <CommandButton actiontype="email" />
                  <CommandButton actiontype="export" />
                </section>
              </section>
            </Card.Body>
          </Card>
        </section>
      </section>
    </Fragment>
  )
}