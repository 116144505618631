import { Input, Label, Select, Textarea } from "../../../../../../components"
import { Controller, useFormContext } from "react-hook-form"
import type { DataList } from "../../types"
import { useModule } from "../../utils"

export function FormSection(props: { isDetail?: boolean; isUpdate?: boolean; action?: string }) {
  const { control } = useFormContext<DataList>();
  const { data, isLoading } = useModule()

  return (
    <table className="table">
      <tbody>
          <Controller
            control={control}
            name="menu_code"
            render={({ field, fieldState }) => 
              <tr>
                <td><Label text="CODE" error={Boolean(fieldState.error)} /></td>
                <td>
                  <Input 
                    {...field} 
                    placeholder="Input Code" 
                    error={fieldState.error?.message} 
                    value={field.value ?? ""} 
                    disabled={props.action === "DETAIL" || props.action === "DELETE"} 
                  />
                </td>
              </tr>
            }
          />

          <Controller
            control={control}
            name="module_id"
            render={({ field, fieldState }) => (
              <tr>
                <td><Label text="MODULE" error={Boolean(fieldState.error)} /></td>
                <td>
                  <Select
                    placeholder="Select Module"
                    error={fieldState.error?.message}
                    isLoading={isLoading}
                    isDisabled={Boolean(props?.action === 'DELETE' || props?.action === 'DETAIL')}
                    options={data}
                    value={data.find(item => item.value === field.value?.toString())}
                    onChange={e => field.onChange(e?.value)}
                  />
                </td>
              </tr>
            )}
          />

          <Controller
            control={control}
            name="menu_name"
            render={({ field, fieldState }) => 
              <tr>
                <td><Label text="MENU" error={Boolean(fieldState.error)} /></td>
                <td><Input {...field} placeholder="Input Menu" error={fieldState.error?.message} value={field.value ?? ""} disabled={props.action === "DETAIL" || props.action === "DELETE"} /></td>
              </tr>
            }
          />

          <Controller
            control={control}
            name="description"
            render={({ field, fieldState }) => 
              <tr>
                <td><Label text="DESCRIPTION" error={Boolean(fieldState.error)} /></td>
                <td><Textarea {...field} placeholder="Input Description" error={fieldState.error?.message} value={field.value ?? ""} disabled={props.action === "DETAIL" || props.action === "DELETE"} /></td>
              </tr>
            }
          />
      </tbody>
    </table>
  )
}