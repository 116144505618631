// React
import { Fragment, useContext } from "react"

// Components
import { CommandButton, Loading } from "components"
import { ActionButton } from "../components"
import { DeleteModal, InputSection } from "./components"

// Contexts
import { AccountProvider } from "contexts"
import { PoContext } from "./contexts"

// Form
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

// Third-Party Libraries
import toast from "react-hot-toast"
import { useNavigate, useParams } from "react-router-dom"

// Utils
import { useApi, useToggle } from "utils"
import { type FormType, validationSchema, useDepositDetail } from "./utils"

export default function EditDepositVendor(): JSX.Element {
  // Hooks
  const { deposit_vendor_id } = useParams()
  const { data, isLoading } = useDepositDetail(deposit_vendor_id!)

  if (isLoading || !data) {
    return <Loading loading={isLoading} />
  }

  return (
    <PoContext.Provider value={data}>
      <AccountProvider>
        <FormSection />
      </AccountProvider>
    </PoContext.Provider>
  )
}

function FormSection(): JSX.Element {
  // Hooks
  const api = useApi()
  const data = useContext(PoContext)
  const navigate = useNavigate()
  const { deposit_vendor_id } = useParams()

  // Vars
  const datapo = data.datapo

  // Form
  const defaultValues: FormType = {
    _balance: 0,
    amount: data.amount,
    coa_id: data.coa_id,
    memo: data.memo,
    no_cheque: data.no_cheque,
    trx_code: data.trx_code,
    transaction_date: data.transaction_date,
    vendor_id: datapo.vendor_id
  }
  const onSubmit = (value: FormType) => {
    const finalValue = {
      deposit_vendor_id,
      purchase_order_id: data.purchase_order_id,
      coa_id: value.coa_id,
      vendor_id: value.vendor_id,
      location_id: datapo.location_id,
      project_id: null,
      trx_code: value.trx_code,
      transaction_date: value.transaction_date,
      amount: value.amount,
      memo: value.memo,
      no_cheque: value.no_cheque
    }

    return new Promise<void>((resolve) => {
      toast.promise(
        api.put("/depositvendor/editdeposit", finalValue),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.detail?.message ?? err.response.data.message
        }
      ).then(() => {
        navigate(-1)
      }).catch(() => {}).finally(resolve)
    })
  }
  const methods = useForm<FormType>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })
  
  return (
    <FormProvider {...methods}>
      <InputSection
        actionsection={(
          <ActionButton>
            <section className="flex flex-wrap gap-3">
              <CommandButton actiontype="help" />
              <CommandButton actiontype="print" />
              <CommandButton actiontype="email" />
              <CommandButton actiontype="journal" />
              <CommandButton actiontype="attachment" />
            </section>

            <section className="flex flex-wrap gap-3">
              {data.approve_status === 1 && <Delete />}

              {data.approve_status !== 4 && (
                <CommandButton
                  actiontype="save"
                  loading={methods.formState.isSubmitting ? "true" : undefined}
                  onClick={methods.handleSubmit(onSubmit)}
                />
              )}
            </section>
          </ActionButton>
        )}
      />
    </FormProvider>
  )
}

function Delete(): JSX.Element {
  // Hooks
  const navigate = useNavigate()
  const { deposit_vendor_id } = useParams()
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <CommandButton
        actiontype="delete"
        onClick={toggle}
      />

      {isActive && (
        <DeleteModal
          deposit_vendor_id={deposit_vendor_id!}
          toggle={toggle}
          onSuccess={() => navigate(-1)}
        />
      )}
    </Fragment>
  )
}