import { DateInput, Select } from "components";
import { Fragment } from "react";
import { useLocation } from "../utils";
import { FilterType } from "../types";
import moment from "moment";
import { DELIVERED } from "pages/Sales/Register/utils/vars";

export function Filter(props: {
    selected: FilterType,
    setSelected: (data: FilterType) => void
}) {
    const { dataLocation, isLoadingLocation } = useLocation()
    const allOption = { value: "", label: "ALL" }
    const optionsDelevered = [allOption].concat(Object.values(DELIVERED).filter(item => typeof item === 'number').map((item) => ({ value: item as unknown as string, label: DELIVERED[item as number] })))

    return (
        <Fragment>
            <section className="grid lg:grid-cols-4 gap-2">
                <DateInput
                    label="DATE FROM"
                    onChange={(date) => {
                        props.setSelected({ ...props?.selected, start_date: moment(date).format("YYYY-MM-DD") })
                    }}
                    selected={props.selected?.start_date ? moment(props.selected.start_date).toDate() : null}
                />

                <DateInput
                    label="DATE END"
                    onChange={(date) => {
                        props.setSelected({ ...props?.selected, end_date: moment(date).format("YYYY-MM-DD") })
                    }}
                    selected={props.selected?.end_date ? moment(props.selected?.end_date).toDate() : null}
                />

                <Select
                    label="LOCATION"
                    isLoading={isLoadingLocation}
                    value={props.selected?.location_id !== "" ? [allOption, ...dataLocation].find((item) => item.value?.toString() === props.selected?.location_id?.toString()) : allOption}
                    options={[allOption, ...dataLocation]}
                    onChange={(e) => { props.setSelected({ ...props.selected, location_id: e?.value }) }}
                />

                <Select
                    label="DELIVERED"
                    value={props.selected?.delivery_status !== "" ? optionsDelevered.find((item) => item.value?.toString() === props.selected?.delivery_status?.toString()) : optionsDelevered}
                    options={optionsDelevered}
                    onChange={(e) => { props.setSelected({ ...props.selected, delivery_status: e?.value }) }}
                />
            </section>
        </Fragment>
    )
}