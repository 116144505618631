import { Fragment, useState } from "react"
import { ActionButton, Card, Button, Loading, HeaderMenu } from "../../../../../../../../../components"
import type { AddressList, PersonalList, ProfileList } from "../../../../../types"
import { RenderProvider } from "../../../../../utils"
import { useLocation, useNavigate } from "react-router-dom"
import { Tab } from "./Tabs"
import { FormPersonalData } from "../../../../FormSection/FormPersonalData"
import { FormProvider, useForm } from "react-hook-form"
import { FormProfile } from "../../../../FormSection/FormProfile"
import { FormMap } from "../../../../FormSection/FormMap"
import { useDetailProfileData, useDetailAddressData, useDetailMapData } from "../utils"
import { FormAddress } from "../../../../FormSection/FormAddress"

export function Detail(props: { id: PersonalList; permission?: boolean }) {
  const navigate = useNavigate()
  return (
    <Fragment>
      <ActionButton.Detail permission={"CR032"} className="join-item" onClick={() => navigate("/cards/transaction/create-new-vendor/detail", { state: { dataId: props.id } })} />
    </Fragment>
  )
}
export function DetailPage() {
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState(0);
  const location = useLocation()
  const id = location?.state?.dataId?.vendor_id
  const data = location?.state?.dataId
  const tabs = ["PROFILE", "ADDRESS", "MAP"];
  
  const { dataProfile, errorProfile, isLoadingProfile } = useDetailProfileData(id ? id : null)
  const { dataAddress, errorAddress, isLoadingAddress } = useDetailAddressData(id ? id : null)
  const { dataMap, errorMap, isLoadingMap } = useDetailMapData(id ? id : null)

  return (
    <RenderProvider>
      <section className="container my-5">
        <div className="mb-5">
          <Card>
            <Card.Body className="flex-row justify-between items-center">
              <HeaderMenu title="DETAIL VENDOR REGISTRATION" />
            </Card.Body>
          </Card>
        </div>
        <Card>
          <Card.Body className="flex-row justify-end items-center">
            <Button color="primary" onClick={() => navigate("/cards/register/all")}>
                BACK
            </Button>
          </Card.Body>
          <Card.Body>
            <div className="lg:flex">
              <div className="lg:w-[34%] mr-0.5">
                <button className="bg-[#3c3c3c] px-4 py-2 w-full mr-0.5 text-white text-[12px]"><b>PERSONAL DATA</b></button>
                <div className={`max-w-sm w-full lg:max-w-full lg:flex h-[1430px]`}>
                  <div className="w-full border border-[#3c3c3c] lg:border-[#3c3c3c] p-6">
                    <DataForm dataDefault={data!} setActiveTab={setActiveTab} />
                  </div>
                </div>
              </div>
              <div className="lg:w-[66%]">
                <Tab tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} className="text-[11px]" />
                <div className={`max-w-sm w-full lg:max-w-full lg:flex h-[1430px]`}>
                  <div className="w-full border border-[#3c3c3c] lg:border-[#3c3c3c] p-6 mr-0.5">
                    {activeTab === 0 
                      ? isLoadingProfile || errorProfile 
                        ? <Loading errorText={errorProfile} loading={isLoadingProfile} /> 
                        : <DataProfileForm dataProfile={dataProfile!} />
                      : ""
                    }
                    {activeTab === 1
                      ? isLoadingAddress || errorAddress 
                        ? <Loading errorText={errorAddress} loading={isLoadingAddress} /> 
                        : <DataAddressForm dataAddress={dataAddress!} />
                      : ""
                    }
                    {activeTab === 2
                      ? isLoadingMap || errorMap 
                        ? <Loading errorText={errorMap} loading={isLoadingMap} /> 
                        : <DataMapForm dataMap={dataMap!} />
                      : ""
                    }
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </section>
    </RenderProvider>
  )
}
function DataForm(props: {
  dataDefault: PersonalList
  setActiveTab: (newState: any) => void
}) {
  const [fileValue, setFileValue] = useState<File | undefined>(undefined);
  console.log(fileValue)
  const defaultValues = {
    vendor_code: props?.dataDefault?.vendor_code!,
    vendor_name: props?.dataDefault.vendor_name,
    vendor_Foto: props?.dataDefault?.foto,
  }
  const methods = useForm<PersonalList>({ defaultValues })

  return (
    <FormProvider {...methods}>
      {/* @ts-ignore */}
      <FormPersonalData action="DETAIL" onSubmit={() => {}} setFileValue={setFileValue} />
    </FormProvider>
  )
}
function DataProfileForm(props: { dataProfile: ProfileList }) {
  const defaultValues = {
    handphone: props?.dataProfile?.handphone,
    email: props?.dataProfile?.email,
    website: props?.dataProfile?.website,
    card_label:  props?.dataProfile?.card_label,
    group_card: props?.dataProfile?.group_card ?? "3",
    category_vendor_id: props?.dataProfile?.category_vendor_id,
    source_from: props?.dataProfile?.source_from,
    employee_id: props?.dataProfile?.employee_id,
    media: props?.dataProfile?.media,
    price_level_id: props?.dataProfile?.price_level_vendor_id,
    payment_term_id: props?.dataProfile?.payment_term_vendor_id,
    max_balance: props?.dataProfile?.max_balance,
    bank_id: props?.dataProfile?.bank_id,
    bank_account: props?.dataProfile?.bank_account,
    bank_beneficiary: props?.dataProfile?.bank_beneficiary,
    bank_branch: props?.dataProfile?.bank_branch,
  }
  const methods = useForm<ProfileList>({  defaultValues })

  return (
    <FormProvider {...methods}>
      {/* @ts-ignore */}
      <FormProfile action="DETAIL" />
    </FormProvider>
  )
}
function DataAddressForm(props: { dataAddress: any }) {
  const defaultValues = {
    address_type_id: props?.dataAddress?.[0]?.address_type_id,
    address_type_id_2: props?.dataAddress?.[0]?.address_type_id_2,
    country_id: props?.dataAddress?.[0]?.country_id,
    country_id_2: props?.dataAddress?.[0]?.country_id_2,
    province_id: props?.dataAddress?.[0]?.province_id,
    province_id_2: props?.dataAddress?.[0]?.province_id_2,
    regency_id: props?.dataAddress?.[0]?.regency_id,
    regency_id_2: props?.dataAddress?.[0]?.regency_id_2,
    subdistrict_id: props?.dataAddress?.[0]?.subdistrict_id,
    subdistrict_id_2: props?.dataAddress?.[0]?.subdistrict_id_2,
    village_id: props?.dataAddress?.[0]?.village_id,
    village_id_2: props?.dataAddress?.[0]?.village_id_2,
    address: props?.dataAddress?.[0]?.address,
    address_2: props?.dataAddress?.[0]?.address_2,
  }
  const methods = useForm<AddressList>({  defaultValues })

  return (
    <FormProvider {...methods}>
      {/* @ts-ignore */}
      <FormAddress action="DETAIL" />
    </FormProvider>
  )
}
function DataMapForm(props: { dataMap: ProfileList }) {
  const defaultValues = { link_map: props?.dataMap?.link_map}
  const methods = useForm<ProfileList>({  defaultValues })

  return (
    <FormProvider {...methods}>
      {/* @ts-ignore */}
      <FormMap action="DETAIL" />
    </FormProvider>
  )
}