import { Fragment } from "react"
import { Loading, Modal } from "../../../../../../../components"
import { FormProvider, useForm } from "react-hook-form"
import type { FormType } from "../../../types"
import { useToggle } from "../../../../../../../utils"
import { useDetail } from "../utils"
import { FormPicture } from "../../FormSection/FormPicture"
import { PiImageSquareBold } from "react-icons/pi";

export function Pictures(props: { id: number }) {
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <button data-tip="Picture" className="px-2 tooltip" onClick={toggle}><PiImageSquareBold className="size-5" /></button>
      {isActive && <ModalSection id={props.id} toggle={toggle} />}
    </Fragment>
  )
}

function ModalSection(props: {
  id: number
  toggle: () => void
}) {
  const { data, dataError, isLoading } = useDetail(props.id)

  return (
    <Modal isOpen title="PICTURE" size="xl" closeModal={props.toggle}>
      {isLoading || dataError 
        ? <Loading errorText={dataError} loading={isLoading} />
        : <DataForm data={data!} id={props.id} toggle={props.toggle} />
      }
    </Modal>
  )
}

function DataForm(props: {
  data: FormType
  id: number
  toggle: () => void
}) {
  const methods = useForm({ defaultValues: props.data })

  return (
    <FormProvider {...methods}>
      <FormPicture />
    </FormProvider>
  )
}