import { useApi } from "utils";
import { ApproveType } from "../../types";
import { useEffect, useState } from "react";
import { SuccessFetch } from "types";

export function useApprovalList(params: string) {
    const api = useApi();
    const [data, setData] = useState<ApproveType[]>([]);
    const [isLoading, setLoading] = useState(true)

    const fetchUrl = () => {
        return api.post(`/probill/getdata?Approval=${params}`)
    }
  
    const fetchData = () => {
        setLoading(true);

        fetchUrl().then((res: SuccessFetch<{ payload: ApproveType[] }>) => {
            setData(res.data.payload);
        }).catch(() => {
            setData([]);
        }).finally(() => {
            setLoading(false);
        });
    }

    const refetch = () => fetchData();

    useEffect(() => {
        fetchData();

        return () => {
            setData([]);
            setLoading(true);
        }
        // eslint-disable-next-line
    }, [params]);

    return { data, isLoading, refetch }
}

export function useEmployeeStatusId(id: number) {
    const api = useApi();
    const [data, setData] = useState<any>([]);
    const [isLoading, setLoading] = useState(true);

    const fetchUrl = () => {
        return api.post(`/employee/workstatus?employee_id=${id}`);
    };

    const fetchData = () => {
        setLoading(true);

        fetchUrl().then((res: SuccessFetch<{ payload: any }>) => {
            setData(res.data.payload);
        }).catch(() => {
            setData([]);
        }).finally(() => {
            setLoading(false);
        });
    };

    const refetch = () => fetchData();

    useEffect(() => {
        fetchData();

        return () => {
            setData([]);
            setLoading(true);
        };
        // eslint-disable-next-line
    }, [id]);

    return { data, isLoading, refetch };
}