import { Button } from "components"
import { TabTransactionPath } from "pages/Inventori/Transaction/AddNewItem/types";

type TabTransactionProps = { activeTab: string; setActiveTab: (path: string) => void; }
type Tabs = { label: string; path: TabTransactionPath; permission: string }

export function TabNavTransaction({ activeTab, setActiveTab }: TabTransactionProps) { 
  const tabs: Tabs[] = [
    { label: "ADJUSTMENT", path: "adjustment", permission: "IT" },
    { label: "COUNT INVENTORY", path: "count-inventory", permission: "IT" },
    { label: "TRANSFERS", path: "transfers", permission: "IT" },
    { label: "SKU ATTACHED", path: "sku-attached", permission: "IT" },
    { label: "PURCHASES", path: "purchases", permission: "IT" },
    { label: "SALES ORDER", path: "sales-order", permission: "IT" },
    { label: "BILLS", path: "bills", permission: "IT" },
    { label: "INVOICE", path: "invoice", permission: "IT" },
  ]
  
  return (
    <section className="grow flex items-stretch overflow-x-auto">
      {tabs.map((tab) => (
        <Button
          key={tab?.path}
          type="button"
          color="ghost"
          size="sm"
          permission={tab?.permission}
          className={`grow bg-[#3c3c3c] border-y-0 border-x border-white no-animation hover:bg-primary hover:text-black whitespace-nowrap uppercase ${tab.path === activeTab ? "bg-primary text-black" : "text-white"}`}
          onClick={() => setActiveTab(tab?.path)}
        >
          {tab.label}
        </Button>
      ))}
    </section>
  )
}
