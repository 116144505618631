// React
import { PropsWithChildren } from "react"

export function Label(props: PropsWithChildren<{
  className?: string
  error?: boolean
  text?: string
}>) {
  return (
    <label className="label">
      <span className={`label-text font-bold ${props.error && "text-error"} ${props.className}`}>{props.text ?? props.children}</span>
    </label>
  )
}