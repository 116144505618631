// React
import { Fragment } from "react"

// Components
import { ActionButton, Button, Modal } from "components"

// Utils
import { useToggle } from "utils"

export function DeleteAlert(params: { onDelete: () => void }) {
  // Hooks
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <ActionButton.Delete type="button" onClick={toggle} />

      {isActive && (
        <Modal
          isOpen
          title="DELETE DETAIL PURCHASE ORDER"
          closeModal={toggle}
        >
          <Modal.Body>
            <div className="text-error">Are you sure want to delete this data?</div>
          </Modal.Body>

          <Modal.Footer className="flex justify-end gap-3">
            <Button
              color="error"
              onClick={() => {
                params.onDelete()
                toggle()
              }}
            >
              DELETE
            </Button>

            <Button
              color="ghost"
              onClick={toggle}
            >
              CANCEL
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Fragment>
  )
}