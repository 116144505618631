import { FormSalesOrderType } from "../../types"
import { calculateTotalSalesOrderDetail } from "./calculateTotalSalesOrderDetail"

type CalculateSummarySalesOrder = Pick<FormSalesOrderType, 'sodetail' | "freight">

type ReturnCalculateSummarySalesOrder = {
  subtotal: number
  tax: number,
  freight: number
  totalAmount: number
  payment: number
  balance: number
}

export const calculateSummarySalesOrder = ({ sodetail, freight }: CalculateSummarySalesOrder): ReturnCalculateSummarySalesOrder => {
  const summary = sodetail?.reduce((acc, item) => {
    const calculateItem = calculateTotalSalesOrderDetail(item)

    acc.subtotal += calculateItem.finalPrice
    acc.tax += calculateItem.totalTax

    return acc
  }, {
    subtotal: 0,
    tax: 0,
  })

  const totalAmount = (summary?.subtotal ?? 0) + (freight ?? 0) + (summary?.tax ?? 0)

  const payment = 0

  const balance = totalAmount - payment

  return {
    subtotal: summary?.subtotal ?? 0,
    tax: summary?.tax ?? 0,
    freight: freight ?? 0,
    totalAmount,
    payment,
    balance
  }
}