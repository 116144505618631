import { DataJournalType } from "../vars/validationSchemaJournal"

export const isCoaDuplicate = (
  datajournal: DataJournalType[],
  coa_id: string,
  index?: number
) => {
  return datajournal.some((item, idx) => {
    if (item.coa_id === coa_id && index === idx) {
      return false
    }

    return item.coa_id === coa_id
  })
}
