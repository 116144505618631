/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react"
import { SuccessFetch } from "types"
import { useApi } from "utils"
import { DetailReceived } from "../../types"

type UseDetailReceived = {
  received_id: string
  onSuccess?: (data: DetailReceived) => void
}

type Response = {
  payload: DetailReceived
}

export function useDetailReceived({
  received_id,
  onSuccess
}: UseDetailReceived) {
  const api = useApi()
  const [data, setData] = useState<DetailReceived | null>(null)
  const [isLoading, setLoading] = useState(false)

  const fetchData = () => {
    setLoading(true)

    const params = {
      received_id
    }

    api
      .get("/received/detailreceived", { params })
      .then((res: SuccessFetch<Response>) => {
        const data = res.data.payload
        onSuccess?.(data)
        setData(data)
      })
      .catch(() => setData(null))
      .finally(() => setLoading(false))
  }

  const refetch = () => fetchData()

  useEffect(() => {
    if (received_id) {
      fetchData()
    }

    return () => {
      setData(null)
      setLoading(false)
    }
  }, [received_id])

  return { data, isLoading, refetch }
}
