import { DataList } from "../types"
import { BottomSection } from "./BottomSection"

interface ExpensesActivitesProps { data?: DataList[] }

export const ExpensesActivites:React.FC<ExpensesActivitesProps> = ({ data }) => (
    <div className="mt-5">
        <div className="label-text"><b>EXPENSES ACTIVITIES</b></div>
        <div className="flex overflow-x-auto">
            <BottomSection className="!ml-0 lg:!ml-5" label="FIXED EXPENSES" value={data?.[0]?.coa_id!?.toString()} />
            <BottomSection label="VARIABLE EXPENSES" value={data?.[0]?.coa_id!?.toString()} />
            <BottomSection label="OTHER EXPENSES" value={data?.[0]?.coa_id!?.toString()} />
        </div>
    </div>
  )