// EXCEPTIONS
// #1: ignore type error to check field name is available on the form type

// React
import { Fragment } from "react"

// Components
import { ActionButton, Modal } from "../../../../../components"
import { FormSection } from "./FormSection"

// Form
import { FormProvider, useForm } from "react-hook-form"

// Types
import type { FormType, TaxCodeType } from "../types"

// Utils
import { useToggle } from "../../../../../utils"

export function Detail(props: {item: TaxCodeType }) {
	// Hooks
	const { isActive, toggle } = useToggle(false)

	return (
		<Fragment>
			<ActionButton.Detail onClick={toggle} />
			{isActive && <ModalSection item={props.item} toggle={toggle} />}
		</Fragment>
	)
}

function ModalSection(props: {
	item: TaxCodeType
	toggle: () => void
}) {
	// Form
	const defaultValues = {
		tax_code: props.item.tax_code,
		tax_name: props.item.tax_name,
		type_tax_id: props.item.type_tax_id.toString(),
		rate: props.item.rate,
		coa_collect_id: props.item.coa_collect_id.toString(),
		coa_paid_id: props.item.coa_paid_id.toString(),
		note: props.item.note
	}
	const methods = useForm<FormType>({ defaultValues })

	return (
		<Modal isOpen title="DETAIL TAX CODE" closeModal={props.toggle}>
			<FormProvider {...methods}>
				<FormSection isDisabled />
			</FormProvider>
		</Modal>
	)
}