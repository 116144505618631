import { Button, DateInput, Input, Modal, NumberInput } from "components"
import moment from "moment"
import { Fragment, useState } from "react"
import { toast } from "react-hot-toast"
import { useApi, useToggle } from "utils"
import { TransferItemsList } from "../types"

type ModalDeleteProps = {
  data: Pick<
    TransferItemsList,
    | "transfer_id"
    | "transaction_date"
    | "trx_code"
    | "location_from_name"
    | "location_to_name"
    | "employee_name"
    | "quantity_transfer"
    | "memo"
  >
  modalState: ReturnType<typeof useToggle>
  onSuccess?: () => void
}

export const ModalDelete = ({
  data,
  modalState,
  onSuccess
}: ModalDeleteProps) => {
  const [loadingData, setLoadingData] = useState(false)
  const api = useApi()

  const handleDeleteData = async () => {
    setLoadingData(true)
    await toast
      .promise(api.delete(`transfer/delete/${data?.transfer_id}`), {
        loading: "Loading...",
        success: (res) => res?.data?.message,
        error: (err) => err.response?.data?.detail?.message
      })
      .then(() => {
        modalState.setActive(false)
        onSuccess?.()
      })
      .finally(() => {
        setLoadingData(false)
      })
  }

  return (
    <Modal
      isOpen={modalState.isActive}
      title="DELETE TRANSFER ITEM"
      size="xl"
      closeModal={modalState.toggle}>
      <Modal.Body>
        <Fragment>
          <small className="text-lg font-medium text-red-400">
            Are you sure want to delete this data?
          </small>

          <Input
            disabled
            label="TRANSFER ITEM ID"
            defaultValue={data.trx_code}
          />

          <DateInput
            disabled
            label="DATE"
            selected={moment(data.transaction_date).toDate()}
            onChange={() => null}
          />

          <Input
            disabled
            label="FROM LOCATION"
            defaultValue={data.location_from_name}
          />

          <Input
            disabled
            label="TO LOCATION"
            defaultValue={data.location_to_name}
          />

          <Input disabled label="TRADER" defaultValue={data.employee_name} />

          <Input disabled label="MEMO" defaultValue={data.memo} />

          <NumberInput
            disabled
            label="QTY TRANSFERED"
            defaultValue={data.quantity_transfer}
          />
        </Fragment>
      </Modal.Body>

      <Modal.Footer>
        <Button
          type="button"
          color="error"
          disabled={loadingData}
          loading={loadingData ? "true" : undefined}
          onClick={handleDeleteData}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
