import { BaseTable, Card, CommandButton, Loading } from "components";
import { Fragment, useState } from "react";
import { Filter, Item } from "./components";
import { FilterType } from "./types";
import { defaultFilter, useList } from "./utils";

export function TabAgedReceivable() {

    const [selected, setSelected] = useState<FilterType>(defaultFilter)
    const { data, isLoading } = useList(selected)

    return (
        <Fragment>
            <section className="mt-2">
                <Card>
                    <Card.Body>
                        <Filter selected={selected} setSelected={setSelected} />
                    </Card.Body>
                </Card>
            </section>

            <section className="mt-2">
                <Card>
                    <Card.Body>
                        <div className="overflow-x-auto">
                            <BaseTable>
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th colSpan={2}>INVOICE ID#</th>
                                        <th>REF SO ID#</th>
                                        <th>DATE</th>
                                        <th>CUSTOMER</th>
                                        <th>BALANCE</th>
                                        <th>7 DAYS</th>
                                        <th>15 DAYS</th>
                                        <th>21 DAYS</th>
                                        <th>30+ DAYS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {isLoading || data?.payload?.length === 0 ? (
                                        <tr>
                                            <td colSpan={15} className="text-center">
                                                <Loading loading={isLoading} errorText={isLoading ? "" : "No data available"} />
                                            </td>
                                        </tr>
                                    ) :
                                        data?.payload?.map((item: any, key: number) => (
                                            <Item data={item} index={key} dataPaging={selected} />
                                        ))}
                                </tbody>
                            </BaseTable>
                        </div>
                    </Card.Body>
                </Card>
            </section>

            <section className="mt-2">
                <Card>
                    <Card.Body>
                        <section className="flex flex-wrap gap-3">
                            <CommandButton actiontype="help" />
                            <CommandButton actiontype="print" />
                            <CommandButton actiontype="email" />
                            <CommandButton actiontype="export" />
                        </section>
                    </Card.Body>
                </Card>
            </section>
        </Fragment>
    )
}