import { ExportExcel } from "components"
import { FilterType } from "pages/Card/Transaction/CreateNewEmployee/types/FilterType"
import { useExportList } from "pages/Card/Transaction/CreateNewEmployee/utils"

export function Excel(props: {
    dataFillter: FilterType
}) {

    const { data } = useExportList()

    const dataExcel = [
        {
            header: 'Employee Code',
            items: data?.map((item) => item.employee_code),
        },
        {
            header: 'Employee Name',
            items: data?.map((item) => item.employee_name),
        },

        props.dataFillter?.nomorNationalId &&
        {
            header: 'Nomot National Id',
            items: data?.map((item) => item.nomor),
        },

        props.dataFillter?.religion &&
        {
            header: 'Religion',
            items: data?.map((item) => item.religion_name),
        },

        props.dataFillter?.handphone &&
        {
            header: 'Handphone',
            items: data?.map((item) => item.handphone),
        },

        props.dataFillter?.email &&
        {
            header: 'Email',
            items: data?.map((item) => item.email),
        },


        props.dataFillter?.country &&
        {
            header: 'Country',
            items: data?.map((item) => item.country_name),
        },

        props.dataFillter?.province &&
        {
            header: 'Province',
            items: data?.map((item) => item.province_name),
        },

        props.dataFillter?.regency &&
        {
            header: 'Regency',
            items: data?.map((item) => item.regency_name),
        },

        props.dataFillter?.subdistrict &&
        {
            header: 'Subdistrict',
            items: data?.map((item) => item.subdistrict_name),
        },

        props.dataFillter?.village &&
        {
            header: 'Village',
            items: data?.map((item) => item.village_name),
        },

        props.dataFillter?.address &&
        {
            header: 'Address',
            items: data?.map((item) => item.address),
        },

        props.dataFillter?.departement &&
        {
            header: 'Departement',
            items: data?.map((item) => item.department_name),
        },

        props.dataFillter?.divisi &&
        {
            header: 'Divisi',
            items: data?.map((item) => item.divisi_name),
        },

        props.dataFillter?.gender &&
        {
            header: 'Gender',
            items: data?.map((item) => item.gender),
        },

        props.dataFillter?.employeeStatus &&
        {
            header: 'Employee Status',
            items: data?.map((item) => item.employee_status_name),
        },

        props.dataFillter?.bank &&
        {
            header: 'Bank',
            items: data?.map((item) => item.bank_name),
        },

        props.dataFillter?.accountBankName &&
        {
            header: 'Account Number',
            items: data?.map((item) => item.account_number),
        },

        props.dataFillter?.branch &&
        {
            header: 'Branch',
            items: data?.map((item) => item.branch),
        },
    ]


    return (
        <>
            <ExportExcel dataList={dataExcel} title="REGISTRASI EMPLOYEE" fileName="Employee Registration" />
        </>
    )
}