import moment from "moment"
import { IoSendSharp } from "react-icons/io5"
import { Link } from "react-router-dom"
import { ReconcileList } from "../types"
import {
  generateLinkTransaction,
  getDataSourceLabel,
  getGroupCardLabel
} from "../utils/function"
import { convertNumber } from "utils"

interface ItemProps {
  index: number
  item: ReconcileList
  onChangeRc: (checked: boolean, item: any, index: number) => void
}

export function Item({ item, index, onChangeRc }: ItemProps) {
  const navigateTo = generateLinkTransaction({
    data_source: item.data_source,
    data_source_id: item.data_source_id
  })

  return (
    <tr key={index}>
      <td className="font-normal text-nowrap">
        <Link to={navigateTo} target="_blank" rel="noopener noreferrer">
          <button
            color="primary"
            className="pe-4 rounded-full"
            title="Collapse">
            <IoSendSharp />
          </button>
        </Link>
        <span className="pe-2">{getDataSourceLabel(item.data_source)}</span>
      </td>

      <td className="font-normal text-nowrap">
        <span className="ps-4">
          {moment(item.transaction_date).format("DD/MM/YYYY")}
        </span>
      </td>

      <td className="text-center">
        <input
          type="checkbox"
          defaultChecked={item.rc}
          className="checkbox checkbox-sm rounded-sm"
          onChange={(e) => onChangeRc(e.target.checked, item, index)}
        />
      </td>

      <td>{getGroupCardLabel(item.group_card)}</td>

      <td>{item.card_name}</td>

      <td>
        <div className="flex">
          <button
            color="primary"
            className="pe-4 rounded-full"
            onClick={() => {}}
            title="Collapse">
            <IoSendSharp />
          </button>
          <span className="ps-4">{item.reference_no}</span>
        </div>
      </td>

      <td>{item.memo}</td>

      <td className="text-right">
        {item.position === 2 ? convertNumber(item.amount).intoCurrency : "0"}
      </td>

      <td className="text-right">
        {item.position === 1 ? convertNumber(item.amount).intoCurrency : "0"}
      </td>
    </tr>
  )
}
