// Form
import { Controller, useFormContext } from "react-hook-form"

// Icons
import { TbChevronRight } from "react-icons/tb"

export function TradingTerm(params: { name: string }) {
  // Form
  const { control } = useFormContext()

  return (
    <Controller
      control={control}
      name={params.name}
      render={({ field }) => (
        <div className="mb-3 flex flex-wrap">TRADING TERM <span className="mt-1"><TbChevronRight /></span> Net {field.value} days after OEM</div>
      )}
    />
  )
}