import { useState, useEffect } from "react"
import type { SelectOptionType, SuccessFetch } from "../../../../../../../../types"
import { useApi } from "../../../../../../../../utils"

export function useProductUnit(type?: string ) {
  const api = useApi()
  const [dataUnit, setData] = useState<SelectOptionType[]>([])
  const [isLoadingUnit, setLoading] = useState(false)

  const refetchUnit = () => {
    setLoading(true)
    api.get("/productunit/").then((res: SuccessFetch<{
      payload: {
        mr_unit_id: number
        mr_unit_code: string
        mr_unit_name: string
      }[]
    }>) => {
      setData(res.data.payload.map(item => {
        return {
          label: type === "name" ? item.mr_unit_name : item.mr_unit_code,
          value: item.mr_unit_id.toString()
        }
      }))
    }).catch(() => {
      setData([])
    }).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
      setLoading(true)
      api.get("/productunit/").then((res: SuccessFetch<{
        payload: {
          mr_unit_id: number
          mr_unit_code: string
          mr_unit_name: string
        }[]
      }>) => {
        setData(res.data.payload.map(item => {
          return {
            label: type === "name" ? item.mr_unit_name : item.mr_unit_code,
            value: item.mr_unit_id.toString()
          }
        }))
      }).catch(() => {
        setData([])
      }).finally(() => {
        setLoading(false)
      })
    // eslint-disable-next-line
  }, [])

  return { dataUnit, isLoadingUnit, refetchUnit }
}