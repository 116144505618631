import { useState } from "react"
import type { SelectOptionType, SuccessFetch } from "types"
import { useApi } from "utils"
import { DataList, DataListReference } from "../types"
import { SelectedState, SelectedStateDropdown } from "pages/Purchase/Register/types/Reference"

export function useList() {
  const api = useApi()
  const [data, setData] = useState<DataList[]>([])
  const [isLoading, setLoading] = useState(false)

  const refetch = (params: Partial<SelectedState>) => {
    setLoading(true)

    api.get("/purchaseorder/by-reference", { params: {
      date_from: params?.date_from, 
      date_end: params?.date_end, 
      referensi: params?.referensi,
      delivery_status: params?.delivery_status,
    } }) 
    .then((res: SuccessFetch<{ payload: DataList[] }>) => setData(res.data.payload))
    .catch(() => setData([]) )
    .finally(() => setLoading(false))
  
    return () => {
      setData([])
      setLoading(true)
    }
  }

  return { data, isLoading, refetch }
}
export function useListReference() {
  const api = useApi()
  const [dataReference, setData] = useState<SelectOptionType[]>([])
  const [isLoadingReference, setLoading] = useState(false)

  const refetchReference = (params: Partial<SelectedStateDropdown>) => {
    setLoading(true)
  
    api.get(`/purchaseorder/dropdown-referensi?date_from=${params?.date_from}&date_end=${params?.date_end}`)
    .then((res: SuccessFetch<{ payload: DataListReference[] }>) => {
      const location = res.data.payload.map(item => ({
        label: item.referensi,
        value: item.referensi.toString()
      }));
      setData(location);
    })
    .catch(() => setData([]) )
    .finally(() => setLoading(false))
  
    return () => {
      setData([])
      setLoading(true)
    }
  }

  return { dataReference, isLoadingReference, refetchReference }
}