import { FormInvoiceDetailType } from "../../types/FormInvoiceDetail"
import { calculateTotalReturnItem } from "./calculateTotalReturnItem"

export const calculateSummaryReturnItems = (returnDetails: Array<FormInvoiceDetailType>) => {
  const summary = returnDetails?.reduce((acc, item) => {
    const calculateItemInvoice = calculateTotalReturnItem({
      discount: item.discount,
      price_return: item.price_invoice,
      qty: item.quantity_invoice,
      tax_rate: item.tax_rate
    })

    acc.subtotal += calculateItemInvoice.finalPrice
    acc.tax += calculateItemInvoice.totalTax

    return acc
  },
    {
      subtotal: 0,
      tax: 0,
    }
  )

  return {
    subtotal: summary.subtotal,
    tax: summary.tax,
    totalReject: summary.subtotal + summary.tax
  }
}