/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, HeaderMenu, Input } from "components"
import { APPROVAL } from "pages/Sales/Register/utils/vars"
import { DropdownCustomer } from "pages/Sales/Transaction/CustomerOrder/components"
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch
} from "react-hook-form"
import { IoSendSharp } from "react-icons/io5"
import { FormReturnItemsType } from "../types/FormReturnItems"
import { CodeReturnItems } from "./CodeReturnItems"
import { CommandButtons } from "./CommandButtons"
import { FormReturnItems } from "./FormReturnItems"
import { SummaryFooter } from "./SummaryFooter"
import { TableInvoiceReturnItems } from "./Tables"
import toast from "react-hot-toast"

export type FormSectionProps = {
  approvalView?: boolean
  isEdit?: boolean
  onSubmit: () => void
}

export const FormSection = ({ approvalView, isEdit = false, onSubmit }: FormSectionProps) => {
  const methods = useFormContext<FormReturnItemsType>()
  const [approval_status] = useWatch({
    control: methods.control,
    name: ["approval_status"]
  })

  const invoice_detail = useFieldArray({
    control: methods.control,
    name: "invoice_detail"
  })

  const return_detail = useFieldArray({
    control: methods.control,
    name: "return_detail"
  })

  return (
    <section className="container my-5 flex flex-col gap-5">
      <HeaderMenu title="DATA ENTRY | RETURN ITEMS">
        <CodeReturnItems isEdit={isEdit} />
      </HeaderMenu>

      <section className="grid lg:grid-cols-3 gap-x-6 gap-y-3">
        <section className="w-full">
          <DropdownCustomer approvalView={approvalView} isEdit />
        </section>

        <section>
          <div className="normal-case flex justify-evenly items-center pt-10">
            <div className="flex flex-col gap-2">
              <span>TRADING TERM </span>
              <span>PRICE LEVEL</span>
            </div>

            <div className="px-2 flex flex-col gap-2">
              <IoSendSharp />
              <IoSendSharp />
            </div>

            <div className="flex flex-col gap-2">
              <Controller
                control={methods.control}
                name="payment_term_name"
                render={({ field }) => (
                  <span>Net {field.value ?? 0} Days after OEM </span>
                )}
              />

              <Controller
                control={methods.control}
                name="price_level_name"
                render={({ field }) => (
                  <span>{field.value ? field.value : "-"}</span>
                )}
              />
            </div>
          </div>
        </section>

        <section className="lg:justify-self-end self-end mb-3">
          <Controller
            control={methods.control}
            name="tax_inclusive"
            render={({ field }) => (
              <Checkbox disabled value={field.value} label="">
                TAX-INCLUSIVE
              </Checkbox>
            )}
          />
        </section>
      </section>

      <FormReturnItems approvalView={approvalView} />

      <TableInvoiceReturnItems
        approvalView={approvalView}
        data={return_detail.fields}
        displayArrowNavigation={false}
        onClickDeleteSOInvoice={(key: number) => {
          return_detail.remove(key)
        }}
      />

      <section className="grid lg:grid-cols-2 gap-x-6 gap-y-3">
        <div className="flex flex-col gap-3 flex-grow">
          <Controller
            control={methods.control}
            name="approval_status"
            render={({ field, fieldState }) => (
              <Input
                disabled
                label="APPROVE STATUS"
                value={APPROVAL[field.value]}
                ref={field.ref}
                onChange={field.onChange}
                error={fieldState.error?.message}
              />
            )}
          />

          <Controller
            control={methods.control}
            name="approved_by_name"
            render={({ field, fieldState }) => (
              <Input
                disabled
                label="APPROVE BY"
                value={field.value}
                ref={field.ref}
                onChange={field.onChange}
                error={fieldState.error?.message}
              />
            )}
          />
        </div>

        <div className="flex flex-col lg:items-end gap-3">
          <SummaryFooter />
        </div>
      </section>

      <TableInvoiceReturnItems
        display
        displayArrowNavigation
        approvalView={approvalView}
        data={invoice_detail.fields}
        onClickArrowNavigation={(item) => {
          const isExist = return_detail?.fields?.find(
            (inv) => inv.product_name === item.product_name
          )

          if (!isExist) {
            return return_detail.append(item)
          }

          toast.error("Invoice Detail is already on Return Detail List")
        }}
      />

      <CommandButtons
        approvalView={approvalView}
        showSaveButton={approval_status !== APPROVAL.APPROVE}
        showDeleteButton={isEdit && approval_status !== APPROVAL.APPROVE}
        onSave={onSubmit}
      />
    </section>
  )
}
