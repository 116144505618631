// React
import { useEffect, useState } from "react"

// Types
import type { SuccessFetch } from "types"
import type { BillType } from "../../types"

// Utils
import { useApi } from "utils"

export function useBillDetail(id: string) {
  // Hooks
  const api = useApi()
  const [data, setData] = useState<BillType | null>(null)
  const [isLoading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    api.get("/probill/detailbill", {
      params: { bill_id: id }
    }).then((res: SuccessFetch<{
      payload: {
        databill: {
          address: string
          balance: number
          location_id: number
          trx_code: string
          vendor_id: number
          vendor_name: string
        }
      }
    }>) => {
      // Vars
      const result = res.data.payload

      setData({
        bill_id: 0,
        approve_status: 0,
        coa_id: "",
        deposit_amount: 0,
        memo: "",
        payment_amount: 0,
        referensi: "",
        transaction_date: "",
        trx_code: "",
        databill: {
          ...result.databill,
          location_id: result.databill.location_id.toString(),
          vendor_id: result.databill.vendor_id.toString()
        }
      })
    }).catch(() => {
      setData(null)
    }).finally(() => {
      setLoading(false)
    })

    return () => {
      setData(null)
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [])

  return { data, isLoading }
}