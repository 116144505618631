import React from "react";
import type { ProfileList } from "../../../../../types"

interface TabProps {
  tabs: string[];
  activeTab?: number
  className?: string
  disabled?: boolean
  dataProfile?: ProfileList
  setActiveTab: (newState: any) => void
}

export const Tab: React.FC<TabProps> = ({ tabs, activeTab, setActiveTab, className, disabled, dataProfile }) => {

  return (
    <div className="flex justify-between overflow-auto">
      {tabs.map((tab, index) => (
        <button
          key={index}
          className={`${index === activeTab ? "bg-primary dark:text-black" : "bg-[#3c3c3c]"} px-4 py-2 w-full mr-0.5 text-white dark:text-white`}
          onClick={() => setActiveTab(index)}
          disabled={disabled}
        >
          <div className={className}><b>{tab}</b></div>
        </button>
      ))}
    </div>
  );
};
