// React
import { PropsWithChildren, createContext } from "react"

// Types
import type { SelectOptionType } from "types"

// Utils
import { useLocation } from "utils"

export const LocationContext = createContext<{
  data: SelectOptionType[]
  isLoading: boolean
}>({
  data: [],
  isLoading: false
})

export function LocationProvider(params: PropsWithChildren<{}>): JSX.Element {
  // Hooks
  const { data, isLoading } = useLocation()

  return (
    <LocationContext.Provider
      value={{
        data: data,
        isLoading: isLoading
      }}
    >
      {params.children}
    </LocationContext.Provider>
  )
}