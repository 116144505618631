// React
import { useContext } from "react"

// Components
import { Textarea } from "components"

// Contexts
import { DisabledContext } from "../../contexts"

// Form
import { Controller, useFormContext } from "react-hook-form"

// Types
import type { FormType } from "../utils"

export function Memo(): JSX.Element {
  // Hooks
  const disabled = useContext(DisabledContext)

  // Form
  const { control } = useFormContext<FormType>()

  return (
    <Controller
      name="memo"
      control={control}
      render={({ field, fieldState }) => (
        <Textarea
          {...field}
          label="MEMO"
          disabled={disabled}
          error={fieldState.error?.message}
        />
      )}
    />
  )
}