import { useContext, useEffect } from "react"
import { Button, HintList, Modal } from "../../../../../components"
import { FormSection } from "./FormSection"
import * as yup from "yup"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import toast from "react-hot-toast"
import type { DataList } from "../types"
import { useApi } from "../../../../../utils"
import { Render, useCode } from "../utils"
import { menuTitle } from "./ChildItem/components/Item/components/MenuTitle"

export function CreateModal(props: { religion_code?: string; religion_name?: string; description?: string; toggle: () => void; refetch?: () => void }) {
  const api = useApi()
  const { toggle } = useContext(Render)
  const { dataCode} = useCode()

  useEffect(() => {
    if (dataCode?.code) { methods.setValue("religion_code", dataCode?.code) }
  // eslint-disable-next-line
  }, [dataCode?.code])

  type CreateData = Omit<DataList, "religion_id"> 

  const defaultValues:CreateData= {
    religion_code: "",
    religion_name: "",
    description: ""
  }
  const validationSchema = yup.object().shape({
    religion_code: yup.string().label("Religion code").required().test('len', 'Must be exactly 3 characters', val => val.toString().length === 3),
    religion_name: yup.string().label("Religion name").required(),
    description: yup.string().label("Description").optional(),
  })
  const onSubmit = (value: CreateData) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.post("/religion/add", value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message
        }
      ).then(() => {
        props.toggle()
        props?.refetch && props.refetch()
        toggle()
      }).catch(() => {}).finally(resolve)
    })
  }
  const methods = useForm<CreateData>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })

  return (
    <Modal isOpen title={`SETUP | CREATE ${menuTitle.title}`} closeModal={props.toggle}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Modal.Body
            hintText={(
              <HintList
                title="Creating a Religion"
                list={[
                  {
                    label: "Religion Code",
                    list: [
                      {
                        label: "Definition",
                        value: "A unique alphanumeric identifier assigned to each religion in the system."
                      },
                      {
                        label: "Purpose",
                        value: "The code simplifies data entry, search, and reporting by providing a shorthand reference for each religion. It ensures consistency and standardization across all records and prevents ambiguity or duplication."
                      },
                      {
                        label: "Example",
                        value: "Codes could be 'REL01' for Christianity, 'REL02' for Islam, 'REL03' for Hinduism, etc. The specific format may vary depending on the organization's coding standards."
                      }
                    ]
                  },
                  {
                    label: "Name of Religion",
                    list: [
                      {
                        label: "Definition",
                        value: "The full name of the religion being recorded."
                      },
                      {
                        label: "Purpose",
                        value: "This attribute provides a clear, user-friendly reference for each religion. It is used in forms, reports, and user interfaces where human readability is essential."
                      },
                      {
                        label: "Example",
                        value: 'Names might include "Christianity," "Islam," "Hinduism," "Buddhism," "Judaism," "Sikhism," "Atheism," or any other recognized religious or non-religious belief.'
                      },
                    ]
                  },
                  {
                    label: "Description",
                    list: [
                      {
                        label: "Definition",
                        value: "A brief explanatory text that provides additional details or context about the religion."
                      },
                      {
                        label: "Purpose",
                        value: "The description helps users understand the characteristics, scope, or unique aspects of each religion. It can also provide important notes on cultural or legal considerations relevant to the organization, such as holiday observances, dietary restrictions, or specific practices."
                      },
                      {
                        label: "Example",
                        value: 'For "Christianity," the description might read: "A monotheistic religion based on the teachings of Jesus Christ, primarily practiced in the Americas, Europe, and parts of Africa." For "Islam," it might say: "A monotheistic religion based on the teachings of the Prophet Muhammad, practiced primarily in the Middle East, Asia, and Africa.'
                      },
                    ]
                  }
                ]}
                detail="These attributes are crucial for capturing accurate and detailed demographic data, supporting diversity initiatives, ensuring legal compliance (such as equal opportunity employment), and improving customer or employee relations by respecting individual beliefs and practices. "
              />
            )}
          >
            <FormSection />
          </Modal.Body>

          <Modal.Footer className="text-end">
            <Button type="submit" color="primary" loading={methods.formState.isSubmitting ? "true" : undefined}>Save</Button>
          </Modal.Footer>
        </form>
      </FormProvider>
    </Modal>
  )
}