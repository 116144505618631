import { BalanceSheetNoneType } from "pages/Finance/Report/types"

function getTotalBalance(
  element: BalanceSheetNoneType,
  data: Array<BalanceSheetNoneType>
): number {
  let totalBalance = 0

  const children = data.filter((coa) => coa.parent_id === element.coa_id)

  children.forEach((child) => {
    totalBalance += child.balance + getTotalBalance(child, data)
  })

  return totalBalance
}

export const calculateParent = (data: Array<BalanceSheetNoneType>) => {
  return data?.map((element) => {
    const children = data.filter((coa) => coa.parent_id === element.coa_id)

    if (!children.length) return element

    return { ...element, balance: getTotalBalance(element, data) }
  })
}
