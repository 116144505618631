import { Button, DateInput, Modal } from "components";
import moment from "moment";
import { Fragment, useState } from "react";
import toast from "react-hot-toast";
import { IoSendSharp } from "react-icons/io5";
import { useApi, useToggle } from "utils";
import PaymentDepositDetail from "../../PaymentDeposit/components/Detail";


export function ApproveAction(props: {
  data: any
  refetch: () => void
}) {
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <Button
        type="button"
        color="ghost"
        permission={"ST132"}
        onClick={toggle}
        disabled={Boolean(props?.data?.approval_status === 3 || props?.data?.approval_status === 4)}
      >
        <IoSendSharp />
      </Button>
      {isActive && <ModalSection toggle={toggle} data={props.data} refetch={props.refetch} />}
    </Fragment>
  )
}

function ModalSection(props: {
  toggle: () => void,
  data: any
  refetch: () => void
}): JSX.Element {
  const api = useApi()
  const [date, setDate] = useState<string>(moment().format("YYYY-MM-DD"))


  const onSubmit = (date: string | undefined, status: number) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.patch(`/depositcustomer/approve/${props.data.deposit_customer_id}`,
          {
            approval_status: status,
            approve_date: date,
          }),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => {
            if (err.response && err.response.status === 403) {
              return err.response.data.message || err.response.data.detail.message;
            } else {
              return "An error occurred";
            }
          }
        }
      ).then(() => {
        props.toggle()
        props?.refetch && props.refetch()
      }).catch(() => { }).finally(resolve)
    })
  }

  return (
    <Modal
      isdarktitle
      isOpen
      title="APPROVAL PAYMENT DEPOSIT"
      closeModal={props.toggle}
    >
      <Modal.Body>
        <PaymentDepositDetail
          deposit_customer_id={props?.data?.deposit_customer_id}
          sales_order_id={props?.data?.sales_order_id}
        />
      </Modal.Body>

      <Modal.Footer className="flex md:flex-row flex-col justify-between md:items-end gap-3">
        <DateInput
          placeholderText="Select Date"
          selected={moment(date).toDate()}
          className="w-full"
          label="APPROVAL DATE"
          onChange={(date) => {
            const selectDate = moment(date).format("YYYY-MM-DD")
            setDate(selectDate)
          }}
        />

        <section className="flex gap-3">
          <Button onClick={props.toggle}>CANCEL</Button>
          <Button color="error" onClick={() => onSubmit(date, 3)}>VOID</Button>
          <Button color="primary" onClick={() => onSubmit(date, 4)} >APPROVED</Button>
        </section>
      </Modal.Footer>
    </Modal>
  )
}

