export default function Home(): JSX.Element {
  // Vars
  const list = [
    {
      title: "Step 1: System Preferences Setup",
      children: [
        {
          title: "Login Configuration:",
          children: [
            "Ensure that all users have their login credentials.",
            "Set up user roles and permissions according to their job functions.",
            "Configure security settings like password policies, two-factor authentication, and session timeouts."
          ]
        },
        {
          title: "Localization Settings:",
          children: [
            "Set the correct time zone, date formats, and language preferences.",
            "Configure the system's base currency and any additional currencies needed for transactions."
          ]
        },
        {
          title: "General Settings:",
          children: [
            "Define your company's fiscal year.",
            "Set up default warehouse locations, tax rates, and other company-wide defaults.",
            "Customize dashboard views according to user roles."
          ]
        }
      ]
    },
    {
      title: "Step 2: Accounting System Setup",
      children: [
        {
          title: "Chart of Accounts:",
          children: [
            "Import or create a Chart of Accounts that fits your organization's financial structure.",
            "Set up account types like assets, liabilities, equity, income, and expenses.",
            "Ensure all necessary sub-accounts are included for detailed tracking."
          ]
        },
        {
          title: "Tax Configuration:",
          children: [
            "Define your tax codes and link them to the relevant accounts.",
            "Configure tax rates for sales, purchases, and other applicable transactions.",
            "Set up tax rules for different regions or products if applicable."
          ]
        },
        {
          title: "Bank Accounts:",
          children: [
            "Add company bank accounts to the system.",
            "Configure the linkage between bank accounts and general ledger accounts for seamless reconciliation."
          ]
        },
        {
          title: "Multi-Currency:",
          children: [
            "If your business deals with multiple currencies, configure exchange rates.",
            "Set up automatic updates for exchange rates or input them manually."
          ]
        }
      ]
    },
    {
      title: "Step 3: Inventory Management Setup",
      children: [
        {
          title: "Warehouse and Location Setup:",
          children: [
            "Define your warehouses and their locations.",
            "Set up storage bins, shelves, and sections within each warehouse."
          ]
        },
        {
          title: "Inventory Items:",
          children: [
            "Import or manually enter your inventory items.",
            "Assign categories, units of measure, and reordering levels for each item."
          ]
        },
        {
          title: "Inventory Valuation Methods:",
          children: [
            "Choose the valuation method: FIFO, LIFO, or Weighted Average.",
            "Set up automatic or manual adjustments for inventory counts."
          ]
        },
        {
          title: "Reorder and Restocking Rules:",
          children: [
            "Configure reorder points and restocking rules.",
            "Set up automated alerts or purchase orders when stock levels fall below the reorder point."
          ]
        }
      ]
    },
    {
      title: "Step 4: Sales and Purchase Module Setup",
      children: [
        {
          title: "Customer and Vendor Setup:",
          children: [
            "Import or manually enter customer and vendor information.",
            "Assign credit limits, payment terms, and discount structures."
          ]
        },
        {
          title: "Sales Configuration:",
          children: [
            "Set up price lists, discounts, and promotional pricing.",
            "Configure sales order workflows, including approvals and invoicing."
          ]
        },
        {
          title: "Purchase Configuration:",
          children: [
            "Define purchase order workflows and approval hierarchies.",
            "Configure vendor pricing and purchase agreements."
          ]
        }
      ]
    },
    {
      title: "Step 5: Human Resources and Payroll Setup",
      children: [
        {
          title: "Employee Records:",
          children: [
            "Enter or import employee data, including personal details, job roles, and departments.",
            "Configure access permissions based on roles."
          ]
        },
        {
          title: "Payroll Setup:",
          children: [
            "Set up payroll structures, including salary components, deductions, and allowances.",
            "Configure tax deduction rules and link to the accounting system for automated payroll processing."
          ]
        },
        {
          title: "Leave and Attendance:",
          children: [
            "Define leave types, accrual rules, and leave approval workflows.",
            "Set up attendance tracking, including integration with biometric systems if applicable."
          ]
        }
      ]
    },
    {
      title: "Step 6: Reporting and Analytics Setup",
      children: [
        {
          title: "Dashboard Customization:",
          children: [
            "Customize dashboards for different roles to display relevant KPIs and metrics.",
            "Set up real-time data feeds for critical business information."
          ]
        },
        {
          title: "Standard Reports:",
          children: [
            "Ensure all standard financial, inventory, and HR reports are configured correctly.",
            "Set up automated report generation and distribution schedules."
          ]
        },
        {
          title: "Custom Reports:",
          children: [
            "Train users on creating custom reports using the system’s reporting tools.",
            "Define parameters and filters to extract specific data as needed."
          ]
        }
      ]
    },
    {
      title: "Step 7: User Training and Support",
      children: [
        {
          title: "User Training Sessions:",
          children: [
            "Conduct role-based training sessions to ensure users understand how to navigate and use the ERP system.",
            "Provide user manuals, quick reference guides, and video tutorials."
          ]
        },
        {
          title: "Helpdesk and Support:",
          children: [
            "Set up a helpdesk system for users to report issues or seek help.",
            "Assign support staff to assist users during the initial implementation phase."
          ]
        }
      ]
    },
    {
      title: "Step 8: Go-Live and Post-Implementation",
      children: [
        {
          title: "Data Migration:",
          children: [
            "Migrate legacy data into the new ERP system, ensuring accuracy and completeness.",
            "Perform data validation checks to ensure everything is in place."
          ]
        },
        {
          title: "Go-Live Checklist:",
          children: [
            "Confirm all modules are working correctly.",
            "Ensure all users can access the system and their roles are properly configured.",
            "Monitor system performance and user activity closely during the initial days."
          ]
        },
        {
          title: "Post-Implementation Review:",
          children: [
            "Schedule follow-up meetings to address any issues or feedback.",
            "Continuously monitor system performance and user satisfaction."
          ]
        }
      ]
    }
  ]

  return (
    <section className="flex justify-center">
      <div className="w-full max-w-[800px]">
        <div className="text-xl text-center font-bold">SETUP Page Content</div>

        <div>Click Here to Learn More...</div>
        <iframe className="w-full aspect-video" src="https://www.youtube.com/embed/NZ4CzUd3BhU?si=N4mvEWbfSa02Yiaa" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
        <div>Setting up a new ERP system can seem overwhelming for end users, but breaking it down into clear steps helps simplify the process. Here's a guide to help you explain the setup to your users, starting from system preferences to the accounting system and beyond:</div>

        <section className="my-3 flex flex-col gap-3">
          {list.map((item, key) => (
            <section key={key}>
              <div className="text-lg font-bold">{item.title}</div>

              {item.children.map((item, key) => (
                <ul
                  key={key}
                  className="list-inside list-disc"
                >
                  <li>{item.title}</li>

                  <ul className="ml-6 list-inside list-[circle]">
                    {item.children.map((item, key) => <li key={key}>{item}</li>)}
                  </ul>
                </ul>
              ))}
            </section>
          ))}
        </section>

        <div>This step-by-step approach should help your users feel more comfortable and confident during the ERP system setup process.</div>
      </div>
    </section>
  )
}