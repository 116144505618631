import { GroupName } from "pages/Finance/Transaction/types"
import { useState } from "react"

export interface UseFilter {
  CoaID?: string
  LocationID?: string
  GroupCardID?: GroupName
  TraderID?: string
  ReferenceCode?: string
  typeFilter?: number
  dateFrom: string
  dateTo: string
}

export const useFilter = ({ typeFilter, CoaID, LocationID, GroupCardID, TraderID, ReferenceCode, dateFrom, dateTo }: UseFilter) => {
  const [filter, setFilter] = useState<UseFilter>({
    CoaID,
    LocationID,
    GroupCardID,
    TraderID,
    ReferenceCode,
    typeFilter,
    dateFrom,
    dateTo
  })

  const handleChangeFilter = (params: Partial<UseFilter>) => {
    setFilter((prev) => ({ ...prev, ...params }))
  }

  return {
    filter,
    handleChangeFilter
  }
}
