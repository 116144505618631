// Components
import { BaseTable, Card, CommandButton, DateInput, HeaderMenu, Input, Select, Textarea } from "components"

export default function AdjustmentInventory(): JSX.Element {
  return (
    <section className="container my-5 flex flex-col gap-5">
      <HeaderMenu title="DATA ENTRY | ADJUSTMENT INVENTORY">
        ABC123
      </HeaderMenu>

      <section className="grid lg:grid-cols-2 gap-x-6 gap-y-3">
        <section className="flex flex-col gap-3">
          <Input
            label="LOCATION"
            placeholder="Select Location"
          />

          <Input
            label="REQUEST BY"
            placeholder="Select Location"
          />

          <DateInput
            disabled
            label="DATE"
            onChange={() => {}}
          />

          <Textarea
            disabled
            label="COUNT MEMO"
          />

          <Input
            disabled
            label="LINK COUNT ID#"
          />
        </section>

        <section className="flex flex-col gap-3">
          <Select
            label="ADJUSTMENT BY"
            placeholder="Select Location"
          />

          <DateInput
            label="ADJUSTMENT DATE"
            onChange={() => {}}
          />

          <Textarea label="ADJUSTMENT MEMO" />
        </section>
      </section>

      <Card>
        <Card.Body className="flex flex-col gap-3">
          <div className="text-xl font-bold">LIST ITEM TRANSFERED</div>

          <BaseTable>
            <thead>
              <tr>
                <th rowSpan={2}>#</th>
                <th rowSpan={2}>ITEM</th>
                <th rowSpan={2}>DESCRIPTION</th>
                <th rowSpan={2}>UNIT</th>
                <th rowSpan={2}>STOCK IN</th>
                <th rowSpan={2}>STOCK OUT</th>
                <th colSpan={2}>RECAP IN CONVERTION UNIT</th>
                <th rowSpan={2}>AVERAGE UNIT PRICE</th>
                <th rowSpan={2}>AMOUNT ADJUSTMENT</th>
                <th rowSpan={2}>POSITION</th>
                <th rowSpan={2}>COA INVENTORY</th>
                <th rowSpan={2}>COA EXPENCES/COGS</th>
              </tr>

              <tr>
                <th>IN</th>
                <th>OUT</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td colSpan={13} />
              </tr>
            </tbody>
          </BaseTable>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body className="flex flex-col gap-5">
          <section className="flex flex-wrap justify-between gap-3">
            <section className="flex flex-wrap gap-3">
              <CommandButton actiontype="help" />
              <CommandButton actiontype="print" />
              <CommandButton actiontype="email" />
              <CommandButton actiontype="export" />
              <CommandButton actiontype="journal" />
              <CommandButton actiontype="attachment" />
            </section>

            <section className="flex flex-wrap gap-3">
              <CommandButton actiontype="delete" />
              <CommandButton actiontype="save" />
            </section>
          </section>
        </Card.Body>
      </Card>
    </section>
  )
}