// React
import { useContext } from "react"

// Components
import { Loading } from "components"
import { InputSection } from "../components"

// Contexts
import { AccountProvider } from "contexts"
import { PoContext } from "../contexts"

// Form
import { FormProvider, useForm } from "react-hook-form"

// Utils
import { type FormType, useDepositDetail } from "../utils"
import { DisabledContext } from "../../contexts"

export function Detail(params: {
  deposit_vendor_id: string
}): JSX.Element {
  // Vars
  const deposit_vendor_id = params.deposit_vendor_id

  // Hooks
  const { data, isLoading } = useDepositDetail(deposit_vendor_id!)

  if (isLoading || !data) {
    return <Loading loading={isLoading} />
  }

  return (
    <PoContext.Provider value={data}>
      <DisabledContext.Provider value={true}>
        <AccountProvider>
          <FormSection />
        </AccountProvider>
      </DisabledContext.Provider>
    </PoContext.Provider>
  )
}

function FormSection(): JSX.Element {
  // Hooks
  const data = useContext(PoContext)

  // Vars
  const datapo = data.datapo

  // Form
  const defaultValues: FormType = {
    _balance: 0,
    amount: data.amount,
    coa_id: data.coa_id,
    memo: data.memo,
    no_cheque: data.no_cheque,
    trx_code: data.trx_code,
    transaction_date: data.transaction_date,
    vendor_id: datapo.vendor_id
  }
  const methods = useForm<FormType>({
    defaultValues,
  })
  
  return (
    <FormProvider {...methods}>
      <InputSection />
    </FormProvider>
  )
}