/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react"
import { SuccessFetch } from "types"
import { useApi } from "utils"
import { JournalList, MapJournalList } from "../../types"
import { mapJournalList } from "../functions/mapJournalList"

type UseJournalList = {
  DataSource: number
  StartDate: string
  EndDate: string
  onSuccess?: (data: MapJournalList[]) => void
}

type Response = {
  payload: JournalList[]
}

export function useJournalList({
  DataSource,
  StartDate,
  EndDate,
  onSuccess
}: UseJournalList) {
  const api = useApi()
  const [data, setData] = useState<MapJournalList[]>([])
  const [isLoading, setLoading] = useState(false)

  const fetchData = () => {
    setLoading(true)

    api
      .postForm("/journal/getjournaldata", { StartDate, EndDate, DataSource })
      .then((res: SuccessFetch<Response>) => {
        const data = res.data.payload ?? []
        const mapData = mapJournalList(data)

        onSuccess?.(mapData)
        setData(mapData)
      })
      .catch(() => setData([]))
      .finally(() => setLoading(false))
  }

  const refetch = () => fetchData()

  useEffect(() => {
    if (StartDate && EndDate) {
      fetchData()
    }

    return () => {
      setData([])
      setLoading(false)
    }
  }, [StartDate, EndDate, DataSource])

  return { data, isLoading, refetch }
}
