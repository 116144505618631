// React
import { Fragment } from "react"

// Components
import { Button, Label, Select } from "components"
import { CreateModal as LocationModal } from "../../../../../../../Setup/Preference/Location/components"

// Form
import { Controller, useFormContext } from "react-hook-form"

// Icons
import { TbPlus } from "react-icons/tb"

// Types
import type { FormType } from "pages/Finance/Transaction/utils"

// Utils
import { useToggle } from "utils"
import { useLocation } from "./utils"

export function Location() {
  // Hooks
  const { data, isLoading, refetch } = useLocation()

  // Form
  const { control } = useFormContext<FormType>()

  return (
    <Controller
      control={control}
      name="location_id"
      render={({ field, fieldState }) => (
        <section>
          <Label text="LOCATION" error={Boolean(fieldState.error)} />
    
          <section className="flex gap-3">
            <Select
              className="grow"
              placeholder="Select Location"
              error={fieldState.error?.message}
              isLoading={isLoading}
              options={data}
              value={data.find(item => item.value === field.value) ?? null}
              onChange={item => field.onChange(item?.value)}
            />
    
            <CreateLocation onSuccess={refetch} />
          </section>
        </section>
      )}
    />
  )
}

function CreateLocation(props: {
  onSuccess: () => void
}) {
  // Hooks
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <Button color="primary" className="px-2" onClick={toggle}>
        <TbPlus size={24} />
      </Button>

      {isActive && <LocationModal toggle={toggle} onSuccess={props.onSuccess} />}
    </Fragment>
  )
}