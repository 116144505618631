/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, HeaderMenu, Input, NumberInput } from "components"
import { APPROVAL } from "pages/Sales/Register/utils/vars"
import { DropdownCustomer } from "pages/Sales/Transaction/CustomerOrder/components"
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch
} from "react-hook-form"
import toast from "react-hot-toast"
import { IoSendSharp } from "react-icons/io5"
import { useNavigate } from "react-router-dom"
import { useToggle } from "utils"
import { FormInvoiceType } from "../types/FormInvoice"
import { useDeliveryNo } from "../utils/hooks"
import { CodeInvoice } from "./CodeInvoice"
import { CommandButtons } from "./CommandButtons"
import { FormInvoice } from "./FormInvoice"
import { ModalDelete } from "./ModalDelete"
import { SummaryFooter } from "./SummaryFooter"
import { TableSalesOrderInvoice } from "./Tables"

export type FormSectionProps = {
  approvalView?: boolean
  isEdit?: boolean
  onSubmit: () => void
}

export const FormSection = ({
  approvalView,
  isEdit = false,
  onSubmit
}: FormSectionProps) => {
  const navigate = useNavigate()
  const modalDelete = useToggle(false)
  const methods = useFormContext<FormInvoiceType>()
  const [
    invoice_id,
    approval_status,
    sales_order_id,
    transaction_date,
    trx_code,
    customer_id,
    customer_name
  ] = useWatch({
    control: methods.control,
    name: [
      "invoice_id",
      "approval_status",
      "sales_order_id",
      "transaction_date",
      "trx_code",
      "customer_id",
      "customer_name"
    ]
  })

  const invoice_detail = useFieldArray({
    control: methods.control,
    name: "invoice_detail"
  })

  const sodetail = useFieldArray({
    control: methods.control,
    name: "sodetail"
  })

  const deleteData = {
    invoice_id: invoice_id ?? 0,
    customer_id: customer_id ?? 0,
    transaction_date,
    trx_code: trx_code ?? "",
    customer_name: customer_name ?? "",
    approval_status: approval_status ?? 1
  }

  useDeliveryNo({
    enabled: !isEdit && Boolean(sales_order_id),
    onSuccess: (data) => methods.setValue("delivery_no", data)
  })

  return (
    <section className="container my-5 flex flex-col gap-5">
      <HeaderMenu title="DATA ENTRY | DELIVERY INVOICE">
        <CodeInvoice isEdit={isEdit} />
      </HeaderMenu>

      <section className="grid lg:grid-cols-3 gap-x-6 gap-y-3">
        <section className="w-full">
          <DropdownCustomer approvalView={approvalView} isEdit />
        </section>

        <section>
          <div className="normal-case flex justify-evenly items-center pt-10">
            <div className="flex flex-col gap-2">
              <span>TRADING TERM </span>
              <span>PRICE LEVEL</span>
            </div>

            <div className="px-2 flex flex-col gap-2">
              <IoSendSharp />
              <IoSendSharp />
            </div>

            <div className="flex flex-col gap-2">
              <Controller
                control={methods.control}
                name="payment_term_name"
                render={({ field }) => (
                  <span>Net {field.value ?? 0} Days after OEM </span>
                )}
              />

              <Controller
                control={methods.control}
                name="price_level_name"
                render={({ field }) => (
                  <span>{field.value ? field.value : "-"}</span>
                )}
              />
            </div>
          </div>
        </section>

        <section className="lg:justify-self-end self-end mb-3">
          <Controller
            control={methods.control}
            name="tax_inclusive"
            render={({ field }) => (
              <Checkbox disabled value={field.value} label="">
                TAX-INCLUSIVE
              </Checkbox>
            )}
          />
        </section>
      </section>

      <FormInvoice approvalView={approvalView} />

      <TableSalesOrderInvoice
        approvalView={approvalView}
        data={invoice_detail.fields}
        displayArrowNavigation={false}
        displayStockOnHand={true}
        onClickDeleteSOInvoice={(key: number) => {
          invoice_detail.remove(key)
        }}
      />

      <section className="grid lg:grid-cols-2 gap-x-6 gap-y-3">
        <div className="flex flex-col gap-3 flex-grow">
          <Controller
            control={methods.control}
            name="invoice_status_text"
            render={({ field }) => (
              <Input disabled label="INVOICE STATUS" value={field.value} />
            )}
          />

          <Input disabled label="PAYMENT STATUS" value="" />

          <Controller
            control={methods.control}
            name="approval_status"
            render={({ field, fieldState }) => (
              <Input
                disabled
                label="APPROVE STATUS"
                value={APPROVAL[field.value]}
                ref={field.ref}
                onChange={field.onChange}
                error={fieldState.error?.message}
              />
            )}
          />

          <Controller
            control={methods.control}
            name="approved_by_name"
            render={({ field, fieldState }) => (
              <Input
                disabled
                label="APPROVE BY"
                value={field.value}
                ref={field.ref}
                onChange={field.onChange}
                error={fieldState.error?.message}
              />
            )}
          />

          <Controller
            control={methods.control}
            name="freight"
            render={({ field, fieldState }) => (
              <NumberInput
                disabled={approval_status === APPROVAL.APPROVE || approvalView}
                decimalSeparator="."
                thousandSeparator=","
                decimalScale={0}
                textRight
                label="FREIGHT"
                value={field.value}
                ref={field.ref}
                onValueChange={(value) => field.onChange(value.floatValue)}
                error={fieldState.error?.message}
              />
            )}
          />
        </div>

        <div className="flex flex-col lg:items-end gap-3">
          <SummaryFooter />
        </div>
      </section>

      <TableSalesOrderInvoice
        display
        approvalView={approvalView}
        data={sodetail.fields}
        displayArrowNavigation={true}
        displayStockOnHand={false}
        onClickArrowNavigation={(item) => {
          const isExist = invoice_detail?.fields?.find(
            (inv) => inv.product_name === item.product_name
          )
          // const hasStock = (item?.soh ?? 0) > 0
          const hasStock = true

          if (!isExist && hasStock) {
            return invoice_detail.append(item)
          }

          toast.error(
            isExist
              ? "Sales Order Detail is already on Invoice Detail List"
              : "Stock is not available"
          )
        }}
      />

      <CommandButtons
        approvalView={approvalView}
        showSaveButton={approval_status !== APPROVAL.APPROVE}
        showDeleteButton={isEdit && approval_status !== APPROVAL.APPROVE}
        onDelete={() => modalDelete.setActive(true)}
        onSave={onSubmit}
      />

      <ModalDelete
        modalState={modalDelete}
        data={deleteData}
        onSuccess={() => {
          modalDelete.setActive(false)
          navigate("/sales/register", { replace: true })
        }}
      />
    </section>
  )
}
