import { Input } from "components";

type BreakEventAnalysisProps = { disable?: boolean };

export const BreakEventAnalysis = ({ disable }: BreakEventAnalysisProps) => {
    const calculatorUse = "Use this calculator to determine the break-event point for a product: the number of units you need to self for your revenue to equal your costs. Enter your costs, unit price and unit increments in the table below."
    const toCalculator = "To calculate the break-event point in units use the formula: Break-Event point(units) = Fixed Costs + (Sales price per unit - variable costs per unit) or in sales dollars using the formula: Break-Event point (sales dollars) = Fixed Costs + Contribution Margin."

    return (
      <>
        <div className="text-[16px] mb-5"><b>BREAK-EVENT ANALYSIS</b></div>
        <small className="mb-5">{calculatorUse}</small>
        <div className="flex items-center justify-end mt-2">
            <div className="mr-3 text-[13px]"><b>FIXED EXP/ YEAR</b></div>
            <Input disabled={disable} />
        </div>
        <div className="flex items-center justify-end mt-2">
            <div className="mr-3 text-[13px]"><b>COGM</b></div>
            <Input disabled={disable} />
        </div>
        <div className="flex items-center justify-end mt-2">
            <div className="mr-3 text-[13px]"><b>RETAIL PRICE</b></div>
            <Input disabled={disable} />
        </div>
        <div className="flex items-center justify-end my-2">
            <div className="mr-3 text-[13px]"><b>UNIT BEP</b></div>
            <Input disabled={disable} />
        </div>
        <small className="mt-5">{toCalculator}</small>
      </>
    )
}