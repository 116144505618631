import { useEffect, useState } from "react"
import type { SelectOptionType, SuccessFetch } from "../../../../../../../../types"
import { useApi } from "../../../../../../../../utils"

export function useProductSize() {
  const api = useApi()
  const [dataSize, setData] = useState<SelectOptionType[]>([])
  const [isLoadingSize, setLoading] = useState(true)

  const refetchSize = () => {
    setLoading(true)

    api.get("/productsize/")
    .then((res: SuccessFetch<{
      payload: {
        mr_product_size_id: number
        mr_product_size_name: string
      }[]
    }>) => { setData(res.data.payload.map(item => { return { label: item.mr_product_size_name, value: item.mr_product_size_id.toString() }}))})
    .catch(() => setData([]))
    .finally(() => setLoading(false))
  }

  useEffect(() => {
    setLoading(true)

    api.get("/productsize/").then((res: SuccessFetch<{
      payload: {
        mr_product_size_id: number
        mr_product_size_name: string
      }[]
    }>) => { setData(res.data.payload.map(item => { return { label: item.mr_product_size_name, value: item.mr_product_size_id.toString() }}))})
    .catch(() => setData([]))
    .finally(() => setLoading(false))
    // eslint-disable-next-line
  }, [])

  return { dataSize, isLoadingSize, refetchSize }
}