import { Fragment, useContext } from "react"
import { useToggle } from "utils"
import { DataTableContext } from "./DataForm"
import { Modal } from "components"
import { CreatePage as NewVendor } from "pages/Card/Transaction/CreateNewVendor/components"

export const Vendor = (props: { action?: "CREATE" | "UPDATE" | "DETAIL" }) => {
    const { isActive, toggle } = useToggle(false)
    const { dropdownVendor } = useContext(DataTableContext)
  
    return (
      <Fragment>
        <th className="whitespace-nowrap">
          VENDOR {props.action !== "DETAIL" && <span className="whitespace-nowrap text-primary cursor-pointer" onClick={toggle}>[+]</span>}
        </th>
  
        {isActive &&
          <Modal
            isOpen 
            title="CREATE NEW VENDOR"
            size="full" 
            closeModal={() => {
              toggle()
              dropdownVendor.refetchVendor()
            }}>
              <NewVendor asModal />
          </Modal>
        }
      </Fragment>
    )
  }