/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react"
import { SuccessFetch } from "types"
import { useApi } from "utils"
import { TransferItemsList } from "../../types"

type UseTransferItemsListProps = {
  start_date?: string
  end_date?: string
  Approval?: number
  search?: string
  page?: number
  limit?: number
}

type Response = {
  payload: TransferItemsList[]
  pagination_data: {
    total: number
    page: number
    size: number
    pages: number
  }
}

export function useTransferItemsList({
  start_date,
  end_date,
  Approval,
  search,
  page,
  limit
}: UseTransferItemsListProps) {
  const api = useApi()
  const [data, setData] = useState<Response["payload"]>([])
  const [paginate, setPaginate] = useState<Response["pagination_data"]>()
  const [isLoading, setLoading] = useState(false)

  const fetchData = () => {
    setLoading(true)

    const params = {
      DateFrom: start_date,
      DateEnd: end_date,
      Approval,
      search,
      page,
      limit
    }

    api
      .post("/transfer/getdata", null, { params })
      .then((res: SuccessFetch<Response>) => {
        const data = res.data.payload ?? []

        setData(data)
        setPaginate(res.data.pagination_data)
      })
      .catch(() => {
        setData([])
        setPaginate({
          page: 1,
          size: 0,
          pages: 0,
          total: 0
        })
      })
      .finally(() => setLoading(false))
  }

  const refetch = () => fetchData()

  useEffect(() => {
    fetchData()

    return () => {
      setData([])
      setLoading(false)
    }
  }, [page, limit, Approval])

  return {
    data,
    paginate,
    isLoading,
    refetch,
    dropdown: data.map((item) => ({
      value: item.transfer_id,
      label: item.trx_code
    }))
  }
}
