import { useEffect, useState } from "react"
import type { SuccessFetch } from "../../../../../../types"
import { useApi } from "../../../../../../utils"

interface FilteredParams {
  page?: number
  keyword?: string
  limit?: number
}

interface DataType {
  items: {
    smp_id: number
    smp_code: string
    smp_name: string
    description?: string,
  }[]
  pagination_data: {
    total: number
    page: number
    size: number
    pages: number
  }
}

export function useList({ page, keyword, limit }: FilteredParams) {

  // Vars
  const defaultValue = {
    items: [],
    pagination_data: {
      page: 0,
      pages: 0,
      size: 0,
      total: 0
    }
  }

  const api = useApi()
  const [data, setData] = useState<DataType>(defaultValue)
  const [isLoading, setLoading] = useState(true)

  const fetchUrl = () => {
    if (page && keyword && limit) {
      return api.get(`/smp/all?page=1&keyword=${keyword}&limit=${limit}`)
    } else if (page && !keyword && !limit) {
      return api.get(`/smp/all?page=${page}`)
    } else if (page && limit) {
      return api.get(`/smp/all?page=${page}&limit=${limit}`)
    } else if (page && keyword) {
      return api.get(`/smp/all?page=${page}&keyword=${keyword}`)
    } else {
      return api.get("/smp/all")
    }
  }

  const fetchData = () => {
    setLoading(true)

    fetchUrl().then((res: SuccessFetch<{
      payload: DataType
    }>) => {
      setData(res.data.payload)
    }).catch(() => {
      setData(defaultValue)
    }).finally(() => {
      setLoading(false)
    })
  }

  const refetch = () => fetchData()

  useEffect(() => {
    fetchData()

    return () => {
      setData(defaultValue)
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [page, keyword, limit])

  return { data, isLoading, refetch }
}


interface code {
  code: string
}

export function useCode() {
  const api = useApi()
  const [dataCode, setDataCode] = useState<code | null>(null)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    const fetchData = () => api.get("/smp/gencode")

    fetchData().then((res: SuccessFetch<{
      payload: code
    }>) => {
      setDataCode(res.data.payload)
    }).catch(() => {
      setDataCode(null)
    }).finally(() => {
      setLoading(false)
    })

    return () => {
      setDataCode(dataCode)
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [])

  return { dataCode, isLoading, setDataCode }
}