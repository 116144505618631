// React
import { PropsWithChildren } from "react"

// Components
import { Card, CommandButton } from "components"

export function ActionButton(params: PropsWithChildren<{}>) {
  return (
    <Card>
      <Card.Body className="flex flex-col gap-5">
        <section className="flex flex-wrap justify-between gap-3">
          {params.children}
        </section>
      </Card.Body>
    </Card>
  )
}

function LeftSide() {
  return (
    <section className="flex flex-wrap gap-3">
      <CommandButton actiontype="help" />
      <CommandButton actiontype="print" />
      <CommandButton actiontype="email" />
      <CommandButton actiontype="export" />
      <CommandButton actiontype="journal" />
      <CommandButton actiontype="attachment" />
    </section>
  )
}

ActionButton.LeftSide = LeftSide