import { Modal } from "components";
import { Fragment } from "react";
import { TbPlus } from "react-icons/tb";
import { useToggle } from "utils";
import { UpdatePage } from "../../AddNewItem/components/ChildItem/components/Item/components";
import { useDefaultSize } from "../../AddNewItem/components/ChildItem/components/Item/utils";

export function AddStock(props: {
    id: string
    refetch: () => void
}) {
    const { isActive, toggle } = useToggle(false)
    return (
        <Fragment>
            <button tabIndex={0} className="btn btn-primary rounded-none px-2" onClick={toggle} disabled={Boolean(!props.id)}>
                <TbPlus size={24} />
            </button>
            {isActive && <ModalSection toggle={toggle} id={props.id} refetch={props.refetch} />}
        </Fragment>
    )
}

function ModalSection(props: {
    toggle: () => void,
    id: string,
    refetch: () => void
}) {
    const { dataDefault } = useDefaultSize()
    return (
        <Modal isOpen title="" size="full" closeModal={props.toggle}>
            <Modal.Body>
                <UpdatePage id={props.id} size={dataDefault!} refetch={props.refetch}   />
            </Modal.Body>
        </Modal>
    )
}