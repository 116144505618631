import { useEffect, useState } from "react"
import type { SuccessFetch } from "types"
import type { FetchType, FormType, ListType } from "../../types"
import { useApi } from "utils"
import { default_option, delivery_option } from "../vars"

export function usePurchaseList(params: FormType) {
  const api = useApi()
  const [data, setData] = useState<ListType[]>([])
  const [filter, setFilter] = useState<FormType>(params)
  const [isLoading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    refetch()

    return () => {
      setData([])
      setLoading(false)
    }

    // eslint-disable-next-line
  }, [ filter ])

  const refetch = (): void => {
    api.get("/purchaseorder/by-date", { params: { 
      date_from: filter.DateFrom, 
      date_end: filter.DateEnd, 
      approval_satatus: filter.Approval, 
      delivery_status: filter.Delivered
    }})
    .then((res: SuccessFetch<{ payload: FetchType[] }>) => {
      setData(res.data.payload.map(item => {
        return {
          ...item,
          approval: default_option.find(res => res.value === String(item.approval_status))?.label ?? "-",
          delivery: delivery_option.find(res => res.value === String(item.po_delivery_status))?.label ?? "-"
        }
      }))
    })
    .catch(() => setData([]))
    .finally(() => setLoading(false))
  }

  return { data, filter, isLoading, refetch, setFilter }
}