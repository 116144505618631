import { useState, useEffect } from "react" 
import { useFormContext, useWatch } from "react-hook-form"
import { Button } from "../../../../../../components"
import type { ComponentCreateProps, ComponentList, ComponentProps } from "../../types"
import toast from "react-hot-toast"
import { useApi } from "../../../../../../utils"
import { IngredientFormulaTable, ItemProductTable } from "../ChildItem/components/Item/components/FormComponent"

export const FormComponentCreate = ({ action, id, isActiveSubmit, itemsProfile, selected, setSelected, fieldValue, setFieldValue, setComponentCost, refetchPriceUnit, permission }: ComponentCreateProps) => {
  const api = useApi()
  const total = selected.reduce((acc: any, curr: any) => { return acc + (curr.qty * curr.price_unit_convention) }, 0);
  const { control, getValues, handleSubmit, formState } = useFormContext<ComponentList>()
  const mr_product_group_id = useWatch({ control, name: "mr_product_group_id" })

  const grandTotal = () => {
    const totalAmount = selected?.reduce((prevValue: any, currentValue: any) => {
      const checkTotal = currentValue.amount ? parseFloat(currentValue.amount) : 0
      return prevValue + checkTotal
    }, 0)

    return { 
      amount: total ? total : totalAmount,
      cost_production: totalAmount ? (fieldValue?.cost_production/100 || 0) * totalAmount : total ? fieldValue?.cost_production/100 * total : "0",
      wasted: totalAmount ? (fieldValue?.wasted/100 || 0) * totalAmount : total ? fieldValue?.wasted/100 * total : "0",
      conponent_at_cost: totalAmount
        ? totalAmount + ((fieldValue?.cost_production/100 || 0) * totalAmount) + ((fieldValue?.wasted/100 || 0) * totalAmount)
        : total ? total + ((fieldValue?.cost_production/100 || 0) * total) + ((fieldValue?.wasted/100 || 0) * total) : "0",
    }
  }
  function getFinalValue(value: ComponentList) {
    const data_value: {
      product_id: any,
      product_ingredient_id: string,
      product_component_id: number | undefined,
      qty: number | null,
      product_price_unit_id: string,
    }[] = []
  
    selected.map((item: any) => {
      return data_value.push({
        product_id: id,
        product_ingredient_id: item.product_id,
        product_component_id: item.product_component_id,
        qty: item.qty ? parseFloat(item.qty) : 0,
        product_price_unit_id: item.product_price_unit_id,
      })
    })
  
    return {
      SchemaProductComponentCost: { 
        product_id: id,
        total_ingredient_cost: grandTotal()?.amount,
        production_cost: grandTotal()?.cost_production,
        wasted_cost: grandTotal()?.wasted,
        component_at_cost: grandTotal()?.conponent_at_cost,
      },
      SchemaProductPriceUnit: {
        product_id: id,
        unit_measure_id: fieldValue?.build_item,
        unit_convention_id: fieldValue?.build_item,
        quantity_unit_measure: fieldValue?.quantity,
        quantity_unit_convention: fieldValue?.quantity,
        price_unit_measure: grandTotal()?.conponent_at_cost,
        price_unit_convention: grandTotal()?.conponent_at_cost,
      },
      DataProductComponent: { dataproductcomponent: data_value },
    }
  }
  const onSubmitHandler = (value: ComponentList) => {
    return new Promise<void>((resolve) => {

      toast.promise(
        api.post("/product/component", getFinalValue(value)),
        {
          loading: "Loading...",
          success: (res) => res?.data?.message,
          error: (err) => {
            if (err?.response && err?.response?.status === 422) {
              selected?.forEach((val: any) => {
                if (val.qty === null || typeof val.qty === 'undefined') {
                  return "Qty. Field Required!"
                }
              });
              return err?.response?.data?.message || "Bad Request";
            } else if (err?.response && err?.response?.status === 500) {
              return "Internal Server Error";
            } else {
              return "An error occurred";
            }
          }
        }
      )
      .then(() => { 
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        refetchPriceUnit()
      })
      .catch(() => {})
      .finally(resolve)
    })
  }

  return (
    <div>
      <section>
        <ItemProductTable
          selected={selected} 
          setSelected={setSelected} 
          id={mr_product_group_id} 
          itemsProfile={itemsProfile} 
          fieldValue={fieldValue} 
          setFieldValue={setFieldValue} 
          action={action}
          setComponentCost={setComponentCost}
          refetchCOGSManufacture={() => {}}
          refetchBuying={() => {}}
        />
        <IngredientFormulaTable selected={selected} setSelected={setSelected} action={action} id={id} />
      </section>
      {action !== "DETAIL" &&
        <div className="flex justify-end">
          <Button 
            type="button"
            className="!none mt-5" 
            color="primary"
            loading={formState.isSubmitting ? "true" : undefined}
            onClick={handleSubmit(() => onSubmitHandler(getValues()),(err)=> console.log(err))}
            disabled={isActiveSubmit}
            permission={"CR021"}
          >
            {action === "UPDATE" ? "UPDATE" : "SAVE"}
          </Button>
        </div>
      }
    </div>
  )
}
export const FormComponent = ({ action, id, isActiveSubmit, itemsProfile, dataCOGS, dataComponentTable, dataBuying, refetchComponentTable, refetchCOGSManufacture, refetchBuying, refetch }: ComponentProps) => {
  const api = useApi()
  const [selected, setSelected] = useState<any[]>(dataComponentTable ?? []);

  const total = selected.reduce((acc: any, curr: any) => { return acc + ((curr.qty || 0) * curr.price_unit_convention) }, 0);

  const costProductionPercentage = Math.floor((parseFloat(dataCOGS?.[0]?.production_cost) / total) * 100) ?? ""
  const wastedPercentage = Math.floor((parseFloat(dataCOGS?.[0]?.wasted_cost) / total) * 100) ?? ""
  const overheadPercentage = Math.floor((parseFloat(dataCOGS?.[0]?.overhead) / parseFloat(dataCOGS?.[0]?.component_at_cost)) * 100) ?? ""
  const salesPercentage = Math.floor((parseFloat(dataCOGS?.[0]?.sales_tax) / parseFloat(dataCOGS?.[0]?.component_at_cost)) * 100) ?? ""
  const otherPercentage = Math.floor((parseFloat(dataCOGS?.[0]?.other_charges) / parseFloat(dataCOGS?.[0]?.component_at_cost)) * 100) ?? ""

  const { control, getValues, handleSubmit, formState, setValue } = useFormContext<ComponentList>()
  const [fieldValue, setFieldValue] = useState<any>({ 
    total_ingredient_cost: total ?? undefined, 
    component_at_cost: dataCOGS?.[0]?.component_at_cost ?? undefined, 
    cogm: dataCOGS?.[0]?.cogm ?? undefined, 
    cost_production: costProductionPercentage ?? undefined, 
    wasted: wastedPercentage ?? undefined, 
    overhead_cost: overheadPercentage ?? undefined,
    sales_tax: salesPercentage ?? undefined,
    other_change: otherPercentage ?? undefined,
    build_item: dataBuying?.unit_convention_id ?? undefined,
    quantity: dataBuying?.quantity_unit_convention ?? 1,
  })

  const mr_product_group_id = useWatch({ control, name: "mr_product_group_id" })

  useEffect(() => {
    action === "UPDATE" && setValue('unit_build_id', dataBuying?.unit_convention_id!)
    action === "UPDATE" && setFieldValue({ 
      ...fieldValue, 
      cost_production: Math.floor((parseFloat(dataCOGS?.[0]?.production_cost) / total) * 100) ?? "",
      wasted: Math.floor((parseFloat(dataCOGS?.[0]?.wasted_cost) / total) * 100) ?? ""
    })
    // eslint-disable-next-line
  },[dataBuying?.unit_convention_id, dataCOGS?.[0]?.production_cost])

  const grandTotal = () => {
    const totalAmount = selected?.reduce((prevValue: any, currentValue: any) => {
      const checkTotal = currentValue.amount ? parseFloat(currentValue.amount) : 0
      return prevValue + checkTotal
    }, 0)

    return { 
      amount: total ? total : totalAmount,
      cost_production: total ? fieldValue?.cost_production/100 * total : "0",
      wasted: total ? fieldValue?.wasted/100 * total : "0",
      conponent_at_cost: total ? total + ((fieldValue?.cost_production/100 || 0) * total) + ((fieldValue?.wasted/100 || 0) * total) : "0",
    }
  }
  function getFinalValue(value: ComponentList) {
    const data_value: {
      product_id: any,
      product_ingredient_id: string,
      product_component_id: number | undefined,
      qty: number | null,
      product_price_unit_id: string,
    }[] = []
  
    selected.map(item => {
      return data_value.push({
        product_id: id,
        product_ingredient_id: item?.product_ingredient_id ? item?.product_ingredient_id : item?.product_id,
        product_component_id: item.product_component_id,
        qty: item.qty ? parseFloat(item.qty) : 0,
        product_price_unit_id: item.product_price_unit_id,
      })
    })
  
    return {
      SchemaProductComponentCost: { 
        product_id: id,
        total_ingredient_cost: grandTotal()?.amount,
        production_cost: grandTotal()?.cost_production,
        wasted_cost: grandTotal()?.wasted,
        component_at_cost: grandTotal()?.conponent_at_cost,
      },
      SchemaProductPriceUnit: {
        product_id: id,
        unit_measure_id: fieldValue?.build_item,
        unit_convention_id: fieldValue?.build_item,
        quantity_unit_measure: fieldValue?.quantity,
        quantity_unit_convention: fieldValue?.quantity,
        price_unit_measure: grandTotal()?.conponent_at_cost,
        price_unit_convention: grandTotal()?.conponent_at_cost,
      },
      DataProductComponent: { dataproductcomponent: data_value },
    }
  }
  const onSubmitHandler = (value: ComponentList) => {
    return new Promise<void>((resolve) => {

      toast.promise(
        api.post("/product/component", getFinalValue(value)),
        {
          loading: "Loading...",
          success: (res) => res?.data?.message,
          error: (err) => {
            if (err?.response && err?.response?.status === 422) {
              selected?.forEach(val => {
                if (val.qty === null || typeof val.qty === 'undefined') {
                  return "Qty. Field Required!"
                }
              });
              return err?.response?.data?.message || "Bad Request";
            } else if (err?.response && err?.response?.status === 500) {
              return "Internal Server Error";
            } else {
              return "An error occurred";
            }
          }
        }
      )
      .then(() => { 
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        refetchComponentTable()
        refetchCOGSManufacture()
        refetchBuying()
        refetch && refetch()
      })
      .catch(() => {})
      .finally(resolve)
    })
  }

  return (
    <div>
      <section>
        <ItemProductTable 
          selected={selected} 
          setSelected={setSelected} 
          id={mr_product_group_id} 
          itemsProfile={itemsProfile} 
          fieldValue={fieldValue} 
          setFieldValue={setFieldValue} 
          action={action}
          setComponentCost={() => {}}
          refetchCOGSManufacture={refetchCOGSManufacture}
          refetchBuying={refetchBuying}
        />
        <IngredientFormulaTable selected={selected} setSelected={setSelected} action={action} id={id} />
      </section>
      {action !== "DETAIL" &&
        <div className="flex justify-end">
          <Button 
            type="button"
            className="!none mt-5" 
            color="primary"
            loading={formState.isSubmitting ? "true" : undefined}
            onClick={handleSubmit(() => onSubmitHandler(getValues()),(err)=> console.log(err))}
            disabled={isActiveSubmit}
          >
            {action === "UPDATE" ? "UPDATE" : "SAVE"}
          </Button>
        </div>
      }
    </div>
  )
}


// GET FINAL VALUE
// ( SchemaProductComponentCost )
// overhead: grandTotal()?.overhead_cost,
// sales_tax: grandTotal()?.sales_tax,
// other_charges: grandTotal()?.other_change,
// cogm: grandTotal()?.manufacture,

// DELETE INGRIDIENTS FORMULA
// field?.product_component_id && api.delete(`/product/deletecomponent?product_component_id=${field?.product_component_id}`)

// SUMMARY INGRIDIENTS FORMULA
// {grandTotal()?.amount ? grandTotal()?.amount?.toLocaleString("id-ID", { maximumFractionDigits: 0 }) : total.toLocaleString("id-ID", { maximumFractionDigits: 0 }) }

// CREATE
// amount: total ? total : totalAmount,
// cost_production: totalAmount 
//   ? (fieldValue?.cost_production/100 || 0) * totalAmount 
//   : total ? fieldValue?.cost_production/100 * total : "0",
// wasted: totalAmount 
//   ? (fieldValue?.wasted/100 || 0) * totalAmount
//   : total ? fieldValue?.wasted/100 * total : "0",
// conponent_at_cost: totalAmount
//   ? totalAmount + ((fieldValue?.cost_production/100 || 0) * totalAmount) + ((fieldValue?.wasted/100 || 0) * totalAmount)
//   : total ? total + ((fieldValue?.cost_production/100 || 0) * total) + ((fieldValue?.wasted/100 || 0) * total) : "0",
// overhead_cost: totalAmount 
//   ? (fieldValue?.overhead_cost/100 || 0) * (totalAmount + ((fieldValue?.cost_production/100 || 0) * totalAmount) + ((fieldValue?.wasted/100 || 0) * totalAmount))
//   : total ? (fieldValue?.overhead_cost/100 || 0) * (total + ((fieldValue?.cost_production/100 || 0) * total) + ((fieldValue?.wasted/100 || 0) * total)) : "0",
// sales_tax: totalAmount 
//   ? (fieldValue?.sales_tax/100 || 0) * (totalAmount + ((fieldValue?.cost_production/100 || 0) * totalAmount) + ((fieldValue?.wasted/100 || 0) * totalAmount))
//   : total ? (fieldValue?.sales_tax/100 || 0) * (total + ((fieldValue?.cost_production/100 || 0) * total) + ((fieldValue?.wasted/100 || 0) * total)) : "0",
// other_change: totalAmount
//   ? (fieldValue?.other_change/100 || 0) * (totalAmount + ((fieldValue?.cost_production/100 || 0) * totalAmount) + ((fieldValue?.wasted/100 || 0) * totalAmount))
//   : total ? (fieldValue?.other_change/100 || 0) * (total + ((fieldValue?.cost_production/100 || 0) * total) + ((fieldValue?.wasted/100 || 0) * total)) : "0",
// manufacture: totalAmount
//   ? totalAmount + ((fieldValue?.cost_production/100 || 0) * totalAmount) + ((fieldValue?.wasted/100 || 0) * totalAmount) + 
//     (fieldValue?.overhead_cost/100 || 0) * (totalAmount + ((fieldValue?.cost_production/100 || 0) * totalAmount) + ((fieldValue?.wasted/100 || 0) * totalAmount)) +
//     (fieldValue?.sales_tax/100 || 0) * (totalAmount + ((fieldValue?.cost_production/100 || 0) * totalAmount) + ((fieldValue?.wasted/100 || 0) * totalAmount)) +
//     (fieldValue?.other_change/100 || 0) * (totalAmount + ((fieldValue?.cost_production/100 || 0) * totalAmount) + ((fieldValue?.wasted/100 || 0) * totalAmount))
//   : total
//   ? total + ((fieldValue?.cost_production/100 || 0) * total) + ((fieldValue?.wasted/100 || 0) * total) + 
//     (fieldValue?.overhead_cost/100 || 0) * (total + ((fieldValue?.cost_production/100 || 0) * total) + ((fieldValue?.wasted/100 || 0) * total)) +
//     (fieldValue?.sales_tax/100 || 0) * (total + ((fieldValue?.cost_production/100 || 0) * total) + ((fieldValue?.wasted/100 || 0) * total)) +
//     (fieldValue?.other_change/100 || 0) * (total + ((fieldValue?.cost_production/100 || 0) * total) + ((fieldValue?.wasted/100 || 0) * total))
//   : "0"

// UPDATE
//   amount: total ? total : totalAmount,
//   cost_production: totalAmount 
//     ? (fieldValue?.cost_production/100 || 0) * totalAmount 
//     : total ? fieldValue?.cost_production/100 * total : "0",
//   wasted: totalAmount 
//     ? (fieldValue?.wasted/100 || 0) * totalAmount
//     : total ? fieldValue?.wasted/100 * total : "0",
//   conponent_at_cost: totalAmount
//     ? totalAmount + ((fieldValue?.cost_production/100 || 0) * totalAmount) + ((fieldValue?.wasted/100 || 0) * totalAmount)
//     : total ? total + ((fieldValue?.cost_production/100 || 0) * total) + ((fieldValue?.wasted/100 || 0) * total) : "0",
// overhead_cost: totalAmount 
// ? (fieldValue?.overhead_cost/100 || 0) * (totalAmount + ((fieldValue?.cost_production/100 || 0) * totalAmount) + ((fieldValue?.wasted/100 || 0) * totalAmount))
// : total ? (fieldValue?.overhead_cost/100 || 0) * (total + ((fieldValue?.cost_production/100 || 0) * total) + ((fieldValue?.wasted/100 || 0) * total)) : "0",
// sales_tax: totalAmount 
// ? (fieldValue?.sales_tax/100 || 0) * (totalAmount + ((fieldValue?.cost_production/100 || 0) * totalAmount) + ((fieldValue?.wasted/100 || 0) * totalAmount))
// : total ? (fieldValue?.sales_tax/100 || 0) * (total + ((fieldValue?.cost_production/100 || 0) * total) + ((fieldValue?.wasted/100 || 0) * total)) : "0",
// other_change: totalAmount
// ? (fieldValue?.other_change/100 || 0) * (totalAmount + ((fieldValue?.cost_production/100 || 0) * totalAmount) + ((fieldValue?.wasted/100 || 0) * totalAmount))
// : total ? (fieldValue?.other_change/100 || 0) * (total + ((fieldValue?.cost_production/100 || 0) * total) + ((fieldValue?.wasted/100 || 0) * total)) : "0",
// manufacture: totalAmount
// ? totalAmount + ((fieldValue?.cost_production/100 || 0) * totalAmount) + ((fieldValue?.wasted/100 || 0) * totalAmount) + 
//   (fieldValue?.overhead_cost/100 || 0) * (totalAmount + ((fieldValue?.cost_production/100 || 0) * totalAmount) + ((fieldValue?.wasted/100 || 0) * totalAmount)) +
//   (fieldValue?.sales_tax/100 || 0) * (totalAmount + ((fieldValue?.cost_production/100 || 0) * totalAmount) + ((fieldValue?.wasted/100 || 0) * totalAmount)) +
//   (fieldValue?.other_change/100 || 0) * (totalAmount + ((fieldValue?.cost_production/100 || 0) * totalAmount) + ((fieldValue?.wasted/100 || 0) * totalAmount))
// : total
// ? total + ((fieldValue?.cost_production/100 || 0) * total) + ((fieldValue?.wasted/100 || 0) * total) + 
//   (fieldValue?.overhead_cost/100 || 0) * (total + ((fieldValue?.cost_production/100 || 0) * total) + ((fieldValue?.wasted/100 || 0) * total)) +
//   (fieldValue?.sales_tax/100 || 0) * (total + ((fieldValue?.cost_production/100 || 0) * total) + ((fieldValue?.wasted/100 || 0) * total)) +
//   (fieldValue?.other_change/100 || 0) * (total + ((fieldValue?.cost_production/100 || 0) * total) + ((fieldValue?.wasted/100 || 0) * total))
// : "0"

// MANUFACTURE
// cost_production: totalAmount ? (props.fieldValue?.cost_production/100 || 0) * totalAmount : total ? props.fieldValue?.cost_production/100 * total : "0",
// wasted: totalAmount ? (props.fieldValue?.wasted/100 || 0) * totalAmount : total ? props.fieldValue?.wasted/100 * total : "0",
// conponent_at_cost: totalAmount ? totalAmount + ((props.fieldValue?.cost_production/100 || 0) * totalAmount) + ((props.fieldValue?.wasted/100 || 0) * totalAmount) : total ? total + ((props.fieldValue?.cost_production/100 || 0) * total) + ((props.fieldValue?.wasted/100 || 0) * total) : "0",