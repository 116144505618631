import { useFormContext } from "react-hook-form"
import type { FormType } from "../../types"

export function FormPicture() {
  const { control } = useFormContext<FormType>()

  return (
    <img 
      className="w-full mt-5 rounded-lg"
      src={control?._formValues?.value 
        ? control?._formValues?.value 
        : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2sRHjqz3RQ4yNTs7OKrn_JP0aRuLjMCHGPXoWUZriow&s"} 
      alt="thumbnail" 
    />
  )
}