// React
import { useEffect, useState } from "react"

// Types
import type { SelectOptionType, SuccessFetch } from "../../../../../../types"

// Utils
import { useApi } from "../../../../../../utils"

export function useCoa() {
  // Hooks
  const api = useApi()
  const [data, setData] = useState<SelectOptionType[]>([])
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    api.get("/taxcode/coadetail").then((res: SuccessFetch<{
      payload: {
        coa_id: number
        coa_code: string
        coa_name: string
      }[]
    }>) => {
      setData(res.data.payload.map(item => {
        return {
          label: `${item.coa_code} ${item.coa_name}`,
          value: item.coa_id.toString()
        }
      }))
    }).catch(() => {
      setData([])
    }).finally(() => {
      setLoading(false)
    })
  
    return () => {
      setData([])
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [])

  return { data, isLoading }
}