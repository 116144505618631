// React
import { useEffect, useState } from "react"

// Types
import type { SelectOptionType, SuccessFetch } from "types"

// Utils
import { useApi } from "utils"

export function useSupplier() {
  // Types
  interface Type extends SelectOptionType {
    payment_term_vendor_name: string
  }

  // Hooks
  const api = useApi()
  const [data, setData] = useState<Type[]>([])
  const [isLoading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    refetch()

    return () => {
      setData([])
      setLoading(false)
    }

    // eslint-disable-next-line
  }, [])

  const refetch = () => {
    setLoading(true)

    api.get("/vendor/").then((res: SuccessFetch<{
      payload: {
        payment_term_vendor_name: number
        vendor_id: number
        vendor_name: string
      }[]
    }>) => {
      setData(res.data.payload.map(item => {
        return {
          label: item.vendor_name,
          payment_term_vendor_name: item.payment_term_vendor_name?.toString() ?? "0",
          value: item.vendor_id.toString()
        }
      }))
    }).catch(() => {
      setData([])
    }).finally(() => {
      setLoading(false)
    })
  }

  return { data, isLoading, refetch }
}