import * as yup from "yup"

export type ReconcileList = yup.InferType<typeof reconcileListSchema>

export const reconcileListSchema = yup.object().shape({
  journal_id: yup.number().required(),
  journal_code: yup.string().required(),
  data_source: yup.number().required(),
  data_source_id: yup.number().required(),
  transaction_code: yup.string().required(),
  transaction_date: yup.string().required(),
  group_card: yup.number().required(),
  card_id: yup.number().required(),
  card_name: yup.string().required(),
  reference_no: yup.string().required(),
  memo: yup.string().required(),
  amount: yup.number().required(),
  position: yup.number().required(),
  rc: yup.boolean().optional()
})
