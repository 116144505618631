/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react"
import { SuccessFetch } from "types"
import { useApi } from "utils"
import { Customer } from "../../types"

export type Response = {
  payload: Customer[]
}

export function useDropdownCustomer() {
  const api = useApi()
  const [data, setData] = useState<Response["payload"]>([])
  const [isLoading, setLoading] = useState(false)

  const fetchData = () => {
    setLoading(true)

    api
      .get("/customer/")
      .then((res: SuccessFetch<Response>) => {
        const data = res.data.payload

        setData(data)
      })
      .catch(() => setData([]))
      .finally(() => setLoading(false))
  }

  const refetch = () => fetchData()

  useEffect(() => {
    fetchData()

    return () => {
      setData([])
      setLoading(false)
    }
  }, [])

  return {
    data,
    isLoading,
    dropdown: data?.map((item) => ({
      ...item,
      label: item.customer_name,
      value: item.customer_id.toString()
    })),
    refetch
  }
}
