import { useMemo } from "react"
import { totalAmountJournal } from "../utils/function"
import { convertNumber } from "utils"
import { BaseTable } from "components"

export type FooterAccountTableProps = {
  total: ReturnType<typeof totalAmountJournal>

}

export const FooterAccountTable = ({ total }: FooterAccountTableProps) => {
  const balance = useMemo(() => {
    return total.credit - total.debet
  }, [total.credit, total.debet])

  return (
    <BaseTable className="w-full min-w-[100px] md:max-w-[400px]">
      <tbody className="text-lg">
        <tr className="border-dotted border-b-2">
          <td className="w-[1px] whitespace-nowrap pr-3 pt-5 uppercase font-bold">Total Debit</td>
          <td className="pt-5">: {convertNumber(total.debet).intoCurrency}</td>
        </tr>
        <tr className="border-dotted border-b-2">
          <td className="w-[1px] whitespace-nowrap pr-3 pt-5 uppercase font-bold">Total Credit</td>
          <td className="pt-5">: {convertNumber(total.credit).intoCurrency}</td>
        </tr>
        <tr className="border-dotted border-b-2">
          <td className="w-[1px] whitespace-nowrap pr-3 pt-5 uppercase font-bold">Balance</td>
          <td className="pt-5">: <span className={`${balance !== 0 && "text-error"}`}>{convertNumber(balance).intoCurrency}</span></td>
        </tr>
      </tbody>
    </BaseTable>
  )
}