// Pages
import Find from "pages/FindSection"
import Register from "pages/Accounts/Register"
import ProfitAndLost from "pages/Accounts/Report/ProfitAndLoss"
import OpeningBalance from "pages/Accounts/Transaction/OpeningBalance"
import Journal from "pages/Finance/Journal"
import FinanceReport from "pages/Finance/Report"
import GeneralEntry from "pages/Finance/Transaction/GeneralEntry"

// Types
import type { RouteType } from "types"

export const accountsRoutes: RouteType[] = [
  {
    path: "/accounts/register",
    element: <Register />,
    permission: "AR"
  },
  {
    path: "/accounts/transaction/opening-balance",
    element: <OpeningBalance />,
    permission: "AT01"
  },
  {
    path: "/accounts/report/balance-sheet",
    element: <FinanceReport />,
    permission: "AP01"
  },
  {
    path: "/accounts/report/profit-loss",
    element: <ProfitAndLost />,
    permission: "AP02"
  },
  {
    path: "/accounts/journal",
    element: <Journal />,
    permission: "AJ"
  },
  {
    path: "/accounts/find",
    element: <Find />,
    permission: "AF"
  },
  {
    path: "/accounts/transaction/general-entry",
    element: <GeneralEntry />,
    permission: "AT02"
  },
  {
    path: "/accounts/transaction/chart-of-register",
    element: <div />,
    permission: "AT03"
  },
  {
    path: "/accounts/transaction/transaction-journal",
    element: <div />,
    permission: "AT04"
  },
  {
    path: "/accounts/transaction/task-record",
    element: <div />,
    permission: "AT05"
  },
  {
    path: "/accounts/transaction/dashboard",
    element: <div />,
    permission: "AT06"
  },
  {
    path: "/accounts/report/account-list-summary",
    element: <div />,
    permission: "AP03"
  },
  {
    path: "/accounts/report/account-list-detail",
    element: <div />,
    permission: "AP04"
  },
  {
    path: "/accounts/report/general-ledger-summary",
    element: <div />,
    permission: "AP05"
  },
  {
    path: "/accounts/report/general-ledger-detail",
    element: <div />,
    permission: "AP06"
  },
  {
    path: "/accounts/report/bugget-analist",
    element: <div />,
    permission: "AP07"
  },
  {
    path: "/accounts/report/general-journal-transactions",
    element: <div />,
    permission: "AP08"
  }
]