import { FormReconcileType, SubmitType } from "../../types"

export const submitMap = (data: FormReconcileType): SubmitType => {
  return {
    coa_id: data.coa_id,
    bank_statement_date: data.bank_statement_date,
    calculated_statement_balance: data.calculate_statement_balance,
    last_reconciliation_date: data.last_reconciliation_date,
    last_statement_balance: data.last_statement_balance,
    new_statement_balance: data.new_statement_balance,
    out_of_balance: data.out_of_balance,
    transaction_date: data.bank_statement_date,
    total_cleared_credit: data.total_cleared_credit,
    total_cleared_debet: data.total_cleared_debet,
    journal_ids:
      data.data?.filter((item) => item.rc).map((item) => item.journal_id) ?? []
  }
}
