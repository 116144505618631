/* eslint-disable react-hooks/exhaustive-deps */

// React
import { useEffect } from "react"

// Form
import { Controller, useFormContext, useWatch } from "react-hook-form"

// Utils
import { convertNumber } from "utils"
import { DetailFormType } from "../../../utils"

export function Total() {
  // Form
  const { control, setValue } = useFormContext<DetailFormType>()
  const discount = useWatch({
    control,
    name: "discount"
  })
  const price_measure = useWatch({
    control,
    name: "price_measure"
  })
  const qty_order = useWatch({
    control,
    name: "qty_order"
  })

  useEffect(() => {
    setValue("total", qty_order * price_measure * (100 - discount) / 100)
     // eslint-disable-next-line
  }, [discount, price_measure, qty_order])

  return (
    <Controller
      name="total"
      control={control}
      render={({ field }) => <div className="px-3 text-right">{convertNumber(field.value).intoCurrency}</div>}
    />
  )
}