import { Button } from "components"
import { TabRegisterPath } from "../../types"

type TabRegisterProps = {
  activeTab: string
  setActiveTab: (path: string) => void
}

type Tabs = {
  label: string
  path: TabRegisterPath
  permission: string
}

export function TabNavRegister({ activeTab, setActiveTab }: TabRegisterProps) {

  const tabs: Tabs[] = [
    { label: "By Date Range", path: "by-date-range", permission: "SR01" },
    { label: "By Card", path: "by-card", permission: "SR02" },
    { label: "By Location", path: "by-location", permission: "SR03" },
    { label: "Reference Id", path: "reference-id", permission: "SR04" },
    { label: "All Invoice", path: "all-invoice", permission: "SR05" },
    { label: "Aged Receivable", path: "aged-receivable", permission: "SR06" }, 
    { label: "Work Order", path: "work-order", permission: "SR07" },
  ]

  return (
    <section className="grow flex items-stretch overflow-x-auto">
      {tabs.map((tab) => (
        <Button
          key={tab.path}
          permission={tab?.permission}
          type="button"
          color="ghost"
          size="sm"
          className={`grow bg-[#3c3c3c] border-y-0 border-x border-white no-animation hover:bg-primary hover:text-black whitespace-nowrap uppercase ${tab.path === activeTab ? "bg-primary text-black" : "text-white"}`}
          onClick={() => setActiveTab(tab.path)}
        >
          {tab.label}
        </Button>
      ))}
    </section>
  )
}
