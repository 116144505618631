// React
import { useEffect, useState } from "react"

// Types
import type { SuccessFetch } from "types"
import type { PoType } from "../../types"

// Utils
import { useApi } from "utils"

export function useDepositDetail(deposit_vendor_id: string): {
  data: PoType | null
  isLoading: boolean
} {
  // Hooks
  const api = useApi()
  const [data, setData] = useState<PoType | null>(null)
  const [isLoading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    api.get("/depositvendor/getdeposit_by_id", {
      params: { deposit_vendor_id }
    }).then((res: SuccessFetch<{
      payload: {
        amount: number
        approve_status: number
        coa_id: number
        memo: string
        no_cheque: string
        purchase_order_id: number
        trx_code: string
        transaction_date: string
      }
    }>) => {
      // Vars
      const payload = res.data.payload

      api.get("/purchaseorder/detailpo", {
        params: { po_id: payload.purchase_order_id }
      }).then((res: SuccessFetch<{
        payload: {
          datapo: {
            address: string
            location_id: number
            trx_code: string
            vendor_id: number
            vendor_name: string
          }
        }
      }>) => {
        // Vars
        const result = res.data.payload
  
        setData({
          amount: payload.amount,
          approve_status: payload.approve_status,
          coa_id: payload.coa_id.toString(),
          memo: payload.memo,
          no_cheque: payload.no_cheque,
          transaction_date: payload.transaction_date,
          trx_code: payload.trx_code,
          purchase_order_id: payload.purchase_order_id,
          datapo: {
            ...result.datapo,
            location_id: result.datapo.location_id.toString(),
            vendor_id: result.datapo.vendor_id.toString()
          }
        })
      }).catch(() => {
        setData(null)
      }).finally(() => {
        setLoading(false)
    }).catch(() => {
      setData(null)
      setLoading(false)
    })
    })

    return () => {
      setData(null)
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [])

  return { data, isLoading }
}