import { useFormContext, useWatch } from "react-hook-form"
import { FormSalesOrderType } from "../types"
import { calculateSummarySalesOrder } from "../utils/functions"
import { BaseTable } from "components"

export const SummaryFooter = () => {
  const { control } = useFormContext<FormSalesOrderType>()
  const [freight, sodetail] = useWatch({ control, name: ['freight', 'sodetail'] })
  const summary = calculateSummarySalesOrder({ sodetail, freight })

  return (
    <BaseTable className="w-full min-w-[100px] md:max-w-[400px]">
      <tbody className="text-lg  text-end">
        <tr className="border-dotted border-b-2">
          <td className="w-[1px] whitespace-nowrap pr-3 pt-5 uppercase font-bold">
            SUBTOTAL
          </td>
          <td className="pt-5">{summary?.subtotal?.toLocaleString()}</td>
        </tr>
        <tr className="border-dotted border-b-2">
          <td className="w-[1px] whitespace-nowrap pr-3 pt-5 uppercase font-bold">
            FREIGHT
          </td>
          <td className="pt-5"> {summary?.freight?.toLocaleString()}</td>
        </tr>
        <tr className="border-dotted border-b-2">
          <td className="w-[1px] whitespace-nowrap pr-3 pt-5 uppercase font-bold">
            TAX
          </td>
          <td className="pt-5"> {summary?.tax.toLocaleString()}</td>
        </tr>
        <tr className="border-dotted border-b-2">
          <td className="w-[1px] whitespace-nowrap pr-3 pt-5 uppercase font-bold">
            TOTAL AMOUNT
          </td>
          <td className="pt-5"> {summary?.totalAmount?.toLocaleString()}</td>
        </tr>
        <tr className="border-dotted border-b-2">
          <td className="w-[1px] whitespace-nowrap pr-3 pt-5 uppercase font-bold">
            PAYMENT
          </td>
          <td className="pt-5 text-error"> {summary.payment?.toLocaleString()}</td>
        </tr>
        <tr className="border-dotted border-b-2">
          <td className="w-[1px] whitespace-nowrap pr-3 pt-5 uppercase font-bold text-xl">
            BALANCE
          </td>
          <td className="pt-5 text-xl"> {summary?.balance.toLocaleString()}</td>
        </tr>
      </tbody>
    </BaseTable>
  )
}
