// Third-Party Libraries
import toast from "react-hot-toast"

// Utils
import { DetailFormType } from "../vars"

export const duplicateChecker = (
  value: DetailFormType,
  detail: DetailFormType[],
  bill_is_approved: boolean
): DetailFormType | null => {
  // Vars
  const isDuplicate = detail.find(item => item.product_id === value.product_id)

  if (isDuplicate) {
    toast.error("Purchase Order Detail is already on Bill Detail List")
    return null
  } else if (bill_is_approved) {
    toast.error("Bill is already Approved")
    return null
  }

  return value
}