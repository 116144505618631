import { useState } from "react"
import type { SuccessFetch } from "types"
import { useApi } from "utils"
import { DataList } from "../../types"

export function useList() {
  const api = useApi()
  const [data, setData] = useState<DataList[]>([])
  const [isLoading, setLoading] = useState(false)

  const refetch = ( search?: string, detail?: string, from?: string, to?: string, sort?: string ) => {
    setLoading(true)

    api.get("/inventoryjournal/", { params: { by: search, refID: detail, start_date: from, end_date: to, sort: sort } }) 
    .then((res: SuccessFetch<{ payload: DataList[] }>) => setData(res.data.payload))
    .catch(() => setData([]) )
    .finally(() => setLoading(false))
  
    return () => {
      setData([])
      setLoading(true)
    }
  }

  return { data, isLoading, refetch }
}