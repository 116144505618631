import { yupResolver } from "@hookform/resolvers/yup"
import { Select } from "components"
import { useProductGroup } from "pages/Inventori/Transaction/AddNewItem/components/FormSection/utils"
import { BuyingList, ComponentList } from "pages/Inventori/Transaction/AddNewItem/types"
import { Controller, FormProvider, useForm, useFormContext } from "react-hook-form"
import * as yup from "yup"

interface DataFormProps { action?: "CREATE" | "UPDATE" | "DETAIL" }

export const DataForm = ({ action }: DataFormProps) => {
    const { control } = useFormContext<ComponentList>()
    const { dataProduct, isLoadingProduct } = useProductGroup()
  
    type CreatePersonalData = Pick<BuyingList, "vendor_id" | "vendor_description" | "buying_price" | "tax_id" | "datavalue">;
  
    const defaultValues: CreatePersonalData = {
      vendor_id: "",
      vendor_description: "",
      buying_price: "",
      tax_id: "",
      datavalue: []
    }
    const validationSchema = yup.object().shape({
      vendor_id: yup.string().label("Vendor").optional(),
      vendor_description: yup.string().label("Description").optional(),
      buying_price: yup.string().label("buying price").optional(),
      tax_id: yup.string().label("Tax").optional(),
      datavalue: yup.array().label("Tax").defined(),
    })
    const methods = useForm<CreatePersonalData>({
      defaultValues,
      resolver: yupResolver(validationSchema)
    })
  
    return (
      <FormProvider {...methods}>
        <Controller
          control={control}
          name="mr_product_group_id"
          render={({ field, fieldState }) => {
            return(
            <Select
              label="BUILD COMPONENT"
              placeholder="Select build component"
              controlClass="w-[500px]"
              isDisabled={action === "DETAIL"}
              isLoading={isLoadingProduct}
              error={fieldState.error?.message}
              options={dataProduct}
              value={dataProduct.find(item => item.value === field.value?.toString())}
              onChange={e => field.onChange(e?.value)}
            />
          )}}
        />
      </FormProvider>
    )
  }