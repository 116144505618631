// React
import { Fragment } from "react"

// Components
import { ApproveAction } from "./ApproveAction"

import { TableNumber } from "components"

// Third-Party Libraries
import moment from "moment"

import { APPROVAL } from "utils"

// context
import { PaginationState } from "types"

type TypeParams = {
    data: any
    index: number
    refetch: () => void
    pagination: PaginationState
}


export function Item({
    data,
    index,
    refetch,
    pagination, 
}: TypeParams) {

    return (
        <Fragment>
            <tr key={index}>
                <td>{TableNumber({ dataLength:pagination.limit , pageCurrent: pagination.page, index: index, limit: pagination.limit, totalData: pagination.total })}</td>
                <td>
                    <ApproveAction data={data} refetch={refetch} />
                </td>
                <td>{data.trx_code}</td>
                <td>{moment(data.transaction_date).format("DD/MM/YYYY")}</td>
                <td>{data.location_from.location_name}</td>
                <td>{data.location_to.location_name}</td>
                <td>{data.memo}</td>
                <td>{APPROVAL[data.approval_status]}</td>
                <td>{data.approve_date ? moment(data.approve_date).format("DD/MM/YYYY") : ""}</td>
            </tr>
        </Fragment>
    )
}