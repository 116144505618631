// Components
import { Loading } from "components"
import { FormSection } from "../components"

// Contexts
import { ListJournalProvider } from "../contexts"

// Form
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

// Third-Party Libraries
import toast from "react-hot-toast"
import { useParams, useNavigate } from "react-router"

// Utils
import { useApi, usePermission } from "utils"
import { type FormEditType, getAcc, getFinalValue, useDetail, validationSchemaEdit } from "../utils"
import { formParams } from "./utils"

export default function EditSpendMoney() {
  // Hooks
  const { data_source, data_source_id } = useParams()
  const { data, isLoading } = useDetail(data_source!, data_source_id!)

  if (isLoading || !data) {
    return <Loading loading={isLoading} errorText="No Data Available" />
  }

  return <DataSection data={data} />
}

function DataSection(params: { data: FormEditType }) {
  // Hooks
  const api = useApi()
  const navigate = useNavigate()
  const permission = usePermission(["FT01"])

  // Form  
  const methods = useForm<FormEditType>({
    defaultValues: params.data,
    resolver: yupResolver(validationSchemaEdit)
  })
  const onSubmit = (value: FormEditType) => {
    // Hooks
    const { sub_total, tax } = getAcc(value.datajournal)

    return new Promise<void>((resolve) => {
      if (Math.abs(value.amount - (sub_total + tax)) !== 0) {
        toast.error("Balance must be 0")
        return resolve()
      }

      toast.promise(
        api.put("/spendmoney/update", {
          financeid: value.financeid,
          journal_code: value.journal_code,
          ...getFinalValue(value, "spend-money")
        }),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.detail?.message ?? err.response.data.message
        }
      ).then(() => {
        navigate("/finance/journal")
      }).catch(() => {}).finally(resolve)
    })
  }

  return (
    <FormProvider {...methods}>
      <ListJournalProvider
        is_enabled
        data_source={formParams.dataSource}
        date={params.data.transaction_date}
        journal_code={params.data.journal_code}
      >
        <FormSection
          {...formParams}
          isEdit
          onSubmit={methods.handleSubmit(onSubmit)}
          permission={permission}
        />
      </ListJournalProvider>
    </FormProvider>
  )
}