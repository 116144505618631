import { 
    BaseTable, 
    Card, 
    CommandButton, 
    HeaderMenu, 
    Label, 
    // Loading, 
    // PaginationData, 
    Select 
} from "components";
// import { useApprovalList, defaultValue } from "./utils";
// import { useState } from "react";
// import { FilterState } from "./types";
// import { Item } from "./components";
import { APPROVAL_OPTIONS } from "utils";


export default function ApproveAdjustment() {
    // const [pagination, setPagination] = useState<FilterState>(defaultValue)
    // const { data, isLoading, refetch } = useApprovalList(pagination)

    return (
        <section className="container my-2">
            <section>
                <HeaderMenu title="APPROVAL ADJUSTMENT" />

                <section>
                    <section className="mt-2">
                        <Card>
                            <Card.Body>
                                <div className="justify-end lg:flex items-center">
                                    <Label className="mr-2">APPROVAL</Label>
                                    <Select
                                        className="lg:w-[200px]"
                                        placeholder="Select Approval"
                                        options={APPROVAL_OPTIONS}
                                        // value={APPROVAL_OPTIONS.find(item => item.value === pagination.approval_status)}
                                        // onChange={e => setPagination({ ...pagination, approval_status: e?.value })}
                                    />
                                </div>
                            </Card.Body>
                        </Card>
                    </section>
                </section>

                <section className="mt-2">
                    <Card>
                        <Card.Body>
                            <div className="overflow-x-auto">
                                <BaseTable>
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th colSpan={2}>ADJUSTMENT INVENTORY ID#</th>
                                            <th>DATE</th>
                                            <th>LOCATION</th>
                                            <th>TRADER</th>
                                            <th>MEMO</th>
                                            <th>STATUS</th>
                                            <th>DATE APPROVE</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* {isLoading || data?.payload?.length === 0 ? (
                                            <tr>
                                                <td colSpan={12} className="text-center">
                                                    <Loading loading={isLoading} errorText={isLoading ? "" : "No data available"} />
                                                </td>
                                            </tr>
                                        ) :
                                            data?.payload.map((item, key: number) => (
                                                <Item
                                                    data={item}
                                                    index={key}
                                                    refetch={refetch}
                                                    pagination={pagination}
                                                />
                                            ))} */}
                                    </tbody>
                                </BaseTable>
                            </div>
                            <div className="mt-5">
                                {/* <PaginationData data={data} pagination={pagination} setPagination={setPagination} /> */}
                            </div>
                        </Card.Body>
                    </Card>
                </section>

                <section className="mt-2">
                    <Card>
                        <Card.Body className="flex flex-col gap-5">
                            <section className="flex flex-wrap justify-between items-center gap-3">
                                <section className="flex flex-wrap gap-3">
                                    <CommandButton actiontype="help" />
                                    <CommandButton actiontype="print" />
                                    <CommandButton actiontype="email" />
                                    <CommandButton actiontype="export" />
                                </section>
                            </section>
                        </Card.Body>
                    </Card>
                </section>
            </section>
        </section>
    )
}