import { FormReturnItemsType, SubmitType } from "../../types"

export const submitMap = (data: FormReturnItemsType): SubmitType => {
  return {
    return_id: data.return_id,
    invoice_id: data.invoice_id ?? NaN,
    trx_code: data.trx_code ?? "",
    customer_id: data.customer_id,
    location_id: data.location_id,
    address: data.shipped,
    memo: data.memo,
    transaction_date: data.transaction_date,
    referensi: data.referensi,
    return_detail:
      data?.return_detail?.map((item) => {
        const totalTax = (item.total * (item.tax_rate ?? 0)) / 100
        const totalTaxReturn = item.total + totalTax

        return {
          return_detail_id: item.return_detail_id,
          quantity_return: item.quantity_returned,
          quantity_unit: item.mr_unit_id,
          product_id: item.product_id,
          price_return: item.price_invoice,
          discount_return: item.discount,
          total_return: item.total,
          tax_id: item.tax_id,
          tax_rate: item.tax_rate ?? 0,
          total_tax_return: totalTaxReturn
        }
      }) ?? []
  }
}
