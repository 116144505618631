import { Input, NumberInput } from "components"
import { SellingList } from "pages/Inventori/Transaction/AddNewItem/types"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { DisableField } from "./DisableField"
import { useEffect } from "react"
import { convertNumber } from "utils"

interface SalesTaxProps {
    action?: "CREATE" | "UPDATE" | "DETAIL"
    totalValueProduction: number 
}
export const SalesTax = ({ action, totalValueProduction }: SalesTaxProps) => {
    const { control, setValue } = useFormContext<SellingList>()
    
    const overhead = useWatch({control,name: "overhead"})
    const salesTax = useWatch({control, name: "sales_tax"})
    const otherCahnges = useWatch({control, name: "other_changes"})
    const buyingPrice = useWatch({ control, name: 'price_unit_convention' })
    const componentAtCost = useWatch({ control, name: 'component_at_cost' })

    const valueOverhead =  (overhead) ? totalValueProduction * parseFloat(String(overhead)) / 100 : 0
    const valueSalesTax = (salesTax) ? totalValueProduction * parseFloat(String(salesTax)) / 100 : 0
    const valueOtherChanges = (otherCahnges) ? totalValueProduction * parseFloat(String(otherCahnges)) / 100 : 0
    const cogm = (valueOverhead ?? 0) + (valueSalesTax ?? 0) + (valueOtherChanges ?? 0) + totalValueProduction

    const cogmClass = "w-[110px] !rounded-l-none"

    useEffect(() => {
        setValue('cogm', Math.floor(parseFloat(String(cogm))) ?? "0")
        // eslint-disable-next-line
    }, [cogm])
    
    return (
        <div>
            {/* OVERHEAD COST */}
            <div className="flex justify-between items-center mb-2">
                <div><b>OVERHEAD COST (%)</b></div>
                <div className="join">
                    <Controller
                        control={control}
                        name="overhead"
                        render={({ field }) =>
                            <NumberInput
                                decimalScale={0}
                                value={field.value}
                                inputClass="w-[110px] !rounded-r-none"
                                onChange={(e) => field.onChange(e.target.value)}
                                disabled={action === "DETAIL"}
                            />
                        }
                    />
                    {action === "DETAIL" 
                        ? <Input disabled inputClass={cogmClass} value={convertNumber(valueOverhead)?.intoCurrency ?? 0} />
                        : <DisableField width="110px" className="!rounded-l-none">{convertNumber(valueOverhead ? valueOverhead : 0)?.intoCurrency ?? 0}</DisableField>
                    }
                </div>
            </div>
            {/* SALES TAX */}
            <div className="flex justify-between items-center item mb-2">
                <div><b>SALES TAX (%)</b></div>
                <div className="join">
                    <Controller
                        control={control}
                        name="sales_tax"
                        render={({ field }) =>
                            <NumberInput
                                decimalScale={0}
                                value={field.value}
                                inputClass="w-[110px] !rounded-r-none"
                                onChange={(e) => field.onChange(e.target.value)}
                                disabled={action === "DETAIL"}
                            />
                        }
                    />
                    {action === "DETAIL" 
                        ? <Input disabled inputClass={cogmClass} value={convertNumber(valueSalesTax)?.intoCurrency ?? 0} />
                        : <DisableField width="110px" className="!rounded-l-none">{convertNumber(valueSalesTax ? valueSalesTax : 0)?.intoCurrency ?? 0}</DisableField>
                    }
                </div>
            </div>
            {/* OTHER CHANGES */}
            <div className="flex justify-between items-center item mb-2">
                <div><b>OTHER CHANGES (%)</b></div>
                <div className="join">
                    <Controller
                        control={control}
                        name="other_changes"
                        render={({ field }) =>
                            <NumberInput
                                decimalScale={0}
                                value={field.value}
                                inputClass="w-[110px] !rounded-r-none"
                                onChange={(e) => {
                                    field.onChange(e.target.value)
                                    setValue('product_price_unit_id', buyingPrice ?? "0")
                                    setValue('product_component_cost_id', componentAtCost ?? "0")
                                }}
                                disabled={action === "DETAIL"}
                            />
                        }
                    />
                    {action === "DETAIL" 
                        ? <Input disabled inputClass={cogmClass} value={convertNumber(valueOtherChanges)?.intoCurrency ?? 0} />
                        : <DisableField width="110px" className="!rounded-l-none">{convertNumber(valueOtherChanges ? valueOtherChanges : 0)?.intoCurrency ?? 0}</DisableField>
                    }
                </div>
            </div>
        </div>
    )
}