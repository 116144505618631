// React
import { Fragment, useEffect } from "react"

// Components
import { Checkbox, Input, Select } from "../../../../../components"

// Form
import { Controller, useFormContext, useWatch } from "react-hook-form"

// Types
import type { SelectOptionType } from "../../../../../types"
import type { FormType } from "../../types"

// Utils
import { normal_post_list, useAccountGroup, useAccountType, useCoaCode } from "./utils"

export function FormSection(props: {
  isDetail?: boolean
  isUpdate?: boolean
  parent_id?: number
}) {
  // Vars
  const header_level: SelectOptionType[] = [
    {
      label: "Header",
      value: "1"
    },
    {
      label: "Sub Header",
      value: "2"
    },
    {
      label: "Detail",
      value: "3"
    }
  ]

  // Form
  const { control, getValues, setValue } = useFormContext<FormType>()

  return (
    <section className="flex flex-col gap-3">
      <section className="grid md:grid-cols-2 gap-3">
        <AccountGroup isDisabled={Boolean(props.isUpdate || props.isDetail)} />
        
        <Controller
          control={control}
          name="report_type"
          render={({ field }) => <Input disabled label="REPORT TYPE" value={field.value ?? ""} />}
        />

        <AccountType isDisabled={getValues("coa_level") === "1" || Boolean(props.isUpdate || props.isDetail)} />
        <Input disabled label="PARENT" value={getValues("parent_name")} />
        <Input disabled label="COA LEVEL" value={getValues("coa_level")} />

        <Controller
          control={control}
          name="header_level"
          render={({ field, fieldState }) => {
            // Vars
            const list = header_level.filter(item => {
              if (parseInt(getValues("coa_level")) > 1) {
                if (parseInt(getValues("coa_level")) === 2) {
                  return parseInt(item.value) === 2
                }

                return parseInt(item.value) > 1
              }

              return true
            })

            return (
              <Select
                label="HEADER LEVEL"
                placeholder="Choose Header Level"
                isDisabled={getValues("coa_level") === "1" || props.isDetail}
                error={fieldState.error?.message}
                options={list}
                value={list.find(item => item.value === field.value)}
                onChange={e => {
                  setValue("cheque_coa", false)
                  setValue("opening_balance", 0)
                  field.onChange(e?.value)
                }}
              />
            )
          }}
        />

        <CoaCode isDisabled={Boolean(props.isDetail)} isUpdate={props.isUpdate} parent_id={props.parent_id} />

        <Controller
          control={control}
          name="coa_name"
          render={({ field, fieldState }) => (
            <Input
              {...field}
              ref={null}
              label="ACCOUNT NAME"
              disabled={props.isDetail}
              error={fieldState.error?.message}
              onChange={e => field.onChange(e.target.value.toUpperCase())}
            />
          )}
        />

        <Controller
          control={control}
          name="note"
          render={({ field, fieldState }) => (
            <Input
              {...field}
              ref={null}
              label="NOTE"
              disabled={props.isDetail}
              error={fieldState.error?.message}
              value={field.value ?? ""}
            />
          )}
        />
      </section>

      <CheckboxSection isDisabled={Boolean(props.isDetail)} />
    </section>
  )
}

function AccountGroup(props: { isDisabled: boolean }) {
  // Form
  const { control, getValues, setValue } = useFormContext<FormType>()

  // Hooks
  const { data, isLoading } = useAccountGroup()

  return (
    <Controller
      control={control}
      name="group_coa_id"
      render={({ field, fieldState }) => (
        <Select
          label="ACCOUNT GROUP"
          placeholder="Choose Account Group"
          isDisabled={getValues("coa_level") !== "1" || props.isDisabled}
          isLoading={isLoading}
          error={fieldState.error?.message}
          options={data}
          value={data.find(item => item.value === field.value)}
          onChange={e => {
            // @ts-ignore
            setValue("group_coa_code", e?.group_coa_code)
            // @ts-ignore
            setValue("report_type", e?.report_type)
            setValue("account_type_id", "")
            setValue("cheque_coa", false)

            if (e) {
              setValue("normal_pos", normal_post_list[e.value as any])
            }

            field.onChange(e?.value)
          }}
        />
      )}
    />
  )
}

function AccountType(props: { isDisabled: boolean }) {
  // Form
  const { control } = useFormContext<FormType>()
  const group_coa_id = useWatch({
    control,
    name: "group_coa_id"
  })
  const coa_level = useWatch({
    control,
    name: "coa_level"
  })

  // Hooks
  const { data, isLoading, refetch } = useAccountType()

  useEffect(() => {
    refetch(group_coa_id)

    // eslint-disable-next-line
  }, [group_coa_id])

  return (
    <Controller
      control={control}
      name="account_type_id"
      render={({ field, fieldState }) => (
        <Select
          label="ACCOUNT TYPE"
          placeholder="Choose Account Type"
          isDisabled={props.isDisabled || coa_level !== "2"}
          isLoading={isLoading}
          error={fieldState.error?.message}
          options={data}
          value={data.find(item => item.value === field.value) ?? null}
          onChange={e => field.onChange(e?.value)}
        />
      )}
    />
  )
}

function CheckboxSection(props: { isDisabled: boolean }) {
  // Form
  const { control } = useFormContext<FormType>()
  const group_coa_id = useWatch({
    control,
    name: "group_coa_id"
  })

  if (group_coa_id === "1") {
    return (
      <Fragment>
        <Controller
          control={control}
          name="cheque_coa"
          render={({ field, fieldState }) => (
            <Checkbox
              {...field}
              ref={null}
              disabled={props.isDisabled}
              error={fieldState.error?.message}
              onChange={() => field.onChange(!field.value)}
            >
              CHEQUE ACCOUNT
            </Checkbox>
          )}
        />
      </Fragment>
    )
  } else {
    return <div />
  }
}

function CoaCode(props: {
  isDisabled: boolean
  isUpdate?: boolean
  parent_id?: number
}) {
  // Form
  const { control, setValue } = useFormContext<FormType>()
  const group_coa_id = useWatch({
    control,
    name: "group_coa_id"
  })

  // Hooks
  const { generate } = useCoaCode(props.parent_id)

  useEffect(() => {
    if (!props.isDisabled && !props.isUpdate && group_coa_id) {
      generate(group_coa_id).then(res => setValue("coa_code", res))
    }

    // eslint-disable-next-line
  }, [group_coa_id])

  return (
    <Controller
      control={control}
      name="coa_code"
      render={({ field, fieldState }) => (
        <section>
          <div className="label">
            <span className={`label-text font-bold ${fieldState.error && "text-error"}`}>ACCOUNT NUMBER</span>
          </div>
          
          <section className="flex gap-1">
            <section className="w-16">
              <Controller
                control={control}
                name="group_coa_code"
                render={({ field }) => <Input disabled value={field.value} />}
              />
            </section>

            <section className="grow min-w-[0px] w-[1px]">
              <Input {...field} ref={null} disabled={props.isDisabled} />
            </section>
          </section>

          {fieldState.error && (
            <label className="label">
              <span className="label-text-alt font-bold text-error">{fieldState.error.message}</span>
            </label>
          )}
        </section>
      )}
    />
  )
}