// React
import { useContext } from "react"

// Components
import { ActionButton, NumberInput } from "components"

// Contexts
import { DataContext } from "pages/Purchase/Transaction/EnterBill/contexts"

// Form
import { Controller, useForm, useFormContext, useWatch } from "react-hook-form"

// Third-Party Libraries
import toast from "react-hot-toast"

// Types
import type { ErrorFetch } from "types"
import type { ProductType } from "pages/Purchase/Transaction/PurchaseOrder/components/DetailBody/types"
import type { DetailBillType } from "../types"
import type { FormType } from "../utils"

// Utils
import { convertNumber, useApi } from "utils"

export function FormSection(params: {
  index: number
  project_name?: string
  selectedData?: ProductType
  tax_name?: string
  toggle: () => void
  update: (value: FormType) => void
}): JSX.Element {
  // Hooks
  const api = useApi()
  const data = useContext(DataContext)
  
  // Form
  const { getValues } = useFormContext<DetailBillType>()

  // Vars
  const item = getValues(`detailbill.${params.index}`)
  const selectedData = params.selectedData
  const toggle = params.toggle

  // Form
  const defaultValues: FormType = {
    qty_order: item.qty_order,
    qty_received: item.delivered
  }
  const { control, formState, handleSubmit } = useForm<FormType>({
    defaultValues
  })
  const onSubmit = (value: FormType): Promise<void> => {
    // Vars
    const finalValues = {
      ...item,
      ...value,
      location_id: data.datapo.location_id
    }

    return new Promise<void>((resolve) => {
      api.put(
        `/purchaseorder/editdetailpo?purchase_order_detail_id=${item.purchase_order_detail_id}`,
        finalValues
      ).then(() => {
        params.update(value)
      }).catch((err: ErrorFetch<{
        message: string
      }>) => {
        toast.error(err.response.data.message)
      }).finally(() => resolve())
    })
  }
  const qty_order = useWatch({
    control,
    name: "qty_order"
  })

  return (
    <tr>
      <td />
      <td>
        <Controller
          name="qty_order"
          control={control}
          render={({ field, fieldState }) => (
            <NumberInput
              error={fieldState.error?.message}
              value={field.value}
              ref={field.ref}
              onValueChange={(e) => field.onChange(e.floatValue)}
            />
          )}
        />
      </td>
      <td>{item.delivered}</td>
      <td>{(qty_order - item.delivered) || 0}</td>
      <td>{selectedData?.product_barcode}</td>
      <td>{selectedData?.product_name}</td>
      <td className="text-right">{convertNumber(item.price_measure).intoCurrency}</td>
      <td>{convertNumber(item.discount).intoNormalAmount}%</td>
      <td className="text-right">{convertNumber(item.total).intoCurrency}</td>
      <td>{item.project_name}</td>
      <td>{params.tax_name}</td>
      <td>
        <section className="flex justify-center">
          <ActionButton.Check
            loading={formState.isSubmitting ? "true" : undefined}
            onClick={handleSubmit(onSubmit)}
          />

          <ActionButton.Close
            color="error"
            onClick={toggle}
          />
        </section>
      </td>
    </tr>
  )
}