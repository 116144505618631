import { ReconcileList } from "../../types"

export const calculateForm = (
  data: ReconcileList[] | undefined,
  new_statement_balance: number,
  last_statement_balance: number
) => {
  console.log(data, new_statement_balance, last_statement_balance)

  if (!data)
    return {
      debit: 0,
      credit: 0,
      calculateStatementBalance: 0,
      outOfBalance: 0
    }

  const { debit, credit } = data?.reduce(
    (acc, item) => {
      if (item.rc && item.position === 1) {
        acc.debit += item.amount
      }

      if (item.rc && item.position === 2) {
        acc.credit += item.amount
      }

      return acc
    },
    { debit: 0, credit: 0 }
  )

  const calculateStatementBalance = debit - credit

  const outOfBalance =
    last_statement_balance - new_statement_balance + calculateStatementBalance

  return {
    debit,
    credit,
    calculateStatementBalance,
    outOfBalance
  }
}
