export function Loading(params: {
  loading?: boolean
  errorText?: string
}) {
  return (
    <section className="text-center">
      {params.loading ? (
        <span className="loading" />
      ) : (
        <div>{params.errorText ?? "Data error"}</div>
      )}
    </section>
  )
}