// React
import { useContext } from "react"

// Components
import { Checkbox } from "components"

// Contexts
import { FilterContext } from "../contexts"

export function CheckboxSection() {
  // Hooks
  const { account_number, sub_header, zero_value } = useContext(FilterContext)

  return (
    <section className="mb-3 w-fit flex md:flex-row flex-col gap-3">
      <Checkbox value={sub_header.isActive} onChange={sub_header.toggle}>
        <div className="whitespace-nowrap">
          Sub Header
        </div>
      </Checkbox>

      <Checkbox value={zero_value.isActive} onChange={zero_value.toggle}>
        <div className="whitespace-nowrap">
          Zero Value
        </div>
      </Checkbox>

      <Checkbox value={account_number.isActive} onChange={account_number.toggle}>
        <div className="whitespace-nowrap">
          Account Number
        </div>
      </Checkbox>
    </section>
  )
}