import { useEffect, useState } from "react"
import type { SelectOptionType, SuccessFetch } from "../../../../../../../../types"
import { useApi } from "../../../../../../../../utils"

export function useProductMaterial() {
  const api = useApi()
  const [dataMaterial, setData] = useState<SelectOptionType[]>([])
  const [isLoadingMaterial, setLoading] = useState(true)

  const refetchMaterial = () => {
    setLoading(true)

    api.get("/productmaterial/")
    .then((res: SuccessFetch<{
      payload: {
        mr_product_material_id: number
        mr_product_material_name: string
      }[]
    }>) => { setData(res.data.payload.map(item => { return { label: item.mr_product_material_name, value: item.mr_product_material_id.toString() }}))})
    .catch(() => setData([]))
    .finally(() => setLoading(false))
  }

  useEffect(() => {
    api.get("/productmaterial/")
    .then((res: SuccessFetch<{
      payload: {
        mr_product_material_id: number
        mr_product_material_name: string
      }[]
    }>) => { setData(res.data.payload.map(item => { return { label: item.mr_product_material_name, value: item.mr_product_material_id.toString() }}))})
    .catch(() => setData([]))
    .finally(() => setLoading(false))
    // eslint-disable-next-line
  }, [])

  return { dataMaterial, isLoadingMaterial, refetchMaterial }
}