// React
import { useEffect, useState } from "react"

// Third-Party Libraries
import moment from "moment"

// Types
import type { SuccessFetch } from "types"
import type { ItemType } from "../vars"

// Utils
import { useApi } from "utils"

export function useList() {
  // Hooks
  const api = useApi()
  const [data, setData] = useState<ItemType[]>([])
  const [date, setDate] = useState(moment().startOf("year").format("YYYY-MM-DD"))
  const [isEdit, setEdit] = useState(false)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    api.get("/openbalance/").then((res: SuccessFetch<{
      balance_date: string | null
      isedit: boolean
      payload: {
        coacode: string
        coa_id: number
        coa_name: string
        group_coa_id: number
        header_level: 1 | 2 | 3
        linked_code: string
        normal_pos: number
        opening_balance?: number
        parent_id: number
      }[]
    }>) => {
      // Vars
      const balance_date = res.data.balance_date

      if (balance_date) {
        setDate(moment(balance_date).format("YYYY-MM-DD"))
      }

      setEdit(res.data.isedit)
      setData(res.data.payload.map(item => {
        // Vars
        const opening_balance = item.opening_balance ?? 0

        return {
          ...item,
          opening_balance,
          old_opening_balance: item.opening_balance ?? 0
        }
      }))
    }).catch(() => {
      setData([])
    }).finally(() => {
      setLoading(false)
    })
  
    return () => {
      setData([])
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [])

  return { data, date, isEdit, isLoading, setData, setDate, setEdit }
}