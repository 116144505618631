import { Button, Card, DateInput, Input, Textarea } from "components"
import moment from "moment"
import { APPROVAL } from "pages/Sales/Register/utils/vars"
import { DropdownEmployee, DropdownLocation, FormReminder } from "pages/Sales/Transaction/CustomerOrder/components"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { TbPlus } from "react-icons/tb"
import { useToggle } from "utils"
import { FormInvoiceType } from "../types/FormInvoice"

type FormInvoiceProps = {
  approvalView?: boolean
}

export const FormInvoice = ({ approvalView }: FormInvoiceProps) => {
  const modalReminder = useToggle(false)
  const { control } = useFormContext<FormInvoiceType>()
  const approval_status = useWatch({
    control: control,
    name: "approval_status"
  })

  return (
    <Card>
      <Card.Body className="grid lg:grid-cols-2 gap-x-6 gap-y-3">
        <section className="flex flex-col gap-3">
          <section>
            <section className="flex gap-3">
              <div className="grow">
                <Controller
                  control={control}
                  name="shipped"
                  render={({ field, fieldState }) => (
                    <Textarea
                      {...field}
                      label="SHIPPED"
                      disabled={approval_status !== APPROVAL.PENDING || approvalView}
                      defaultValue={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                      error={fieldState.error?.message}
                    />
                  )}
                />
              </div>
            </section>
          </section>

          <Controller
            control={control}
            name="memo"
            render={({ field, fieldState }) => (
              <Textarea
                {...field}
                label="MEMO"
                defaultValue={field.value}
                disabled={approval_status !== APPROVAL.PENDING || approvalView}
                onChange={(e) => {
                  field.onChange(e.target.value)
                }}
                error={fieldState.error?.message}
              />
            )}
          />

          <DropdownLocation approvalView={approvalView} />

          <Controller
            control={control}
            name="link"
            render={
              ({ field }) => (
                <Input disabled label="LINK" defaultValue={field.value} />
              )

            } />

        </section>

        <section className="flex flex-col gap-3">
          <Controller
            control={control}
            name="transaction_date"
            render={({ field, fieldState }) => (
              <DateInput
                label="DATE"
                disabled={approval_status !== APPROVAL.PENDING || approvalView}
                error={fieldState.error?.message}
                selected={moment(field.value).toDate()}
                onChange={(date) => {
                  const selectedDate: string = moment(date).format("YYYY-MM-DD")
                  field.onChange(selectedDate)
                }}
              />
            )}
          />

          <Controller
            control={control}
            name="referensi"
            render={({ field, fieldState }) => (
              <Input
                {...field}
                label="CUSTOMER ID"
                defaultValue={field.value}
                value={field.value}
                disabled={approval_status !== APPROVAL.PENDING || approvalView}
                onChange={(date) => {
                  field.onChange(date)
                }}
                error={fieldState.error?.message}
              />
            )}
          />

          <Controller
            control={control}
            name="promize_date"
            render={({ field, fieldState }) => (
              <DateInput
                disabled
                label="PROMISED DATE"
                error={fieldState.error?.message}
                selected={moment(field.value).toDate()}
                onChange={(date) => {
                  const selectedDate: string = moment(date).format("YYYY-MM-DD")
                  field.onChange(selectedDate)
                }}
              />
            )}
          />

          <DropdownEmployee approvalView={approvalView} />

          <Controller
            control={control}
            name="delivery_no"
            render={({ field, fieldState }) => (
              <Input
                {...field}
                disabled
                label="DELIVERED"
                defaultValue={field.value}
                value={field.value}
                error={fieldState.error?.message}
              />
            )}
          />

          <section className="flex lg:justify-end">
            <Button permission="ST121" className="w-fit" onClick={modalReminder.toggle}>
              <TbPlus /> ADD REMINDER
            </Button>

            {modalReminder.isActive && (
              <FormReminder approvalView={approvalView} modalReminder={modalReminder} />
            )}
          </section>
        </section>
      </Card.Body>
    </Card>
  )
}
