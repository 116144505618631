// React
import { Fragment, useState } from "react"

// Components
import { TabSection } from "components"

// Pages
import Module from "./Module"
import Menu from "./Menu"
import Feature from "./Feature"
import TypeIndustry from "./TypeIndustry"
import Transaction from "./Transaction"

export default function SystemSetup(): JSX.Element {
  // Vars
  const labelList: string[] = ["MODUL", "MENU", "TRANSACTION", "FEATURE", "TYPE INDUSTRY"]
  const contentList: (() => JSX.Element)[] = [Module, Menu, Transaction, Feature, TypeIndustry]

  // Hooks
  const [activeTab, setTab] = useState<number>(0)

  return (
    <div className="container pt-3">
      <TabSection
        activeIndex={activeTab}
        list={labelList}
        onChangeTab={setTab}
      />

      {contentList.map((Item, key): JSX.Element => {
        if (key === activeTab) {
          return <Item key={key} />
        }

        return <Fragment />
      })}
    </div>
  )
}