/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react"
import { SuccessFetch } from "types"
import { useApi } from "utils"

type UseInvoiceListProps = {
  sales_order_id?: string
  start_date?: string
  end_date?: string
  approval_status?: number
  invoice_status?: number
  search?: string
  page?: number
  limit?: number
}

export type InvoiceList = {
  invoice_id: number
  trx_code: string
  memo: string
  approve_date?: string
  sales_order: {
    sales_order_id: number
    trx_code: string
  }
  transaction_date: string
  customer: {
    customer_name: string
    customer_id: number
    customer_code: string
  }
  approval_status: number
  delivered_qty: number
  location: {
    location_id: number
    location_name: string
  }
  subtotal: string
  payment: number
  balance_owing: number
}

type Response = {
  payload: InvoiceList[]
  pagination_data: {
    total: number
    page: number
    size: number
    pages: number
  }
}

export function useInvoiceList({
  sales_order_id,
  start_date,
  end_date,
  approval_status,
  invoice_status,
  search,
  page,
  limit
}: UseInvoiceListProps) {
  const api = useApi()
  const [data, setData] = useState<Response["payload"]>([])
  const [paginate, setPaginate] = useState<Response["pagination_data"]>()
  const [isLoading, setLoading] = useState(false)

  const fetchData = () => {
    setLoading(true)

    const params = {
      sales_order_id,
      start_date,
      end_date,
      approval_status,
      invoice_status,
      search,
      page,
      limit
    }

    api
      .get("/invoice/", { params })
      .then((res: SuccessFetch<Response>) => {
        const data = res.data.payload ?? []

        setData(data)
        setPaginate(res.data.pagination_data)
      })
      .catch(() => {
        setData([])
        setPaginate({
          page: 1,
          size: 0,
          pages: 0,
          total: 0
        })
      })
      .finally(() => setLoading(false))
  }

  const refetch = () => fetchData()

  useEffect(() => {
    fetchData()

    return () => {
      setData([])
      setLoading(false)
    }
  }, [page, limit, approval_status])

  return {
    data,
    paginate,
    isLoading,
    refetch,
    dropdown: data.map((item) => ({
      value: item.invoice_id,
      label: item.trx_code
    }))
  }
}
