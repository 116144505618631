import { useProjectList } from "pages/Finance/Transaction/GeneralEntry/utils/hooks"
import { CreateModal as ProjectModal } from "pages/Setup/Preference/Project/components"
import { Fragment } from "react"
import { useToggle } from "utils"

type ProjectHeaderProps = {
  approvalView?: boolean
  project: ReturnType<typeof useProjectList>
}

export const JobsHeader = ({
  approvalView,
  project,
}: ProjectHeaderProps) => {
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <th className="whitespace-nowrap">
        JOBS{" "}
        <span
          className={`whitespace-nowrap text-primary cursor-pointer ${approvalView ? "hidden" : ""}`}
          onClick={toggle}>
          [+]
        </span>

      </th>

      {isActive && (
        <ProjectModal
          toggle={() => {
            toggle()
            project.refetch()
          }}
        />
      )}
    </Fragment>
  )
}
