// Icons
import { TbEye, TbEyeOff, TbLock } from "react-icons/tb"

// Third-Party Libraries
import { Controller, useFormContext } from "react-hook-form"

// Types
import type { FormType } from "../types"

// Utils
import { useToggle } from "../../../utils"

export function Password() {
  // Hooks
  const { isActive, toggle } = useToggle(false)

  // Variables
  const Icon = isActive ? TbEye : TbEyeOff

  // Form
  const { control } = useFormContext<FormType>()

  return (
    <Controller
      control={control}
      name="password"
      render={({ field, fieldState }) => (
        <section>
          <div className={`join w-full ${fieldState.error && "border-error border-2"}`}>
            <button disabled type="button" title="Password" className="btn-ghost join-item !bg-white p-2 border-0">
              <TbLock className="text-black" size={24} />
            </button>
    
            <input {...field} type={isActive ? "text": "password"} className="grow input input-bordered join-item bg-white text-black" placeholder="Password"/>

            <button
              type="button"
              title={isActive ? "Show Password" : "Hide Password"}
              className="btn-ghost join-item bg-white p-2 border-0"
              onClick={toggle}
            >
              <Icon className="text-black" size={24} />
            </button>
          </div>

          {fieldState.error && (
            <div className="label">
              <span className="label-text text-error">{fieldState.error.message}</span>
            </div>
          )}
        </section>
      )}
    />
  )
}