import { SelectOptionType } from "types"

export type SelectOptionPeriodeType = Pick<SelectOptionType, "label"> & {
  value: string | undefined
}

export const periode: SelectOptionPeriodeType[] = [
  { value: "1", label: "None" },
  { value: "2", label: "Day" },
  { value: "3", label: "Month" },
  { value: "4", label: "Quarter" },
  { value: "5", label: "Year" }
]
