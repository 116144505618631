import { Button, Checkbox, Loading, Accordion, BaseTable } from "../../../../../../components"
import { useApi } from "utils"
import toast from "react-hot-toast"
import { useIsSubscribe } from "../ChildItem/components/Item/utils"
import { PrivillegeProps } from "../../types"

export const FormPrivilege = ({ action, onSubmit, isActiveSubmit, setPrivillegeData, privillegeData, isLoadingPrivillege, id, refetchPrivillege, permission }: PrivillegeProps) => {
  const api = useApi()

  const { dataSubscribe, refetchSubscribe } = useIsSubscribe(id!)
  const isDisabled = action === "DETAIL" || !dataSubscribe?.is_admin

  const handleFeatureChange = (moduleIndex: number, detailIndex: number) => {
    setPrivillegeData((prevDummy: any) => prevDummy.map((mod: any, modIdx: any) => {
      if (modIdx === moduleIndex) {
        return {
          ...mod,
          features: mod.features.map((det: any, detIdx: any) => detIdx === detailIndex 
            ? { ...det, is_enabled: !det?.is_enabled } : det
          )
        };
      }
      return mod;
    }));
  };
  const handleModuleChange = (moduleIndex: number) => {
    const newDummy = privillegeData.map((module: any, index:any) => {
      if (index === moduleIndex) {
        const newModulePermissions = !module.module_permissions;
        return {
          ...module,
          module_permissions: newModulePermissions,
          features: module.features.map((detail: any) => ({
            ...detail,
            is_enabled: newModulePermissions
          }))
        };
      }
      return module;
    });

    setPrivillegeData(newDummy);
  };
  const handleAllPermissionsChange = () => {
    const allPermissionsTrue = privillegeData.every((module: any) => module.features.every((detail: any) => detail.is_enabled));

    const newDummy = privillegeData.map((module: any) => ({
      ...module,
      module_permissions: !allPermissionsTrue,
      features: module.features.map((detail: any) => ({
        ...detail,
        is_enabled: !allPermissionsTrue
      }))
    }));

    setPrivillegeData(newDummy);
  };
  const allPermissionsTrue = privillegeData.every((module: any) => module.features.every((detail: any) => detail.is_enabled));

  const convertAction = (data: string) => {
    if(data === "1") return "CREATE"
    if(data === "2") return "READ"
    if(data === "3") return "UPDATE"
    if(data === "4") return "DELETE"
    if(data === "5") return "SOFT DELETE"
  }

  return (
    <div>
      <section>
        <section className="flex justify-between items-center">
          <div className="w-[100px]">
            <div>
              <label htmlFor="toggle" className="flex items-center cursor-pointer">
                <small className={`absolute ${dataSubscribe?.is_admin ? "text-black ml-10" : " ml-4"} transition duration-300 pointer-events-none`}>ADMIN</small>
                <input 
                  type="checkbox" 
                  id="toggle" 
                  className={`toggle w-[6rem] h-[3rem] ${dataSubscribe?.is_admin ? "!bg-primary" : "!bg-[#151515] dark:!bg-[#686D76]"}`} 
                  checked={dataSubscribe?.is_admin} 
                  onClick={() => toast.promise(
                    api.patch(`/employee/${id}/admin`, { is_admin: !dataSubscribe?.is_admin } ),
                      {
                        loading: "Loading...",
                        success: (res) => res.data.message,
                        error: (err) => err.response.data.message
                      }
                    )
                    .then(() => {
                      refetchPrivillege()
                      refetchSubscribe()
                    })
                    .catch(() => {
                      refetchPrivillege()
                      refetchSubscribe()
                    })
                  }
                />
              </label>
            </div>
          </div>

          <div className="flex justify-end">
            <div>
              <Checkbox ref={null} value={allPermissionsTrue} disabled={isDisabled} onChange={handleAllPermissionsChange}>
                All Access
              </Checkbox>
            </div>
          </div>
        </section>

        <div className="mt-5">
          {privillegeData?.map((val: any, index: any) => (
            <Accordion 
              title={val?.module_name}
              checkboxType
              content={
                <div>
                  <hr />
                  <div className="mt-5 mb-5">
                    <Checkbox ref={null} value={val?.module_permissions} disabled={isDisabled} onChange={() => handleModuleChange(index)}>
                      <div>MODULE: {val?.module_name}</div>
                    </Checkbox>
                  </div>
                  <BaseTable>
                    <thead>
                      <tr>
                        <th className="w-[50px]">NO</th>
                        <th className="w-[100px]">CODE</th>
                        <th className="w-[200px]">MENU</th>
                        <th className="w-[350px]">FEATURE</th>
                        <th className="w-[150px]">ACTION</th>
                        <th>PERMISSIONS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoadingPrivillege || val?.features?.length === 0 
                        ? <tr><td colSpan={9} className="text-center"><Loading loading={isLoadingPrivillege} errorText="No data available" /></td></tr>
                        : val?.features?.map((item: any, key: any) => (
                            <tr className="font-normal">
                              <td className="text-center">{key + 1}</td>
                              <td className="w-[100px]">{item?.feature?.feature_code ?? "-"}</td>
                              <td>{item?.menu?.menu_name ?? "-"}</td>
                              <td>{item?.feature?.feature_name ?? "-"}</td>
                              <td>{item?.feature?.action ? convertAction(item?.feature?.action?.toString()) : "-"}</td>
                              <td className="text-center w-[40px] pl-[47px]">
                                <Checkbox ref={null} value={item?.is_enabled} disabled={isDisabled} onChange={() => handleFeatureChange(index, key)} />
                              </td>
                            </tr>
                          ))
                      }
                    </tbody>
                  </BaseTable>
                </div>
              }
            />
          ))}
        </div>
      </section>
      {action !== "DETAIL" &&
        <div className="flex justify-end">
          <Button 
            type="button"
            className="!none mt-5" 
            color="primary"
            permission={"CR011"}
            onClick={() => onSubmit()}
            disabled={isActiveSubmit}
          >
            SAVE
          </Button>
        </div>
      }
    </div>
  )
}