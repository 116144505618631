// React
import { useEffect, useState } from "react"

// Types
import type { SelectOptionType, SuccessFetch } from "types"

// Utils
import { useApi } from "utils"

export function useLocation() {
  // Types
  interface Type extends SelectOptionType {
    address: string
  }

  // Hooks
  const api = useApi()
  const [data, setData] = useState<Type[]>([])
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    refetch()
  
    return () => {
      setData([])
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [])

  function refetch() {
    setLoading(true)

    api.get("/location/").then((res: SuccessFetch<{
      payload: {
        address: string
        location_id: number
        location_name: string
      }[]
    }>) => {
      setData(res.data.payload.map(item => {
        return {
          address: item.address,
          label: item.location_name,
          value: item.location_id.toString()
        }
      }))
    }).catch(() => {
      setData([])
    }).finally(() => {
      setLoading(false)
    })
  }

  return { data, isLoading, refetch }
}