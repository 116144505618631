import { Button } from "components"
import { TabRegisterPath } from "../../types"

type TabRegisterProps = {
  activeTab: string
  setActiveTab: (path: string) => void
}

type Tabs = {
  label: string
  path: TabRegisterPath
  permission: string
}

export function TabNavRegister({ activeTab, setActiveTab }: TabRegisterProps) { 
  const tabs: Tabs[] = [
    { label: "By Date Range", path: "by-date-range", permission: "PR01" },
    { label: "By Card", path: "by-card", permission: "PR02" },
    { label: "By Location", path: "by-location", permission: "PR03" },
    { label: "By Reference Id", path: "reference-id", permission: "PR04" },
    { label: "All Bills", path: "all-bills", permission: "PR05" },
    { label: "Aged Payable", path: "aged-payable", permission: "PR06" },
    { label: "Work Order", path: "work-order", permission: "PR07" },
  ]

  return (
    <section className="grow flex items-stretch overflow-x-auto">
      {tabs.map((tab) => (
        <Button
          key={tab?.path}
          type="button"
          color="ghost"
          size="sm"
          permission={tab?.permission}
          className={`grow bg-[#3c3c3c] border-y-0 border-x border-white no-animation hover:bg-primary hover:text-black whitespace-nowrap uppercase ${tab.path === activeTab ? "bg-primary text-black" : "text-white"}`}
          // onClick={() => permission?.checkPermission(tab?.permission) ? setActiveTab(tab?.path) : notify()}
          onClick={() => setActiveTab(tab?.path)}
        >
          {tab.label}
        </Button>
      ))}
    </section>
  )
}
