// React
import { Fragment } from "react";

// Components
import { Button, Label, Modal, Select } from "components";
import { CreatePage as EmployeeModal } from "pages/Card/Transaction/CreateNewEmployee/components";

// Icons
import { TbPlus } from "react-icons/tb";

// Utils
import { useToggle } from "utils";
import { useEmployeeList } from "../utils/hooks";
import { Controller, useFormContext } from "react-hook-form";
import { FormType } from "../types";

export function Employee() {
  const { control } = useFormContext<FormType>();
  const { dropdown } = useEmployeeList();

  return (
    <section>
      <Label text="REQUEST BY" />

      <section className="flex gap-3">
        <Controller
          control={control}
          name="card_id"
          render={({ field, fieldState }) => (
            <Select
              ref={field.ref}
              className="grow"
              placeholder="Select Employee"
              options={dropdown ?? []}
              value={
                field.value
                  ? dropdown.find(
                      (item) => item.value === field.value?.toString()
                    )
                  : null
              }
              onChange={(e) => field.onChange(e?.value)}
              error={fieldState.error?.message}
            />
          )}
        />

        <CreateEmployee />
      </section>
    </section>
  );
}

function CreateEmployee() {
  // Hooks
  const { isActive, toggle } = useToggle(false);

  return (
    <Fragment>
      <Button color="primary" permission="AT021" className="px-2" onClick={toggle}>
        <TbPlus size={24} />
      </Button>

      {isActive && (
        <Modal
          isOpen
          title="Create New Employee"
          size="full"
          closeModal={toggle}>
          <Modal.Body>
            <EmployeeModal asModal />
          </Modal.Body>
        </Modal>
      )}
    </Fragment>
  );
}
