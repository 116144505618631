
type CalculateTotalReturnItem = {
  qty: number
  price_return: number
  discount: number
  tax_rate?: number
}

export const calculateTotalReturnItem = ({
  qty,
  price_return,
  discount,
  tax_rate
}: CalculateTotalReturnItem) => {

  const decimalDiscount = discount ?? 0

  const total = qty * price_return

  const totalDiscount = (total * decimalDiscount) / 100

  const totalAfterDiscount = total - totalDiscount

  const totalTax = (totalAfterDiscount * (tax_rate ?? 0)) / 100

  const totalAfterTax = totalAfterDiscount + totalTax

  return {
    totalPrice: total,
    discount: decimalDiscount + "%",
    discountAmount: totalDiscount,
    finalPrice: totalAfterDiscount,
    totalTax: totalTax,
    totalAfterTax: totalAfterTax
  }
}