import { Card, Loading } from "components"
import { Fragment, useEffect, useState } from "react"
import { SearchingSections } from "./SearchingSection"
import { SelectedState } from "../../types/Location"
import { useList } from "./hooks"
import { FotherSection, TableSection } from "./components"

export function RegisterByLocation() {
  const [selected, setSelected] = useState<SelectedState>({ date_from: "", date_end: "", location_id: "", delivered_id: "" })

  const { data, isLoading, refetch } = useList()

  useEffect(() => {
    const filteredSelected: Partial<SelectedState> = {};

    (Object.keys(selected) as Array<keyof SelectedState>).forEach(key => {
      if (selected[key]) { filteredSelected[key] = selected[key] }
    });

    refetch(filteredSelected);
    
    // eslint-disable-next-line
  }, [selected])

  return (
    <Fragment>
      <section className="mt-2">
        <Card>
          <Card.Body>
            <SearchingSections 
              selected={selected} 
              setSelected={setSelected}
            />
          </Card.Body>
        </Card>
      </section>

      {isLoading
        ? <Card className="mt-2"><Card.Body><div className="flex justify-center py-12"><Loading loading /></div></Card.Body></Card> 
        : <>
            <section className="mt-2">
              <Card>
                <Card.Body>
                  <TableSection data={data} isLoading={isLoading} refetch={refetch} selected={selected} />
                </Card.Body>
              </Card>
            </section>

            <section className="mt-2">
              <Card>
                <Card.Body>
                  <FotherSection data={data} />
                </Card.Body>
              </Card>
            </section>
          </>
        }
    </Fragment>
  )
}
