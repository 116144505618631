import { BaseTable, Button, Loading, Modal } from "components";
import { useProductByGroupId } from "../../utils";

interface ModalSectionPortalProps { data: string; toggle: () => void }

export const ModalSectionPortal = ({ data, toggle }: ModalSectionPortalProps) => {
    const { dataGroupById, dataError, isLoading } = useProductByGroupId(data ? data : "")
  
    return (
      <Modal isOpen title="LIST SIMILAR GROUP OF ITEM" closeModal={toggle} size="xl">
        {isLoading || dataError 
          ? <Loading errorText={dataError} loading={isLoading} />
          : <>
              <div className="border-[1px] mt-4 p-3 border-red h-[300px] overflow-y-auto">
                <BaseTable className="border-collapse">
                  <tbody>
                    {dataGroupById?.map((val, index) => (
                        <tr>
                          <td className="w-[20px]">{index + 1}</td>
                          <td className="w-[170px]">{val?.product_barcode ?? "-"}</td>
                          <td className="max-w-[100px]">{val?.product_name ?? "-"}</td>
                        </tr>
                      )
                    )}
                  </tbody>
                </BaseTable>
              </div>
              <div className="flex justify-end">
                <Button type="button" color="primary" onClick={toggle} className="mt-4">CLOSE</Button>
              </div>
            </>
        }
      </Modal>
    )
  }