import { Select, Button } from "../../../../../../components"
import { Controller, useFormContext } from "react-hook-form"
import type { EducationList } from "../../types"
import { useAcademy, useDoctoral, useMagister, useSD, useSMA, useSMP, useUniversity } from "./utils"
import { useState } from "react"
import { FaPlus } from "react-icons/fa";
import { CreateModal as CreateModalSD } from "../../../../../Setup/Preference/SD/components";
import { CreateModal as CreateModalSMP } from "../../../../../Setup/Preference/SMP/components";
import { CreateModal as CreateModalSMA } from "../../../../../Setup/Preference/SMA/components";
import { CreateModal as CreateModalAcademy } from "../../../../../Setup/Preference/Academy/components";
import { CreateModal as CreateModalUniversity } from "../../../../../Setup/Preference/Univercity/components";
import { CreateModal as CreateModalMagister } from "../../../../../Setup/Preference/Magister/components";
import { CreateModal as CreateModalDoctoral } from "../../../../../Setup/Preference/Doctoral/components";
interface AddressProps {
  action?: "CREATE" | "UPDATE" | "DETAIL"
  onSubmit: (newState: any) => void
  isActiveSubmit?: boolean
  permission?: boolean
}

export const FormEducation:React.FC<AddressProps> = ({ action, onSubmit, isActiveSubmit, permission }) => {
  const { getValues, handleSubmit, formState } = useFormContext<EducationList>()

  return (
    <div>
      <section>
        <SD isDisabled={Boolean(action === "DETAIL")} />
        <SMP isDisabled={Boolean(action === "DETAIL")} />
        <SMA isDisabled={Boolean(action === "DETAIL")} />
        <Academy isDisabled={Boolean(action === "DETAIL")} />
        <University isDisabled={Boolean(action === "DETAIL")} />
        <Magister isDisabled={Boolean(action === "DETAIL")} />
        <Doctoral isDisabled={Boolean(action === "DETAIL")} />
      </section>
      {action !== "DETAIL" &&
        <div className="flex justify-end">
          <Button 
            type="button"
            className="!none mt-5" 
            color="primary"
            permission={"CR011"}
            loading={formState.isSubmitting ? "true" : undefined}
            onClick={handleSubmit(() => onSubmit(getValues()),(err)=> console.log(err))}
            disabled={isActiveSubmit}
          >
            SAVE
          </Button>
        </div>
      }
    </div>
  )
}

function SD(props: { isDisabled: boolean }) {
  const { control } = useFormContext<EducationList>()
  const { data, isLoading, refetch } = useSD()
  const [modalConfig, setModalConfig] = useState(false)

  return (
    <div className="flex items-top">
      <Controller
        control={control}
        name="sd_id"
        render={({ field, fieldState }) => (
          <Select
            label="SD"
            placeholder="Choose sd"
            controlClass={!props.isDisabled ? "!rounded-r-none" : ""}
            isDisabled={props.isDisabled}
            isLoading={isLoading}
            error={fieldState.error?.message}
            options={data}
            value={data.find(item => item.value === field.value?.toString())}
            onChange={e => {
              field.onChange(e?.value)
              refetch()
            }}
          />
        )}
      />
      {!props.isDisabled && 
        <div className="mt-[36px]">
          <Button 
            type="button" 
            color="primary" 
            className="!rounded-r-lg" 
            onClick={() => setModalConfig(true)}>
            <FaPlus />
          </Button>
        </div>
      }
      {modalConfig === true && <CreateModalSD toggle={() => {
        setModalConfig(false)
        refetch()
      }} />}
    </div>
  )
}
function SMP(props: { isDisabled: boolean }) {
  const { control } = useFormContext<EducationList>()
  const { data, isLoading, refetch } = useSMP()
  const [modalConfig, setModalConfig] = useState(false)

  return (
    <div className="flex items-top">
      <Controller
        control={control}
        name="smp_id"
        render={({ field, fieldState }) => (
          <Select
            label="SMP"
            placeholder="Choose smp"
            controlClass={!props.isDisabled ? "!rounded-r-none" : ""}
            isDisabled={props.isDisabled}
            isLoading={isLoading}
            error={fieldState.error?.message}
            options={data}
            value={data.find(item => item.value === field.value?.toString())}
            onChange={e => {
              field.onChange(e?.value)
              refetch()
            }}
          />
        )}
      />
      {!props.isDisabled && 
        <div className="mt-[36px]">
          <Button 
            type="button" 
            color="primary" 
            className="!rounded-r-lg" 
            onClick={() => setModalConfig(true)}>
            <FaPlus />
          </Button>
        </div>
      }
      {modalConfig === true && <CreateModalSMP toggle={() => {
        setModalConfig(false)
        refetch()
      }} />}
    </div>
  )
}
function SMA(props: { isDisabled: boolean }) {
  const { control } = useFormContext<EducationList>()
  const { data, isLoading, refetch } = useSMA()
  const [modalConfig, setModalConfig] = useState(false)

  return (
    <div className="flex items-top">
      <Controller
        control={control}
        name="sma_id"
        render={({ field, fieldState }) => (
          <Select
            label="SMU/SMK"
            placeholder="Choose smu/smk"
            controlClass={!props.isDisabled ? "!rounded-r-none" : ""}
            isDisabled={props.isDisabled}
            isLoading={isLoading}
            error={fieldState.error?.message}
            options={data}
            value={data.find(item => item.value === field.value?.toString())}
            onChange={e => {
              field.onChange(e?.value)
              refetch()
            }}
          />
        )}
      />
      {!props.isDisabled && 
        <div className="mt-[36px]">
          <Button 
            type="button" 
            color="primary" 
            className="!rounded-r-lg" 
            onClick={() => setModalConfig(true)}>
            <FaPlus />
          </Button>
        </div>
      }
      {modalConfig === true && <CreateModalSMA toggle={() => {
        setModalConfig(false)
        refetch()
      }} />}
    </div>
  )
}
function Academy(props: { isDisabled: boolean }) {
  const { control } = useFormContext<EducationList>()
  const { data, isLoading, refetch } = useAcademy()
  const [modalConfig, setModalConfig] = useState(false)

  return (
    <div className="flex items-top">
      <Controller
        control={control}
        name="academy_id"
        render={({ field, fieldState }) => (
          <Select
            label="ACADEMY"
            placeholder="Choose academy"
            controlClass={!props.isDisabled ? "!rounded-r-none" : ""}
            isDisabled={props.isDisabled}
            isLoading={isLoading}
            error={fieldState.error?.message}
            options={data}
            value={data.find(item => item.value === field.value?.toString())}
            onChange={e => {
              field.onChange(e?.value)
              refetch()
            }}
          />
        )}
      />
      {!props.isDisabled && 
        <div className="mt-[36px]">
          <Button 
            type="button" 
            color="primary" 
            className="!rounded-r-lg" 
            onClick={() => setModalConfig(true)}>
            <FaPlus />
          </Button>
        </div>
      }
      {modalConfig === true && <CreateModalAcademy toggle={() => {
        setModalConfig(false)
        refetch()
      }} />}
    </div>
  )
}
function University(props: { isDisabled: boolean }) {
  const { control } = useFormContext<EducationList>()
  const { data, isLoading, refetch } = useUniversity()
  const [modalConfig, setModalConfig] = useState(false)

  return (
    <div className="flex items-top">
      <Controller
        control={control}
        name="university_id"
        render={({ field, fieldState }) => (
          <Select
            label="UNIVERSITY"
            placeholder="Choose university"
            controlClass={!props.isDisabled ? "!rounded-r-none" : ""}
            isDisabled={props.isDisabled}
            isLoading={isLoading}
            error={fieldState.error?.message}
            options={data}
            value={data.find(item => item.value === field.value?.toString())}
            onChange={e => {
              field.onChange(e?.value)
              refetch()
            }}
          />
        )}
      />
      {!props.isDisabled && 
        <div className="mt-[36px]">
          <Button 
            type="button" 
            color="primary" 
            className="!rounded-r-lg" 
            onClick={() => setModalConfig(true)}>
            <FaPlus />
          </Button>
        </div>
      }
      {modalConfig === true && <CreateModalUniversity toggle={() => {
        setModalConfig(false)
        refetch()
      }} />}
    </div>
  )
}
function Magister(props: { isDisabled: boolean }) {
  const { control } = useFormContext<EducationList>()
  const { data, isLoading, refetch } = useMagister()
  const [modalConfig, setModalConfig] = useState(false)

  return (
    <div className="flex items-top">
      <Controller
        control={control}
        name="magister_id"
        render={({ field, fieldState }) => (
          <Select
            label="MAGISTER"
            placeholder="Choose magister"
            controlClass={!props.isDisabled ? "!rounded-r-none" : ""}
            isDisabled={props.isDisabled}
            isLoading={isLoading}
            error={fieldState.error?.message}
            options={data}
            value={data.find(item => item.value === field.value?.toString())}
            onChange={e => {
              field.onChange(e?.value)
              refetch()
            }}
          />
        )}
      />
      {!props.isDisabled && 
        <div className="mt-[36px]">
          <Button 
            type="button" 
            color="primary" 
            className="!rounded-r-lg" 
            onClick={() => setModalConfig(true)}>
            <FaPlus />
          </Button>
        </div>
      }
      {modalConfig === true && <CreateModalMagister toggle={() => {
        setModalConfig(false)
        refetch()
      }} />}
    </div>
  )
}
function Doctoral(props: { isDisabled: boolean }) {
  const { control } = useFormContext<EducationList>()
  const { data, isLoading, refetch } = useDoctoral()
  const [modalConfig, setModalConfig] = useState(false)

  return (
    <div className="flex items-top">
      <Controller
        control={control}
        name="doctoral_id"
        render={({ field, fieldState }) => (
          <Select
            label="DOCTORAL"
            placeholder="Choose doctoral"
            controlClass={!props.isDisabled ? "!rounded-r-none" : ""}
            isDisabled={props.isDisabled}
            isLoading={isLoading}
            error={fieldState.error?.message}
            options={data}
            value={data.find(item => item.value === field.value?.toString())}
            onChange={e => {
              field.onChange(e?.value)
              refetch()
            }}
          />
        )}
      />
      {!props.isDisabled && 
        <div className="mt-[36px]">
          <Button 
            type="button" 
            color="primary" 
            className="!rounded-r-lg" 
            onClick={() => setModalConfig(true)}>
            <FaPlus />
          </Button>
        </div>
      }
      {modalConfig === true && <CreateModalDoctoral toggle={() => {
        setModalConfig(false)
        refetch()
      }} />}
    </div>
  )
}