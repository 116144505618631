import { Controller, useFormContext } from "react-hook-form"
import { Input, Button } from "components"
import type { BuyingList, BuyingProps } from "../../types"
import { DataTable } from "../ChildItem/components/Item/components/FormBuying/DataTable"

export const FormBuying = ({ action, onSubmit, checkLoading, setCheckLoading, setBuyingPrice, permission }: BuyingProps) => {
  const { control } = useFormContext<BuyingList>()
  const methods = useFormContext<BuyingList>()
  
  return (
    <div>
      <section>
        <DataTable action={action} checkLoading={checkLoading} setCheckLoading={setCheckLoading} setBuyingPrice={setBuyingPrice} />

        {/* UI ONLY */}
        <div>
          <Controller
            control={control}
            name="tax_id"
            render={({ field }) => 
              <Input 
                {...field} 
                label="LAST PURCHASED TRANSACTION" 
                type="text" 
                // className="w-[150px]"
                placeholder="Input purchased transaction..." 
                value={field.value ?? ""}
                onChange={(e) => {
                  const result = e.target.value.replace(/[^\d.]/g, '')
                  field.onChange(result)
                }}
                disabled 
              />
            }
          />
          <Controller
            control={control}
            name="tax_id"
            render={({ field }) => 
              <Input 
                {...field} 
                label="LAST SOLD TRANSACTION" 
                type="text" 
                // className="w-[150px]"
                placeholder="Input sold transaction..." 
                value={field.value ?? ""}
                onChange={(e) => {
                  const result = e.target.value.replace(/[^\d.]/g, '')
                  field.onChange(result)
                }}
                disabled 
              />
            }
          />
          <Controller
            control={control}
            name="tax_id"
            render={({ field }) => 
              <Input 
                {...field} 
                label="STOCK IN" 
                type="text" 
                // className="w-[150px]"
                placeholder="Input stock in..." 
                value={field.value ?? ""}
                onChange={(e) => {
                  const result = e.target.value.replace(/[^\d.]/g, '')
                  field.onChange(result)
                }}
                disabled 
              />
            }
          />
          <Controller
            control={control}
            name="tax_id"
            render={({ field }) => 
              <Input 
                {...field} 
                label="STOCK OUT" 
                type="text" 
                // className="w-[150px]"
                placeholder="Input stock out..." 
                value={field.value ?? ""}
                onChange={(e) => {
                  const result = e.target.value.replace(/[^\d.]/g, '')
                  field.onChange(result)
                }}
                disabled 
              />
            }
          />
          <Controller
            control={control}
            name="tax_id"
            render={({ field }) => 
              <Input 
                {...field} 
                label="QUANTITY ON HAND" 
                type="text" 
                // className="w-[150px]"
                placeholder="Input quantity on hand..." 
                value={field.value ?? ""}
                onChange={(e) => {
                  const result = e.target.value.replace(/[^\d.]/g, '')
                  field.onChange(result)
                }}
                disabled 
              />
            }
          />
          <Controller
            control={control}
            name="tax_id"
            render={({ field }) => 
              <Input 
                {...field} 
                label="COGM VALUE" 
                type="text" 
                // className="w-[150px]"
                placeholder="Input cogm value..." 
                value={field.value ?? ""}
                onChange={(e) => {
                  const result = e.target.value.replace(/[^\d.]/g, '')
                  field.onChange(result)
                }}
                disabled 
              />
            }
          />
          <Controller
            control={control}
            name="tax_id"
            render={({ field }) => 
              <Input 
                {...field} 
                label="AVERAGE COST" 
                type="text" 
                // className="w-[150px]"
                placeholder="Input average cost..." 
                value={field.value ?? ""}
                onChange={(e) => {
                  const result = e.target.value.replace(/[^\d.]/g, '')
                  field.onChange(result)
                }}
                disabled 
              />
            }
          />
          <Controller
            control={control}
            name="tax_id"
            render={({ field }) => 
              <Input 
                {...field} 
                label="ON ORDER" 
                type="text" 
                // className="w-[150px]"
                placeholder="Input on order..." 
                value={field.value ?? ""}
                onChange={(e) => {
                  const result = e.target.value.replace(/[^\d.]/g, '')
                  field.onChange(result)
                }}
                disabled 
              />
            }
          />
          <Controller
            control={control}
            name="tax_id"
            render={({ field }) => 
              <Input 
                {...field} 
                label="AVAILABLE" 
                type="text" 
                placeholder="Input available..." 
                value={field.value ?? ""}
                onChange={(e) => {
                  const result = e.target.value.replace(/[^\d.]/g, '')
                  field.onChange(result)
                }}
                disabled 
              />
            }
          />
        </div>
      </section>
      {action !== "DETAIL" &&
        <div className="flex justify-end">
          <Button
            type="button"
            color="primary"
            className="mt-5" 
            permission={permission}
            loading={methods.formState.isSubmitting ? "true" : undefined}
            onClick={onSubmit}
          >
            {action === "UPDATE" ? "UPDATE" : "SAVE"}
          </Button>
        </div>
      }
    </div>
  )
}