import { Select } from "components"
import { useProjectList } from "pages/Finance/Transaction/GeneralEntry/utils/hooks"
import { Fragment } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { FormSalesOrderDetailType } from "../types"

type DropdownJobsProps = {
  jobs: ReturnType<typeof useProjectList>
}

export const DropdownJobs = ({ jobs }: DropdownJobsProps) => {
  const { control, setValue } = useFormContext<FormSalesOrderDetailType>()

  return (
    <Fragment>
      <Controller
        control={control}
        name="project_id"
        render={({ field, fieldState }) => {
          return (
            <section>
              <Select
                placeholder="Select Jobs"
                error={fieldState.error?.message}
                ref={field.ref}
                options={jobs.dropdown}
                value={
                  field.value
                    ? jobs.dropdown.find(
                      (item) => item.value === field.value.toString()
                    )
                    : null
                }
                onChange={(e) => {
                  field.onChange(e?.value)
                  setValue("project_name", e?.label ?? "")
                }}
              />
            </section>
          )
        }}
      />
    </Fragment>
  )
}
