// React
import { useEffect, useState } from "react"

// Third-Party Libraries
import axios from "axios"

// Types
import type { SuccessFetch } from "types"
import type { PoFetchType } from "pages/Purchase/Transaction/EnterBill/types"
import type { BillDetail } from "../../types"

// Utils
import { useApi } from "utils"

export function useDetail(
  id: string,
  bill_id: string
) {
  // Hooks
  const api = useApi()
  const [data, setData] = useState<BillDetail | null>(null)
  const [isLoading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    axios.all([
      api.get("/probill/detailbill_bypo", {
        params: { purchase_order_id: id }
      }),
      api.get("/probill/detailbill", {
        params: { bill_id }
      }),
      api.get("/product/"),
      api.get("/vendor/")
    ]).then(axios.spread((respo, res, resproduct, resvendor) => {
      // Vars
      const datapo: SuccessFetch<{
        payload: PoFetchType
      }> = respo
      const data: SuccessFetch<{
        payload: {
          databill: {
            vendor_id: number
            vendor_name: string
            location_id: number
            location_name: string
            address: string
            trx_code: string
            delivery_no: number
          }
          detailbill: {
            quantity_bill: number
            quantity_unit: number
            product_id: number
            product_name: string
            unit_measure: string
            price_bill: number
            discount: number
            total: number
            tax_id: number
            tax_rate: number
            tax_name: string
          }[]
        }
      }> = res
      const dataproduct: SuccessFetch<{
        payload: {
          product_barcode: string
          product_id: number
        }[]
      }> = resproduct
      const datavendor: SuccessFetch<{
        payload: {
          vendor_id: number
          payment_term_vendor_id: number
        }[]
      }> = resvendor
      const payloadpo = datapo.data.payload
      const payload = data.data.payload
      const payment_term_vendor_id: string = datavendor.data.payload.find(item => item.vendor_id === payload.databill.vendor_id)?.payment_term_vendor_id.toString() ?? ""

      setData({
        data: {
          payment_term_vendor_id,
          vendor_id: payload.databill.vendor_id.toString(),
          vendor_name: payload.databill.vendor_name,
          location_id: payload.databill.location_id.toString(),
          location_name: payload.databill.location_name,
          address: payload.databill.address,
          trx_code: payload.databill.trx_code,
          delivery_no: payload.databill.delivery_no.toString(),
          tax_inclusive: payloadpo.datapo.tax_inclusive,
          payment_term: payloadpo.datapo.payment_term.toString(),
          trx_code_reject: "",
          transaction_date: "",
          memo: "",
          referensi: "",
          approve_by_name: "-",
          approve_status: "-",
          reject_status: "-"
        },
        detail_reject: [],
        detail: payload.detailbill.map(item => {
          // Vars
          const product_barcode = dataproduct.data.payload.find(i => i.product_id === item.product_id)?.product_barcode ?? "-"

          return {
            product_barcode,
            discount: item.discount,
            price_bill: item.price_bill,
            product_id: item.product_id,
            product_name: item.product_name,
            quantity_bill: item.quantity_bill,
            quantity_rate: item.quantity_unit / item.quantity_bill,
            tax_id: item.tax_id,
            tax_name: item.tax_name,
            tax_rate: item.tax_rate,
            total: item.total,
            unit_measure: item.unit_measure
          }
        })
      })
    })).catch(() => {
      setData(null)
    }).finally(() => {
      setLoading(false)
    })

    return () => {
      setData(null)
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [])

  return { data, isLoading }
}