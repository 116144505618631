import { AxiosInstance } from "axios";

type GenerateDeleteJournalPromiseParams = {
  api: AxiosInstance;
  data_source: number;
  params: { JournalCode: string | undefined };
};

export const generateDeleteJournalPromise = ({
  api,
  data_source,
  params
}: GenerateDeleteJournalPromiseParams) => {
  switch (data_source) {
    case 2:
      return api.delete("/receivemoney/delete", { params });
    case 3:
      return api.delete(`/general-entry/${params.JournalCode}`);
    case 4:
      return api.delete("/transfermoney/delete", { params });
    default:
      return api.delete("/spendmoney/delete", { params });
  }
};
