import { Card, DateInput, Select } from 'components'
import moment from 'moment'
import { SearchingSectionProps } from 'pages/FindSection/types'
import { useAccount } from 'pages/Inventori/Transaction/TransactionJournal/utils'
import { useEffect } from 'react'

export const FilterSection = ({ selected, setSelected }: SearchingSectionProps) => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();
  const dateTo = new Date(year, month, 1);
  const dateEnd = new Date(year, month + 1, 0);
  const dataSearchBy = [{ label: "Account", value: "1" }]

  const { dataAccount } = useAccount()

  type DataType = typeof dataAccount
  const dropdownMapping: { [key: string]: DataType } = { "1": dataAccount };
  const dropdownData: DataType | string = dropdownMapping[selected?.search_by!] || null; 

  useEffect(() => {
    if(selected?.date_from === "") { setSelected({ ...selected, date_from: moment(dateTo).format("YYYY-MM-DD")}) }
    if(selected?.date_end === "") { setSelected({ ...selected, date_end: moment(dateEnd).format("YYYY-MM-DD")}) }

    // eslint-disable-next-line
  }, [selected])

  return (
    <section className="mt-2">
        <Card>
            <Card.Body>
                <section className="flex justify-between items-center">
                    <div className="w-[80%]">
                        <div className="w-full lg:flex justify-center lg:justify-start items-center mb-3">
                            <div className="label-text pb-1 font-bold pe-2 !w-[150px]">SEARCH BY</div>
                            <div>
                                <Select 
                                    controlClass="w-full lg:w-[265px]"
                                    value={selected?.search_by !== "" ? dataSearchBy.find(item => item.value === selected?.search_by) : []}
                                    options={dataSearchBy}
                                    onChange={(e) => setSelected({ ...selected, search_by: e?.value! })}
                                />
                            </div>
                            <Select 
                                className="lg:ml-2 mt-2 lg:mt-0" 
                                controlClass="w-full lg:w-[339px]"
                                value={selected?.search_id !== "" ? dropdownData.find((item) => item.value?.toString() === selected?.search_id?.toString()) : []}
                                options={dropdownData}
                                onChange={(e) => setSelected({ ...selected, search_id: e?.value! })}
                                isDisabled={!Boolean(selected?.search_by)}
                            />
                        </div>
                        <div className="w-full lg:flex justify-center lg:justify-start items-center">
                            <div className="label-text pb-1 font-bold pe-2 !w-[100px]">DATE FROM</div>
                            <DateInput 
                                className="w-full lg:!w-[264px]" 
                                placeholderText="Date from" 
                                onChange={(date) => setSelected({ ...selected, date_from: moment(date).format("YYYY-MM-DD")})}
                                selected={ selected?.date_from ? moment(selected?.date_from).toDate() : null }
                            />

                            <div className="mt-3 lg:mt-0 lg:px-2 pb-1 label-text font-bold !w-[85px]">DATE TO</div>
                            <DateInput 
                                className="w-full lg:!w-[263px]" 
                                placeholderText="Date To" 
                                onChange={(date) => setSelected({ ...selected, date_end: moment(date).format("YYYY-MM-DD")})}
                                selected={ selected?.date_end ? moment(selected?.date_end).toDate() : null }
                            />
                        </div>
                    </div>
                </section>
            </Card.Body>
        </Card>
    </section>
  )
}