// Form
import * as yup from "yup"

export const validationSchema = yup.object().shape({
  _balance: yup.number().defined(),
  amount: yup.number().label("Payment Amount").required().max(yup.ref("_balance")),
  coa_id: yup.string().label("Account").required(),
  memo: yup.string().label("Memo").required(),
  no_cheque: yup.string().label("Field").required(),
  transaction_date: yup.string().label("Receive Date").required(),
  trx_code: yup.string().defined(),
  vendor_id: yup.string().defined()
})

export type FormType = yup.InferType<typeof validationSchema>