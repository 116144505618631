// React
import { Fragment } from "react"

// Components
import { BaseTable, CommandButton, Modal } from "components"
import { Freight, Product } from "./components"

// Form
import { useFormContext, useWatch } from "react-hook-form"

// Types
import type { FormType } from "../../utils"

// Utils
import { useToggle } from "utils"
import { useCoaByCode } from "./utils"

export function JournalModals(): JSX.Element {
  // Hooks
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <CommandButton
        actiontype="journal"
        onClick={toggle}
      />

      <Modal isOpen={isActive} title="JOURNAL" closeModal={toggle} size="full">
        <Modal.Body>
          <JournalTable />
        </Modal.Body>
      </Modal>
    </Fragment>
  )
}

function JournalTable() {
  // Hooks
  const { data } = useCoaByCode()

  // Form
  const { control } = useFormContext<FormType>()
  const transaction_date = useWatch({
    control,
    name: "transaction_date"
  })
  const trx_code = useWatch({
    control,
    name: "trx_code"
  })

  return (
    <BaseTable>
      <thead>
        <tr>
          <th>DATE</th>
          <th>IDM</th>
          <th>ACCOUNT</th>
          <th>DEBET</th>
          <th>CREDIT</th>
        </tr>
      </thead>

      <tbody>
        <Product
          tracing={data.tracing}
          transaction_date={transaction_date}
          trx_code={trx_code}
        />

        <Freight
          freight={data.freight}
          tracing={data.tracing}
          transaction_date={transaction_date}
          trx_code={trx_code}
        />
      </tbody>
    </BaseTable>
  )
}