// React
import { Fragment, useState } from "react"

// Components
import { ActionButton, Card, ImageGallery } from "components"

export function FileList<T>(props: {
  error: string | undefined
  items: T[]
  label: string
  onDelete: (value: T, key: number) => void
}) {
  // Hooks
  const [activeIndex, setIndex] = useState<number | null>(null)

  return (
    <Fragment>
      {props.items.map((item, key) => {
        return (
          <section key={key} className="w-full md:max-w-[300px] my-3">
            <Card>
              <Card.Body className="flex flex-row justify-between">
                <div
                  className="truncate cursor-pointer"
                  onClick={() => setIndex(key)}
                >
                  {props.label}-{(key + 1).toString().padStart(2, "0")}
                </div>

                <ActionButton.Delete onClick={() => props.onDelete(item, key)} />
              </Card.Body>
            </Card>
          </section>
        )
      })}

      {props.error && (
        <label className="label">
          <span className="label-text-alt font-bold text-error">{props.error}</span>
        </label>
      )}

      {activeIndex != null && (
        <ImageGallery
          isOpen
          activeIndex={activeIndex}
          onClose={() => setIndex(null)}
          images={props.items.map(item => {
            return { src: `${process.env.REACT_APP_BASE_URL_API}/getimage/?imgpath=${item}` }
          })}
        />
      )}
    </Fragment>
  )
}