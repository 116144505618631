// Components
import { Button, Modal } from "components"
import { Detail } from "./Detail"

// Third-Party Libraries
import toast from "react-hot-toast"

// Types
import type { ErrorFetch, SuccessFetch } from "types"

// Utils
import { useApi, useToggle } from "utils"

export function DeleteModal(params: {
  deposit_vendor_id: string
  onSuccess: () => void
  toggle: () => void
}) {
  // Hooks
  const api = useApi()
  const { isActive, setActive } = useToggle(false)

  const onDelete = (): void => {
    setActive(true)

    toast.promise(
      api.delete(`/depositvendor/deletedeposit?deposit_vendor_id=${params.deposit_vendor_id}`),
      {
        loading: "Loading...",
        success: (res: SuccessFetch<{ message: string }>) => res.data.message,
        error: (err: ErrorFetch<{ message: string }>) => err.response.data.message
      }
    ).then(() => {
      params.toggle()
      params.onSuccess()
    }).catch(() => {
    }).finally(() => {
      setActive(false)
    })
  }

  return (
    <Modal
      isOpen
      title="DELETE DEPOSIT VENDOR"
      closeModal={params.toggle}
    >
      <Modal.Body className="flex flex-col gap-3">
        <div className="text-error">Are you sure want to delete this data?</div>

        <Detail deposit_vendor_id={params.deposit_vendor_id} />
      </Modal.Body>

      <Modal.Footer>
        <Button
          type="button"
          color="error"
          loading={isActive ? "true" : undefined}
          onClick={onDelete}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  )
}