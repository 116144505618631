import { useEffect, useState } from "react"
import type { SelectOptionType, SuccessFetch } from "../../../../../../../../types"
import { useApi } from "../../../../../../../../utils"

export function useJobPosition() {
  const api = useApi()
  const [dataJobPosition, setData] = useState<SelectOptionType[]>([])
  const [isLoadingJobPosition, setLoading] = useState(true)

  useEffect(() => {
    api.get("/jobposition/")
    .then((res: SuccessFetch<{
      payload: {
        job_position_id: number
        job_position_name: string
      }[]
    }>) => { setData(res.data.payload.map(item => { return { label: item.job_position_name, value: item.job_position_id.toString() }}))})
    .catch(() => setData([]))
    .finally(() => setLoading(false))
  
    return () => {
      setData([])
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [])

  return { dataJobPosition, isLoadingJobPosition }
}