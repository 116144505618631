import * as yup from "yup"

const baseValidation = {
    transaction_date: yup.string().label("Transaction Date").required(),
    location_id: yup.string().label("Location").required(),
    product_id: yup.string().label("Product Item").required(),
    mr_unit_id: yup.string().label("Unit").required(),
    av_price_unit: yup.string().label("Price Unit").defined().notOneOf(['0'],'Price unit entered is zero'),
    soh: yup.string().label("Stock").required().notOneOf(['0'],'Stock entered is zero'),
}

export const validationSchema = yup.object().shape(baseValidation)

export type FormType = yup.InferType<typeof validationSchema>