import { Button, Modal } from "components"
import { useToggle } from "utils"

type ModalReconcileConfirmationProps = {
  modal: ReturnType<typeof useToggle>
  onConfirm: () => void
  onPrint: () => void
}

export const ModalReconcileConfirmation = ({
  modal,
  onConfirm,
  onPrint
}: ModalReconcileConfirmationProps) => {
  return (
    <Modal
      title="Account Reconciliation Confirmation"
      isOpen={modal.isActive}
      closeModal={modal.toggle}>
      <Modal.Body>
        <p>Status: Reconciliation Successful</p>

        <p className="mt-2">Action Required:</p>
        <p>
          If you wish to document the transactions scheduled for reconciliation,
          click "Print Report" to generate a detailed report. <br />
          Once the report is ready, proceed to click "Reconcile" again.
        </p>

        <p className="mt-2">Important Note:</p>
        <p>
          Upon clicking "Reconcile", all transactions marked as cleared will be
          officially reconciled. <br />
          Subsequently, during future reconciliation processes, <br />
          these transactions will not be presented for review. Ensure accuracy
          before proceeding.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex gap-2 justify-end">
          <Button color="primary" onClick={modal.toggle}>
            CANCEL
          </Button>

          <Button color="primary" onClick={onPrint}>
            PRINT REPORT
          </Button>

          <Button color="primary" onClick={onConfirm}>
            CONTINUE
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
