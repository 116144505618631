import { Input } from "components";

type StockAnalysisProps = { label: string; value: string | number; textError?: boolean; withInputField?: boolean };

export const StockAnalysis = ({ label, value, textError, withInputField }: StockAnalysisProps) => {

    return (
        <div className="flex mt-2">
            <div className={`w-[200px] ${withInputField ? "pt-[28px]" : ""}`}>
                <div className="text-right">{label}</div>
                <hr />
            </div>
            <div className="ml-5 w-[200px]">
                {withInputField 
                    ? <Input className="w-[150px] lg:w-full" />
                    : <><div className={textError ? "text-error" : ""}><b>{value}</b></div><hr /></>
                }
            </div>
        </div>
    )
}