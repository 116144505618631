// Components
import { Button } from "components"

// Icons
import { TbRepeat } from "react-icons/tb"

export function RecurringButton() {
  return (
    <section className="mt-auto">
      <Button>
        <TbRepeat /> SAVE AS RECURRING
      </Button>
    </section>
  )
}