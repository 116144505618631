// React
import { createContext } from "react"

// Types
import type { ProductType } from "../types"

export const ProductContext = createContext<{
  data: ProductType[]
  isLoading: boolean
}>({
  data: [],
  isLoading: false
})