// Form
import * as yup from "yup"

export const detailValidationSchema = yup.object().shape({
  qty_order: yup.number().defined(),
  qty_multiply: yup.number().defined(),
  project_id: yup.string().defined(),
  project_name: yup.string().defined(),
  mr_unit_id: yup.string().defined(),
  product_id: yup.string().label("Item Order").required(),
  discount: yup.number().label("Discount").required(),
  total: yup.number().label("Total").required(),
  tax_id: yup.string().label("Tax").required(),
  quantity_bill: yup.number().label("Quantity").required().min(1),
  quantity_unit: yup.number().label("Quantity").required(),
  price_bill: yup.number().label("Price").required()
})
export const validationSchema = yup.object().shape({
  purchase_order_id: yup.string().defined(),
  reminder: yup.object().nullable(),
  trx_code: yup.string().defined(),
  delivery_no: yup.string().defined(),
  vendor_id: yup.string().label("Supplier").required(),
  location_id: yup.string().label("Location").required(),
  address: yup.string().label("Address").required(),
  memo: yup.string().label("Memo").required(),
  transaction_date: yup.string().label("Date").required(),
  referensi: yup.string().label("Referensi").required(),
  payment_term: yup.string().label("Trading Term").required(),
  tax_inclusive: yup.boolean().label("Tax Inclusive").required(),
  promise_date: yup.string().label("Promised Date").required(),
  freight: yup.number().label("Freight").required(),
  probildetail: yup.array().label("Detail").min(1).required().of(detailValidationSchema)
})

export type FormType = yup.InferType<typeof validationSchema>
export type DetailFormType = yup.InferType<typeof detailValidationSchema>