// Components
import { Card, CommandButton, HeaderMenu, Loading } from "components"
import { EditSection, ListSection } from "./components"

// Utils
import { useList } from "./utils"

export default function OpeningBalance() {
  return (
    <section className="container my-5">
      <HeaderMenu title="DATA ENTRY | OPENING BALANCE" />
      
      <Card>
        <Card.Body className="!text-xl font-bold">
          <TableSection />
        </Card.Body>
      </Card>

      <section className="mt-2">
        <Card>
          <Card.Body className="flex flex-col gap-5">
            <section className="flex flex-wrap justify-between items-center gap-3">
              <section className="flex flex-wrap gap-3">
                <CommandButton actiontype="help" />
                <CommandButton actiontype="print" />
                <CommandButton actiontype="email" />
                <CommandButton actiontype="export" />
              </section>
            </section>
          </Card.Body>
        </Card>
      </section>
    </section>
  )
}

function TableSection() {
  // Hooks
  const { data, date, isEdit, isLoading, setData, setEdit } = useList()

  if (isLoading) {
    return <Loading loading={isLoading} />
  } else if (isEdit) {
    return (
      <EditSection
        date={date}
        list={data}
      />
    )
  } else {
    return (
      <ListSection
        date={date}
        list={data}
        onSuccess={(list) => {
          setData(list.map(item => {
            return {
              ...item,
              old_opening_balance: item.opening_balance
            }
          }))
          setEdit(true)
        }}
      />
    )
  }
}