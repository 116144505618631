// React
import { useContext } from "react"

// Components
import { BaseTable } from "components"

// Contexts
import { DataJournalContext } from "contexts"

// Form
import { useFormContext, useWatch } from "react-hook-form"

// Types
import type { DetailFormType } from "../PurchaseOrder/utils"

// Utils
import { convertNumber } from "utils"

export function Acc(params: {
  name: {
    detail: string
    freight: string
  }
}) {
  // Hooks
  const { tax_code } = useContext(DataJournalContext)

  // Form
  const { control } = useFormContext()
  const detail = useWatch({
    control,
    name: params.name.detail
  })
  const freight = useWatch({
    control,
    name: params.name.freight
  })

  // Functions
  const getSubtotalItem = (item: DetailFormType): number => {
    return item.total
  }

  // Vars
  const subtotal: number = detail.reduce((acc: number, item: DetailFormType): number => {
    return acc + getSubtotalItem(item)
  }, 0)
  const tax: number = detail.reduce((acc: number, item: DetailFormType): number => {
    // Vars
    const tax_item = tax_code.data.find(i => i.value === item.tax_id)

    return acc + (getSubtotalItem(item) * (tax_item?.rate ?? 0) / 100)
  }, 0)
  const total = subtotal + freight + tax

  return (
    <section className="flex flex-col lg:items-end gap-3">
      <BaseTable
        plain
        className="w-full min-w-[100px] md:max-w-[400px] text-right"
      >
        <tbody>
          <tr>
            <td className="w-[1px] whitespace-nowrap">SUBTOTAL</td>
            <td>{convertNumber(subtotal).intoCurrency}</td>
          </tr>
          <tr>
            <td className="w-[1px] whitespace-nowrap">FREIGHT</td>
            <td>{convertNumber(freight).intoCurrency}</td>
          </tr>
          <tr>
            <td className="w-[1px] whitespace-nowrap">TAX</td>
            <td>{convertNumber(tax).intoCurrency}</td>
          </tr>
          <tr className="font-bold">
            <td className="w-[1px] whitespace-nowrap">TOTAL AMOUNT</td>
            <td>{convertNumber(total).intoCurrency}</td>
          </tr>
          <tr className="text-info">
            <td className="w-[1px] whitespace-nowrap">PAYMENT</td>
            <td>0</td>
          </tr>
          <tr className="border-y-2 border-black text-lg font-bold">
            <td className="w-[1px] whitespace-nowrap">BALANCE</td>
            <td>{convertNumber(total).intoCurrency}</td>
          </tr>
        </tbody>
      </BaseTable>
    </section>
  )
}