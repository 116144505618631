// React
import { Fragment } from "react"

// Components
import { Button, ErrorText, Label, Modal, Select } from "components"
import { FormSection } from "./components"

// Form
import { Controller, useFormContext } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"

// Icons
import { LuFileEdit } from "react-icons/lu"

// Types
import type { FormType } from "pages/Finance/Transaction/utils"

// Utils
import { useToggle } from "utils"
import { useAccounts } from "./utils"

export function Accounts() {
  // Hooks
  const { data, isLoading } = useAccounts()

  // Form
  const { control, formState, setValue } = useFormContext<FormType>()

  return (
    <Controller
      control={control}
      name="coa_id"
      render={({ field, fieldState }) => (
        <section>
          <Label text="ACCOUNTS" error={Boolean(fieldState.error)} />
    
          <section className="flex gap-3">
            <Select
              className="grow"
              placeholder="Select Account"
              error={fieldState.error?.message}
              isLoading={isLoading}
              options={data}
              ref={field.ref}
              value={data.find(item => item.value === field.value) ?? null}
              onChange={item => {
                field.onChange(item?.value)
                setValue("amount", 0)
                setValue("coa_name", item?.label ?? "")
              }}
            />
    
            <ModalSection />
          </section>

          <ErrorMessage errors={formState.errors} name="expired_payment" render={({ message }) => <ErrorText text={message} />} />
          <ErrorMessage errors={formState.errors} name="type_payment" render={({ message }) => <ErrorText text={message} />} />
        </section>
      )}
    />
  )
}

function ModalSection() {
  // Hooks
  const { isActive, toggle } = useToggle(false)

  // Form
  const { clearErrors, getValues, setValue } = useFormContext<FormType>()

  const getValue = () => {
    return {
      type_payment: getValues("type_payment"),
      name_payment: getValues("name_payment"),
      number_payment: getValues("number_payment"),
      expired_payment: getValues("expired_payment"),
      authorization_payment: getValues("authorization_payment"),
      note_payment: getValues("note_payment")
    }
  }

  return (
    <Fragment>
      <Button color="primary" className="px-2" onClick={toggle}>
        <LuFileEdit size={24} />
      </Button>

      {isActive && (
        <Modal isOpen title="" size="xl" closeModal={toggle}>
          <Modal.Body className="flex flex-col gap-5">
            <section className="flex justify-center">
              <div className="text-xl text-center border-b-2 px-3 border-black dark:border-white font-bold">PAYMENT DETAIL</div>
            </section>

            <FormSection
              defaultValues={getValue()}
              onSubmit={item => {
                setValue("authorization_payment", item.authorization_payment)
                setValue("expired_payment", item.expired_payment)
                setValue("name_payment", item.name_payment)
                setValue("note_payment", item.note_payment)
                setValue("number_payment", item.number_payment)
                setValue("type_payment", item.type_payment)
                clearErrors(["expired_payment", "type_payment"])
                toggle()
              }}
            />
          </Modal.Body>
        </Modal>
      )}
    </Fragment>
  )
}