import { Input, Button } from "../../../../../../components"
import { Controller, useFormContext } from "react-hook-form"
import type { ProfileList } from "../../types"

interface MapProps {
  action?: "CREATE" | "UPDATE" | "DETAIL"
  onSubmit: (newState: any) => void
  isActiveSubmit?: boolean
  permission?: string
}

export const FormMap:React.FC<MapProps> = ({ action, onSubmit, isActiveSubmit, permission }) => {
  const { control, getValues, handleSubmit, formState } = useFormContext<ProfileList>()

  return (
    <div>
      <section>
        <div className="flex">
          <div className="w-full">
            <Controller
              control={control}
              name="link_map"
              render={({ field, fieldState }) => 
                <Input 
                  {...field} 
                  label="LINK MAP" 
                  className="w-full" 
                  error={control?._formValues?.link_map ? "" : fieldState.error?.message}
                  placeholder="Input link map" 
                  value={field.value ?? ""} 
                  disabled={action === "DETAIL"} 
                />
              }
            />
          </div>
        </div>
      </section>
      {action !== "DETAIL" &&
        <div className="flex justify-end">
          <Button 
            type="button"
            className="!none mt-5" 
            color="primary"
            loading={formState.isSubmitting ? "true" : undefined}
            onClick={handleSubmit(() => onSubmit(getValues()),(err)=> console.log(err))}
            disabled={isActiveSubmit}
            permission={permission}
          >
            SAVE
          </Button>
        </div>
      }
    </div>
  )
}