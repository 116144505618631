// React
import { Fragment } from "react"

// Components
import { ActionButton } from "components"
import { DeletePurchaseOrderModal } from "pages/Purchase/components"

// Types
import type { DetailDeleteType } from "pages/Purchase/types"

// Utils
import { useToggle } from "utils"

export function DeleteItem(params: {
  item: DetailDeleteType
  onSuccess: () => void
}) {
  // Hooks
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <ActionButton.Delete
        permission={"PR014"}
        className="text-error"
        onClick={toggle}
      />

      {isActive && (
        <DeletePurchaseOrderModal
          onSuccess={params.onSuccess}
          toggle={toggle}
          item={{
            purchase_order_id: params.item.purchase_order_id.toString(),
            transaction_date: params.item.transaction_date,
            trx_code: params.item.trx_code,
            vendor_name: params.item.vendor_name
          }}
        />
      )}
    </Fragment>
  )
}