import { useEffect, useState } from "react"
import type { SuccessFetch } from "../../../../../../types"
import type { ListBank } from "../../types"
import { useApi } from "../../../../../../utils"

export function useList() {
  const api = useApi()
  const [data, setData] = useState<ListBank[]>([])
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    const fetchData = () => api.get("/groupcoa/")

    fetchData().then((res: SuccessFetch<{
      payload: {
        group_coa_code: string
        group_coa_name: string
        group_coa_type: number,
        description: string,
      }[]
    }>) => {
      setData(res.data.payload)
    }).catch(() => {
      setData([])
    }).finally(() => {
      setLoading(false)
    })
  
    return () => {
      setData([])
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [])

  return { data, isLoading }
}