import { Button } from "components";
import { FaFileExcel } from "react-icons/fa6";
import ExcelJS from "exceljs";
import toast from "react-hot-toast";

interface StyleBodyType {
  size?: number,
  width?: number,
  bold?: boolean,
  alignment?: {
    horizontal?: string,
    vertical?: string
  },
  numberFormat?: string
}

interface StyleHeaderType {
  size?: number,
  alignment?: {
    horizontal?: string,
    vertical?: string
  },
  fill?: string
}

const applyFillStyleBody = (
  cellBody: any,
  style?: StyleBodyType
) => {
  cellBody.border = {
    top: { style: 'thin', color: { argb: '000000' } },
    left: { style: 'thin', color: { argb: '000000' } },
    bottom: { style: 'thin', color: { argb: '000000' } },
    right: { style: 'thin', color: { argb: '000000' } }
  }
  cellBody.font = {
    size: style?.size,
    bold: style?.bold
  }
  cellBody._column._worksheet.getColumn(cellBody._column.number).width = style?.width
  cellBody.alignment = {
    horizontal: style?.alignment?.horizontal,
    vertical: style?.alignment?.vertical
  }
  cellBody.numFmt = style?.numberFormat
}

const applyFillStyleHeader = (
  cell: any,
  styleHeader?: StyleHeaderType
) => {
  cell.fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: styleHeader?.fill ?? 'FFD700' },
  };
  cell.alignment = {
    vertical: styleHeader?.alignment?.vertical ?? 'middle',
    horizontal: styleHeader?.alignment?.horizontal ?? 'center',
    wrapText: true
  }
  cell.font = {
    size: styleHeader?.size ?? 13,
    bold: true,
  }
};

const applyFillStyleTitle = (cell: any) => {
  cell.alignment = {
    vertical: 'middle',
    horizontal: 'center',
    wrapText: true
  };
  cell.font = {
    bold: true,
    size: 20
  };
}

export const ExportExcel = (props: {
  dataList: any,
  title: string,
  fileName: string
}) => {

  const defaultValue = {
    size: 12,
    width: 20,
    bold: false,
    alignment: {
      horizontal: 'left',
      vertical: 'middle'
    },
    numberFormat: ''
  }

  const handleExport = () => {

    const dataExcelFiltered = props.dataList.filter((item: any) => item !== false);

    if (dataExcelFiltered) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Sheet 1");
      worksheet.views = [{ showGridLines: false }];

      let rowIndex = 4;
      worksheet.mergeCells(2, 2, 1, dataExcelFiltered.length + 2);

      const titleCell = worksheet.getCell(1, 2);
      titleCell.value = props.title;
      applyFillStyleTitle(titleCell)

      dataExcelFiltered.forEach((column: any, columnIndex: number) => {
        const { header, items, style, styleHeader } = column;

        // NoCell
        const noCell = worksheet.getCell(rowIndex, 2)
        noCell.value = 'No'
        applyFillStyleHeader(noCell)

        // DataHeader
        const headerCell = worksheet.getCell(rowIndex, columnIndex + 3);
        headerCell.value = header;
        applyFillStyleHeader(headerCell, styleHeader)

        items.forEach((item: any, itemIndex: number) => {

          // noCell
          const noCellItems = worksheet.getCell(rowIndex + itemIndex + 1, 2)
          noCellItems.value = itemIndex + 1
          applyFillStyleBody(noCellItems, {
            width: 10,
            alignment: {
              horizontal: 'center',
              vertical: 'middle'
            },
          });

          const cell = worksheet.getCell(rowIndex + itemIndex + 1, columnIndex + 3);
          cell.value = item;
          applyFillStyleBody(cell, style ?? defaultValue)
        });
      });

      workbook.xlsx.writeBuffer().then(buffer => {
        const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${props.fileName}.xlsx`;
        a.click();
        window.URL.revokeObjectURL(url);
      });
    } else {
      toast.error('Export Excel Gagal');
    }
  };

  return (
    <>
      <Button type="button" color="warning" onClick={() => handleExport()}>
        <FaFileExcel className="w-4 h-4" /> Export Excel
      </Button>
    </>
  )
}