export const usePermission = (permission: string[]) => { 
  const dataToken = localStorage?.getItem("access")
  let getToken = dataToken!?.split(",")

  let canModule = false;
  let canMenu = false;
  let canFeature = false;

  let canCreate = false;
  let canRead = false;
  let canUpdate = false;
  let canDelete = false;
  let canSoftDelete = false;

  const checkPermission = (permission: string) => {
    const tabPermission = Boolean(getToken?.some((val: any) => val?.substring(0, 4) === permission))
    return tabPermission
  }

  const getPermissionAction = (permission: string) => {
    return {
      canModule: Boolean(getToken?.some((val: any) => val?.charAt(0) === permission)),
      canMenu: Boolean(getToken?.some((val: any) => val?.charAt(1) === permission)),
      canFeature: Boolean(getToken?.some((val: any) => val?.substring(2, 4) === permission)),

      canCreate: Boolean(getToken?.some((val: any) => val === permission + "1")),
      canRead: Boolean(getToken?.some((val: any) => val === permission + "2")),
      canUpdate: Boolean(getToken?.some((val: any) => val === permission + "3")),
      canDelete: Boolean(getToken?.some((val: any) => val === permission + "4")),
      canSoftDelete: Boolean(getToken?.some((val: any) => val === permission + "5"))
    };
  };

  if (typeof permission === "string") {
    const permissionAction = getPermissionAction(permission);

    canModule = permissionAction.canModule;
    canMenu = permissionAction.canMenu;
    canFeature = permissionAction.canFeature;

    canCreate = permissionAction.canCreate;
    canRead = permissionAction.canRead;
    canUpdate = permissionAction.canUpdate;
    canDelete = permissionAction.canDelete;
    canSoftDelete = permissionAction.canSoftDelete;
  } else {
    permission.forEach((val: any) => {
      const permissionAction = getPermissionAction(val);

      canModule = canModule ? true : permissionAction.canModule;
      canMenu = canMenu ? true : permissionAction.canMenu;
      canFeature = canFeature ? true : permissionAction.canFeature;

      canCreate = canCreate ? true : permissionAction.canCreate;
      canRead = canRead ? true : permissionAction.canRead;
      canUpdate = canUpdate ? true : permissionAction.canUpdate;
      canDelete = canDelete ? true : permissionAction.canDelete;
      canSoftDelete = canSoftDelete ? true : permissionAction.canSoftDelete;
    });
  }

  return { canCreate, canRead, canUpdate, canDelete, canSoftDelete, canModule, canMenu, canFeature, checkPermission };
};
