// React
import { Fragment, useContext } from "react"

// Components
import { Card, CommandButton, Loading } from "components"
import { DeleteModal, InputSection } from "./components"

// Contexts
import { DataContext, ParamsContext } from "./contexts"

// Form
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

// Third-Party Libraries
import toast from "react-hot-toast"
import { useNavigate, useParams } from "react-router-dom"

// Utils
import { useApi, useToggle } from "utils"
import { type FormType, useDetailReject, validationSchema } from "./utils"

export default function Edit(): JSX.Element {
  // Hooks
  const { bill_id, reject_id } = useParams()
  const { data, isLoading } = useDetailReject(bill_id!, reject_id!)

  if (isLoading || !data) {
    return (
      <Loading
        errorText="No data available"
        loading={isLoading}
      />
    )
  }

  return (
    <DataContext.Provider value={data}>
      <FormSection />
    </DataContext.Provider>
  )
}

function FormSection() {
  // Hooks
  const api = useApi()
  const navigate = useNavigate()
  const { bill_id, reject_id } = useParams()
  const { data, detail_reject } = useContext(DataContext)

  // Form
  const defaultValues: FormType = {
    trx_code: data.trx_code_reject,
    transaction_date: data.transaction_date,
    memo: data.memo,
    referensi: data.referensi,
    prorejectdetail: detail_reject
  }
  const methods = useForm<FormType>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })
  const onSubmit = (value: FormType) => {
    // Vars
    const finalValue = {
      ...value,
      bill_id,
      reject_id,
      payment_term_vendor_id: data.payment_term_vendor_id,
      vendor_id: data.vendor_id,
      location_id: data.location_id,
      address: data.address,
      prorejectdetail: value.prorejectdetail.map(item => {
        // Vars
        const quantity_reject = item.quantity_reject
        const quantity_unit: number = quantity_reject * item.quantity_rate
        const subtotal: number = quantity_reject * item.price_reject
        const total: number = subtotal - (item.discount / 100 * subtotal)
        const total_tax_paid: number = item.tax_rate / 100 * total

        return {
          quantity_reject,
          quantity_unit,
          total,
          total_tax_paid,
          product_id: item.product_id,
          price_reject: item.price_reject,
          discount: item.discount,
          tax_id: item.tax_id,
          tax_rate: item.tax_rate
        }
      })
    }

    return new Promise<void>((resolve) => {
      toast.promise(
        api.post("/proreject/add", finalValue),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.detail?.message ?? err.response.data.message
        }
      ).then(() => {
        navigate(-1)
      }).catch(() => {}).finally(resolve)
    })
  }

  return (
    <FormProvider {...methods}>
      <ParamsContext.Provider value={{}}>
        <InputSection
          actionbutton={(
            <Card>
              <Card.Body className="flex flex-col gap-5">
                <section className="flex flex-wrap justify-between gap-3">
                  <section className="flex flex-wrap gap-3">
                    <CommandButton actiontype="help" />
                    <CommandButton actiontype="print" />
                    <CommandButton actiontype="email" />
                    <CommandButton actiontype="export" />
                    <CommandButton actiontype="journal" />
                    <CommandButton actiontype="attachment" />
                  </section>

                  <section className="flex flex-wrap gap-3">
                    {data.approve_status === "Pending" && <Delete />}
                    
                    {data.approve_status !== "Approved" && (
                      <CommandButton
                        actiontype="save"
                        loading={methods.formState.isSubmitting ? "true" : undefined}
                        onClick={methods.handleSubmit(onSubmit)}
                      />
                    )}
                  </section>
                </section>
              </Card.Body>
            </Card>
          )}
        />
      </ParamsContext.Provider>
    </FormProvider>
  )
}

function Delete(): JSX.Element {
  // Hooks
  const navigate = useNavigate()
  const { bill_id, reject_id } = useParams()
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <CommandButton
        actiontype="delete"
        onClick={toggle}
      />

      {isActive && (
        <DeleteModal
          bill_id={bill_id!}
          reject_id={reject_id!}
          toggle={toggle}
          onSuccess={() => navigate(-1)}
        />
      )}
    </Fragment>
  )
}