import { ColumnConfigType } from "types";

export const defaultValue = (dataList: ColumnConfigType[]) => {
    const handphone = dataList?.find(item => item.column_code === 'vhp')?.column_status;
    const email = dataList.find(item => item.column_code === 'vemail')?.column_status;
    const cardLabel = dataList.find(item => item.column_code === 'vcardlab')?.column_status;
    const category = dataList.find(item => item.column_code === 'vcat')?.column_status;
    const priceLevel = dataList.find(item => item.column_code === 'vpricelev')?.column_status;
    const bank = dataList.find(item => item.column_code === 'vbank')?.column_status;
    const bankAccount = dataList.find(item => item.column_code === 'vbankac')?.column_status;
    const country = dataList.find(item => item.column_code === 'vcount')?.column_status;
    const province = dataList.find(item => item.column_code === 'vprov')?.column_status;
    const regency = dataList.find(item => item.column_code === 'vreg')?.column_status;
    const subdistrict = dataList.find(item => item.column_code === 'vsubdis')?.column_status;
    const village = dataList.find(item => item.column_code === 'vvil')?.column_status;
    const address = dataList.find(item => item.column_code === 'vadress')?.column_status;

    return {
        handphone,
        email,
        cardLabel,
        category,
        priceLevel,
        bank,
        bankAccount,
        country,
        province,
        regency,
        subdistrict,
        village,
        address
    };
};
