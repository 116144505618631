// React
import { createContext } from "react"

// Types
import type { BillDetail } from "../types"

export const DataContext = createContext<BillDetail>({
  detail: [],
  detail_reject: [],
  data: {
    address: "",
    delivery_no: "",
    payment_term: "",
    payment_term_vendor_id: "",
    tax_inclusive: false,
    trx_code: "",
    vendor_id: "",
    vendor_name: "",
    location_id: "",
    location_name: "",
    memo: "",
    referensi: "",
    transaction_date: "",
    trx_code_reject: "",
    approve_by_name: "",
    approve_status: "",
    reject_status: ""
  }
})