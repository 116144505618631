// React
import { Fragment } from "react"

// Components
import { HintList, Input, Label, Modal, NumberInput, Select, Textarea } from "../../../../../components"

// Form
import { Controller, useFormContext } from "react-hook-form"

// Types
import type { FormType } from "../types"

// Utils
import { useCoa, useType } from "../utils"

export function FormSection(props: {
  isDisabled?: boolean
}) {
  // Form
  const { control } = useFormContext<FormType>()

  return (
    <Modal.Body
      hintText={(
        <HintList
          title="Setting Up Tax Criteria"
          bottomList={[
            {
              label: "Button: Tutorial",
              list: [
                {
                  label: "Purpose",
                  value: "To save the tax criteria setup after entering all necessary details."
                },
                {
                  label: "Action",
                  value: "Click this button to store the information and apply the tax criteria to relevant transactions."
                },
              ]
            }
          ]}
          list={[
            {
              label: "Tax Code",
              list: [
                {
                  label: "Definition",
                  value: "A unique identifier or code assigned to the tax criteria."
                },
                {
                  label: "Purpose",
                  value: "Helps in easily referencing and applying tax criteria across the system."
                },
                {
                  label: "Example",
                  value: "TX-VAT-20 or TAX123."
                }
              ]
            },
            {
              label: "Tax Name",
              list: [
                {
                  label: "Definition",
                  value: "The name or title for the tax criteria."
                },
                {
                  label: "Purpose",
                  value: "Provides a clear and descriptive name for the tax that users can easily understand."
                },
                {
                  label: "Example",
                  value: "VAT 20%, State Sales Tax."
                },
              ]
            },
            {
              label: "Tax Type",
              list: [
                {
                  label: "Definition",
                  value: "The classification of the tax."
                },
                {
                  label: "Purpose",
                  value: "Determines the kind of tax being applied (e.g., sales tax, VAT, excise tax)."
                },
                {
                  label: "Options",
                  value: [
                    "Sales Tax",
                    "Value Added Tax (VAT)",
                    "Excise Tax",
                    "Service Tax"
                  ]
                },
              ]
            },
            {
              label: "Tax Rate",
              list: [
                {
                  label: "Definition",
                  value: "The percentage rate at which the tax is applied."
                },
                {
                  label: "Purpose",
                  value: "Specifies the amount of tax to be charged based on the transaction amount."
                },
                {
                  label: "Example",
                  value: "Enter as a percentage (e.g., 15%, 8.5%)."
                },
              ]
            },
            {
              label: "Chart of Account Collect",
              list: [
                {
                  label: "Definition",
                  value: "The account in the chart of accounts where collected tax amounts are recorded."
                },
                {
                  label: "Purpose",
                  value: "Ensures that collected tax revenue is properly accounted for."
                },
                {
                  label: "Example",
                  value: "Tax Collected - Sales Tax."
                },
              ]
            },
            {
              label: "Chart of Account Paid",
              list: [
                {
                  label: "Definition",
                  value: "The account in the chart of accounts where paid tax amounts are recorded."
                },
                {
                  label: "Purpose",
                  value: "Ensures that paid tax expenses are properly tracked."
                },
                {
                  label: "Example",
                  value: "Tax Expense - VAT."
                },
              ]
            },
            {
              label: "Note",
              list: [
                {
                  label: "Definition",
                  value: "Any additional information or remarks related to the tax criteria."
                },
                {
                  label: "Purpose",
                  value: "Provides context or specific details that might be relevant for users managing tax criteria."
                },
                {
                  label: "Example",
                  value: "Applicable only for online sales or Special rate for promotional items."
                },
              ]
            },
          ]}
        />
      )}
    >
      <table className="table">
        <tbody>
          <Controller
            control={control}
            name="tax_code"
            render={({ field, fieldState }) => (
              <tr>
                <td>
                  <Label
                    text="CODE"
                    error={Boolean(fieldState.error)}
                  />
                </td>

                <td>
                  <Input
                    {...field}
                    ref={null}
                    placeholder="Input Code"
                    disabled={props.isDisabled}
                    error={fieldState.error?.message}
                  />
                </td>
              </tr>
            )}
          />

          <Controller
            control={control}
            name="tax_name"
            render={({ field, fieldState }) => (
              <tr>
                <td>
                  <Label
                    text="NAME"
                    error={Boolean(fieldState.error)}
                  />
                </td>

                <td>
                  <Input
                    {...field}
                    ref={null}
                    placeholder="Input Name"
                    disabled={props.isDisabled}
                    error={fieldState.error?.message}
                  />
                </td>
              </tr>
            )}
          />

          <Type isDisabled={props.isDisabled} />

          <Controller
            control={control}
            name="rate"
            render={({ field, fieldState }) => (
              <tr>
                <td>
                  <Label
                    text="RATE (%)"
                    error={Boolean(fieldState.error)}
                  />
                </td>

                <td>
                  <NumberInput
                    placeholder="Input Rate (%)"
                    disabled={props.isDisabled}
                    error={fieldState.error?.message}
                    value={field.value}
                    onValueChange={value => field.onChange(value.floatValue ?? 0)}
                  />
                </td>
              </tr>
            )}
          />

          <Coa isDisabled={props.isDisabled} />

          <Controller
            control={control}
            name="note"
            render={({ field, fieldState }) => (
              <tr>
                <td>
                  <Label
                    text="NOTE"
                    error={Boolean(fieldState.error)}
                  />
                </td>

                <td>
                  <Textarea
                    {...field}
                    ref={null}
                    placeholder="Input Note"
                    disabled={props.isDisabled}
                    error={fieldState.error?.message}
                  />
                </td>
              </tr>
            )}
          />
        </tbody>
      </table>
    </Modal.Body>
  )
}

function Type(props: { isDisabled?: boolean }) {
  // Hooks
  const { data, isLoading } = useType()

  // Form
  const { control } = useFormContext<FormType>()

  return (
    <Controller
      control={control}
      name="type_tax_id"
      render={({ field, fieldState }) => (
        <tr>
          <td>
            <Label
              text="TYPE"
              error={Boolean(fieldState.error)}
            />
          </td>

          <td>
            <Select
              placeholder="Select Item"
              error={fieldState.error?.message}
              isDisabled={props.isDisabled}
              isLoading={isLoading}
              options={data}
              value={data.find(item => item.value === field.value)}
              onChange={e => field.onChange(e?.value)}
            />
          </td>
        </tr>
      )}
    />
  )
}

function Coa(props: { isDisabled?: boolean }) {
  // Hooks
  const { data, isLoading } = useCoa()

  // Form
  const { control } = useFormContext<FormType>()

  return (
    <Fragment>
      <Controller
        control={control}
        name="coa_collect_id"
        render={({ field, fieldState }) => (
          <tr>
            <td>
              <Label
                text="COA COLLECT"
                error={Boolean(fieldState.error)}
              />
            </td>

            <td>
              <Select
                placeholder="Select Item"
                error={fieldState.error?.message}
                isDisabled={props.isDisabled}
                isLoading={isLoading}
                options={data}
                value={data.find(item => item.value === field.value)}
                onChange={e => field.onChange(e?.value)}
              />
            </td>
          </tr>
        )}
      />

      <Controller
        control={control}
        name="coa_paid_id"
        render={({ field, fieldState }) => (
          <tr>
            <td>
              <Label
                text="COA PAID"
                error={Boolean(fieldState.error)}
              />
            </td>

            <td>
              <Select
                placeholder="Select Item"
                error={fieldState.error?.message}
                isDisabled={props.isDisabled}
                isLoading={isLoading}
                options={data}
                value={data.find(item => item.value === field.value)}
                onChange={e => field.onChange(e?.value)}
              />
            </td>
          </tr>
        )}
      />
    </Fragment>
  )
}