import { useEffect, useState } from "react"
import type { SuccessFetch } from "../../../../../../types"
import type { ProductList } from "../../types"
import { useApi } from "../../../../../../utils"

interface FilteredParams {
  page?: number
  keyword?: string
  limit?: number
  group_id?: string
  sort?: string
}
interface DataType {
  items: ProductList[]
  pagination_data: {
    total: number
    page: number
    size: number
    pages: number
  }
}
export function useList({ page, keyword, limit, group_id, sort }: FilteredParams) {
  const defaultValue = {
    items: [],
    pagination_data: {
      page: 0,
      pages: 0,
      size: 0,
      total: 0
    }
  }

  const api = useApi()
  const [data, setData] = useState<DataType>(defaultValue)
  const [isLoading, setLoading] = useState(true)

  const params: { [key: string]: number | string | undefined } = {}; 
  if (page) params.page = page;
  if (keyword) params.keyword = keyword;
  if (limit) params.limit = limit;
  if (group_id) params.group_id = group_id;
  if (sort) params.sort = sort;

  const fetchingData = () => api.get("/product/all", { params })
  const fetchData = () => {
    setLoading(true)

    fetchingData()
    .then((res: SuccessFetch<{ payload: DataType }>) => setData(res.data.payload))
    .catch(() => setData(defaultValue))
    .finally(() => setLoading(false))
  }
  const refetch = () => fetchData()

  useEffect(() => {
    refetch()

    return () => {
      setData(defaultValue)
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    fetchData()

    return () => {
      setData(defaultValue)
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [page, group_id, limit, sort, keyword])

  return { data, isLoading, refetch }
}