// React
import { useEffect, useState } from "react"

// Types
import type { SuccessFetch, TaxCodeList, TaxCodePayload } from "types"

// Utils
import { useApi } from "utils"

export function useTaxCode() {
  // Hooks
  const api = useApi()
  const [data, setData] = useState<TaxCodeList[]>([])
  const [isLoading, setLoading] = useState(true)
  const [isReloading, setReloading] = useState(false)

  useEffect(() => {
    refetch()
  
    return () => {
      setData([])
      setLoading(true)
      setReloading(false)
    }

    // eslint-disable-next-line
  }, [])

  const refetch = () => {
    setReloading(true)

    api.get("/taxcode/").then((res: SuccessFetch<{
      payload: TaxCodePayload[]
    }>) => {
      setData(res.data.payload.map(item => {
        return {
          label: `${item.tax_name} (${item.rate}%)`,
          rate: item.rate,
          tax_coa_collect: `${item.coa_collect_grp_code}-${item.coa_collect_coa_code} ${item.coa_collect_coa_name}`,
          tax_coa_collect_id: item.coa_collect_id,
          tax_coa_paid: `${item.coa_paid_grp_code}-${item.coa_paid_coa_code} ${item.coa_paid_coa_name}`,
          tax_coa_paid_id: item.coa_paid_id,
          value: item.tax_id.toString()
        }
      }))
    }).catch(() => {
      setData([])
    }).finally(() => {
      setLoading(false)
      setReloading(false)
    })
  }

  return { data, isLoading, isReloading, refetch }
}