import { BaseTable, Card, Loading } from "components"
import { UseFilter } from "pages/Finance/Journal/utils/hooks"
import { RegisterDateType } from "../types"
import { Item } from "./Item"
import { permissionProps } from "types"

type TableRegisterProps = {
  data: RegisterDateType[]
  isLoading?: boolean
  filter: UseFilter
  fetchList: () => void
  permission: permissionProps
}

export const TableRegister = ({
  data,
  isLoading,
  fetchList,
  permission
}: TableRegisterProps) => {
  return (
    <Card>
      <Card.Body>
        <BaseTable>
          <thead className="uppercase">
            <tr>
              <th rowSpan={2}>No</th>
              <th colSpan={2} rowSpan={2}>
                SO Id#
              </th>
              <th rowSpan={2}>Date</th>
              <th rowSpan={2}>Customer</th>
              <th colSpan={2}>Status</th>
              <th rowSpan={2}>
                Qty
                <br />
                Order
              </th>
              <th rowSpan={2}>
                Qty
                <br />
                Delivered
              </th>
              <th rowSpan={2}>
                Qty
                <br />
                Back Order
              </th>
              <th rowSpan={2}>
                Total
                <br />
                Sales
              </th>
              <th rowSpan={2}>
                Total
                <br />
                Payment
              </th>
              <th rowSpan={2}>
                Balance
                <br />
                Owing
              </th>
              <th rowSpan={2}>Action</th>
            </tr>

            <tr>
              <th>Approval</th>
              <th>Delivered</th>
            </tr>
          </thead>

          <tbody>
            {!data.length || isLoading ? (
              <tr>
                <td colSpan={14} className="text-center">
                  <Loading
                    loading={isLoading}
                    errorText={isLoading ? "" : "No data available"}
                  />
                </td>
              </tr>
            ) : (
              data.map((item, key) => (
                <Item
                  permission={permission}
                  item={item}
                  index={key}
                  onDeleteSucces={fetchList}
                />
              ))
            )}
          </tbody>
        </BaseTable>
      </Card.Body>
    </Card>
  )
}
