import { BalanceSheetNoneType } from "pages/Finance/Report/types"

export const generateNetProfit = (data: Array<BalanceSheetNoneType>) => {
  const accCoaIncome =
    data?.find((coa) => coa.group_coa_code === "04" && coa.parent_id === -1)
      ?.balance ?? 0

  const accCoaCostOfGoods =
    data?.find((coa) => coa.group_coa_code === "05" && coa.parent_id === -1)
      ?.balance ?? 0

  const accCoaExpense =
    data?.find((coa) => coa.group_coa_code === "06" && coa.parent_id === -1)
      ?.balance ?? 0

  const accCoaOtherIncome =
    data?.find((coa) => coa.group_coa_code === "08" && coa.parent_id === -1)
      ?.balance ?? 0

  const accCoaOtherExpense =
    data?.find((coa) => coa.group_coa_code === "09" && coa.parent_id === -1)
      ?.balance ?? 0

  const netProfit: BalanceSheetNoneType = {
    coa_id: -2,
    parent_id: -1,
    group_coa_id: "3",
    header_level: 1,
    coa_level: 1,
    group_coa_code: "00",
    coacode: "",
    coa_name: "NET PROFIT",
    normal_pos: 0,
    linked_code: "",
    balance:
      accCoaIncome -
      accCoaCostOfGoods -
      accCoaExpense +
      (accCoaOtherIncome - accCoaOtherExpense)
  }

  return data.concat([netProfit])
}
