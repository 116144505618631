// React
import { useContext } from "react"

// Components
import { Input } from "components"

// Contexts
import { DisabledContext } from "../../contexts"

// Form
import { Controller, useFormContext } from "react-hook-form"

// Types
import type { FormType } from "../utils"

export function Cheque(): JSX.Element {
  // Hooks
  const disabled = useContext(DisabledContext)

  // Form
  const { control } = useFormContext<FormType>()

  return (
    <Controller
      name="no_cheque"
      control={control}
      render={({ field, fieldState }) => (
        <Input
          {...field}
          label="CC | DEBIT NO | CHEQUE NO"
          disabled={disabled}
          error={fieldState.error?.message}
        />
      )}
    />
  )
}