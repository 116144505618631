// Types
import type { SuccessFetch } from "../../../../../../../types"

// Utils
import { useApi } from "../../../../../../../utils"

export function useCoaCode(parent_id?: number) {
  // Hooks
  const api = useApi()

  const generate = (group_coa_id: string) => {
    return new Promise<string>((resolve) => {
      api.get("/coa/suggestion", {
        params: {
          group_id: group_coa_id,
          parent_id: parent_id
        }
      }).then((res: SuccessFetch<{
        payload: { suggest_num: string }
      }>) => {
        resolve(res.data.payload.suggest_num)
      }).catch(() => {
        resolve("")
      })
    })
  }

  return { generate }
}