import { Fragment } from "react"
import { Button } from "../../../../components"
import { CreateModal } from "./CreateModal"
import { TbChevronRight } from "react-icons/tb"
import { useToggle } from "../../../../utils"

export function Create(props: { onSuccess: () => void; permission?: boolean }) { 
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <Button permission={"AR011"} color="primary" onClick={toggle}>
        CREATE NEW <TbChevronRight />
      </Button>

      {isActive && <CreateModal parent_level={1} toggle={toggle} onSuccess={props.onSuccess} />}
    </Fragment>
  )
}