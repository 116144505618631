import { convertNumber } from "utils";

interface FotherSectionProps { data?: any }

export const FotherSection = ({ data }: FotherSectionProps) => {
  const getTotal = data.reduce((acc: any, current: any) => {
    const pos = current.position;
    const amount = parseFloat(current.amount);
  
    if (!acc[pos]) { acc[pos] = 0 }
  
    acc[pos] += amount;
  
    return acc;
  }, {});

  const getTotalAccumulation = getTotal[1] - getTotal[2]

  return (
    <div className="mt-5 label-text flex justify-end text-right">
      <div>
        <div>SUMMARY DEBIT</div>
        <div className="text-error">SUMMARY CREDIT</div>
        <div>NET CHANGE</div>
      </div>
      <div className="mx-2">
        <div>:</div>
        <div className="text-error">:</div>
        <div>:</div>
      </div>
      <div>
        <div>{getTotal[1] ? convertNumber(getTotal?.[1])?.intoCurrency : "0"}</div>
        <div className="text-error">{getTotal[2] ? convertNumber(getTotal?.[2])?.intoCurrency : "0"}</div>
        <div>{getTotalAccumulation ? convertNumber(getTotalAccumulation)?.intoCurrency : "0"}</div>
      </div>
    </div>
  )
}