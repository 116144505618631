// React
import { Fragment } from "react"

// Form
import { useFormContext, useWatch } from "react-hook-form"

// Third-Party Libraries
import moment from "moment"

// Types
import type { FormType } from "../../../utils"

// Utils
import { convertNumber } from "utils"

export function Freight(params: {
  freight: string
  tracing: string
  transaction_date: string
  trx_code: string
}): JSX.Element {
  // Form
  const { control } = useFormContext<FormType>()
  const freight = useWatch({
    control,
    name: "freight"
  })

  // Vars
  const freightData: {
    currency: string
    label: string
  } = {
    currency: convertNumber(freight).intoCurrency,
    label: "Freight"
  }

  if (freight) {
    return (
      <Fragment>
        <tr>
          <td>{moment(params.transaction_date).format("DD/MM/YYYY")}</td>
          <td>{params.trx_code}</td>
          <td>{params.freight}</td>
          <td>{freightData.currency}</td>
          <td></td>
        </tr>

        <tr>
          <td>{moment(params.transaction_date).format("DD/MM/YYYY")}</td>
          <td>{params.trx_code}</td>
          <td>{params.tracing}</td>
          <td></td>
          <td>{freightData.currency}</td>
        </tr>
      </Fragment>
    )
  }

  return <Fragment />
}