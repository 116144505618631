
// Third-Party Libraries
import axios from "axios"
import toast from "react-hot-toast"

// Utils
import { useHandleAuth } from "./useHandleAuth"

async function sendRefreshToken(refresh_token: string) {
  return fetch("/auth/refresh", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ refresh_token })
  })
  .then(response => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error("Failed to refresh token");
    }
  });
}

export function useApi() {
  // Vars
  const services = axios.create({ baseURL: process.env.REACT_APP_BASE_URL_API })

  // Hooks
  const { logout } = useHandleAuth()

  // ** Request Interceptor
  services.interceptors.request.use(
    config => {
      // ** Get token from localStorage
      const accessToken = localStorage?.getItem("token")
  
      // ** If token is present add it to request's Authorization Header
      if (accessToken) {
        // ** eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${accessToken}`
      }
      return config
    },
    error => Promise.reject(error)
  )

  // ** Add request/response interceptor
  services.interceptors.response.use(
    response => response,
    error => {
      // ** const { config, response: { status } } = error

      // Jika tidak memiliki refresh token
      const { response } = error
      // Jika memiliki refresh token
      // const { config, response } = error
      // const originalRequest = config

      // ** if (status === 401) {
      if (response?.status === 401) {
        const refresh_token = localStorage?.getItem("ref");

        if (refresh_token) {
          sendRefreshToken(refresh_token)
          .then(data => {
            localStorage?.setItem("token", data?.token)
            toast.success("Token refreshed successfully", { position: "top-right" })
          }) 
          .catch(() => {
            logout()
          });
        } else {
          logout()
        }
      }
      return Promise.reject(error)
    }
  )

  return services
}