// Types
import type { MenuType } from "../types"

export const analyticsMenu = {
  menu: [
    {
      label: "REGISTER",
      link: "/analytics/register", 
      // permission: "NR"
      permission: "AR" // BELUM
    },
    {
      label: "TRANSACTION",
      // link: "#",
      link: "/analytics/transaction",
      // permission: "NT"
      permission: "AR" // BELUM
    },
    {
      label: "REPORT",
      // link: "#",
      link: "/analytics/report", 
      // permission: "NP"
      permission: "AR" // BELUM
    },
    {
      label: "JOURNAL",
      link: "/analytics/journal",
      // permission: "NJ",
      permission: "AR" // BELUM
    },
    {
      label: "FIND",
      link: "/analytics/find",
      // permission: "NF"
      permission: "AR" // BELUM
    }
  ] as MenuType[]
}