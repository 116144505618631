import { Fragment, useState } from "react"
import { ActionButton, Button, Card, HeaderMenu, Loading } from "../../../../../../../../../components"
import { FormProvider, useForm } from "react-hook-form"
import type { AddressList, PersonalList, ProfileList } from "../../../../../types"
import { useLocation, useNavigate } from "react-router-dom"
import { useDetailProfileData, useDetailAddressData, useDetailMapData } from "../utils"
import { RenderProvider } from "../../../../../utils"
import { Tab } from "./Tabs"
import { FormPersonalData } from "../../../../FormSection/FormPersonalData"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useApi } from "../../../../../../../../../utils"
import toast from "react-hot-toast"
import { FormProfile } from "../../../../FormSection/FormProfile"
import { FormAddress } from "../../../../FormSection/FormAddress"
import { FormMap } from "../../../../FormSection/FormMap"

export function Update(props: { id: PersonalList; permission?: boolean }) {
  const navigate = useNavigate()
  return (
    <Fragment>
      <ActionButton.Update permission={"CR033"} className="join-item" onClick={() => navigate("/cards/transaction/create-new-vendor/update", { state: { dataId: props.id } })} />
    </Fragment>
  )
}
export function UpdatePage() {
  const navigate = useNavigate()
  const location = useLocation()
  const id = location?.state?.dataId?.vendor_id
  const data = location?.state?.dataId
  const tabs = ["PROFILE", "ADDRESS", "MAP"];

  const [activeTab, setActiveTab] = useState<number | undefined>(0);
  
  const { dataProfile, errorProfile, isLoadingProfile } = useDetailProfileData(id ? id : null)
  const { dataAddress, errorAddress, isLoadingAddress } = useDetailAddressData(id ? id : null)
  const { dataMap, errorMap, isLoadingMap } = useDetailMapData(id ? id : null)

  return (
    <RenderProvider>
      <section className="container my-5">
        <div className="mb-5">
          <Card>
            <Card.Body className="flex-row justify-between items-center">
              <HeaderMenu title="UPDATE VENDOR REGISTRATION" />
            </Card.Body>
          </Card>
        </div>
        <Card>
          <Card.Body className="flex-row justify-end items-center">
            <Button color="primary" onClick={() => navigate("/cards/register/all")}>
                BACK
            </Button>
          </Card.Body>
          <Card.Body>
            <div className="lg:flex">
              <div className="lg:w-[34%] mr-0.5">
                <button className="bg-[#3c3c3c] px-4 py-2 w-full mr-0.5 text-white text-[12px]"><b>PERSONAL DATA</b></button>
                <div className={`max-w-sm w-full lg:max-w-full lg:flex h-[1600px]`}>
                  <div className="w-full border border-[#3c3c3c] lg:border-[#3c3c3c] p-6">
                    <DataForm dataDefault={data!} setActiveTab={setActiveTab} />
                  </div>
                </div>
              </div>
              <div className="lg:w-[66%]">
                <Tab tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} className="text-[11px]" />
                <div className={`max-w-sm w-full lg:max-w-full lg:flex h-[1600px]`}>
                  <div className="w-full border border-[#3c3c3c] lg:border-[#3c3c3c] p-6 mr-0.5">
                    {activeTab === 0 
                      ? isLoadingProfile || errorProfile 
                        ? <Loading errorText={errorProfile} loading={isLoadingProfile} /> 
                        : <DataProfileForm dataProfile={dataProfile!} id={id} />
                      : ""
                    }
                    {activeTab === 1
                      ? isLoadingAddress || errorAddress 
                        ? <Loading errorText={errorAddress} loading={isLoadingAddress} /> 
                        : <DataAddressForm dataAddress={dataAddress!} id={id} />
                      : ""
                    }
                    {activeTab === 2
                      ? isLoadingMap || errorMap 
                        ? <Loading errorText={errorMap} loading={isLoadingMap} /> 
                        : <DataMapForm dataMap={dataMap!} id={id} />
                      : ""
                    }
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </section>
    </RenderProvider>
  )
}
function DataForm(props: {
  dataDefault: PersonalList
  setActiveTab: (newState: any) => void
}) {
  const api = useApi()
  const location = useLocation()

  const [fileValue, setFileValue] = useState<File | undefined>(undefined);

  type UpdatePersonalData = Pick<PersonalList, "vendor_code" | "vendor_Name" | "vendor_Foto">;

  const defaultValues:UpdatePersonalData = {
    vendor_code: props?.dataDefault?.vendor_code ?? "",
    vendor_Name: props?.dataDefault?.vendor_name ?? "",
    vendor_Foto: props?.dataDefault?.foto ?? "",
  }
  const validationSchema = yup.object().shape({ vendor_Name: yup.string().label("Vendor name").required() })
  const methods = useForm<UpdatePersonalData>({ 
    defaultValues,
    resolver: yupResolver(validationSchema)
  })
  const onSubmit = (value: PersonalList) => {
    const formData = new FormData();
    
    formData.append('vendor_code', value?.vendor_code!);
    formData.append('vendor_Name', value?.vendor_Name);
    if(value?.vendor_Foto){formData.append(fileValue ? 'vendor_Foto' : "", value?.vendor_Foto)}

    const params: { [key: string]: string | undefined } = {};
    if (location?.state?.dataId?.vendor_id) params.vendor_id = location?.state?.dataId?.vendor_id;

    return new Promise<void>((resolve) => {
      toast.promise(
        api.put("/vendor/edit", formData, { params }),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => {
            if (err.response && err.response.status === 422) {
              return err.response.data.message || "Bad Request";
            } else {
              return "An error occurred";
            }
          }
        }
      )
      .then(() => {
        props.setActiveTab(0)
      })
      .catch(() => {})
      .finally(resolve)
    })
  }

  return (
    <FormProvider {...methods}>
      {/* @ts-ignore */}
      <FormPersonalData action="UPDATE" onSubmit={onSubmit} setFileValue={setFileValue} />
    </FormProvider>
  )
}

interface ProfileProps { 
  dataProfile?: any 
  id: number
}
const DataProfileForm: React.FC<ProfileProps> = ({ dataProfile, id }) => {
  const api = useApi()

  const defaultValues = {
    handphone: dataProfile?.handphone ?? "",
    email: dataProfile?.email ?? "",
    website: dataProfile?.website ?? "",
    card_label:  dataProfile?.card_label ?? undefined,
    group_card: dataProfile?.group_card ?? "3",
    category_vendor_id: dataProfile?.category_vendor_id ?? undefined,
    source_from: dataProfile?.source_from ?? "",
    employee_id: dataProfile?.employee_id ?? undefined, 
    media: dataProfile?.media ?? "",
    price_level_id: dataProfile?.price_level_vendor_id ?? undefined,
    payment_term_id: dataProfile?.payment_term_vendor_id ?? undefined,
    max_balance: dataProfile?.max_balance ?? undefined,
    bank_id: dataProfile?.bank_id ?? undefined,
    bank_account: dataProfile?.bank_account ?? "",
    bank_beneficiary: dataProfile?.bank_beneficiary ?? "",
    bank_branch: dataProfile?.bank_branch ?? "",
  }
  const methods = useForm<ProfileList>({ defaultValues })
  const onSubmit = (value: ProfileList) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.put(`/vendor/updateprofile?vendor_id=${id}`, value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => {
            if (err.response && err.response.status === 422) {
              return err.response.data.message || "Bad Request";
            } else {
              return "An error occurred";
            }
          }
        }
      )
      .then(() => {})
      .catch(() => {})
      .finally(resolve)
    })
  }

  return (
    <FormProvider {...methods}>
      {/* @ts-ignore */}
      <FormProfile action="UPDATE" onSubmit={onSubmit} />
    </FormProvider>
  )
}

interface AddressProps { 
  dataAddress?: any 
  id: number
}
const DataAddressForm: React.FC<AddressProps> = ({ dataAddress, id }) => {
  const api = useApi()

  const defaultValues = {
    address_type_id: dataAddress?.[0]?.address_type_id ?? undefined,
    address_type_id_2: dataAddress?.[0]?.address_type_id_2 ?? undefined,
    country_id: dataAddress?.[0]?.country_id ?? undefined,
    country_id_2: dataAddress?.[0]?.country_id_2 ?? undefined,
    province_id: dataAddress?.[0]?.province_id ?? undefined,
    province_id_2: dataAddress?.[0]?.province_id_2 ?? undefined,
    regency_id: dataAddress?.[0]?.regency_id ?? undefined,
    regency_id_2: dataAddress?.[0]?.regency_id_2 ?? undefined,
    subdistrict_id: dataAddress?.[0]?.subdistrict_id ?? undefined,
    subdistrict_id_2: dataAddress?.[0]?.subdistrict_id_2 ?? undefined,
    village_id: dataAddress?.[0]?.village_id ?? undefined,
    village_id_2: dataAddress?.[0]?.village_id_2 ?? undefined,
    address: dataAddress?.[0]?.address ?? undefined,
    address_2: dataAddress?.[0]?.address_2 ?? undefined,
  }
  const methods = useForm<AddressList>({ defaultValues })
  const onSubmit = (value: AddressList) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.put(`/vendor/updateaddress?vendor_id=${id}`, value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => {
            if (err.response && err.response.status === 422) {
              return err.response.data.message || "Bad Request";
            } else {
              return "An error occurred";
            }
          }
        }
      )
      .then(() => {})
      .catch(() => {})
      .finally(resolve)
    })
  }

  return (
    <FormProvider {...methods}>
      {/* @ts-ignore */}
      <FormAddress action="UPDATE" onSubmit={onSubmit} />
    </FormProvider>
  )
}

interface MapProps { 
  dataMap?: any 
  id: number
}
const DataMapForm: React.FC<MapProps> = ({ dataMap, id }) => {
  const api = useApi()

  const defaultValues = { link_map: dataMap?.link_map ?? "" }
  const methods = useForm<ProfileList>({ defaultValues })
  const onSubmit = (value: ProfileList) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.put(`/vendor/updatealinkmap?vendor_id=${id}`, value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => {
            if (err.response && err.response.status === 422) {
              return err.response.data.message || "Bad Request";
            } else {
              return "An error occurred";
            }
          }
        }
      )
      .then(() => {})
      .catch(() => {})
      .finally(resolve)
    })
  }

  return (
    <FormProvider {...methods}>
      {/* @ts-ignore */}
      <FormMap action="UPDATE" onSubmit={onSubmit} />
    </FormProvider>
  )
}