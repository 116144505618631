// React
import { DetailedHTMLProps, TextareaHTMLAttributes, forwardRef } from "react"

interface Type extends DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
  error?: string
  label?: string
}

export const Textarea = forwardRef((props: Type, ref) => {
  return (
    <label className="form-control">
      {props.label && (
        <label className="label">
          <span className={`label-text font-bold ${props.error && "text-error"}`}>{props.label}</span>
        </label>
      )}

      <textarea
        {...props}
        // @ts-ignore
        ref={ref}
        className={`textarea textarea-bordered dark:border-gray-400 h-24 ${props.error && "!textarea-error"}`}
      />

      {props.error && (
        <label className="label">
          <span className={`label-text-alt font-bold ${props.error && "text-error"}`}>{props.error}</span>
        </label>
      )}
    </label>
  )
})