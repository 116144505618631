/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react"
import { SuccessFetch } from "types"
import { useApi } from "utils"
import { Product } from "../../types"

type useDropdownProductProps = { location_id?: number }

type Response = {
  payload: Product[]
}

export function useDropdownProduct({ location_id }: useDropdownProductProps) {
  const api = useApi()
  const [data, setData] = useState<Response["payload"]>([])
  const [isLoading, setLoading] = useState(false)

  const fetchData = () => {
    setLoading(true)

    const params = { location_id }

    api
      .get("/product/selling", { params })
      .then((res: SuccessFetch<Response>) => {
        const data = res.data.payload

        setData(data)
      })
      .catch(() => setData([]))
      .finally(() => setLoading(false))
  }

  const refetch = () => fetchData()

  useEffect(() => {
    if (location_id) {
      fetchData()
    }

    return () => {
      setData([])
      setLoading(false)
    }
  }, [location_id])

  return {
    data,
    isLoading,
    dropdown: data?.map((item) => ({
      ...item,
      label: `${item.product_barcode} - ${item.product_name}`,
      value: item.product_id.toString()
    })),
    refetch
  }
}
