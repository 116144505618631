// React
import { useContext } from "react"

// Components
import { ActionButton, ErrorText } from "components"
import { FormSection, Product } from "./components"

// Contexts
import { DataJournalContext } from "contexts"
import { DisabledContext } from "pages/Purchase/Transaction/contexts"
import { DataBillContext } from "../../contexts"

// Form
import { Controller, useFormContext } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"

// Types
import type { FormType } from "../../utils"

// Utils
import { convertNumber, useToggle } from "utils"

export function Detail(params: {
  index: number
  remove: (key: number) => void
}): JSX.Element {
  // Hooks
  const dataBill = useContext(DataBillContext)
  const disabled = useContext(DisabledContext)
  const { isActive, toggle } = useToggle(false)
  const { tax_code } = useContext(DataJournalContext)

  // Form
  const { control, formState, clearErrors, getValues, setValue } = useFormContext<FormType>()

  if (isActive) {
    return (
      <FormSection
        toggle={toggle}
        value={getValues(`probildetail.${params.index}`)}
        onSuccess={value => {
          setValue(`probildetail.${params.index}`, value)
          clearErrors(`probildetail.${params.index}`)
          toggle()
        }}
      />
    )
  }

  return (
    <Controller
      name={`probildetail.${params.index}`}
      control={control}
      render={({ field }) => {
        // Vars
        const tax_name: string | undefined = tax_code.data.find(i => i.value === field.value.tax_id)?.label

        return (
          <tr>
            <td>{field.value.qty_order}</td>

            <td>
              <div>{field.value.quantity_bill}</div>

              <ErrorMessage
                name={`probildetail.${params.index}.quantity_bill`}
                errors={formState.errors}
                render={({ message }) => <ErrorText text={message} />}
              />
            </td>

            <Product id={field.value.product_id} />
            <td className="text-right">{convertNumber(field.value.price_bill).intoCurrency}</td>
            <td>{convertNumber(field.value.discount).intoNormalAmount}%</td>
            <td className="text-right">{convertNumber(field.value.total).intoCurrency}</td>
            <td>{field.value.project_name}</td>
            <td>{tax_name}</td>

            <td>
              {!disabled && (
                <section className="flex justify-center">
                  <ActionButton.Update onClick={toggle} />

                  {dataBill.approval_status !== "Approved" && (
                    <ActionButton.Delete
                      className="text-error"
                      onClick={() => params.remove(params.index)}
                    />
                  )}
                </section>
              )}
            </td>
          </tr>
        )
      }}
    />
  )
}