import { Input, NumberInput } from "components"
import { SellingList } from "pages/Inventori/Transaction/AddNewItem/types"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { DisableField } from "./DisableField"
import { useState } from "react"
import { convertNumber } from "utils"

interface PriceLevelProps {
    action?: "CREATE" | "UPDATE" | "DETAIL"
    totalValueProduction: number 
}
export const PriceLevel = ({ action, totalValueProduction }: PriceLevelProps) => {
    const { control } = useFormContext<SellingList>()

    const [selectedValue, setSelectedValue] = useState<any>({ 
        wholesales_mark_up: 0,
        wholesales_profit: 0,
        distributor_mark_up: 0,
        distributor_profit: 0,
        factory_mark_up: 0,
        factory_profit: 0,
        online_mark_up: 0,
        online_profit: 0,
        special_arrangement_mark_up: 0,
        special_arrangement_profit: 0,
    });

    const price_retail = useWatch({control, name: "price_retail"})
    const wholesales_price = useWatch({control, name: "wholesales_price"})
    const distributor_price = useWatch({control, name: "distributor_price"})
    const factory_price = useWatch({control, name: "factory_price"})
    const online_price = useWatch({control, name: "online_price"})
    const special_arrangement_price = useWatch({control, name: "special_arrangement_price"})
    const overhead = useWatch({control,name: "overhead"})
    const salesTax = useWatch({control, name: "sales_tax"})
    const otherCahnges = useWatch({control, name: "other_changes"})

    const valueOverhead =  (overhead) ? totalValueProduction * parseFloat(String(overhead)) / 100 : 0
    const valueSalesTax = (salesTax) ? totalValueProduction * parseFloat(String(salesTax)) / 100 : 0
    const valueOtherChanges = (otherCahnges) ? totalValueProduction * parseFloat(String(otherCahnges)) / 100 : 0
    const calculateCOGM = (valueOverhead ?? 0) + (valueSalesTax ?? 0) + (valueOtherChanges ?? 0) + totalValueProduction

    const calculateValue = (props: { price?: any }) => {
        const calculateMarkUP = Math.round(((props.price - parseFloat(String(calculateCOGM)))/parseFloat(String(calculateCOGM))) * 100)
        const calculateProfit = (props.price || 0) - parseFloat(String(calculateCOGM))

        return {
            price: props?.price ?? "0",
            mark_up: props?.price ? calculateMarkUP : "0",
            profit: props?.price ? calculateProfit : "0"
        }
    }

    const inputClass = "my-[-4px] mx-[-8px]"
    const childClass = "border max-w-[140px]"
    const labelClass = "border text-[12px]"

    return (
        <div>
            <div>
                <table className="table border table-sm">
                    <thead>
                        <tr>
                            <th className="text-left">PRICE LEVEL</th>
                            <th className="text-left">PRICE</th>
                            <th className="text-left">G-MARK UP</th>
                            <th className="text-left">PROFIT</th> 
                        </tr>
                    </thead>
                    <tbody>
                        {/* RETAIL */}
                        <tr>
                            <td className={labelClass}><b>RETAIL</b></td>
                            <td className={childClass}>{action === "DETAIL" 
                                ? <Input inputClass={inputClass} value={calculateValue({ price: price_retail })?.price ? convertNumber(calculateValue({ price: price_retail })?.price)?.intoCurrency : 0} disabled />
                                : <DisableField className="my-[-4px] mx-[-8px] max-w-[131px]">{calculateValue({ price: price_retail })?.price ? convertNumber(calculateValue({ price: price_retail })?.price)?.intoCurrency : 0}</DisableField>
                            }</td>
                            <td className={childClass}>{action === "DETAIL" 
                                ? <Input inputClass={inputClass} value={`${calculateValue({ price: price_retail })?.mark_up} %`} disabled />
                                : <DisableField className="my-[-4px] mx-[-8px] max-w-[131px]">{`${calculateValue({ price: price_retail })?.mark_up} %`}</DisableField>
                            }</td>
                            <td className={childClass}>{action === "DETAIL" 
                                ? <Input inputClass={inputClass} value={convertNumber(Number(calculateValue({ price: price_retail })?.profit))?.intoCurrency} disabled />
                                : <DisableField className="my-[-4px] mx-[-8px] max-w-[131px]">{convertNumber(Number(calculateValue({ price: price_retail })?.profit))?.intoCurrency}</DisableField>
                            }</td>
                        </tr>
                        {/* WHOLESALE */}
                        <tr>
                            <td className={labelClass}><b>WHOLESALE</b></td>
                            <td className={childClass}>
                                <Controller
                                    control={control}
                                    name="wholesales_price"
                                    render={({ field, fieldState }) => 
                                        <NumberInput
                                            inputClass={inputClass} 
                                            decimalSeparator='.' 
                                            thousandSeparator=","
                                            value={field.value}
                                            error={fieldState.error?.message}
                                            onValueChange={value => {
                                                field.onChange(value.floatValue)
                                                setSelectedValue({ 
                                                    ...selectedValue, 
                                                    wholesales_mark_up: Math.round(((value.floatValue! - parseFloat(String(calculateCOGM)))/parseFloat(String(calculateCOGM))) * 100),
                                                    wholesales_profit: value.floatValue! - parseFloat(String(calculateCOGM))
                                                })
                                            }}
                                            disabled={action === "DETAIL"}
                                        />
                                    }
                                />
                            </td>
                            <td className={childClass}>{action === "DETAIL" ?
                                <Input 
                                    inputClass={inputClass} 
                                    value={`${selectedValue?.wholesales_mark_up === 0
                                        ? convertNumber(Number(calculateValue({ price: wholesales_price })?.mark_up))?.intoNormalAmount 
                                        : selectedValue?.wholesales_mark_up ? selectedValue?.wholesales_mark_up : "0"
                                    } %`} 
                                    disabled 
                                /> :
                                <DisableField className="my-[-4px] mx-[-8px] max-w-[131px]">
                                    {`${selectedValue?.wholesales_mark_up === 0
                                        ? convertNumber(Number(calculateValue({ price: wholesales_price })?.mark_up))?.intoNormalAmount
                                        : selectedValue?.wholesales_mark_up ? selectedValue?.wholesales_mark_up : "0"
                                    } %`} 
                                </DisableField>}
                            </td>
                            <td className={childClass}>{action === "DETAIL" ?
                                <Input 
                                    inputClass={inputClass}
                                    value={`${selectedValue?.wholesales_profit === 0
                                        ? convertNumber(Number(calculateValue({ price: wholesales_price })?.profit))?.intoCurrency
                                        : selectedValue?.wholesales_profit ? convertNumber(selectedValue?.wholesales_profit)?.intoCurrency : "0"
                                    }`} 
                                    disabled 
                                /> :
                                <DisableField className="my-[-4px] mx-[-8px] max-w-[131px]">
                                    {`${selectedValue?.wholesales_profit === 0
                                        ? convertNumber(Number(calculateValue({ price: wholesales_price })?.profit))?.intoCurrency
                                        : selectedValue?.wholesales_profit ? convertNumber(selectedValue?.wholesales_profit)?.intoCurrency : "0"
                                    }`} 
                                </DisableField>}
                            </td>
                        </tr>
                        {/* DISTRIBUTOR */}
                        <tr>
                            <td className={labelClass}><b>DISTRIBUTOR</b></td>
                            <td className={childClass}>
                                <Controller
                                    control={control}
                                    name="distributor_price"
                                    render={({ field, fieldState }) => 
                                        <NumberInput
                                            inputClass={inputClass} 
                                            decimalSeparator='.' 
                                            thousandSeparator=","
                                            value={field.value}
                                            error={fieldState.error?.message}
                                            onValueChange={value => {
                                                field.onChange(value.floatValue)
                                                setSelectedValue({ 
                                                    ...selectedValue, 
                                                    distributor_mark_up: Math.round(((value.floatValue! - parseFloat(String(calculateCOGM)))/parseFloat(String(calculateCOGM))) * 100),
                                                    distributor_profit: value.floatValue! - parseFloat(String(calculateCOGM))
                                                })
                                            }}
                                            disabled={action === "DETAIL"}
                                        />
                                    }
                                />
                            </td>
                            <td className={childClass}>{action === "DETAIL" ?
                                <Input 
                                    inputClass={inputClass} 
                                    value={`${selectedValue?.distributor_mark_up === 0
                                        ? convertNumber(Number(calculateValue({ price: distributor_price })?.mark_up))?.intoNormalAmount
                                        : selectedValue?.distributor_mark_up ? selectedValue?.distributor_mark_up : "0"
                                    } %`} 
                                    disabled 
                                /> :
                                <DisableField className="my-[-4px] mx-[-8px] max-w-[131px]">
                                    {`${selectedValue?.distributor_mark_up === 0
                                        ? convertNumber(Number(calculateValue({ price: distributor_price })?.mark_up))?.intoNormalAmount
                                        : selectedValue?.distributor_mark_up ? selectedValue?.distributor_mark_up : "0"
                                    } %`} 
                                </DisableField>}
                            </td>
                            <td className={childClass}>{action === "DETAIL" ?
                                <Input 
                                    inputClass={inputClass} 
                                    value={`${selectedValue?.distributor_profit === 0
                                        ? convertNumber(Number(calculateValue({ price: distributor_price })?.profit))?.intoCurrency
                                        : selectedValue?.distributor_profit ? convertNumber(selectedValue?.distributor_profit)?.intoCurrency : "0"
                                    }`} 
                                    disabled 
                                /> :
                                <DisableField className="my-[-4px] mx-[-8px] max-w-[131px]">
                                    {`${selectedValue?.distributor_profit === 0
                                        ? convertNumber(Number(calculateValue({ price: distributor_price })?.profit))?.intoCurrency
                                        : selectedValue?.distributor_profit ? convertNumber(selectedValue?.distributor_profit)?.intoCurrency : "0"
                                    }`} 
                                </DisableField>}
                            </td>
                        </tr>
                        {/* FACTORY */}
                        <tr>
                            <td className={labelClass}><b>FACTORY</b></td>
                            <td className={childClass}>
                                <Controller
                                    control={control}
                                    name="factory_price"
                                    render={({ field, fieldState }) => 
                                        <NumberInput
                                            inputClass={inputClass} 
                                            decimalSeparator='.' 
                                            thousandSeparator=","
                                            value={field.value}
                                            error={fieldState.error?.message}
                                            onValueChange={value => {
                                                field.onChange(value.floatValue)
                                                setSelectedValue({ 
                                                    ...selectedValue, 
                                                    factory_mark_up: Math.round(((value.floatValue! - parseFloat(String(calculateCOGM)))/parseFloat(String(calculateCOGM))) * 100),
                                                    factory_profit: value.floatValue! - parseFloat(String(calculateCOGM))
                                                })
                                            }}
                                            disabled={action === "DETAIL"}
                                        />
                                    }
                                />
                            </td>
                            <td className={childClass}>{action === "DETAIL" ?
                                <Input 
                                    inputClass={inputClass} 
                                    value={`${selectedValue?.factory_mark_up === 0
                                        ? convertNumber(Number(calculateValue({ price: factory_price })?.mark_up))?.intoNormalAmount
                                        : selectedValue?.factory_mark_up ? selectedValue?.factory_mark_up : "0"
                                    } %`} 
                                    disabled 
                                /> :
                                <DisableField className="my-[-4px] mx-[-8px] max-w-[131px]">
                                    {`${selectedValue?.factory_mark_up === 0
                                        ? convertNumber(Number(calculateValue({ price: factory_price })?.mark_up))?.intoNormalAmount
                                        : selectedValue?.factory_mark_up ? selectedValue?.factory_mark_up : "0"
                                    } %`} 
                                </DisableField>}
                            </td>
                            <td className={childClass}>{action === "DETAIL" ?
                                <Input 
                                    inputClass={inputClass} 
                                    value={`${selectedValue?.factory_profit === 0
                                        ? convertNumber(Number(calculateValue({ price: factory_price })?.profit))?.intoCurrency
                                        : selectedValue?.factory_profit ? convertNumber(selectedValue?.factory_profit)?.intoCurrency : "0"
                                    }`} 
                                    disabled 
                                /> :
                                <DisableField className="my-[-4px] mx-[-8px] max-w-[131px]">
                                    {`${selectedValue?.factory_profit === 0
                                        ? convertNumber(Number(calculateValue({ price: factory_price })?.profit))?.intoCurrency
                                        : selectedValue?.factory_profit ? convertNumber(selectedValue?.factory_profit)?.intoCurrency : "0"
                                    }`} 
                                </DisableField>}
                            </td>
                        </tr>
                        {/* ONLINE */}
                        <tr>
                            <td className={labelClass}><b>ONLINE</b></td>
                            <td className={childClass}>
                                <Controller
                                    control={control}
                                    name="online_price"
                                    render={({ field, fieldState }) => 
                                        <NumberInput
                                            inputClass={inputClass} 
                                            decimalSeparator='.' 
                                            thousandSeparator=","
                                            value={field.value}
                                            error={fieldState.error?.message}
                                            onValueChange={value => {
                                                field.onChange(value.floatValue)
                                                setSelectedValue({ 
                                                    ...selectedValue, 
                                                    online_mark_up: Math.round(((value.floatValue! - parseFloat(String(calculateCOGM)))/parseFloat(String(calculateCOGM))) * 100),
                                                    online_profit: value.floatValue! - parseFloat(String(calculateCOGM))
                                                })
                                            }}
                                            disabled={action === "DETAIL"}
                                        />
                                    }
                                />
                            </td>
                            <td className={childClass}>{action === "DETAIL" ?
                                <Input 
                                    inputClass={inputClass} 
                                    value={`${selectedValue?.online_mark_up === 0
                                        ? convertNumber(Number(calculateValue({ price: online_price })?.mark_up))?.intoNormalAmount
                                        : selectedValue?.online_mark_up ? selectedValue?.online_mark_up : "0"
                                    } %`} 
                                    disabled 
                                /> : 
                                <DisableField className="my-[-4px] mx-[-8px] max-w-[131px]">
                                    {`${selectedValue?.online_mark_up === 0
                                        ? convertNumber(Number(calculateValue({ price: online_price })?.mark_up))?.intoNormalAmount
                                        : selectedValue?.online_mark_up ? selectedValue?.online_mark_up : "0"
                                    } %`} 
                                </DisableField>}
                            </td>
                            <td className={childClass}>{action === "DETAIL" ?
                                <Input 
                                    inputClass={inputClass} 
                                    value={`${selectedValue?.online_profit === 0
                                        ? convertNumber(Number(calculateValue({ price: online_price })?.profit))?.intoCurrency
                                        : selectedValue?.online_profit ? convertNumber(selectedValue?.online_profit)?.intoCurrency : "0"
                                    }`} 
                                    disabled 
                                /> :
                                <DisableField className="my-[-4px] mx-[-8px] max-w-[131px]">
                                    {`${selectedValue?.online_profit === 0
                                        ? convertNumber(Number(calculateValue({ price: online_price })?.profit))?.intoCurrency
                                        : selectedValue?.online_profit ? convertNumber(selectedValue?.online_profit)?.intoCurrency : "0"
                                    }`} 
                                </DisableField>}
                            </td>
                        </tr>
                        {/* SPECIAL ARANGEMENT */}
                        <tr>
                            <td className={labelClass}><b>SPECIAL ARANGEMENT</b></td>
                            <td className={childClass}>
                                <Controller
                                    control={control}
                                    name="special_arrangement_price"
                                    render={({ field, fieldState }) => 
                                        <NumberInput
                                            inputClass={inputClass} 
                                            decimalSeparator='.' 
                                            thousandSeparator=","
                                            value={field.value}
                                            error={fieldState.error?.message}
                                            onValueChange={value => {
                                                field.onChange(value.floatValue)
                                                setSelectedValue({ 
                                                    ...selectedValue, 
                                                    special_arrangement_mark_up: Math.round(((value.floatValue! - parseFloat(String(calculateCOGM)))/parseFloat(String(calculateCOGM))) * 100),
                                                    special_arrangement_profit: value.floatValue! - parseFloat(String(calculateCOGM))
                                                })
                                            }}
                                            disabled={action === "DETAIL"}
                                        />
                                    }
                                />
                            </td>
                            <td className={childClass}>{action === "DETAIL" ?
                                <Input 
                                    inputClass={inputClass} 
                                    value={`${selectedValue?.special_arrangement_mark_up === 0
                                        ? convertNumber(Number(calculateValue({ price: special_arrangement_price })?.mark_up))?.intoNormalAmount
                                        : selectedValue?.special_arrangement_mark_up ? selectedValue?.special_arrangement_mark_up : "0"
                                    } %`} 
                                    disabled 
                                /> :
                                <DisableField className="my-[-4px] mx-[-8px] max-w-[131px]">
                                    {`${selectedValue?.special_arrangement_mark_up === 0
                                        ? convertNumber(Number(calculateValue({ price: special_arrangement_price })?.mark_up))?.intoNormalAmount
                                        : selectedValue?.special_arrangement_mark_up ? selectedValue?.special_arrangement_mark_up : "0"
                                    } %`} 
                                </DisableField>}
                            </td>
                            <td className={childClass}>{action === "DETAIL" ?
                                <Input 
                                    inputClass={inputClass} 
                                    value={`${selectedValue?.special_arrangement_profit === 0
                                        ? convertNumber(Number(calculateValue({ price: special_arrangement_price })?.profit))?.intoCurrency
                                        : selectedValue?.special_arrangement_profit ? convertNumber(selectedValue?.special_arrangement_profit)?.intoCurrency : "0"
                                    }`} 
                                    disabled 
                                /> :
                                <DisableField className="my-[-4px] mx-[-8px] max-w-[131px]">
                                    {`${selectedValue?.special_arrangement_profit === 0
                                        ? convertNumber(Number(calculateValue({ price: special_arrangement_price })?.profit))?.intoCurrency
                                        : selectedValue?.special_arrangement_profit ? convertNumber(selectedValue?.special_arrangement_profit)?.intoCurrency : "0"
                                    }`} 
                                </DisableField>}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

interface PriceLevelCreateProps {
    action?: "CREATE" | "UPDATE" | "DETAIL"
    totalValueProduction: number
    selectedValue?: any
    setSelectedValue: ( newState: any ) => void
}
export const PriceLevelCreate = ({ action, totalValueProduction, selectedValue, setSelectedValue }: PriceLevelCreateProps) => {
    const { control } = useFormContext<SellingList>()

    const price_retail = useWatch({control, name: "price_retail"})
    const overhead = useWatch({control,name: "overhead"})
    const salesTax = useWatch({control, name: "sales_tax"})
    const otherCahnges = useWatch({control, name: "other_changes"})

    const valueOverhead =  (overhead) ? totalValueProduction * parseFloat(String(overhead)) / 100 : 0
    const valueSalesTax = (salesTax) ? totalValueProduction * parseFloat(String(salesTax)) / 100 : 0
    const valueOtherChanges = (otherCahnges) ? totalValueProduction * parseFloat(String(otherCahnges)) / 100 : 0
    const calculateCOGM = (valueOverhead ?? 0) + (valueSalesTax ?? 0) + (valueOtherChanges ?? 0) + totalValueProduction

    const calculateValue = (props: { price?: any }) => {
        const calculateMarkUP = Math.round(((props.price - parseFloat(String(calculateCOGM)))/parseFloat(String(calculateCOGM))) * 100)
        const calculateProfit = (props.price || 0) - parseFloat(String(calculateCOGM))

        return {
            price: props?.price ?? "0",
            mark_up: props?.price ? calculateMarkUP : "0",
            profit: props?.price ? calculateProfit : "0"
        }
    }

    const inputClass = "my-[-4px] mx-[-8px]"
    const childClass = "border max-w-[140px]"
    const labelClass = "border text-[12px]"

    return (
        <div>
            <div>
                <table className="table border table-sm">
                    <thead>
                        <tr>
                            <th className="text-left">PRICE LEVEL</th>
                            <th className="text-left">PRICE</th>
                            <th className="text-left">G-MARK UP</th>
                            <th className="text-left">PROFIT</th> 
                        </tr>
                    </thead>
                    <tbody>
                        {/* RETAIL */}
                        <tr>
                            <td className={labelClass}><b>RETAIL</b></td>
                            <td className={childClass}>
                                <DisableField className="my-[-4px] mx-[-8px] max-w-[131px]">{calculateValue({ price: price_retail })?.price ? convertNumber(Number(calculateValue({ price: price_retail })?.price))?.intoCurrency : 0}</DisableField>
                            </td>
                            <td className={childClass}>
                                <DisableField className="my-[-4px] mx-[-8px] max-w-[131px]">{`${calculateValue({ price: price_retail })?.mark_up ? calculateValue({ price: price_retail })?.mark_up : "0"} %`}</DisableField>
                            </td>
                            <td className={childClass}>
                                <DisableField className="my-[-4px] mx-[-8px] max-w-[131px]">{convertNumber(Number(calculateValue({ price: price_retail })?.profit ? calculateValue({ price: price_retail })?.profit : 0))?.intoCurrency}</DisableField>
                            </td>
                        </tr>
                        {/* WHOLESALE */}
                        <tr>
                            <td className={labelClass}><b>WHOLESALE</b></td>
                            <td className={childClass}>
                                <Controller
                                    control={control}
                                    name="wholesales_price"
                                    render={({ field, fieldState }) => 
                                        <NumberInput
                                            inputClass={inputClass} 
                                            decimalSeparator='.' 
                                            thousandSeparator=","
                                            value={field.value ? field.value : 0}
                                            error={fieldState.error?.message}
                                            onValueChange={value => {
                                                field.onChange(value.floatValue)
                                                setSelectedValue({ 
                                                    ...selectedValue, 
                                                    wholesales_mark_up: Math.round(((value.floatValue! - parseFloat(String(calculateCOGM)))/parseFloat(String(calculateCOGM))) * 100),
                                                    wholesales_profit: value.floatValue! - parseFloat(String(calculateCOGM))
                                                })
                                            }}
                                            disabled={action === "DETAIL"}
                                        />
                                    }
                                />
                            </td>
                            <td className={childClass}>
                                <DisableField className="my-[-4px] mx-[-8px] max-w-[131px]">
                                    {`${selectedValue?.wholesales_mark_up ? selectedValue?.wholesales_mark_up : "0"} %`} 
                                </DisableField>
                            </td>
                            <td className={childClass}>
                                <DisableField className="my-[-4px] mx-[-8px] max-w-[131px]">
                                    {`${selectedValue?.wholesales_profit ? convertNumber(selectedValue?.wholesales_profit)?.intoCurrency : "0"}`} 
                                </DisableField>
                            </td>
                        </tr>
                        {/* DISTRIBUTOR */}
                        <tr>
                            <td className={labelClass}><b>DISTRIBUTOR</b></td>
                            <td className={childClass}>
                                <Controller
                                    control={control}
                                    name="distributor_price"
                                    render={({ field, fieldState }) => 
                                        <NumberInput
                                            inputClass={inputClass} 
                                            decimalSeparator='.' 
                                            thousandSeparator=","
                                            value={field.value ? field.value : 0}
                                            error={fieldState.error?.message}
                                            onValueChange={value => {
                                                field.onChange(value.floatValue)
                                                setSelectedValue({ 
                                                    ...selectedValue, 
                                                    distributor_mark_up: Math.round(((value.floatValue! - parseFloat(String(calculateCOGM)))/parseFloat(String(calculateCOGM))) * 100),
                                                    distributor_profit: value.floatValue! - parseFloat(String(calculateCOGM))
                                                })
                                            }}
                                            disabled={action === "DETAIL"}
                                        />
                                    }
                                />
                            </td>
                            <td className={childClass}>
                                <DisableField className="my-[-4px] mx-[-8px] max-w-[131px]">
                                    {`${selectedValue?.distributor_mark_up ? selectedValue?.distributor_mark_up : "0"} %`} 
                                </DisableField>
                            </td>
                            <td className={childClass}>
                                <DisableField className="my-[-4px] mx-[-8px] max-w-[131px]">
                                    {`${selectedValue?.distributor_profit ? convertNumber(selectedValue?.distributor_profit)?.intoCurrency : "0"}`} 
                                </DisableField>
                            </td>
                        </tr>
                        {/* FACTORY */}
                        <tr>
                            <td className={labelClass}><b>FACTORY</b></td>
                            <td className={childClass}>
                                <Controller
                                    control={control}
                                    name="factory_price"
                                    render={({ field, fieldState }) => 
                                        <NumberInput
                                            inputClass={inputClass} 
                                            decimalSeparator='.' 
                                            thousandSeparator=","
                                            value={field.value ? field.value : 0}
                                            error={fieldState.error?.message}
                                            onValueChange={value => {
                                                field.onChange(value.floatValue)
                                                setSelectedValue({ 
                                                    ...selectedValue, 
                                                    factory_mark_up: Math.round(((value.floatValue! - parseFloat(String(calculateCOGM)))/parseFloat(String(calculateCOGM))) * 100),
                                                    factory_profit: value.floatValue! - parseFloat(String(calculateCOGM))
                                                })
                                            }}
                                            disabled={action === "DETAIL"}
                                        />
                                    }
                                />
                            </td>
                            <td className={childClass}>
                                <DisableField className="my-[-4px] mx-[-8px] max-w-[131px]">
                                    {`${selectedValue?.factory_mark_up ? selectedValue?.factory_mark_up : "0"} %`} 
                                </DisableField>
                            </td>
                            <td className={childClass}>
                                <DisableField className="my-[-4px] mx-[-8px] max-w-[131px]">
                                    {`${selectedValue?.factory_profit ? convertNumber(selectedValue?.factory_profit)?.intoCurrency : "0"}`} 
                                </DisableField>
                            </td>
                        </tr>
                        {/* ONLINE */}
                        <tr>
                            <td className={labelClass}><b>ONLINE</b></td>
                            <td className={childClass}>
                                <Controller
                                    control={control}
                                    name="online_price"
                                    render={({ field, fieldState }) => 
                                        <NumberInput
                                            inputClass={inputClass} 
                                            decimalSeparator='.' 
                                            thousandSeparator=","
                                            value={field.value ? field.value : 0}
                                            error={fieldState.error?.message}
                                            onValueChange={value => {
                                                field.onChange(value.floatValue)
                                                setSelectedValue({ 
                                                    ...selectedValue, 
                                                    online_mark_up: Math.round(((value.floatValue! - parseFloat(String(calculateCOGM)))/parseFloat(String(calculateCOGM))) * 100),
                                                    online_profit: value.floatValue! - parseFloat(String(calculateCOGM))
                                                })
                                            }}
                                            disabled={action === "DETAIL"}
                                        />
                                    }
                                />
                            </td>
                            <td className={childClass}>
                                <DisableField className="my-[-4px] mx-[-8px] max-w-[131px]">
                                    {`${selectedValue?.online_mark_up ? selectedValue?.online_mark_up : "0"} %`} 
                                </DisableField>
                            </td>
                            <td className={childClass}>
                                <DisableField className="my-[-4px] mx-[-8px] max-w-[131px]">
                                    {`${selectedValue?.online_profit ? convertNumber(selectedValue?.online_profit)?.intoCurrency : "0"}`} 
                                </DisableField>
                            </td>
                        </tr>
                        {/* SPECIAL ARANGEMENT */}
                        <tr>
                            <td className={labelClass}><b>SPECIAL ARANGEMENT</b></td>
                            <td className={childClass}>
                                <Controller
                                    control={control}
                                    name="special_arrangement_price"
                                    render={({ field, fieldState }) => 
                                        <NumberInput
                                            inputClass={inputClass} 
                                            decimalSeparator='.' 
                                            thousandSeparator=","
                                            value={field.value ? field.value : 0}
                                            error={fieldState.error?.message}
                                            onValueChange={value => {
                                                field.onChange(value.floatValue)
                                                setSelectedValue({ 
                                                    ...selectedValue, 
                                                    special_arrangement_mark_up: Math.round(((value.floatValue! - parseFloat(String(calculateCOGM)))/parseFloat(String(calculateCOGM))) * 100),
                                                    special_arrangement_profit: value.floatValue! - parseFloat(String(calculateCOGM))
                                                })
                                            }}
                                            disabled={action === "DETAIL"}
                                        />
                                    }
                                />
                            </td>
                            <td className={childClass}>
                                <DisableField className="my-[-4px] mx-[-8px] max-w-[131px]">
                                    {`${selectedValue?.special_arrangement_mark_up ? selectedValue?.special_arrangement_mark_up : "0"} %`} 
                                </DisableField>
                            </td>
                            <td className={childClass}>
                                <DisableField className="my-[-4px] mx-[-8px] max-w-[131px]">
                                    {`${selectedValue?.special_arrangement_profit ? convertNumber(selectedValue?.special_arrangement_profit)?.intoCurrency : "0"}`} 
                                </DisableField>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}