// React
import { useContext } from "react"

// Components
import { NumberInput } from "components"

// Contexts
import { BillContext } from "../contexts"

export function BalanceBill(): JSX.Element {
  // Hooks
  const data = useContext(BillContext)

  return (
    <NumberInput
      disabled
      inputClass="text-right"
      label="BALANCE BILL"
      value={data.databill.balance}
    />
  )
}