import * as yup from "yup"
import { validationSchemaJournal } from "./validationSchemaJournal"

export const validationSchema = yup.object().shape({
  balance: yup.number().label("BALANCE").required(),
  card_id: yup.string().label("TRADER").required(),
  amount: yup.number().label("CREDIT").required(),
  datajournal: yup.array().of(validationSchemaJournal).min(1).required(),
  group_card: yup.string().label("GROUP CARD").required(),
  imgPath: yup.array().label("FILE").min(0).required(),
  location_id: yup.string().label("LOCATION").required(),
  memo: yup.string().label("MEMO").required(),
  reconsiled: yup.bool().required(),
  reference_no: yup.string().label("MEMO").required(),
  tax_inclusive: yup.boolean().label("TAX").required(),
  transaction_date: yup.string().label("TRANSACTION DATE").required(),
  trx_code: yup.string().required(),
  type_payment: yup.string().defined(),
  name_payment: yup.string().defined(),
  number_payment: yup.string().defined(),
  expired_payment: yup.string().defined(),
  authorization_payment: yup.string().defined(),
  note_payment: yup.string().defined(),
  finance_id: yup.number().optional(),
  journal_code: yup.string().optional(),
  data_source: yup.number().optional(),
  data_source_id: yup.number().optional()
})
