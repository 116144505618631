// Third-Party Libraries
import moment from "moment"

// Types
import type { SuccessFetch } from "types"

// Utils
import { useApi } from "utils"

export function useDataCode(): {
  fetchCode: () => Promise<string>
} {
  // Hooks
  const api = useApi()

  const fetchCode = (): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      api.post("/invcount/generate-code", {
        trx_date: moment().format("YYYY-MM-DD")
      }).then((res: SuccessFetch<{
        payload: { next_code: string }
      }>) => {
        resolve(res.data.payload.next_code)
      }).catch(() => {
        reject("")
      })
    })
  }

  return { fetchCode }
}