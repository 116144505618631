import { BaseTable, Loading, PermissionLink } from "components"
import { convertDate } from "pages/Inventori/Transaction/TransactionJournal/utils"
import { TableSectionProps } from "pages/Purchase/Register/types/AgedPayable"
import { IoSendSharp } from "react-icons/io5"
import { convertNumber } from "utils"

export const TableSection = ({ data, isLoading }: TableSectionProps) => {

  return (
    <BaseTable className="border-transparent">
      <thead>
        <tr className="uppercase">
            <th>No</th>
            <th colSpan={2}>Bill Id#</th>
            <th>Ref Po Id#</th>
            <th>Date</th>
            <th>Vendor</th>
            <th>Balance</th>
            <th>7<br />Days</th>
            <th>15<br />Days</th> 
            <th>21<br />Days</th>
            <th>30+<br />Days</th>
        </tr>
      </thead>
      <tbody>
        {!data.length || isLoading 
            ? <tr><td colSpan={13} className="text-center"><Loading loading={isLoading} errorText={isLoading ? "" : "No data available"} /></td></tr>
            : data.map((val, index) => (
              <tr>
                <td>{index + 1}</td>
                <td>
                  <PermissionLink permission="PR053" to={`/purchase/transaction/enter-bills/${val?.purchase_order_id}/edit/${val.bill_id}`}>
                    <IoSendSharp />
                  </PermissionLink>
                </td>
                <td>{val?.trx_code ?? "-"}</td>
                <td>{val?.trx_code_po ?? "-"}</td>
                <td>{val?.transaction_date ? convertDate(val?.transaction_date) : "-"}</td>
                <td>{val?.vendor_name ?? "-"}</td>
                <td className="text-right">{val?.balance ? convertNumber(val?.balance)?.intoCurrency : "0"}</td>
                <td className="text-right">{val?.aged_payable <= 7 ? convertNumber(val?.balance)?.intoCurrency : '-'}</td>
                <td className="text-right">{val?.aged_payable >= 8 && val?.aged_payable <= 15 ? convertNumber(val?.balance)?.intoCurrency : "-"}</td>
                <td className="text-right">{val?.aged_payable >= 16 && val?.aged_payable <= 21 ? convertNumber(val?.balance)?.intoCurrency : "-"}</td>
                <td className="text-right">{val?.aged_payable >= 22 ? convertNumber(val?.balance)?.intoCurrency : "-"}</td>
              </tr>
            ))
        }
      </tbody>
    </BaseTable>
  )
}