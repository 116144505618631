import { BaseTable } from "components"
import { useFormContext, useWatch } from "react-hook-form"
import { FormReturnItemsType } from "../types"
import { calculateSummaryReturnItems } from "../utils/functions/calculateSummaryReturnItems"

export const SummaryFooter = () => {
  const { control } = useFormContext<FormReturnItemsType>()
  const return_detail = useWatch({
    control,
    name: 'return_detail'
  })
  const summary = calculateSummaryReturnItems(return_detail ?? [])

  return (
    <BaseTable className="w-full min-w-[100px] md:max-w-[400px]">
      <tbody className="text-lg  text-end">
        <tr className="border-dotted border-b-2">
          <td className="w-[1px] whitespace-nowrap pr-3 pt-5 uppercase font-bold">
            SUBTOTAL
          </td>
          <td className="pt-5">{summary?.subtotal?.toLocaleString()}</td>
        </tr>
        <tr className="border-dotted border-b-2">
          <td className="w-[1px] whitespace-nowrap pr-3 pt-5 uppercase font-bold">
            TAX
          </td>
          <td className="pt-5"> {(summary.tax).toLocaleString()}</td>
        </tr>
        <tr className="border-dotted border-b-2">
          <td className="w-[1px] whitespace-nowrap pr-3 pt-5 uppercase font-bold">
            TOTAL REJECT
          </td>
          <td className="pt-5"> {(summary.totalReject).toLocaleString()}</td>
        </tr>
        <tr className="border-dotted border-b-2">
          <td className="w-[1px] whitespace-nowrap pr-3 pt-5 uppercase font-bold text-xl">
            BALANCE
          </td>
          <td className="pt-5 text-xl"> {(summary.totalReject).toLocaleString()}</td>
        </tr>
      </tbody>
    </BaseTable>
  )
}
