interface InfoSectionProps { 
    label?: string
    value?: string
    className?: string
    labelClass?: string
}

export const InfoSection:React.FC<InfoSectionProps> = ({ label, value, className, labelClass }) => (
    <div className="ml-5">
      <div className={`label-text flex justify-center ${className}`}>
        <div className="w-[150px] text-right">
          <div className={labelClass}>{label ?? ""}</div>
        </div>
        <div className="mx-2 w-[20px] text-center">
          <div>:</div>
        </div>
        <div className="w-[150px]">
          <div>{value ?? "-"}</div>
        </div>
      </div>
      <hr className="my-1 border-[#3c3c3c]" />
    </div>
  )