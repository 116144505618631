import { useEffect, useState } from "react"
import type { SelectOptionType, SuccessFetch } from "../../../../../../../../types"
import { useApi } from "../../../../../../../../utils"

export function useProductStyle() {
  const api = useApi()
  const [dataStyle, setData] = useState<SelectOptionType[]>([])
  const [isLoadingStyle, setLoading] = useState(false)

  const refetchStyle = () => {
    setLoading(true)

    api.get("/productstyle/")
    .then((res: SuccessFetch<{
      payload: {
        mr_product_style_id: number
        mr_product_style_name: string
      }[]
    }>) => { setData(res.data.payload.map(item => { return { label: item.mr_product_style_name, value: item.mr_product_style_id.toString() }}))})
    .catch(() => setData([]))
    .finally(() => setLoading(false))
  }

  useEffect(() => {
    setLoading(true)

    api.get("/productstyle/").then((res: SuccessFetch<{
      payload: {
        mr_product_style_id: number
        mr_product_style_name: string
      }[]
    }>) => { setData(res.data.payload.map(item => { return { label: item.mr_product_style_name, value: item.mr_product_style_id.toString() }}))})
    .catch(() => setData([]))
    .finally(() => setLoading(false))
    // eslint-disable-next-line
  }, [])

  return { dataStyle, isLoadingStyle, refetchStyle }
}