import { Button, Modal } from "components"
import { useToggle } from "utils"

type ModalErrorReconcileProps = {
  modal: ReturnType<typeof useToggle>
  outOfBalance: number
}

export const ModalErrorReconcile = ({
  modal,
  outOfBalance
}: ModalErrorReconcileProps) => {
  return (
    <Modal
      title="Error: Discrepancy in Account Balance"
      isOpen={modal.isActive}
      closeModal={modal.toggle}>
      <Modal.Body>
        <p>Description:</p>

        <p>
          Your out of balance appears to be{" "}
          {outOfBalance.toLocaleString("id-ID")} <br />
          less than the balance displayed in HAWK System. <br />
          This inconsistency requires immediate attention to ensure accurate
          financial records.
        </p>

        <p className="mt-2">Resolution Steps:</p>
        <p>
          <ol>
            <li>Verify that the entered statement balance in is correct.</li>
            <li>
              Review all cleared checks to ensure none are missing or
              incorrectly recorded.
            </li>
            <li>
              Double-check that no deposits were mistakenly marked as cleared,
              which could affect the balance.
            </li>
          </ol>
        </p>

        <p className="mt-2 text-error">
          Failure to address this issue promptly may lead to inaccurencies in
          financial reporting and transactions.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex gap-2 justify-end">
          <Button color="primary" onClick={modal.toggle}>
            CANCEL
          </Button>
          <Button color="primary" onClick={modal.toggle}>
            REVIEW
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
