import { Card, CommandButton } from "components"

type CommandButtonsProps = {
  display?: boolean
  showDeleteButton?: boolean
  showSaveButton?: boolean
  onDelete?: () => void
  onSave?: () => void
}

export const CommandButtons = ({
  display = true,
  showDeleteButton,
  showSaveButton,
  onDelete,
  onSave
}: CommandButtonsProps) => {
  return (
    <Card className={display ? "" : "hidden"}>
      <Card.Body className="flex flex-col gap-5">
        <section className="flex flex-wrap justify-between gap-3">
          <section className="flex flex-wrap gap-3">
            <CommandButton actiontype="help" />
            <CommandButton actiontype="print" />
            <CommandButton actiontype="email" />
            <CommandButton actiontype="journal" />
            <CommandButton actiontype="attachment" />
          </section>

          <section className="flex flex-wrap gap-3">
            {showDeleteButton && (
              <CommandButton actiontype="delete" onClick={onDelete} />
            )}

            {showSaveButton && (
              <CommandButton
                actiontype="save"
                type="button"
                color="primary"
                permission="ST021"
                onClick={onSave}
              />
            )}
          </section>
        </section>
      </Card.Body>
    </Card>
  )
}
