// Types
import type { SuccessFetch } from "types"

// Utils
import { useApi } from "utils"

export function useBalance(url: string) {
  // Hooks
  const api = useApi()

  const refetch = (coa_id: string, tglsaldo: string) => {
    return new Promise<number>((resolve) => {
      api.post(url, {
        coa_id,
        tglsaldo
      }).then((res: SuccessFetch<{
        payload: { Balance: number }
      }>) => {
        resolve(res.data.payload.Balance)
      }).catch(() => {
        resolve(0)
      })
    })
  }

  return { refetch }
}