import { BaseTable, Loading, PermissionLink } from "components";
import { convertDate } from "pages/Inventori/Transaction/TransactionJournal/utils";
import { Fragment } from "react";
import { IoSendSharp } from "react-icons/io5";
import { convertNumber } from "utils";
import { useList } from "./hooks";

export function TabInvoice(props: {disable?: boolean }) {
  const productId = localStorage?.getItem("prdId")
  const { data, isLoading } = useList(Number(productId))

  return (
    <Fragment>
      <section className="mt-2">
        <div className="overflow-x-auto">
          <BaseTable className="border-transparent">
            <thead>
              <tr className="uppercase">
                <th>No</th>
                <th colSpan={props?.disable ? 1 : 2}>INVOICE ID</th>
                <th>Date</th>
                <th>Customer</th>
                <th>Qty</th>
                <th>Unit</th>
                <th>Price</th>
                <th>Disc</th>
                <th>Ext</th>
              </tr>
            </thead>
            <tbody>
              {!data.length || isLoading
                ? <tr><td colSpan={15} className="text-center"><Loading loading={isLoading} errorText={isLoading ? "" : "No data available"} /></td></tr>
                : data.map((val, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    { !props?.disable &&
                      <td>
                        <PermissionLink permission="IR013" to={`/sales/transaction/invoice/edit/${val.invoice_id}`}>
                          <IoSendSharp />
                        </PermissionLink>
                      </td>
                    }
                    <td>{val?.trx_code ?? ""}</td>
                    <td>{val?.transaction_date ? convertDate(val?.transaction_date) : "-"}</td>
                    <td>{val?.customer.customer_name ?? "-"}</td>
                    <td className="text-center">{val?.quantity_invoice ? convertNumber(val?.quantity_invoice)?.intoNormalAmount : "-"}</td>
                    <td>{val?.unit.mr_unit_name ?? "-"}</td>
                    <td className="text-right">{val?.price_invoice ? convertNumber(val?.price_invoice)?.intoCurrency : "-"}</td>
                    <td className="text-center">{val?.discount ? `${convertNumber(val?.discount)?.intoNormalAmount}%` : "-"}</td>
                    <td className="text-right">{val?.total ? convertNumber(val?.total)?.intoCurrency : "-"}</td>
                  </tr>
                ))
              }
            </tbody>
          </BaseTable>
        </div>
      </section>
    </Fragment>
  )
}