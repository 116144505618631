import { Fragment, useContext } from "react";
import { IoSendSharp } from "react-icons/io5";
import { PermissionLink, TableNumber } from "components";
import { dataContext } from "..";
import { CardType } from "../types";
import { APPROVAL, DELIVERED } from "pages/Sales/Register/utils/vars";
import { convertNumber } from "utils";
import { Delete } from "./Delete";
import moment from "moment";

export function Item(props: {
    data: CardType,
    index: number
}) {
    const { currentPage, limit, size, total } = useContext(dataContext)

    return (
        <Fragment>
            <tr >
                <td>{TableNumber({ dataLength: size, pageCurrent: currentPage, index: props.index, limit: limit, totalData: total })}</td>
                <td>
                    {APPROVAL.PENDING === props?.data?.approval_status &&
                        <PermissionLink permission="SR023" target="_blank" rel="noopener noreferrer" to={`/sales/transaction/customer-order/edit/${props?.data?.sales_order_id}`}>
                            <IoSendSharp />
                        </PermissionLink>
                    }
                </td>
                <td>{props?.data?.trx_code}</td>
                <td>{moment(props?.data?.transaction_date).format("DD/MM/YYYY")}</td>
                <td>CUSTOMER</td>
                <td>{props?.data?.customer?.customer_name}</td>
                <td>{APPROVAL[props?.data?.approval_status]}</td>
                <td>{DELIVERED[props?.data?.delivery_status]}</td>
                <td className="text-center">{convertNumber(props?.data?.qty_order).intoNormalAmount}</td>
                <td className="text-center">{convertNumber(props?.data?.qty_delivered).intoNormalAmount}</td>
                <td className="text-center">{convertNumber(props?.data?.qty_back_order).intoNormalAmount}</td>
                <td className="text-right">{convertNumber(props?.data?.total_sales).intoCurrency}</td>
                <td className="text-right">{convertNumber(props?.data?.total_payment).intoCurrency}</td>
                <td className="text-right">{convertNumber(props?.data?.balance_owing).intoCurrency}</td>
                <td>
                    <Delete
                        approval_status={props?.data?.approval_status}
                        sales_order_id={props?.data?.sales_order_id}
                    />
                </td>
            </tr>
        </Fragment>
    )
}