import { Input, Textarea, Select, Label } from "../../../../../../components"
import { Controller, useFormContext } from "react-hook-form"
import type { ListBank } from "../../types"
import { useAccountGroup } from "./utils"

export function FormSection(props: {
  isDetail?: boolean
  isUpdate?: boolean
  action?: string
}) {

  const { control } = useFormContext<ListBank>()

  return (
    <table className="table">
      <tbody>
        <Controller
          control={control}
          name="account_type_code"
          render={({ field, fieldState }) => 
            <tr>
              <td><Label text="CODE" error={Boolean(fieldState.error)} /></td>
              <td><Input {...field} placeholder="Input Code" value={field.value ?? ""} error={fieldState.error?.message} disabled={props.action === "DETAIL" || props.action === "DELETE"} /></td>
            </tr>
          }
        />

        <CoaGroup action={props.action} />

        <Controller
          control={control}
          name="account_type_name"
          render={({ field, fieldState }) => 
            <tr>
              <td><Label text="COA TYPE" error={Boolean(fieldState.error)} /></td>
              <td><Input {...field} placeholder="Input Coa Type" value={field.value ?? ""} error={fieldState.error?.message} disabled={props.action === "DETAIL" || props.action === "DELETE"} /></td>
            </tr>
          }
        />

        <Controller
          control={control}
          name="description"
          render={({ field, fieldState }) => 
            <tr>
              <td><Label text="DESCRIPTION" error={Boolean(fieldState.error)} /></td>
              <td><Textarea {...field} placeholder="Input Description" value={field.value ?? ""} error={fieldState.error?.message} disabled={props.action === "DETAIL" || props.action === "DELETE"} /></td>
            </tr>
          }
        />
      </tbody>
    </table>
  )
}

function CoaGroup(props: { action?: string }) {
  const { control } = useFormContext<ListBank>()
  const { data, isLoading } = useAccountGroup()
  
  return (
    <Controller
      control={control}
      name="group_coa_id"
      render={({ field, fieldState }) => (
        <tr>
          <td><Label text="COA GROUP" error={Boolean(fieldState.error)} /></td>
          <td>
            <Select
              placeholder="Choose Coa Group"
              isLoading={isLoading}
              error={fieldState.error?.message}
              options={data}
              value={data.find(item => item.value === field.value.toString())}
              onChange={e => {
                field.onChange(e?.value);
              }}
              isDisabled={props.action === "DETAIL" || props.action === "DELETE"}
            />
          </td>
        </tr>
      )}
    />
  )
}