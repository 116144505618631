// React
import { useState } from "react"

// Types
import type { SelectOptionType, SuccessFetch } from "types"
import type { GroupName } from "../../types"

// Utils
import { useApi } from "utils"

export function useTrader() {

  // Hooks
  const api = useApi()
  const [data, setData] = useState<SelectOptionType[]>([])
  const [isLoading, setLoading] = useState(false)

  const handleFetch = (group_name: GroupName) => {
    return new Promise<{
      label: string
      value: string
    }[]>((resolve, reject) => {
      if (group_name === "2") {
        api.get("/customer/").then((res: SuccessFetch<{
          payload: {
            customer_id: number
            customer_name: string
          }[]
        }>) => {
          resolve(res.data.payload.map(item => {
            return {
              label: item.customer_name,
              value: item.customer_id.toString()
            }
          }))
        }).catch(() => {
          reject([])
        })
      } else if (group_name === "1") {
        api.get("/employee/").then((res: SuccessFetch<{
          payload: {
            employee_id: number
            employee_name: string
          }[]
        }>) => {
          resolve(res.data.payload.map(item => {
            return {
              label: item.employee_name,
              value: item.employee_id.toString()
            }
          }))
        }).catch(() => {
          reject([])
        })
      } else {
        api.get("/vendor/").then((res: SuccessFetch<{
          payload: {
            vendor_id: number
            vendor_name: string
          }[]
        }>) => {
          resolve(res.data.payload.map(item => {
            return {
              label: item.vendor_name,
              value: item.vendor_id.toString()
            }
          }))
        }).catch(() => {
          reject([])
        })
      }
    })
  }

  function refetch(group_name: GroupName) {
    setLoading(true)

    handleFetch(group_name).then(setData).catch(setData).finally(() => {
      setTimeout(() => {
        setLoading(false)
      }, 500)
    })
  }

  return { data, isLoading, refetch }
}