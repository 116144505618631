import { Fragment, PropsWithChildren } from "react"
import { Button } from "components/actions"
import { HeaderMenu } from "components/widgets"

interface SelectOptionType {
  label: string
  value: string
  permission?: string
}

export function RegisterLayout(params: PropsWithChildren<{}>) {
  return <Fragment>{params.children}</Fragment>
}

function Filter(params: {
  list: SelectOptionType[]
  value: string
  permission?: boolean
  onChangeFilter: (value: string) => void
}) {

  return (
    <section className="grow flex items-stretch overflow-x-auto">
      {params.list.map((item) => (
        <Button
          key={item?.permission}
          permission={item?.permission}
          type="button"
          color="ghost"
          size="sm"
          className={`grow bg-[#3c3c3c] border-y-0 border-x no-animation hover:bg-primary hover:text-black whitespace-nowrap uppercase ${item.value === params.value ? "bg-primary text-black" : "text-white"}`}
          onClick={() => params.onChangeFilter(item.value)}
        >
          {item.label}
        </Button>
      ))}
    </section>
  )
}

function Header(params: { title: string }) {
  return (
    <HeaderMenu title={params.title} />
  )
}

RegisterLayout.Filter = Filter
RegisterLayout.Header = Header