// React
import { useState } from "react"

// Components
import { TabSection } from "pages/Purchase/Transaction/components"
import { Delivery, Journal, Payment, PaymentBill, Rejected } from "./components"

// Utils
import { tab_list } from "./utils"

export function Tab() {
  // Vars
  const content = [Delivery, Payment, PaymentBill, Rejected, Journal]

  // Hooks
  const [currentTab, setCurrentTab] = useState<number>(0)

  return (
    <section>
      <TabSection
        activeIndex={currentTab}
        list={tab_list}
        onChangeTab={setCurrentTab}
      />

      {content.map((Element, key) => {
        if (currentTab === key) {
          return <Element key={key} />
        }

        return null
      })}
    </section>
  )
}