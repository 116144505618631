import { BaseTable, Button, Card, Input } from "components"
import debounce from "lodash/debounce"
import React, { Fragment, useContext, useState } from "react"
import { TbChevronRight } from "react-icons/tb"
import { default_pagination_value, useToggle } from "utils"
import { CreateModal } from "./components"
import { menuTitle } from "./components/ChildItem/components/Item/components/MenuTitle"
import { DataItem } from "./types"
import { Render, RenderProvider, useList } from "./utils"
import { ChildItem } from "./components/ChildItem"
import { PaginationData } from "components" 
import { PaginationState } from "types"

export const dataContext = React.createContext({
  currentPage: 1,
  limit: 10,
  size: 1,
  total: 0
})

export default function Periode() {

  const [pagination, setPagination] = useState<PaginationState>(default_pagination_value)
  const { data, isLoading, refetch } = useList(pagination)
  const onDebounce = debounce((e: any) => setPagination(prev => {
    return {
      ...prev,
      keyword: e.target.value
    }
  }))

  return (
    <RenderProvider>
      <section className="container pt-5 gap-5 flex flex-col">
        <Card>
          <Card.Body className="flex flex-row items-center justify-between !text-xl font-bold">
            <div className="bg-black p-3 text-white rounded-lg">{menuTitle.title}</div>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body className="flex-row justify-end items-center">
            <Create refetch={refetch} />
          </Card.Body>
          <Card.Body>
            <div className="flex">
              <div className="mb-3">
                <Input
                  label="SEARCH"
                  className="md:max-w-[300px] w-full"
                  placeholder="Search..."
                  onChange={onDebounce}
                />
              </div>
            </div>
              <BaseTable >
                <thead>
                  <tr>
                    <th>NO</th>
                    <th>CODE</th>
                    <th>{menuTitle.title}</th>
                    <th>DESCRIPTION</th>
                    <th className="w-[150px]">ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  <dataContext.Provider
                    value={{
                      currentPage: pagination.page,
                      limit: pagination.limit,
                      size: data.pagination_data.size,
                      total: pagination.total
                    }}
                  >
                    <TableContent dataList={data} isLoading={isLoading} refetch={refetch} />
                  </dataContext.Provider>
                </tbody>
              </BaseTable>
            <div className="mt-5">
              <PaginationData data={data} pagination={pagination} setPagination={setPagination} />
            </div>
          </Card.Body>
        </Card>
      </section>
    </RenderProvider>
  )
}

function Create({ refetch }: { refetch: () => void }) {
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <Button color="primary" onClick={toggle}>
        CREATE NEW <TbChevronRight />
      </Button>

      {isActive && <CreateModal toggle={toggle} refetch={refetch} />}
    </Fragment>
  )
}
function TableContent(props: {
  dataList: DataItem
  isLoading: boolean
  refetch: () => void
}) {
  const { render } = useContext(Render)

  if (render) {
    return <ChildItem dataList={props.dataList} isLoading={props.isLoading} refetch={props.refetch} />
  } else {
    return <div />
  }
}