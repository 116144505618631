import { Fragment, useState } from "react"
import { ActionButton, Card, Button, Loading, HeaderMenu } from "../../../../../../../../../components"
import type { AddressList, BankList, EducationList, PersonalList, ProfileList, WorkStatusList } from "../../../../../types"
import { RenderProvider } from "../../../../../utils"
import { useLocation, useNavigate } from "react-router-dom"
import { Tab } from "./Tabs"
import { FormPersonalData } from "../../../../FormSection/FormPersonalData"
import { FormProvider, useForm } from "react-hook-form"
import { FormProfile } from "../../../../FormSection/FormProfile"
import { useDetailProfileData, useDetailAddressData, useDetailWorkStatusData, useDetailEducationData, useDetailBankData, useDetailPrivillegeData } from "../utils"
import { FormAddress } from "../../../../FormSection/FormAddress"
import { FormWorkStatus } from "../../../../FormSection/FormWorkStatus"
import { FormEducation } from "../../../../FormSection/FormEducation"
import { FormBank } from "../../../../FormSection/FormBank"
import { FormPrivilege } from "../../../../FormSection/FormPrivilege"

export function Detail(props: { id: PersonalList; permission?: boolean }) {
  const navigate = useNavigate()

  return (
    <Fragment>
      <ActionButton.Detail permission={"CR012"} className="join-item" onClick={() => navigate("/cards/transaction/create-new-employee/detail", { state: { dataId: props.id } })} />
    </Fragment>
  )
}
export function DetailPage() {
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState(0);
  const location = useLocation()
  const id = location?.state?.dataId?.employee_id
  const data = location?.state?.dataId
  const tabs = ["PROFILE", "ADDRESS", "WORK STATUS", "EDUCATION", "BANK", "PRIVILEGE"];
  
  const { dataProfile, errorProfile, isLoadingProfile } = useDetailProfileData(id ? id : null)
  const { dataAddress, errorAddress, isLoadingAddress } = useDetailAddressData(id ? id : null)
  const { dataWorkStatus, errorWorkStatus, isLoadingWorkStatus } = useDetailWorkStatusData(id ? id : null)
  const { dataEducation, errorEducation, isLoadingEducation } = useDetailEducationData(id ? id : null)
  const { dataBank, errorBank, isLoadingBank } = useDetailBankData(id ? id : null)
  const { dataPrivillege, errorPrivillege, isLoadingPrivillege } = useDetailPrivillegeData(id ? id : null)

  return (
    <RenderProvider>
      <section className="container my-5">
        <div className="mb-5">
          <Card>
            <Card.Body className="flex-row justify-between items-center">
              <HeaderMenu title="DETAIL EMPLOYEE REGISTRATION" />
            </Card.Body>
          </Card>
        </div>
        <Card>
          <Card.Body className="flex-row justify-end items-center">
            <Button color="primary" onClick={() => navigate("/cards/register/all")}>
                BACK
            </Button>
          </Card.Body>
          <Card.Body>
            <div className="lg:flex">
              <div className="lg:w-[34%] mr-0.5">
                <button className="bg-[#3c3c3c] px-4 py-2 w-full mr-0.5 text-white text-[12px]"><b>PERSONAL DATA</b></button>
                <div className={`max-w-sm w-full lg:max-w-full lg:flex`}>
                  <div className="w-full border border-[#3c3c3c] lg:border-[#3c3c3c] p-6">
                    <DataForm dataDefault={data!} setActiveTab={setActiveTab} />
                  </div>
                </div>
              </div>
              <div className="lg:w-[66%]">
                <Tab tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} className="text-[11px]" />
                <div className={`max-w-sm w-full lg:max-w-full lg:flex`}>
                  <div className="w-full border border-[#3c3c3c] lg:border-[#3c3c3c] p-6 mr-0.5">
                  {activeTab === 0 
                      ? isLoadingProfile || errorProfile 
                        ? <Loading errorText={errorProfile} loading={isLoadingProfile} /> 
                        : <DataProfileForm dataProfile={dataProfile!} />
                      : ""
                    }
                    {activeTab === 1
                      ? isLoadingAddress || errorAddress 
                        ? <Loading errorText={errorAddress} loading={isLoadingAddress} /> 
                        : <DataAddressForm dataAddress={dataAddress!} />
                      : ""
                    }
                    {activeTab === 2
                      ? isLoadingWorkStatus || errorWorkStatus 
                        ? <Loading errorText={errorWorkStatus} loading={isLoadingWorkStatus} /> 
                        : <DataWorkStatusForm dataWorkStatus={dataWorkStatus!} />
                      : ""
                    }
                    {activeTab === 3
                      ? isLoadingEducation || errorEducation 
                        ? <Loading errorText={errorEducation} loading={isLoadingEducation} /> 
                        : <DataEducationForm dataEducation={dataEducation!} />
                      : ""
                    }
                    {activeTab === 4
                      ? isLoadingBank || errorBank 
                        ? <Loading errorText={errorBank} loading={isLoadingBank} /> 
                        : <DataBankForm dataBank={dataBank!} />
                      : ""
                    }
                    {activeTab === 5
                      ? isLoadingPrivillege || errorPrivillege 
                        ? <Loading errorText={errorPrivillege} loading={isLoadingPrivillege} /> 
                        : <DataPrivilege dataPrivillege={dataPrivillege!} id={id} isLoadingPrivillege={isLoadingPrivillege} />
                      : ""
                    }
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </section>
    </RenderProvider>
  )
}
function DataForm(props: {
  dataDefault: PersonalList
  setActiveTab: (newState: any) => void
}) {
  const [fileValue, setFileValue] = useState<File | undefined>(undefined);
  console.log(fileValue)
  const defaultValues = {
    employee_code: props?.dataDefault?.employee_code!,
    employee_name: props?.dataDefault.employee_name,
    account: props?.dataDefault.username,
    Employee_Foto: props?.dataDefault?.photo,
  }
  const methods = useForm<PersonalList>({ defaultValues })

  return (
    <FormProvider {...methods}>
      {/* @ts-ignore */}
      <FormPersonalData action="DETAIL" onSubmit={() => {}} setFileValue={setFileValue} />
    </FormProvider>
  )
}
function DataProfileForm(props: { dataProfile: ProfileList }) {
  const defaultValues = {
    type_nationality: props?.dataProfile?.type_nationality,
    nomor: props?.dataProfile?.nomor,
    birthdate: props?.dataProfile?.birthdate,
    marital_status:  props?.dataProfile?.marital_status,
    religion_id: props?.dataProfile?.religion_id,
    handphone: props?.dataProfile?.handphone,
    email: props?.dataProfile?.email
  }
  const methods = useForm<ProfileList>({  defaultValues })

  return (
    <FormProvider {...methods}>
      {/* @ts-ignore */}
      <FormProfile action="DETAIL" />
    </FormProvider>
  )
}
function DataAddressForm(props: { dataAddress: any }) {
  const defaultValues = {
    country_id: props?.dataAddress?.[0]?.country_id,
    province_id: props?.dataAddress?.[0]?.province_id,
    regency_id: props?.dataAddress?.[0]?.regency_id,
    subdistrict_id: props?.dataAddress?.[0]?.subdistrict_id,
    village_id: props?.dataAddress?.[0]?.village_id,
    address: props?.dataAddress?.[0]?.address,
  }
  const methods = useForm<AddressList>({  defaultValues })

  return (
    <FormProvider {...methods}>
      {/* @ts-ignore */}
      <FormAddress action="DETAIL" />
    </FormProvider>
  )
}
function DataWorkStatusForm(props: { dataWorkStatus: any }) {
  const defaultValues = { 
    date_end: props?.dataWorkStatus?.[0]?.date_end,
    date_start: props?.dataWorkStatus?.[0]?.date_start,
    department_id: props?.dataWorkStatus?.[0]?.department_id,
    department_name: props?.dataWorkStatus?.[0]?.department_name,
    divisi_id: props?.dataWorkStatus?.[0]?.divisi_id,
    divisi_name: props?.dataWorkStatus?.[0]?.divisi_name,
    employee_id: props?.dataWorkStatus?.[0]?.employee_id,
    employee_status_id: props?.dataWorkStatus?.[0]?.employee_status_id,
    employee_status_name: props?.dataWorkStatus?.[0]?.employee_status_name,
    gender: props?.dataWorkStatus?.[0]?.gender,
    group_position_id: props?.dataWorkStatus?.[0]?.group_position_id,
    group_position_name: props?.dataWorkStatus?.[0]?.group_position_name,
    job_position_id: props?.dataWorkStatus?.[0]?.job_position_id,
    job_position_name: props?.dataWorkStatus?.[0]?.job_position_name,
    location_id: props?.dataWorkStatus?.[0]?.location_id,
    location_name: props?.dataWorkStatus?.[0]?.location_name,
    salary_type_id: props?.dataWorkStatus?.[0]?.salary_type_id,
    salary_type_name: props?.dataWorkStatus?.[0]?.salary_type_name
  }
  const methods = useForm<WorkStatusList>({  defaultValues })

  return (
    <FormProvider {...methods}>
      {/* @ts-ignore */}
      <FormWorkStatus action="DETAIL" />
    </FormProvider>
  )
}
function DataEducationForm(props: { dataEducation: any }) {
  const defaultValues = {
    sd_id: props?.dataEducation?.[0]?.sd_id,
    smp_id: props?.dataEducation?.[0]?.smp_id,
    sma_id: props?.dataEducation?.[0]?.sma_id,
    academy_id: props?.dataEducation?.[0]?.academy_id,
    university_id: props?.dataEducation?.[0]?.university_id,
    magister_id: props?.dataEducation?.[0]?.magister_id,
    doctoral_id: props?.dataEducation?.[0]?.doctoral_id,
  }
  const methods = useForm<EducationList>({ defaultValues })

  return (
    <FormProvider {...methods}>
      {/* @ts-ignore */}
      <FormEducation action="DETAIL" />
    </FormProvider>
  )
}
function DataBankForm(props: { dataBank: any }) {
  const defaultValues = { 
    bank_id: props?.dataBank?.[0]?.bank_id,
    branch: props?.dataBank?.[0]?.branch,
    account_number: props?.dataBank?.[0]?.account_number,
    account_name: props?.dataBank?.[0]?.account_name,
  }
  const methods = useForm<BankList>({ defaultValues })

  return (
    <FormProvider {...methods}>
      {/* @ts-ignore */}
      <FormBank action="DETAIL" />
    </FormProvider>
  )
}
function DataPrivilege(props: { dataPrivillege: any, id: number, isLoadingPrivillege: boolean }) {
  const [privillegeData, setPrivillegeData] = useState(props?.dataPrivillege)

  return (
    <FormPrivilege 
      action="DETAIL" 
      privillegeData={privillegeData} 
      setPrivillegeData={setPrivillegeData} 
      onSubmit={() => {}} 
      isLoadingPrivillege={props?.isLoadingPrivillege} 
      refetchPrivillege={() => {}}
    />
  )
}