// React
import { PropsWithChildren, createContext } from 'react'

export const ListJournalContext = createContext<{
  data_source: string
  date: string
  is_enabled: boolean
  journal_code: string
}>({
  data_source: "",
  date: "",
  is_enabled: false,
  journal_code: ""
})

export function ListJournalProvider(props: PropsWithChildren<{
  data_source: string
  date: string
  is_enabled: boolean
  journal_code: string
}>) {
  return (
    <ListJournalContext.Provider value={props}>
      {props.children}
    </ListJournalContext.Provider>
  )
}