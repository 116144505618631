import * as yup from "yup";

export const validationSchemaJournal = yup.object().shape({
  coa_id: yup.string().label("ACCOUNTS").required(),
  coa_name: yup.string().defined(),
  memo: yup.string().label("MEMO").defined(),
  amount: yup.number().label("AMOUNT").required(),
  position: yup.number().label("POSITION").required(),
  project_id: yup.string().label("PROJECT").required(),
  project_name: yup.string().defined(),
  referensi: yup.string().label("REFERENCE").defined(),
  tax_id: yup.string().label("TAX").required(),
  tax_name: yup.string().defined(),
  tax_coa_collect: yup.string().defined(),
  tax_coa_collect_id: yup.string().defined(),
  tax_coa_paid: yup.string().defined(),
  tax_coa_paid_id: yup.string().defined(),
  rate: yup.number().defined()
});

export type DataJournalType = yup.InferType<typeof validationSchemaJournal>;
