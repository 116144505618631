// React
import { Fragment, useContext } from "react"

// Components
import { AccountModal, Select } from "components"
import { FormParamContext } from "pages/Finance/Transaction/components"

// Form
import { Controller, useFormContext, useWatch } from "react-hook-form"

// Types
import type { PropsValue } from "react-select"

// Utils
import { useToggle } from "utils"
import { DataJournalType, account_class } from "../../utils"

export function Expenses() {
  // Hooks
  const { defaultGroup } = useContext(FormParamContext)
  const { isActive, toggle } = useToggle(false)

  // Form
  const { control, setValue } = useFormContext<DataJournalType>()
  const coa_name = useWatch({
    control,
    name: "coa_name"
  })

  return (
    <Fragment>
      <Controller
        control={control}
        name="coa_id"
        render={({ field, fieldState }) => {
          // Vars
          let value: PropsValue<{
            value: string
            label: string
          }> = null

          if (field.value && coa_name) {
            value = {
              label: coa_name,
              value: field.value
            }
          }

          return (
            <section {...account_class} onClick={toggle}>
              <Select
                placeholder="Select Account"
                error={fieldState.error?.message}
                ref={field.ref}
                value={value}
              />
            </section>
          )
        }}
      />

      {isActive && (
        <AccountModal
          defaultGroup={defaultGroup}
          toggle={toggle}
          onChange={(value, label) => {
            setValue("coa_id", value, { shouldValidate: true })
            setValue("coa_name", label)
          }}
        />
      )}
    </Fragment>
  )
}