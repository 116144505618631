import { CommandButton } from "components"
import { TableSectionProps } from "pages/Purchase/Register/types/Location"
import { convertNumber } from "utils"

export const FotherSection = ({ data }: TableSectionProps) => {
  const totalPayment = data.reduce((total, item) => total + item.total_payment!, 0);
  const totalBalance = data.reduce((total, item) => total + item.balance_owing!, 0);

  return (
    <div className="flex justify-between items-center">
      <section className="flex flex-wrap gap-3">
        <CommandButton actiontype="help" />
        <CommandButton actiontype="print" />
        <CommandButton actiontype="email" />
        <CommandButton actiontype="export" />
      </section>

      <div>
        <div className="label-text font-bold flex justify-end text-right">
          <div>
            <div>SUMMARY PAYMENT</div>
            <div>SUMMARY BALANCE</div>
          </div>
          <div className="mx-2">
            <div>:</div>
            <div>:</div>
          </div>
          <div>
            <div>{totalPayment ? convertNumber(totalPayment)?.intoCurrency : "0"}</div>
            <div>{totalBalance ? convertNumber(totalBalance)?.intoCurrency : "0"}</div>
          </div>
        </div>
      </div>
    </div>
  )
}