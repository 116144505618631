import { BaseTable, Button } from "components";
import { FilterType } from "pages/Card/Transaction/CreateNewEmployee/types/FilterType"
import { useExportList } from "pages/Card/Transaction/CreateNewEmployee/utils";
import { forwardRef, useRef } from "react";
import { FaFilePdf } from "react-icons/fa6";
import { useReactToPrint } from "react-to-print";

interface DataListProps {
    fillterData: FilterType
}

export function Pdf(props: {
    dataFillter: FilterType
}) {
    const contentToPrint = useRef(null);
    const handlePdf = useReactToPrint({
        documentTitle: "Print This Document",
        removeAfterPrint: true,
    });

    return (
        <>
            <div style={{ display: "none" }} ><DataList ref={contentToPrint} fillterData={props.dataFillter} /></div>
            <Button color="warning" onClick={() => handlePdf(null, () => contentToPrint.current)}><FaFilePdf className="w-4 h-4" /> Export Pdf</Button>
        </>
    )
}

const DataList = forwardRef<null, DataListProps>((props, ref) => {

    const { data } = useExportList()

    return (
        <div ref={ref}>
            <div className="m-4">
                <BaseTable className="border-collapse">
                    <thead className="bg-yellow-400">
                        <tr className="font-bold">
                            <th>NO</th>
                            <th>ID</th>
                            <th>NAME</th>
                            {props.fillterData.nomorNationalId && <th>NOMOR OF NATIONALITY ID</th>}
                            {props.fillterData.religion && <th>RELIGION</th>}
                            {props.fillterData.handphone && <th>HANDPHONE</th>}
                            {props.fillterData.email && <th>EMAIL</th>}
                            {props.fillterData.country && <th>COUNTRY</th>}
                            {props.fillterData.province && <th>PROVINCE</th>}
                            {props.fillterData.regency && <th>REGENCY</th>}
                            {props.fillterData.subdistrict && <th>SUBDISTRICT</th>}
                            {props.fillterData.village && <th>VILLAGE</th>}
                            {props.fillterData.address && <th>ADDRESS</th>}
                            {props.fillterData.departement && <th>DEPARTEMENT</th>}
                            {props.fillterData.divisi && <th>DIVISI</th>}
                            {props.fillterData.gender && <th>GENDER</th>}
                            {props.fillterData.employeeStatus && <th>EMPLOYEE STATUS</th>}
                            {props.fillterData.bank && <th>BANK</th>}
                            {props.fillterData.branch && <th>BRANCH</th>}
                            {props.fillterData.accountBankName && <th>ACCOUNT BANK NAME</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((item, key) => (
                            <tr key={key}>
                                <td>{key + 1}</td>
                                <td>{item.employee_code}</td>
                                <td>{item.employee_name}</td>
                                {props.fillterData.nomorNationalId && <td>{item.nomor}</td>}
                                {props.fillterData.religion && <td>{item.religion_name}</td>}
                                {props.fillterData.handphone && <td>{item.handphone}</td>}
                                {props.fillterData.email && <td>{item.email}</td>}
                                {props.fillterData.country && <td>{item.country_name}</td>}
                                {props.fillterData.province && <td>{item.province_name}</td>}
                                {props.fillterData.regency && <td>{item.regency_name}</td>}
                                {props.fillterData.subdistrict && <td>{item.subdistrict_name}</td>}
                                {props.fillterData.village && <td>{item.village_name}</td>}
                                {props.fillterData.address && <td>{item.address}</td>}
                                {props.fillterData.departement && <td>{item.department_name}</td>}
                                {props.fillterData.divisi && <td>{item.divisi_name}</td>}
                                {props.fillterData.gender && <td>{item.gender}</td>}
                                {props.fillterData.employeeStatus && <td>{item.employee_status_name}</td>}
                                {props.fillterData.bank && <td>{item.bank_name}</td>}
                                {props.fillterData.branch && <td>{item.branch}</td>}
                                {props.fillterData.accountBankName && <td>{item.account_number}</td>}
                            </tr>
                        ))}
                    </tbody>
                </BaseTable>
            </div>
        </div>
    );
});

