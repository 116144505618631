// React
import { useContext } from "react"

// Components
import { Input } from "components"

// Contexts
import { BillContext } from "../contexts"

export function LinkBill(): JSX.Element {
  // Hooks
  const data = useContext(BillContext)

  return (
    <Input
      disabled
      label="LINK TO DELIVERY BILL ID"
      value={data.databill.trx_code}
    />
  )
}