// React
import { forwardRef } from "react"

// Date Picker
import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

interface Type extends ReactDatePickerProps {
  error?: string
  label?: string
  wrapperClassName?: string
  className?: string
}

export const DateInput = forwardRef((props: Type, ref) => {
  return (
    <div className={`form-control ${props.wrapperClassName}`}>
      {props.label && (
        <label className="label">
          <span
            className={`label-text font-bold ${props.error && "text-error"}`}>
            {props.label}
          </span>
        </label>
      )}

      <ReactDatePicker
        {...props}
        // @ts-ignore
        ref={ref}
        dateFormat={props.dateFormat ?? "dd/MM/yyyy"}
        placeholder={props.disabled ? "-" : props.placeholderText}
        className={`w-full input input-bordered dark:border-gray-400 ${
          props.error && "!input-error"
        } ${props.className}`}
        wrapperClassName="w-full"
      />

      {props.error && (
        <label className="label">
          <span
            className={`label-text-alt font-bold ${
              props.error && "text-error"
            }`}>
            {props.error}
          </span>
        </label>
      )}
    </div>
  )
})
