import { LogoHitam } from "assets"
import { BaseTable } from "components"
import { RefObject, forwardRef } from "react"
import { useReactToPrint } from "react-to-print"
import { convertNumber } from "utils"

type UseTriggerPrint = {
  ref: RefObject<HTMLDivElement>
}

export const useTriggerPrint = (props: UseTriggerPrint) => {
  const handlePrint = useReactToPrint({
    documentTitle: "Document",
    removeAfterPrint: true
  })

  return () => handlePrint(null, () => props.ref.current)

}

export const Print = forwardRef<any, any>((props, ref) => {
  const numberDefault = 200000

  return (
    <div ref={ref} className="py-2 px-12 text-sm">
      <div className="border-b-4 border-b-gray-400">
        <div className="flex text-center justify-center items-center bg-gray-400 uppercase text-white h-10 max-w-[30%]">
          Spend Money
        </div>
      </div>

      <div className="flex flex-col justify-center items-start my-6">
        <div className="w-24 h-24">
          <img src={LogoHitam} alt="logo" />
        </div>
        <span className="font text-gray-400 uppercase">Flying Hawk Indonesia</span>
      </div>

      <hr className="my-3" />

      {/* Withdrawal From account */}
      <div className="w-full flex justify-between items-start mt-12 mb-16">
        <div className="w-7/12 flex gap-2 justify-start items-start ">
          <div className="w-1/2 text-right uppercase flex flex-col items-end text-nowrap font-bold">
            <div>Withdrawal From Account:</div>
            <div>Pay To:</div>
            <div>Location:</div>
            <div>Memo:</div>
          </div>

          <div className="text-nowrap uppercase">
            <div>Value</div>
            <div>Value</div>
            <div>Value</div>
            <div>Value</div>
          </div>
        </div>

        <div className="w-5/12 flex gap-2 justify-end items-end">
          <div className="w-2/3 text-right uppercase font-bold">
            <div className="text-nowrap">Spend Money ID#:</div>
            <div>Transaction Date:</div>
            <div>Reference Number:</div>
          </div>

          <div className="uppercase text-right">
            <div>Value</div>
            <div>Value</div>
            <div>Value</div>
          </div>
        </div>

      </div>

      {/* Content Line Information */}
      <BaseTable>
        <thead>
          <tr className="uppercase text-center">
            <th>No</th>
            <th>Account Description</th>
            <th>Memo/Line Note</th>
            <th>Qty</th>
            <th>Unit Price</th>
            <th>Amount</th>
            <th>Job</th>
            <th>Tax</th>
          </tr>
        </thead>
        <tbody>
          <tr className="text-center">
            <td>1</td>
            <td>COA 123</td>
            <td>MEMO</td>
            <td>3</td>
            <td>{convertNumber(numberDefault).intoCurrency}</td>
            <td>1</td>
            <td>Job 1</td>
            <td>11%</td>
          </tr>
        </tbody>
      </BaseTable>

      {/* Footer Information*/}
      <div className="w-full flex justify-between items-end mt-28">
        <div className="w-1/4 flex gap-2 justify-start items-start ">
          <div className="w-full text-right uppercase flex flex-col items-end justify-start text-nowrap font-bold">
            <div>Created By:</div>
            <div>Approved By:</div>
          </div>

          <div className="text-nowrap uppercase">
            <div>Value</div>
            <div>Value</div>
          </div>
        </div>

        <div className="w-3/4 flex flex-col gap-2 justify-end items-end">
          <div className="w-2/3 border-b-2 flex gap-2 uppercase justify-end">
            <span className="font-bold text-nowrap">Total Allocated:</span>
            <div className="min-w-[200px] text-right">{convertNumber(numberDefault).intoCurrency}</div>
          </div>

          <div className="w-2/3 border-b-2 flex gap-2 uppercase justify-end">
            <span className="font-bold text-nowrap">Tax Amount:</span>
            <div className="min-w-[200px] text-right">{convertNumber(numberDefault).intoCurrency}</div>
          </div>

          <div className="w-2/3 border-b-2 flex gap-2 uppercase justify-end">
            <span className="font-bold text-nowrap">Total Spending:</span>
            <div className="min-w-[200px] text-right">{convertNumber(numberDefault).intoCurrency}</div>
          </div>

          <div className="w-2/3 border-b-2 flex gap-2 uppercase justify-end">
            <span className="font-bold text-nowrap">Out of Balance:</span>
            <div className="min-w-[200px] text-right">{convertNumber(numberDefault).intoCurrency}</div>
          </div>
        </div>
      </div>
    </div >
  )
})