// React
import { useEffect, useState } from "react"

// Third-Party Libraries
import moment from "moment"

// Types
import type { SuccessFetch } from "types"

// Utils
import { useApi } from "utils"

export function useDataCode() {
  // Hooks
  const api = useApi()
  const [data, setData] = useState<string>("")

  useEffect(() => {
    refetch()
  
    return () => {
      setData("")
    }

    // eslint-disable-next-line
  }, [])

  const refetch = (): void => {
    api.post("/probill/generate-code", {
      trx_date: moment().format("YYYY-MM-DD")
    }).then((res: SuccessFetch<{
      payload: { next_code: string }
    }>) => {
      setData(res.data.payload.next_code)
    }).catch(() => {
      setData("")
    })
  }

  return { data, refetch }
}