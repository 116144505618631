/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup"
import { useEffect, useMemo } from "react"
import { FormProvider, useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { useNavigate, useParams } from "react-router-dom"
import { useApi } from "utils"
import { today } from "utils/functions"
import { useDetailTransferItem } from "../TransferItem/utils/hooks"
import { FormSection } from "./components"
import { formReceivedSchema, FormReceivedType } from "./types"
import { submitMap } from "./utils/functions"
import { useDetailReceived } from "./utils/hooks"

export default function ReceivedEdit() {
  const navigate = useNavigate()
  const api = useApi()
  const params = useParams()
  const received = useDetailReceived({
    received_id: params?.received_id ?? ""
  })
  const transfer = useDetailTransferItem({
    transfer_id: received.data?.datareceived?.transfer_id?.toString() ?? ""
  })

  const defaultValues: FormReceivedType = useMemo(
    () => ({
      received_id: received.data?.datareceived.received_id,
      transfer_id: received.data?.datareceived.transfer_id ?? NaN,
      transfer_date: transfer?.data?.datatransfer.transaction_date ?? today(),
      transfer_memo: transfer?.data?.datatransfer.memo ?? "",
      transfer_link: transfer?.data?.datatransfer.trx_code ?? "",
      transfer_employee_id: transfer.data?.datatransfer.employee_id ?? NaN,
      trx_code: received.data?.datareceived.trx_code ?? "",
      location_from_id: received.data?.datareceived.location_from_id ?? NaN,
      location_to_id: received.data?.datareceived.location_to_id ?? NaN,
      employee_id: received.data?.datareceived.employee_id ?? NaN,
      employee_name: received.data?.datareceived.employee_name ?? "",
      transaction_date: received.data?.datareceived.transaction_date ?? today(),
      memo: received.data?.datareceived.memo ?? "",
      received_status: received.data?.datareceived.received_status ?? 0,
      received_detail:
        received.data?.detailreceived?.map((item) => ({
          received_detail_id: item?.received_detail_id,
          product_name: item.product_name,
          product_barcode: item.product_barcode,
          mr_unit_name: item.mr_unit_name,
          product_id: item.product_id,
          total_quantity_transfer: item.quantity_transfer ?? 0,
          quantity_received: item.quantity_received
        })) ?? [],
      location_from_name: received.data?.datareceived.location_from_name,
      location_to_name: received.data?.datareceived.location_to_name,
      approved_by_name: received.data?.datareceived.approve_by_name ?? "",
      approval_status: received.data?.datareceived.approve_status ?? 0,
      approval_status_text:
        received.data?.datareceived.approve_status_text ?? ""
    }),
    [transfer.data]
  )

  const submitPromise = async (value: FormReceivedType) => {
    return await toast
      .promise(api.put("/received/edit", submitMap(value)), {
        loading: "Loading...",
        success: (res) => res.data.message,
        error: (err) =>
          err.response.data.detail?.message ?? err.response.data.message
      })
      .then(() => {
        navigate("/inventory/register/", { replace: true })
      })
      .catch((err) => {
        throw new Error(err)
      })
  }

  const onSubmit = async (value: FormReceivedType) => {
    return await submitPromise(value)
  }

  const methods = useForm<FormReceivedType>({
    defaultValues,
    resolver: yupResolver(formReceivedSchema)
  })

  useEffect(() => methods.reset(defaultValues), [defaultValues])

  return (
    <FormProvider {...methods}>
      <FormSection isEdit onSubmit={methods.handleSubmit(onSubmit)} />
    </FormProvider>
  )
}
