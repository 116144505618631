// React
import { useEffect, useState } from "react"

// Types
import type { SuccessFetch } from "types"

// Utils
import { useApi } from "utils"

export function useCoaBsi(product_id: string): {
  data: string
  isLoading: boolean
} {
  // Hooks
  const api = useApi()
  const [data, setData] = useState<string>("")
  const [isLoading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const promise = new Promise<string>((resolve) => {
      api.get("/probill/coabsi_byproduct", {
        params: { product_id }
      }).then((res: SuccessFetch<{
        payload: {
          coa_buy_code: string
          coa_buy_name: string
          coa_sell_code: string
          coa_sell_name: string
          coa_inv_code: string
          coa_inv_name: string
        }
      }>) => {
        // Vars
        const payload = res.data.payload
        const coa_buy_code: boolean = Boolean(payload.coa_buy_code && payload.coa_buy_code !== "-")
        const coa_inv_code: boolean = Boolean(payload.coa_inv_code && payload.coa_inv_code !== "-")
        const coa_sell_code: boolean = Boolean(payload.coa_sell_code && payload.coa_inv_code !== "-")
  
        if (Boolean(coa_buy_code && coa_inv_code && coa_sell_code)) {
          resolve(`${payload.coa_inv_code} ${payload.coa_inv_name}`)
        } else if (Boolean(coa_buy_code && coa_sell_code)) {
          resolve(`${payload.coa_buy_code} ${payload.coa_buy_name}`)
        } else if (Boolean(coa_buy_code && coa_inv_code)) {
          resolve(`${payload.coa_inv_code} ${payload.coa_inv_name}`)
        } else if (Boolean(coa_buy_code)) {
          resolve(`${payload.coa_buy_code} ${payload.coa_buy_name}`)
        }
      }).catch(() => {
        resolve("")
      }).finally(() => {
        setLoading(false)
      })
    })

    promise.then(setData)

    return () => {
      setData("")
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [])

  return { isLoading, data }
}