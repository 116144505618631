import * as yup from "yup"
import { formTransferDetailSchema } from "./FormTransferDetailType"

export type FormTransferItemsType = yup.InferType<
  typeof formTransferItemsSchema
>

export const formTransferItemsSchema = yup.object().shape({
  transfer_id: yup.number().label("Transfer Items").optional(),
  trx_code: yup.string().label("Code").optional(),
  location_from_id: yup
    .number()
    .label("Location From")
    .typeError("Location From is required")
    .required(),
  location_from_name: yup.string().label("Location From").optional(),
  location_to_id: yup
    .number()
    .label("Location To")
    .typeError("Location From is required")
    .required(),
  location_to_name: yup.string().label("Location To").optional(),
  employee_id: yup
    .number()
    .label("Employee")
    .typeError("Employee is required")
    .required(),
  employee_name: yup.string().label("Employee").optional(),
  transaction_date: yup.string().label("Transaction Date").required(),
  memo: yup.string().label("Memo").required(),
  approved_by: yup.string().label("Approved By").optional(),
  approved_by_name: yup.string().label("Approved By").defined(),
  approval_status: yup.number().label("Approval Status").defined(),
  transfer_status: yup.number().label("Transfer Status").optional(),
  approval_status_text: yup.string().label("Approval Status").defined(),
  transfer_detail: yup.array().of(formTransferDetailSchema).min(1).required()
})
