/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react"
import { SuccessFetch } from "types"
import { useApi } from "utils"

type UseReturnItemsListProps = {
  start_date?: string
  end_date?: string
  Approval?: number
  search?: string
  page?: number
  limit?: number
}

export type ReturnItemsList = {
  return_id: number
  invoice_id: number
  trx_code: string
  customer_id: number
  customer_name: string
  location_id: number
  location_name: string
  address: string
  memo: string
  transaction_date: string
  referensi: string
  approve_status: number
  approve_date: string | null
  approve_by: string | null
  trx_invoice_code: string
}

type Response = {
  payload: ReturnItemsList[]
  pagination_data: {
    total: number
    page: number
    size: number
    pages: number
  }
}

export function useReturnItemsList({
  start_date,
  end_date,
  Approval,
  search,
  page,
  limit
}: UseReturnItemsListProps) {
  const api = useApi()
  const [data, setData] = useState<Response["payload"]>([])
  const [paginate, setPaginate] = useState<Response["pagination_data"]>()
  const [isLoading, setLoading] = useState(false)

  const fetchData = () => {
    setLoading(true)

    const params = {
      start_date,
      end_date,
      Approval,
      search,
      page,
      limit
    }

    api
      .post("/return/getdata", null, { params })
      .then((res: SuccessFetch<Response>) => {
        const data = res.data.payload ?? []

        setData(data)
        setPaginate(res.data.pagination_data)
      })
      .catch(() => {
        setData([])
        setPaginate({
          page: 1,
          size: 0,
          pages: 0,
          total: 0
        })
      })
      .finally(() => setLoading(false))
  }

  const refetch = () => fetchData()

  useEffect(() => {
    fetchData()

    return () => {
      setData([])
      setLoading(false)
    }
  }, [page, limit, Approval])

  return {
    data,
    paginate,
    isLoading,
    refetch,
    dropdown: data.map((item) => ({
      value: item.return_id,
      label: item.trx_code
    }))
  }
}
