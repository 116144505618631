import { ActionButton, BaseTable, Input } from "components"
import { Fragment } from "react"
import { convertNumber, useApi } from "utils"

interface IngredientFormulaTableProps { 
    action?: "CREATE" | "UPDATE" | "DETAIL"
    selected: any
    setSelected: ( newState: any[] ) => void
    id?: number
}
export const IngredientFormulaTable = ({ action, selected, setSelected, id }: IngredientFormulaTableProps) => {
    const api = useApi()
  
    const total = selected.reduce((acc: any, curr: any) => { return acc + ((curr.qty || 0) * curr.price_unit_convention) }, 0);
    const grandTotal = () => {
      const totalAmount = selected?.reduce((prevValue: any, currentValue: any) => {
        const checkTotal = currentValue.amount ? parseFloat(currentValue.amount) : 0
        return prevValue + checkTotal
      }, 0)
  
      return { amount: total ? total : totalAmount }
    }
  
    return (
      <Fragment>
        <div className="mt-5 overflow-x-auto">
          <div className="text-[13px] mb-1 uppercase"><b>Ingridient Formula</b></div>
          <BaseTable className="border-collapse">
            <thead>
              <tr>
                <th>NO</th>
                <th>CODE</th>
                <th>DESCRIPTION</th>
                <th>QTY</th>
                <th>UNIT</th>
                <th>PRICE</th>
                <th>EXT AMOUNT</th>
                {action !== "DETAIL" && <th>ACTION</th>}
              </tr>
            </thead>
            <tbody>
              {selected?.length > 0 
                ? selected?.map((field: any, key: any) => (
                    <tr>
                      <td className="text-center">{key + 1}</td>
                      <td className="w-[100px]">{field?.product_code ?? "-"}</td>
                      <td>{field?.product_name ?? "-"}</td>
                      <td className={`w-[60px] ${action === "DETAIL" ? "text-center" : ""}`}>
                        {action !== "DETAIL"
                          ? <div className="w-[60px]">
                              <Input
                                {...field} 
                                type="text"
                                inputClass="m-[-10px]"
                                value={field.qty ?? 0}
                                onChange={event => {
                                  const selectedItem = selected[key]
                                  const updatedItem = { ...selectedItem, qty: event.target.value.replace(/[^\d,]/g, '').replace(/[^\d.]/g, ''), amount: parseFloat(event.target.value.replace(/[^\d,]/g, '').replace(/[^\d.]/g, '')) * selectedItem?.price_unit_convention}
                                  const updatedSelected = selected.map((item: any, index: any) => index === key ? updatedItem : item);
                                  setSelected(updatedSelected);
                                }}
                              />
                            </div>
                          : field?.qty ?? "0"
                        }
                      </td>
                      <td className="min-w-[60px] text-right">{field?.mr_unit_name ?? "-"}</td>
                      <td className="min-w-[130px] text-right">{field?.price_unit_convention ? convertNumber(field?.price_unit_convention)?.intoCurrency : "0"}</td>
                      <td className="min-w-[130px] text-right">
                        {field?.amount 
                          ? convertNumber(field?.amount)?.intoCurrency
                          : field?.qty 
                            ? convertNumber(field?.qty * field?.price_unit_convention)?.intoCurrency
                            : "0"
                        }
                      </td>
                      {action !== "DETAIL" && <td className="max-w-[100px] text-center">
                        <ActionButton.Delete 
                          onClick={() => {
                            const updatedSelected = [...selected]
                            updatedSelected.splice(key, 1)
                            setSelected(updatedSelected)
                            api.delete(`/product/deletecomponent?product_id=${id}&product_ingredient_id=${field?.product_ingredient_id ? field?.product_ingredient_id : field?.product_id}`)
                          }} 
                        />
                      </td>}
                    </tr>
                  ))
                : <tr><td colSpan={action !== "DETAIL" ? 8 : 7} className="text-center">No Data Available</td></tr>
              }
              <tr>
                <td colSpan={6} className="text-right"><b>SUMMARY</b></td>
                <td className="text-right"><b>
                  {convertNumber(grandTotal()?.amount)?.intoCurrency}
                </b></td>
                {action !== "DETAIL" && <td />}
              </tr>
            </tbody>
          </BaseTable>
        </div>
      </Fragment>
    )
  }