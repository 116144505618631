// React
import { useContext } from "react"

// Components
import { NumberInput } from "components"

// Contexts
import { BillContext } from "../contexts"

// Form
import { useFormContext, useWatch } from "react-hook-form"

// Types
import { FormType } from "../utils"

export function TotalBalance(): JSX.Element {
  // Hooks
  const data = useContext(BillContext)

  // Form
  const { control } = useFormContext<FormType>()
  const deposit_amount = useWatch({
    control,
    name: "deposit_amount"
  })
  const payment_amount = useWatch({
    control,
    name: "payment_amount"
  })

  // Vars
  const value: number = data.databill.balance - deposit_amount - payment_amount

  return (
    <NumberInput
      disabled
      decimalSeparator="."
      inputClass="text-right"
      label="BALANCE"
      thousandSeparator=","
      value={value}
    />
  )
}