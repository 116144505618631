// React
import { Fragment } from "react"

// Components
import { CreateModal as ProjectModal } from "pages/Setup/Preference/Project/components"

// Utils
import { useToggle } from "utils"

export function AddProject(params: { onSuccess: () => void }) {
  // Hooks
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <span className="whitespace-nowrap text-primary cursor-pointer" onClick={toggle}>[+]</span>

      {isActive && (
        <ProjectModal
          toggle={() => {
            toggle()
            params.onSuccess()
          }}
        />
      )}
    </Fragment>
  )
}