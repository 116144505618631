// React
import { useEffect, useState } from "react"

// Types
import type { SuccessFetch } from "types"

// Utils
import { useApi } from "utils"

interface FetchType {
  coa_code: string
  coa_name: string
  group_coa_code: string
}

export function useCoaByCode(code: string): {
  data: string
  isLoading: boolean
} {
  // Hooks
  const api = useApi()
  const [data, setData] = useState<string>("")
  const [isLoading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const promise = new Promise<string>((resolve) => {
      setLoading(true)

      api.get("/linkedaccount/bycode", {
        params: { linked_account_code: code }
      }).then((res: SuccessFetch<{ payload: FetchType[] }>) => {
        // Vars
        const payload = res.data.payload[0]
  
        resolve(`${payload.group_coa_code}-${payload.coa_code} ${payload.coa_name}`)
      }).catch(() => {
        resolve("")
      }).finally(() => {
        setLoading(false)
      })
    })

    promise.then(setData)

    return () => {
      setData("")
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [])

  return { data, isLoading }
}