/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup"
import { ActionButton, NumberInput } from "components"
import {
  useProjectList,
  useTaxCodeList
} from "pages/Finance/Transaction/GeneralEntry/utils/hooks"
import { calculateTotalSalesOrderDetail } from "pages/Sales/Transaction/CustomerOrder/utils/functions"
import { useDropdownProduct } from "pages/Sales/Transaction/CustomerOrder/utils/hooks"
import { useEffect, useMemo } from "react"
import { Controller, FormProvider, useForm } from "react-hook-form"
import { formInvoiceDetailSchema, FormInvoiceDetailType } from "../../../types"

type Action = "add" | "edit"

type RowProps = {
  index?: number
  action: Action
  defaultValues?: any
  onSubmit: (value: FormInvoiceDetailType) => void
  onCancel: () => void
  tax: ReturnType<typeof useTaxCodeList>
  jobs: ReturnType<typeof useProjectList>
  products: ReturnType<typeof useDropdownProduct>
  priceLevelName?: string
  displayArrowNavigation?: boolean
}

export function RowSalesOrder(props: RowProps) {
  const defaultValues: FormInvoiceDetailType = useMemo(
    () => ({
      sales_order_detail_id: props?.defaultValues?.sales_order_detail_id ?? NaN,
      invoiced: props?.defaultValues?.invoiced,
      product_id: props?.defaultValues?.product_id ?? NaN,
      product_name: props?.defaultValues?.product_name ?? "",
      price_level_name: props?.priceLevelName ?? "",
      product_barcode: props?.defaultValues?.product_barcode ?? "",
      qty_order: props?.defaultValues?.qty_order ?? 0,
      received: props?.defaultValues?.received ?? 0,
      mr_unit_id: props?.defaultValues?.mr_unit_id ?? NaN,
      mr_unit_name: props?.defaultValues?.mr_unit_name ?? "",
      price_measure: props?.defaultValues?.price_measure ?? 0,
      discount: props?.defaultValues?.discount ?? 0,
      total: props?.defaultValues?.total ?? 0,
      project_id: props?.defaultValues?.project_id ?? NaN,
      project_name: props?.defaultValues?.project_name ?? "",
      tax_id: props?.defaultValues?.tax_id ?? 2,
      tax_name:
        props?.defaultValues?.tax_name ??
        props.tax?.dropdown.find(({ value }) => value === "2")?.label,
      location_id: props.defaultValues.location_id,
      soh: props?.defaultValues?.soh ?? 0,
      quantity_invoice: props?.defaultValues?.quantity_invoice ?? 0
    }),
    [props.defaultValues, props.priceLevelName]
  )

  const methods = useForm<FormInvoiceDetailType>({
    defaultValues,
    resolver: yupResolver(formInvoiceDetailSchema)
  })

  const [qty_order, price_measure, discount, received] = methods.watch([
    "qty_order",
    "price_measure",
    "discount",
    "received",
    "price_level_name"
  ])

  useEffect(() => methods.reset(defaultValues), [defaultValues])

  useEffect(() => {
    methods.setValue(
      "total",
      calculateTotalSalesOrderDetail(methods.getValues()).finalPrice
    )
  }, [qty_order, price_measure, discount])

  useEffect(() => {
    if (!props.tax.isLoading && props.action === "add") {
      const defaultTax = props.tax.dropdown.find((item) => item.rate === 0)

      methods.setValue("tax_id", Number(defaultTax?.value))
      methods.setValue("tax_name", defaultTax?.label ?? "")
    }
  }, [props.tax.dropdown.length])

  console.log(methods.formState.errors)

  return (
    <FormProvider {...methods}>
      <tr className="no-padding-body">
        <td colSpan={2} className="text-right">
          <Controller
            control={methods.control}
            name="qty_order"
            render={({ field, fieldState }) => (
              <NumberInput
                textRight
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={2}
                inputClass="w-[120px]"
                value={field.value}
                ref={field.ref}
                // isAllowed={(value) =>
                //   (value?.floatValue ?? 0) <= qty_order - received
                // }
                onValueChange={(value) => {
                  if ((value?.floatValue ?? 0) <= qty_order - received) {
                  }

                  field.onChange(value.floatValue)
                }}
                error={fieldState.error?.message}
              />
            )}
          />
        </td>
        <td className="text-right">
          <Controller
            control={methods.control}
            name="received"
            render={({ field }) => (
              <div className="w-[120px] px-3">{field.value}</div>
            )}
          />
        </td>
        <td className="text-right">
          <div className="w-[120px] px-3">{qty_order - received}</div>
        </td>
        <td>
          <Controller
            control={methods.control}
            name="product_barcode"
            render={({ field }) => (
              <div className="w-[250px] px-3">{field.value}</div>
            )}
          />
        </td>
        <td>
          <Controller
            control={methods.control}
            name="product_name"
            render={({ field }) => (
              <div className="w-[250px] px-3">{field.value}</div>
            )}
          />
        </td>
        <td>
          <Controller
            control={methods.control}
            name="mr_unit_name"
            render={({ field }) => <div className="px-3">{field.value}</div>}
          />
        </td>
        <td className="text-right">
          <Controller
            control={methods.control}
            name="price_measure"
            render={({ field }) => (
              <div className="px-3">{field.value.toLocaleString()}</div>
            )}
          />
        </td>
        <td className="text-right">
          <Controller
            control={methods.control}
            name="discount"
            render={({ field }) => <div className="px-3">{field.value}</div>}
          />
        </td>
        <td className="text-right">
          <Controller
            control={methods.control}
            name="total"
            render={({ field }) => (
              <div className="w-[120px] px-3">
                {field.value.toLocaleString()}
              </div>
            )}
          />
        </td>
        <td>
          <Controller
            control={methods.control}
            name="project_name"
            render={({ field }) => (
              <div className="w-[120px] px-3">{field.value}</div>
            )}
          />
        </td>
        <td>
          <Controller
            control={methods.control}
            name="tax_name"
            render={({ field }) => (
              <div className="w-[120px] px-3">{field.value}</div>
            )}
          />
        </td>
        <td className="text-center flex justify-center items-center">
          <ActionButton.Check
            onClick={() => {
              methods.handleSubmit((data) => props.onSubmit(data))()
            }}
          />

          <ActionButton.Close onClick={props.onCancel} />
        </td>
      </tr>
    </FormProvider>
  )
}
