import { useEffect, useState } from "react"
import type { SuccessFetch } from "../../../../../../types"
import { useApi } from "../../../../../../utils"
import { DataList } from "../../types"

interface FilteredParams {
  page?: number
  keyword?: string
  limit?: number
  sort?: string
}

interface DataType {
  payload: DataList[]
  pagination_data: {
    total: number
    page: number
    size: number
    pages: number
  }
}

export function useList({ page, keyword, limit, sort }: FilteredParams) { 

  const parts = sort?.split(":");
  const sortBy = parts?.[0];
  const sortDirection = parts?.[1];

  const defaultValue = {
    payload: [],
    pagination_data: {
      page: 0,
      pages: 0,
      size: 0,
      total: 0
    }
  }

  const api = useApi()
  const [data, setData] = useState<DataType>(defaultValue)
  const [isLoading, setLoading] = useState(true)

  const fetchUrl = () => {
    if (page && keyword && limit) {
      return api.get(`/feature/?only_active=false&page=1&search=${keyword}&limit=${limit}&sort_by=${sortBy ?? "feature_id"}&sort_direction=${sortDirection ?? "desc"}`) 
    } else if (page && !keyword && !limit) {
      return api.get(`/feature/?only_active=false&page=${page}&sort_by=${sortBy ?? "feature_id"}&sort_direction=${sortDirection ?? "desc"}`)
    } else if (page && limit) {
      return api.get(`/feature/?only_active=false&page=${page}&limit=${limit}&sort_by=${sortBy ?? "feature_id"}&sort_direction=${sortDirection ?? "desc"}`)
    } else if (page && keyword) {
      return api.get(`/feature/?only_active=false&page=${page}&search=${keyword}&sort_by=${sortBy ?? "feature_id"}&sort_direction=${sortDirection ?? "desc"}`)
    } else {
      return api.get(`/feature/?only_active=false&sort_by=${sortBy ?? "feature_id"}&sort_direction=${sortDirection ?? "desc"}`)
    }
  }

  const fetchData = () => {
    setLoading(true)

    fetchUrl().then((res: SuccessFetch<DataType>) => {
      setData(res.data)
    }).catch(() => {
      setData(defaultValue)
    }).finally(() => {
      setLoading(false)
    })
  }

  const refetch = () => fetchData()

  useEffect(() => {
    fetchData()

    return () => {
      setData(defaultValue)
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [page, keyword, limit, sort])

  return { data, isLoading, refetch }
}

interface code {
  next_code: string
}

export function useCode() {
  const api = useApi()
  const [dataCode, setDataCode] = useState<code | null>(null)
  const [isLoading, setLoading] = useState(true)

  // useEffect(() => {
  //   const fetchData = () => api.post("/feature/generate-code", requestBody)

  //   fetchData()
  //   .then((res) => setDataCode(res.data.payload))
  //   .catch(() => setDataCode(null))
  //   .finally(() => setLoading(false))

  //   return () => {
  //     setDataCode(dataCode)
  //     setLoading(true)
  //   }

  //   // eslint-disable-next-line
  // }, [])

  const refetch = ( menuId: any ) => {
    setLoading(true)
    const requestBody = { menu_id: menuId };

    api.post("/feature/generate-code", requestBody)
    .then((res) => setDataCode(res.data.payload))
    .catch(() => setDataCode(null))
    .finally(() => setLoading(false))
  
    return () => {
      setDataCode(dataCode)
      setLoading(true)
    }
  }

  return { dataCode, isLoading, setDataCode, refetch }
}