// React
import { useContext } from "react"

// Components
import { NumberInput } from "components"

// Contexts
import { DisabledContext } from "../contexts"

// Form
import { Controller, useFormContext } from "react-hook-form"

export function Freight(params: {
  disabled?: boolean
  name: string
}) {
  // Hooks
  const disabled = useContext(DisabledContext)

  // Form
  const { control } = useFormContext()

  return (
    <Controller
      control={control}
      name={params.name}
      render={({ field, fieldState }) => (
        <NumberInput
          label="FREIGHT"
          decimalSeparator="."
          thousandSeparator=","
          decimalScale={0}
          disabled={params.disabled || disabled}
          error={fieldState.error?.message}
          ref={field.ref}
          value={field.value ?? 0}
          onValueChange={e => {
            // Vars
            const value: number = e.floatValue ?? 0

            field.onChange(value)
          }}
        />
      )}
    />
  )
}