// React
import { createContext } from "react"

// Types
import type { PoType } from "../types"

export const PoContext = createContext<PoType>({
  approve_status: 0,
  amount: 0,
  coa_id: "",
  memo: "",
  no_cheque: "",
  purchase_order_id: 0,
  transaction_date: "",
  trx_code: "",
  datapo: {
    address: "",
    location_id: "",
    trx_code: "",
    vendor_id: "",
    vendor_name: ""
  }
})