import { convertDate } from "../utils"

interface HeaderSectionProps { isSelected?: any }

export const HeaderSection:React.FC<HeaderSectionProps> = ({ isSelected }) => {
  const startDate = isSelected?.date_from ? convertDate(isSelected?.date_from) : "-"
  const endDate = isSelected?.date_to ? convertDate(isSelected?.date_to) : "-"

  return (
    <div className="mb-5 mt-5 label-text">
      <div>ACCOUNTS OF DETAILS</div>
      <div className="text-[#7b7b7b]">PT. HAWK</div>
      <div className="text-[#7b7b7b]">{`REPORT DETAIL FOR PERIODE: ${startDate} TO ${endDate}`}</div>
    </div>
  )
}