// React
import { useEffect } from "react"

// Components
import { Pagination } from "components"

// Types
interface PaginationState {
  total: number
  page: number
  limit: number
  pages: number
  keyword: string
  departemen?: string
  job_position?: string
  main_location?: string
}

export function PaginationData(props: {
  data: any
  pagination: PaginationState
  setPagination: React.Dispatch<React.SetStateAction<PaginationState>>
}) {
  useEffect(() => {
    props.setPagination((prev) => {
      return {
        ...prev,
        pages: props.data.pagination_data.pages,
        total: props.data.pagination_data.total
      }
    })
    // eslint-disable-next-line
  }, [props.data.pagination_data.pages, props.data.pagination_data.total])

  const handlePageChange = (page: number) => {
    props.setPagination((prev) => {
      return {
        ...prev,
        page: page
      }
    })
  }

  const handleSize = (sizeValue: string | undefined) => {
    if (sizeValue) {
      props.setPagination((prev) => {
        return {
          ...prev,
          limit: parseInt(sizeValue),
          page: 1
        }
      })
    }
  }

  return (
    <Pagination
      currentPage={props.pagination.page}
      totalPage={props.pagination.pages}
      onPageChange={handlePageChange}
      onAddShow={(value) => handleSize(value)}
      limit={props.pagination.limit}
      total={props.pagination.total}
      size={props.data.pagination_data.size}
    />
  )
}
