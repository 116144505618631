// React
import { Fragment, useContext } from "react"

// Contexts
import { AccountContext } from "contexts"

// Form
import { useFormContext, useWatch } from "react-hook-form"

// Third-Party Libraries
import moment from "moment"

// Types
import type { SelectOptionType } from "types"
import type { FormType } from "../utils"

// Utils
import { convertNumber, useCoaByCode } from "utils"

export function Body(): JSX.Element {
  // Hooks
  const coaCode = useCoaByCode("LA09")
  const { data } = useContext(AccountContext)

  // Form
  const { control } = useFormContext<FormType>()
  const trx_code = useWatch({
    control,
    name: "trx_code"
  })
  const transaction_date = useWatch({
    control,
    name: "transaction_date"
  })
  const coa_id = useWatch({
    control,
    name: "coa_id"
  })
  const memo = useWatch({
    control,
    name: "memo"
  })
  const amount = useWatch({
    control,
    name: "amount"
  })

  // Vars
  const coa: SelectOptionType | undefined = data.find(item => item.value === coa_id)

  if (coa_id) {
    return (
      <Fragment>
        <tr>
          <td className="text-center">{moment(transaction_date).format("DD/MM/YYYY")}</td>
          <td className="text-center">{trx_code}</td>
          <td>{coa?.label}</td>
          <td>{memo}</td>
          <td className="text-right">0</td>
          <td className="text-right text-error">{convertNumber(amount).intoCurrency}</td>
        </tr>

        <tr>
          <td className="text-center">{moment(transaction_date).format("DD/MM/YYYY")}</td>
          <td className="text-center">{trx_code}</td>
          <td>{coaCode.data}</td>
          <td>{memo}</td>
          <td className="text-right">{convertNumber(amount).intoCurrency}</td>
          <td className="text-right text-error">0</td>
        </tr>
      </Fragment>
    )
  }

  return (
    <tr>
      <td colSpan={6} />
    </tr>
  )
}